import { useState, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { isNotNone, PaginatedSet, PaginationState, PaginationSearch } from 'types'
import { useLocation } from 'react-router-dom'
import { navigate } from './NavigateHoist'
import Table, { TableSchema } from 'components/Table'
import Pagination from 'components/Pagination2'

export interface SearchFormProps {
	setSearch: (x?: PaginationSearch) => void
	close: () => void
}

interface PaginatedTableProps<T> {
	schema: TableSchema<T>
	set?: PaginatedSet<T>
	getKey?: (x: T, i: number) => number | string
	paginationState: PaginationState
	paginationPrefix?: string
	search?: (props: SearchFormProps) => ReactNode
	isLoading?: boolean
	isStriped?: boolean
	className?: ClassValue
}

const PaginatedTable = <T,>({
	schema,
	set,
	getKey = (_, i) => i,
	paginationState,
	paginationPrefix = '',
	search,
	isLoading = false,
	isStriped = false,
	className,
}: PaginatedTableProps<T>) => {
	const p = paginationPrefix
	const location = useLocation()
	const locationState = (location?.state as { [x: string]: any } | null) || {}

	const page = paginationState.page
	const setPage = (x: number) =>
		navigate(location.pathname, { replace: true, state: { ...locationState, [p + 'page']: x } })

	const pageSize = paginationState.pageSize
	const setPageSize = (x: number) =>
		navigate(location.pathname, {
			replace: true,
			state: { ...locationState, [p + 'pageSize']: x, [p + 'page']: 1 },
		})

	const searchCount = Object.values(locationState[p + 'search'] || {}).filter(isNotNone).length

	const [isSearchVisible, setSearchVisible] = useState(searchCount > 0)

	const setSearch = (x?: PaginationSearch) =>
		navigate(location.pathname, { replace: true, state: { ...locationState, [p + 'search']: x, [p + 'page']: 1 } })

	const searchComponent = search ? search({ setSearch, close: () => setSearchVisible(false) }) : null

	return (
		<div className={clsx('relative', className)}>
			<Pagination
				schema={schema}
				items={set?.items || []}
				pageSize={pageSize}
				setPageSize={setPageSize}
				totalPages={set?.totalPages || 0}
				itemCount={set?.items.length || 0}
				totalCount={set?.totalCount || 0}
				page={page}
				setPage={setPage}
				isSearchVisible={isSearchVisible}
				setSearchVisible={setSearchVisible}
				searchCount={searchCount}
				searchComponent={searchComponent}
				isLoading={isLoading}
			>
				<Table
					items={set?.items || []}
					schema={schema}
					getKey={getKey}
					isStriped={isStriped}
					className="-mx-6"
				/>
			</Pagination>
		</div>
	)
}

export default PaginatedTable
