import { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageHeading from 'components/PageHeading'
import Stack from 'components/Stack'
import { format, parseISO, startOfDay } from 'date-fns'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { TableSchema } from 'components/Table'
import PageNavbar from 'components/PageNavbar'
import { links } from 'pages/Admin'
import { gql } from '@apollo/client'
import { GQLConnection, GQLReminderType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import Search from 'search/ReminderSearch'
import endOfDay from 'date-fns/esm/fp/endOfDay/index.js'
import SectionHeader from 'components/SectionHeader'
import Container from 'components/Container'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: ReminderOrderBy!, $search: ReminderSearch) {
		Reminders(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				created
				createdBy {
					id
					email
				}
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				case {
					id
					caseNumber
				}
				event {
					id
					startDate
					type {
						id
						readable
					}
				}
				users {
					id
					fullName
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const ReminderLog: FC = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const searchDate = state?.search?.date
		? {
				created_from: startOfDay(state?.search?.date),
				created_to: endOfDay(state?.search?.date),
		  }
		: {}

	const { data, loading, refetch } = useBufferedQuery<{ Reminders: GQLConnection<GQLReminderType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: {
				caseNumber: state?.search?.caseNumber,
				userId: state?.search?.userId,
				status: state?.search?.status,
				type: state?.search?.type,
				...searchDate,
			},
		},
	})

	const [matches, setMatches] = useState<Record<string, boolean>>({})

	const schema: TableSchema<GQLReminderType> = {
		cols: [
			{
				title: 'Case/Conf',
				value: (x) => (
					<div className="flex flex-col">
						<Link to={`/cases/${x.case?.id}`} className="anchor">
							{x.case?.caseNumber}
						</Link>
						{x.event && x.case && (
							<Link to={`/cases/${x.case.id}/conferences?hearing=${x.event.id}`} className="anchor">
								{x.event.type?.readable} ({format(parseISO(x.event?.startDate || ''), 'dd/MM/yyyy')})
							</Link>
						)}
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			!matches.hideType && {
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
			},
			!matches.hideUser && {
				title: 'User',
				value: (x) => (
					<Link to={`/users/${x.users?.[0].id}`} className="anchor">
						{x.users?.[0].fullName}
					</Link>
				),
				truncate: true,
			},
			{
				title: 'Created',
				value: (x) => format(parseISO(x.created || ''), 'dd/MM/yyyy HH:mm:ss'),
				truncate: true,
			},
			!matches.hideCompleted && {
				title: 'Completed',
				value: (x) => x.status?.readable,
				width: 'minmax(auto, max-content)',
			},
		],
	}

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Reminders" />

			<PageNavbar links={links}>
				<Container
					className="max-w-full"
					queries={{
						hideType: { maxWidth: 800 },
						hideCompleted: { maxWidth: 650 },
						hideUser: { maxWidth: 550 },
					}}
					onQueryChange={setMatches}
				>
					<Stack className="px-6">
						<SectionHeader title="Reminder Log" />
						<GQLPaginatedTable
							schema={schema}
							set={data?.Reminders}
							isLoading={loading}
							refetch={refetch}
							search={(props) => <Search {...props} />}
						/>
					</Stack>
				</Container>
			</PageNavbar>
		</>
	)
}

export default ReminderLog
