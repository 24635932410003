import { FC, useState } from 'react'
import Actions from 'components/Actions'
import Panel from 'components/Panel'
import { format } from 'date-fns'
import { downloadCSV } from 'utils/funcs'

const fmt = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ss")

interface DecisionDueProps {
	startDate?: Date
	endDate?: Date
}

const DecisionDue: FC<DecisionDueProps> = ({ startDate, endDate }) => {
	const [isDownloading, setDownloading] = useState(false)

	const run = async () => {
		if (!startDate || !endDate) return

		setDownloading(true)

		await downloadCSV(
			`/Report/decisionduereport?startDate=${fmt(startDate)}&endDate=${fmt(endDate)}`,
			`report_decision-due_${format(startDate, 'yyyy-MM-dd')}.csv`
		)

		setDownloading(false)
	}

	return (
		<Panel
			title="Decisions Due"
			subtitle="Download report_decision due.csv for all open cases with decision due dates in the selected date range."
			content={
				<Actions
					actions={[
						{
							title: 'Run report',
							intent: 'primary',
							onClick: () => run(),
							isLoading: isDownloading,
						},
					]}
				/>
			}
		/>
	)
}

export default DecisionDue
