import { FC } from 'react'
import PageHeading from 'components/PageHeading'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { PlusIcon, DotsHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { Link, useLocation } from 'react-router-dom'
import { confirm, toast } from 'alerts'
import api from 'api'
import openCreateOrganization from 'modals/openCreateOrganization'
import openEditOrganization from 'modals/openEditOrganization'
import PageNavbar from 'components/PageNavbar'
import { links } from 'pages/Admin'
import { GQLConnection, GQLOrganizationType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { gql } from '@apollo/client'
import { TableSchema } from 'components/Table'
import Stack from 'components/Stack'
import SectionHeader from 'components/SectionHeader'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: OrganizationOrderBy!) {
		Organizations(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy) {
			items {
				id
				name
				email
				phone
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const Organizations: FC = () => {
	const location = useLocation()

	const state = location?.state as { page?: number; pageSize?: number } | null

	const { data, loading, refetch } = useBufferedQuery<{ Organizations: GQLConnection<GQLOrganizationType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
		},
	})

	const schema: TableSchema<GQLOrganizationType> = {
		cols: [
			{
				title: 'Name',
				value: (x) => (
					<Link to={`${x.id}`} className="anchor">
						{x.name}
					</Link>
				),
				width: '30%',
			},
			{
				title: 'Email Address',
				value: (x) => x.email,
				truncate: true,
			},
			{
				title: 'Phone Number',
				value: (x) => x.phone,
				truncate: true,
			},
		],
		actions: (x) => [
			{
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						{
							title: 'Edit Organisation',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => openEditOrganization({ organizationId: x.id, refetch }),
						},
					],
					x.name !== 'ACC' && [
						{
							title: 'Delete Organisation',
							intent: 'danger',
							icon: <TrashIcon className="w-5 h-5" />,
							onClick: () => deleteOrg(x),
						},
					],
				],
			},
		],
	}

	const deleteOrg = async (item: GQLOrganizationType) => {
		await confirm({
			title: 'Are you sure you want to delete this organization?',
			onAccept: async () => {
				try {
					await api.delete(`/Organizations/${item.id}`)

					await refetch()

					toast({ title: 'Organization Deleted' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Organisations" />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader
						title="Organisations"
						actions={[
							{
								title: 'Add Organisation',
								intent: 'primary',
								icon: <PlusIcon className="w-5 h-5" />,
								onClick: () => openCreateOrganization({ refetch }),
							},
						]}
					/>
					<GQLPaginatedTable
						schema={schema}
						set={data?.Organizations}
						isLoading={loading}
						refetch={refetch}
					/>
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Organizations
