import { FC, useMemo } from 'react'
import { HearingModel, TimeslotModel } from 'types/models'
import { format, startOfWeek, endOfWeek, addDays, isSameDay, isSameHour, getDay } from 'date-fns'
import useData from 'hooks/useData'
import Button from 'components/Button'
import Loader from 'components/Loader'
import clsx, { ClassValue } from 'clsx'
import { isNotNone } from 'types'

const getStartDate = (date: Date) => format(startOfWeek(date), "yyyy-MM-dd'T'HH:mm:ss")
const getEndDate = (date: Date) => format(endOfWeek(date), "yyyy-MM-dd'T'HH:mm:ss")

interface EventAvailabilityTemplateProps {
	context?: Date
	onSelect: (x: SubSlot) => void
	selected?: SubSlot
	className?: ClassValue
}

export interface Slot {
	date: Date
	slots: SubSlot[]
}

export interface SubSlot {
	date: string
	availableReviewers: number
}

const EventAvailabilityTemplate: FC<EventAvailabilityTemplateProps> = ({
	context = new Date(),
	onSelect,
	selected,
	className,
}) => {
	const { data: availability, isFetching: loadingAvailability } = useData<TimeslotModel[]>(`Availability/slots`, {
		startDate: getStartDate(context),
		endDate: getEndDate(context),
	})

	const { data: events, isFetching: loadingHearings } = useData<HearingModel[]>(`CaseHearings`, {
		startDate: getStartDate(context),
		endDate: getEndDate(context),
	})

	const slots: Slot[] = useMemo(() => {
		return new Array(7)
			.fill(null)
			.map((_, i): Slot | null => {
				let day = addDays(startOfWeek(context), i)
				let slots: any[] = []

				if (getDay(day) === 0 || getDay(day) === 6) {
					return null
				}

				if (availability && events) {
					slots = availability
						.filter((slot) => isSameDay(new Date(slot.startDate), day))
						.sort((a, b) => new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf())
						.map((slot) => {
							let users = slot.availability.map((x) => x.user.id)

							let h = events.filter((event) =>
								isSameHour(new Date(event?.startDate || ''), new Date(slot.startDate))
							)

							return {
								date: slot.startDate,
								availableReviewers: users.filter((userId) => {
									return h.reduce((acc: boolean, event) => {
										if (event.attendees.find((x) => x.id === userId)) {
											return false
										}
										return acc
									}, true)
								}).length,
							}
						})
				}

				return { date: day, slots }
			})
			.filter(isNotNone)
	}, [context, availability, events])

	return (
		<div className={clsx('relative', className)}>
			<div className="overflow-x-auto">
				<div className="flex">
					{slots.map((slot, i) => (
						<div
							key={i}
							className="flex flex-col flex-1 min-w-40 divide-x divide-gray-200 dark:divide-gray-600"
						>
							<div className="p-2 border-b border-gray-200 dark:border-gray-600 text-center">
								{format(slot.date, 'EEE do')}
							</div>

							{!loadingAvailability && !loadingHearings && (
								<div className="flex flex-col items-center flex-1">
									{slot.slots.map((slot, j) => (
										<div key={j} className="px-2 pt-2 font-feature-tnum">
											<Button
												onClick={() => onSelect(slot)}
												intent={selected === slot ? 'primary' : 'secondary'}
											>
												<span
													className={clsx('text-opacity-75', {
														// 'text-black dark:text-white': selected !== slot,
														// 'text-white dark:text-white': selected === slot,
													})}
												>
													{format(new Date(slot.date), 'hh:mm a')}
												</span>
												<span className="ml-2 pl-2 font-bold border-l border-current border-opacity-50">
													{slot.availableReviewers}
												</span>
											</Button>
										</div>
									))}
								</div>
							)}
						</div>
					))}
				</div>
				{(loadingAvailability || loadingHearings) && (
					<div className="mt-6">
						<Loader />
					</div>
				)}
			</div>
		</div>
	)
}

export default EventAvailabilityTemplate
