import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { gql } from '@apollo/client'
import { GQLApplicationsType, GQLConnection } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Stack from 'components/Stack'
import Card from 'components/Card'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { TableSchema } from 'components/Table'
import { DotsHorizontalIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline'
import openApplication from 'modals/openApplication'
import { confirm, toast, warning } from 'alerts'
import api from 'api'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: ApplicationOrderBy!) {
		Applications(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy) {
			items {
				id
				firstName
				lastName
				email
				phone
				disputeDescription
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const Applications: FC = () => {
	const location = useLocation()

	const state = location?.state as { page?: number; pageSize?: number } | null

	const { data, loading, refetch } = useBufferedQuery<{ Applications: GQLConnection<GQLApplicationsType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
		},
	})

	const schema: TableSchema<GQLApplicationsType> = {
		cols: [
			{
				title: 'Name',
				value: (x) => (
					<button onClick={() => openApplication({ applicationId: x.id, refetch })} className="anchor">
						{x.firstName} {x.lastName}
					</button>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Email',
				value: (x) => x.email,
				truncate: true,
			},
			{
				title: 'Phone no.',
				value: (x) => x.phone,
				truncate: true,
			},
			{
				title: 'Description',
				value: (x) => x.disputeDescription,
				truncate: true,
			},
		],
		actions: (x) => [
			{
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						{
							title: 'View Application',
							icon: <EyeIcon className="w-5 h-5" />,
							onClick: () => openApplication({ applicationId: x.id, refetch }),
						},
					],
					[
						{
							title: 'Delete',
							intent: 'danger',
							icon: <TrashIcon className="w-5 h-5" />,
							onClick: () => deleteApplication(x),
						},
					],
				],
			},
		],
	}

	const deleteApplication = async (item: GQLApplicationsType) => {
		await confirm({
			title: 'Are you sure you want to delete this application?',
			onAccept: async () => {
				try {
					await api.delete(`/Applications/${item.id}`)

					await refetch()

					toast({ title: 'Application Deleted' })
				} catch (error) {
					api.handleError(error)
					warning({ title: 'Application not deleted' })
				}
			},
		})
	}

	return (
		<>
			<PageHeading title="Applications" />

			<PageContent>
				<Stack>
					<Card>
						<GQLPaginatedTable
							schema={schema}
							set={data?.Applications}
							isLoading={loading}
							getKey={(x) => x.id}
							refetch={refetch}
						/>
					</Card>
				</Stack>
			</PageContent>
		</>
	)
}

export default Applications
