import { FC, ReactNode, useState } from 'react'
import { isNavAnchor, isNavButton, isNavGroup, isNavLink, NavListItems } from 'components/NavList'
import PageContent from 'components/PageContent'
import { CardWrapper } from 'components/Card'
import clsx, { ClassValue } from 'clsx'
import Sidebar from './Sidebar'
import Select from './Select'
import { isNotNone } from 'types'
import { matchPath, useLocation } from 'react-router'
import { navigate } from './NavigateHoist'
import Container from './Container'

interface PageNavbarProps {
	links: NavListItems
	className?: ClassValue
	children: ReactNode
}

const PageNavbar: FC<PageNavbarProps> = ({ links, className, children }) => {
	const { pathname } = useLocation()

	const [matches, setMatches] = useState<Record<string, boolean>>({ horizontal: true })

	return (
		<PageContent className={className}>
			<CardWrapper className="relative overflow-hidden">
				<Container
					className="max-w-full"
					queries={{
						horizontal: { minWidth: 700 },
					}}
					onQueryChange={setMatches}
				>
					<div className={clsx('flex items-stretch', matches.horizontal ? 'flex-row' : 'flex-col')}>
						<div>
							{matches.horizontal ? (
								<div className="h-full border-r border-gray-200 dark:border-gray-600">
									<Sidebar
										name="case-min"
										items={links}
										intent="light"
										minimizable
										className="py-3"
									/>
								</div>
							) : (
								<div className="flex items-center gap-4 p-4 border-b border-gray-200 dark:border-gray-600">
									<div className="text-sm font-medium">Navigate</div>
									<div className="flex-1">
										<Select
											value={links.filter(isNavLink).find((x) => {
												return !!matchPath(x.to, pathname)
											})}
											onChange={(val) => {
												if (isNavLink(val)) navigate(val.to)
												if (isNavButton(val)) val.onClick()
												if (isNavAnchor(val)) window.open(val.href)
											}}
											options={links.filter(isNotNone).map((x) => ({
												label: (
													<div className="flex items-center gap-3">
														{!isNavGroup(x) && x?.icon && <div>{x.icon}</div>}
														<div>{x.title}</div>
													</div>
												),
												value: x,
											}))}
										/>
									</div>
								</div>
							)}
						</div>

						<div className="flex-1 relative min-w-0">
							<div className="py-6">{children}</div>
						</div>
					</div>
				</Container>
			</CardWrapper>
		</PageContent>
	)
}

export default PageNavbar
