import { FC, useRef, useEffect } from 'react'
import { openModal, ModalContent } from 'hooks/useModal'
import useData from 'hooks/useData'
import { EmailLogModel } from 'types/models'

interface OpenEmailTemplateProps {
	logId: string
}

const openEmailTemplate = ({ logId }: OpenEmailTemplateProps) => {
	return openModal({
		title: 'View Email',
		size: 'xl',
		render: () => <ViewEmailTemplate logId={logId} />,
	})
}

interface ViewEmailTemplateProps {
	logId: string
}

const ViewEmailTemplate: FC<ViewEmailTemplateProps> = ({ logId }) => {
	const { data: emailTemplate } = useData<EmailLogModel>(`/EmailLogs/${logId}`, {}, { suspense: true })

	return (
		<ModalContent>
			<div className="modal-content">
				<div className="mb-6">
					<h2 className="text-2xl">{emailTemplate?.email?.subject}</h2>
				</div>
				<div className="bg-gray-100 p-4 rounded">
					<Frame content={emailTemplate?.email?.body || ''} width="100%" height="700" />
				</div>
			</div>
		</ModalContent>
	)
}

interface FrameProps {
	content: string
	width: string
	height: string
}

const Frame: FC<FrameProps> = ({ content, ...props }) => {
	const ref = useRef<HTMLIFrameElement>(null)

	useEffect(() => {
		if (ref.current) {
			let doc = ref.current.contentDocument

			if (doc) {
				doc.body.innerHTML = content
			}
		}
	}, [content])

	return <iframe title="Email Content" ref={ref} {...props} />
}

export default openEmailTemplate
