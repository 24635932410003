import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import { getFullName } from 'utils/funcs'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/FormSelect'
import useData from 'hooks/useData'
import { Spinner } from 'components/Icon'
import { CaseModel, UserModel } from 'types/models'
import { USER_ROLE } from 'types/enums'
import { CheckIcon } from '@heroicons/react/outline'
import { ModalResolveFn } from 'types'
import FormToggle from 'components/Form/FormToggle'
import FormInput from 'components/Form/FormInput'
import AutoHeight from 'components/AutoHeight'

interface FilterType {
	roles: USER_ROLE[]
}

interface FormData {
	user: UserModel | null
	billable: boolean
	changeReason: string
}

interface OpenChangeReviewerProps {
	title: string
	filter?: FilterType
	caseData: CaseModel
	onAccept?: (x: FormData) => void | Promise<void>
}

const openChangeReviewer = ({ title, ...props }: OpenChangeReviewerProps) => {
	return openModal<FormData>({
		title,
		size: 'md',
		render: (close) => <FindUser close={close} {...props} />,
	})
}

const FindUser: FC<Omit<OpenChangeReviewerProps, 'title'> & { close: ModalResolveFn<FormData> }> = ({
	close,
	filter = {},
	caseData,
	onAccept,
}) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			user: null,
			billable: false,
			changeReason: '',
		},
	})

	const user = formContext.watch('user')
	const billable = formContext.watch('billable')

	const { data: users = { items: [] }, isFetching } = useData('/users', {
		pageNumber: 1,
		pageSize: 100,
		search: filter,
	})

	const handleSubmit = async (formData: FormData) => {
		try {
			setSaving(true)
			if (onAccept) await onAccept(formData)
			close(formData)
		} catch (e) {
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				{isFetching ? (
					<div className="flex items-center justify-center">
						<Spinner className="w-10 h-10 spin" />
					</div>
				) : (
					<Stack>
						<FormSelect
							name="user"
							label="User"
							options={users.items.map((x) => ({ label: getFullName(x), value: x }))}
							validations={{ required: 'User is required' }}
							autoFocus
						/>

						<AutoHeight
							show={
								filter?.roles?.indexOf(USER_ROLE.Reviewer) !== -1 &&
								(!!caseData?.reviewer?.user &&
									user &&
									caseData?.reviewer?.user.id !== (user as UserModel)?.id) !== null
							}
						>
							<FormToggle name="billable" label="Billable Change" />
						</AutoHeight>

						<AutoHeight show={billable}>
							<FormInput name="changeReason" label="Reason for change" />
						</AutoHeight>
					</Stack>
				)}
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openChangeReviewer
