import { ReactNode, FC } from 'react'
import useLocalStorage from 'hooks/useLocalStorage'
import NavList, { NavListItems, NavListIntentType } from './NavList'
import clsx, { ClassValue } from 'clsx'
import { TooltipIntent } from './Tooltip'
import mural from 'assets/img/newlogo/sidebar-mural.png'
import { useDarkMode } from './DarkMode'

export const SidebarIntents: Record<NavListIntentType, string> = {
	dark: 'bg-brand-700 dark:bg-gray-900 antialiased',
	light: 'bg-white dark:bg-gray-700',
	primary: 'bg-primary-600 dark:bg-primary-700 antialiased',
}

interface SidebarProps {
	items: NavListItems | ((min: boolean) => NavListItems)
	header?: ReactNode | ((min: boolean) => ReactNode)
	footer?: ReactNode | ((min: boolean) => ReactNode)
	name?: string
	minimizable?: boolean
	className?: ClassValue
	intent?: NavListIntentType
	tooltipIntent?: TooltipIntent
}

const Sidebar: FC<SidebarProps> = ({
	items,
	header = null,
	footer = null,
	name = 'sidebar',
	minimizable = false,
	className,
	intent = 'dark',
	tooltipIntent,
}) => {
	const [min, setMin] = useLocalStorage(name, false)

	const { isDark } = useDarkMode()

	return (
		<div
			className={clsx(
				'h-full flex flex-col relative transition-all duration-100 bg-bottom bg-no-repeat',
				SidebarIntents[intent],
				min ? 'w-16' : 'w-64',
				className
			)}
			style={
				intent === 'dark' && !isDark
					? {
							backgroundImage: `url(${mural})`,
							backgroundSize: '256px 500px',
					  }
					: {}
			}
		>
			{minimizable && (
				<div
					className={clsx('absolute right-0 top-0 h-full -mr-2 w-4 z-40 group', {
						'cursor-[e-resize]': min,
						'cursor-[w-resize]': !min,
					})}
					onClick={() => setMin((x) => !x)}
				>
					<div className="group-hover:opacity-100 opacity-0 transition-opacity ease-in-out duration-200 w-1 h-full ml-1.5 bg-primary-600" />
				</div>
			)}

			<div className="flex-1 flex flex-col overflow-auto">
				{typeof header === 'function' ? header(min) : header}

				<div className="px-3 flex-1">
					<NavList
						items={typeof items === 'function' ? items(min) : items}
						intent={intent}
						tooltips={min}
						tooltipIntent={tooltipIntent}
					/>
				</div>

				{typeof footer === 'function' ? footer(min) : footer}
			</div>
		</div>
	)
}

export default Sidebar
