export enum METRIC_TYPE {
	'CaseManagerDecisionDue' = 1,
	'AdminDecisionDue' = 2,
	'ReviewerDecisionDue' = 3,
	'CaseManagerNoHearingSetDown' = 4,
	'AdminNoHearingSetDown' = 5,
	'ReviewerNoHearingSetDown' = 6,
	'CaseManagerPending' = 7,
	'CaseManagerActive' = 8,
	'CaseManagerNeedsApproval' = 9,
	'ReviewerNeedsApproval' = 10,
	'AdminNeedsApproval' = 11,
	'AdminDeclinedByReviewer' = 12,
	'ReviewerDeclinedCases' = 13,
}

export enum API_METRIC_TYPE {
	'DecisionDue' = 1,
	'PendingCases' = 2,
	'ActiveCases' = 3,
	'NoHearingSetDown' = 4,
	'NeedsApproval' = 5,
	'ReviewerDeclined' = 6,
}

export const metricType = {
	1: 'Case Manager Decision Due',
	2: 'Admin Decision Due',
	3: 'Reviewer Decision Due',
	4: 'Case Manager No Hearing Set Down',
	5: 'Admin No Hearing Set Down',
	6: 'Reviewer No Hearing Set Down',
	7: 'Case Manager Pending',
	8: 'Case Manager Active',
	9: 'Case Manager Needs Approval',
	10: 'Reviewer Needs Approval',
	11: 'Admin Needs Approval',
	12: 'Admin Declined By Reviewer',
	13: 'Reviewer Declined Cases',
}

export const MetricType = {
	readable: (x: METRIC_TYPE) => metricType[x],
	options: Object.entries(metricType).map(([key, val]) => ({ label: val, value: +key })),
}
