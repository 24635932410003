import { CountryModel } from 'types/models'

const countries: CountryModel[] = [
	{
		id: 'f1ba8084-e56d-4585-8971-3478ed0a6aaf',
		name: 'Afghanistan',
		friendlyName: 'Afghanistan',
		iso: 'AF',
		isO3: 'AFG',
		countryCode: 4,
		phoneCode: 93,
	},
	{
		id: '626f6563-ba5d-4607-abef-b39b34a407e1',
		name: 'Albania',
		friendlyName: 'Albania',
		iso: 'AL',
		isO3: 'ALB',
		countryCode: 8,
		phoneCode: 355,
	},
	{
		id: 'a6932cbc-1453-42b6-b7b7-6133ab70bda4',
		name: 'Algeria',
		friendlyName: 'Algeria',
		iso: 'DZ',
		isO3: 'DZA',
		countryCode: 12,
		phoneCode: 213,
	},
	{
		id: 'df454e52-6704-4df2-8522-c31bb74c46df',
		name: 'American Samoa',
		friendlyName: 'American Samoa',
		iso: 'AS',
		isO3: 'ASM',
		countryCode: 16,
		phoneCode: 1684,
	},
	{
		id: '2a119629-1ade-45ec-91ae-a3912aa7048a',
		name: 'Andorra',
		friendlyName: 'Andorra',
		iso: 'AD',
		isO3: 'AND',
		countryCode: 20,
		phoneCode: 376,
	},
	{
		id: 'ab513438-7efe-4e44-b7d8-6328d7315b40',
		name: 'Angola',
		friendlyName: 'Angola',
		iso: 'AO',
		isO3: 'AGO',
		countryCode: 24,
		phoneCode: 244,
	},
	{
		id: '353bfaa8-f529-4da5-ad3c-99f399f16750',
		name: 'Anguilla',
		friendlyName: 'Anguilla',
		iso: 'AI',
		isO3: 'AIA',
		countryCode: 660,
		phoneCode: 1264,
	},
	{
		id: 'c59abe97-1ada-46ad-aa40-57ac6bdf8aa7',
		name: 'Antarctica',
		friendlyName: 'Antarctica',
		iso: 'AQ',
		isO3: null,
		countryCode: null,
		phoneCode: 0,
	},
	{
		id: '0a1cf3fc-74d6-4aab-85c4-a75451514f2c',
		name: 'Antigua and Barbuda',
		friendlyName: 'Antigua and Barbuda',
		iso: 'AG',
		isO3: 'ATG',
		countryCode: 28,
		phoneCode: 1268,
	},
	{
		id: '2eb12659-6c45-4132-b6e3-bd3c70f8359e',
		name: 'Argentina',
		friendlyName: 'Argentina',
		iso: 'AR',
		isO3: 'ARG',
		countryCode: 32,
		phoneCode: 54,
	},
	{
		id: '00f925ea-ca05-45bc-945f-1368d9c98211',
		name: 'Armenia',
		friendlyName: 'Armenia',
		iso: 'AM',
		isO3: 'ARM',
		countryCode: 51,
		phoneCode: 374,
	},
	{
		id: '483e6818-8a81-43c0-b736-7e197546540b',
		name: 'Aruba',
		friendlyName: 'Aruba',
		iso: 'AW',
		isO3: 'ABW',
		countryCode: 533,
		phoneCode: 297,
	},
	{
		id: '5045f035-391c-44ff-9872-f7079a299a1b',
		name: 'Australia',
		friendlyName: 'Australia',
		iso: 'AU',
		isO3: 'AUS',
		countryCode: 36,
		phoneCode: 61,
	},
	{
		id: 'ab5c1c8d-a0e5-4778-866e-7eb925470a8d',
		name: 'Austria',
		friendlyName: 'Austria',
		iso: 'AT',
		isO3: 'AUT',
		countryCode: 40,
		phoneCode: 43,
	},
	{
		id: 'd07baa8e-0d98-4016-af99-0cb67b097293',
		name: 'Azerbaijan',
		friendlyName: 'Azerbaijan',
		iso: 'AZ',
		isO3: 'AZE',
		countryCode: 31,
		phoneCode: 994,
	},
	{
		id: 'c520a6f4-ed2f-4183-9f84-49412037d220',
		name: 'Bahamas',
		friendlyName: 'Bahamas',
		iso: 'BS',
		isO3: 'BHS',
		countryCode: 44,
		phoneCode: 1242,
	},
	{
		id: '49072218-0339-4006-a099-0cbe1d7c8847',
		name: 'Bahrain',
		friendlyName: 'Bahrain',
		iso: 'BH',
		isO3: 'BHR',
		countryCode: 48,
		phoneCode: 973,
	},
	{
		id: '9a8e7d6d-0b26-4c26-9df9-ab8125f3e62c',
		name: 'Bangladesh',
		friendlyName: 'Bangladesh',
		iso: 'BD',
		isO3: 'BGD',
		countryCode: 50,
		phoneCode: 880,
	},
	{
		id: '4f3d5ddd-c12b-4823-a07c-52d163a63adb',
		name: 'Barbados',
		friendlyName: 'Barbados',
		iso: 'BB',
		isO3: 'BRB',
		countryCode: 52,
		phoneCode: 1246,
	},
	{
		id: '12970793-58af-4ff4-b251-df437b23189f',
		name: 'Belarus',
		friendlyName: 'Belarus',
		iso: 'BY',
		isO3: 'BLR',
		countryCode: 112,
		phoneCode: 375,
	},
	{
		id: '82bb0f73-b742-4a25-a8d4-a21daa5c93c6',
		name: 'Belgium',
		friendlyName: 'Belgium',
		iso: 'BE',
		isO3: 'BEL',
		countryCode: 56,
		phoneCode: 32,
	},
	{
		id: '71a1dc98-74b3-4d8d-b117-d6ee32d5b129',
		name: 'Belize',
		friendlyName: 'Belize',
		iso: 'BZ',
		isO3: 'BLZ',
		countryCode: 84,
		phoneCode: 501,
	},
	{
		id: 'a09ce6cb-8bcf-4c92-8dbb-b354aee92156',
		name: 'Benin',
		friendlyName: 'Benin',
		iso: 'BJ',
		isO3: 'BEN',
		countryCode: 204,
		phoneCode: 229,
	},
	{
		id: '7cba7733-8623-4cc4-b74c-1302786ece88',
		name: 'Bermuda',
		friendlyName: 'Bermuda',
		iso: 'BM',
		isO3: 'BMU',
		countryCode: 60,
		phoneCode: 1441,
	},
	{
		id: 'a47668a1-0e42-4ba9-8951-ffbb71316363',
		name: 'Bhutan',
		friendlyName: 'Bhutan',
		iso: 'BT',
		isO3: 'BTN',
		countryCode: 64,
		phoneCode: 975,
	},
	{
		id: '71a74cbd-7545-47cb-8599-b029e4b03c70',
		name: 'Bolivia',
		friendlyName: 'Bolivia',
		iso: 'BO',
		isO3: 'BOL',
		countryCode: 68,
		phoneCode: 591,
	},
	{
		id: '1222e8ca-4425-4656-b650-4541e3d7947d',
		name: 'Bosnia and Herzegovina',
		friendlyName: 'Bosnia and Herzegovina',
		iso: 'BA',
		isO3: 'BIH',
		countryCode: 70,
		phoneCode: 387,
	},
	{
		id: '694a4034-2ae8-4be7-83b6-504ad1a76644',
		name: 'Botswana',
		friendlyName: 'Botswana',
		iso: 'BW',
		isO3: 'BWA',
		countryCode: 72,
		phoneCode: 267,
	},
	{
		id: '0cb48af1-bf4c-471d-9b07-1c876861d869',
		name: 'Bouvet Island',
		friendlyName: 'Bouvet Island',
		iso: 'BV',
		isO3: null,
		countryCode: null,
		phoneCode: 0,
	},
	{
		id: 'a566ed6e-4228-4044-8eae-dbe69e7f57f6',
		name: 'Brazil',
		friendlyName: 'Brazil',
		iso: 'BR',
		isO3: 'BRA',
		countryCode: 76,
		phoneCode: 55,
	},
	{
		id: '8e15d059-0be7-41e4-a28e-ea6b90386904',
		name: 'British Indian Ocean Territory',
		friendlyName: 'British Indian Ocean Territory',
		iso: 'IO',
		isO3: null,
		countryCode: null,
		phoneCode: 246,
	},
	{
		id: '2d393a60-eb93-464f-bd31-7697f4c33f16',
		name: 'Brunei Darussalam',
		friendlyName: 'Brunei Darussalam',
		iso: 'BN',
		isO3: 'BRN',
		countryCode: 96,
		phoneCode: 673,
	},
	{
		id: '68ced8db-8513-4bd0-bfe1-09e39b111ca2',
		name: 'Bulgaria',
		friendlyName: 'Bulgaria',
		iso: 'BG',
		isO3: 'BGR',
		countryCode: 100,
		phoneCode: 359,
	},
	{
		id: '40bed803-6a74-48bf-8e26-943d474a62bf',
		name: 'Burkina Faso',
		friendlyName: 'Burkina Faso',
		iso: 'BF',
		isO3: 'BFA',
		countryCode: 854,
		phoneCode: 226,
	},
	{
		id: 'fd32860c-58ef-4e71-88c6-d4e3122c38d8',
		name: 'Burundi',
		friendlyName: 'Burundi',
		iso: 'BI',
		isO3: 'BDI',
		countryCode: 108,
		phoneCode: 257,
	},
	{
		id: '751068ec-249d-4eab-8ab2-57cc2b7af575',
		name: 'Cambodia',
		friendlyName: 'Cambodia',
		iso: 'KH',
		isO3: 'KHM',
		countryCode: 116,
		phoneCode: 855,
	},
	{
		id: 'f797297d-34de-4de7-9dd0-b758650a7443',
		name: 'Cameroon',
		friendlyName: 'Cameroon',
		iso: 'CM',
		isO3: 'CMR',
		countryCode: 120,
		phoneCode: 237,
	},
	{
		id: '437eff71-4259-464b-aa31-29713e40712d',
		name: 'Canada',
		friendlyName: 'Canada',
		iso: 'CA',
		isO3: 'CAN',
		countryCode: 124,
		phoneCode: 1,
	},
	{
		id: 'a22ff2a7-ab4d-4a41-ac50-91cf88a2bb5a',
		name: 'Cape Verde',
		friendlyName: 'Cape Verde',
		iso: 'CV',
		isO3: 'CPV',
		countryCode: 132,
		phoneCode: 238,
	},
	{
		id: 'b3d1e14f-2d2c-4f2f-a3e3-7ca76a9f8364',
		name: 'Cayman Islands',
		friendlyName: 'Cayman Islands',
		iso: 'KY',
		isO3: 'CYM',
		countryCode: 136,
		phoneCode: 1345,
	},
	{
		id: '933b5001-7dec-4bbd-a3fe-6a9595b803f3',
		name: 'Central African Republic',
		friendlyName: 'Central African Republic',
		iso: 'CF',
		isO3: 'CAF',
		countryCode: 140,
		phoneCode: 236,
	},
	{
		id: '3b08b249-6d75-4fd2-9c6b-7eca4b62adda',
		name: 'Chad',
		friendlyName: 'Chad',
		iso: 'TD',
		isO3: 'TCD',
		countryCode: 148,
		phoneCode: 235,
	},
	{
		id: '53a5b29d-586f-4837-85aa-7d373ecb4b6b',
		name: 'Chile',
		friendlyName: 'Chile',
		iso: 'CL',
		isO3: 'CHL',
		countryCode: 152,
		phoneCode: 56,
	},
	{
		id: '260ae243-70f3-47d0-8a54-bef49972d822',
		name: 'China',
		friendlyName: 'China',
		iso: 'CN',
		isO3: 'CHN',
		countryCode: 156,
		phoneCode: 86,
	},
	{
		id: '9dc706ed-2f39-42ee-abbb-3c8dece76ecb',
		name: 'Christmas Island',
		friendlyName: 'Christmas Island',
		iso: 'CX',
		isO3: null,
		countryCode: null,
		phoneCode: 61,
	},
	{
		id: 'e9bc1846-6825-4a15-a340-5afad0a2a1c8',
		name: 'Cocos (Keeling) Islands',
		friendlyName: 'Cocos (Keeling) Islands',
		iso: 'CC',
		isO3: null,
		countryCode: null,
		phoneCode: 672,
	},
	{
		id: '1fe26c9c-a8ab-4f87-bc10-bb4d80b73aaa',
		name: 'Colombia',
		friendlyName: 'Colombia',
		iso: 'CO',
		isO3: 'COL',
		countryCode: 170,
		phoneCode: 57,
	},
	{
		id: '34f36034-6d12-454c-8117-cba30705d536',
		name: 'Comoros',
		friendlyName: 'Comoros',
		iso: 'KM',
		isO3: 'COM',
		countryCode: 174,
		phoneCode: 269,
	},
	{
		id: 'f7b6f94d-4e2f-40be-8425-826b02783eae',
		name: 'Congo',
		friendlyName: 'Congo',
		iso: 'CG',
		isO3: 'COG',
		countryCode: 178,
		phoneCode: 242,
	},
	{
		id: '49f7cfb2-0a48-4709-8270-01ebcc8ab541',
		name: 'Congo, the Democratic Republic of the',
		friendlyName: 'Congo, the Democratic Republic of the',
		iso: 'CD',
		isO3: 'COD',
		countryCode: 180,
		phoneCode: 242,
	},
	{
		id: '6f10c853-2556-4bc0-988a-e544ce6f049b',
		name: 'Cook Islands',
		friendlyName: 'Cook Islands',
		iso: 'CK',
		isO3: 'COK',
		countryCode: 184,
		phoneCode: 682,
	},
	{
		id: '2dfd33a3-d7bd-421a-a17c-a50c2be39c69',
		name: 'Costa Rica',
		friendlyName: 'Costa Rica',
		iso: 'CR',
		isO3: 'CRI',
		countryCode: 188,
		phoneCode: 506,
	},
	{
		id: 'd0a963e9-8759-4df2-a732-fa382a992daf',
		name: "Cote D'Ivoire",
		friendlyName: "Cote D'Ivoire",
		iso: 'CI',
		isO3: 'CIV',
		countryCode: 384,
		phoneCode: 225,
	},
	{
		id: '16516389-0dbd-4203-961a-df8ee5300762',
		name: 'Croatia',
		friendlyName: 'Croatia',
		iso: 'HR',
		isO3: 'HRV',
		countryCode: 191,
		phoneCode: 385,
	},
	{
		id: '728b4701-1662-4359-8e91-647cebd2bdbb',
		name: 'Cuba',
		friendlyName: 'Cuba',
		iso: 'CU',
		isO3: 'CUB',
		countryCode: 192,
		phoneCode: 53,
	},
	{
		id: '1406a7ad-01f5-47f5-98da-f7393bf09938',
		name: 'Cyprus',
		friendlyName: 'Cyprus',
		iso: 'CY',
		isO3: 'CYP',
		countryCode: 196,
		phoneCode: 357,
	},
	{
		id: '5cad661a-4d8a-4772-9768-810846af6b02',
		name: 'Czech Republic',
		friendlyName: 'Czech Republic',
		iso: 'CZ',
		isO3: 'CZE',
		countryCode: 203,
		phoneCode: 420,
	},
	{
		id: '124333e0-148f-4fe6-b1ce-d682caa80dcc',
		name: 'Denmark',
		friendlyName: 'Denmark',
		iso: 'DK',
		isO3: 'DNK',
		countryCode: 208,
		phoneCode: 45,
	},
	{
		id: '7029252b-afed-44f9-87b2-a85c4be9422e',
		name: 'Djibouti',
		friendlyName: 'Djibouti',
		iso: 'DJ',
		isO3: 'DJI',
		countryCode: 262,
		phoneCode: 253,
	},
	{
		id: '6d2ed2cd-34cf-4ad4-896a-91b5d7fdbc0f',
		name: 'Dominica',
		friendlyName: 'Dominica',
		iso: 'DM',
		isO3: 'DMA',
		countryCode: 212,
		phoneCode: 1767,
	},
	{
		id: 'f82f082e-1955-4eff-84c4-d5841a79daa6',
		name: 'Dominican Republic',
		friendlyName: 'Dominican Republic',
		iso: 'DO',
		isO3: 'DOM',
		countryCode: 214,
		phoneCode: 1809,
	},
	{
		id: '98fa9885-e2ce-4510-a561-e16692dbc482',
		name: 'Ecuador',
		friendlyName: 'Ecuador',
		iso: 'EC',
		isO3: 'ECU',
		countryCode: 218,
		phoneCode: 593,
	},
	{
		id: '6b360d2e-a3f8-44bb-a045-d27541a1eee9',
		name: 'Egypt',
		friendlyName: 'Egypt',
		iso: 'EG',
		isO3: 'EGY',
		countryCode: 818,
		phoneCode: 20,
	},
	{
		id: '70afeb2a-5e8c-4128-b294-459ff3be12a4',
		name: 'El Salvador',
		friendlyName: 'El Salvador',
		iso: 'SV',
		isO3: 'SLV',
		countryCode: 222,
		phoneCode: 503,
	},
	{
		id: 'c6f08fb2-28ed-48f2-aa41-01303946eec8',
		name: 'Equatorial Guinea',
		friendlyName: 'Equatorial Guinea',
		iso: 'GQ',
		isO3: 'GNQ',
		countryCode: 226,
		phoneCode: 240,
	},
	{
		id: 'caff65dc-9288-4cc9-b51f-f4bba2030be9',
		name: 'Eritrea',
		friendlyName: 'Eritrea',
		iso: 'ER',
		isO3: 'ERI',
		countryCode: 232,
		phoneCode: 291,
	},
	{
		id: 'd1fac9fa-03a5-4a63-97fb-6b7726d2bfa3',
		name: 'Estonia',
		friendlyName: 'Estonia',
		iso: 'EE',
		isO3: 'EST',
		countryCode: 233,
		phoneCode: 372,
	},
	{
		id: '296b5e9e-7cfd-4eba-b73b-ea4e19c04c78',
		name: 'Ethiopia',
		friendlyName: 'Ethiopia',
		iso: 'ET',
		isO3: 'ETH',
		countryCode: 231,
		phoneCode: 251,
	},
	{
		id: '58a0af31-0d9c-4bde-a4f5-85ed6fabc95e',
		name: 'Falkland Islands (Malvinas)',
		friendlyName: 'Falkland Islands (Malvinas)',
		iso: 'FK',
		isO3: 'FLK',
		countryCode: 238,
		phoneCode: 500,
	},
	{
		id: '26a9103e-8df2-4193-b7ee-cd466e03db55',
		name: 'Faroe Islands',
		friendlyName: 'Faroe Islands',
		iso: 'FO',
		isO3: 'FRO',
		countryCode: 234,
		phoneCode: 298,
	},
	{
		id: '0253607d-c680-458b-aaac-00c6a370938e',
		name: 'Fiji',
		friendlyName: 'Fiji',
		iso: 'FJ',
		isO3: 'FJI',
		countryCode: 242,
		phoneCode: 679,
	},
	{
		id: '11d7d1d7-d6b0-4ec7-8d63-b2bb08fedd4e',
		name: 'Finland',
		friendlyName: 'Finland',
		iso: 'FI',
		isO3: 'FIN',
		countryCode: 246,
		phoneCode: 358,
	},
	{
		id: '6a47689f-ef03-4640-8b20-e32e507f90c0',
		name: 'France',
		friendlyName: 'France',
		iso: 'FR',
		isO3: 'FRA',
		countryCode: 250,
		phoneCode: 33,
	},
	{
		id: '57f90c92-9f38-40fc-a96d-3036858d4f09',
		name: 'French Guiana',
		friendlyName: 'French Guiana',
		iso: 'GF',
		isO3: 'GUF',
		countryCode: 254,
		phoneCode: 594,
	},
	{
		id: '61448370-bfc0-4253-b86c-d46e9047422c',
		name: 'French Polynesia',
		friendlyName: 'French Polynesia',
		iso: 'PF',
		isO3: 'PYF',
		countryCode: 258,
		phoneCode: 689,
	},
	{
		id: 'a698d8d0-3a28-4eaf-9619-57b03aea05a8',
		name: 'French Southern Territories',
		friendlyName: 'French Southern Territories',
		iso: 'TF',
		isO3: null,
		countryCode: null,
		phoneCode: 0,
	},
	{
		id: '54582dcb-186e-4502-813a-917dbc06fa54',
		name: 'Gabon',
		friendlyName: 'Gabon',
		iso: 'GA',
		isO3: 'GAB',
		countryCode: 266,
		phoneCode: 241,
	},
	{
		id: '6dcd9292-7cbc-436f-8ad9-d45bfaeb4359',
		name: 'Gambia',
		friendlyName: 'Gambia',
		iso: 'GM',
		isO3: 'GMB',
		countryCode: 270,
		phoneCode: 220,
	},
	{
		id: 'b897d8f3-c195-46f1-ab3d-d474add20427',
		name: 'Georgia',
		friendlyName: 'Georgia',
		iso: 'GE',
		isO3: 'GEO',
		countryCode: 268,
		phoneCode: 995,
	},
	{
		id: '53b62232-c825-4c33-976a-4819a9d08c9a',
		name: 'Germany',
		friendlyName: 'Germany',
		iso: 'DE',
		isO3: 'DEU',
		countryCode: 276,
		phoneCode: 49,
	},
	{
		id: '5e27ea09-8b04-4820-bfa0-f6f294d604b9',
		name: 'Ghana',
		friendlyName: 'Ghana',
		iso: 'GH',
		isO3: 'GHA',
		countryCode: 288,
		phoneCode: 233,
	},
	{
		id: '0fe69d5b-cf07-4aca-a1a4-89b1100faeeb',
		name: 'Gibraltar',
		friendlyName: 'Gibraltar',
		iso: 'GI',
		isO3: 'GIB',
		countryCode: 292,
		phoneCode: 350,
	},
	{
		id: '6b3a6b38-bf9a-4655-b219-fad13e63dfc7',
		name: 'Greece',
		friendlyName: 'Greece',
		iso: 'GR',
		isO3: 'GRC',
		countryCode: 300,
		phoneCode: 30,
	},
	{
		id: '070d6a34-0982-4f5d-8401-f7f71297b494',
		name: 'Greenland',
		friendlyName: 'Greenland',
		iso: 'GL',
		isO3: 'GRL',
		countryCode: 304,
		phoneCode: 299,
	},
	{
		id: '004909f2-1fdd-4571-9870-17cff5e5d104',
		name: 'Grenada',
		friendlyName: 'Grenada',
		iso: 'GD',
		isO3: 'GRD',
		countryCode: 308,
		phoneCode: 1473,
	},
	{
		id: '68853419-0010-4f02-a7bb-8bf85a217526',
		name: 'Guadeloupe',
		friendlyName: 'Guadeloupe',
		iso: 'GP',
		isO3: 'GLP',
		countryCode: 312,
		phoneCode: 590,
	},
	{
		id: '86fda87e-d1f8-42d0-9b7c-3b18c4d39515',
		name: 'Guam',
		friendlyName: 'Guam',
		iso: 'GU',
		isO3: 'GUM',
		countryCode: 316,
		phoneCode: 1671,
	},
	{
		id: '8b094543-7280-49eb-be73-bde14f233892',
		name: 'Guatemala',
		friendlyName: 'Guatemala',
		iso: 'GT',
		isO3: 'GTM',
		countryCode: 320,
		phoneCode: 502,
	},
	{
		id: 'fa57e7c4-588c-4120-81fb-252dd4ddcfd0',
		name: 'Guinea',
		friendlyName: 'Guinea',
		iso: 'GN',
		isO3: 'GIN',
		countryCode: 324,
		phoneCode: 224,
	},
	{
		id: '7f5b95dd-47a1-42f9-b6d8-260d098387f3',
		name: 'Guinea-Bissau',
		friendlyName: 'Guinea-Bissau',
		iso: 'GW',
		isO3: 'GNB',
		countryCode: 624,
		phoneCode: 245,
	},
	{
		id: 'fc883fb7-5fd1-410b-b9ee-e36456b27f97',
		name: 'Guyana',
		friendlyName: 'Guyana',
		iso: 'GY',
		isO3: 'GUY',
		countryCode: 328,
		phoneCode: 592,
	},
	{
		id: 'cb660752-d656-4dee-8ccc-8eed1eea6720',
		name: 'Haiti',
		friendlyName: 'Haiti',
		iso: 'HT',
		isO3: 'HTI',
		countryCode: 332,
		phoneCode: 509,
	},
	{
		id: 'c9ff93b4-a01b-487f-bf98-4f2d4d66568f',
		name: 'Heard Island and Mcdonald Islands',
		friendlyName: 'Heard Island and Mcdonald Islands',
		iso: 'HM',
		isO3: null,
		countryCode: null,
		phoneCode: 0,
	},
	{
		id: '2b6b3b52-605c-408a-aa84-1c0fae3dbf17',
		name: 'Holy See (Vatican City State)',
		friendlyName: 'Holy See (Vatican City State)',
		iso: 'VA',
		isO3: 'VAT',
		countryCode: 336,
		phoneCode: 39,
	},
	{
		id: 'd05098d6-5965-46ad-9c0c-c3199809530a',
		name: 'Honduras',
		friendlyName: 'Honduras',
		iso: 'HN',
		isO3: 'HND',
		countryCode: 340,
		phoneCode: 504,
	},
	{
		id: 'ea310335-97fa-4598-b514-bb2ffd6d5918',
		name: 'Hong Kong',
		friendlyName: 'Hong Kong',
		iso: 'HK',
		isO3: 'HKG',
		countryCode: 344,
		phoneCode: 852,
	},
	{
		id: 'd40a3d06-f06e-43b5-b5c4-2720619616ec',
		name: 'Hungary',
		friendlyName: 'Hungary',
		iso: 'HU',
		isO3: 'HUN',
		countryCode: 348,
		phoneCode: 36,
	},
	{
		id: '1e61723c-60cf-431d-8f55-3d0962851a85',
		name: 'Iceland',
		friendlyName: 'Iceland',
		iso: 'IS',
		isO3: 'ISL',
		countryCode: 352,
		phoneCode: 354,
	},
	{
		id: '32923126-f3c1-4e14-9b2b-096bddb4466e',
		name: 'India',
		friendlyName: 'India',
		iso: 'IN',
		isO3: 'IND',
		countryCode: 356,
		phoneCode: 91,
	},
	{
		id: '9d53543d-15c3-47ab-a800-cc9ef32b2724',
		name: 'Indonesia',
		friendlyName: 'Indonesia',
		iso: 'ID',
		isO3: 'IDN',
		countryCode: 360,
		phoneCode: 62,
	},
	{
		id: '6e839934-1c3c-4c4e-bedb-81ecbfda479f',
		name: 'Iran, Islamic Republic of',
		friendlyName: 'Iran, Islamic Republic of',
		iso: 'IR',
		isO3: 'IRN',
		countryCode: 364,
		phoneCode: 98,
	},
	{
		id: '827d3770-0ab3-4210-8f7d-3c2962ea503f',
		name: 'Iraq',
		friendlyName: 'Iraq',
		iso: 'IQ',
		isO3: 'IRQ',
		countryCode: 368,
		phoneCode: 964,
	},
	{
		id: '725d7ca2-32c7-49e2-95c8-37c1e6d90fd0',
		name: 'Ireland',
		friendlyName: 'Ireland',
		iso: 'IE',
		isO3: 'IRL',
		countryCode: 372,
		phoneCode: 353,
	},
	{
		id: '9157088c-b029-45ec-8a71-5ea18967ab40',
		name: 'Israel',
		friendlyName: 'Israel',
		iso: 'IL',
		isO3: 'ISR',
		countryCode: 376,
		phoneCode: 972,
	},
	{
		id: '9b5ae0c7-51f7-4c47-8188-3cab43c37a17',
		name: 'Italy',
		friendlyName: 'Italy',
		iso: 'IT',
		isO3: 'ITA',
		countryCode: 380,
		phoneCode: 39,
	},
	{
		id: 'd324dd07-7105-486e-b21a-5d15ef90b2ed',
		name: 'Jamaica',
		friendlyName: 'Jamaica',
		iso: 'JM',
		isO3: 'JAM',
		countryCode: 388,
		phoneCode: 1876,
	},
	{
		id: '7f25391a-849b-4aaf-8555-6c920eaa33fb',
		name: 'Japan',
		friendlyName: 'Japan',
		iso: 'JP',
		isO3: 'JPN',
		countryCode: 392,
		phoneCode: 81,
	},
	{
		id: '1cd15b0a-d668-495a-b58e-2ae372f45eb8',
		name: 'Jordan',
		friendlyName: 'Jordan',
		iso: 'JO',
		isO3: 'JOR',
		countryCode: 400,
		phoneCode: 962,
	},
	{
		id: '284fb4f2-9d1c-47a5-9197-b22fb70d6d8a',
		name: 'Kazakhstan',
		friendlyName: 'Kazakhstan',
		iso: 'KZ',
		isO3: 'KAZ',
		countryCode: 398,
		phoneCode: 7,
	},
	{
		id: '0ed67e11-bc5d-4253-9591-27d6ffaf3011',
		name: 'Kenya',
		friendlyName: 'Kenya',
		iso: 'KE',
		isO3: 'KEN',
		countryCode: 404,
		phoneCode: 254,
	},
	{
		id: 'a12ae8da-d3df-42ff-8342-ad501aa82eda',
		name: 'Kiribati',
		friendlyName: 'Kiribati',
		iso: 'KI',
		isO3: 'KIR',
		countryCode: 296,
		phoneCode: 686,
	},
	{
		id: 'af443457-8d6c-4651-bd74-9ce40264365b',
		name: "Korea, Democratic People's Republic of",
		friendlyName: "Korea, Democratic People's Republic of",
		iso: 'KP',
		isO3: 'PRK',
		countryCode: 408,
		phoneCode: 850,
	},
	{
		id: 'e7247683-9c7d-49c9-a15d-64769a79951d',
		name: 'Korea, Republic of',
		friendlyName: 'Korea, Republic of',
		iso: 'KR',
		isO3: 'KOR',
		countryCode: 410,
		phoneCode: 82,
	},
	{
		id: 'c4800def-f6d5-436a-8408-260a15ae421a',
		name: 'Kuwait',
		friendlyName: 'Kuwait',
		iso: 'KW',
		isO3: 'KWT',
		countryCode: 414,
		phoneCode: 965,
	},
	{
		id: '14989fd3-86c5-4e3c-8d6f-5a9beca66a8c',
		name: 'Kyrgyzstan',
		friendlyName: 'Kyrgyzstan',
		iso: 'KG',
		isO3: 'KGZ',
		countryCode: 417,
		phoneCode: 996,
	},
	{
		id: 'ae9a8c78-34c1-43e7-8a77-cce1829e9fdd',
		name: "Lao People's Democratic Republic",
		friendlyName: "Lao People's Democratic Republic",
		iso: 'LA',
		isO3: 'LAO',
		countryCode: 418,
		phoneCode: 856,
	},
	{
		id: '297d6730-c575-4a5c-8bce-a564cfb2921c',
		name: 'Latvia',
		friendlyName: 'Latvia',
		iso: 'LV',
		isO3: 'LVA',
		countryCode: 428,
		phoneCode: 371,
	},
	{
		id: 'e5766b55-cfdb-442d-be68-1153bee75048',
		name: 'Lebanon',
		friendlyName: 'Lebanon',
		iso: 'LB',
		isO3: 'LBN',
		countryCode: 422,
		phoneCode: 961,
	},
	{
		id: '81c32747-391f-487c-9fea-0327b1b395d2',
		name: 'Lesotho',
		friendlyName: 'Lesotho',
		iso: 'LS',
		isO3: 'LSO',
		countryCode: 426,
		phoneCode: 266,
	},
	{
		id: 'f609aaeb-1721-4df7-be1f-722948497844',
		name: 'Liberia',
		friendlyName: 'Liberia',
		iso: 'LR',
		isO3: 'LBR',
		countryCode: 430,
		phoneCode: 231,
	},
	{
		id: 'c6dae06c-1e2d-440a-b82b-746278cd6ba4',
		name: 'Libyan Arab Jamahiriya',
		friendlyName: 'Libyan Arab Jamahiriya',
		iso: 'LY',
		isO3: 'LBY',
		countryCode: 434,
		phoneCode: 218,
	},
	{
		id: '57fbd018-5303-4afa-9f84-cb3bf5a0a282',
		name: 'Liechtenstein',
		friendlyName: 'Liechtenstein',
		iso: 'LI',
		isO3: 'LIE',
		countryCode: 438,
		phoneCode: 423,
	},
	{
		id: '270cc7fa-e162-44f5-aeaa-ae79911d0c8f',
		name: 'Lithuania',
		friendlyName: 'Lithuania',
		iso: 'LT',
		isO3: 'LTU',
		countryCode: 440,
		phoneCode: 370,
	},
	{
		id: '0e32b7ca-29b5-4a91-b5bb-b65512ee4933',
		name: 'Luxembourg',
		friendlyName: 'Luxembourg',
		iso: 'LU',
		isO3: 'LUX',
		countryCode: 442,
		phoneCode: 352,
	},
	{
		id: '7e1a7105-69a3-4199-900d-cfa3a80dabb3',
		name: 'Macao',
		friendlyName: 'Macao',
		iso: 'MO',
		isO3: 'MAC',
		countryCode: 446,
		phoneCode: 853,
	},
	{
		id: '0fd621f3-e48f-4206-afd4-13f3716d8d29',
		name: 'Macedonia, the Former Yugoslav Republic of',
		friendlyName: 'Macedonia, the Former Yugoslav Republic of',
		iso: 'MK',
		isO3: 'MKD',
		countryCode: 807,
		phoneCode: 389,
	},
	{
		id: 'a7ff42c8-fc11-4459-9a74-ac5e179723ef',
		name: 'Madagascar',
		friendlyName: 'Madagascar',
		iso: 'MG',
		isO3: 'MDG',
		countryCode: 450,
		phoneCode: 261,
	},
	{
		id: '0498795a-e770-4603-932b-565d04b9e90d',
		name: 'Malawi',
		friendlyName: 'Malawi',
		iso: 'MW',
		isO3: 'MWI',
		countryCode: 454,
		phoneCode: 265,
	},
	{
		id: '305446a1-4cb9-4a21-b2ac-3d7cec0f893f',
		name: 'Malaysia',
		friendlyName: 'Malaysia',
		iso: 'MY',
		isO3: 'MYS',
		countryCode: 458,
		phoneCode: 60,
	},
	{
		id: '22fb6a1c-7cf6-48a9-bd50-6ea05c516b35',
		name: 'Maldives',
		friendlyName: 'Maldives',
		iso: 'MV',
		isO3: 'MDV',
		countryCode: 462,
		phoneCode: 960,
	},
	{
		id: 'a87def84-5a64-4266-82fc-594634d93879',
		name: 'Mali',
		friendlyName: 'Mali',
		iso: 'ML',
		isO3: 'MLI',
		countryCode: 466,
		phoneCode: 223,
	},
	{
		id: 'ecc27493-6f44-4124-8491-9ded25128953',
		name: 'Malta',
		friendlyName: 'Malta',
		iso: 'MT',
		isO3: 'MLT',
		countryCode: 470,
		phoneCode: 356,
	},
	{
		id: '74f2ec02-75a1-4e36-8aaf-a56d0d513e51',
		name: 'Marshall Islands',
		friendlyName: 'Marshall Islands',
		iso: 'MH',
		isO3: 'MHL',
		countryCode: 584,
		phoneCode: 692,
	},
	{
		id: '9ab7c797-9a7f-4614-8fcc-b532d8f84356',
		name: 'Martinique',
		friendlyName: 'Martinique',
		iso: 'MQ',
		isO3: 'MTQ',
		countryCode: 474,
		phoneCode: 596,
	},
	{
		id: '041aa2dc-4802-4efa-983d-722946e2807a',
		name: 'Mauritania',
		friendlyName: 'Mauritania',
		iso: 'MR',
		isO3: 'MRT',
		countryCode: 478,
		phoneCode: 222,
	},
	{
		id: '057f8348-a3e9-414d-a9ff-3b0c67054b96',
		name: 'Mauritius',
		friendlyName: 'Mauritius',
		iso: 'MU',
		isO3: 'MUS',
		countryCode: 480,
		phoneCode: 230,
	},
	{
		id: '0def9965-377a-40f1-80eb-91d05c4ee989',
		name: 'Mayotte',
		friendlyName: 'Mayotte',
		iso: 'YT',
		isO3: null,
		countryCode: null,
		phoneCode: 269,
	},
	{
		id: 'd9074a97-ec07-4c07-a562-f61434fc66cf',
		name: 'Mexico',
		friendlyName: 'Mexico',
		iso: 'MX',
		isO3: 'MEX',
		countryCode: 484,
		phoneCode: 52,
	},
	{
		id: 'b39f28b1-7726-42d4-aaaa-124ec00f05b6',
		name: 'Micronesia, Federated States of',
		friendlyName: 'Micronesia, Federated States of',
		iso: 'FM',
		isO3: 'FSM',
		countryCode: 583,
		phoneCode: 691,
	},
	{
		id: '67b50405-ea99-413a-9162-07adbca33f8b',
		name: 'Moldova, Republic of',
		friendlyName: 'Moldova, Republic of',
		iso: 'MD',
		isO3: 'MDA',
		countryCode: 498,
		phoneCode: 373,
	},
	{
		id: '32a6afa1-5c31-4198-bc69-d35679b6c249',
		name: 'Monaco',
		friendlyName: 'Monaco',
		iso: 'MC',
		isO3: 'MCO',
		countryCode: 492,
		phoneCode: 377,
	},
	{
		id: '08e1796c-a7c9-407c-9ca4-b3cf6a2979c8',
		name: 'Mongolia',
		friendlyName: 'Mongolia',
		iso: 'MN',
		isO3: 'MNG',
		countryCode: 496,
		phoneCode: 976,
	},
	{
		id: '906c81eb-4e2b-4b32-95f7-4e0aa905e15d',
		name: 'Montserrat',
		friendlyName: 'Montserrat',
		iso: 'MS',
		isO3: 'MSR',
		countryCode: 500,
		phoneCode: 1664,
	},
	{
		id: '01fd8f7c-4376-4389-842b-53e4c2522633',
		name: 'Morocco',
		friendlyName: 'Morocco',
		iso: 'MA',
		isO3: 'MAR',
		countryCode: 504,
		phoneCode: 212,
	},
	{
		id: '95f8566b-afb4-4b50-bb3f-af951e8992ca',
		name: 'Mozambique',
		friendlyName: 'Mozambique',
		iso: 'MZ',
		isO3: 'MOZ',
		countryCode: 508,
		phoneCode: 258,
	},
	{
		id: '0c4cbe76-3a3d-41c0-bc13-d169dc053dff',
		name: 'Myanmar',
		friendlyName: 'Myanmar',
		iso: 'MM',
		isO3: 'MMR',
		countryCode: 104,
		phoneCode: 95,
	},
	{
		id: '2474069f-7df5-4cd4-9abb-b145e7020812',
		name: 'Namibia',
		friendlyName: 'Namibia',
		iso: 'NA',
		isO3: 'NAM',
		countryCode: 516,
		phoneCode: 264,
	},
	{
		id: '81d89090-3441-4c46-b3c0-16f54692a172',
		name: 'Nauru',
		friendlyName: 'Nauru',
		iso: 'NR',
		isO3: 'NRU',
		countryCode: 520,
		phoneCode: 674,
	},
	{
		id: '008b852c-f450-456e-9422-10fa1abe09eb',
		name: 'Nepal',
		friendlyName: 'Nepal',
		iso: 'NP',
		isO3: 'NPL',
		countryCode: 524,
		phoneCode: 977,
	},
	{
		id: '6ed3b739-40ed-4eaa-ad38-3bff0b2e87b8',
		name: 'Netherlands',
		friendlyName: 'Netherlands',
		iso: 'NL',
		isO3: 'NLD',
		countryCode: 528,
		phoneCode: 31,
	},
	{
		id: 'a7a7518f-f70e-4f81-a0a8-3c4335f8926f',
		name: 'Netherlands Antilles',
		friendlyName: 'Netherlands Antilles',
		iso: 'AN',
		isO3: 'ANT',
		countryCode: 530,
		phoneCode: 599,
	},
	{
		id: '3fd6ec7b-dfb4-42a4-b3d4-807234914d20',
		name: 'New Caledonia',
		friendlyName: 'New Caledonia',
		iso: 'NC',
		isO3: 'NCL',
		countryCode: 540,
		phoneCode: 687,
	},
	{
		id: '12017638-55aa-478c-9506-d1567685ae9f',
		name: 'New Zealand',
		friendlyName: 'New Zealand',
		iso: 'NZ',
		isO3: 'NZL',
		countryCode: 554,
		phoneCode: 64,
	},
	{
		id: '4a68f663-081f-4d7d-b294-cfeafb4bf0d9',
		name: 'Nicaragua',
		friendlyName: 'Nicaragua',
		iso: 'NI',
		isO3: 'NIC',
		countryCode: 558,
		phoneCode: 505,
	},
	{
		id: 'cb355d1f-b8d5-40e0-888b-ea136eec0887',
		name: 'Niger',
		friendlyName: 'Niger',
		iso: 'NE',
		isO3: 'NER',
		countryCode: 562,
		phoneCode: 227,
	},
	{
		id: '2d1c3cdb-c4f2-4db1-8f48-9adf4828a38f',
		name: 'Nigeria',
		friendlyName: 'Nigeria',
		iso: 'NG',
		isO3: 'NGA',
		countryCode: 566,
		phoneCode: 234,
	},
	{
		id: '6695e361-7b04-47f2-8155-cdebb3f9077a',
		name: 'Niue',
		friendlyName: 'Niue',
		iso: 'NU',
		isO3: 'NIU',
		countryCode: 570,
		phoneCode: 683,
	},
	{
		id: 'e6ec59d7-2325-4319-9773-8148a70ab6d0',
		name: 'Norfolk Island',
		friendlyName: 'Norfolk Island',
		iso: 'NF',
		isO3: 'NFK',
		countryCode: 574,
		phoneCode: 672,
	},
	{
		id: '65566b99-7ab6-452a-80fd-ec9f6da2d2cf',
		name: 'Northern Mariana Islands',
		friendlyName: 'Northern Mariana Islands',
		iso: 'MP',
		isO3: 'MNP',
		countryCode: 580,
		phoneCode: 1670,
	},
	{
		id: '809328a3-dd64-4880-826e-3fa626fa9076',
		name: 'Norway',
		friendlyName: 'Norway',
		iso: 'NO',
		isO3: 'NOR',
		countryCode: 578,
		phoneCode: 47,
	},
	{
		id: '533ec0b8-2d81-4f5c-80e3-ecda38ae6941',
		name: 'Oman',
		friendlyName: 'Oman',
		iso: 'OM',
		isO3: 'OMN',
		countryCode: 512,
		phoneCode: 968,
	},
	{
		id: '02e2a11b-d71f-4c2a-be4c-2ecf38a64690',
		name: 'Pakistan',
		friendlyName: 'Pakistan',
		iso: 'PK',
		isO3: 'PAK',
		countryCode: 586,
		phoneCode: 92,
	},
	{
		id: '80c56fb7-0613-4091-bf70-d2b88a3ec22c',
		name: 'Palau',
		friendlyName: 'Palau',
		iso: 'PW',
		isO3: 'PLW',
		countryCode: 585,
		phoneCode: 680,
	},
	{
		id: '562a5acc-dfa7-4728-9bf4-c5123e6976d7',
		name: 'Palestinian Territory, Occupied',
		friendlyName: 'Palestinian Territory, Occupied',
		iso: 'PS',
		isO3: null,
		countryCode: null,
		phoneCode: 970,
	},
	{
		id: '6fd0098d-84da-4534-b6b1-4fafe41ff2a2',
		name: 'Panama',
		friendlyName: 'Panama',
		iso: 'PA',
		isO3: 'PAN',
		countryCode: 591,
		phoneCode: 507,
	},
	{
		id: '8a7e30ab-cf0e-4d1b-9409-e8241a674da2',
		name: 'Papua New Guinea',
		friendlyName: 'Papua New Guinea',
		iso: 'PG',
		isO3: 'PNG',
		countryCode: 598,
		phoneCode: 675,
	},
	{
		id: '86ad30fc-dc82-409e-96e7-02c08b545dbd',
		name: 'Paraguay',
		friendlyName: 'Paraguay',
		iso: 'PY',
		isO3: 'PRY',
		countryCode: 600,
		phoneCode: 595,
	},
	{
		id: '830f5cfb-6457-42dd-b523-38a229e3edc8',
		name: 'Peru',
		friendlyName: 'Peru',
		iso: 'PE',
		isO3: 'PER',
		countryCode: 604,
		phoneCode: 51,
	},
	{
		id: 'abfed238-f016-4d71-b431-a2dd2d0679a3',
		name: 'Philippines',
		friendlyName: 'Philippines',
		iso: 'PH',
		isO3: 'PHL',
		countryCode: 608,
		phoneCode: 63,
	},
	{
		id: '1f627e67-9800-4057-bcea-b9eda56d2548',
		name: 'Pitcairn',
		friendlyName: 'Pitcairn',
		iso: 'PN',
		isO3: 'PCN',
		countryCode: 612,
		phoneCode: 0,
	},
	{
		id: '0d3fd2e5-43ea-4840-bda4-533e23dc28f5',
		name: 'Poland',
		friendlyName: 'Poland',
		iso: 'PL',
		isO3: 'POL',
		countryCode: 616,
		phoneCode: 48,
	},
	{
		id: 'cfd97db7-e1e8-4b0c-bb9f-c133945f26b7',
		name: 'Portugal',
		friendlyName: 'Portugal',
		iso: 'PT',
		isO3: 'PRT',
		countryCode: 620,
		phoneCode: 351,
	},
	{
		id: '2c54d23f-4497-443f-aa00-ea4f8562837a',
		name: 'Puerto Rico',
		friendlyName: 'Puerto Rico',
		iso: 'PR',
		isO3: 'PRI',
		countryCode: 630,
		phoneCode: 1787,
	},
	{
		id: '4375ffec-bb21-40d6-ab3d-dd8faf170faf',
		name: 'Qatar',
		friendlyName: 'Qatar',
		iso: 'QA',
		isO3: 'QAT',
		countryCode: 634,
		phoneCode: 974,
	},
	{
		id: '995a776e-8272-4913-8a42-e7620171f13f',
		name: 'Reunion',
		friendlyName: 'Reunion',
		iso: 'RE',
		isO3: 'REU',
		countryCode: 638,
		phoneCode: 262,
	},
	{
		id: '476d75d7-49df-40b9-b96e-935ebd412f36',
		name: 'Romania',
		friendlyName: 'Romania',
		iso: 'RO',
		isO3: 'ROM',
		countryCode: 642,
		phoneCode: 40,
	},
	{
		id: '4ab87567-a983-4fef-8394-334292df3b4a',
		name: 'Russian Federation',
		friendlyName: 'Russian Federation',
		iso: 'RU',
		isO3: 'RUS',
		countryCode: 643,
		phoneCode: 70,
	},
	{
		id: '120f2c43-5d52-4e68-b38a-cf1bc991beb3',
		name: 'Rwanda',
		friendlyName: 'Rwanda',
		iso: 'RW',
		isO3: 'RWA',
		countryCode: 646,
		phoneCode: 250,
	},
	{
		id: 'bd825147-db57-44d4-9cb2-1e1943eb2d8f',
		name: 'Saint Helena',
		friendlyName: 'Saint Helena',
		iso: 'SH',
		isO3: 'SHN',
		countryCode: 654,
		phoneCode: 290,
	},
	{
		id: '4b04235f-82fe-4cbd-b86f-39873b720d64',
		name: 'Saint Kitts and Nevis',
		friendlyName: 'Saint Kitts and Nevis',
		iso: 'KN',
		isO3: 'KNA',
		countryCode: 659,
		phoneCode: 1869,
	},
	{
		id: '04ce36e9-933e-4e41-84d1-cd1595a81a03',
		name: 'Saint Lucia',
		friendlyName: 'Saint Lucia',
		iso: 'LC',
		isO3: 'LCA',
		countryCode: 662,
		phoneCode: 1758,
	},
	{
		id: '15c1df6a-7e2d-4e9a-ba40-c6a4e2e0a834',
		name: 'Saint Pierre and Miquelon',
		friendlyName: 'Saint Pierre and Miquelon',
		iso: 'PM',
		isO3: 'SPM',
		countryCode: 666,
		phoneCode: 508,
	},
	{
		id: 'fbabf4bf-a4cd-4eda-b697-7281d6ec4b51',
		name: 'Saint Vincent and the Grenadines',
		friendlyName: 'Saint Vincent and the Grenadines',
		iso: 'VC',
		isO3: 'VCT',
		countryCode: 670,
		phoneCode: 1784,
	},
	{
		id: 'a1a502c7-5162-49e3-831b-70b3b2d3124e',
		name: 'Samoa',
		friendlyName: 'Samoa',
		iso: 'WS',
		isO3: 'WSM',
		countryCode: 882,
		phoneCode: 684,
	},
	{
		id: '42d89cea-6a31-4b73-a347-5b9610a2354f',
		name: 'San Marino',
		friendlyName: 'San Marino',
		iso: 'SM',
		isO3: 'SMR',
		countryCode: 674,
		phoneCode: 378,
	},
	{
		id: '96810137-ce16-4624-8583-2a80002cb2d9',
		name: 'Sao Tome and Principe',
		friendlyName: 'Sao Tome and Principe',
		iso: 'ST',
		isO3: 'STP',
		countryCode: 678,
		phoneCode: 239,
	},
	{
		id: 'b4df1b8b-196c-4050-9fae-b51e9a594e79',
		name: 'Saudi Arabia',
		friendlyName: 'Saudi Arabia',
		iso: 'SA',
		isO3: 'SAU',
		countryCode: 682,
		phoneCode: 966,
	},
	{
		id: '514a142b-73ea-4141-9a8b-869a44084f5b',
		name: 'Senegal',
		friendlyName: 'Senegal',
		iso: 'SN',
		isO3: 'SEN',
		countryCode: 686,
		phoneCode: 221,
	},
	{
		id: 'e4fc6034-8875-417f-8239-f3c616b9658e',
		name: 'Serbia and Montenegro',
		friendlyName: 'Serbia and Montenegro',
		iso: 'CS',
		isO3: null,
		countryCode: null,
		phoneCode: 381,
	},
	{
		id: 'd9fbbaf0-59f5-48ce-9dca-a4e3bcb7c028',
		name: 'Seychelles',
		friendlyName: 'Seychelles',
		iso: 'SC',
		isO3: 'SYC',
		countryCode: 690,
		phoneCode: 248,
	},
	{
		id: 'c009c2ce-6722-433b-8d11-3c5c1ab47090',
		name: 'Sierra Leone',
		friendlyName: 'Sierra Leone',
		iso: 'SL',
		isO3: 'SLE',
		countryCode: 694,
		phoneCode: 232,
	},
	{
		id: 'ed03b1b5-cb53-4e6a-87d3-e500c2d11d3e',
		name: 'Singapore',
		friendlyName: 'Singapore',
		iso: 'SG',
		isO3: 'SGP',
		countryCode: 702,
		phoneCode: 65,
	},
	{
		id: 'd636be80-4927-41f4-b09b-1e097093cc61',
		name: 'Slovakia',
		friendlyName: 'Slovakia',
		iso: 'SK',
		isO3: 'SVK',
		countryCode: 703,
		phoneCode: 421,
	},
	{
		id: '6b6aeafc-2cb8-4c38-a6aa-e873fe058dce',
		name: 'Slovenia',
		friendlyName: 'Slovenia',
		iso: 'SI',
		isO3: 'SVN',
		countryCode: 705,
		phoneCode: 386,
	},
	{
		id: '356a191a-6a03-4261-9079-f954e66e106e',
		name: 'Solomon Islands',
		friendlyName: 'Solomon Islands',
		iso: 'SB',
		isO3: 'SLB',
		countryCode: 90,
		phoneCode: 677,
	},
	{
		id: '72bd643c-9ac2-4d85-8900-01dd512982d7',
		name: 'Somalia',
		friendlyName: 'Somalia',
		iso: 'SO',
		isO3: 'SOM',
		countryCode: 706,
		phoneCode: 252,
	},
	{
		id: 'a282e7aa-18b8-435a-86e2-eb2479e9bbae',
		name: 'South Africa',
		friendlyName: 'South Africa',
		iso: 'ZA',
		isO3: 'ZAF',
		countryCode: 710,
		phoneCode: 27,
	},
	{
		id: '5152eba2-4bf6-4817-8642-cf1d20e157bd',
		name: 'South Georgia and the South Sandwich Islands',
		friendlyName: 'South Georgia and the South Sandwich Islands',
		iso: 'GS',
		isO3: null,
		countryCode: null,
		phoneCode: 0,
	},
	{
		id: '2bc72f1c-17f4-47c0-9115-46f7957c0c4d',
		name: 'Spain',
		friendlyName: 'Spain',
		iso: 'ES',
		isO3: 'ESP',
		countryCode: 724,
		phoneCode: 34,
	},
	{
		id: '8e6443bd-60a5-450b-9614-3423edbf2f57',
		name: 'Sri Lanka',
		friendlyName: 'Sri Lanka',
		iso: 'LK',
		isO3: 'LKA',
		countryCode: 144,
		phoneCode: 94,
	},
	{
		id: '5b5403bd-1be4-4ce6-84ca-02f2a3a83194',
		name: 'Sudan',
		friendlyName: 'Sudan',
		iso: 'SD',
		isO3: 'SDN',
		countryCode: 736,
		phoneCode: 249,
	},
	{
		id: 'f7aa1e35-3f1c-4b91-a442-5798cb0a7229',
		name: 'Suriname',
		friendlyName: 'Suriname',
		iso: 'SR',
		isO3: 'SUR',
		countryCode: 740,
		phoneCode: 597,
	},
	{
		id: '6f760b59-6280-4cf0-949a-a82149e6f3a8',
		name: 'Svalbard and Jan Mayen',
		friendlyName: 'Svalbard and Jan Mayen',
		iso: 'SJ',
		isO3: 'SJM',
		countryCode: 744,
		phoneCode: 47,
	},
	{
		id: '138a69bf-1322-4e04-918d-55369fd9c1e0',
		name: 'Swaziland',
		friendlyName: 'Swaziland',
		iso: 'SZ',
		isO3: 'SWZ',
		countryCode: 748,
		phoneCode: 268,
	},
	{
		id: '894917df-ec91-4d6b-b4c7-2f2d62e12084',
		name: 'Sweden',
		friendlyName: 'Sweden',
		iso: 'SE',
		isO3: 'SWE',
		countryCode: 752,
		phoneCode: 46,
	},
	{
		id: '9babd23c-5e5f-429d-b1da-6d727ea9aa8b',
		name: 'Switzerland',
		friendlyName: 'Switzerland',
		iso: 'CH',
		isO3: 'CHE',
		countryCode: 756,
		phoneCode: 41,
	},
	{
		id: 'fae83189-44c3-492b-90ac-d945e2b4c824',
		name: 'Syrian Arab Republic',
		friendlyName: 'Syrian Arab Republic',
		iso: 'SY',
		isO3: 'SYR',
		countryCode: 760,
		phoneCode: 963,
	},
	{
		id: '645fbef9-51f2-4df7-888c-83a75154bcf5',
		name: 'Taiwan, Province of China',
		friendlyName: 'Taiwan, Province of China',
		iso: 'TW',
		isO3: 'TWN',
		countryCode: 158,
		phoneCode: 886,
	},
	{
		id: 'fb060350-90e6-40bd-83e3-569852b5e15e',
		name: 'Tajikistan',
		friendlyName: 'Tajikistan',
		iso: 'TJ',
		isO3: 'TJK',
		countryCode: 762,
		phoneCode: 992,
	},
	{
		id: 'eba687df-21c2-499c-8420-5b1580961dbc',
		name: 'Tanzania, United Republic of',
		friendlyName: 'Tanzania, United Republic of',
		iso: 'TZ',
		isO3: 'TZA',
		countryCode: 834,
		phoneCode: 255,
	},
	{
		id: 'a10f56f5-09bc-49ea-b375-31c523db6f85',
		name: 'Thailand',
		friendlyName: 'Thailand',
		iso: 'TH',
		isO3: 'THA',
		countryCode: 764,
		phoneCode: 66,
	},
	{
		id: 'beb93ca0-2d11-4e54-be6b-f7ec4ad36bb0',
		name: 'Timor-Leste',
		friendlyName: 'Timor-Leste',
		iso: 'TL',
		isO3: null,
		countryCode: null,
		phoneCode: 670,
	},
	{
		id: '488628f1-8902-4f14-9e30-6573f6724124',
		name: 'Togo',
		friendlyName: 'Togo',
		iso: 'TG',
		isO3: 'TGO',
		countryCode: 768,
		phoneCode: 228,
	},
	{
		id: 'e6953758-8af5-4efe-97e1-e433655456e6',
		name: 'Tokelau',
		friendlyName: 'Tokelau',
		iso: 'TK',
		isO3: 'TKL',
		countryCode: 772,
		phoneCode: 690,
	},
	{
		id: '8900b4df-d861-4a9a-af71-00924eca2115',
		name: 'Tonga',
		friendlyName: 'Tonga',
		iso: 'TO',
		isO3: 'TON',
		countryCode: 776,
		phoneCode: 676,
	},
	{
		id: '0ba60b70-0100-4879-b84f-15f2a53e868d',
		name: 'Trinidad and Tobago',
		friendlyName: 'Trinidad and Tobago',
		iso: 'TT',
		isO3: 'TTO',
		countryCode: 780,
		phoneCode: 1868,
	},
	{
		id: 'b6446ca8-aa6f-4e73-b044-cf6a61daca08',
		name: 'Tunisia',
		friendlyName: 'Tunisia',
		iso: 'TN',
		isO3: 'TUN',
		countryCode: 788,
		phoneCode: 216,
	},
	{
		id: 'a26c7cd2-85a4-4b3d-bbfe-d2bb6f08ebb6',
		name: 'Turkey',
		friendlyName: 'Turkey',
		iso: 'TR',
		isO3: 'TUR',
		countryCode: 792,
		phoneCode: 90,
	},
	{
		id: 'e37be210-6abc-4427-8b40-1831ac6d289e',
		name: 'Turkmenistan',
		friendlyName: 'Turkmenistan',
		iso: 'TM',
		isO3: 'TKM',
		countryCode: 795,
		phoneCode: 7370,
	},
	{
		id: '1a1949c2-0297-4dd9-ad0b-3ea711c5ef26',
		name: 'Turks and Caicos Islands',
		friendlyName: 'Turks and Caicos Islands',
		iso: 'TC',
		isO3: 'TCA',
		countryCode: 796,
		phoneCode: 1649,
	},
	{
		id: 'ef6f9692-f6d2-4304-84a8-1838403960f9',
		name: 'Tuvalu',
		friendlyName: 'Tuvalu',
		iso: 'TV',
		isO3: 'TUV',
		countryCode: 798,
		phoneCode: 688,
	},
	{
		id: 'bab1a405-e1a3-4c32-aff2-a9cf31746210',
		name: 'Uganda',
		friendlyName: 'Uganda',
		iso: 'UG',
		isO3: 'UGA',
		countryCode: 800,
		phoneCode: 256,
	},
	{
		id: 'e6bb5a02-b1f0-4159-a081-09efecfe6b4b',
		name: 'Ukraine',
		friendlyName: 'Ukraine',
		iso: 'UA',
		isO3: 'UKR',
		countryCode: 804,
		phoneCode: 380,
	},
	{
		id: 'ae77f8b2-3091-49b8-aaaa-c6037a92e36f',
		name: 'United Arab Emirates',
		friendlyName: 'United Arab Emirates',
		iso: 'AE',
		isO3: 'ARE',
		countryCode: 784,
		phoneCode: 971,
	},
	{
		id: 'de0daa79-ab92-42d6-abb4-9e948967d004',
		name: 'United Kingdom',
		friendlyName: 'United Kingdom',
		iso: 'GB',
		isO3: 'GBR',
		countryCode: 826,
		phoneCode: 44,
	},
	{
		id: 'fe934489-a341-4a53-9537-20b2ff7df059',
		name: 'United States',
		friendlyName: 'United States',
		iso: 'US',
		isO3: 'USA',
		countryCode: 840,
		phoneCode: 1,
	},
	{
		id: 'f6cc300e-e0ad-4cbe-bde8-74d43d19aec2',
		name: 'United States Minor Outlying Islands',
		friendlyName: 'United States Minor Outlying Islands',
		iso: 'UM',
		isO3: null,
		countryCode: null,
		phoneCode: 1,
	},
	{
		id: 'ddd98e02-d3e9-4bcf-b4ef-ec269792c044',
		name: 'Uruguay',
		friendlyName: 'Uruguay',
		iso: 'UY',
		isO3: 'URY',
		countryCode: 858,
		phoneCode: 598,
	},
	{
		id: '7e9d2100-b3cd-4697-ab1e-edb16546c11d',
		name: 'Uzbekistan',
		friendlyName: 'Uzbekistan',
		iso: 'UZ',
		isO3: 'UZB',
		countryCode: 860,
		phoneCode: 998,
	},
	{
		id: 'f800feb4-947f-4491-b2e6-ab468a4aed02',
		name: 'Vanuatu',
		friendlyName: 'Vanuatu',
		iso: 'VU',
		isO3: 'VUT',
		countryCode: 548,
		phoneCode: 678,
	},
	{
		id: '725cd908-d85c-4622-9222-fdd141914a1f',
		name: 'Venezuela',
		friendlyName: 'Venezuela',
		iso: 'VE',
		isO3: 'VEN',
		countryCode: 862,
		phoneCode: 58,
	},
	{
		id: 'b16189d7-0185-43fe-99c1-77a4b7058448',
		name: 'Viet Nam',
		friendlyName: 'Viet Nam',
		iso: 'VN',
		isO3: 'VNM',
		countryCode: 704,
		phoneCode: 84,
	},
	{
		id: '10c26aef-924e-40f3-9e5c-425e96954fbf',
		name: 'Virgin Islands, British',
		friendlyName: 'Virgin Islands, British',
		iso: 'VG',
		isO3: 'VGB',
		countryCode: 92,
		phoneCode: 1284,
	},
	{
		id: 'a59e4fe8-6375-41bd-8434-1ddd120470bf',
		name: 'Virgin Islands, U.s.',
		friendlyName: 'Virgin Islands, U.s.',
		iso: 'VI',
		isO3: 'VIR',
		countryCode: 850,
		phoneCode: 1340,
	},
	{
		id: 'bd303020-605c-473d-a27e-12dce487a2f4',
		name: 'Wallis and Futuna',
		friendlyName: 'Wallis and Futuna',
		iso: 'WF',
		isO3: 'WLF',
		countryCode: 876,
		phoneCode: 681,
	},
	{
		id: '4c528642-5430-4039-804a-eb1ccdb13034',
		name: 'Western Sahara',
		friendlyName: 'Western Sahara',
		iso: 'EH',
		isO3: 'ESH',
		countryCode: 732,
		phoneCode: 212,
	},
	{
		id: '1500ca9b-53a9-4033-a091-c75e075a7344',
		name: 'Yemen',
		friendlyName: 'Yemen',
		iso: 'YE',
		isO3: 'YEM',
		countryCode: 887,
		phoneCode: 967,
	},
	{
		id: 'f25df431-6799-44cd-af96-0ac2f8e9048d',
		name: 'Zambia',
		friendlyName: 'Zambia',
		iso: 'ZM',
		isO3: 'ZMB',
		countryCode: 894,
		phoneCode: 260,
	},
	{
		id: '0784d872-bbd3-4145-b8bc-7b9a5e091230',
		name: 'Zimbabwe',
		friendlyName: 'Zimbabwe',
		iso: 'ZW',
		isO3: 'ZWE',
		countryCode: 716,
		phoneCode: 263,
	},
]

export default countries

export const nz = countries.find((x) => x.name === 'New Zealand')
