import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'

function stringToHslColor(str: string, s: string, l: string) {
	var hash = 0
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}

	var h = hash % 360
	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}

interface UserDefaultProps {
	name?: string
	className?: ClassValue
}

const UserDefault: FC<UserDefaultProps> = ({ name = '', className }) => {
	const nameParts = name.split(' ')
	const firstName = nameParts[0]
	const lastName = nameParts[nameParts.length - 1]

	return (
		<div className={clsx(className)}>
			{name ? (
				<svg viewBox="0 0 100 100" className="w-full h-full">
					<circle cx="50" cy="50" r="50" fill={stringToHslColor(name || '', '50', '50')} />
					<text
						x="50"
						y="50"
						textAnchor="middle"
						dominantBaseline="central"
						fill="white"
						className="font-sans"
						style={{ fontSize: '40px', userSelect: 'none' }}
					>
						{firstName?.length > 0 ? `${firstName[0].toUpperCase()}` : ''}
						{lastName?.length > 0 ? `${lastName[0].toUpperCase()}` : ''}
					</text>
				</svg>
			) : (
				<svg className="text-gray-300 bg-gray-100" fill="currentColor" viewBox="0 0 24 24">
					<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path>
				</svg>
			)}
		</div>
	)
}

export default UserDefault
