import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { useController, useFormContext } from 'react-hook-form'

interface FormCheckboxProps {
	name: string
	label?: ReactNode
	defaultValue?: any
	disabled?: boolean
	inputClassName?: ClassValue
	className?: ClassValue
	color?: string
}

const FormCheckbox: FC<FormCheckboxProps> = ({
	name,
	label,
	defaultValue,
	disabled,
	inputClassName,
	color = 'text-primary-600 dark:text-emerald-500',
	className,
}) => {
	const {
		formState: { isSubmitting },
	} = useFormContext()

	const {
		field: { onChange, value },
	} = useController({ name, defaultValue })

	return (
		<div className={clsx(className)}>
			<label className="text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 inline-flex items-center cursor-pointer">
				<input
					type="checkbox"
					checked={value}
					onChange={(e) => onChange(e.target.checked)}
					className={clsx('form-checkbox', inputClassName, {
						[color]: !disabled,
						'text-gray-400': disabled,
					})}
					disabled={disabled || isSubmitting}
				/>
				{label && <span className="ml-3">{label}</span>}
			</label>
		</div>
	)
}

export default FormCheckbox
