import { FC, useEffect, useState } from 'react'
import { openModal, ModalContent, ModalFooter } from 'hooks/useModal'
import api from 'api'
import { blobUrl } from 'utils/funcs'
import Loader from 'components/Loader'
import Button from 'components/Button'
import { AppFileModel, ApplicationFileModel, CaseFileModel, LineItemFileModel } from 'types/models'
import { ExternalLinkIcon } from '@heroicons/react/outline'

interface OpenFileViewProps {
	file: CaseFileModel | AppFileModel | LineItemFileModel | ApplicationFileModel
	endpoint?: string
}

const openFileView = ({ file, endpoint = 'CaseFiles' }: OpenFileViewProps) => {
	return openModal({
		title: `View File: ${file.name}`,
		size: 'xl',
		render: (close) => <FileView file={file} close={close} endpoint={endpoint} />,
	})
}

const FileView: FC<OpenFileViewProps & { close: () => void }> = ({ file, endpoint }) => {
	const [blob, setBlob] = useState<string | undefined>()

	useEffect(() => {
		const load = async () => {
			if (file.fileType === '.html' || file.fileType === '.txt') {
				const res = await api.get<string>(`/${endpoint}/${file.id}/download`, { responseType: 'text' })
				setBlob(res.data)
			} else {
				const res = await api.get<Blob>(`/${endpoint}/${file.id}/download`, { responseType: 'blob' })
				setBlob(blobUrl(res.data))
			}
		}

		load()
	}, [file, file.id, endpoint])

	return (
		<>
			<ModalContent>
				{!blob ? (
					<Loader />
				) : (
					<div className="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-800">
						{file.fileType === '.jpg' ||
						file.fileType === '.gif' ||
						file.fileType === '.png' ||
						file.fileType === '.svg' ? (
							<img src={blob} width="100%" alt={file.name} />
						) : file.fileType === '.pdf' ? (
							<embed src={blob} width="100%" height="600" type="application/pdf" />
						) : file.fileType === '.html' && blob.indexOf('<script') === -1 ? (
							<div className="p-4" dangerouslySetInnerHTML={{ __html: blob }} />
						) : file.fileType === '.txt' ? (
							<pre className="p-4">{blob}</pre>
						) : (
							<div className="p-4">This filetype cannot be displayed</div>
						)}
					</div>
				)}
			</ModalContent>

			{blob && file.fileType !== '.html' && file.fileType !== '.txt' && (
				<ModalFooter>
					<div className="flex justify-end">
						<a href={blob} target="_blank" rel="noopener noreferrer">
							<Button intent="primary">
								<ExternalLinkIcon className="w-5 h-5 -ml-1 mr-2" />
								<span>View file in new tab</span>
							</Button>
						</a>
					</div>
				</ModalFooter>
			)}
		</>
	)
}

export default openFileView
