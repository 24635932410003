import { ReactNode, MouseEvent, forwardRef, ElementType } from 'react'
import clsx, { ClassValue } from 'clsx'
import { IntentType, ButtonSize, ButtonRoundness } from 'types'
import { Spinner } from './Icon'

export const sizes = {
	xs: 'px-2.5 py-1.5 text-xs leading-4',
	sm: 'px-3 py-2 text-sm leading-4',
	md: 'px-4 py-2 text-sm leading-5',
	lg: 'px-4 py-2 text-base leading-6',
	xl: 'px-6 py-3 text-base leading-6',
	inline: '',
}

export const roundedness = {
	none: '',
	sm: 'rounded-sm',
	rounded: 'rounded',
	md: 'rounded-md',
	lg: 'rounded-lg',
	full: 'rounded-full',
}

export const intents = {
	primary: {
		span: 'inline-flex', // shadow-sm
		button: clsx(
			'inline-flex items-center font-medium border transition ease-in-out duration-150 antialiased',
			'disabled:cursor-not-allowed',
			'text-white dark:text-white',
			'border-0 border-blue-500',
			'bg-primary-600 hover:bg-primary-700 dark:bg-primary-600  dark:hover:bg-primary-500 active:bg-primary-700 dark:active:bg-primary-700',
			'focus:outline-none dark:focus:outline-none focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-300 dark:ring-offset-gray-800',
			'disabled:opacity-50'
		),
	},
	secondary: {
		span: 'inline-flex', // shadow-sm
		button: clsx(
			'inline-flex items-center font-medium border transition ease-in-out duration-150',
			'disabled:cursor-not-allowed',
			'border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500',
			'text-gray-700 active:text-gray-800 hover:text-gray-500 dark:text-gray-200 dark:active:text-white dark:hover:text-white',
			'bg-white active:bg-gray-50 dark:bg-gray-800 dark:active:bg-gray-900 dark:hover:bg-gray-750',
			'focus:outline-none dark:focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-500'
		),
	},
	save: {
		span: 'inline-flex', // shadow-sm
		button: clsx(
			'inline-flex items-center font-medium border transition ease-in-out duration-150',
			'disabled:cursor-not-allowed',
			'text-white dark:text-white',
			'border-0',
			'bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-500 hover:bg-emerald-400 dark:hover:bg-emerald-400 active:bg-emerald-600 dark:active:bg-emerald-700',
			'focus:outline-none dark:focus:outline-none focus:ring-2 focus:ring-emerald-300 dark:focus:ring-emerald-700'
		),
	},
	danger: {
		span: 'inline-flex', // shadow-sm
		button: clsx(
			'inline-flex items-center font-medium border transition ease-in-out duration-150 antialiased',
			'disabled:cursor-not-allowed',
			'text-white dark:text-white',
			'border-0',
			'bg-red-600 hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-500 active:bg-red-700 dark:active:bg-red-700',
			'focus:outline-none dark:focus:outline-none focus:ring-2 focus:ring-red-300 dark:focus:ring-red-300'
		),
	},
	menu: {
		span: 'flex',
		button: clsx(
			'w-8 h-8 inline-flex items-center justify-center rounded-full',
			'bg-transparent hover:bg-primary-300 hover:bg-opacity-25 dark:hover:bg-primary-300 dark:hover:bg-opacity-25 focus:bg-primary-200',
			'text-gray-400 hover:text-gray-500 focus:text-gray-500 dark:hover:text-gray-300',
			'focus:outline-none focus:ring-2 focus:ring-primary-500',
			'transition ease-in-out duration-100',
			'dark:hover:bg-gray-800 dark:focus:bg-gray-900 dark:hover:text-gray-400'
		),
	},
	'sidebar-menu': {
		span: 'flex',
		button: clsx(
			'w-8 h-8 inline-flex items-center justify-center rounded-full',
			'bg-transparent hover:bg-gray-700 focus:bg-gray-600',
			'text-gray-400 hover:text-gray-300 focus:text-gray-200',
			'focus:outline-none focus:ring-2 focus:ring-primary-500',
			'transition ease-in-out duration-100',
			'dark:hover:bg-gray-800 dark:focus:bg-gray-900 dark:hover:text-gray-400'
		),
	},
	'sub-menu': {
		span: 'flex',
		button: 'flex items-center justify-between text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out font-normal dark:text-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-750',
	},
	borderless: {
		span: 'inline hover', // shadow-sm
		button: clsx(
			'inline text-left border border-transparent hover:border-gray-300 focus:bg-gray-50 transition ease-in-out duration-150 dark:hover:border-gray-600 dark:focus:bg-gray-750',
			'focus:border-primary-500 dark:focus:border-primary-500',
			'focus:outline-none focus:ring-1 focus:ring-primary-500'
		),
	},
	invisible: {
		span: 'inline',
		button: clsx(
			'inline-flex items-center text-left border border-transparent',
			'hover:bg-gray-800 dark:hover:bg-gray-100 bg-opacity-10 hover:bg-opacity-10 dark:hover:bg-opacity-10',
			'focus:outline-none focus:ring-2 focus:ring-primary-500'
		),
	},
}

interface ButtonProps {
	onClick?: (e: MouseEvent) => void
	children: ReactNode
	className?: ClassValue
	intent?: IntentType
	type?: 'button' | 'submit'
	disabled?: boolean
	isLoading?: boolean
	size?: ButtonSize
	rounded?: ButtonRoundness
	as?: ElementType
}

const Button = forwardRef<HTMLElement, ButtonProps>(
	(
		{
			onClick,
			type = 'button',
			intent = 'secondary',
			size = 'md',
			rounded = 'rounded',
			disabled,
			isLoading,
			className,
			as = 'button',
			children,
		},
		ref
	) => {
		const Comp = as

		const attrs = {}

		if (as === 'button') {
			// @ts-ignore
			attrs.type = type
		}

		return (
			<span className={clsx('inline-flex select-none', intents[intent].span, roundedness[rounded])} ref={ref}>
				<Comp
					{...attrs}
					className={clsx(
						'focus:outline-none',
						intents[intent].button,
						sizes[size],
						roundedness[rounded],
						className
					)}
					onClick={onClick}
					disabled={disabled || isLoading || false}
				>
					{isLoading && <Spinner className="animate-spin -ml-1 mr-3 h-4 w-4" />}
					{children}
				</Comp>
			</span>
		)
	}
)

export default Button
