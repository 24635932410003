import { FC, Fragment } from 'react'
import clsx from 'clsx'
import { CalendarIcon } from '@heroicons/react/outline'
import { Link, useLocation } from 'react-router-dom'
import gql from 'graphql-tag'
import { GQLCaseType, GQLConnection } from 'types/gql'
import { Auth } from 'Auth'
import { navigate } from 'components/NavigateHoist'

// hooks
import useBufferedQuery from 'hooks/useBufferedQuery'

// components
import PageHeading from 'components/PageHeading'
import EventCalendar from 'components/EventCalendar'
import Card from 'components/Card'
import Stack from 'components/Stack'
import PageContent from 'components/PageContent'
import { TableSchema } from 'components/Table'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import Badge from 'components/Badge'
import Search from 'search/CasesSearch'

// pages
import AdminList from 'pages/Dashboards/MetricLists/AdminList'
import CaseManagerList from 'pages/Dashboards/MetricLists/CaseManagerList'
// import ReviewerList from 'pages/Dashboards/MetricLists/ReviewerList'
import SupportList from 'pages/Dashboards/MetricLists/SupportList'
// import MediatorList from 'pages/Dashboards/MetricLists/MediatorList'
import ACCFileHandlerDashboard from 'pages/Dashboards/ACCFileHandlerDashboard'
import { ACCOrgId } from 'utils/constants'
import { caseStatuses, CASE_STATUS } from 'types/enums'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: CaseOrderBy!, $search: CaseSearch) {
		Cases(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				caseNumber
				applicantName
				accClaimNumber
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				applicant {
					id
					user {
						id
						fullName
					}
				}
				reviews {
					id
					reviewNumber
					withdrawDate
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const NewDashboard: FC = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const rolesWithCaseTable = [
		'Applicant',
		'Respondent',
		'Reviewer',
		'Mediator',
		'Adjudicator',
		'Facilitator',
		'ACCAdmin',
	]

	const { advocate, caseManager, applicant, reviewer, mediator, respondent, peerReviewer, ...search } =
		state?.search || {}

	const { data, loading } = useBufferedQuery<{ Cases: GQLConnection<GQLCaseType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: state?.search
				? {
						...search,
						...(applicant && advocate
							? {
									applicant: {
										user: applicant,
										parties: advocate,
									},
							  }
							: {}),
						...(applicant && !advocate
							? {
									applicant: {
										user: applicant,
									},
							  }
							: {}),
						...(!applicant && advocate
							? {
									applicant: {
										parties: advocate,
									},
							  }
							: {}),
						...(caseManager ? { caseManager: { user: { id: caseManager } } } : {}),
						...(reviewer ? { reviewer: { user: { id: reviewer } } } : {}),
						...(mediator ? { mediator: { user: { id: mediator } } } : {}),
						...(respondent ? { respondent: { user: { id: respondent } } } : {}),
						...(peerReviewer ? { peerReviewer: { user: { id: peerReviewer } } } : {}),
						...(Auth.is('ACCAdmin', 'Applicant')
							? { status: caseStatuses.filter((x) => x.value >= CASE_STATUS.Open).map((x) => x.value) } // statuses open and "above"
							: {}),
						...(Auth.is('ACCAdmin') ? { organization: ACCOrgId } : {}),
				  }
				: {
						...(Auth.is('ACCAdmin', 'Applicant')
							? { status: caseStatuses.filter((x) => x.value >= CASE_STATUS.Open).map((x) => x.value) } // statuses open and "above"
							: {}),
						...(Auth.is('ACCAdmin') ? { organization: ACCOrgId } : {}),
				  },
		},
		skip: !Auth.is(...rolesWithCaseTable),
	})

	const schema: TableSchema<GQLCaseType> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`/cases/${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Claim #',
				value: (x) => x.accClaimNumber || '-',
				truncate: true,
			},
			{
				title: 'Review #',
				value: (x) =>
					x.reviews?.map(
						(y, i) =>
							x.reviews && (
								<Fragment key={i}>
									<span className={clsx({ 'line-through': !!y.withdrawDate })}>{y.reviewNumber}</span>
									{i !== x.reviews.length - 1 ? ', ' : ''}
								</Fragment>
							)
					),
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) => x.applicant?.user?.fullName || x.applicantName || '-',
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
				responsive: 'lg',
			},
			{
				title: 'Status',
				value: (x) => (
					<Badge status={x.status?.id} size="sm">
						{x.status?.readable}
					</Badge>
				),
				responsive: 'lg',
				width: 'minmax(auto, max-content)',
			},
		],
	}

	const resetDefaultSearches = () => {
		navigate(location.pathname, { replace: true, state: { search: {} } })
	}

	let roleCount =
		0 +
		+Auth.is('Admin') +
		+Auth.is('CaseManager') +
		+Auth.is('Reviewer') +
		+Auth.is('Mediator') +
		+Auth.is('Support')

	return (
		<>
			<PageHeading
				title="Dashboard"
				actions={[
					Auth.is('Admin', 'Support', 'CaseManager', 'Reviewer', 'Mediator', 'ACCAdmin') && {
						title: 'Conferences',
						icon: <CalendarIcon className="w-4 h-4" />,
						intent: 'secondary',
						size: 'sm',
						rounded: 'md',
						menu: (
							<div className="bg-white dark:bg-gray-900 rounded-md p-4 w-[600px]">
								<EventCalendar
									actions={[
										!Auth.is('Admin', 'CaseManager', 'Support') && {
											title: 'Current Month',
											onClick: resetDefaultSearches,
											rounded: 'md',
										},
									]}
								/>
							</div>
						),
					},
				]}
			/>

			<PageContent>
				<Stack space={6} dividers>
					{Auth.is('Admin') && (
						<Stack space={6}>
							{roleCount > 1 && (
								<Badge color="gray" icon={null} rounded>
									Administrator
								</Badge>
							)}
							<AdminList />
						</Stack>
					)}
					{Auth.is('CaseManager') && (
						<Stack space={6}>
							{roleCount > 1 && (
								<Badge color="gray" icon={null} rounded>
									Case Manager
								</Badge>
							)}
							<CaseManagerList />
						</Stack>
					)}
					{/* {Auth.is('Reviewer') && (
						<Stack space={6}>
							{roleCount > 1 && (
								<Badge color="gray" icon={null} rounded>
									Reviewer
								</Badge>
							)}
							<ReviewerList />
						</Stack>
					)}
					{Auth.is('Mediator') && (
						<Stack space={6}>
							{roleCount > 1 && (
								<Badge color="gray" icon={null} rounded>
									Mediator
								</Badge>
							)}
							<MediatorList />
						</Stack>
					)} */}
					{Auth.is('Support') && (
						<Stack space={6}>
							{roleCount > 1 && (
								<Badge color="gray" icon={null} rounded>
									Support
								</Badge>
							)}
							<SupportList />
						</Stack>
					)}

					{Auth.is('FileHandler') && <ACCFileHandlerDashboard />}

					{/* for cases */}
					{Auth.is(...rolesWithCaseTable) && (
						<Card>
							<Stack>
								<div className="text-lg font-medium">{Auth.is('ACCAdmin') ? 'ACC' : 'Your'} Cases</div>

								<GQLPaginatedTable
									schema={schema}
									set={data?.Cases}
									isLoading={loading}
									search={(props) => {
										return (
											<Search
												{...props}
												presetValues={
													Auth.is('ACCAdmin', 'Respondent')
														? {
																organization: { value: '', hidden: true },
														  }
														: {}
												}
											/>
										)
									}}
								/>
							</Stack>
						</Card>
					)}
				</Stack>
			</PageContent>
		</>
	)
}

export default NewDashboard
