export const env = process.env.REACT_APP_ENV || 'dev'
export const site = process.env.REACT_APP_SITE || 'dev'

const registry = 'nzdrc-icra.azurewebsites.net'

interface Environment {
	url: string
	gql: string
	registry: string
}

const environments: { [x: string]: Environment } = {
	dev: {
		// url: 'https://nzdrc-api-dev.azurewebsites.net/api',
		url: 'https://nzdrc-api-stage.azurewebsites.net/api',
		// gql: 'https://nzdrc-icra-gql-dev.azurewebsites.net/graphql',
		gql: 'https://nzdrc-icra-gql-staging.azurewebsites.net/graphql',
		// gql: 'http://localhost:4000/graphql',
		registry,
	},
	staging: {
		url: 'https://nzdrc-api-stage.azurewebsites.net/api',
		gql: 'https://nzdrc-icra-gql-staging.azurewebsites.net/graphql',
		registry,
	},
	demo: {
		url: 'https://nzdrc-api-demo.azurewebsites.net/api',
		gql: 'https://nzdrc-icra-gql-demo.azurewebsites.net/graphql',
		registry,
	},
	livetest: {
		url: 'https://nzdrc-api.azurewebsites.net/api', //temporary
		gql: 'https://nzdrc-icra-gql.azurewebsites.net/graphql',
		registry,
	},
	production: {
		url: 'https://nzdrc-api.azurewebsites.net/api',
		gql: 'https://nzdrc-icra-gql.azurewebsites.net/graphql',
		registry,
	},
}

export default environments[site]
