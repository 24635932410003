import { FC, ReactNode, useState } from 'react'
import Select from 'react-select'
import clsx, { ClassValue } from 'clsx'
import { useFormContext, useController, RegisterOptions } from 'react-hook-form'
import { path } from 'ramda'
import { XIcon } from '@heroicons/react/outline'
import { split } from 'utils/funcs'
import { v4 as uuidv4 } from 'uuid'

export interface Option {
	label: ReactNode
	value: any
}

interface FormSelectProps {
	name: string
	label?: ReactNode
	options: Option[]
	clearable?: boolean
	disabled?: boolean
	description?: string
	placeholder?: string
	defaultValue?: any
	validations?: RegisterOptions
	hideOptional?: boolean
	className?: ClassValue
	autoFocus?: boolean
	onChange?: (value: any) => void
}

const FormSelect: FC<FormSelectProps> = ({
	name,
	label,
	options,
	clearable,
	disabled = false,
	description,
	placeholder,
	defaultValue,
	validations = {},
	hideOptional = false,
	className,
	autoFocus,
	onChange: onChangeProp,
}) => {
	const {
		formState: { isSubmitting, errors },
	} = useFormContext()

	const error: undefined | { type: string; message: string } = path(split(name), errors)

	const isRequired = !!validations.required

	const {
		field: { onChange, onBlur, value },
	} = useController({ name, defaultValue, rules: validations })

	const [token] = useState(uuidv4())

	return (
		<div className={clsx(className)}>
			<label className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200">
				{label && (
					<>
						{label}{' '}
						{!isRequired && !hideOptional && (
							<span className="text-gray-400 dark:text-gray-300 font-normal text-xs leading-4 opacity-75">
								(optional)
							</span>
						)}
					</>
				)}

				<div className="text-[0px] leading-none select-none">{token}</div>

				<Select
					options={options}
					value={
						options.find((x) => {
							return (
								x.value === value ||
								(value !== null && x.value === +value) ||
								(x.value?.id !== undefined && x.value?.id === value?.id)
							)
						}) || null
					}
					onChange={(option: any) => {
						onChange(option ? option.value : null)
						if (onChangeProp) onChangeProp(option ? option.value : null)
					}}
					onBlur={onBlur}
					// inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
					className={clsx(
						'react-select-container font-normal',
						label && 'mt-1',
						error && 'react-select-container-error'
					)}
					classNamePrefix="react-select"
					placeholder={placeholder}
					isClearable={clearable}
					isDisabled={disabled || isSubmitting}
					autoFocus={autoFocus}
					menuPlacement="auto"
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: '#f0f5ff',
							primary: '#5850ec',
						},
					})}
					components={{
						ClearIndicator: ({ hasValue, innerProps: { ref, ...restInnerProps } }) => {
							return hasValue ? (
								<span ref={ref} {...restInnerProps}>
									<XIcon className="mr-8 w-5 h-5 cursor-pointer text-gray-400 hover:text-red-500" />
								</span>
							) : null
						},
						DropdownIndicator: ({ innerProps: { ref } }) => null,
					}}
				/>
			</label>

			{description && !error && <p className="mt-2 text-sm text-gray-500">{description}</p>}
			{error && error.message && <p className="mt-2 text-sm text-red-600 dark:text-red-400">{error.message}</p>}
		</div>
	)
}

export default FormSelect
