import { AnimatePresence, motion } from 'framer-motion'
import { FC, ReactNode, useState, useEffect, Fragment } from 'react'

export interface ToastDef {
	id: string
	element: JSX.Element
}

// allows for lifting functions up out of components to be global
export const toastContainer: { addToast: (x: ToastDef) => () => void; removeToast: (x: string) => void } = {
	addToast: (x) => () => {},
	removeToast: (id) => {},
}

interface ToastProviderProps {
	children: ReactNode
}

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
	const [toasts, setToasts] = useState<ToastDef[]>([])

	useEffect(() => {
		const removeToast = (id: string) => setToasts((t) => t.filter((x) => x.id !== id))

		toastContainer.addToast = (x: ToastDef) => {
			setToasts((t) => [...t, x])
			return () => removeToast(x.id)
		}
		toastContainer.removeToast = removeToast
	}, [setToasts])

	return (
		<Fragment>
			{children}
			<div className="pointer-events-none absolute top-0 right-0 z-[60] flex w-full flex-col items-end gap-4 p-4">
				<AnimatePresence>
					{toasts.map((x) => (
						<motion.div
							key={x.id}
							layout
							animate={{ opacity: 1, x: 0 }}
							initial={{ opacity: 0, x: 10 }}
							exit={{ opacity: 0, x: 10 }}
							transition={{
								type: 'tween',
								ease: 'easeOut',
								duration: 0.3,
							}}
							className="w-full max-w-sm"
						>
							{x.element}
						</motion.div>
					))}
				</AnimatePresence>
			</div>
		</Fragment>
	)
}
