import { FC, ReactNode } from 'react'
import useData from 'hooks/useData'
import api from 'api'
import { getFullName } from 'utils/funcs'
import { CaseModel, LineItemModel } from 'types/models'
import { isNotNone, None } from 'types'
import { DocumentIcon, DotsHorizontalIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import Table, { TableSchema } from 'components/Table'
import SectionHeader from 'components/SectionHeader'
import { confirm } from 'alerts'
import Stack from 'components/Stack'
import openCreateLineitem from 'modals/openCreateLineitem'
import openEditLineitem from 'modals/openEditLineitem'
import openLineitemDocs from 'modals/openLineitemDocs'
import TableFrame from './TableFrame'

const hours = 'Exceptional Case Fee (hrs)'

const hourlyRate = 81.22
// const hourlyRateInclGST = hourlyRate + 11.625

const trinary = ['-', 'Yes', 'No']

interface TotalRowItem {
	title?: ReactNode
	value: ReactNode
}

interface TotalRowProps {
	title: ReactNode
	items: (TotalRowItem | None)[]
}

const TotalRow: FC<TotalRowProps> = ({ title, items }) => {
	return (
		<div className="flex space-between">
			<div className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400 flex-1">{title}</div>
			<div className="flex space-x-4">
				{items.filter(isNotNone).map((item, i) => (
					<div key={i} className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
						{item.title}
						{item.title ? ': ' : null}
						<span className="text-black dark:text-white font-medium">{item.value}</span>
					</div>
				))}
			</div>
		</div>
	)
}

interface CaseCalculatorProps {
	caseData: CaseModel
}

const CaseCalculator: FC<CaseCalculatorProps> = ({ caseData }) => {
	const { data: lineitems, refetch } = useData<LineItemModel[]>(
		`/Cases/${caseData.id}/lineitems`,
		{},
		{ suspense: true }
	)

	if (!isNotNone(lineitems)) return null

	const reviewerLineitems =
		caseData.reviewer && caseData.reviewer.user
			? lineitems &&
			  lineitems
					.filter((x) => x.payableToId === caseData.reviewer?.user?.id)
					.filter((x) => x.itemCode === 'AC' || x.itemCode === 'ECF' || x.itemCode === 'TF')
			: []
	const mediatorLineitems =
		caseData.mediator && caseData.mediator.user
			? lineitems &&
			  lineitems
					.filter((x) => x.payableToId === caseData.mediator?.user?.id)
					.filter((x) => x.itemCode === 'AC' || x.itemCode === 'ECF' || x.itemCode === 'TF')
			: []
	const accLineitems =
		lineitems &&
		lineitems
			.filter((x) => !x.payableToId)
			.filter((x) => x.itemCode === 'AC' || x.itemCode === 'ECF' || x.itemCode === 'TF')

	const items = {
		inPerson: {
			reviewer: reviewerLineitems,
			mediator: mediatorLineitems,
		},
		acc: accLineitems,
	}

	const totals = {
		// inPerson total
		A: [...items.inPerson.reviewer, ...items.inPerson.mediator]
			.reduce((sum, x) => sum + (x.costPrice || 0), 0)
			.toFixed(2),
		// inPerson approved total
		C: [...items.inPerson.reviewer, ...items.inPerson.mediator]
			.reduce((sum, x) => sum + (x.gstAmountApproved || 0), 0)
			.toFixed(2),
		// acc total
		B: items.acc.reduce((sum, x) => sum + (x.costPrice || 0), 0).toFixed(2),
		// acc approved total
		D: items.acc.reduce((sum, x) => sum + (x.gstAmountApproved || 0), 0).toFixed(2),
	}

	const removeLineitem = (x: LineItemModel) => {
		confirm({
			title: 'Are you sure you want to delete this line item?',
			onAccept: async () => {
				await api.delete(`/Billing/lineitem/${x.id}`)

				await refetch()
			},
		})
	}

	const makeSchema = (type: 'reviewer' | 'mediator' | 'acc') => {
		const schema: TableSchema<LineItemModel> = {
			cols: [
				{
					title: <div className="whitespace-normal">Item</div>,
					value: (x) => x.description,
					width: 'minmax(auto, max-content)',
				},
				{
					title: <div className="whitespace-normal">Amount GST Excl</div>,
					value: (x) =>
						x.description === hours
							? // <td className={styles.td} title={`$${x.costPrice.toFixed(2)}`}>
							  x.costPrice / 81.22 + ' hrs'
							: `$${(+x.costPrice).toFixed(2)}`,
					width: 'minmax(auto, max-content)',
				},
				{
					title: <div className="whitespace-normal">Desc</div>,
					value: (x) => x.claimReason || '-',
					truncate: true,
				},
				{
					title: <div className="whitespace-normal">Claimable</div>,
					value: (x) => trinary[x.claimable],
				},
				{
					title: <div className="whitespace-normal">Payment Requested from ACC</div>,
					value: (x) => trinary[x.paymentRequested],
				},
				{
					title: <div className="whitespace-normal">Payment Approved by ACC</div>,
					value: (x) => trinary[x.paymentApprovedACC],
				},
				{
					title: <div className="whitespace-normal">Amount GST Excl Approved</div>,
					value: (x) =>
						x.description === hours ? (
							<div title={`$${x.gstAmountApproved.toFixed(2)}`}>
								{(x.gstAmountApproved / hourlyRate).toFixed(1) + ' hrs'}
							</div>
						) : (
							<div>{x.gstAmountApproved ? `$${x.gstAmountApproved.toFixed(2)}` : '-'}</div>
						),
				},
				{
					title: <div className="whitespace-normal">Documents</div>,
					value: (x) => (x.id && x.files.length > 0 ? `${x.files.length} Docs` : '-'),
				},
			],
			actions: (x) => [
				{
					title: '',
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						[
							{
								title: 'Edit Item',
								icon: <PencilIcon className="w-5 h-5" />,
								onClick: () => openEditLineitem({ caseData, type, lineitem: x, refetch }),
							},
							{
								title: 'View/Edit Documents',
								icon: <DocumentIcon className="w-5 h-5" />,
								onClick: () => openLineitemDocs({ caseData, lineitem: x, refetch }),
							},
						],
						[
							{
								title: 'Delete Item',
								icon: <TrashIcon className="w-5 h-5" />,
								onClick: () => removeLineitem(x),
							},
						],
					],
				},
			],
		}

		return schema
	}

	const reviewerSchema = makeSchema('reviewer')
	const mediatorSchema = makeSchema('mediator')
	const accSchema = makeSchema('acc')

	return (
		<Stack space={6} dividers>
			{caseData.reviewer && caseData.reviewer.user && (
				<Stack space={6} className="px-6">
					<SectionHeader
						title={`ICRA Disbursements: ${getFullName(caseData.reviewer.user)}`}
						actions={[
							{
								title: 'Add Line Item',
								icon: <PlusIcon className="w-5 h-5" />,
								onClick: () => openCreateLineitem({ caseData, type: 'reviewer', refetch }),
							},
						]}
					/>

					<TableFrame>
						<Table schema={reviewerSchema} items={items.inPerson.reviewer} className="-my-px" />

						<div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-600 border-t-[3px] border-gray-100 dark:border-gray-600">
							<TotalRow
								title="Subtotal In person fees and expenses"
								items={[
									{
										title: 'Amount GST Excl',
										value: `$${totals.A}`,
									},
									{
										title: 'Amount GST Excl Approved',
										value: `$${totals.C}`,
									},
								]}
							/>
						</div>
					</TableFrame>
				</Stack>
			)}

			{caseData.mediator && caseData.mediator.user && (
				<Stack space={6} className="px-6">
					<SectionHeader
						title={`ICRA Disbursements: ${getFullName(caseData.mediator.user)}`}
						actions={[
							{
								title: 'Add Line Item',
								icon: <PlusIcon className="w-5 h-5" />,
								onClick: () => openCreateLineitem({ caseData, type: 'mediator', refetch }),
							},
						]}
					/>

					<TableFrame>
						<Table schema={mediatorSchema} items={items.inPerson.mediator} className="-my-px" />

						<div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-600 border-t-[3px] border-gray-100 dark:border-gray-600">
							<TotalRow
								title="Subtotal In person fees and expenses"
								items={[
									{
										title: 'Amount GST Excl',
										value: `$${totals.A}`,
									},
									{
										title: 'Amount GST Excl Approved',
										value: `$${totals.C}`,
									},
								]}
							/>
						</div>
					</TableFrame>
				</Stack>
			)}

			<Stack space={6} className="px-6">
				<SectionHeader
					title={`ICRA Disbursements: ${caseData.organization?.name} reimbursable costs`}
					actions={[
						{
							title: 'Add Line Item',
							icon: <PlusIcon className="w-5 h-5" />,
							onClick: () => openCreateLineitem({ caseData, type: 'acc', refetch }),
						},
					]}
				/>

				<TableFrame>
					<Table schema={accSchema} items={items.acc} className="-my-px" />

					<div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-600 border-t-[3px] border-gray-100 dark:border-gray-600">
						<TotalRow
							title="Subtotal Reimbursable Costs"
							items={[
								{
									title: 'Amount GST Excl',
									value: `$${totals.B}`,
								},
								{
									title: 'Amount GST Excl Approved',
									value: `$${totals.D}`,
								},
							]}
						/>

						<TotalRow
							title="Total Disbursements"
							items={[
								{
									value: `$${(+totals.A + +totals.B).toFixed(2)}`,
								},
							]}
						/>

						<TotalRow
							title="TOTAL APPROVED AMOUNT excluding GST"
							items={[
								{
									value: `$${(+totals.C + +totals.D).toFixed(2)}`,
								},
							]}
						/>
					</div>
				</TableFrame>
			</Stack>
		</Stack>
	)
}

export default CaseCalculator
