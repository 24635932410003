import { FC, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import NavLink from './NavLink'
import logo from 'assets/img/newlogo/icra-acronym-white.png'
import { RouteConfig, isLinkRouteConfig, isAnchorRouteConfig } from 'types'
import AutoHeight from 'components/AutoHeight'
import Topbar from 'components/Topbar'

interface MobileMenuProps {
	links: RouteConfig[]
	withTopbar?: boolean
}

const MobileMenu: FC<MobileMenuProps> = ({ links, withTopbar = false }) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<div className="md:hidden bg-gray-800 dark:bg-gray-900 flex justify-between p-4">
				<Link to="/dashboard">
					<img src={logo} alt="ICRA" className="h-10" />
				</Link>

				<button
					className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
					onClick={() => setOpen((x) => !x)}
				>
					<svg
						className={clsx('h-6 w-6', { block: !open, hidden: open })}
						stroke="currentColor"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M4 6h16M4 12h16M4 18h16"
						/>
					</svg>

					<svg
						className={clsx('h-6 w-6', { block: open, hidden: !open })}
						stroke="currentColor"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			</div>

			<div className={clsx('md:hidden bg-gray-800 dark:bg-gray-900 antialiased')}>
				<AutoHeight show={open}>
					<div className="px-2 pt-2 pb-3 sm:px-3">
						{links
							.filter((x) => x)
							.filter((x) => x.show())
							.map((link, i) =>
								isLinkRouteConfig(link) ? (
									<NavLink
										key={i}
										to={link.to}
										className="flex items-center px-3 py-2 rounded-md text-base font-medium"
										activeClassName="text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700'"
										inactiveClassName="flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
										// getProps={({ isCurrent }) => ({
										// 	className: clsx({
										// 		'flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700':
										// 			!isCurrent,
										// 		'flex items-center px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700':
										// 			isCurrent,
										// 	}),
										// })}
										onClick={() => setOpen(false)}
									>
										<div className="mr-3">{link.icon}</div>
										{link.title}
									</NavLink>
								) : isAnchorRouteConfig(link) ? (
									<a
										key={i}
										href={link.url}
										className="flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
									>
										<div className="mr-3">{link.icon}</div>
										{link.title}
									</a>
								) : null
							)}

						{withTopbar && <Topbar intent="dark" />}
					</div>
				</AutoHeight>
			</div>
		</>
	)
}

export default MobileMenu
