import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormSelect from 'components/Form/FormSelect'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import Stack from 'components/Stack'
import { CaseModel } from 'types/models'
import { CareIndication } from 'types/enums'
import { RefetchFn } from 'types'
import { toast } from 'components/toast'

interface OpenCaseManagementProps {
	caseData: CaseModel
	refetch: RefetchFn
}

const openCaseManagement = ({ caseData, refetch }: OpenCaseManagementProps) => {
	return openModal({
		title: 'Case Management',
		render: (close) => <CaseManagement close={close} refetch={refetch} caseData={caseData} />,
	})
}

interface CaseManagementProps {
	caseData: CaseModel
	refetch: RefetchFn
	close: (answer?: boolean | undefined) => void
}

interface FormData {
	careIndication: string
	careManagementPlan: string
	specialInstructions: string
	triageNotes: string
}

const CaseManagement: FC<CaseManagementProps> = ({ caseData, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			careIndication: caseData.fields?.find((x) => x.name === 'careIndication')?.value || '',
			careManagementPlan: caseData.fields?.find((x) => x.name === 'careManagementPlan')?.value || '',
			specialInstructions: caseData.fields?.find((x) => x.name === 'specialInstructions')?.value || '',
			triageNotes: caseData.fields?.find((x) => x.name === 'triageNotes')?.value || '',
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/Cases/${caseData.id}`, {
				...caseData,
				...formData,
				fields: caseData.fields?.map((x) => {
					if (x.name === 'careIndication') return { ...x, value: formData.careIndication }
					if (x.name === 'careManagementPlan') return { ...x, value: formData.careManagementPlan }
					if (x.name === 'specialInstructions') return { ...x, value: formData.specialInstructions }
					if (x.name === 'triageNotes') return { ...x, value: formData.triageNotes }
					return x
				}),
			})

			if (refetch) refetch()
			toast({ title: 'Case Management Saved' })
			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormSelect
						name="careIndication"
						label="Care Indication status"
						options={CareIndication.options}
						hideOptional
						autoFocus
					/>
					<FormInput name="specialInstructions" label="Additional Information" as="textarea" />
					<FormInput name="triageNotes" label="Triage notes" as="textarea" />
					<FormInput name="careManagementPlan" label="Care Indicator Information" as="textarea" />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openCaseManagement
