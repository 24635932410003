import { FC, ReactNode, ElementType, useState } from 'react'
import clsx, { ClassValue } from 'clsx'
import { useFormContext, RegisterOptions, useController } from 'react-hook-form'
import { path } from 'ramda'
import { split } from 'utils/funcs'
import useAutoFocus from 'hooks/useAutoFocus'
import { v4 as uuidv4 } from 'uuid'

interface FormInputProps {
	as?: ElementType | string
	name: string
	type?: string
	label?: ReactNode
	defaultValue?: any
	placeholder?: string
	disabled?: boolean
	step?: number
	description?: string
	validations?: RegisterOptions
	hideOptional?: boolean
	className?: ClassValue
	inputClassName?: ClassValue
	autoFocus?: boolean
	customErrors?: {
		[x: string]: string
	}
}

const FormInput: FC<FormInputProps> = ({
	as = 'input',
	name,
	type = 'text',
	label,
	defaultValue,
	placeholder,
	disabled = false,
	step,
	description,
	validations = {},
	hideOptional = false,
	className,
	inputClassName,
	autoFocus,
	customErrors = {},
}) => {
	const {
		formState: { isSubmitting, errors },
	} = useFormContext()

	const error: { type: string; message: string } | undefined = path(split(name), errors)

	const isRequired = !!validations.required

	const Comp = as

	const ref = useAutoFocus<HTMLInputElement>(!!autoFocus)

	const {
		field: { onChange, onBlur, value },
	} = useController({ name, defaultValue, rules: validations })

	const [token] = useState(uuidv4())

	return (
		<div className={clsx(className)}>
			<div
				className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 cursor-default"
				onClick={() => ref.current?.focus()}
			>
				{label && (
					<>
						{label}{' '}
						{!isRequired && !hideOptional && (
							<span className="text-gray-400 dark:text-gray-300 font-normal text-xs leading-4 opacity-75">
								(optional)
							</span>
						)}
					</>
				)}
			</div>

			<div className="text-[0px] leading-none select-none">{token}</div>

			<div className={clsx('relative rounded-md', label && 'mt-1')}>
				<Comp
					type={type}
					autoComplete="off"
					placeholder={placeholder}
					step={step}
					ref={ref}
					disabled={disabled || isSubmitting}
					value={value !== null ? value : ''}
					onChange={onChange}
					onBlur={onBlur}
					// autoFocus={autoFocus}
					className={clsx(
						'form-input block w-full sm:text-sm sm:leading-5',
						error &&
							'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
						inputClassName
					)}
				/>

				{error && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
							<path
								fillRule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
				)}
			</div>

			{description && !error && <p className="mt-2 text-sm text-gray-500">{description}</p>}

			{error && error.message && <p className="mt-2 text-sm text-red-600 dark:text-red-400">{error.message}</p>}
			{error && error.type && customErrors[error.type] && (
				<p className="mt-2 text-sm text-red-600">{customErrors[error.type]}</p>
			)}
		</div>
	)
}

export default FormInput
