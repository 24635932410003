export enum CASE_FILE_CATEGORIES {
	'General' = 0,
	'OutcomeNotice' = 1,
	'Minute' = 2,
	'Decision' = 3,
	'AdjournmentNotice' = 4,
	'DraftMinute' = 5,
	'Recording' = 6,
	'DraftDecision' = 7,
	'MinuteCMC' = 8,
	'MinuteAdjournment' = 9,
	'DecisionWithdrawal' = 10,
	'MinutePartHeard' = 11,
	'AdjournmentRequest' = 12,
	'Ruling' = 13,
	'Billing' = 14,
	'NoSettlement' = 15,
	'PartialSettlement' = 16,
	'FullSettlement' = 17,
	'SignatureRequired' = 18,
	'DecisionOnCosts' = 19,
}

export const caseFileCategories = [
	{ value: 0, label: 'General' },
	// { value: 4, label: 'Adjournment Notice' },
	{ value: 12, label: 'Adjournment Request' },
	{ value: 3, label: 'Decision' },
	{ value: 10, label: 'Decision (Withdrawal)' },
	{ value: 19, label: 'Decision On Costs' },
	{ value: 7, label: 'Draft Decision' },
	{ value: 5, label: 'Draft Minute' },
	{ value: 2, label: 'Minute' },
	{ value: 9, label: 'Minute (Adjournment)' },
	{ value: 8, label: 'Minute (CMC)' },
	{ value: 11, label: 'Minute (Part-heard)' },
	{ value: 1, label: 'Outcome Notice' },
	{ value: 17, label: 'Outcome Notice (Full Settlement)' },
	{ value: 16, label: 'Outcome Notice (Partial Settlement)' },
	{ value: 15, label: 'Outcome Notice (No Settlement)' },
	{ value: 18, label: 'Outcome Notice (Signature Required)' },
	{ value: 6, label: 'Recording' },
	{ value: 13, label: 'Ruling' },
	// { value: 14, label: 'Billing' },
]

export const CaseFileCategories = {
	readable: (x: CASE_FILE_CATEGORIES) => caseFileCategories.find((y) => y.value === x)?.label || '-',
	options: caseFileCategories,
}
