import { FC, ReactNode, useState, useRef, MouseEvent } from 'react'
import { BackdropTransition, AlertTransition } from 'components/AlertTransition'
import alert from 'utils/alert'
import { Spinner } from 'components/Icon'
import useAutoFocus from 'hooks/useAutoFocus'
import { intents as buttonIntents, roundedness as buttonRoundness, sizes as buttonSizes } from 'components/Button'
import clsx from 'clsx'

type ConfirmIntent = 'danger' | 'primary'

interface ConfirmProps {
	resolve: (x?: boolean | undefined) => void
	close: () => void
	title: ReactNode
	message?: ReactNode
	buttonLabel?: ReactNode
	dismissable?: boolean
	intent?: ConfirmIntent
	onAccept?: () => Promise<any> | void
}

const Confirm: FC<ConfirmProps> = ({
	resolve,
	close,
	title,
	message,
	buttonLabel = 'Yes',
	dismissable = true,
	intent = 'danger',
	onAccept,
}) => {
	const [open, setOpen] = useState(true)
	const [loading, setLoading] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const autoFocusRef = useAutoFocus<HTMLButtonElement>()

	const closeAlert = (x: boolean) => {
		setOpen(false)
		resolve(x)
		setTimeout(() => {
			close()
		}, 300)
	}

	const handleAccept = async () => {
		if (onAccept) {
			setLoading(true)
			await new Promise((resolve) => setTimeout(resolve, 500))
			await onAccept()
			closeAlert(true)
		} else {
			closeAlert(true)
		}
	}

	return (
		<BackdropTransition show={open}>
			<div
				className="fixed inset-0 w-screen h-screen flex p-4 justify-center bg-black bg-opacity-50 overflow-y-auto z-[60] backdrop-filter backdrop-grayscale"
				onClick={(e: MouseEvent) => {
					if (e.target === ref.current) {
						dismissable && !loading && closeAlert(false)
					}
				}}
				ref={ref}
			>
				<AlertTransition
					show={open}
					className="w-full pointer-events-none sm:flex sm:justify-center sm:items-start"
				>
					<div
						className="bg-white dark:bg-gray-700 rounded-lg overflow-hidden shadow-xl sm:max-w-lg sm:w-full pointer-events-auto"
						ref={dismissable ? ref : undefined}
					>
						<div className="bg-white dark:bg-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
							<div className="sm:flex sm:items-start">
								<div
									className={clsx(
										'mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
										{
											'bg-red-100 text-red-600': intent === 'danger',
											'bg-primary-100 text-primary-600': intent === 'primary',
										}
									)}
								>
									<svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
										/>
									</svg>
								</div>

								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
										{title}
									</h3>
									{message && (
										<div className="mt-2 text-sm leading-5 text-gray-500 dark:text-gray-300">
											{message}
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="bg-gray-50 dark:bg-gray-750 px-4 py-3 sm:px-6 flex flex-col-reverse sm:flex-row justify-end sm:space-x-3">
							<span className={clsx(buttonIntents.danger.span, buttonRoundness.md)}>
								<button
									onClick={() => closeAlert(false)}
									type="button"
									// className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5 antialiased disabled:opacity-50 disabled:cursor-not-allowed dark:border-gray-600 dark:text-gray-200 dark:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700"
									className={clsx(
										buttonIntents.secondary.button,
										buttonSizes.md,
										buttonRoundness.md,
										'w-full sm:w-auto justify-center'
									)}
									disabled={loading}
									tabIndex={1}
								>
									Cancel
								</button>
							</span>

							<span className={clsx(buttonIntents[intent].span, buttonRoundness.md)}>
								<button
									onClick={handleAccept}
									type="button"
									// className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 antialiased"
									className={clsx(
										buttonIntents[intent].button,
										buttonSizes.md,
										buttonRoundness.md,
										'w-full sm:w-auto justify-center mb-3 sm:mb-0'
									)}
									disabled={loading}
									ref={autoFocusRef}
									tabIndex={2}
								>
									<div className="flex items-center">
										{loading && <Spinner className="animate-spin -ml-1 mr-3 w-4 h-4" />}
										{buttonLabel}
									</div>
								</button>
							</span>
						</div>
					</div>
				</AlertTransition>
			</div>
		</BackdropTransition>
	)
}

export default Confirm

export const confirm = async ({
	title,
	message,
	intent,
	buttonLabel,
	onAccept,
}: {
	title: ReactNode
	message?: ReactNode
	intent?: ConfirmIntent
	buttonLabel?: ReactNode
	onAccept?: () => Promise<any> | void
}) => {
	const answer = await alert<boolean>(({ resolve, close }) => (
		<Confirm
			resolve={resolve}
			close={close}
			title={title}
			message={message}
			buttonLabel={buttonLabel}
			onAccept={onAccept}
			dismissable={true}
			intent={intent}
		/>
	))

	return answer
}
