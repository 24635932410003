import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import api from 'api'
import { isAxiosError } from 'types'
import { queryString } from 'utils/funcs'

const useData = <T>(
	endpoint: string,
	variables: any = {},
	options?: UseQueryOptions<T, unknown, T, any[]>
): UseQueryResult<T> => {
	return useQuery(
		[endpoint, variables || {}],
		async () => {
			let { search, ...rest } = variables

			let qs = queryString(rest)

			if (search) {
				let searchPart = JSON.stringify(search)

				if (searchPart !== '{}') {
					qs += `&search=${encodeURIComponent(searchPart)}`
				}
			}

			const { data }: { data: T } = await api.get(endpoint + (qs ? `?${qs}` : ''))

			return data
		},
		{
			refetchOnWindowFocus: true,
			retry: (_, error) => {
				if (isAxiosError(error)) {
					if (
						error.response?.status === 400 ||
						error.response?.status === 404 ||
						error.response?.status === 500
					) {
						return false
					}
				}

				return true
			},
			...options,
		}
	)
}

export default useData
