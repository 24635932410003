import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'

interface CardProps {
	className?: ClassValue
	header?: ReactNode
	footer?: ReactNode
	children?: ReactNode
}

const Card: FC<CardProps> = ({ className, header, footer, children }) => (
	<CardWrapper className={clsx(className)}>
		{header}
		{children && <CardContent>{children}</CardContent>}
		{footer && <CardFooter>{footer}</CardFooter>}
	</CardWrapper>
)

export const CardWrapper: FC<{ children: ReactNode; className?: ClassValue }> = ({ className, children }) => (
	<div className={clsx('bg-white dark:bg-gray-700 shadow rounded-none sm:rounded-md', className)}>{children}</div>
)

export const CardContent: FC<{ children: ReactNode; className?: ClassValue }> = ({ className, children }) => (
	<div className={clsx('px-4 py-5 sm:p-6', className)}>{children}</div>
)

export const CardFooter: FC<{ children: ReactNode; className?: ClassValue }> = ({ className, children }) => (
	<div className={clsx('bg-gray-50 dark:bg-gray-750 sm:rounded-b-md px-4 py-4 sm:px-6', className)}>{children}</div>
)

export default Card
