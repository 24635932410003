import { FC, ReactNode, useRef, useState } from 'react'
import clsx, { ClassValue } from 'clsx'
import { usePopper } from 'hooks/usePopper'
import { Placement } from '@popperjs/core/index'
import { Menu } from '@headlessui/react'
import { AnimatePresence, motion, MotionStyle } from 'framer-motion'
import { Link } from 'react-router-dom'
import {
	ActionType,
	isActionMenuActionType,
	isButtonActionType,
	isSubmitActionType,
	isLinkActionType,
	isCustomMenuActionType,
	isDividerActionType,
	isNotNone,
	IntentType,
	ButtonSize,
	ButtonRoundness as ButtonRoundnessType,
	None,
} from 'types'
import Button, {
	intents as ButtonIntents,
	sizes as ButtonSizes,
	roundedness as ButtonRoundness,
} from 'components/Button'
import CustomMenu from 'components/CustomMenu'

const intents = {
	primary:
		'block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 font-normal dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-750 h-10',
	secondary:
		'rounded-md block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-primary-300 hover:bg-opacity-25 dark:hover:bg-primary-300 dark:hover:bg-opacity-25 focus:outline-none focus:bg-gray-100 font-normal dark:text-gray-200 dark:focus:bg-gray-750 active:bg-gray-200 dark:active:bg-gray-700 h-10',
	save: 'rounded-md block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-green-300 hover:bg-opacity-25 dark:hover:bg-green-300 dark:hover:bg-opacity-25 focus:outline-none focus:bg-green-100 hover:text-green-600 font-normal dark:text-green-200 dark:hover:bg-opacity-50 dark:hover:text-green-100 dark:focus:bg-green-400 active:bg-green-200 dark:active-bg-green-300 h-10',
	danger: 'rounded-md block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-red-300 hover:bg-opacity-25 dark:hover:bg-red-300 dark:hover:bg-opacity-25 focus:outline-none focus:bg-red-100 hover:text-red-600 font-normal dark:text-red-200 dark:hover:bg-opacity-50 dark:hover:text-red-100 dark:focus:bg-red-400 active:bg-red-200 dark:active-bg-red-300 h-10',
	submit: 'block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 font-normal h-10',
	cancel: 'block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 font-normal',
	menu: 'block',
	'sidebar-menu': '',
	'sub-menu':
		'block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 font-normal dark:text-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-750',
	anchor: 'block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 font-normal',
	borderless:
		'block px-4 py-2 text-sm text-left w-full leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 font-normal',
	invisible: '',
}

interface ActionMenuProps {
	actions: ActionType[] | (ActionType[] | None)[]
	intent?: IntentType
	placement?: Placement
	offset?: number | [number, number]
	hoverable?: boolean
	disabled?: boolean
	size?: ButtonSize
	rounded?: ButtonRoundnessType
	className?: ClassValue
	children: ReactNode
}

const ActionMenu: FC<ActionMenuProps> = ({
	actions,
	intent,
	placement = 'bottom-end',
	offset = 10,
	hoverable = false,
	disabled = false,
	size,
	rounded = 'rounded',
	className,
	children,
}) => {
	const [reference, tooltip] = usePopper({
		placement,
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: { offset: offset instanceof Array ? offset : [0, offset] },
			},
		],
	})

	const [isHover, setHover] = useState(false)
	const [hoverTimer, setHoverTimer] = useState<ReturnType<typeof setTimeout> | null>()

	const isDeepArray = (x: Array<any>): x is (ActionType[] | None)[] => x.filter(isNotNone)[0] instanceof Array

	const actionList: ActionType[] = isDeepArray(actions)
		? actions.filter(isNotNone).reduce((list: ActionType[], actions: ActionType[], index) => {
				if (index === 0) return [...actions]
				return [...list, { divider: true }, ...actions.filter(isNotNone)]
		  }, [])
		: actions.filter(isNotNone)

	const prevOpen = useRef(false)

	return (
		<div
			className={clsx('block text-left w-full', className)}
			onMouseOver={() => {
				if (hoverable) {
					setHover(true)
					if (hoverTimer) {
						clearTimeout(hoverTimer)
					}
				}
			}}
			onMouseOut={() => {
				if (hoverable) {
					setHoverTimer(
						setTimeout(() => {
							setHover(false)
						}, 100)
					)
				}
			}}
		>
			<Menu>
				{({ open }) => {
					/// I dunno man, triggering a window resize event fixes this usePopper hook, which I think is jank af
					if (open !== prevOpen.current) {
						prevOpen.current = open
						if (open) {
							window.dispatchEvent(new Event('resize'))
						}
					}

					return (
						<>
							<span className={clsx(intent && ButtonIntents[intent].span, ButtonRoundness[rounded])}>
								<Menu.Button
									className={clsx(
										intent && ButtonIntents[intent].button,
										size && ButtonSizes[size],
										ButtonRoundness[rounded]
									)}
									disabled={disabled}
									// @ts-ignore
									ref={reference}
								>
									{children}
								</Menu.Button>
							</span>

							<div ref={tooltip} className="z-20 whitespace-nowrap">
								<AnimatePresence>
									{(open || isHover) && !disabled && (
										<motion.div
											style={{ originY: 0 } as MotionStyle}
											variants={{
												hidden: { scale: 0.95, opacity: 0 },
												visible: { scale: 1, opacity: 1 },
											}}
											transition={{
												type: 'tween',
												ease: 'easeOut',
												duration: 0.3,
											}}
											initial="hidden"
											animate="visible"
											exit="hidden"
										>
											<Menu.Items
												static
												className="max-w-[100vw] origin-top-right bg-white dark:bg-gray-900 rounded-md shadow-lg outline-none"
											>
												<div className="p-2 rounded-md ring-1 ring-black ring-opacity-5">
													{actionList.filter(isNotNone).map((action, i) =>
														isLinkActionType(action) ? (
															<Menu.Item key={i} disabled={action.disabled}>
																{({ active }) => (
																	<Link
																		key={i}
																		to={action.to}
																		className={clsx(
																			intents[action.intent || 'secondary'],
																			'flex items-center',
																			{
																				'ring-2 ring-primary-500 hover:ring-0':
																					active,
																			}
																		)}
																		state={action.state}
																		replace={action.replace}
																	>
																		<ActionInner action={action} />
																	</Link>
																)}
															</Menu.Item>
														) : isButtonActionType(action) ? (
															!action.disabled && (
																<Menu.Item key={i} disabled={action.disabled}>
																	{({ active }) => (
																		<button
																			key={i}
																			onClick={(e) => {
																				e.stopPropagation()
																				action.onClick(e)
																			}}
																			className={clsx(
																				intents[action.intent || 'secondary'],
																				{
																					'ring-2 ring-primary-500 hover:ring-0':
																						active,
																				}
																			)}
																			type="button"
																		>
																			<ActionInner action={action} />
																		</button>
																	)}
																</Menu.Item>
															)
														) : isSubmitActionType(action) ? (
															!action.disabled && (
																<Menu.Item key={i} disabled={action.disabled}>
																	{({ active }) => (
																		<button
																			key={i}
																			className={clsx(
																				intents[action.intent || 'secondary'],
																				{
																					'ring-2 ring-primary-500 hover:ring-0':
																						active,
																				}
																			)}
																			type="submit"
																		>
																			<ActionInner action={action} />
																		</button>
																	)}
																</Menu.Item>
															)
														) : isActionMenuActionType(action) ? (
															<ActionMenu
																key={i}
																actions={action.actions}
																intent={action.intent || 'sub-menu'}
																placement={action.placement}
																offset={action.offset || [-4, -1]}
																hoverable={action.hoverable}
															>
																<Menu.Item disabled={action.disabled}>
																	{({ active }) => (
																		<div
																			className={clsx(
																				intents[action.intent || 'secondary'],
																				'flex items-center',
																				{
																					'ring-2 ring-primary-500 hover:ring-0':
																						active,
																				}
																			)}
																		>
																			<ActionInner action={action} />
																		</div>
																	)}
																</Menu.Item>
															</ActionMenu>
														) : isCustomMenuActionType(action) ? (
															<CustomMenu
																key={i}
																menu={action.menu}
																placement={action.placement}
																className={action.className}
																maxWidth={action.maxWidth}
															>
																{({ onClick }) => (
																	<span className="block">
																		<Button
																			intent={action.intent || 'secondary'}
																			onClick={onClick}
																		>
																			<ActionInner action={action} />
																		</Button>
																	</span>
																)}
															</CustomMenu>
														) : isDividerActionType(action) ? (
															<div
																key={i}
																className="border-t border-gray-100 dark:border-gray-700 my-1"
															/>
														) : null
													)}
												</div>
											</Menu.Items>
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						</>
					)
				}}
			</Menu>
		</div>
	)
}

interface ActionInnerProps {
	action: ActionType
}

const ActionInner: FC<ActionInnerProps> = ({ action }) => {
	if (!isNotNone(action)) return null

	let side = action.iconSide || 'left'

	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center">
				{side === 'left' && action.icon ? (
					<span className={clsx(action.title ? '-ml-1 mr-3 opacity-75' : '-ml-1 -mr-1')}>{action.icon}</span>
				) : null}
				{action.title}
			</div>
			{side === 'right' && action.icon ? <span className="-mr-1 ml-2">{action.icon}</span> : null}
		</div>
	)
}

export default ActionMenu
