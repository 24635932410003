import { FC } from 'react'
import CaseHeader from 'components/CaseHeader'
import useData from 'hooks/useData'
import Stack from 'components/Stack'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import { useLocation, useParams } from 'react-router-dom'
import { navigate } from 'components/NavigateHoist'
import { CaseModel } from 'types/models'
import Tabs from 'components/Tabs'
import { Auth } from 'Auth'
import Suspender from 'components/Suspender'
// import ReviewerCalculator from 'components/ReviewerCalculator'
import CaseCalculator from 'components/CaseCalculator'
import clsx from 'clsx'
import BillingInfo from 'components/BillingInfo'

// interface CaseBillingProps {
// 	caseId?: string
// }

const CaseBilling: FC = () => {
	const params = useParams()
	const { caseId } = params

	const location = useLocation()

	const state = location?.state as { tab?: number } | null

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const links = caseData ? caseLinks(caseData) : []

	const activeTab = Auth.is('Admin', 'CaseManager', 'FENZAdmin') ? state?.tab || 0 : 1

	// const isReviewer = caseData?.reviewer?.user?.id === Auth.profile()?.id
	// const isMediator = caseData?.mediator?.user?.id === Auth.profile()?.id

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack>
					<div className="px-6 flex justify-between items-start">
						<SectionHeader title="Billing" />

						<Tabs
							activeIndex={activeTab}
							theme="light"
							items={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									title: 'Additional Costs',
									onClick: () => navigate(location.pathname, { replace: true, state: { tab: 0 } }),
								},
								{
									title: 'Billing Info',
									onClick: () => navigate(location.pathname, { replace: true, state: { tab: 1 } }),
								},
							]}
						/>
					</div>

					<div className={clsx(activeTab !== 0 && 'hidden')}>
						{caseData && Auth.is('Admin', 'CaseManager', 'FENZAdmin') && (
							<Suspender render={<CaseCalculator caseData={caseData} />} />
						)}

						{/* {!Auth.is('Admin', 'CaseManager') &&
							(isReviewer && caseData?.reviewer?.user ? (
								<Suspender
									render={
										<ReviewerCalculator
											caseData={caseData}
											user={caseData.reviewer.user}
											partyRole="Reviewer"
										/>
									}
								/>
							) : isMediator && caseData?.mediator?.user ? (
								<Suspender
									render={
										<ReviewerCalculator
											caseData={caseData}
											user={caseData.mediator.user}
											partyRole="Mediator"
										/>
									}
								/>
							) : null)} */}
					</div>

					<div className={clsx(activeTab !== 1 && 'hidden')}>
						{caseData && <Suspender render={<BillingInfo caseData={caseData} />} />}
					</div>
				</Stack>
			</PageNavbar>
		</>
	)
}

export default CaseBilling
