import { FC } from 'react'
import { CloudUploadIcon } from '@heroicons/react/outline'
import { RefetchFn } from 'types'
import useData from 'hooks/useData'
import { CaseModel, ReminderModel } from 'types/models'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import openCloseReminder from 'modals/openCloseReminder'
import { Spinner } from './Icon'
import api from 'api'
import { getFullName } from 'utils/funcs'

interface ReminderMenuProps {
	refetch: RefetchFn
}

const ReminderMenu: FC<ReminderMenuProps> = ({ refetch }) => {
	const {
		data,
		isFetching,
		refetch: localRefetch,
	} = useData<ReminderModel[]>('/Reminders', {
		startDate: startOfDay(subDays(new Date(), 10)).toISOString(),
		endDate: endOfDay(new Date()).toISOString(),
		search: {
			reminderStatus: 0,
		},
	})

	return (
		<div className="flex flex-col rounded-md overflow-hidden bg-white dark:bg-gray-700 min-w-80">
			<div className="p-4">
				<h3 className="text-lg font-medium">Reminders</h3>
			</div>

			<div className="flex flex-col border-t border-b border-gray-200 dark:border-gray-600 divide-y divide-gray-200 dark:divide-gray-600">
				{data?.map((x, i) => (
					<button
						className="py-3 px-4 text-sm flex items-center justify-between space-x-4 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-750 cursor-pointer"
						key={i}
						onClick={async () => {
							const { data: caseData } = await api.get<CaseModel>(`/Cases/${x.caseId}`)

							openCloseReminder({
								title: `${x.description} - ${getFullName(caseData.applicant?.user)}, ${
									caseData.caseNumber
								}`,
								reminder: x,
								refetch: () => Promise.all([refetch(), localRefetch()]),
							})
						}}
					>
						<div>{x.description}</div>

						<CloudUploadIcon className="w-5 h-5" />
					</button>
				))}

				{!data?.length && <div className="p-4 text-sm font-medium text-center italic">No reminders...</div>}

				{!data?.length && isFetching && (
					<div className="flex items-center justify-center">
						<Spinner className="w-10 h-10 aniamte-spin" />
					</div>
				)}
			</div>
		</div>
	)
}

export default ReminderMenu
