import { FC, useState, useRef, useCallback, useEffect, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'

interface BasicTruncateProps {
	title: string
	className?: ClassValue
	children: ReactNode
}

const BasicTruncate: FC<BasicTruncateProps> = ({ title, className, children }) => {
	const ref = useRef<HTMLSpanElement>(null)
	const timer = useRef<NodeJS.Timeout | undefined>(undefined)
	const [isOverflowing, setOverflowing] = useState(false)

	const check = useCallback(() => {
		if (ref.current) {
			const { clientWidth, clientHeight, scrollWidth, scrollHeight } = ref.current
			setOverflowing(scrollHeight > clientHeight || scrollWidth > clientWidth)
		}
	}, [])

	useEffect(() => {
		const handler = () => {
			if (timer.current) clearTimeout(timer.current)
			timer.current = setTimeout(check, 500)
		}
		window.addEventListener('resize', handler)
		return () => window.removeEventListener('resize', handler)
	}, [check])

	useEffect(check, [check])

	return (
		<span
			ref={ref}
			className={clsx('overflow-ellipsis overflow-hidden', className)}
			title={isOverflowing ? title : ''}
		>
			{children}
		</span>
	)
}

export default BasicTruncate
