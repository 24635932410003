import { FC } from 'react'
import Card from 'components/Card'
import FileExplorer from 'components/FileExplorer'
import CardHeader from 'components/CardHeader'
import { CaseModel } from 'types/models'

interface DocumentsProps {
	caseData?: CaseModel
}

const Documents: FC<DocumentsProps> = ({ caseData }) => {
	return (
		<Card header={<CardHeader title="Documents" subtitle="Upload your documents and other files" />}>
			{caseData && <FileExplorer cases={[caseData]} open />}
		</Card>
	)
}

export default Documents
