import { FC, ReactNode, useState, useEffect, useRef, useCallback, MouseEvent } from 'react'
import { BackdropTransition, AlertTransition } from 'components/AlertTransition'
import alert from 'utils/alert'

interface SuccessProps {
	close: () => void
	resolve: () => void
	title: ReactNode
	message?: ReactNode
	dismissable?: boolean
}

const Success: FC<SuccessProps> = ({ close, resolve, title, message, dismissable = true }) => {
	const [open, setOpen] = useState(true)

	const closeModal = useCallback(() => {
		setOpen(false)
		resolve()
		setTimeout(() => {
			close()
		}, 300)
	}, [close, resolve])

	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleClose = () => closeModal()

		window.addEventListener('keyup', handleClose)
		return () => window.removeEventListener('keyup', handleClose)
	}, [closeModal])

	return (
		<BackdropTransition show={open}>
			<div
				className="fixed inset-0 w-screen h-screen flex p-4 justify-center bg-black bg-opacity-50 overflow-y-auto z-[60] backdrop-filter backdrop-grayscale"
				onClick={(e: MouseEvent) => {
					if (e.target === ref.current) {
						dismissable && closeModal()
					}
				}}
				ref={ref}
			>
				<AlertTransition
					show={open}
					className="w-full pointer-events-none sm:flex sm:justify-center sm:items-start"
				>
					<div
						className="bg-white dark:bg-gray-700 rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl sm:max-w-sm sm:w-full sm:p-6 pointer-events-auto"
						ref={dismissable ? ref : undefined}
					>
						<div>
							<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
								<svg
									className="h-6 w-6 text-green-600"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 13l4 4L19 7"
									/>
								</svg>
							</div>
							<div className="mt-3 text-center sm:mt-5">
								<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">{title}</h3>
								{message && (
									<div className="mt-2">
										<p className="text-sm leading-5 text-gray-500 dark:text-gray-300">{message}</p>
									</div>
								)}
							</div>
						</div>
						<div className="mt-5 sm:mt-6">
							<span className="flex w-full rounded-md shadow-sm">
								<button
									onClick={() => closeModal()}
									type="button"
									className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary transition ease-in-out duration-150 sm:text-sm sm:leading-5 antialiased"
								>
									Ok
								</button>
							</span>
						</div>
					</div>
				</AlertTransition>
			</div>
		</BackdropTransition>
	)
}

export default Success

export const success = async ({ title, message }: { title: ReactNode; message?: ReactNode }) => {
	await alert(({ close, resolve }) => (
		<Success close={close} resolve={resolve} title={title} message={message} dismissable={true} />
	))
}
