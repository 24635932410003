import { FC } from 'react'
import { useMatch, useParams, Outlet, Navigate } from 'react-router-dom'
import useData from 'hooks/useData'
import { CaseModel } from 'types/models'
import { CASE_STATUS } from 'types/enums'
import { Auth } from 'Auth'
import { Spinner } from 'components/Icon'

// interface ViewCaseOpenProps {
// 	caseId: string
// 	children: ReactNode
// }

const ViewCaseOpen: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: item } = useData<CaseModel>(
		`/Cases/${caseId}`,
		{},
		{
			refetchOnWindowFocus: Auth.is('Admin', 'CaseManager', 'FENZAdmin'),
			refetchOnMount: 'always',
			suspense: true,
		}
	)

	const matchRequest = useMatch('/cases/:caseId/request')
	const matchEdit = useMatch('/cases/:caseId/pending')
	const matchCase = useMatch('/cases/:caseId')

	// const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

	if (!item) {
		return (
			<div className="h-screen flex flex-col">
				<div className="flex-1 flex flex-col md:flex-row md:overflow-hidden">
					<div className="flex flex-col flex-1 md:w-0 md:overflow-hidden">
						<div className="flex-1 relative z-0 overflow-y-auto focus:outline-none flex items-cente justify-center">
							<Spinner className="w-10 h-10 spin" />
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (item?.status === CASE_STATUS.Request && !matchRequest) {
		return <Navigate to={`/cases/${caseId}/request`} replace />
	}

	if (item?.status === CASE_STATUS.Pending && !matchEdit) {
		return <Navigate to={`/cases/${caseId}/pending`} replace />
	}

	if (item?.status !== CASE_STATUS.Pending && item?.status !== CASE_STATUS.Request && matchCase) {
		return <Navigate to={`/cases/${caseId}/case-information`} replace />
	}

	return <Outlet />
}

export default ViewCaseOpen
