import { FC, useEffect, useState } from 'react'
import { RegisterOptions, useController, useFormContext } from 'react-hook-form'
import clsx, { ClassValue } from 'clsx'
import { path } from 'ramda'
import { usePopper } from 'hooks/usePopper'
import { Placement } from '@popperjs/core/index'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import useDismissable from 'hooks/useDismissable'
import {
	addDays,
	addMonths,
	endOfMonth,
	format,
	getDaysInMonth,
	getISODay,
	isAfter,
	isBefore,
	isSameDay,
	parse,
	startOfMonth,
	subDays,
	subMonths,
} from 'date-fns'
import { ensureDate, getReservedDate, isValidDate, split } from 'utils/funcs'
import { ErrorMessage } from '@hookform/error-message'
import useAutoFocus from 'hooks/useAutoFocus'
import composeRefs from '@seznam/compose-react-refs'

const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

const isDate = (x: any): x is Date => x && x instanceof Date && isValidDate(x)

interface FormDateProps {
	name: string
	label?: string
	description?: string
	placeholder?: string
	defaultValue?: any
	hideOptional?: boolean
	validations?: RegisterOptions
	placement?: Placement
	offset?: number | [number, number]
	disabled?: boolean
	minDate?: Date
	maxDate?: Date
	disableHolidays?: boolean
	inputDisabled?: boolean
	autoFocus?: boolean
	className?: ClassValue
	inputClassName?: ClassValue
}

const FormDate: FC<FormDateProps> = ({
	name,
	label,
	description,
	placeholder = 'DD/MM/YYYY',
	defaultValue,
	hideOptional,
	validations = {},
	placement = 'bottom-end',
	offset = 10,
	disabled,
	minDate,
	maxDate,
	inputDisabled,
	disableHolidays = false,
	autoFocus = false,
	className,
	inputClassName,
}) => {
	const {
		formState: { isSubmitting, errors },
	} = useFormContext()

	const error: { type: string; message: string } | undefined = path(split(name), errors)

	const isRequired = !!validations.required

	const {
		field: { onChange, onBlur, value },
	} = useController({ name, defaultValue, rules: validations })

	const [display, setDisplay] = useState(isDate(value) ? format(value, 'dd/MM/yyyy') : '')
	const [context, setContext] = useState<Date>(startOfMonth(isDate(value) ? value : new Date()))
	const [prevValue, setPrevValue] = useState<Date | null | undefined>()

	useEffect(() => {
		if (prevValue !== value) {
			if (value) {
				let ensuredDate = ensureDate(value)
				setDisplay(isDate(ensuredDate) ? format(ensuredDate, 'dd/MM/yyyy') : '')
				setPrevValue(value)
			} else {
				setDisplay('')
				setContext(new Date())
			}
		}
	}, [prevValue, value])

	const {
		open,
		setOpen,
		ignoreClass,
		ref: dismissableRef,
	} = useDismissable({
		onOpen: () => {
			try {
				if (value) {
					let dateValue = ensureDate(value)
					setDisplay(format(dateValue, 'dd/MM/yyyy'))
					setContext(dateValue)
				}
			} catch (error) {
				console.log('error', error)
				setDisplay('')
				setContext(new Date())
			}
		},
	})

	const [reference, tooltip] = usePopper({
		placement,
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: { offset: offset instanceof Array ? offset : [0, offset] },
			},
		],
	})

	const preDays = new Array(getISODay(startOfMonth(context)) - 1)
		.fill(null)
		.map((_, i) => subDays(endOfMonth(subMonths(context, 1)), i))
		.reverse()

	const postDays = new Array(7 - getISODay(endOfMonth(context)))
		.fill(null)
		.map((_, i) => addDays(startOfMonth(addMonths(context, 1)), i))

	const days = new Array(getDaysInMonth(context)).fill(null).map((_, i) => addDays(startOfMonth(context), i))

	const select = (day: Date) => {
		onChange(day)
		setOpen(false)

		setDisplay(format(day, 'dd/MM/yyyy'))
	}

	const autoFocusRef = useAutoFocus<HTMLInputElement>(autoFocus)

	return (
		<div className={clsx(className)} ref={dismissableRef}>
			<label>
				{label && (
					<div className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200">
						{label}{' '}
						{!isRequired && !hideOptional && (
							<span className="text-gray-400 dark:text-gray-300 font-normal text-xs leading-4 opacity-75">
								(optional)
							</span>
						)}
					</div>
				)}

				<div className={clsx('relative rounded-md', label && 'mt-1')}>
					<input
						type="text"
						value={display}
						onChange={(e) => setDisplay(e.target.value)}
						placeholder={placeholder}
						disabled={isSubmitting || disabled}
						// @ts-ignore
						ref={composeRefs(reference, autoFocusRef)}
						onClick={() => setOpen(true)}
						onKeyDown={(e) => {
							if (inputDisabled) {
								e.preventDefault()
							} else {
								setOpen(false)
							}
						}}
						onBlur={() => {
							if (!display) {
								onChange(null)
							} else {
								let displayDate = parse(display, 'dd/MM/yyyy', new Date())

								if (isValidDate(displayDate)) {
									onChange(displayDate)
								} else {
									setDisplay(isDate(value) ? format(value, 'dd/MM/yyyy') : '')
								}
							}

							onBlur()
						}}
						className={clsx(
							'form-input block w-full sm:text-sm sm:leading-5',
							error &&
								'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
							inputClassName,
							ignoreClass
						)}
					/>

					<div className={clsx(!open && 'hidden', 'z-10')} ref={tooltip}>
						<div className="mt rounded-md shadow-lg bg-white dark:bg-gray-700 w-80 overflow-hidden">
							<div className="flex items-center dark:bg-gray-750 p-4 border-b border-gray-100 dark:border-gray-600">
								<button
									type="button"
									onClick={() => setContext((c) => subMonths(c, 1))}
									className="inline-flex items-center font-medium border transition ease-in-out duration-150 disabled:cursor-not-allowed border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500 text-gray-700 active:text-gray-800 hover:text-gray-500 dark:text-gray-200 dark:active:text-white dark:hover:text-white bg-white active:bg-gray-50 dark:bg-gray-800 dark:active:bg-gray-900 dark:hover:bg-gray-750 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-700 px-3 py-2 text-sm leading-5 rounded-md"
								>
									<ArrowLeftIcon className="w-4 h-4" />
								</button>

								<div className="flex-1 flex items-center justify-center text-sm font-medium">
									{format(context, 'MMMM yyyy')}
								</div>

								<button
									type="button"
									onClick={() => setContext((c) => addMonths(c, 1))}
									className="inline-flex items-center font-medium border transition ease-in-out duration-150 disabled:cursor-not-allowed border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500 text-gray-700 active:text-gray-800 hover:text-gray-500 dark:text-gray-200 dark:active:text-white dark:hover:text-white bg-white active:bg-gray-50 dark:bg-gray-800 dark:active:bg-gray-900 dark:hover:bg-gray-750 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-700 px-3 py-2 text-sm leading-5 rounded-md"
								>
									<ArrowRightIcon className="w-4 h-4" />
								</button>
							</div>

							<div className="p-4 grid grid-cols-7">
								{weekDays.map((day, i) => (
									<div
										key={i}
										className="col-span-1 w-8 h-8 flex items-center justify-center rounded m-1 cursor-default text-sm text-gray-400"
									>
										{day}
									</div>
								))}

								{preDays.map((day, i) => (
									<div
										key={i}
										className="col-span-1 w-8 h-8 flex items-center justify-center rounded text-gray-300 dark:text-gray-500 m-1 cursor-default text-sm font-medium"
									>
										{format(day, 'd')}
									</div>
								))}

								{days.map((day, i) => (
									<button
										key={i}
										type="button"
										className={clsx(
											'col-span-1 w-7 h-7 flex items-center justify-center rounded m-2 cursor-pointer disabled:pointer-events-none text-sm font-medium hover:bg-primary-100 dark:hover:bg-gray-800 disabled:opacity-50',
											isSameDay(new Date(), day) && 'text-primary-400 ring-2 ring-primary-300',
											value && isSameDay(ensureDate(value), day) && 'bg-primary-600 text-white'
										)}
										onClick={() => select(day)}
										disabled={
											(minDate && isBefore(day, minDate)) ||
											(maxDate && isAfter(day, maxDate)) ||
											(disableHolidays && !!getReservedDate(day))
										}
										title={getReservedDate(day)}
									>
										{format(day, 'd')}
									</button>
								))}

								{postDays.map((day, i) => (
									<div
										key={i}
										className="col-span-1 w-8 h-8 flex items-center justify-center rounded text-gray-300 dark:text-gray-500 m-1 cursor-default text-sm font-medium"
									>
										{format(day, 'd')}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</label>

			{description && !error && <p className="mt-2 text-sm text-gray-500">{description}</p>}

			<ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-400">{message}</p>}
			/>
		</div>
	)
}

export default FormDate
