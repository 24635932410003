import { FC } from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import { CaseFileModel } from 'types/models'
import { removeFileExtension } from 'utils/funcs'
import api from 'api'

// hooks
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'

// components
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Actions from 'components/Actions'
import { toast } from 'components/toast'

interface OpenRenameFile {
	file: CaseFileModel
	refetch: () => Promise<unknown>
	callback?: (x: string) => void | Promise<any>
}

const openRenameFile = ({ file, refetch, callback }: OpenRenameFile) => {
	return openModal({
		title: 'Rename File',
		render: (close) => <RenameFileForm file={file} refetch={refetch} close={close} callback={callback} />,
	})
}

interface FormData {
	name: string
}

const RenameFileForm: FC<OpenRenameFile & { close: () => void }> = ({ file, refetch, close, callback }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: { name: removeFileExtension(file.name) },
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			const objData = {
				id: file.id,
				name: `${formData.name}${file.fileType}`,
				description: file.description,
				permissions: file.permissions,
				caseFileCategory: file.caseFileCategory,
				case: {
					id: file.case.id,
				},
			}

			await api.put(`/CaseFiles/${file.id}`, objData)
			await refetch()
			if (callback) callback(`${formData.name}${file.fileType}`)
			toast({ title: 'File name updated' })
			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<FormInput
					name="name"
					validations={{
						required: {
							value: true,
							message: 'File name required',
						},
					}}
				/>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openRenameFile
