import { gql } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { endOfMonth, getDaysInMonth, isSameDay, parseISO, startOfMonth } from 'date-fns'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { FC, useState } from 'react'
import { GQLConnection, GQLEventType } from 'types/gql'
import Calendar, { CalendarSchema } from 'components/Calendar'
import MonthSwitcher from 'components/MonthSwitcher'
import Stack from 'components/Stack'
import { addDays, startOfDay } from 'date-fns/esm'
import { endOfDay } from 'date-fns/fp'
import Actions from './Actions'
import { ActionType, None } from 'types'
import { navigate } from './NavigateHoist'

const query = gql`
	query ($orderBy: EventOrderBy!, $search: EventSearch) {
		Events(orderBy: $orderBy, search: $search) {
			items {
				id
				startDate
			}
		}
	}
`

interface EventCalendarProps {
	selected?: Date
	actions?: (ActionType | None)[]
}

const EventCalendar: FC<EventCalendarProps> = ({ selected, actions = [] }) => {
	const [startDate, setStartDate] = useState(startOfMonth(new Date()))
	const [endDate, setEndDate] = useState(endOfMonth(new Date()))

	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const { data, loading } = useBufferedQuery<{ Events: GQLConnection<GQLEventType> }>(query, {
		variables: {
			orderBy: 'date_DESC',
			search: { status: [1], isPostponed: false, from: startDate.toISOString(), to: endDate.toISOString() },
		},
	})

	const items = new Array(getDaysInMonth(startDate)).fill(null).map((_, i) => ({
		date: addDays(startDate, i),
		value:
			data?.Events.items?.filter((x) => x.startDate && isSameDay(parseISO(x.startDate), addDays(startDate, i))) ||
			[],
	}))

	const schema: CalendarSchema<GQLEventType[]> = {
		size: 'sm',

		onClick: (x, date) =>
			navigate('/diary', {
				replace: true,
				state: {
					...state,
					search: {
						...(state?.search || {}),
						from: startOfDay(date).toISOString(),
						to: endOfDay(date).toISOString(),
					},
				},
			}),

		className: (x, date) => [
			selected && isSameDay(selected, date)
				? 'border-2 border-primary-600'
				: 'border-2 border-white dark:border-gray-900',
			'focus:outline-none',
		],

		render: (x) =>
			(x.length || 0) > 0 && (
				<div className="text-white flex items-center justify-center rounded-full bg-primary-500 dark:bg-opacity-50 w-6 h-6">
					{x.length || ''}
				</div>
			),
	}

	return (
		<Stack>
			<div className="flex justify-end space-x-3">
				<Actions
					actions={[
						...actions,
						{
							title: 'Today',
							rounded: 'md',
							onClick: () => {
								setStartDate(startOfMonth(new Date()))
								setEndDate(endOfMonth(new Date()))
							},
						},
					]}
				/>
				<MonthSwitcher
					value={[startDate, endDate]}
					onChange={(values) => {
						setStartDate(values[0])
						setEndDate(values[1])
					}}
				/>
			</div>

			<Calendar
				contextDate={startDate}
				items={items}
				schema={schema}
				isLoading={loading && !data}
				includeWeekend
			/>
		</Stack>
	)
}

export default EventCalendar
