import { Children, FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'

interface InlineProps {
	space?: SpaceType
	children: ReactNode
	className?: ClassValue
}

type SpaceType = 0 | 1 | 2 | 3 | 4 | 6 | 8

const spaces = {
	0: {
		wrapper: '',
		item: '',
	},
	1: {
		wrapper: '-ml-1 -mt-1',
		item: 'pl-1 pt-1',
	},
	2: {
		wrapper: '-ml-2 -mt-2',
		item: 'pl-2 pt-2',
	},
	3: {
		wrapper: '-ml-3 -mt-3',
		item: 'pl-3 pt-3',
	},
	4: {
		wrapper: '-ml-4 -mt-4',
		item: 'pl-4 pt-4',
	},
	6: {
		wrapper: '-ml-6 -mt-6',
		item: 'pl-6 pt-6',
	},
	8: {
		wrapper: '-ml-8 -mt-8',
		item: 'pl-8 pt-8',
	},
}

const Inline: FC<InlineProps> = ({ space = 3, children, className }) => {
	const items = Children.toArray(children)

	return (
		<div className={clsx('flex flex-wrap', spaces[space || 0].wrapper, className)}>
			{items.map((item, i) => (
				<div key={i} className={clsx('flex items-center', spaces[space || 0].item)}>
					{item}
				</div>
			))}
		</div>
	)
}

export default Inline
