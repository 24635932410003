import { FC } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Badge from 'components/Badge'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import api from 'api'
import useData from 'hooks/useData'
import CaseHeader from 'components/CaseHeader'
import Stack from 'components/Stack'
import { LinkIcon, DotsHorizontalIcon } from '@heroicons/react/outline'
import openLinkCases from 'modals/openLinkCases'
import { confirm, toast } from 'alerts'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import { CaseModel } from 'types/models'
import { TableSchema } from 'components/Table'
import { gql } from '@apollo/client'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { GQLCaseType, GQLConnection } from 'types/gql'
import { Auth } from 'Auth'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: CaseOrderBy!, $search: CaseSearch) {
		Cases(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				caseNumber
				applicantName
				accClaimNumber
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				applicant {
					id
					user {
						id
						fullName
					}
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

// interface LinkedCasesProps {
// 	caseId?: string
// }

const LinkedCases: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch: refetchCase } = useData<CaseModel>(`/Cases/${caseId}`, {}, { suspense: true })
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const {
		data,
		loading,
		refetch: refetchGql,
	} = useBufferedQuery<{ Cases: GQLConnection<GQLCaseType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: {
				group: caseData?.group?.id,
				excludeIds: [caseId],
			},
		},
		skip: !caseData?.group?.id,
	})

	const refetch = async () => {
		if (caseData?.group?.id) {
			await refetchGql()
		} else {
			await refetchCase()
		}
	}

	const handleUnlink = (item: GQLCaseType) => {
		confirm({
			title: `Are you sure you want to unlink these cases?`,
			onAccept: async () => {
				try {
					await api.delete(`/CaseGroups/${caseData?.group?.id}/case/${item.id}`)

					toast({ title: 'Cases unlinked' })

					refetch()
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const schema: TableSchema<GQLCaseType> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`/cases/${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
			},
			{
				title: 'ACC Claim Number',
				value: (x) => x.accClaimNumber || '-',
			},
			{
				title: 'Applicant Name',
				value: (x) => x.applicantName || '-',
			},
			{
				title: 'Case Type',
				value: (x) => x.type?.readable,
			},
			{
				title: 'Status',
				value: (x) => <Badge status={x.status?.id}>{x.status?.readable}</Badge>,
			},
		],
		actions: (x) => [
			x.id !== caseData?.id &&
				Auth.is('Admin', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						{
							title: 'Unlink Case',
							intent: 'danger',
							onClick: () => handleUnlink(x),
						},
					],
				},
		],
	}

	const links = caseData ? caseLinks(caseData) : []

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader
						title="Linked Cases"
						actions={[
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
								title: 'Link Cases',
								icon: <LinkIcon className="w-5 h-5" />,
								intent: 'primary',
								onClick: () => caseData && openLinkCases({ caseData, refetch }),
							},
						]}
					/>

					<GQLPaginatedTable schema={schema} set={data?.Cases} isLoading={loading} refetch={refetchGql} />
				</Stack>
			</PageNavbar>
		</>
	)
}

export default LinkedCases
