import { RefCallback, useCallback, useMemo, useRef } from 'react'
import { createPopper, Options } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import offset from '@popperjs/core/lib/modifiers/offset'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'

/**
 * Example implementation to use Popper: https://popper.js.org/
 */
export function usePopper(options?: Partial<Options>): [RefCallback<Element | null>, RefCallback<HTMLElement | null>] {
	const reference = useRef<Element | null>(null)
	const popper = useRef<HTMLElement | null>(null)

	const cleanupCallback = useRef(() => {})

	const instantiatePopper = useCallback(() => {
		if (!reference.current) return
		if (!popper.current) return

		if (cleanupCallback.current) cleanupCallback.current()

		cleanupCallback.current = createPopper(reference.current, popper.current, {
			...options,
			modifiers: [flip, offset, preventOverflow, ...(options?.modifiers || [])],
		}).destroy
	}, [reference, popper, cleanupCallback, options])

	return useMemo(
		() => [
			(referenceDomNode) => {
				reference.current = referenceDomNode
				instantiatePopper()
			},
			(popperDomNode) => {
				popper.current = popperDomNode
				instantiatePopper()
			},
		],
		[reference, popper, instantiatePopper]
	)
}
