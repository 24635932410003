import { FC, useEffect, useState } from 'react'
import clsx, { ClassValue } from 'clsx'
import api from 'api'
import UserDefault from 'components/UserDefault'

interface ImgCache {
	[x: string]: string | null
}

const cache: ImgCache = {}

const blobUrl = (blob: any) => {
	if (!blob.url) {
		blob.url = window.URL.createObjectURL(blob)
	}
	return blob.url
}

interface ImageProps {
	src?: string
	alt?: string
	name?: string
	className?: ClassValue
}

const Image: FC<ImageProps> = ({ src, alt, name, className }) => {
	const url = src?.indexOf('api/') === 0 ? src.replace('api/', '') : src || ''

	const [blob, setBlob] = useState<string | null>(src && src.indexOf('data:') === 0 ? src : null)

	useEffect(() => {
		if (url.indexOf('/static') === 0) {
			setBlob(url)
		} else if (cache[url] || cache[url] === null) {
			setBlob(cache[url])
		} else {
			if (url) {
				api.get<Blob>(url, { responseType: 'blob' }).then((res) => {
					if (!res.data.size) {
						setBlob(null)
						cache[url] = null
					} else {
						setBlob(blobUrl(new Blob([res.data])))
						cache[url] = blobUrl(new Blob([res.data]))
					}
				})
			}
		}
	}, [url])

	return url && blob ? (
		<img className={clsx(className)} src={blob} alt={alt} />
	) : (
		<UserDefault className={clsx(className)} name={name || ''} />
	)
}

export default Image
