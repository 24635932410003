import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'

interface ButtonSpacerProps {
	className?: ClassValue
}

const ButtonSpacer: FC<ButtonSpacerProps> = ({ className }) => (
	<div className={clsx('px-4 py-2 text-sm opacity-0 leading-5 pointer-events-none', className)}>&nbsp;</div>
)

export default ButtonSpacer
