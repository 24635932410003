export enum HEARING_TYPE {
	CMC = 1,
	Review = 2,
	Mediation = 3,
}

export const hearingType = {
	1: 'CMC',
	2: 'Review Hearing',
	3: 'Mediation',
}

export const HearingType = {
	readable: (x: HEARING_TYPE) => hearingType[x],
	options: Object.entries(hearingType).map(([key, val]) => ({ label: val, value: +key })),
}
