export enum CASE_APPEAL_STATUS {
	'RemoveAppealStatus' = 0,
	'DecisionUnderAppeal' = 1,
	'AppealWithdrawn' = 2,
	'AppealDismissed' = 3,
	'ReviewDecisionModified' = 4,
	'ReviewDecisionQuashedSubstituted' = 5,
}

export const caseAppealStatus = {
	0: 'Remove Appeal Status',
	1: 'Decision under Appeal',
	2: 'Appeal Withdrawn',
	3: 'Appeal Outcome: Appeal Dismissed',
	4: 'Appeal Outcome: Review Decision modified',
	5: 'Appeal Outcome: Review Decision quashed/substituted',
}

export const CaseAppealStatus = {
	readable: (x: CASE_APPEAL_STATUS) => caseAppealStatus[x],
	options: Object.entries(caseAppealStatus).map(([key, val]) => ({ label: val, value: +key })),
}
