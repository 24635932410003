import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchFormProps } from 'components/PaginatedTable'
import { SearchIcon } from '@heroicons/react/outline'
import { Undo } from 'components/Icon'
import { CaseAppealStatus, CaseStatus, CaseType, CASE_STATUS, ClaimType, USER_ROLE } from 'types/enums'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import { useForm } from 'react-hook-form'
import Actions from 'components/Actions'
import FormSelect from 'components/Form/FormSelect'
import { PresetValue, PaginationSearch, OptionType } from 'types'
import Tabs from 'components/Tabs'
import Divider from 'components/Divider'
import { gql } from '@apollo/client'
import { GQLConnection, GQLUserType, GQLOrganizationType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { Auth } from 'Auth'

const query = gql`
	query {
		Organizations {
			items {
				id, name
			}
		}
		caseManagers: Users(search: { role: "${USER_ROLE.CaseManager}" }) {
			items {
				...userFields
			}
		}
		reviewers: Users(search: { role: "${USER_ROLE.Reviewer}" }) {
			items {
				...userFields
			}
		}
		peerReviewers: Users(search: { role: "${USER_ROLE.PeerReviewer}" }) {
			items {
				...userFields
			}
		}
		mediators: Users(search: { role: "${USER_ROLE.Mediator}" }) {
			items {
				...userFields
			}
		}
		respondents: Users(search: { role: "${USER_ROLE.Respondent}" }) {
			items {
				...userFields
			}
		}
	}

	fragment userFields on User {
		id
		fullName
	}
`

export interface PresetValues {
	status?: PresetValue<number[] | string>
	organization?: PresetValue<string>
	caseManager?: PresetValue<string>
	reviewer?: PresetValue<string>
	peerReviewer?: PresetValue<string>
	respondent?: PresetValue<string>
	mediator?: PresetValue<string>
}

interface Props {
	presetValues?: PresetValues
}

const SearchForm: FC<SearchFormProps & Props> = ({ setSearch, close, presetValues }) => {
	const location = useLocation()

	const state = (location.state as { search: PaginationSearch })?.search

	const getDefaultValues = (state?: any, presetValues?: any) => {
		return {
			caseNumber: state?.caseNumber || '',
			type: state?.type ? state?.type[0] : '',
			status: presetValues?.status?.value || (state?.status ? state?.status[0] : ''),
			appealStatus: state?.appealStatus ? state?.appealStatus[0] : '',
			accClaimNumber: state?.accClaimNumber || '',
			applicantName: state?.applicantName || '',
			organization: presetValues?.organization?.value || state?.organization || null,

			caseManager: presetValues?.caseManager?.value || state?.caseManager || null,
			reviewer: presetValues?.reviewer?.value || state?.reviewer || null,
			mediator: presetValues?.mediator?.value || state?.mediator || null,
			peerReviewer: presetValues?.peerReviewer?.value || state?.peerReviewer || null,
			respondent: presetValues?.respondent?.value || state?.respondent || null,

			applicant: {
				firstName: state?.applicant?.firstName || '',
				lastName: state?.applicant?.lastName || '',
				email: state?.applicant?.email || '',
				phoneNumber: state?.applicant?.phoneNumber || '',
			},
			advocate: {
				firstName: state?.advocate?.firstName || '',
				lastName: state?.advocate?.lastName || '',
				email: state?.advocate?.email || '',
				phoneNumber: state?.advocate?.phoneNumber || '',
			},

			reviews: {
				reviewNumber: '',
				issueCode: '',
				...state?.reviews,
			},
		}
	}

	const formContext = useForm<PaginationSearch>({
		defaultValues: getDefaultValues(state, presetValues),
	})

	const handleSubmit = (formData: PaginationSearch) => {
		const search: PaginationSearch = {}

		if (formData.caseNumber) search.caseNumber = formData.caseNumber
		if (formData.type) search.type = [formData.type]
		if (formData.status) search.status = [formData.status]
		if (formData.appealStatus) search.appealStatus = [formData.appealStatus]
		if (formData.organization) search.organization = formData.organization
		if (formData.accClaimNumber) search.accClaimNumber = formData.accClaimNumber
		if (formData.applicantName) search.applicantName = formData.applicantName

		if (formData.caseManager) search.caseManager = formData.caseManager
		if (formData.reviewer) search.reviewer = formData.reviewer
		if (formData.mediator) search.mediator = formData.mediator
		if (formData.peerReviewer) search.peerReviewer = formData.peerReviewer
		if (formData.respondent) search.respondent = formData.respondent

		if (formData.applicant.firstName)
			search.applicant = { ...search.applicant, firstName: formData.applicant.firstName }
		if (formData.applicant.lastName)
			search.applicant = { ...search.applicant, lastName: formData.applicant.lastName }
		if (formData.applicant.email) search.applicant = { ...search.applicant, email: formData.applicant.email }
		if (formData.applicant.phoneNumber)
			search.applicant = { ...search.applicant, phoneNumber: formData.applicant.phoneNumber }

		if (formData.advocate.firstName)
			search.advocate = { ...search.advocate, firstName: formData.advocate.firstName }
		if (formData.advocate.lastName) search.advocate = { ...search.advocate, lastName: formData.advocate.lastName }
		if (formData.advocate.email) search.advocate = { ...search.advocate, email: formData.advocate.email }
		if (formData.advocate.phoneNumber)
			search.advocate = { ...search.advocate, phoneNumber: formData.advocate.phoneNumber }

		if (formData.reviews.reviewNumber)
			search.reviews = { ...search.reviews, reviewNumber: formData.reviews.reviewNumber }
		if (formData.reviews.issueCode) search.reviews = { ...search.reviews, issueCode: formData.reviews.issueCode }

		setSearch(search)
	}

	const {
		data = {
			Organizations: { items: [] },
			caseManagers: { items: [] },
			reviewers: { items: [] },
			peerReviewers: { items: [] },
			mediators: { items: [] },
			respondents: { items: [] },
		},
	} = useBufferedQuery<{
		Organizations: GQLConnection<GQLOrganizationType>
		caseManagers: GQLConnection<GQLUserType>
		reviewers: GQLConnection<GQLUserType>
		peerReviewers: GQLConnection<GQLUserType>
		mediators: GQLConnection<GQLUserType>
		respondents: GQLConnection<GQLUserType>
	}>(query, { skip: Auth.is('Applicant') })

	const [tab, setTab] = useState(0)

	return (
		<Form
			context={formContext}
			onSubmit={handleSubmit}
			className="px-6 pb-6 pt-2 bg-gray-50 dark:bg-gray-750 border-t border-gray-200 dark:border-gray-600"
		>
			<div className="pb-4 mb-6 flex relative justify-between items-center py-2 border-b border-gray-200 dark:border-gray-600">
				<h3 className="text-lg leading-5 font-medium text-gray-900 dark:text-white">Search</h3>

				<Tabs
					activeIndex={tab}
					theme="links"
					items={[
						{ title: 'Case Info', onClick: () => setTab(0), key: 0 },
						!Auth.is('Applicant') && { title: 'Case Parties', onClick: () => setTab(1), key: 1 },
						{ title: 'Applicant User', onClick: () => setTab(2), key: 2 },
						!Auth.is('Applicant') && { title: 'Advocates', onClick: () => setTab(3), key: 3 },
						{ title: 'Reviews', onClick: () => setTab(4), key: 4 },
					]}
				/>
			</div>

			<Stack className={tab !== 0 && 'hidden'}>
				<div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-6 sm:space-y-0">
					<Stack className="flex-1">
						{!Auth.is('Applicant') && (
							<FormInput
								name="applicantName"
								label="Applicant Name (On Case Request)"
								hideOptional
								autoFocus
							/>
						)}

						<FormInput
							name="accClaimNumber"
							label="ACC Claim #"
							hideOptional
							autoFocus={Auth.is('Applicant')}
						/>

						<FormInput name="caseNumber" label="Case Number" hideOptional />
					</Stack>

					<Stack className="flex-1">
						{!Auth.is('Applicant') && !presetValues?.organization?.hidden && (
							<FormSelect
								name="organization"
								label="Organisation"
								options={
									data.Organizations.items?.map((x) => ({ label: x.name || '', value: x.id })) || []
								}
								clearable={!presetValues?.organization?.locked || true}
								disabled={presetValues?.organization?.locked}
								hideOptional
							/>
						)}

						<FormSelect name="type" label="Type" options={CaseType.options} clearable hideOptional />

						{presetValues?.status?.hidden !== true && (
							<FormSelect
								name="status"
								label="Status"
								options={
									Auth.is('Admin', 'CaseManager', 'FENZAdmin')
										? CaseStatus.options
										: CaseStatus.options.filter(
												(x) =>
													x.value !== CASE_STATUS.Request && x.value !== CASE_STATUS.Pending
										  )
								}
								clearable={!presetValues?.status?.locked || true}
								disabled={presetValues?.status?.locked}
								hideOptional
							/>
						)}

						<FormSelect
							name="appealStatus"
							label="Appeal Status"
							options={CaseAppealStatus.options.filter((x: OptionType) => x.value !== 0)}
							clearable
							hideOptional
						/>
					</Stack>
				</div>
			</Stack>

			<Stack className={tab !== 1 && 'hidden'}>
				<div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-6 sm:space-y-0">
					<Stack className="flex-1">
						<FormSelect
							name="caseManager"
							label="Case Manager"
							options={
								data.caseManagers.items?.map((x) => ({
									label: x.fullName || '',
									value: x.id,
								})) || []
							}
							clearable={!presetValues?.caseManager?.locked || true}
							disabled={presetValues?.caseManager?.locked}
							hideOptional
						/>

						<FormSelect
							name="reviewer"
							label="Reviewer"
							options={data.reviewers.items?.map((x) => ({ label: x.fullName || '', value: x.id })) || []}
							clearable={!presetValues?.reviewer?.locked || true}
							disabled={presetValues?.reviewer?.locked}
							hideOptional
						/>

						<FormSelect
							name="peerReviewer"
							label="Peer Reviewer"
							options={
								data.peerReviewers.items?.map((x) => ({
									label: x.fullName || '',
									value: x.id,
								})) || []
							}
							clearable={!presetValues?.peerReviewer?.locked || true}
							disabled={presetValues?.peerReviewer?.locked}
							hideOptional
						/>
					</Stack>

					<Stack className="flex-1">
						<FormSelect
							name="respondent"
							label="Respondent"
							options={
								data.respondents.items?.map((x) => ({ label: x.fullName || '', value: x.id })) || []
							}
							clearable={!presetValues?.respondent?.locked || true}
							disabled={presetValues?.respondent?.locked}
							hideOptional
						/>

						<FormSelect
							name="mediator"
							label="Mediator"
							options={data.mediators.items?.map((x) => ({ label: x.fullName || '', value: x.id })) || []}
							clearable={!presetValues?.mediator?.locked || true}
							disabled={presetValues?.mediator?.locked}
							hideOptional
						/>
					</Stack>
				</div>
			</Stack>

			<Stack className={tab !== 2 && 'hidden'}>
				<div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-6 sm:space-y-0">
					<Stack className="flex-1">
						<FormInput name="applicant.firstName" label="First Name" hideOptional />

						{!Auth.is('Applicant') && <FormInput name="applicant.email" label="Email" hideOptional />}
					</Stack>

					<Stack className="flex-1">
						<FormInput name="applicant.lastName" label="Last Name" hideOptional />

						{!Auth.is('Applicant') && (
							<FormInput name="applicant.phoneNumber" label="Phone Number" hideOptional />
						)}
					</Stack>
				</div>
			</Stack>

			<Stack className={tab !== 3 && 'hidden'}>
				<div className="flex space-x-4">
					<Stack className="flex-1">
						<FormInput name="advocate.firstName" label="First Name" hideOptional />

						<FormInput name="advocate.email" label="Email" hideOptional />
					</Stack>

					<Stack className="flex-1">
						<FormInput name="advocate.lastName" label="Last Name" hideOptional />

						<FormInput name="advocate.phoneNumber" label="Phone Number" hideOptional />
					</Stack>
				</div>
			</Stack>

			<Stack className={tab !== 4 && 'hidden'}>
				<div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-6 sm:space-y-0">
					<Stack className="flex-1">
						<FormInput name="reviews.reviewNumber" label="Review Number" hideOptional />
					</Stack>

					<Stack className="flex-1">
						<FormSelect
							name="reviews.issueCode"
							label="Issue Code"
							options={ClaimType.options}
							clearable
							hideOptional
						/>
					</Stack>
				</div>
			</Stack>

			<Divider className="my-6" />

			<div className="flex justify-end">
				<Actions
					actions={[
						{
							title: 'Reset',
							icon: <Undo className="w-4 h-5" />,
							onClick: () => {
								setSearch({})
								formContext.reset(getDefaultValues())
							},
						},
						{
							title: 'Search',
							icon: <SearchIcon className="w-4 h-5" />,
							intent: 'primary',
							type: 'submit',
						},
					]}
				/>
			</div>
		</Form>
	)
}

export default SearchForm
