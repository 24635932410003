export const claimType = {
	1: 'W3: Accident Compensation Act 1982 matters',
	2: 'W5: Backdated Attendant Care Compensation Reviews - Accident Compensation Act 1982',
	3: 'X24: Premiums-classification',
	4: 'X25: Experience rating',
	5: 'VRR1: Vehicle risk rating - general',
	6: 'VRR2: Vehicle risk rating - regulations',
	7: 'X30: Liable earnings/discounts',
	8: 'X31: Multi issues - levy',
	9: 'X32: CPX',
	10: 'W4: Maori / Pacific protocol reviews',
	11: "W8: Code of ACC claimant's rights",
	12: 'X2: Cover - is there a personal injury',
	13: 'X3: Cover - gradual process',
	14: 'X4: Cover - medical misadventure',
	15: 'X5: Cover - criminal injury',
	16: 'X26: Cover - mental injury',
	17: 'X29: Cover - Treatment injury',
	18: 'Y1: Cover - accident',
	19: 'Y2: Overseas injuries (residents etc.)',
	20: 'Y3: Cover - Motor Vehicle Injuries',
	21: 'Y5: Death benefits - Compensation',
	22: 'Y6: Death benefits - Spouse',
	23: 'Y7: Death benefits - Funeral Grant',
	24: 'Y8: Death benefits - Survivors Grant',
	25: 'Y9: Death benefits - Child and other dependents',
	26: 'Y10: Death benefits - Child care',
	27: 'Y4: Disentitlement',
	28: 'X8: Suspension/cessation of entitlements',
	29: 'X9: Suspension/non-compliance',
	30: 'Y25: Independence allowance',
	31: 'Y26: Lump sums (2001 Act)',
	32: 'Z6: Jurisdiction - Code',
	33: 'Z5: Jurisdiction - other',
	34: 'Y11: Out of time - late commencement of review',
	35: 'Y12: Out of time - failure to issue decision',
	36: 'Z2: Out of time - late lodgement of application for review',
	37: 'Y13: Overpayments',
	38: 'Z1: Physical treatment regulations',
	39: 'X28: Elective surgery',
	40: 'X10: Rehabilitation plan',
	41: 'X11: Rehabilitation - Complex Personal Injury',
	42: 'X12: Rehabilitation - Education support',
	43: 'X13: Rehabilitation - Housing support',
	44: 'X14: Rehabilitation - Motor Vehicles',
	45: 'X15: Rehabilitation - Training for Independent Living',
	46: 'Y16: Rehabilitation - Aids and Appliances',
	47: 'Y17: Rehabilitation - Attendant Care, Home Help, Child care',
	48: 'Z3: Transport to treatment, accommodation, escort etc',
	49: 'X6: Entitlement to weekly compensation',
	50: 'X17: Weekly abatement of compensation (calculation)',
	51: 'X18: Weekly compensation (calculation)',
	52: 'X19: Weekly compensation - self employed (calculation)',
	53: 'X20: Weekly compensation - employee and self employed (calculation)',
	54: 'X21: Weekly compensation - shareholder employee (calculation)',
	55: 'X22: Weekly compensation - estimated earnings (calculation)',
	56: 'Y18: Weekly compensation - first week',
	57: 'Y19: Weekly compensation - loss of potential earnings',
	58: 'Y20: Weekly compensation - interest for late payment',
	59: 'Y21: Weekly compensation - extension of entitlement',
	60: 'Y22: Weekly compensation - voluntary cover',
	61: 'Y23: Upper age limits',
	62: 'X16: Vocational rehabilitation',
	63: 'X7: Work capacity assessment',
	64: 'X23: Work injury',
	65: 'W1: Multi issue disputes',
	66: 'W2: Multi party disputes (e.g.: Insurer Disputes under ss 105-114 of the 1998 Act)',
	67: 'W7: Remote Claim Unit reviews',
	68: 'Z4: Prior approval issues',
	69: 'Z7: Review costs (following withdrawal where ACC does not change or modify its decision)',
	70: 'Z8: Accredited Employer Lump Sum Technical determination',
}

export const ClaimType = {
	getId: (name: string) => +Object.fromEntries(Object.entries(claimType).map(([a, b]) => [b, a]))[name],
	options: Object.entries(claimType).map(([_, val]) => ({ label: val, value: val })),
}
