import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CaseStatusBadge from 'components/CaseStatusBadge'
import Form from 'components/Form/Form'
import ErrorBadge from 'components/Form/ErrorBadge'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import { fieldsBag, includer, getFullName, ensureDate, isValidDate } from 'utils/funcs'
import { series } from 'utils/funcs'
import { ACCOrgId, FENZOrgId } from 'utils/constants'
import { newField, newParty, newHearing } from './utils/ValidationSchema'
import CaseInformation from './CaseInformation'
import Documents from './Documents'
import Hearings from './Events'
import Personnel from './Participants'
import useData from 'hooks/useData'
import api from 'api'
import { toast, confirm } from 'alerts'
import { isNotNone } from 'types'
import clsx from 'clsx'
import {
	addMinutes,
	setMinutes,
	getMinutes,
	addDays,
	subDays,
	endOfDay,
	setHours,
	getHours,
	addMonths,
	format,
} from 'date-fns'
import {
	UserIcon,
	UsersIcon,
	HashtagIcon,
	InformationCircleIcon,
	PhoneIcon,
	DocumentTextIcon,
	ArchiveIcon,
} from '@heroicons/react/outline'
import {
	CASE_TYPE,
	CASE_PARTY_ROLE,
	HEARING_TYPE,
	FORUM_TYPE,
	HEARING_STATUS,
	HearingType,
	CASE_STATUS,
	CASE_COMPLEXITY,
	ADR_TYPE,
	CASE_PROCESS,
} from 'types/enums'
import {
	CaseFieldModel,
	CasePartyModel,
	CaseModel,
	OrganizationModel,
	UserModel,
	HearingModel,
	CaseFileModel,
} from 'types/models'
import queryClient from 'utils/QueryClient'
import openApplication from 'modals/openApplication'
import openFileUpload from 'modals/openFileUpload'
import { navigate } from 'components/NavigateHoist'

type Override<T1, T2> = Omit<T1, keyof T2> & T2

export interface FormHearingType {
	id?: string
	name?: string
	description?: string
	hearingType?: HEARING_TYPE
	forumType?: FORUM_TYPE
	venue?: string
	venueId?: string
	startDate?: Date | null
	time?: string | null
	duration?: number | null
	setByDate?: Date | null
	lodgementDate?: Date | null
	zoomMeeting?: string
	accessibility?: string
	interpreterRequired?: boolean
	cultural?: string
	security?: string
	other?: string
	roomBooked?: boolean
	travelBooked?: boolean
	roomHireDetails?: string
	travelArrangements?: string
	agreeToMediateApplicant?: boolean
	agreeToMediateDefendant?: boolean
	caseHearingStatus?: HEARING_STATUS
	attendees?: {
		caseManager: {
			user: boolean
			parties: boolean[]
		}
		applicant: {
			user: boolean
			parties: boolean[]
		}
		reviewer: {
			user: boolean
			parties: boolean[]
		}
		mediator: {
			user: boolean
			parties: boolean[]
		}
		peerReviewer: {
			user: boolean
			parties: boolean[]
		}
		respondent: {
			user: boolean
			parties: boolean[]
		}
	}
	reviews: { [id: string]: boolean }
}

interface FormData {
	caseType?: CASE_TYPE
	caseManager?: UserModel
	originalRequestDate?: string | Date
	created?: string | Date
	parties: {
		applicant: CasePartyModel
		mediator: CasePartyModel
		reviewer: CasePartyModel
		peerReviewer: CasePartyModel
		caseManager: CasePartyModel
		respondent: CasePartyModel
		adjudicator: CasePartyModel
		facilitator: CasePartyModel
	}
	fields: {
		applicantName: CaseFieldModel
		careManagement: CaseFieldModel
		accClaimNumber: CaseFieldModel
		careManagementPlan: CaseFieldModel
		specialInstructions: CaseFieldModel
		triageNotes: CaseFieldModel
		careIndication: CaseFieldModel
		issueCode: CaseFieldModel
		zoom: CaseFieldModel
		complex: CaseFieldModel
		// for fenz
		// process: CaseFieldModel
	}
	hearings: Partial<FormHearingType>[]
	organization?: OrganizationModel
	reviews: {
		id?: string
		reviewNumber: string
		issueCode: string
		accDecisionDate?: string
		lodgementDate: string
		setByDate?: string
	}[]
	process: CASE_PROCESS
}

interface PendingCaseProps {
	caseId?: string
}

const PendingCase: FC<PendingCaseProps> = ({ caseId }) => {
	const location = useLocation()
	const state = (location?.state as { tab?: number } | null) || {}
	if (!state?.tab) state.tab = 0
	const isPublishing = useRef(false)
	const { data: item, refetch } = useData<CaseModel>(`/Cases/${caseId}`, {}, { suspense: true })
	const fields = item?.fields ? fieldsBag(item.fields) : {}

	const [isSaving, setSaving] = useState(false)

	useEffect(() => {
		if ((item?.status || 0) >= CASE_STATUS.Open) {
			navigate(`/cases/${caseId}`)
		}
	}, [item, caseId])

	const formContext = useForm<FormData>({
		criteriaMode: 'all',
		shouldUnregister: false,
		defaultValues: {
			caseType: item?.caseType || CASE_TYPE.Review,
			caseManager: item?.caseManager,
			originalRequestDate: item?.originalRequestDate,
			created: item?.created,
			parties: {
				applicant: item?.applicant || newParty(null, CASE_PARTY_ROLE.Applicant),
				mediator: item?.mediator || newParty(null, CASE_PARTY_ROLE.Mediator),
				reviewer: item?.reviewer || newParty(null, CASE_PARTY_ROLE.Reviewer),
				peerReviewer: item?.peerReviewer || newParty(null, CASE_PARTY_ROLE.PeerReviewer),
				caseManager: item?.caseManager || newParty(null, CASE_PARTY_ROLE.CaseManager),
				respondent: item?.respondent || newParty(null, CASE_PARTY_ROLE.Respondent),
				adjudicator: item?.adjudicator || newParty(null, CASE_PARTY_ROLE.Adjudicator),
				facilitator: item?.facilitator || newParty(null, CASE_PARTY_ROLE.Facilitator),
			},
			fields: {
				...newField('applicantName', fields.applicantName, ''),
				...newField('careManagement', fields.careManagement, false, true),
				...newField('accClaimNumber', fields.accClaimNumber, ''),
				...newField('careManagementPlan', fields.careManagementPlan, ''),
				...newField('specialInstructions', fields.specialInstructions, ''),
				...newField('triageNotes', fields.triageNotes, ''),
				...newField('careIndication', fields.careIndication, ''),
				...newField('issueCode', fields.issueCode, ''),
				...newField('zoom', fields.zoom, ''),
				...newField('complex', fields.complex, CASE_COMPLEXITY.Standard),
			},
			// hearings: item?.hearings.length ? item?.hearings.map((x) => newHearing(item, x)) : [newHearing(item)],
			// organization: item?.organization || { id: ACCOrgId, name: 'ACC' },
			hearings: item?.hearings.length
				? item?.hearings.map((x) => newHearing(item, x))
				: item?.organization?.id === FENZOrgId
				? []
				: [newHearing(item)],
			organization: item?.organization || { id: ACCOrgId, name: 'ACC' },
			reviews: item?.reviews && item?.reviews.length ? item?.reviews : [],
			// for fenz
			process: item?.process || CASE_PROCESS.Unknown,
		},
	})

	const caseType = formContext.watch('caseType')
	const organization = formContext.watch('organization')
	const process = formContext.watch('process')

	const { isSubmitting } = formContext.formState

	const handleSupportingParties = async (prev: CasePartyModel, record: CasePartyModel) => {
		if (!prev) return
		// if there are any supporting parties to this case party
		if (prev.id) {
			let prevParties = prev.parties
			let newParties = record.parties

			// for each supporting party that already existed
			if (prevParties)
				await series(
					prevParties.filter((x) => x),
					async (party) => {
						const found = newParties.find((x) => x.id === party.id)

						// if they are not found in the new list of parties
						if (!found) {
							// then delete them
							await api.delete(`/CaseParties/${prev.id}/partyuser/${party.id}`)
						}
					}
				)

			// for each supporting party currently in the list
			if (newParties)
				await series(
					newParties.filter((x) => x),
					async (party) => {
						const found = prevParties.find((x) => x.id === party.id)

						// if they are not found in the previous list of parties
						if (!found) {
							// then add them
							await api.post(`/CaseParties/${prev.id}/partyuser/${party.id}`)
						}
					}
				)
		}
	}

	const saveCase = async (formData: FormData, publish: boolean) => {
		// console.log('formData', formData)
		// console.log('isPublishing.current', isPublishing.current)
		const { parties, reviews = [], hearings, caseType } = formData
		let { fields } = formData
		let halt = false

		console.log(1)

		const { data: caseData } = await api.get<CaseModel>(`/Cases/${caseId}`)

		if (caseData.status >= CASE_STATUS.Open) {
			window.location.reload()
			return
		}

		console.log(2)

		try {
			// handle case parties
			await series(Object.entries(parties), async ([field, record]) => {
				let role = 0

				type caseRole =
					| 'caseManager'
					| 'mediator'
					| 'reviewer'
					| 'applicant'
					| 'respondent'
					| 'peerReviewer'
					| 'adjudicator'
					| 'facilitator'

				if (field === 'caseManager') role = CASE_PARTY_ROLE.CaseManager
				if (field === 'mediator') role = CASE_PARTY_ROLE.Mediator
				if (field === 'reviewer') role = CASE_PARTY_ROLE.Reviewer
				if (field === 'applicant') role = CASE_PARTY_ROLE.Applicant
				if (field === 'respondent') role = CASE_PARTY_ROLE.Respondent
				if (field === 'peerReviewer') role = CASE_PARTY_ROLE.PeerReviewer

				// FOR FENZ
				if (field === 'adjudicator') role = CASE_PARTY_ROLE.Adjudicator
				if (field === 'facilitator') role = CASE_PARTY_ROLE.Facilitator

				let itemCaseRole = caseData?.[field as caseRole]

				// if there is an existing party from the api
				if (itemCaseRole && itemCaseRole?.user) {
					// if there is a user chosen in the form but the user is the same as the existing party, then do nothing
					if (record.user && itemCaseRole.user.id === record.user.id) {
						if (field !== 'caseManager' && field !== 'peerReviewer')
							await handleSupportingParties(itemCaseRole, record)

						return
					}

					// if there is no user chosen in the form
					if (!record.user) {
						// then delete the party
						return api.delete(`/Cases/${caseData?.id}/party/${itemCaseRole.id}`)

						// if there is a user chosen in the form
					} else {
						// update the party to use the new user
						await api.put(`/Cases/${caseData?.id}/party/${itemCaseRole.id}`, {
							role,
							...record,
							id: itemCaseRole.id,
						})

						if (field !== 'caseManager' && field !== 'peerReviewer')
							await handleSupportingParties(itemCaseRole, record)
					}

					// if there is no existing party from the api but there is a user chosen in the form
				} else if (isNotNone(record.user)) {
					// create the party
					const { data } = await api.post(`/Cases/${caseData?.id}/party`, {
						role,
						userid: record.user.id, // case party fix
						// ...record,
					})

					if (field !== 'caseManager' && field !== 'peerReviewer') await handleSupportingParties(data, record)
				}
			})

			// save and update reviews
			if (reviews) {
				await series(reviews, async (review) => {
					let lodgement

					try {
						lodgement = new Date(review.lodgementDate)
					} catch (e) {
						lodgement = null
					}

					let payload: { [x: string]: any } = {
						reviewNumber: review.reviewNumber,
						issueCode: review.issueCode,
						accDecisionDate: review.accDecisionDate
							? ensureDate(review.accDecisionDate).toISOString()
							: null,
						lodgementDate: lodgement && isValidDate(lodgement) ? lodgement.toISOString() : '',
					}

					if (review.lodgementDate) {
						payload.setByDate = addMonths(ensureDate(review.lodgementDate), 3).toISOString()
					}

					if (!review.id) {
						// console.log('save new hearing')
						let { data: newReview } = await api.post(`/Cases/${caseData?.id}/review`, payload)
						review.id = newReview.id
					} else {
						// console.log('update existing hearing', payload)
						await api.put(`/Cases/${caseData?.id}/review/${review.id}`, {
							id: review.id,
							...payload,
						})
					}

					formContext.setValue('reviews', reviews)
				})
			}

			// delete any removed reviews
			if (caseData?.reviews) {
				await series(caseData.reviews, async (existingReview) => {
					let found = reviews.find((x) => x.id === existingReview.id)

					if (!found) {
						// console.log('remove old hearing', existingReview)
						await api.delete(`/Cases/${caseData?.id}/review/${existingReview.id}`)
					}
				})
			}
			// save hearings
			await series(hearings, async (hearing) => {
				if (!hearing) return

				const originalHearing = caseData?.hearings.find((x) => x.id === hearing.id)

				let att = { ...parties }

				const included = includer(att, hearing.attendees || {})

				const attendees = Object.entries<CasePartyModel>(included)
					.reduce((list: any[], [_, val]) => {
						return [...list, val.user, ...(val.parties || [])]
					}, [])
					.filter(isNotNone)
					.filter((x, i, a) => a.findIndex((y) => y.id === x.id) === i)

				let startDate: Date | null = null
				let endDate: Date | null = null

				if (hearing.forumType === FORUM_TYPE.OnThePapers) {
					if (hearing.startDate) {
						startDate = endOfDay(hearing.startDate)
						endDate = endOfDay(hearing.startDate)
					}
				} else if (hearing.startDate) {
					let fromDate = new Date(`1970-01-01 ${hearing?.time || '00:00'}`)

					startDate = setMinutes(setHours(hearing.startDate, getHours(fromDate)), getMinutes(fromDate))
					endDate = hearing.duration ? addMinutes(startDate, hearing.duration) : startDate
				}

				let decisionDate = startDate && addDays(startDate, 28)

				let adrType = undefined
				if (hearing.hearingType === HEARING_TYPE.Mediation) {
					adrType =
						formData.fields.complex.value === CASE_COMPLEXITY.Complex
							? ADR_TYPE.MultiIssue
							: ADR_TYPE.Standard
				}

				const body: Partial<Override<HearingModel, { reviews: { id: string }[]; case: { id?: string } }>> = {
					// ...hearing,
					name: hearing.name || 'event',
					description: hearing.description,
					hearingType: hearing.hearingType,
					venue: hearing.venue,
					zoomMeeting: hearing.zoomMeeting,
					accessibility: hearing.accessibility,
					interpreterRequired: hearing.interpreterRequired,
					cultural: hearing.cultural,
					security: hearing.security,
					other: hearing.other,
					roomBooked: hearing.roomBooked,
					travelBooked: hearing.travelBooked,
					roomHireDetails: hearing.roomHireDetails,
					travelArrangements: hearing.travelArrangements,
					agreeToMediateApplicant: hearing.agreeToMediateApplicant,
					agreeToMediateDefendant: hearing.agreeToMediateDefendant,
					attendees: originalHearing ? originalHearing.attendees : [],
					startDate: startDate?.toISOString(),
					endDate: endDate?.toISOString(),
					decisionDate: decisionDate?.toISOString(),
					setByDate: isNotNone(hearing.lodgementDate)
						? addMonths(hearing.lodgementDate, 3).toISOString()
						: undefined,
					peerReviewDate:
						hearing.hearingType === HEARING_TYPE.Review && decisionDate
							? subDays(decisionDate, 10).toISOString()
							: undefined,
					forumType: hearing.forumType || 0,
					case: { id: caseData?.id },
					status: originalHearing?.status || HEARING_STATUS.Pending,
					notificationsEnabled: true,
					isPostponed: false,
					reviews:
						hearing.reviews &&
						Object.entries(hearing.reviews)
							.filter(([_, x]) => x)
							.map(([id]) => ({ id })),
					adrType,
					hearingVenue:
						!hearing.venueId || hearing.venueId === 'custom' ? undefined : { id: hearing.venueId },
				}

				// check availability
				if (publish && body.status !== 1) {
					// check availability of reviewer
					await series<UserModel>(Object.values(attendees), async (attendee) => {
						if (hearing.forumType === FORUM_TYPE.OnThePapers) return

						if (
							attendee.id === (parties.reviewer.user && parties.reviewer.user.id) &&
							startDate &&
							endDate
						) {
							const { data: isAvailabile } = await api.get(
								`/CaseHearings/Availability?userId=${attendee.id}&startDate=${format(
									startDate,
									`yyyy-MM-dd'T'HH:mm:ss`
								)}&endDate=${format(endDate, `yyyy-MM-dd'T'HH:mm:ss`)}`
							)

							if (!isAvailabile) {
								const override = await confirm({
									title: 'Availability collision!',
									message: (
										<div>
											<p>
												{getFullName(attendee)} is not available during the time allocated for
												the{' '}
												{hearing.hearingType ? HearingType.readable(hearing.hearingType) : ''}{' '}
												hearing.
											</p>
											<p>Do you want to proceed anyway?</p>
										</div>
									),
								})

								if (!override) {
									halt = true
								}
							}
						}
					})
				}

				// if any of the hearing have unfavourable availability conflicts, cancel the save process.
				if (halt) {
					return
				}

				try {
					// if there are hearings then proceed, this has been change because of FENZ
					if (hearings.length > 0) {
						// if the hearing is brand new
						if (!hearing.id) {
							// create it
							const { data: newHearing } = await api.post('/CaseHearings', {
								...body,
								attendees,
							})

							if (reviews) {
								// attach all reviews with ids
								await series(reviews, async (review) => {
									if (review.id) {
										await api.post(`/CaseHearings/${newHearing.id}/review/${review.id}`)
									}
								})
							}

							// if (hearing.reviews) await handleNewHearingReviews(newHearing, hearing.reviews)

							if (publish) {
								await api.put(`/CaseHearings/${newHearing.id}`, {
									...body,
									id: newHearing.id,
									status: 1,
								})
							}

							// if the hearing is old
						} else {
							// update it
							await api.put(`/CaseHearings/${hearing.id}`, {
								id: hearing.id,
								...body,
							})

							if (originalHearing && hearing.reviews) {
								// await handleHearingReviews(
								// 	originalHearing,
								// 	{
								// 		...body,
								// 		id: hearing.id,
								// 	},
								// 	hearing.reviews
								// )

								await series(Object.entries(hearing.reviews), async ([id, keep]) => {
									let found = !!originalHearing.reviews.find((x) => x.id === id)
									if (keep && !found) {
										await api.post(`/CaseHearings/${originalHearing.id}/review/${id}`)
										return
									}

									if (!keep && found) {
										await api.delete(`/CaseHearings/${originalHearing.id}/review/${id}`)
										return
									}
								})
							}

							// delete or add attendees

							// out of all attendees that are ticked in the form...
							await series(attendees, async (attendee) => {
								// if the attendee was in the list before, and still is
								const found = originalHearing?.attendees.find((x) => x.id === attendee.id)
								// then do nothing
								if (found) return

								// if they didn't exist previously, add them
								await api.post(`/CaseHearings/${hearing.id}/attendee/${attendee.id}`)
							})

							// out of all the attendees originally in this hearing
							if (originalHearing?.attendees) {
								await series(originalHearing.attendees, async (attendee) => {
									// if the attendee is still in the list, do nothing
									const found = attendees.find((x) => x.id === attendee.id)
									if (found) return

									// if they don't exist anymore, remove them
									await api.delete(`/CaseHearings/${hearing.id}/attendee/${attendee.id}`)
								})
							}

							if (publish) {
								await api.put(`/CaseHearings/${hearing.id}`, {
									...body,
									id: hearing.id,
									status: 1,
								})
							}
						}
					}
				} catch (error) {
					api.handleError(error)
					halt = true
					return
				}
			})

			if (halt) {
				return
			}

			// delete any hearings that previously existed, and now do not.
			if (caseData?.hearings) {
				await series(
					caseData.hearings.filter((x) => !hearings.find((y) => y.id === x.id)),
					async (hearing) => {
						await api.delete(`/CaseHearings/${hearing.id}`)
					}
				)
			}

			// handle case fields that may not already exist
			await series(Object.entries(fields), async ([key, field]) => {
				if (!caseData?.fields?.find((x) => x.name === key)) {
					// create a field that does not exist
					const { data: newField } = await api.post('/CaseFields', {
						case: {
							id: caseData?.id,
						},
						name: key,
						title: key,
						label: key,
						value: field.value,
						fieldType: 1,
					})

					type caseFieldName =
						| 'applicantName'
						| 'careManagement'
						| 'accClaimNumber'
						| 'careManagementPlan'
						| 'specialInstructions'
						| 'triageNotes'
						| 'careIndication'
						| 'issueCode'
						| 'zoom'
						| 'complex'

					fields[key as caseFieldName] = newField
				}
			})

			if (!publish) {
				// handle case values

				try {
					await api.put(`/Cases/${caseData?.id}`, {
						...caseData,
						caseType,
						organization: formData.organization,
						fields: Object.entries(fields).map(([key, field]) => {
							let casefield = caseData?.fields?.find((y) => y.name === key)

							return {
								...casefield,
								...field,
							}
						}),
						// for FENZ
						process: formData.process,
					})
					return true
				} catch (error) {
					api.handleError(error)
				}
			}

			if (publish) {
				// handle case values

				try {
					await api.put(`/Cases/${caseData?.id}`, {
						...caseData,
						caseType,
						status: CASE_STATUS.Open,
						// decisionDate,
						setByDate: addMonths(ensureDate(caseData.originalRequestDate), 3),
						organization: formData.organization,
						fields: Object.entries(fields).map(([key, field]) => {
							let casefield = caseData?.fields?.find((y) => y.name === key)

							return {
								...casefield,
								...field,
							}
						}),
						// for FENZ
						process: formData.process,
					})
					return true
				} catch (error) {
					api.handleError(error)
				}
			}
		} catch (e) {
			setSaving(false)
			api.handleError(e)
			throw e
		}
	}

	const handleSubmit = async (formData: FormData) => {
		let publish = isPublishing.current

		try {
			if (publish && isFENZ) {
				const decisionFile = await uploadFENZDecision()

				if (!decisionFile) return
			}

			let saved = await saveCase(formData, publish)

			if (!saved) return

			if (!publish) {
				refetch()

				toast({ title: 'Case saved!' })
			} else {
				queryClient.removeQueries([`/Cases/${item?.id}`, {}])

				navigate(`/cases/${caseId}`)

				await toast({ title: 'Case activated!' })
			}
		} catch (e) {}
	}

	const rejectCase = async (formData: FormData) => {
		setSaving(true)

		try {
			isPublishing.current = false

			const decisionFile = await uploadFENZDecision()

			if (!decisionFile) return

			await saveCase(formData, true)

			let { data: newCase } = await api.get<CaseModel>(`/Cases/${item?.id}`)

			await api.put(`/Cases/${item?.id}`, {
				...newCase,
				rejectedDate: new Date(),
				status: CASE_STATUS.Rejected,
				process: CASE_PROCESS.Adjudication,
			})

			navigate(`/cases/${caseId}`)

			toast({
				title: 'Case rejected!',
				message: 'It is now visible as an open Adjudication case',
			})
		} catch (e) {
			setSaving(false)
			api.handleError(e)
			throw e
		}
	}

	const uploadFENZDecision = async () => {
		const { data: root } = await api.get<CaseFileModel[]>(`/CaseFiles/descendants?caseId=${item?.id}`)
		const { data: folders } = await api.get<CaseFileModel[]>(
			`/CaseFiles/descendants?caseId=${item?.id}&nodeId=${root[0]?.id}`
		)

		const parent = folders.find((x) => x.name === 'DECISIONS/AGREEMENTS')

		if (!parent) return

		const decisionFile = await openFileUpload({ title: 'Upload Fire Emergency decision document', parent })

		return decisionFile
	}

	const isFENZ = organization?.id === FENZOrgId

	return (
		<Form context={formContext}>
			<PageHeading
				pageTitle={`Pending: ${item?.caseNumber}`}
				title={item?.caseNumber}
				infoList={[
					{
						title: <CaseStatusBadge status={item?.status} size="sm" />,
					},
					{
						title: item?.fields?.find((x) => x.name === 'applicantName')?.value || '-',
						icon: <UserIcon className="w-5 h-5" />,
					},
					{
						title: item?.fields?.find((x) => x.name === 'accClaimNumber')?.value || '-',
						icon: <HashtagIcon className="w-5 h-5" />,
					},
				]}
				actions={[
					item?.application?.id && {
						title: 'View Application',
						icon: <ArchiveIcon className="w-5 h-5" />,
						intent: 'secondary',
						onClick: async () => {
							if (item?.application?.id) {
								openApplication({ applicationId: item.application.id, canCreate: false })
							}
						},
					},
					!isSubmitting && {
						title: 'Save Changes',
						intent: 'secondary',
						onClick: async () => {
							isPublishing.current = false
							setSaving(true)
							await handleSubmit(formContext.getValues())
							setSaving(false)
						},
						isLoading: isSaving,
					},
					{
						title: 'Activate Case',
						intent: 'primary',
						onClick: () => {
							isPublishing.current = true
							formContext.handleSubmit(handleSubmit)()
						},
						isLoading: isSubmitting || isSaving,
					},
					isFENZ && {
						title: 'Reject Case',
						intent: 'danger',
						onClick: () => {
							isPublishing.current = true
							formContext.handleSubmit(rejectCase)()
						},
						isLoading: isSubmitting || isSaving,
					},
				]}
			/>

			<PageContent>
				<div className="flex flex-col md:flex-row sm:items-start space-x-0 md:space-x-4 space-y-4 md:space-y-0">
					<div className="px-4 sm:px-0 flex flex-col space-y-2 min-w-64">
						<button
							className={clsx(
								'group flex items-center p-2 text-sm font-medium leading-5 rounded-md transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-primary-500',
								state?.tab === 0
									? 'text-primary-600 bg-gray-100 dark:text-gray-200 dark:bg-gray-700'
									: 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 dark:hover:bg-gray-750 hover:bg-gray-50 focus:text-gray-900'
							)}
							type="button"
							onClick={() => navigate(location.pathname, { replace: true, state: { tab: 0 } })}
						>
							<InformationCircleIcon className="w-5 h-5 mr-2" />
							<div className="flex-1 text-left">Case Information</div>
							<ErrorBadge fields={['fields.careIndication']} />
						</button>

						<button
							className={clsx(
								'group flex items-center p-2 text-sm font-medium leading-5 rounded-md transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-primary-500',
								state?.tab === 1
									? 'text-primary-600 bg-gray-100 dark:text-gray-200 dark:bg-gray-700'
									: 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 dark:hover:bg-gray-750 hover:bg-gray-50 focus:text-gray-900'
							)}
							type="button"
							onClick={() => navigate(location.pathname, { replace: true, state: { tab: 1 } })}
						>
							<UsersIcon className="w-5 h-5 mr-2" />
							<div className="flex-1 text-left">Participants</div>
							<ErrorBadge
								fields={[
									'parties.applicant.user',
									'parties.respondent.user',
									(caseType === CASE_TYPE.Review || caseType === CASE_TYPE.Hybrid) &&
										'parties.reviewer.user',
									(caseType === CASE_TYPE.Mediation || caseType === CASE_TYPE.Hybrid) &&
										'parties.mediator.user',
									organization?.id === FENZOrgId &&
										process === CASE_PROCESS.Adjudication &&
										'parties.adjudicator.user',
									organization?.id === FENZOrgId &&
										(process === CASE_PROCESS.Facilitation ||
											process === CASE_PROCESS.FastTrackAdjudication) &&
										'parties.facilitator.user',
								].filter(isNotNone)}
							/>
						</button>

						<button
							className={clsx(
								'group flex items-center p-2 text-sm font-medium leading-5 rounded-md transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-primary-500',
								state?.tab === 2
									? 'text-primary-600 bg-gray-100 dark:text-gray-200 dark:bg-gray-700'
									: 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 dark:hover:bg-gray-750 hover:bg-gray-50 focus:text-gray-900'
							)}
							type="button"
							onClick={() => navigate(location.pathname, { replace: true, state: { tab: 2 } })}
						>
							<PhoneIcon className="w-5 h-5 mr-2" />
							<div className="flex-1 text-left">Conferences</div>
							<ErrorBadge fields={['hearings']} />
						</button>

						<button
							className={clsx(
								'group flex items-center p-2 text-sm font-medium leading-5 rounded-md transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-primary-500',
								state?.tab === 3
									? 'text-primary-600 bg-gray-100 dark:text-gray-200 dark:bg-gray-700'
									: 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 dark:hover:bg-gray-750 hover:bg-gray-50 focus:text-gray-900'
							)}
							type="button"
							onClick={() => navigate(location.pathname, { replace: true, state: { tab: 3 } })}
						>
							<DocumentTextIcon className="w-5 h-5 mr-2" />
							Documents
						</button>
					</div>

					<div className="flex-1 min-w-0">
						<div className={clsx({ hidden: state?.tab !== 0 }, 'relative z-10')}>
							<CaseInformation caseData={item} />
						</div>

						<div className={clsx({ hidden: state?.tab !== 1 }, 'relative z-10')}>
							<Personnel refetch={refetch} />
						</div>

						<div className={clsx({ hidden: state?.tab !== 2 }, 'relative z-10')}>
							<Hearings caseData={item} />
						</div>

						<div className={clsx({ hidden: state?.tab !== 3 }, 'relative z-10')}>
							<Documents caseData={item} />
						</div>
					</div>
				</div>
			</PageContent>
		</Form>
	)
}

export default PendingCase
