import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'
import { smartReplaceClasses } from 'utils/funcs'

interface IconProps {
	className?: ClassValue
}

const fixClasses = (className = '') =>
	smartReplaceClasses(className, [
		{ pattern: /^w-\d+$/, defaultValue: 'w-5' },
		{ pattern: /^h-\d+$/, defaultValue: 'h-5' },
	])

export const Spinner: FC<IconProps> = ({ className }) => (
	<svg className={fixClasses(clsx(className))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
		<path
			className="opacity-75"
			fill="currentColor"
			d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
		/>
	</svg>
)

export const FilePlus: FC<IconProps> = ({ className }) => (
	<svg
		className={fixClasses(clsx(className))}
		fill="none"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth="1"
		stroke="currentColor"
		viewBox="0 0 24 24"
	>
		<path d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
	</svg>
)

export const History: FC<IconProps> = ({ className }) => (
	<svg
		className={fixClasses(clsx(className))}
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
	>
		<g transform="scale(0.8)" style={{ transformOrigin: 'center' }}>
			<path d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z" />
		</g>
	</svg>
)

export const Undo: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
	>
		<g transform="scale(0.8)" style={{ transformOrigin: 'center' }}>
			<path
				fill="currentColor"
				d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"
			/>
		</g>
	</svg>
)

export const FilePdf: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
		/>
	</svg>
)

export const FileImage: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z"
		/>
	</svg>
)

export const FileWord: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm57.1 120H305c7.7 0 13.4 7.1 11.7 14.7l-38 168c-1.2 5.5-6.1 9.3-11.7 9.3h-38c-5.5 0-10.3-3.8-11.6-9.1-25.8-103.5-20.8-81.2-25.6-110.5h-.5c-1.1 14.3-2.4 17.4-25.6 110.5-1.3 5.3-6.1 9.1-11.6 9.1H117c-5.6 0-10.5-3.9-11.7-9.4l-37.8-168c-1.7-7.5 4-14.6 11.7-14.6h24.5c5.7 0 10.7 4 11.8 9.7 15.6 78 20.1 109.5 21 122.2 1.6-10.2 7.3-32.7 29.4-122.7 1.3-5.4 6.1-9.1 11.7-9.1h29.1c5.6 0 10.4 3.8 11.7 9.2 24 100.4 28.8 124 29.6 129.4-.2-11.2-2.6-17.8 21.6-129.2 1-5.6 5.9-9.5 11.5-9.5zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
		/>
	</svg>
)

export const FileText: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
		/>
	</svg>
)

export const FileArchive: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM128.4 336c-17.9 0-32.4 12.1-32.4 27 0 15 14.6 27 32.5 27s32.4-12.1 32.4-27-14.6-27-32.5-27zM224 136V0h-63.6v32h-32V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM95.9 32h32v32h-32zm32.3 384c-33.2 0-58-30.4-51.4-62.9L96.4 256v-32h32v-32h-32v-32h32v-32h-32V96h32V64h32v32h-32v32h32v32h-32v32h32v32h-32v32h22.1c5.7 0 10.7 4.1 11.8 9.7l17.3 87.7c6.4 32.4-18.4 62.6-51.4 62.6z"
		/>
	</svg>
)

export const FileExcel: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
		/>
	</svg>
)

export const File: FC<IconProps> = ({ className }) => (
	<svg
		aria-hidden="true"
		focusable="false"
		className={fixClasses(clsx(className))}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
	>
		<path
			fill="currentColor"
			d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
		/>
	</svg>
)

export const FileIcon: FC<IconProps & { type: string }> = ({ type, className }) =>
	type === '.pdf' ? (
		<FilePdf className={className} />
	) : type === '.xlsx' ? (
		<FileExcel className={className} />
	) : type === '.docx' ? (
		<FileWord className={className} />
	) : type === '.jpg' || type === '.png' || type === '.gif' ? (
		<FileImage className={className} />
	) : type === '.zip' || type === '.7z' || type === '.rar' ? (
		<FileArchive className={className} />
	) : (
		<File className={className} />
	)
