export enum CASE_PARTY_ROLE {
	Reviewer = 1,
	Mediator = 2,
	Applicant = 4,
	Respondent = 8,
	CaseManager = 16,
	PeerReviewer = 32,
	// ACCReviewSpecialist = 64,
	// FOR FENZ
	Facilitator = 128,
	Adjudicator = 256,
}

export const casePartyRole = {
	1: 'Reviewer',
	2: 'Mediator',
	4: 'Applicant',
	8: 'Respondent',
	16: 'Case Manager',
	32: 'Peer Reviewer',
	// 64: 'ACCReviewSpecialist',
	// FOR FENZ
	128: 'Facilitator',
	256: 'Adjudicator',
}

export const CasePartyRole = {
	readable: (x: CASE_PARTY_ROLE) => casePartyRole[x],
	options: Object.entries(casePartyRole).map(([key, val]) => ({ label: val, value: +key })),
}
