import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { isNotNone, None } from 'types/index'

interface DataItemType {
	title: string
	value?: ReactNode
}

interface DataListColumnsProps {
	items: (DataItemType | None)[]
	columns?: 1 | 2 | 3
	className?: ClassValue
}

const columnClassNames = {
	1: '',
	2: 'sm:w-1/2',
	3: 'sm:w-1/3',
}

const DataListColumns: FC<DataListColumnsProps> = ({ items, columns = 2, className }) => {
	const cols = items
		.filter(isNotNone)
		.filter((x) => x.value)
		.reduce(
			(list: DataItemType[][], item: DataItemType) => {
				if (list[list.length - 1].length < columns) {
					list[list.length - 1].push(item)
					return list
				} else {
					return [...list, [item]]
				}
			},
			[[]]
		)

	return (
		<div className={clsx(className)}>
			<dl className="flex flex-col space-y-8">
				{cols.map((lists, i) => (
					<div key={i} className="flex flex-col sm:flex-row sm:space-x-8 space-y-8 sm:space-y-0">
						{lists.map((item, j) => (
							<div key={j} className={clsx('w-full', columnClassNames[columns])}>
								<dt className="text-sm leading-5 text-gray-500 dark:text-gray-300">{item.title}</dt>
								<dd className="mt-1 text-sm leading-5 break-all text-gray-900 dark:text-white">
									{item.value}
								</dd>
							</div>
						))}
					</div>
				))}
			</dl>
		</div>
	)
}

export default DataListColumns
