import { FC, useState } from 'react'
import { addMinutes, endOfDay, getHours, getMinutes, setHours, setMinutes, startOfDay } from 'date-fns'
import { format, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import { TimeSlot, TimeSlots } from 'types/enums'
import { getConferencesForTimeSlot, getDateFromTime, getTzOffset, toNZDate } from 'utils/funcs'
import openSlideout, { SlideoutHeader, SlideoutContent } from 'hooks/openSlideout'
import { AvailabilityModel } from 'types/models'
import clsx from 'clsx'
import { isNotNone, RefetchFn } from 'types'
import api from 'api'
import { Auth } from 'Auth'
import useData from 'hooks/useData'
import { Spinner } from 'components/Icon'
import { gql } from '@apollo/client'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { GQLEventType, GQLConnection } from 'types/gql'
import { navigate } from 'components/NavigateHoist'

// @ts-ignore
window.format = format
// @ts-ignore
window.zonedTimeToUtc = zonedTimeToUtc
// @ts-ignore
window.utcToZonedTime = utcToZonedTime

// @ts-ignore
window.toNZDate = toNZDate

const query = gql`
	query ($search: EventSearch) {
		Events(search: $search, orderBy: date_DESC) {
			items {
				id
				startDate
				endDate
				type {
					id
					readable
				}
				case {
					id
					caseNumber
					reviewer {
						id
						user {
							id
						}
					}
					mediator {
						id
						user {
							id
						}
					}
				}
			}
		}
	}
`

const openEditAvailabilitySlideout = (props: OpenEditAvailabilitySlideoutProps) => {
	return openSlideout({
		size: 'xl',
		render: (close) => <EditAvailabilitySlideout {...props} close={close} />,
	})
}

interface OpenEditAvailabilitySlideoutProps {
	date: Date
	refetch: RefetchFn
}

const EditAvailabilitySlideout: FC<OpenEditAvailabilitySlideoutProps & { close: () => void }> = ({
	date,
	refetch,
	close,
}) => {
	const profile = Auth.profile()

	const { data: events, refetch: refetchEvents } = useData<AvailabilityModel[]>(
		`/Availability`,
		{
			// startDate: format(startOfDay(date), "yyyy-MM-dd'T'HH:mm:ss"),
			// endDate: format(endOfDay(date), "yyyy-MM-dd'T'HH:mm:ss"),
			startDate: format(startOfDay(toNZDate(date)), "yyyy-MM-dd'T'HH:mm:ss"),
			endDate: format(endOfDay(toNZDate(date)), "yyyy-MM-dd'T'HH:mm:ss"),
		},
		{ suspense: true }
	)

	const getEvent = (slot: TimeSlot) => {
		return events?.find((x) => {
			let found = false

			try {
				found =
					format(toNZDate(new Date(x.startDate)), 'HH:mm') ===
					format(getDateFromTime(slot.from24, toNZDate(new Date())), 'HH:mm')
			} catch (e) {}

			return found
		})
	}

	// I called availability records "events" already, so now when I have actual events, I'm calling them "conferences"
	const { data: conferences, loading: conferencesLoading } = useBufferedQuery<{
		Events: GQLConnection<GQLEventType>
	}>(query, {
		variables: {
			search: {
				from: startOfDay(toNZDate(date)).toISOString(),
				to: endOfDay(toNZDate(date)).toISOString(),
				attendees: [profile?.id],
				isPostponed: false,
			},
		},
	})

	const getConferences = (slot: TimeSlot) => {
		if (!conferences?.Events?.items) return

		let list = getConferencesForTimeSlot(toNZDate(date), slot, conferences.Events.items)

		if (list)
			return list.reduce<GQLEventType[]>((items, item) => {
				if (!items.find((x) => x.case?.caseNumber === item.case?.caseNumber)) {
					return [...items, item]
				}
				return items
			}, [])
	}

	// optimistic ui biatch
	let [saving, setSaving] = useState<TimeSlot[]>([])
	let [deleting, setDeleting] = useState<TimeSlot[]>([])

	const updateSlot = async (slot: TimeSlot, checked: boolean) => {
		try {
			let event = getEvent(slot)

			if (!!event && !checked) {
				setDeleting((d) => [...d, slot])
				await api.delete(`/Availability/${event.id}`)
			}

			if (checked) {
				const start = getDateFromTime(slot.from24, date)
				const end = getDateFromTime(slot.to24, date)

				const m = Intl.DateTimeFormat().resolvedOptions().timeZone === 'Pacific/Auckland'
				const extra = m
					? -start.getTimezoneOffset()
					: start.getTimezoneOffset() + getTzOffset('Pacific/Auckland')

				const startDate = addMinutes(start, start.getTimezoneOffset() + extra).toISOString()
				const endDate = addMinutes(end, end.getTimezoneOffset() + extra).toISOString()

				// console.log({ startDate, endDate })
				// return

				setSaving((s) => [...s, slot])

				await api.post('/Availability', {
					user: profile,
					startDate,
					endDate,
				})
			}

			await Promise.all([refetchEvents(), refetch()])
		} catch (error) {
			api.handleError(error)
		}

		setDeleting((d) => d.filter((x) => x !== slot))
		setSaving((s) => s.filter((x) => x !== slot))
	}

	const [isSyncing, setSyncing] = useState(false)
	const isAllChecked = TimeSlots.map((slot) => !!getEvent(slot)).reduce((pass, bool) => pass && bool, true)

	const updateAll = async (newState: boolean) => {
		setSyncing(true)

		const checkConfs = (x: TimeSlot) => {
			let hasConfs = getConferences(x)

			if (hasConfs) return hasConfs.length === 0
			return true
		}

		if (newState) {
			try {
				let data = TimeSlots.filter(checkConfs)
					.map((slot) => {
						let event = getEvent(slot)

						if (event) return null

						const start = getDateFromTime(slot.from24)
						const end = getDateFromTime(slot.to24)
						const startDate = setHours(setMinutes(toNZDate(date), getMinutes(start)), getHours(start))
						const endDate = setHours(setMinutes(toNZDate(date), getMinutes(end)), getHours(end))

						return {
							userId: profile?.id,
							startDate,
							endDate,
						}
					})
					.filter(isNotNone)

				await api.post(`/Availability/post-multiple`, data)

				await Promise.all([refetchEvents(), refetch()])
			} catch (error) {
				api.handleError(error)
			}
		} else {
			try {
				let data = TimeSlots.filter(checkConfs)
					.map(getEvent)
					.filter(isNotNone)
					.map((x) => x.id)

				await api.delete(`/Availability/delete-multiple`, { data })

				await Promise.all([refetchEvents(), refetch()])
			} catch (error) {
				api.handleError(error)
			}
		}

		setSyncing(false)
	}

	return (
		<>
			<SlideoutHeader
				// title={
				// 	<div className="space-y-2 w-full">
				// 		<div className="text-sm font-medium opacity-50">Edit Availability:</div>
				// 		<div className="text-2xl font-bold">{format(date, "EEE do 'of' MMM")}</div>
				// 	</div>
				// }
				title="View Availability"
				subtitle={format(date, "EEE do 'of' MMMM")}
				actions={[]}
			/>

			<SlideoutContent>
				<div className="p-4 flex-1 flex flex-col">
					<div className="flex-1 flex flex-col min-h-0 overflow-y-scroll">
						<div className="mb-3 pb-3 border-b border-gray-200 dark:border-gray-600 flex items-center justify-between">
							<label className="py-1 pl-1 text-sm font-medium cursor-pointer flex items-center tabular-nums">
								<input
									type="checkbox"
									className="form-checkbox mr-3"
									checked={isAllChecked}
									onChange={(e) => updateAll(e.target.checked)}
									disabled={isSyncing}
								/>
								{isAllChecked ? 'Uncheck All' : 'Check All'}
								{isSyncing && <Spinner className="w-4 h-4 ml-4 animate-spin" />}
							</label>

							{conferencesLoading && <Spinner className="w-5 h-5 animate-spin" />}
						</div>

						{TimeSlots.map((slot, i) => {
							let conf = getConferences(slot)

							return (
								<div
									key={i}
									className={clsx(
										i % 2 &&
											i < TimeSlots.length - 1 &&
											'mb-3 pb-3 border-b border-gray-200 dark:border-gray-600'
									)}
								>
									<label className="py-1 pl-1 text-sm font-medium flex flex-col items-start justify-between tabular-nums">
										<div className={clsx('flex items-center', !conf?.length && 'cursor-pointer')}>
											<input
												type="checkbox"
												className={clsx('form-checkbox mr-3 disabled:opacity-50')}
												checked={
													saving.includes(slot)
														? true
														: deleting.includes(slot)
														? false
														: !!getEvent(slot)
												}
												onChange={(e) => updateSlot(slot, e.target.checked)}
												disabled={
													isSyncing ||
													saving.includes(slot) ||
													deleting.includes(slot) ||
													!!conf?.length
												}
											/>
											{slot.from} - {slot.to}
											{(saving.includes(slot) || deleting.includes(slot)) && (
												<Spinner className="w-4 h-4 ml-4 animate-spin" />
											)}
										</div>

										{conf && conf.length > 0 && (
											<div className="ml-9 px-1.5 py-0.5 rounded-md bg-primary-100 dark:bg-gray-750">
												{conf.map((x) => (
													<div key={x.id} className="flex items-center space-x-2">
														<span>{x.type?.readable}:</span>
														{x.case?.reviewer?.user?.id === profile?.id ||
														x.case?.mediator?.user?.id === profile?.id ? (
															<button
																className="text-sm font-medium tabular-nums text-primary-600 dark:text-primary-400"
																onClick={() => {
																	navigate(
																		`/cases/${x.case?.id}/conferences?hearing=${x.id}`
																	)
																	close()
																}}
															>
																{x.case?.caseNumber}
															</button>
														) : (
															<div className="text-sm font-medium tabular-nums">
																{x.case?.caseNumber}
															</div>
														)}
													</div>
												))}
											</div>
										)}
									</label>
								</div>
							)
						})}
					</div>
				</div>
			</SlideoutContent>
		</>
	)
}

export default openEditAvailabilitySlideout
