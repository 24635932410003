import {
	FC,
	useState,
	forwardRef,
	useImperativeHandle,
	useMemo,
	useRef,
	useEffect,
	createContext,
	useContext,
} from 'react'
import useData from 'hooks/useData'
import clsx, { ClassValue } from 'clsx'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import openFileView from 'modals/openFileView'
import openFileUpload from 'modals/openFileUpload'
import openFileImport from 'modals/openFileImport'
import openCreateFolder from 'modals/openCreateFolder'
import openFilePermissions from 'modals/openFilePermissions'
import openEditSubmission from 'modals/openEditSubmission'
import openFileSlideout from 'slideouts/openFileSlideout'
import openRenameFile from 'modals/openRenameFile'
import { format, isAfter, parseISO } from 'date-fns'
import { humanFileSize } from 'utils/funcs'
import FileSaver from 'file-saver'
import { confirm, warning } from 'alerts'
import { toast } from 'components/toast'
import jsPDF from 'jspdf'
import api from 'api'
import {
	FolderOpenIcon,
	FolderIcon,
	DotsHorizontalIcon,
	DocumentSearchIcon,
	CloudDownloadIcon,
	KeyIcon,
	TrashIcon,
	EyeIcon,
	CloudUploadIcon,
	InboxInIcon,
	FolderAddIcon,
	PencilIcon,
	PencilAltIcon,
	CheckCircleIcon,
	ExclamationCircleIcon as ExclamationCircleIconOutline,
	DownloadIcon,
} from '@heroicons/react/outline'
import {
	FolderOpenIcon as FilledFolderOpenIcon,
	FolderIcon as FilledFolderIcon,
	ExclamationCircleIcon,
	// DocumentIcon,
} from '@heroicons/react/solid'
import { FileIcon, Spinner } from 'components/Icon'
import { CaseModel, CaseFileModel } from 'types/models'
import { isAxiosError, RefetchFn } from 'types'
import { Auth } from 'Auth'
import { SCAN_STATUS } from 'types/enums'
import Badge from './Badge'
import AutoHeight from './AutoHeight'

type FileExplorerContextType = {
	files: CaseFileModel[]
}

const FileExplorerContext = createContext({} as FileExplorerContextType)

const useFileExplorerContext = () => useContext(FileExplorerContext)

// const flattenFiles = (list: CaseFileModel[]): CaseFileModel[] => {
// 	return list.reduce<CaseFileModel[]>((files, file) => {
// 		return [...files, file, ...flattenFiles(file.children)]
// 	}, [])
// }

// const sortFolders = (list: CaseFileModel[]) => {
// 	let secondList = [...list]

// 	const i = secondList.findIndex((x) => x.name === 'CORRESPONDENCE')
// 	const correspondenceFolder = secondList[i]

// 	if (i > -1) {
// 		secondList.splice(i, 1)
// 		secondList.splice(2, 0, correspondenceFolder)
// 	}

// 	return secondList
// }

interface FileExplorerProps {
	cases: CaseModel[]
	open?: boolean
	className?: ClassValue
}

const FileExplorer = forwardRef<any, FileExplorerProps>(({ cases, open = false, className }, ref) => {
	return (
		<Stack dividers space={0} className={clsx(className)}>
			{cases.map((x) => (
				<CaseFiles key={x.id} caseData={x} open={open} ref={ref} />
			))}
		</Stack>
	)
})

export default FileExplorer

interface CaseFilesProps {
	caseData: CaseModel
	open?: boolean
}

const CaseFiles = forwardRef<any, CaseFilesProps>(({ caseData, open }, ref) => {
	const [isOpen, setIsOpen] = useState(open)
	const {
		data: files = [],
		refetch,
		isLoading,
	} = useData<CaseFileModel[]>('/CaseFiles/hierarchy', { caseId: caseData.id }, { enabled: isOpen })

	useImperativeHandle(ref, () => ({
		refetch,
	}))

	// create polling refetch if there is any scanning files
	const timer = useRef<ReturnType<typeof setInterval>>()
	useEffect(() => {
		let anyScanning = (files?.filter((x) => x.scanResult === SCAN_STATUS.Scanning).length || 0) > 0
		// let anyScanning = true

		if (timer.current) {
			clearInterval(timer.current)
		}

		if (anyScanning) {
			timer.current = setInterval(() => refetch(), 3 * 1000)
		}
	}, [files, refetch])

	const rootDir = files.find((x) => x.parentId === null && !x.isFile) || files[0]

	if (!caseData) return null

	return (
		<FileExplorerContext.Provider value={{ files }}>
			<div>
				{isLoading && !rootDir ? (
					<div className="ml-0.5 p-3 flex items-center space-x-2">
						<Spinner className="w-5 h-5 animate-spin" />
						<div className="text-sm font-medium text-gray-500 dark:text-gray-300">
							{caseData.caseNumber}
						</div>
					</div>
				) : (
					<Folder
						file={{ ...rootDir, name: caseData.caseNumber }}
						refetch={refetch}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
					/>
				)}
			</div>
		</FileExplorerContext.Provider>
	)
})

interface FolderProps {
	file: CaseFileModel
	refetch: () => Promise<unknown>
	isOpen?: boolean
	setIsOpen?: (x: boolean) => void
}

const Folder: FC<FolderProps> = ({ file, refetch, isOpen, setIsOpen }) => {
	const [open, setOpen] = useState(isOpen)
	const { files } = useFileExplorerContext()

	// the following should happen on the api anyway, but just in case
	if (Auth.is('ACCAdmin', 'Respondent')) {
		let name = file.name.toLowerCase()

		// don't show these folders to ACCAdmin and respondent
		if (
			name === 'correspondence' ||
			name === 'review notes' ||
			name === 'reviewer draft documents' ||
			name === 'invoices'
		) {
			return null
		}
	}

	const childNodes = files.filter((x) => x.parentId === file.id)
	const childFolders = childNodes.filter((x) => !x.isFile)
	const childFiles = childNodes.filter((x) => x.isFile)

	const children = [
		...childFolders.sort((a, b) => a.name.localeCompare(b.name)),
		...childFiles.sort((a, b) => (isAfter(new Date(a.created), new Date(b.created)) ? 1 : -1)),
	]

	return (
		<div>
			<div
				className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-300 font-medium rounded-md hover:bg-primary-50 dark:hover:bg-gray-800"
				style={{ paddingLeft: ((file.level || 1) - 1) * 1.75 + 'rem' }}
			>
				<div
					className="p-3 flex items-center space-x-2 flex-1 cursor-pointer ml-0.5"
					onClick={() => {
						setOpen((x) => !x)
						setIsOpen && setIsOpen(true)
					}}
				>
					{children?.length ? (
						open ? (
							<FilledFolderOpenIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
						) : (
							<FilledFolderIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
						)
					) : open ? (
						<FolderOpenIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
					) : (
						<FolderIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
					)}
					<span>{file.name}</span>
				</div>

				{!!file.case &&
					(Auth.is(
						'Admin',
						'CaseManager',
						'Reviewer',
						'Mediator',
						'FENZAdmin',
						'Adjudicator',
						'Facilitator'
					) ||
						(file.name.toLowerCase() === 'acc submissions/evidence' &&
							Auth.is('ACCAdmin', 'Respondent'))) && (
						<Actions
							className="mr-2"
							actions={{
								icon: <DotsHorizontalIcon className="w-5 h-5" />,
								intent: 'menu',
								actions: [
									Auth.is(
										'Admin',
										'CaseManager',
										'Reviewer',
										'Mediator',
										'FENZAdmin',
										'Adjudicator',
										'Facilitator',
										'ACCAdmin',
										'Respondent'
									) && {
										title: 'Upload Files',
										icon: <CloudUploadIcon className="w-5 h-5" />,
										onClick: () => openFileUpload({ parent: file, refetch }),
										type: 'button',
									},
									Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
										title: 'Import Files',
										icon: <InboxInIcon className="w-5 h-5" />,
										onClick: () => openFileImport({ parent: file, refetch }),
									},
									Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
										title: 'Add Subfolder',
										icon: <FolderAddIcon className="w-5 h-5" />,
										onClick: () => openCreateFolder({ parent: file, refetch }),
									},
								],
							}}
						/>
					)}
			</div>

			<AutoHeight show={!!open}>
				<div>
					{children?.map((child) =>
						child.isFile ? (
							<File key={child.id} file={child} refetch={refetch} />
						) : (
							<Folder key={child.id} file={child} refetch={refetch} isOpen={false} />
						)
					)}
				</div>
			</AutoHeight>
		</div>
	)
}

interface FileProps {
	file: CaseFileModel
	refetch: RefetchFn
}

const File: FC<FileProps> = ({ file, refetch }) => {
	const [isDownloading, setDownloading] = useState(false)

	// const isInfected = useMemo(() => file.scanResult === SCAN_STATUS.VirusDetected, [file])
	const isInfected = useMemo(() => file.scanResult === SCAN_STATUS.VirusDetected, [file])
	const isScanning = useMemo(() => file.scanResult === SCAN_STATUS.Scanning, [file])

	const handleDelete = () => {
		confirm({
			title: 'Are you sure you want to delete this file?',
			onAccept: async () => {
				try {
					await api.delete(`/CaseFiles/${file.id}`)

					await refetch()

					toast({
						title: 'File Deleted',
					})
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const handleDownload = async () => {
		setDownloading(true)

		const closeToast = toast({
			title: 'File Downloading',
			intent: 'inProgress',
			icon: <DownloadIcon className="h-6 w-6" />,
			isLoading: true,
			dismissable: false,
		})

		if (file.fileType === '.html') {
			var doc = new jsPDF('p', 'pt', 'a4')

			try {
				const res = await api.get<string>(`/CaseFiles/${file.id}/download`, { responseType: 'text' })

				doc.html(
					`
				<style>
					body {
						font-family: Arial, Helvetica, sans-serif;
						padding: 1em;
					}

					p, li {
						line-height: 1.5;
					}

					u {
						text-decoration: underline;
					}
				</style>

				${res.data}
			`,
					{ x: 10, y: 10, width: 595 }
				)

				doc.save(file.name.replace('.html', '.pdf'))

				toast({ title: 'File Downloaded', message: file.name, icon: <CheckCircleIcon className="h-6 w-6" /> })
			} catch (error) {
				if (isAxiosError(error)) {
					let resBlob = error?.response?.data
					let resJson = resBlob instanceof Blob ? JSON.parse(await resBlob.text()) : {}

					if (resJson?.error === 'File Deleted') {
						toast({
							title: 'File has been deleted, and cannot be downloaded',
							message: `Deleted at ${format(new Date(resJson?.date), 'dd/MM/yyyy HH:mm:ss')}`,
							intent: 'error',
							icon: <ExclamationCircleIconOutline className="h-6 w-6" />,
						})
					} else {
						toast({
							title: 'File failed to download',
							message: resJson?.error,
							intent: 'error',
							icon: <ExclamationCircleIconOutline className="h-6 w-6" />,
						})
					}
				}
			}
		} else {
			try {
				const res = await api.get<Blob>(`/CaseFiles/${file.id}/download`, { responseType: 'blob' })
				FileSaver.saveAs(res.data, file.name)

				toast({ title: 'File Downloaded', message: file.name })
			} catch (error) {
				if (isAxiosError(error)) {
					let resBlob = error?.response?.data
					let resJson = resBlob instanceof Blob ? JSON.parse(await resBlob.text()) : {}

					if (resJson?.error === 'File Deleted') {
						toast({
							title: 'File has been deleted, and cannot be downloaded',
							message: `Deleted at ${format(new Date(resJson?.date), 'dd/MM/yyyy HH:mm:ss')}`,
							intent: 'error',
							icon: <ExclamationCircleIconOutline className="h-6 w-6" />,
						})
					} else {
						toast({
							title: 'File failed to download',
							message: resJson?.error,
							intent: 'error',
							icon: <ExclamationCircleIconOutline className="h-6 w-6" />,
						})
					}
				}
			}
		}

		closeToast()
		setDownloading(false)
	}

	const openFile = () => {
		if (isInfected) {
			warning({ title: 'Infected file', message: 'File is possibly infected and cannot be opened' })
		} else if (isScanning) {
			warning({
				title: 'File still scanning',
				message: 'This file will be available when the virus scan has finished',
			})
		} else {
			openFileView({ file })
		}
	}

	return (
		<div
			className={clsx(
				'p-2 flex items-center justify-between space-x-2 text-sm font-medium rounded-md cursor-pointer',
				isInfected
					? 'text-red-500 dark:text-red-300 hover:bg-red-50 dark:hover:bg-red-800'
					: 'text-gray-500 dark:text-gray-300 hover:bg-primary-50 dark:hover:bg-gray-800'
			)}
			style={{ paddingLeft: (file.level - 1) * 1.75 + 0.8 + 'rem' }}
		>
			{isInfected ? (
				<ExclamationCircleIcon className="w-5 h-5 text-red-600 dark:text-red-300" />
			) : (
				<FileIcon type={file.fileType} className="w-5 h-5 text-gray-600 dark:text-gray-300" />
			)}

			{isScanning && (
				<Badge color="gray" icon={<Spinner className="h-4 w-4 mr-2 animate-spin" />}>
					Scanning...
				</Badge>
			)}

			<div className="flex-1 min-w-0" onClick={() => openFile()}>
				<div className="truncate">{file.name}</div>
			</div>

			<div className="px-1 font-normal">
				{format(parseISO(file.created), 'dd/MM/yyyy')}
				<span className="ml-1 hidden sm:inline">{format(parseISO(file.created), 'HH:mm:ss')}</span>
			</div>

			<div className="md:w-20 font-normal">{humanFileSize(file.fileSize)}</div>

			<Actions
				actions={{
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					isLoading: isDownloading,
					actions: [
						[
							{
								title: 'View File',
								icon: <EyeIcon className="w-5 h-5" />,
								disabled: isDownloading || isInfected || isScanning,
								onClick: () => openFileView({ file }),
							},
							{
								title: 'View Details',
								icon: <DocumentSearchIcon className="w-5 h-5" />,
								onClick: () => openFileSlideout({ fileId: file.id, refetch }),
							},
							{
								title: 'Download File',
								icon: <CloudDownloadIcon className="w-5 h-5" />,
								disabled: isDownloading || isInfected || isScanning,
								onClick: () => handleDownload(),
							},
							Auth.is('Admin', 'CaseManager') && {
								title: 'Edit Permissions',
								icon: <KeyIcon className="w-5 h-5" />,
								onClick: () => openFilePermissions({ file, refetch }),
							},
							Auth.is('Admin', 'Applicant', 'FENZAdmin') &&
								file.fileType === '.html' && {
									title: 'Edit File',
									icon: <PencilIcon className="w-5 h-5" />,
									onClick: () => openEditSubmission({ file, refetch }),
								},
							// for renaming document
							Auth.is('Admin', 'CaseManager') && {
								title: 'Rename File',
								icon: <PencilAltIcon className="w-5 h-5" />,
								onClick: () => openRenameFile({ file, refetch }),
							},
							// for renaming document
						],
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && [
							{
								title: 'Delete File',
								intent: 'danger',
								icon: <TrashIcon className="w-5 h-5" />,
								disabled: isDownloading,
								onClick: () => handleDelete(),
							},
						],
					],
				}}
			/>
		</div>
	)
}
