export const CMC_DURATION = {
	30: '30 Minutes',
	60: '1 Hour',
}

export const REVIEW_DURATION = {
	60: '1 Hour',
	90: '1 Hour and 30 Minutes',
	120: '2 Hours',
}

export const MEDIATION_DURATION = {
	120: '2 Hours',
	180: '3 Hours',
	240: '4 Hours',
}

export const Duration = {
	CMC: {
		options: Object.entries(CMC_DURATION).map(([key, val]) => ({ label: val, value: +key })),
	},
	Review: {
		options: Object.entries(REVIEW_DURATION).map(([key, val]) => ({ label: val, value: +key })),
	},
	Mediation: {
		options: Object.entries(MEDIATION_DURATION).map(([key, val]) => ({ label: val, value: +key })),
	},
}
