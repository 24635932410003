import create from 'zustand'
import { addMinutes } from 'date-fns'
import Storage from 'utils/storage'
import { isNotNone } from 'types'
import { v4 as uuidv4 } from 'uuid'
import { CaseModel, RoleModel, UserModel } from 'types/models'
import XLocalStorage from 'utils/XLocalStorage'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import conf, { site, env } from 'utils/config'
import api from 'api'

const isDev = site === 'dev' || site === 'staging' || env === 'dev'

const setSentryUser = (profile: UserModel) => {
	Sentry.setUser(profile)
}

let deviceToken = Storage.getString('deviceToken')

if (!deviceToken) {
	deviceToken = uuidv4()
	Storage.set('deviceToken', deviceToken)
}

XLocalStorage.dispatch({ type: 'device-token', payload: deviceToken })

const syncLoginSession = () => {
	// uncomment this stuff when we put that feature live
	// XLocalStorage.dispatch({
	// 	type: 'login-session',
	// 	payload: {
	// 		token: localStorage.getItem('token'),
	// 		refresh: localStorage.getItem('refresh'),
	// 		exp: localStorage.getItem('exp'),
	// 	},
	// })
}

syncLoginSession()

// interface Profile {
// 	id: string
// 	firstName: string
// 	lastName: string
// 	roles: Role[]
// }

type Store = {
	// token: string | null
	// expiry: number | null
	// refresh: string | null
	profile: UserModel | null
	version: string | null
	fakeRoles: RoleModel[] | null
	darkMode: boolean | null
	render: number

	isLoggedIn: () => boolean
	login: (token: string, expiry: number, refresh: string, profile?: UserModel) => void
	logout: () => void
	setLatestVersion: (v: string) => void
	setFakeRoles: (roles: RoleModel[] | null) => void
	setProfile: (profile: UserModel) => void
	setDarkMode: (darkMode: boolean | null) => void
}

const useStore = create<Store>((set, get) => ({
	// token: Storage.getString('token'),
	// expiry: Storage.getNumber('expiry'),
	// refresh: Storage.getString('refresh'),
	profile: null, //Storage.getObject('profile'),
	version: Storage.getString('version') || '1.0.0',
	fakeRoles: Storage.getObject('fakeRoles'),
	darkMode: Storage.getBoolean('darkMode'),
	render: 0,

	isLoggedIn: () => !!Storage.getString('token'),

	login: (token: string, expiry: number, refresh: string, profile?: UserModel) => {
		Storage.set('token', token)
		Storage.set('expiry', addMinutes(new Date(), expiry).valueOf())
		Storage.set('refresh', refresh)

		if (profile !== undefined) {
			set({ profile })
			// Storage.set('profile', profile)
		} else {
			set({ render: get().render + 1 })
		}

		// set({ token, expiry, refresh })

		if (profile) setSentryUser(profile)
	},

	logout: async () => {
		try {
			let token = Storage.getString('token')
			axios.post(
				`${conf.url}/Accounts/logout`,
				{},
				{
					headers: {
						Registry: conf.registry,
						Authorization: `bearer ${token}`,
					},
				}
			)
		} catch (error) {}

		Storage.remove('token')
		Storage.remove('expiry')
		Storage.remove('refresh')
		// Storage.remove('profile')

		// set({ token: null, expiry: null, refresh: null, profile: null })
		set({ render: get().render + 1 })

		Sentry.configureScope((scope) => scope.setUser(null))
	},

	setLatestVersion: (version: string) => {
		Storage.set('version', version)

		set({ version })
	},

	setFakeRoles: (roles: RoleModel[] | null) => {
		Storage.set('fakeRoles', roles)

		set({ fakeRoles: roles })
	},

	setProfile: (profile: UserModel) => {
		// Storage.set('profile', profile)

		set({ profile })
	},

	setDarkMode: (darkMode: boolean | null) => {
		Storage.set('darkMode', darkMode)

		set({ darkMode })
	},
}))

//@ts-ignore
window.store = useStore

export default useStore

export const Auth = {
	isLoggedIn: () => !!Storage.getString('token'),
	profile: () => useStore.getState().profile,
	is: (...roles: string[]) => {
		const { profile, fakeRoles } = useStore.getState()

		if (!profile) return false

		let rolesToCheck = profile.roles

		if (isNotNone(fakeRoles) && isDev) {
			rolesToCheck = fakeRoles
		}

		let processedRoles = roles.map((x) => {
			if (x.toLowerCase() === 'admin' || x.toLowerCase() === 'administrator') {
				return 'Administrator'
			}

			return x
		})

		return processedRoles.reduce(
			(pass, role) =>
				pass ||
				(role.toLowerCase() === 'applicant'
					? rolesToCheck.length === 0
					: rolesToCheck.some((x) => x.name.toLowerCase() === role.toLowerCase())),
			false
		)
	},

	isOnly: (role: string) => {
		const { profile, fakeRoles } = useStore.getState()

		if (!profile) return false

		let rolesToCheck = profile.roles

		if (isNotNone(fakeRoles)) {
			rolesToCheck = fakeRoles
		}

		if (rolesToCheck.length === 1) {
			return Auth.is(role)
		}
		return false
	},

	isCaseRole: (caseData: CaseModel | undefined, ...roles: string[]) => {
		const { profile } = useStore.getState()

		if (!caseData) return false

		return roles.reduce((pass, role) => {
			if (role.toLowerCase() === 'applicant') {
				if (caseData?.applicant?.user?.id === profile?.id) return true
			}

			if (role.toLowerCase() === 'advocate') {
				if (!!caseData?.applicant?.parties.find((x) => x.id === profile?.id)) return true
			}

			if (role.toLowerCase() === 'respondent') {
				if (caseData?.respondent?.user?.id === profile?.id) return true
			}

			if (role.toLowerCase() === 'respondentadvocate') {
				if (!!caseData?.respondent?.parties.find((x) => x.id === profile?.id)) return true
			}

			if (role.toLowerCase() === 'mediator') {
				if (caseData?.mediator?.user?.id === profile?.id) return true
			}

			if (role.toLowerCase() === 'reviewer') {
				if (caseData?.reviewer?.user?.id === profile?.id) return true
			}

			if (role.toLowerCase() === 'caseManager') {
				if (caseData?.caseManager?.user?.id === profile?.id) return true
			}

			return pass
		}, false)
	},

	setDarkMode: (mode: boolean | null) => {
		const { setDarkMode } = useStore.getState()

		setDarkMode(mode)
	},
}
;(async function () {
	if (Auth.isLoggedIn()) {
		let profile = Auth.profile()

		if (profile) {
			setSentryUser(profile)
		} else {
			try {
				const { data: profile } = await api.get<UserModel>(`${conf.url}/Accounts/profile`)

				useStore.setState({ profile })
				setSentryUser(profile)
			} catch (error) {
				useStore.getState().logout()
			}
		}
	}
})()
