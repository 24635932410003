import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { openModal } from 'hooks/useModal'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import { getFullName } from 'utils/funcs'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import useData from 'hooks/useData'
import { SearchIcon, PlusIcon } from '@heroicons/react/outline'
import { Spinner } from 'components/Icon'
import openCreateUser from './openCreateUser'
import openConfirmUser from './openConfirmUser'
import { UserModel } from 'types/models'
import { USER_ROLE } from 'types/enums'
import Table, { TableSchema } from 'components/Table'
import UserAvatar from 'components/UserAvatar'
import { ModalResolveFn, PaginatedSet } from 'types'

interface FilterType {
	role: USER_ROLE
}

interface OpenFindUserProps {
	title: string
	filter?: FilterType
	requireConfirmation?: boolean
	onAccept?: (x: UserModel | null) => void | Promise<void>
}

const openFindUser = ({ title, filter, requireConfirmation = false, onAccept }: OpenFindUserProps) => {
	return openModal<UserModel>({
		title,
		size: 'lg',
		render: (close) => (
			<FindUser close={close} filter={filter} requireConfirmation={requireConfirmation} onAccept={onAccept} />
		),
	})
}

interface FormData {
	firstName: string
	lastName: string
	email: string
}

const FindUser: FC<Omit<OpenFindUserProps, 'title'> & { close: ModalResolveFn<UserModel> }> = ({
	close,
	filter = {},
	requireConfirmation,
	onAccept,
}) => {
	const formContext = useForm<FormData>({
		defaultValues: { firstName: '', lastName: '', email: '' },
	})

	const { firstName, lastName, email } = formContext.watch(['firstName', 'lastName', 'email'])

	const handleSubmit = (formData: FormData) => {
		setSearch(formData)
	}

	const [search, setSearch] = useState<FormData | null>(null)

	const { data: users, isFetching } = useData<PaginatedSet<UserModel>>(
		'/users',
		{
			pageNumber: 1,
			pageSize: 100,
			search: {
				...search,
				...filter,
			},
		},
		{
			enabled: !!search,
		}
	)

	const schema: TableSchema<UserModel> = {
		cols: [
			{
				title: 'Name',
				value: (x) => (
					<div className="flex flex-row space-x-3">
						<UserAvatar
							className="rounded-full w-9 h-9 object-cover shadow-sm"
							src={x.avatarUrl}
							name={getFullName(x)}
						/>
						<div className="flex flex-col items-start justify-center">
							<div>{getFullName(x)}</div>
							<div className="font-normal hidden sm:block">{x.email}</div>
						</div>
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Phone',
				value: (x) => x.phoneNumber,
				truncate: true,
				responsive: 'md',
			},
		],
		actions: (x) => [
			{
				title: 'Select',
				intent: 'primary',
				size: 'sm',
				onClick: async () => {
					if (requireConfirmation) {
						const confirmed = await openConfirmUser({ user: x })

						if (!confirmed) return
					}

					if (onAccept) await onAccept(x)

					close(x)
				},
			},
		],
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<Stack>
				<Stack className="px-4 py-5 sm:p-6">
					<div className="flex flex-col sm:flex-row sm:items-end space-x-0 sm:space-x-6 space-y-6 sm:space-y-0">
						<FormInput name="firstName" label="First Name" className="flex-1" autoFocus />
						<FormInput name="lastName" label="Last Name" className="flex-1" />
						<FormInput name="email" label="Email" className="flex-1" />
					</div>

					<div className="flex justify-end">
						<Actions
							actions={[
								{
									title: 'Create User',
									icon: <PlusIcon className="w-5 h-5" />,
									intent: 'secondary',
									onClick: async () => {
										const user = await openCreateUser({
											presetValues: {
												firstName: { value: firstName },
												lastName: { value: lastName },
												email: { value: email },
											},
										})

										if (user) {
											formContext.setValue('firstName', user.firstName)
											formContext.setValue('lastName', user.lastName)
											formContext.setValue('email', user.email)
											formContext.handleSubmit(handleSubmit)()
										}
									},
								},
								{
									title: 'Search',
									icon: <SearchIcon className="w-4 h-4" />,
									intent: 'primary',
									type: 'submit',
									disabled: !firstName && !lastName && !email,
								},
							]}
						/>
					</div>
				</Stack>

				{isFetching ? (
					<div className="flex justify-center py-4">
						<Spinner className="animate-spin w-10 h-10" />
					</div>
				) : users?.items.length ? (
					<div className="rounded-bl-lg rounded-br-lg overflow-hidden">
						<Table schema={schema} items={users.items} />
					</div>
				) : search !== null ? (
					<div className="p-4">No users found</div>
				) : null}
			</Stack>
		</Form>
	)
}

export default openFindUser
