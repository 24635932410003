import { Children, FC, Fragment, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import Divider from 'components/Divider'
import AutoHeight from 'components/AutoHeight'
import { omit } from 'ramda'
import { isNotNone } from 'types'

interface StackProps {
	space?: SpaceType
	dividers?: boolean
	children: ReactNode
	className?: ClassValue
}

type SpaceType = 0 | 1 | 2 | 3 | 4 | 6 | 8

const spaces = {
	0: '',
	1: 'pt-1',
	2: 'pt-2',
	3: 'pt-3',
	4: 'pt-4',
	6: 'pt-6',
	8: 'pt-8',
}

const Stack: FC<StackProps> = ({ space = 6, dividers, className, children }: StackProps) => {
	const items = Children.toArray(children)

	const filteredItems = items.reduce<ReactNode[]>((list, x, i) => {
		// @ts-ignore
		const type = x?.type
		// @ts-ignore
		const children: ReactNode[] | null = x?.props?.children || null
		// @ts-ignore
		const props = omit(['children', 'show'], x.props) || null
		// @ts-ignore
		const show = x?.props?.show || null

		if (type === Fragment) {
			return list.concat(children)
		}

		return [
			...list,
			type === AutoHeight ? (
				<AutoHeight show={show} {...props}>
					<div className={clsx(i > 0 && spaces[space || 0])}>
						{dividers && i > 0 && <Divider className={spaces[space || 0]} />}
						{children}
					</div>
				</AutoHeight>
			) : (
				x
			),
		]
	}, [])

	return (
		<div className={clsx(`flex flex-col`, className)}>
			{filteredItems.filter(isNotNone).map((item, i) =>
				// @ts-ignore
				item.type === AutoHeight ? (
					<Fragment key={i}>{item}</Fragment>
				) : (
					<div key={i} className={clsx(i > 0 && spaces[space || 0])}>
						{dividers && i > 0 && <Divider className={spaces[space || 0]} />}
						{item}
					</div>
				)
			)}
		</div>
	)
}

export default Stack
