import { FC, ReactNode, useRef, useState } from 'react'
import { motion, AnimatePresence, MotionStyle } from 'framer-motion'
import clsx from 'clsx'

export type TooltipIntent = 'light' | 'dark'

interface TooltipProps {
	message: ReactNode
	enabled?: boolean
	intent?: TooltipIntent
	children: ReactNode
}

const Tooltip: FC<TooltipProps> = ({ message, enabled = true, intent = 'light', children }) => {
	let ref = useRef<HTMLDivElement>(null)
	const [hover, setHover] = useState(false)
	const [X, setX] = useState(0)
	const [Y, setY] = useState(0)

	const show = () => {
		if (!ref.current) return null
		if (!enabled) return null

		setX(ref.current.offsetLeft + ref.current.offsetWidth)
		setY(ref.current.offsetTop)
		setHover(true)
	}

	const hide = () => {
		setHover(false)
	}

	return (
		<div ref={ref} onMouseOver={show} onMouseOut={hide}>
			<div>{children}</div>

			<div
				className="flex items-center absolute z-10 ml-6 w-80 pointer-events-none"
				style={{
					left: `${X}px`,
					top: `${Y}px`,
				}}
			>
				<AnimatePresence>
					{hover && (
						<motion.div
							style={{ originX: 0 } as MotionStyle}
							variants={{
								hidden: { opacity: 0, x: -30 },
								visible: { opacity: 1, x: 0 },
							}}
							transition={{
								type: 'tween',
								ease: 'easeOut',
								duration: 0.1,
							}}
							initial="hidden"
							animate="visible"
							exit="hidden"
						>
							<div
								className={clsx('rounded px-3 py-2 text-sm font-medium shadow-lg', {
									'bg-white text-gray-900 dark:bg-gray-900 dark:text-white': intent === 'light',
									'bg-primary-900 text-white dark:bg-primary-200 dark:text-primary-900 antialiased':
										intent === 'dark',
								})}
							>
								{message}
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	)
}

export default Tooltip
