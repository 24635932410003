import { Suspense, useEffect } from 'react'
import html2canvas from 'html2canvas'
import Router from './Router'
import { ChatProvider } from 'components/Chat/useChat'
import Chat from 'components/Chat/Chat'
import 'react-quill/dist/quill.snow.css'
import './main.css'
import { ApolloProvider } from '@apollo/client'
import client from 'utils/ApolloClient'
import openDevTools from 'modals/openDevTools'
import { site, env } from 'utils/config'
import { QueryClientProvider } from 'react-query'
import queryClient from 'utils/QueryClient'
import { DarkModeProvider } from 'components/DarkMode'

// @ts-ignore
window.html2canvas = html2canvas

const isDev = site === 'dev' || site === 'staging' || env === 'dev'

const root = () => {
	return (
		<Suspense fallback={null}>
			<App />
		</Suspense>
	)
}

const App = () => {
	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (e.key === '/' && e.ctrlKey && isDev) {
				openDevTools()
			}
		}

		window.addEventListener('keyup', listener)

		return () => window.removeEventListener('keyup', listener)
	}, [])

	return (
		<DarkModeProvider>
			<QueryClientProvider client={queryClient}>
				<ApolloProvider client={client}>
					<ChatProvider serverUrl="https://nzdrc-chat.azurewebsites.net">
						<Router />
						<Chat />
					</ChatProvider>
				</ApolloProvider>
			</QueryClientProvider>
		</DarkModeProvider>
	)
}

export default root
