import { FC } from 'react'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormDate from 'components/Form/FormDate'
import FormSelect from 'components/Form/FormSelect'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { toast, confirm } from 'alerts'
import { CheckIcon } from '@heroicons/react/outline'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import api from 'api'
import { CaseModel, ReviewModel } from 'types/models'
import { useForm } from 'react-hook-form'
import { ADR_TYPE, CASE_COMPLEXITY, ClaimType } from 'types/enums'
import { addMonths, endOfDay, startOfDay } from 'date-fns'
import { ensureDate, isComplexReview, updateADRType, updateCaseComplexity } from 'utils/funcs'

interface OpenEditReviewProps {
	review: ReviewModel
	caseData: CaseModel
	refetch: () => Promise<unknown>
}

const openEditReview = ({ review, caseData, refetch }: OpenEditReviewProps) => {
	return openModal({
		title: 'Edit Review',
		render: (close) => <EditReviewForm review={review} caseData={caseData} refetch={refetch} close={close} />,
	})
}

interface FormData {
	reviewNumber: string
	issueCode: string
	lodgementDate: Date
	accDecisionDate?: Date
}

const EditReviewForm: FC<OpenEditReviewProps & { close: () => void }> = ({ review, caseData, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			...review,
			lodgementDate: new Date(review.lodgementDate),
			accDecisionDate: review.accDecisionDate ? new Date(review.accDecisionDate) : undefined,
		},
	})

	const handleSubmit = async (formData: FormData) => {
		const warnOverdue = formData.accDecisionDate
			? endOfDay(addMonths(ensureDate(formData.accDecisionDate), 3)).valueOf() <
			  startOfDay(ensureDate(formData.lodgementDate)).valueOf()
			: false

		const warnIssueCode = ClaimType.getId(formData.issueCode) === 17

		if (warnOverdue) {
			const warnConfirmed = await confirm({
				title: 'Late request!',
				message: (
					<Stack>
						<div>The Lodgement Date for this case is not within 3 months after the ACC Decision Date.</div>
						<div>Please proceed only if ACC have accepted this case as a Late Request.</div>
						<div>Do you wish to proceed?</div>
					</Stack>
				),
				intent: 'primary',
			})

			if (!warnConfirmed) return null
		}

		if (warnIssueCode) {
			const issueConfirmed = await confirm({
				title: 'Confirmation',
				message: (
					<Stack>
						<div>Please confirm with ACC if this case is standard or complex.</div>
					</Stack>
				),
				intent: 'primary',
			})

			if (!issueConfirmed) return null
		}

		try {
			setSaving(true)

			await api.put(`/Cases/${caseData.id}/review/${review.id}`, {
				...formData,
				setByDate: addMonths(ensureDate(formData.lodgementDate), 3).toISOString(),
			})

			// update to complex case if we just edited a review to be one of the complex ones
			if (isComplexReview(formData)) {
				await updateCaseComplexity(caseData, CASE_COMPLEXITY.Complex)
				await updateADRType(caseData, ADR_TYPE.MultiIssue)
			} else {
				let spreadReviews = [...caseData.reviews].map((x) => (x.id === review.id ? formData : x))

				let complexReviews = spreadReviews.filter((x) => isComplexReview(x as { issueCode: string }))

				if (complexReviews.length === 0 && caseData.reviews.length < 2) {
					await updateCaseComplexity(caseData, CASE_COMPLEXITY.Standard)
					await updateADRType(caseData, ADR_TYPE.Standard)
				}
			}

			await refetch()

			toast({ title: 'Review Updated' })

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput
						name="reviewNumber"
						label="Review Number"
						validations={{ required: 'Review Number is required' }}
						autoFocus
					/>

					<FormSelect
						name="issueCode"
						label="Issue Code"
						options={ClaimType.options}
						validations={{ required: 'Issue Code is required' }}
					/>

					<FormDate name="accDecisionDate" label="ACC Decision Date" inputDisabled />

					<FormDate
						name="lodgementDate"
						label="Lodgement Date"
						validations={{ required: 'Lodgement Date is required' }}
						inputDisabled
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditReview
