import { FC, Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent } from 'hooks/useModal'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import { SearchIcon } from '@heroicons/react/outline'
import { Spinner } from 'components/Icon'
import { CASE_STATUS } from 'types/enums'
import { TableSchema } from 'components/Table'
import { ModalResolveFn } from 'types'
import { GQLCaseType, GQLConnection } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { gql } from '@apollo/client'
import clsx from 'clsx'
import Badge from 'components/Badge'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { useLocation } from 'react-router-dom'

const query = gql`
	query ($search: CaseSearch, $pageNumber: Int, $pageSize: Int) {
		Cases(search: $search, pageNumber: $pageNumber, pageSize: $pageSize) {
			items {
				id
				caseNumber
				applicantName
				accClaimNumber
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				applicant {
					id
					user {
						id
						fullName
					}
				}
				reviews {
					id
					reviewNumber
					withdrawDate
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

interface FilterType {
	status: CASE_STATUS[]
}

interface OpenFindCaseProps {
	title: string
	filter?: FilterType
	onAccept?: (x: GQLCaseType | null) => Promise<boolean>
}

const openFindCase = ({ title, filter, onAccept }: OpenFindCaseProps) => {
	return openModal<GQLCaseType>({
		title,
		size: 'lg',
		render: (close) => <FindCase close={close} filter={filter} onAccept={onAccept} />,
	})
}

interface FormData {
	caseNumber: string
	accClaimNumber: string
}

const FindCase: FC<Omit<OpenFindCaseProps, 'title'> & { close: ModalResolveFn<GQLCaseType> }> = ({
	close,
	filter = {},
	onAccept,
}) => {
	const location = useLocation()

	const state = location?.state as { page?: number; pageSize?: number } | null

	const formContext = useForm<FormData>({
		defaultValues: { caseNumber: '', accClaimNumber: '' },
	})

	const { caseNumber, accClaimNumber } = formContext.watch(['caseNumber', 'accClaimNumber'])

	const handleSubmit = (formData: FormData) => {
		setSearch(formData)
	}

	const [search, setSearch] = useState<FormData | null>(null)

	const { data, loading } = useBufferedQuery<{ Cases: GQLConnection<GQLCaseType> }>(query, {
		variables: {
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 5,
			search: {
				...search,
				...filter,
			},
		},
		skip: !search || (!caseNumber && !accClaimNumber),
	})

	const schema: TableSchema<GQLCaseType> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => x.caseNumber,
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Claim #',
				value: (x) => x.accClaimNumber || '-',
				truncate: true,
			},
			{
				title: 'Review #',
				value: (x) =>
					x.reviews?.map(
						(y, i) =>
							x.reviews && (
								<Fragment key={i}>
									<span className={clsx({ 'line-through': !!y.withdrawDate })}>{y.reviewNumber}</span>
									{i !== x.reviews.length - 1 ? ', ' : ''}
								</Fragment>
							)
					),
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) => x.applicant?.user?.fullName || x.applicantName || '-',
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
				responsive: 'lg',
			},
			{
				title: 'Status',
				value: (x) => (
					<Badge status={x.status?.id} size="sm">
						{x.status?.readable}
					</Badge>
				),
				responsive: 'lg',
				width: 'minmax(auto, max-content)',
			},
		],
		actions: (x) => [
			{
				title: 'Select',
				intent: 'primary',
				size: 'sm',
				onClick: async () => {
					if (onAccept) {
						let accepted = await onAccept(x)

						if (accepted) close(x)
					} else {
						close(x)
					}
				},
			},
		],
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack dividers>
					<Stack>
						<div className="flex flex-col sm:flex-row sm:items-end space-x-0 sm:space-x-6 space-y-6 sm:space-y-0">
							<FormInput name="caseNumber" label="Case Number" className="flex-1" autoFocus />
							<FormInput name="accClaimNumber" label="ACC Claim Number" className="flex-1" />
						</div>

						<div className="flex justify-end">
							<Actions
								actions={[
									{
										title: 'Search',
										icon: <SearchIcon className="w-4 h-4" />,
										intent: 'primary',
										type: 'submit',
										disabled: !caseNumber && !accClaimNumber,
									},
								]}
							/>
						</div>
					</Stack>

					{loading && !data ? (
						<div className="flex justify-center py-4">
							<Spinner className="animate-spin w-10 h-10" />
						</div>
					) : data?.Cases?.items?.length ? (
						<GQLPaginatedTable schema={schema} set={data?.Cases} />
					) : search !== null ? (
						<p>No cases found</p>
					) : null}
				</Stack>
			</ModalContent>
		</Form>
	)
}

export default openFindCase
