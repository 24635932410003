import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PageHeading from 'components/PageHeading'
import PaginatedTable from 'components/PaginatedTable'
import { TableSchema } from 'components/Table'
import { Spinner } from 'components/Icon'
import PageNavbar from 'components/PageNavbar'
import { format } from 'date-fns'
import usePaginatedData from 'hooks/usePaginatedData'
import openFileView from 'modals/openFileView'
import { humanFileSize } from 'utils/funcs'
import { DotsHorizontalIcon, EyeIcon, CloudDownloadIcon } from '@heroicons/react/outline'
import api from 'api'
import FileSaver from 'file-saver'
import { links } from 'pages/Admin'
import { CaseFileModel } from 'types/models'
import { toast, warning } from 'alerts'
import SectionHeader from 'components/SectionHeader'
import Stack from 'components/Stack'

const Uploads: FC = () => {
	const location = useLocation()

	const state = location?.state as { page?: number; pageSize?: number } | null

	const paginationState = {
		orderBy: 'created',
		orderDir: 2,
		page: state?.page || 1,
		pageSize: state?.pageSize || 10,
	}

	const { data, isFetching } = usePaginatedData<CaseFileModel>('/CaseFiles/logs', paginationState)

	const [isDownloading, setDownloading] = useState<CaseFileModel[]>([])

	const schema: TableSchema<CaseFileModel> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.name,
				truncate: true,
			},
			{
				title: 'Uploaded',
				value: (x) => format(new Date(x.created), 'dd/MM/yyyy HH:mm:ss'),
				truncate: true,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.fileSize),
				truncate: true,
			},
		],
		actions: (x) => [
			{
				icon:
					isDownloading.indexOf(x) > -1 ? (
						<Spinner className="w-5 h-5 animate-spin" />
					) : (
						<DotsHorizontalIcon className="w-5 h-5" />
					),
				intent: 'menu',
				actions: [
					{
						title: 'View File',
						icon: <EyeIcon className="w-5 h-5" />,
						onClick: () => openFileView({ file: x }),
						disabled: isDownloading.indexOf(x) > -1,
					},
					{
						title: 'Download File',
						icon: <CloudDownloadIcon className="w-5 h-5" />,
						onClick: () => downloadFile(x),
						disabled: isDownloading.indexOf(x) > -1,
					},
				],
			},
		],
	}

	const downloadFile = async (file: CaseFileModel) => {
		setDownloading((list) => list.concat([file]))

		try {
			const res = await api.get<Blob>(`/CaseFiles/${file.id}/download`, { responseType: 'blob' })
			FileSaver.saveAs(res.data, file.name)

			setDownloading((list) => list.filter((x) => x !== file))
			toast({ title: 'File Downloaded', message: file.name })
		} catch (e) {
			setDownloading((list) => list.filter((x) => x !== file))
			warning({ title: 'Error' })
		}
	}

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Uploads" />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader title="File Upload Log" />

					<PaginatedTable
						schema={schema}
						set={data}
						paginationState={paginationState}
						isLoading={isFetching}
					/>
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Uploads
