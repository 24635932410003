import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import axios from 'axios'
import { isPast } from 'date-fns'
import conf from 'utils/config'
import storage from 'utils/storage'
import store from 'Auth'

// https://www.apollographql.com/docs/react/networking/authentication/#header
const authLink = setContext(async (_, { headers }) => {
	const exp = storage.getNumber('expiry')
	const token = storage.getString('token')
	const refresh = storage.getString('refresh')

	let newHeaders = {
		...headers,
		Authorization: token ? `bearer ${token}` : '',
	}

	if (exp && isPast(new Date(exp)) && token && refresh) {
		try {
			const {
				data: { access_token, expires_in, refresh_token },
			} = await axios.post<
				{ refreshToken: string; bearerToken: string },
				{ data: { access_token: string; expires_in: number; refresh_token: string } }
			>(
				`${conf.url}/Auth/refreshToken`,
				{
					refreshToken: refresh,
					bearerToken: token,
				},
				{
					headers: {
						Registry: conf.registry,
					},
				}
			)

			store.getState().login(access_token, expires_in, refresh_token)

			newHeaders.Authorization = `Bearer ${access_token}`
		} catch (error) {
			// console.log(error)
			store.getState().logout()
			// navigate('/login')
		}
	} else {
		newHeaders.Authorization = `Bearer ${token}`
	}

	return {
		headers: newHeaders,
	}
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.log(`Graphql error '${message}'`)
		})
	}
})

const link = from([errorLink, new HttpLink({ uri: conf.gql })])

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: authLink.concat(link),
})

export default client
