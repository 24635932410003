import { FC } from 'react'
import CaseHeader from 'components/CaseHeader'
import { format, parseISO } from 'date-fns'
import useData from 'hooks/useData'
import DataListColumns from 'components/DataListColumns'
import Stack from 'components/Stack'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import { useParams } from 'react-router-dom'
import { CaseModel } from 'types/models'
import { CaseStatus } from 'types/enums'
import openEditCaseCompletion from 'modals/openEditCaseCompletion'
import { Auth } from 'Auth'

// interface CaseDecisionProps {
// 	caseId?: string
// }

const CaseDecision: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const links = caseData ? caseLinks(caseData) : []

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader
						title="Case Status"
						actions={[
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
								title: 'Edit Case Completion',
								onClick: () => caseData && openEditCaseCompletion({ caseData, refetch }),
							},
						]}
					/>

					<DataListColumns
						items={[
							{
								title: 'Case Status',
								value: CaseStatus.readable(caseData?.status || 0),
							},
							{
								title: 'Date Completed',
								value: caseData?.completedDate
									? format(parseISO(caseData.completedDate), 'dd/MM/yyyy')
									: '-',
							},
							{
								title: 'Billing Date',
								value: caseData?.billingDate
									? format(parseISO(caseData.billingDate), 'dd/MM/yyyy')
									: '-',
							},
						]}
					/>
				</Stack>
			</PageNavbar>
		</>
	)
}

export default CaseDecision
