import { FC } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/FormSelect'
import { hearingDifferentiator } from 'utils/funcs'
import { toast, warning } from 'alerts'
import api from 'api'
import { CaseModel, HearingModel } from 'types/models'
import { ReminderType, REMINDER_TYPE } from 'types/enums'

interface OpenCreateReminderProps {
	caseData: CaseModel
	type: REMINDER_TYPE
}

const openCreateReminder = ({ caseData, type }: OpenCreateReminderProps) => {
	return openModal({
		title: `Create ${ReminderType.readable(type)} Reminder`,
		render: (close) => <Reminder close={close} caseData={caseData} type={type} />,
	})
}

interface FormData {
	hearing: HearingModel
}

const Reminder: FC<OpenCreateReminderProps & { close: () => void }> = ({ close, caseData, type }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm({
		defaultValues: {
			hearing: null,
		},
	})

	const handleSubmit = async (formData: FormData) => {
		let reviewer = caseData.reviewer?.user

		if (!reviewer) {
			warning({ title: 'No reviewer assigned to case' })
			return
		}

		setSaving(true)

		try {
			await api.post('/Reminders', {
				notificationDate: new Date().toISOString(),
				description: `${ReminderType.readable(type)} required for ${hearingDifferentiator(
					formData.hearing,
					caseData
				)}`,
				caseId: caseData.id,
				caseHearingId: formData.hearing.id,
				reminderType: type,
				userIds: [reviewer.id],
			})

			close()

			toast({ title: 'Reminder created' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<FormSelect
					name="hearing"
					label="Hearing"
					options={caseData.hearings.map((x) => ({ label: hearingDifferentiator(x, caseData), value: x }))}
					validations={{
						required: 'Hearing is required',
					}}
					autoFocus
				/>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openCreateReminder
