import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormCheckbox from 'components/Form/FormCheckbox'
import { CheckIcon } from '@heroicons/react/outline'
import Actions from 'components/Actions'
import Table from 'components/Table'
import api from 'api'
import { getFullName, getCaseRole } from 'utils/funcs'
import { series } from 'utils/funcs'
import { openModal, ModalFooter, useModalState } from 'hooks/useModal'
import { toast } from 'components/toast'
import { CaseModel, HearingModel, UserModel } from 'types/models'
import { isNotNone, RefetchFn } from 'types'
import { TableSchema } from 'components/Table'
import { uniq } from 'ramda'
import UserAvatar from 'components/UserAvatar'

interface OpenEditHearingAttendeesProps {
	caseData: CaseModel
	hearing: HearingModel
	refetch: RefetchFn
}

const openEditHearingAttendees = ({ caseData, hearing, refetch }: OpenEditHearingAttendeesProps) => {
	return openModal({
		title: 'Edit Attendees',
		size: 'xl',
		render: (close) => (
			<EditHearingAttendees caseData={caseData} hearing={hearing} refetch={refetch} close={close} />
		),
	})
}

interface FormData {
	attendees: {
		[id: string]: boolean
	}
}

const EditHearingAttendees: FC<OpenEditHearingAttendeesProps & { close: () => void }> = ({
	caseData,
	hearing,
	refetch,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const attendees = uniq([
		caseData.applicant?.user,
		...(caseData.applicant?.parties || []),
		caseData.reviewer?.user,
		...(caseData.reviewer?.parties || []),
		caseData.mediator?.user,
		...(caseData.mediator?.parties || []),
		caseData.peerReviewer?.user,
		...(caseData.peerReviewer?.parties || []),
		caseData.respondent?.user,
		...(caseData.respondent?.parties || []),
		...hearing.attendees,
	]).filter(isNotNone)

	const formContext = useForm<FormData>({
		defaultValues: {
			attendees: attendees.reduce(
				(obj, user) => ({
					...obj,
					[user.id]: !!hearing.attendees.find((y) => y.id === user.id),
				}),
				{}
			),
		},
	})

	const schema: TableSchema<UserModel> = {
		cols: [
			{
				title: '',
				value: (x) => (
					<FormCheckbox
						name={`attendees.${x.id}`}
						label={
							<div className="flex flex-row space-x-3">
								<UserAvatar
									className="rounded-full w-9 h-9 object-cover shadow-sm"
									src={x.avatarUrl}
									name={getFullName(x)}
								/>
								<div className="flex flex-col">
									<div className="font-medium">{getFullName(x)}</div>
									<div>{x.email}</div>
								</div>
							</div>
						}
					/>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Phone',
				value: (x) => x.phoneNumber,
				truncate: true,
			},
			{
				title: 'Case Role',
				value: (x) => getCaseRole(caseData, x),
				truncate: true,
			},
		],
	}

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			const checked = Object.entries(formData.attendees)
				.filter(([_, included]) => included)
				.map(([id]) => attendees.find((x) => x.id === id))
				.filter(isNotNone)

			await series(hearing.attendees, async (attendee) => {
				// if a previous attendee is no longer checked
				if (!checked.find((x) => x.id === attendee.id)) {
					// remove them
					await api.delete(`/CaseHearings/${hearing.id}/attendee/${attendee.id}`)
				}
			})

			// go through all checked attendees
			await series(checked, async (attendee) => {
				// if a checked attendee was not previously attached to the hearing
				if (!hearing.attendees.find((x) => x.id === attendee.id)) {
					// attach them
					await api.post(`/CaseHearings/${hearing.id}/attendee/${attendee.id}`)
				}
			})

			await refetch()

			toast({ title: 'Updated Attendees' })

			close()
		} catch (error) {
			setSaving(false)
			api.handleError(error)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			{/* <ModalContent> */}
			<Table items={attendees} schema={schema} className="-my-px" />
			{/* </ModalContent> */}

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditHearingAttendees
