import { FC, ChangeEvent, SetStateAction } from 'react'
import clsx, { ClassValue } from 'clsx'
import Inline from './Inline'
import { Spinner } from './Icon'
import { SearchIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import { intents, sizes, roundedness } from 'components/Button'

interface PaginationProps {
	page: number
	setPage: (x: number) => void
	pageSize: number
	setPageSize: (x: number) => void
	totalCount: number
	pageCount: number
	className?: ClassValue
	showInfo?: boolean
	showCounter?: boolean
	setFilterVisible?: (x: SetStateAction<boolean>) => void
	showFilter?: boolean
	isLoading?: boolean
}

const Pagination: FC<PaginationProps> = ({
	page,
	setPage,
	pageSize = 10,
	setPageSize,
	totalCount,
	pageCount,
	className,
	showInfo,
	showCounter,
	setFilterVisible,
	showFilter,
	isLoading,
}) => {
	const pages = new Array(Math.ceil(totalCount / pageSize)).fill(null).map((_, i) => i + 1)

	const changePageSize = (e: ChangeEvent<HTMLSelectElement>) => {
		setPage(1)
		setPageSize(+e.target.value)
	}

	const handleKeyPress = (e: ChangeEvent<HTMLInputElement>) => {
		setPage(+e.target.value)
	}

	return (
		<div className={clsx('flex items-center justify-between', className)}>
			<Inline>
				{showInfo && (
					<div className="hidden sm:block">
						<p className="text-sm leading-5 text-gray-700 dark:text-gray-200">
							Showing
							<span className="font-medium px-1">{(page - 1) * pageSize + 1}</span>
							to
							<span className="font-medium px-1">{(page - 1) * pageSize + pageCount}</span>
							of
							<span className="font-medium px-1">{totalCount}</span>
							results
						</p>
					</div>
				)}
				{showCounter && (
					<div className="max-w-xs rounded-md shadow-sm hidden sm:block">
						<select
							className="block form-input w-full transition duration-150 ease-in-out sm:text-sm sm:leading-4"
							value={pageSize}
							onChange={changePageSize}
						>
							<option value={5}>5 results</option>
							<option value={10}>10 results</option>
							<option value={20}>20 results</option>
							<option value={50}>50 results</option>
							<option value={100}>100 results</option>
						</select>
					</div>
				)}
				{showFilter && setFilterVisible && (
					<button
						className={intents.secondary.button}
						onClick={() => setFilterVisible((x) => !x)}
						type="button"
					>
						<SearchIcon className="w-4 h-4" />
					</button>
				)}
			</Inline>

			<div className="flex-1 flex justify-end items-center">
				{isLoading && <Spinner className="w-5 h-5 spin mr-3" />}

				<span className="relative z-0 inline-flex shadow-sm rounded-md mr-4">
					{pages.length > 200 ? (
						<input
							type="number"
							className="form-input leading-5 py-1.5"
							value={page}
							onChange={handleKeyPress}
							min={1}
							max={pages.length}
						/>
					) : (
						<select
							className={
								'relative block form-input w-full transition duration-150 ease-in-out sm:text-sm sm:leading-4 focus:z-10'
							}
							value={page}
							onChange={(e) => setPage(+e.target.value)}
						>
							{pages.map((n) => (
								<option key={n} value={n}>
									{n}
								</option>
							))}
						</select>
					)}
				</span>

				<span className={clsx('relative z-0 inline-flex shadow-sm', roundedness.md)}>
					<button
						type="button"
						onClick={() => setPage(page > 1 ? page - 1 : page)}
						className={clsx(intents.secondary.button, sizes.md, roundedness.md)}
						aria-label="Previous"
					>
						<ArrowLeftIcon className="w-4 h-4" />
					</button>
				</span>
				<span className={clsx('ml-4 relative z-0 inline-flex shadow-sm', roundedness.md)}>
					<button
						type="button"
						onClick={() => setPage(page < pages.length ? page + 1 : page)}
						className={clsx(intents.secondary.button, sizes.md, roundedness.md)}
					>
						<ArrowRightIcon className="w-4 h-4" />
					</button>
				</span>
			</div>
		</div>
	)
}

export default Pagination
