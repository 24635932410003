import { FC } from 'react'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Table, { TableSchema } from 'components/Table'
import useData from 'hooks/useData'
import { confirm } from 'alerts'
import { DeviceModel } from 'types/models'
import UAParser from 'ua-parser-js'
import api from 'api'
import { CardWrapper } from 'components/Card'
import { format, parseISO } from 'date-fns'
import { DotsHorizontalIcon, TrashIcon } from '@heroicons/react/outline'

const Devices: FC = () => {
	const { data = [], refetch } = useData<DeviceModel[]>('/Devices')

	const deleteDevice = async (x: DeviceModel) => {
		const confirmed = await confirm({
			title: 'Are you sure you want to deauthorise this device?',
			message: 'You will require a two-factor code on this device again in future.',
			intent: 'primary',
		})

		if (!confirmed) return false

		await api.delete(`/Devices/${x.id}`)
		refetch()
	}

	const getUAName = (userAgent: string) => {
		let ua = new UAParser(userAgent)
		return `${ua.getBrowser().name} on ${ua.getOS().name} ${ua.getOS().version}`
	}

	const schema: TableSchema<DeviceModel> = {
		cols: [
			{
				title: 'Type',
				value: (x) => getUAName(x.userAgent),
			},
			{
				title: 'Last Login Date',
				value: (x) => (x.lastLoggedIn ? format(parseISO(x.lastLoggedIn), 'dd/MM/yyyy') : '-'),
			},
		],
		actions: (x) => [
			{
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					{
						title: 'Deauthorize',
						intent: 'danger',
						icon: <TrashIcon className="w-5 h-5" />,
						onClick: () => deleteDevice(x),
					},
				],
			},
		],
	}

	return (
		<>
			<PageHeading title="Devices" />

			<PageContent>
				<CardWrapper className="overflow-hidden">
					<div className="-my-px">
						<Table items={data} schema={schema} />
					</div>
				</CardWrapper>
			</PageContent>
		</>
	)
}

export default Devices
