import { FC, useEffect } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import Card, { CardWrapper } from 'components/Card'
import CardHeader from 'components/CardHeader'
import FormInput from 'components/Form/FormInput'
import FormSelect from 'components/Form/FormSelect'
import { addMonths, format, parseISO } from 'date-fns'
import Stack from 'components/Stack'
import { PencilIcon, XIcon, PlusIcon } from '@heroicons/react/outline'
import Table, { TableSchema } from 'components/Table'
import { CareIndication, CaseComplexity, CaseType, CASE_COMPLEXITY, CaseProcess, CASE_PROCESS } from 'types/enums'
import useData from 'hooks/useData'
import openPendingReview from 'modals/openPendingReview'
import { CaseModel, OrganizationModel, ReviewModel } from 'types/models'
import FormHidden from 'components/Form/FormHidden'
import { ensureDate, isComplexReview } from 'utils/funcs'
import { FENZOrgId } from 'utils/constants'

interface CaseInformationProps {
	caseData?: CaseModel
}

const CaseInformation: FC<CaseInformationProps> = ({ caseData }) => {
	const { watch, setValue } = useFormContext()
	const { created, rejected } = watch(['created', 'rejected'])

	const { fields: reviews, append, insert, remove } = useFieldArray({ name: 'reviews', keyName: 'key' })

	const { data: organizations = { items: [] } } = useData<{ items: OrganizationModel[] }>('organizations', {
		pageSize: 100,
	})

	const type = watch('caseType')
	const organization = watch('organization')

	const isFENZ = organization?.id === FENZOrgId

	const reviewSchema: TableSchema<Partial<ReviewModel & { key: string }>> = {
		cols: [
			{
				title: 'Review Number',
				value: (x) => x.reviewNumber,
				truncate: true,
			},
			{
				title: 'Issue Code',
				value: (x) => x.issueCode,
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'ACC Decision Date',
				value: (x) => (x.accDecisionDate ? format(ensureDate(x.accDecisionDate), 'dd/MM/yyyy') : '-'),
				width: 'minmax(auto, max-content)',
				responsive: 'md',
			},
			{
				title: 'Lodgement Date',
				value: (x) => (x.lodgementDate ? format(ensureDate(x.lodgementDate), 'dd/MM/yyyy') : '-'),
				width: 'minmax(auto, max-content)',
				responsive: 'md',
			},
			{
				title: 'Set By Date',
				value: (x) => (x.lodgementDate ? format(addMonths(ensureDate(x.lodgementDate), 3), 'dd/MM/yyyy') : '-'),
				width: 'minmax(auto, max-content)',
				responsive: 'md',
			},
		],
		actions: (x, i) => [
			{
				icon: <PencilIcon className="w-4 h-4" />,
				intent: 'menu',
				onClick: async (e) => {
					const updatedReview = await openPendingReview({ review: x })

					if (updatedReview) {
						if (isComplexReview(updatedReview)) {
							setValue('fields.complex.value', CASE_COMPLEXITY.Complex)
						} else {
							let spreadReviews = [...reviews]
							spreadReviews.splice(i, 1, updatedReview)
							let complexReviews = spreadReviews.filter((x) =>
								isComplexReview(x as { issueCode: string })
							)

							if (complexReviews.length === 0 && reviews.length < 2) {
								setValue('fields.complex.value', CASE_COMPLEXITY.Standard)
							}
						}

						remove(i)
						insert(i, updatedReview)
					}
				},
			},
			{
				icon: <XIcon className="w-4 h-4" />,
				intent: 'menu',
				onClick: (e) => {
					e.preventDefault()
					e.stopPropagation()

					remove(i)

					if (
						reviews.length - 1 < 2 ||
						![...reviews].splice(i, 1).filter((x) => isComplexReview(x as { issueCode?: string })).length
					) {
						setValue('fields.complex.value', CASE_COMPLEXITY.Standard)
					}
				},
			},
		],
	}

	useEffect(() => {
		if (rejected) setValue('process', CASE_PROCESS.Adjudication)
	}, [rejected, setValue])

	// useEffect(() => {
	// 	if (orgValue.id !== FENZOrgId && hearings.length === 0) {
	// 		setValue('hearings', [newHearing(caseData)])
	// 	}
	// }, [orgValue.id, hearings, caseData, setValue])

	return (
		<Stack space={8}>
			<Card header={<CardHeader title="Details" subtitle="Case information and dates" />}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					<Stack>
						<FormSelect
							name="organization"
							label="Organisation"
							options={organizations.items.map((x) => ({ label: x.name, value: x }))}
							validations={{ required: 'Organisation is required' }}
						/>

						{!isFENZ && (
							<FormInput
								name="fields.accClaimNumber.value"
								label="ACC Claim Number"
								validations={{ required: 'ACC Claim Number is required' }}
							/>
						)}

						<FormSelect
							name="fields.careIndication.value"
							label="Care Indication Status"
							options={CareIndication.options}
							validations={{ required: 'Care Indication Status is required' }}
						/>
					</Stack>

					<Stack>
						{!isFENZ && (
							<FormSelect
								name="caseType"
								label="Case Type"
								options={CaseType.options}
								validations={{ required: 'Case Type is required' }}
							/>
						)}

						{!isFENZ && (
							<FormSelect
								name="fields.complex.value"
								label="Case Complexity"
								options={CaseComplexity.options}
								validations={{ required: 'Case Complexity is required' }}
							/>
						)}

						{isFENZ && (
							<>
								<FormSelect
									name="process"
									label="Process"
									options={CaseProcess.options}
									validations={{ required: 'Process is required' }}
									disabled={rejected}
								/>
							</>
						)}

						<div className="flex flex-col mb-0">
							<label className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200">
								Application Date (to ICRA)
							</label>

							<div className="mt-1 px-3 py-2 text-gray-700 dark:text-gray-300 text-sm rounded-md border border-gray-200 dark:border-gray-600">
								{format(parseISO(created), 'dd/MM/yyyy')}
							</div>
						</div>
					</Stack>
				</div>
			</Card>

			{!isFENZ && (
				<CardWrapper className="overflow-hidden">
					<CardHeader
						title="Reviews"
						subtitle="Individual review numbers and their dates"
						actions={[
							{
								title: 'Add Review',
								intent: 'secondary',
								icon: <PlusIcon className="w-5 h-5" />,
								size: 'xs',
								onClick: async () => {
									const newReview = await openPendingReview({})

									if (newReview) {
										append(newReview)
										setValue('caseType', type, { shouldDirty: true })

										if (isComplexReview(newReview)) {
											setValue('fields.complex.value', CASE_COMPLEXITY.Complex)
										} else if (reviews.length > 0) {
											// this actually means if there is now 2 or more reviews
											setValue('fields.complex.value', CASE_COMPLEXITY.Complex)
										}
									}
								},
							},
						]}
					/>

					<Table items={reviews} schema={reviewSchema} getKey={(x, i) => x.key || i} className="-my-px" />

					{reviews.map((review, i) => (
						<div key={review.key}>
							<FormHidden name={`reviews[${i}].id`} defaultValue={review.id} />
							<FormHidden name={`reviews[${i}].reviewNumber`} defaultValue={review.reviewNumber} />
							<FormHidden name={`reviews[${i}].issueCode`} defaultValue={review.issueCode} />
							<FormHidden
								name={`reviews[${i}].accDecisionDate`}
								defaultValue={review.accDecisionDate ? ensureDate(review.accDecisionDate) : null}
							/>
							<FormHidden
								name={`reviews[${i}].lodgementDate`}
								defaultValue={ensureDate(review.lodgementDate)}
							/>
						</div>
					))}
				</CardWrapper>
			)}

			<Card header={<CardHeader title="Case Management" subtitle="Additional Information for case management" />}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					<FormInput
						name="fields.specialInstructions.value"
						label="Additional Information"
						as="textarea"
						inputClassName="h-20 min-h-10"
					/>

					<FormInput
						name="fields.triageNotes.value"
						label="Triage Notes"
						as="textarea"
						inputClassName="h-20 min-h-10"
					/>

					<FormInput
						name="fields.careManagementPlan.value"
						label="Care Indicator Information"
						as="textarea"
						inputClassName="h-20 min-h-10"
					/>
				</div>
			</Card>
		</Stack>
	)
}

export default CaseInformation
