import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { endOfDay, format, parseISO, startOfDay } from 'date-fns'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { UserModel } from 'types/models'
import { TableSchema } from 'components/Table'
import ReminderSearch from 'search/ReminderSearch'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { gql } from '@apollo/client'
import { GQLConnection, GQLReminderType } from 'types/gql'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: ReminderOrderBy!, $search: ReminderSearch) {
		Reminders(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				created
				createdBy {
					id
					email
				}
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				case {
					id
					caseNumber
				}
				event {
					id
					startDate
					type {
						id
						readable
					}
				}
				users {
					id
					fullName
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

interface UserTabRemindersProps {
	user: UserModel
}

const UserTabReminders: FC<UserTabRemindersProps> = ({ user }) => {
	const location = useLocation()

	const state = location?.state as {
		reminders_search?: any
		reminders_page?: number
		reminders_pageSize?: number
	} | null

	const searchDate = state?.reminders_search?.date
		? {
				created_from: startOfDay(state?.reminders_search?.date),
				created_to: endOfDay(state?.reminders_search?.date),
		  }
		: {}

	const { data, loading, refetch } = useBufferedQuery<{ Reminders: GQLConnection<GQLReminderType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.reminders_page || 1,
			pageSize: state?.reminders_pageSize || 5,
			search: {
				caseNumber: state?.reminders_search?.caseNumber,
				userId: user.id,
				status: state?.reminders_search?.status,
				type: state?.reminders_search?.type,
				...searchDate,
			},
		},
	})

	const schema: TableSchema<GQLReminderType> = {
		cols: [
			{
				title: 'Case',
				value: (x) => (
					<Link to={`/cases/${x.case?.id}`} className="anchor">
						{x.case?.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Conference',
				value: (x) =>
					x.event &&
					x.case && (
						<Link to={`/cases/${x.case.id}/conferences?hearing=${x.event.id}`} className="anchor">
							{x.event.type?.readable} ({format(parseISO(x.event?.startDate || ''), 'dd/MM/yyyy')})
						</Link>
					),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
			},
			{
				title: 'Created',
				value: (x) => format(parseISO(x.created || ''), 'dd/MM/yyyy HH:mm:ss'),
				truncate: true,
			},
			{
				title: 'Completed',
				value: (x) => x.status?.readable,
				width: 'minmax(auto, max-content)',
			},
		],
	}

	return (
		<GQLPaginatedTable
			set={data?.Reminders}
			schema={schema}
			isLoading={loading}
			refetch={refetch}
			paginationPrefix="reminders_"
			search={(props) => (
				<ReminderSearch
					{...props}
					presetValues={{
						userId: { value: user.id, locked: true },
					}}
				/>
			)}
		/>
	)
}

export default UserTabReminders
