import { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import FormInput from 'components/Form/FormInput'
import FormDate from 'components/Form/FormDate'
import FormCheckbox from 'components/Form/FormCheckbox'
import FormSelect from 'components/Form/FormSelect'
import FormToggle from 'components/Form/FormToggle'
import FormHidden from 'components/Form/FormHidden'
import { format, subMinutes, addMinutes } from 'date-fns'
import { ensureDate, getDateFromTime, getFullName, isNZ } from 'utils/funcs'
import Stack from 'components/Stack'
import Card, { CardWrapper } from 'components/Card'
import CardHeader from 'components/CardHeader'
import AutoHeight from 'components/AutoHeight'
import { FORUM_TYPE, ForumType, HEARING_TYPE, HearingType, Duration, TimeSlots } from 'types/enums'
import { CaseModel, ReviewModel, UserModel } from 'types/models'
import Table, { TableSchema } from 'components/Table'
import { isNotNone } from 'types'
import { FormHearingType } from 'pages/Cases/PendingCase/PendingCase'
import { path } from 'ramda'
import UserDefault from 'components/UserDefault'
import UserAvatar from 'components/UserAvatar'
import { ACCOrgId } from 'utils/constants'
import { ExclamationIcon } from '@heroicons/react/solid'
import { gql, useQuery } from '@apollo/client'
import { GQLConnection, GQLVenueType } from 'types/gql'

const endOfDay = getDateFromTime('17:00')

const venuesQuery = gql`
	query {
		Venues {
			items {
				id
				name
				address
			}
		}
	}
`

interface HearingProps {
	caseData: CaseModel
	index: number
	hearing: FormHearingType
}

const Hearing: FC<HearingProps> = ({ caseData, index, hearing }) => {
	const { data: venues } = useQuery<{ Venues: GQLConnection<GQLVenueType> }>(venuesQuery)

	const [nz] = useState(isNZ())

	const { watch, setValue } = useFormContext()

	const { parties, organization } = watch(['parties', 'reviews', 'organization'])
	const hearingType = watch(`hearings[${index}].hearingType`)
	const duration = watch(`hearings[${index}].duration`)
	const startDate = watch(`hearings[${index}].startDate`)
	const time = watch(`hearings[${index}].time`)
	const forumType = watch(`hearings[${index}].forumType`)
	const venueId = watch(`hearings[${index}].venueId`)
	const roomBooked = watch(`hearings[${index}].roomBooked`)
	const travelBooked = watch(`hearings[${index}].travelBooked`)
	const formAttendees = watch(`hearings[${index}].attendees`)
	const reviews = watch(`hearings[${index}].reviews`)

	// Times
	let times = useMemo(() => {
		if (!duration) {
			return TimeSlots
		} else {
			let endPoint = +subMinutes(endOfDay, duration)
			return TimeSlots.filter((x) => +getDateFromTime(x.from24) <= endPoint)
		}
	}, [duration])

	// unset time if it becomes invalid
	useEffect(() => {
		if (time && !times.find((x) => x.from === time)) {
			setValue(`hearings[${index}].time`, null)
			setValue(`hearings[${index}].duration`, null)
		}
	}, [time, times, index, setValue])

	// Durations
	let durations = useMemo(() => {
		let list: { label: string; value: number }[] = []

		if (+hearingType === HEARING_TYPE.CMC) list = Duration.CMC.options
		if (+hearingType === HEARING_TYPE.Review) list = Duration.Review.options
		if (+hearingType === HEARING_TYPE.Mediation) list = Duration.Mediation.options

		list = list.filter((x) => {
			if (!time) return true
			let from24 = TimeSlots.find((x) => x.from === time)?.from24
			if (from24) {
				let endPoint = addMinutes(getDateFromTime(from24), +x.value)
				return endPoint.valueOf() <= endOfDay.valueOf()
			}
			return true
		})

		return list
	}, [hearingType, time])

	// unset the duration if it disappears from the list
	useEffect(() => {
		if (duration && !durations?.find((x) => x.value === duration)) {
			setValue(`hearings[${index}].duration`, null)
		}
	}, [durations, duration, index, setValue])

	const attendees = [
		{ user: parties.applicant?.user, path: 'applicant.user', role: 'Applicant' },
		...(parties.applicant?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `applicant.parties.${i}`,
			role: 'Advocate',
		})) || []),
		{
			user: parties.reviewer?.user,
			path: 'reviewer.user',
			role: 'Reviewer',
		},
		...(parties.reviewer?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `reviewer.parties.${i}`,
			role: 'Reviewer support',
		})) || []),
		{ user: parties.mediator?.user, path: 'mediator.user', role: 'Mediator' },
		...(parties.mediator?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `mediator.parties.${i}`,
			role: 'Mediator support',
		})) || []),
		{ user: parties.peerReviewer?.user, path: 'peerReviewer.user', role: 'Peer Reviewer' },
		{
			user: parties.respondent?.user,
			path: 'respondent.user',
			role: organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent',
		},
		...(parties.respondent?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `respondent.parties.${i}`,
			role: 'Respondent support',
		})) || []),
	].filter((x) => isNotNone(x.user))

	const attendeeSchema: TableSchema<{ user: UserModel; path: string; role: string }> = {
		cols: [
			{
				title: (
					<div className="flex space-x-4">
						<input
							type="checkbox"
							className="form-checkbox dark:text-green-500"
							ref={(el) => {
								if (!el) return

								if (Object.values<boolean>(attendees).length === 0) {
									el.checked = false
									el.indeterminate = false
									return
								}

								const allChecked = Object.values<{ path: string }>(attendees).reduce<boolean>(
									(pass, item) => {
										return pass && !!path(item.path.split('.'), formAttendees)
									},
									true
								)

								const noneChecked = Object.values<{ user: boolean; parties: boolean[] }>(
									formAttendees
								).reduce<boolean>((fail, item) => {
									return (
										fail && !item.user && item.parties.reduce((fail2, val) => fail2 && !val, true)
									)
								}, true)

								if (allChecked) {
									el.checked = true
									el.indeterminate = false
								} else if (noneChecked) {
									el.checked = false
									el.indeterminate = false
								} else if (!allChecked && !noneChecked) {
									el.checked = false
									el.indeterminate = true
								}
							}}
							onChange={(e) => {
								attendees.forEach((item) => {
									setValue(`hearings[${index}].attendees.${item.path}`, e.target.checked)
								})
							}}
						/>

						<div>Check All</div>
					</div>
				),
				value: (x) => (
					<FormCheckbox
						name={`hearings[${index}].attendees.${x.path}`}
						label={
							<div className="flex flex-row space-x-4 items-center pl-2">
								<div className="cursor-pointer">
									{!x.user?.avatarUrl ? (
										<UserDefault name={getFullName(x.user)} className="w-9 h-9" />
									) : (
										<UserAvatar
											className="rounded-full w-9 h-9 object-cover shadow-sm"
											src={x.user?.avatarUrl}
											name={getFullName(x.user)}
										/>
									)}
								</div>
								<div className="flex flex-col items-start">
									<div>{getFullName(x.user)}</div>
									<div className="text-gray-400 font-normal">{x.user?.email}</div>
									<div className="font-medium sm:hidden">{x.role}</div>
								</div>
							</div>
						}
						className="flex items-center"
						defaultValue={path(x.path.split('.'), formAttendees)}
					/>
				),
				className: 'font-medium',
			},
			{
				title: 'Role',
				value: (x) => x.role,
				responsive: 'sm',
			},
		],
	}

	const reviewSchema: TableSchema<ReviewModel> = {
		cols: [
			{
				title: (
					<div className="flex space-x-4">
						<input
							type="checkbox"
							className="form-checkbox dark:text-green-500"
							ref={(el) => {
								if (!el) return

								if (Object.values<boolean>(reviews).length === 0) {
									el.checked = false
									el.indeterminate = false
									return
								}

								const allChecked = Object.values<boolean>(reviews).reduce<boolean>((pass, checked) => {
									return pass && checked
								}, true)

								const noneChecked = Object.values<boolean>(reviews).reduce<boolean>((pass, checked) => {
									return pass && !checked
								}, true)

								if (allChecked) {
									el.checked = true
									el.indeterminate = false
								} else if (noneChecked) {
									el.checked = false
									el.indeterminate = false
								} else if (!allChecked && !noneChecked) {
									el.checked = false
									el.indeterminate = true
								}
							}}
							onChange={(e) => {
								Object.keys(reviews).forEach((id) => {
									setValue(`hearings[${index}].reviews.${id}`, e.target.checked)
								})
							}}
						/>

						<div>Check All</div>
					</div>
				),
				value: (x, i) => {
					return (
						<FormCheckbox
							name={`hearings[${index}].reviews.${x.id}`}
							label={x.reviewNumber}
							className="flex items-center"
							defaultValue={x?.id && hearing.reviews[x.id] ? hearing.reviews[x.id] : false}
						/>
					)
				},
				className: 'font-medium',
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Issue Code',
				value: (x) => x.issueCode,
				truncate: true,
				responsive: 'sm',
			},
			{
				title: 'Lodgement Date',
				value: (x) => (x.lodgementDate ? format(ensureDate(x.lodgementDate), 'dd/MM/yyyy') : '-'),
				width: 'minmax(auto, max-content)',
				responsive: 'sm',
			},
			{
				title: 'ACC Decision Date',
				value: (x) => (x.accDecisionDate ? format(ensureDate(x.accDecisionDate), 'dd/MM/yyyy') : '-'),
				width: 'minmax(auto, max-content)',
				responsive: 'sm',
			},
		],
	}

	return (
		<Stack space={8}>
			<Card header={<CardHeader title="Details" subtitle="When and Where the meeting is" />}>
				{!nz && (
					<div className="rounded-md bg-yellow-400 text-yellow-800 dark:bg-yellow-500 dark:text-yellow-300 bg-opacity-25 dark:bg-opacity-25 p-4 mb-6">
						<div className="flex">
							<div className="shrink-0">
								<ExclamationIcon className="h-5 w-5 text-yellow-300" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
									Attention needed
								</h3>
								<div className="mt-2 text-sm text-yellow-700 dark:text-yellow-300">
									<p>
										We have detected that you are currently using a timezone outside of New Zealand.
										The conference date set on this page will be in NZ time.
									</p>
								</div>
							</div>
						</div>
					</div>
				)}

				<FormHidden name={`hearings[${index}].id`} defaultValue={hearing?.id} />
				<Stack>
					<div className="flex space-x-6">
						<FormSelect
							name={`hearings[${index}].hearingType`}
							label="Type"
							options={HearingType.options}
							className="flex-1"
							validations={{ required: 'Type is required' }}
							defaultValue={hearing?.hearingType || null}
						/>

						<FormDate
							name={`hearings[${index}].startDate`}
							label="Date"
							className="flex-1"
							validations={{ required: 'Date is required' }}
							defaultValue={hearing?.startDate || null}
							disableHolidays
						/>
					</div>

					<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
						<div className="flex space-x-6">
							<FormSelect
								name={`hearings[${index}].time`}
								label="Time"
								options={times.map((x) => ({ label: x.from, value: x.from }))}
								disabled={!startDate}
								className="flex-1"
								validations={{ required: 'Time is required' }}
								defaultValue={hearing?.time || null}
							/>

							<FormSelect
								name={`hearings[${index}].duration`}
								label="Duration"
								options={durations || []}
								disabled={!time}
								className="flex-1"
								validations={{ required: 'Duration is required' }}
								defaultValue={hearing?.duration || null}
							/>
						</div>
					</AutoHeight>

					<FormSelect
						name={`hearings[${index}].forumType`}
						label="Forum"
						options={ForumType.options}
						validations={{ required: 'Forum is required' }}
						defaultValue={hearing?.forumType || null}
					/>

					<AutoHeight show={forumType === FORUM_TYPE.FaceToFace}>
						<FormSelect
							name={`hearings[${index}].venueId`}
							label="Venue"
							options={
								venues?.Venues.items?.concat({ id: 'custom', name: 'Custom' })?.map((x) => ({
									value: x.id,
									label: (
										<div>
											<div>{x.name}</div>
											{x.address && <div className="opacity-50 text-sm">{x.address}</div>}
										</div>
									),
								})) || []
							}
							validations={{ required: 'Venue is required' }}
							defaultValue={hearing?.venueId || ''}
						/>
					</AutoHeight>

					<AutoHeight show={forumType === FORUM_TYPE.FaceToFace && venueId === 'custom'}>
						<FormInput
							name={`hearings[${index}].venue`}
							label="Venue Address"
							validations={{ required: 'Venue is required' }}
							defaultValue={hearing?.venue || ''}
						/>
					</AutoHeight>

					{forumType !== FORUM_TYPE.OnThePapers && (
						<FormInput
							name={`hearings[${index}].zoomMeeting`}
							label="Meeting"
							as="textarea"
							defaultValue={hearing?.zoomMeeting || ''}
							validations={
								forumType !== FORUM_TYPE.FaceToFace
									? { required: 'Meeting Details is required' }
									: undefined
							}
						/>
					)}
				</Stack>
			</Card>

			<CardWrapper className="overflow-hidden">
				<CardHeader title="Attendees" subtitle="Who is attending the meeting" />
				<Table items={attendees} schema={attendeeSchema} className="-mt-px" />
			</CardWrapper>

			<CardWrapper className="overflow-hidden">
				<CardHeader
					title="Reviews"
					subtitle="Which Review Numbers are going to be discussed in this meeting?"
				/>
				<Table items={caseData.reviews} schema={reviewSchema} className="-mt-px" />
			</CardWrapper>

			<Card header={<CardHeader title="Special Requirements" />}>
				<Stack>
					<FormInput
						name={`hearings[${index}].accessibility`}
						label="Accessibility"
						defaultValue={hearing?.accessibility || ''}
					/>
					<FormInput
						name={`hearings[${index}].cultural`}
						label="Cultural"
						defaultValue={hearing?.cultural || ''}
					/>
					<FormInput
						name={`hearings[${index}].security`}
						label="Security"
						defaultValue={hearing?.security || ''}
					/>
					<FormInput
						name={`hearings[${index}].other`}
						label="Other"
						as="textarea"
						defaultValue={hearing?.other || ''}
					/>
					<FormToggle
						name={`hearings[${index}].interpreterRequired`}
						label="Interpreter required"
						defaultValue={hearing?.interpreterRequired || false}
					/>
				</Stack>
			</Card>

			<Card header={<CardHeader title="Operational Details" subtitle="Other details about the meeting" />}>
				<Stack>
					<FormToggle
						name={`hearings[${index}].roomBooked`}
						label="Room booked?"
						defaultValue={hearing?.roomBooked || false}
					/>

					<AutoHeight show={!!roomBooked}>
						<FormInput
							name={`hearings[${index}].roomHireDetails`}
							label="Room hire details"
							as="textarea"
							defaultValue={hearing?.roomHireDetails || ''}
						/>
					</AutoHeight>

					<FormToggle
						name={`hearings[${index}].travelBooked`}
						label="Travel booked?"
						defaultValue={hearing?.travelBooked || false}
					/>

					<AutoHeight show={!!travelBooked}>
						<FormInput
							name={`hearings[${index}].travelArrangements`}
							label="Travel arrangements"
							as="textarea"
							defaultValue={hearing?.travelArrangements || ''}
						/>
					</AutoHeight>
				</Stack>
			</Card>
		</Stack>
	)
}

export default Hearing
