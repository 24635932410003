import { useEffect, useRef } from "react"

const useAutoFocus = <T = HTMLElement>(enabled: boolean = true) => {
	const ref = useRef<T>(null)

	useEffect(() => {
		if (ref.current && enabled) { 
			// @ts-ignore
			if (typeof ref.current?.focus === 'function') {
				setTimeout(() => {
					// @ts-ignore
					ref.current?.focus()
				}, 100)
			}
		}
	}, [enabled])

	return ref
}

export default useAutoFocus