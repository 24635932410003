import { FC } from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import { UserModel } from 'types/models'

// hooks
import { openModal, ModalContent, ModalFooter } from 'hooks/useModal'

// components
import Form from 'components/Form/Form'
import Actions from 'components/Actions'
import { ModalResolveFn } from 'types'
import FormSelect from 'components/Form/FormSelect'
import { gql, useQuery } from '@apollo/client'
import { USER_ROLE } from 'types/enums'
import { GQLConnection, GQLUserType } from 'types/gql'
import api from 'api'
import { FENZOrgId } from 'utils/constants'

const query = gql`
	query {
		Users(search: { role: "${USER_ROLE.CaseManager}", organization: "${FENZOrgId}" }) {
			items {
				id
				fullName
			}
		}
	}
`

const openApplicationCM = () => {
	return openModal<UserModel>({
		title: 'Select Case Manager',
		render: (close) => <RenameFileForm close={close} />,
	})
}

interface FormData {
	user: UserModel | null
}

const RenameFileForm: FC<{ close: ModalResolveFn<UserModel> }> = ({ close }) => {
	const { data } = useQuery<{ Users: GQLConnection<GQLUserType> }>(query)

	console.log('data', data)

	const formContext = useForm<FormData>({
		defaultValues: { user: null },
	})

	const handleSubmit = async (formData: FormData) => {
		if (formData.user) {
			let res = await api.get<UserModel>(`/Users/${formData.user.id}`)

			close(res.data)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<FormSelect
					name="user"
					label="Select Case Manager"
					options={data?.Users.items?.map((x) => ({ value: x, label: x.fullName || '' })) || []}
					validations={{
						required: 'Case Manager is required',
					}}
				/>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openApplicationCM
