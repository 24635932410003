import { useState, useEffect } from 'react'
import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client'

const useBufferedQuery = <T>(query: DocumentNode, options?: QueryHookOptions<T>) => {
	const { data, ...rest } = useQuery<T>(query, {
		fetchPolicy: 'no-cache',
		...options,
	})

	const [resolvedData, setResolvedData] = useState<T | undefined>()

	useEffect(() => {
		if (data) setResolvedData(data)
	}, [data])

	return { data: resolvedData, ...rest }
}

export default useBufferedQuery
