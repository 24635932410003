import { FC } from 'react'
import { endOfDay, format, parseISO, startOfDay } from 'date-fns'
import useData from 'hooks/useData'
import Stack from 'components/Stack'
import CaseHeader from 'components/CaseHeader'
import { Link, useLocation, useParams } from 'react-router-dom'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { TableSchema } from 'components/Table'
import { gql } from '@apollo/client'
import { GQLConnection, GQLReminderType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { CaseModel } from 'types/models'
import Search from 'search/ReminderSearch'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: ReminderOrderBy!, $search: ReminderSearch) {
		Reminders(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				created
				createdBy {
					id
					email
				}
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				case {
					id
					caseNumber
				}
				event {
					id
					startDate
					type {
						id
						readable
					}
				}
				users {
					id
					fullName
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

// interface ReminderProps {
// 	caseId?: string
// }

const Reminders: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const links = caseData ? caseLinks(caseData) : []

	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const searchDate = state?.search?.date
		? {
				created_from: startOfDay(state?.search?.date),
				created_to: endOfDay(state?.search?.date),
		  }
		: {}

	const {
		data,
		loading,
		refetch: refetchReminders,
	} = useBufferedQuery<{ Reminders: GQLConnection<GQLReminderType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: {
				caseNumber: caseData?.caseNumber,
				userId: state?.search?.userId,
				status: state?.search?.status,
				type: state?.search?.type,
				...searchDate,
			},
		},
	})

	const schema: TableSchema<GQLReminderType> = {
		cols: [
			{
				title: 'Conference',
				value: (x) =>
					x.event &&
					x.case && (
						<Link to={`/cases/${x.case.id}/conferences?hearing=${x.event.id}`} className="anchor">
							{x.event.type?.readable} ({format(parseISO(x.event?.startDate || ''), 'dd/MM/yyyy')})
						</Link>
					),
				truncate: true,
			},
			{
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
			},
			{
				title: 'User',
				value: (x) => x.users?.[0].fullName,
				truncate: true,
			},
			{
				title: 'Created',
				value: (x) => format(parseISO(x.created || ''), 'dd/MM/yyyy HH:mm:ss'),
				truncate: true,
			},
			{
				title: 'Completed',
				value: (x) => x.status?.readable,
				width: 'minmax(auto, max-content)',
			},
		],
	}

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader title="Reminders" />

					{caseData && (
						<GQLPaginatedTable
							schema={schema}
							set={data?.Reminders}
							isLoading={loading}
							refetch={refetchReminders}
							search={(props) => (
								<Search
									{...props}
									presetValues={{
										caseNumber: { value: caseData.caseNumber, locked: true },
									}}
								/>
							)}
						/>
					)}
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Reminders
