import { FC } from 'react'
import { Link } from 'react-router-dom'
import {
	ActionType,
	isActionMenuActionType,
	isButtonActionType,
	isSubmitActionType,
	isLinkActionType,
	isCustomMenuActionType,
	isNotNone,
} from 'types'
import ActionMenu from './ActionMenu'
import CustomMenu from './CustomMenu'
import Inline from './Inline'
import Button from './Button'
import clsx, { ClassValue } from 'clsx'

interface ActionsProps {
	actions?: ActionType[] | ActionType
	className?: ClassValue
}

const Actions: FC<ActionsProps> = ({ actions = [], className }) => {
	const actionList = (actions instanceof Array ? actions : [actions]).filter(isNotNone)

	return (
		<Inline className={clsx('inline-flex', className)}>
			{actionList.map((action, i) =>
				isLinkActionType(action) ? (
					<Link
						key={i}
						to={action.to}
						className={action.className}
						state={action.state}
						replace={action.replace}
					>
						<Button intent={action.intent} size={action.size} rounded={action.rounded}>
							<ActionInner action={action} />
						</Button>
					</Link>
				) : isButtonActionType(action) ? (
					<Button
						key={i}
						intent={action.intent}
						onClick={(e) => {
							e.stopPropagation()
							action.onClick && action.onClick(e)
						}}
						disabled={action.disabled || action.isLoading || false}
						className={action.className}
						type={action.type}
						isLoading={action.isLoading}
						size={action.size}
						rounded={action.rounded}
					>
						<ActionInner action={action} />
					</Button>
				) : isSubmitActionType(action) ? (
					<Button
						key={i}
						intent={action.intent}
						disabled={action.disabled || action.isLoading || false}
						className={action.className}
						type={action.type}
						isLoading={action.isLoading}
						size={action.size}
						rounded={action.rounded}
					>
						<ActionInner action={action} />
					</Button>
				) : isActionMenuActionType(action) ? (
					<ActionMenu
						key={i}
						actions={action.actions}
						intent={action.intent || 'secondary'}
						placement={action.placement}
						offset={action.offset}
						hoverable={action.hoverable}
						className={action.className}
						disabled={action.disabled}
						size={action.size || 'md'}
						rounded={action.rounded}
					>
						<ActionInner action={action} />
					</ActionMenu>
				) : isCustomMenuActionType(action) ? (
					<CustomMenu
						key={i}
						menu={action.menu}
						placement={action.placement}
						className={action.className}
						maxWidth={action.maxWidth}
					>
						{({ onClick }) => (
							<Button
								intent={action.intent}
								disabled={action.disabled}
								size={action.size}
								onClick={onClick}
							>
								<ActionInner action={action} />
							</Button>
						)}
					</CustomMenu>
				) : null
			)}
		</Inline>
	)
}

interface ActionInnerProps {
	action: ActionType
}

const ActionInner: FC<ActionInnerProps> = ({ action }) => {
	if (!isNotNone(action)) return null

	if ((isButtonActionType(action) || isSubmitActionType(action)) && action.isLoading) return <>{action.title}</>

	return (
		<>
			{(action.iconSide === 'left' || !action.iconSide) && action.icon ? (
				<span className={clsx(action.title ? '-ml-1 mr-2' : '')}>{action.icon}</span>
			) : null}
			{action.title}
			{action.iconSide === 'right' && action.icon ? <span className="-mr-1 ml-2">{action.icon}</span> : null}
		</>
	)
}

export default Actions
