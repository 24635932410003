import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { motion, AnimatePresence, MotionStyle } from 'framer-motion'

interface AlertTransitionProps {
	show: boolean
	className?: ClassValue
	children: ReactNode
}

export const AlertTransition: FC<AlertTransitionProps> = ({ show, className, children }) => (
	<motion.div
		className={clsx(className, 'relative z-40')}
		style={{ originY: 0 } as MotionStyle}
		variants={{
			hidden: { y: -24 },
			visible: { y: 0 },
		}}
		transition={{
			type: 'tween',
			ease: 'easeOut',
			duration: 0.2,
		}}
		initial="hidden"
		animate="visible"
		exit="hidden"
	>
		{children}
	</motion.div>
)

export const BackdropTransition: FC<AlertTransitionProps> = ({ show, className, children }) => (
	<AnimatePresence>
		{show && (
			<motion.div
				className={clsx(className, 'relative z-40')}
				style={{ originY: 0 } as MotionStyle}
				variants={{
					hidden: { opacity: 0 },
					visible: { opacity: 1 },
				}}
				transition={{
					type: 'tween',
					ease: 'easeOut',
					duration: 0.2,
				}}
				initial="hidden"
				animate="visible"
				exit="hidden"
			>
				{children}
			</motion.div>
		)}
	</AnimatePresence>
)

interface SlideoutTransitionProps {
	show: boolean
	className?: ClassValue
	children: ReactNode
}

export const SlideoutTransition: FC<SlideoutTransitionProps> = ({ show, className, children }) => (
	<motion.div
		className={clsx(className)}
		variants={{
			hidden: { opacity: 0 },
			visible: { opacity: 1 },
		}}
		transition={{
			type: 'tween',
			ease: 'easeOut',
			duration: 0.2,
		}}
		initial="hidden"
		animate="visible"
		exit="hidden"
	>
		{children}
	</motion.div>
)

interface ToastTransitionProps {
	show: boolean
	className?: ClassValue
	children: ReactNode
}

export const ToastTransition: FC<ToastTransitionProps> = ({ show, className, children }) => (
	<AnimatePresence>
		{show && (
			<motion.div
				className={clsx(className, 'z-40')}
				style={{ originY: 1, originX: 1 } as MotionStyle}
				variants={{
					hidden: { scale: 0.95, opacity: 0 },
					visible: { scale: 1, opacity: 1 },
				}}
				transition={{
					type: 'tween',
					ease: 'easeOut',
					duration: 0.3,
				}}
				initial="hidden"
				animate="visible"
				exit="hidden"
			>
				{children}
			</motion.div>
		)}
	</AnimatePresence>
)
