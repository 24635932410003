import Chat from 'twilio-chat'
import axios from 'axios'

export const createClient = async (token) => {
	var client = new Chat(token)

	await client.initialize()

	return client
}

export const getChannels = (client) => {
	if (client) {
		return client.getSubscribedChannels()
	}
	return Promise.reject(new Error('Client is undefined'))
}

export const createChannel = async (server, client, payload) => {
	let res = await axios.post(`${server}/convo`, payload)

	if (client) {
		return client.getChannelByUniqueName(res.data)
	}
	return Promise.reject(new Error('Client is undefined'))
}

export const getMessages = (channel) => {
	if (channel) {
		return channel.getMessages()
	}
	return Promise.reject(new Error('Channel is undefined'))
}

export const getLastMessages = (channel, num = 1) => {
	if (channel) {
		return channel.getMessages(num)
	}
	return Promise.reject(new Error('Channel is undefined'))
}

export const send = (channel, message) => {
	if (channel) {
		return channel.sendMessage(message)
	}
	return Promise.reject(new Error('Channel is undefined'))
}

export const on = (x, event, callback) => {
	x.on(event, callback)
	return () => x.removeListener(event, callback)
}
