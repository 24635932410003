import { FC, useState } from 'react'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { XIcon, CheckIcon } from '@heroicons/react/outline'
import FileDropper from 'components/FileDropper'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import api from 'api'
import { humanFileSize, isAcceptableFile } from 'utils/funcs'
import { warning } from 'alerts'
import { CaseFileCategories, CasePartyRole, CASE_FILE_CATEGORIES, CASE_PARTY_ROLE } from 'types/enums'
import { RefetchFn } from 'types'
import { CaseFileModel, HearingModel, ReminderModel } from 'types/models'
import Table, { TableSchema } from 'components/Table'
import Select from 'components/Select'
import { toast } from 'components/toast'

const categories = [
	CASE_FILE_CATEGORIES.General,
	CASE_FILE_CATEGORIES.DraftDecision,
	CASE_FILE_CATEGORIES.DraftMinute,
].map((x) => ({ label: CaseFileCategories.readable(x), value: x }))

interface OpenCloseReminder {
	title?: string
	reminder: ReminderModel
	refetch: RefetchFn
}

const openCloseReminder = ({ title, reminder, refetch }: OpenCloseReminder) => {
	return openModal({
		title: title || 'Upload Document via Reminder',
		size: 'lg',
		render: (close) => <CloseReminder close={close} reminder={reminder} refetch={refetch} />,
	})
}

const CloseReminder: FC<OpenCloseReminder & { close: () => void }> = ({ close, reminder, refetch }) => {
	const { isSaving, setSaving } = useModalState()
	const [files, setFiles] = useState<File[]>([])
	const [category, setCategory] = useState(categories[0])

	const handleSubmit = async () => {
		if (!files.length) return

		setSaving(true)

		try {
			const { data: hearing } = await api.get<HearingModel>(`/CaseHearings/${reminder.caseHearingId}`)
			const { data: fileList } = await api.get<CaseFileModel[]>(`/CaseFiles/hierarchy?caseId=${hearing.case?.id}`)

			let rootNode = fileList?.find((x) => x.parentId === null && !x.isFile)

			if (!rootNode) {
				setSaving(false)
				return
			}

			// const findFolder = (name: string, folder: CaseFileModel): CaseFileModel | undefined => {
			// 	if (folder.name === name) return folder
			// 	if (folder.hasDescendants) {
			// 		for (let i = 0; i < folder.children.length; i++) {
			// 			let result = findFolder(name, folder.children[i])
			// 			if (result) return result
			// 		}
			// 	}
			// }

			// let targetFolderName =
			// 	(reminder.reminderType === REMINDER_TYPE.Minute || reminder.reminderType === REMINDER_TYPE.AdjournmentMinute)
			// 		? 'REVIEWER DRAFT DOCUMENTS'
			// 		: 'DECISION/OUTCOME NOTIFICATION'

			const targetFolderName = 'REVIEWER DRAFT DOCUMENTS'

			let targetFolder = fileList.find((x) => x.name === targetFolderName)

			if (!targetFolder) {
				// console.log('creating node because it doesnt exist', targetFolderName)
				const { data: newFolder } = await api.post('/CaseFiles/node', {
					name: targetFolderName,
					description: 'folder',
					permissions: CasePartyRole.options.map((x) => x.value),
					parentId: rootNode.id,
					case: {
						id: hearing.case?.id,
					},
				})

				targetFolder = newFolder
			}

			const body = new FormData()

			if (hearing.case) body.append('case.id', hearing.case?.id)
			if (targetFolder) body.append('parentId', targetFolder.id)
			body.append('caseFileCategory', String(category || 0))

			let hadUnacceptable = 0

			for (let i = 0; i < files.length; i++) {
				let item = files[i]

				if (!isAcceptableFile(item)) {
					hadUnacceptable++
					continue
				}

				body.append('files', item)

				body.append(`permissions[${i}]`, String(CASE_PARTY_ROLE.CaseManager))
				body.append(`permissions[${i}]`, String(CASE_PARTY_ROLE.Reviewer))
			}

			if (hadUnacceptable) {
				warning({
					title: 'Invalid Files',
					message: 'At least one file with an invalid type was skipped',
				})
				if (files.length === hadUnacceptable) {
					setSaving(false)
					return
				}
			}

			await api.post('/CaseFiles', body)

			await api.put(`/Reminders/${reminder.id}`, {
				...reminder,
				reminderStatus: 1,
			})

			await refetch()

			close()

			toast({ title: 'File uploaded' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const schema: TableSchema<File> = {
		cols: [
			{
				title: 'Name',
				value: (x) => <div className="min-w-0 w-full truncate">{x.name}</div>,
				truncate: true,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.size),
				width: 'minmax(auto, max-content)',
			},
		],
		actions: (x) => [
			{
				icon: <XIcon className="w-5 h-5" />,
				intent: 'menu',
				disabled: isSaving,
				onClick: () => {
					setFiles((f) => f.filter((y) => y !== x))
				},
			},
		],
	}

	return (
		<>
			<ModalContent>
				<Stack>
					<FileDropper
						onChange={(newfiles) =>
							setFiles((f) => [...f, ...newfiles.filter((x) => !f.find((y) => y.name === x.name))])
						}
					/>

					{files.length > 0 && <Table items={files} schema={schema} />}

					<Select value={category} onChange={(val) => setCategory(val)} options={categories} />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Upload',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: handleSubmit,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openCloseReminder
