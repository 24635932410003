import { ReactNode, SetStateAction } from 'react'
import { AxiosError } from 'axios'
import { Placement } from '@popperjs/core/index'

export const isAxiosError = <T = any>(obj: any): obj is AxiosError<T> =>
	typeof obj === 'object' && obj.isAxiosError === true

export type IntentType =
	| 'primary'
	| 'secondary'
	| 'save'
	| 'danger'
	| 'menu'
	| 'sidebar-menu'
	| 'sub-menu'
	| 'borderless'
	| 'invisible'

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'inline'

export type ButtonRoundness = 'none' | 'sm' | 'rounded' | 'md' | 'lg' | 'full'

export type None = undefined | '' | false | null

export const isNotNone = <T>(x: T | None): x is T => {
	return x !== undefined && x !== '' && x !== false && x !== null
}

export type Nullish = undefined | null

export const isNotNullish = <T>(x: T | Nullish): x is T => {
	return typeof x !== 'undefined' && x !== null
}

export interface BaseActionType {
	title?: ReactNode
	intent?: IntentType
	className?: string
	icon?: ReactNode
	iconSide?: 'left' | 'right'
	disabled?: boolean
	size?: ButtonSize
	rounded?: ButtonRoundness
}

export interface LinkActionType extends BaseActionType {
	to: string
	state?: { [x: string]: any }
	replace?: boolean
}

export const isLinkActionType = (action: any): action is LinkActionType => typeof action.to === 'string'

export interface ButtonActionType extends BaseActionType {
	onClick: (e: any) => void
	type?: 'button' | 'submit'
	isLoading?: boolean
}

export const isButtonActionType = (action: any): action is ButtonActionType => typeof action.onClick === 'function'

export interface SubmitActionType extends BaseActionType {
	type: 'submit'
	isLoading?: boolean
}

export const isSubmitActionType = (action: any): action is ButtonActionType => action.type === 'submit'

export interface DividerActionType extends BaseActionType {
	divider: true
}

export const isDividerActionType = (action: any): action is DividerActionType => action?.divider === true

export interface ActionMenuActionType extends BaseActionType {
	actions: ActionType[] | (ActionType[] | None)[]
	placement?: Placement
	offset?: number | [number, number]
	hoverable?: boolean
}

export const isActionMenuActionType = (action: any): action is ActionMenuActionType => action.actions instanceof Array

export interface CustomMenuActionType extends BaseActionType {
	menu: ReactNode
	maxWidth?: number
	placement?: Placement
}

export const isCustomMenuActionType = (action: any): action is CustomMenuActionType => !!action.menu

export type ActionType =
	| LinkActionType
	| ButtonActionType
	| SubmitActionType
	| ActionMenuActionType
	| CustomMenuActionType
	| DividerActionType
	| None

export interface RouteConfig {
	title: string
	icon: ReactNode
	show: () => boolean
	exact: boolean
	children?: RouteConfig[]
	tooltip?: ReactNode
}

export interface LinkRouteConfig extends RouteConfig {
	to: string
}

export const isLinkRouteConfig = (x: any): x is LinkRouteConfig => typeof x.to !== 'undefined'

export interface AnchorRouteConfig extends RouteConfig {
	url: string
}

export const isAnchorRouteConfig = (x: any): x is AnchorRouteConfig => typeof x.url !== 'undefined'

export type SetState<T> = (x: SetStateAction<T>) => void

export interface BaseTabType {
	title: ReactNode
	simpleTitle?: string
	errors?: number
	key?: number | string
}

export interface LinkTabType extends BaseTabType {
	to: string
	state?: { [x: string]: any }
	replace?: boolean
}

export const isLinkTabType = (action: any): action is LinkTabType => typeof action.to === 'string'

export interface ButtonTabType extends BaseTabType {
	onClick: (item: TabType, n: number) => void
}

export const isButtonTabType = (action: any): action is ButtonTabType => typeof action.onClick === 'function'

export type TabType = LinkTabType | ButtonTabType

export interface PaginatedSet<T> {
	items: T[]
	pageNumber: number
	pageSize: number
	totalCount: number
	totalPages: number
}

export interface FilterFormProps {
	clear: () => void
}

export const isFile = (x: any): x is File => x instanceof File

export type RefetchFn = () => Promise<unknown>

export type ModalResolveFn<T = unknown> = (answer?: T) => void

export interface PresetValue<T> {
	value: T
	locked?: boolean
	hidden?: boolean
	filter?: (x: any) => boolean
}

export type Keyed<T> = T & { key: string }

export interface PaginationSearch {
	[x: string]: any
}

export interface PaginationState {
	page: number
	pageSize: number
	orderBy?: string
	orderDir: number
	search?: PaginationSearch
}

export interface InitialPaginationState {
	page?: number
	pageSize?: number
	orderBy?: string
	orderDir?: number
	search?: PaginationSearch
}

export interface OptionType {
	value: any
	label: any
}
