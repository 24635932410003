import { FC, useEffect, useState } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import ReactQuill from 'react-quill'
import api from 'api'
import { toast } from 'components/toast'
import { CaseFileModel } from 'types/models'
import { RefetchFn } from 'types'
import { createNumberedFileName } from 'utils/funcs'

const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', { color: [] }],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ align: [] }],
		['clean'],
	],
}

interface OpenEditSubmissionProps {
	file: CaseFileModel
	refetch?: RefetchFn
}

const openEditSubmission = (props: OpenEditSubmissionProps) => {
	return openModal({
		title: 'Edit Submission',
		size: 'lg',
		render: (close) => <EditSubmission close={close} {...props} />,
	})
}

const EditSubmission: FC<OpenEditSubmissionProps & { close: () => void }> = ({ close, file, refetch }) => {
	const { isSaving, setSaving } = useModalState()
	const [value, setValue] = useState('')

	useEffect(() => {
		api.get<string>(`/CaseFiles/${file.id}/download`, { responseType: 'text' }).then((res) => setValue(res.data))
	}, [file.fileType, file.id])

	const handleSubmit = async (e: MouseEvent) => {
		e.preventDefault()

		const uploadFile = new File([value], `${createNumberedFileName(file.name.replace('.html', ''))}.html`, {
			type: 'text/html',
		})

		setSaving(true)

		const body = new FormData()

		body.append('parentId', file.parentId)
		body.append('case.id', file.case.id)
		body.append('files', uploadFile)

		file.permissions.forEach((role) => {
			body.append('permissions[0]', String(role))
		})

		await api.post('/CaseFiles', body)

		close()

		if (refetch) await refetch()

		toast({ title: 'Submission Edited' })
	}

	return (
		<>
			<ModalContent>
				<Stack>
					<ReactQuill value={value} onChange={(val) => setValue(val)} modules={modules} />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: handleSubmit,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openEditSubmission
