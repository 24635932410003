import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import Tabs from 'components/Tabs'
import Stack from 'components/Stack'
import Table, { TableSchema } from 'components/Table'
import Actions from 'components/Actions'
import { XIcon, CheckIcon } from '@heroicons/react/outline'
import FileDropper from 'components/FileDropper'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import { warning } from 'alerts'
import { toast } from 'components/toast'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import api from 'api'
import { humanFileSize, isAcceptableFile, series } from 'utils/funcs'
import { format } from 'date-fns'
import AutoHeight from 'components/AutoHeight'
import { CASE_PARTY_ROLE, CASE_TYPE } from 'types/enums'
import { ModalResolveFn, RefetchFn } from 'types'
import { AppFileModel, ApplicationModel, CaseModel } from 'types/models'
import usePaginatedData from 'hooks/usePaginatedData'
import { FENZOrgId } from 'utils/constants'
import TableFrame from 'components/TableFrame'

// const allPermissions = [
// 	CASE_PARTY_ROLE.Reviewer,
// 	CASE_PARTY_ROLE.Mediator,
// 	CASE_PARTY_ROLE.Applicant,
// 	CASE_PARTY_ROLE.Respondent,
// 	CASE_PARTY_ROLE.CaseManager,
// ]

const permissions = [
	// CASE_PARTY_ROLE.Reviewer,
	// CASE_PARTY_ROLE.Mediator,
	// CASE_PARTY_ROLE.Applicant,
	// CASE_PARTY_ROLE.Respondent,
	CASE_PARTY_ROLE.CaseManager,
]

const createField = (name: string, value: string | null = null) => ({
	name,
	value,
	label: name,
	title: name,
})

interface FolderType {
	id: string
	name: string
	description: string
}

interface OpenCreateCaseProps {
	application?: ApplicationModel
	refetch?: RefetchFn
}

const useCreateCase = (props: OpenCreateCaseProps) => {
	return openModal<CaseModel>({
		title: 'Create Case',
		size: 'lg',
		render: (close) => <CreateCaseForm close={close} {...props} />,
	})
}

interface FormData {
	accClaimNumber?: string
	applicantName: string
}

const CreateCaseForm: FC<OpenCreateCaseProps & { close: ModalResolveFn<CaseModel> }> = ({
	application,
	refetch,
	close,
}) => {
	const [activeTab, setActiveTab] = useState(0)

	const [uploads, setUploads] = useState<File[]>([])

	const formContext = useForm<FormData>({
		defaultValues: {
			accClaimNumber: '',
			applicantName: '',
		},
	})

	const uploadSchema: TableSchema<File> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.name,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.size),
			},
		],
		actions: (item) => [
			{
				icon: <XIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => {
					setUploads((f) => f.filter((x) => x !== item))
				},
			},
		],
	}

	const { data: appFiles = { items: [] }, isFetching } = usePaginatedData<AppFileModel>('/AppFiles', {
		pageSize: 100,
	})

	const [imports, setImports] = useState<AppFileModel[]>([])

	const importSchema: TableSchema<AppFileModel> = {
		cols: [
			{
				title: 'Name',
				value: (x, i) => (
					<label className="flex items-center space-x-4">
						<input
							id={`checkbox-${i}`}
							type="checkbox"
							className="form-checkbox text-primary-600 cursor-pointer"
							checked={!!imports.find((y) => y === x)}
							disabled={isSaving}
							onChange={() => {
								const index = imports.findIndex((y) => y === x)

								if (index > -1) {
									setImports((i) => i.filter((y) => y !== x))
								} else {
									setImports((i) => [...i, x])
								}
							}}
						/>
						<div className="font-medium cursor-pointer">{x.name}</div>
					</label>
				),
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.fileSize),
			},
			{
				title: 'Uploaded',
				value: (x) => format(new Date(x.created), 'dd/MM/yyyy'),
			},
		],
	}

	const { isSaving, setSaving } = useModalState()

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			const { data: caseData } = await api.post('/Cases', {
				name: `${formData.accClaimNumber} - ${formData.applicantName}`,
				caseType: CASE_TYPE.Review,
				applicationId: application?.id,
				organization: application ? { id: FENZOrgId } : undefined,
				process: application?.process,
				fields: [
					createField('accClaimNumber', formData.accClaimNumber || ''),
					createField(
						'applicantName',
						application ? `${application?.firstName} ${application?.lastName}` : formData.applicantName
					),
					createField('careManagement'),
					createField('careManagementPlan'),
					createField('specialInstructions'),
					createField('triageNotes'),
					createField('careIndication'),
					createField('issueCode'),
					createField('zoom'),
				],
				folders: [
					'ACC SUBMISSIONS/EVIDENCE',
					'APPLICANT SUBMISSIONS/EVIDENCE',
					'REVIEWER NOTES',
					'REVIEWER DRAFT DOCUMENTS',
					'DECISION/OUTCOME NOTIFICATION',
					'ADR/MEDIATION',
					'MINUTES',
					'RECORDINGS',
					'INVOICES',
					'SET DOWN NOTICES',
					'CORRESPONDENCE',
				],
			})

			const folders = caseData.folders
			const accSubmissions: FolderType | undefined = folders.find(
				(x: FolderType) => x.name === 'ACC SUBMISSIONS/EVIDENCE'
			)

			if (!accSubmissions) {
				throw new Error('The ACC SUBMISSIONS/EVIDENCE folder was not created for this case')
			}

			if (imports.length) {
				await series(imports, async (file: AppFileModel) => {
					await api.post('/CaseFiles/attach', {
						appFileId: file.id,
						parentId: accSubmissions.id,
						permissions,
						case: {
							id: caseData.id,
						},
					})
				})
			}

			if (uploads.length) {
				const body = new FormData()

				body.append('case.id', caseData.id)
				body.append('parentId', accSubmissions.id)

				let hadUnacceptable = 0

				for (let i = 0; i < uploads.length; i++) {
					let item = uploads[i]

					if (!isAcceptableFile(item)) {
						hadUnacceptable++
						continue
					}

					body.append('files', item)

					permissions.forEach((permission) => {
						body.append(`permissions[${i}]`, String(permission))
					})
				}

				if (hadUnacceptable) {
					warning({
						title: 'Invalid Files',
						message: 'At least one file with an invalid type was skipped',
					})
					if (uploads.length === hadUnacceptable) {
						setSaving(false)
						return
					}
				}

				await api.post('/CaseFiles', body)
			}

			// let newHearing = await openBooking({ caseData })

			// if (!newHearing) toast({ title: 'Case created' })
			toast({ title: 'Case created' })

			close()
			if (refetch) refetch()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6">
						<div className="w-full sm:w-1/2">
							<FormInput name="accClaimNumber" label="ACC Claim Number" autoFocus />
						</div>

						<div className="w-full sm:w-1/2">
							<FormInput
								name="applicantName"
								label="Applicant Name"
								validations={{ required: 'Applicant Name is required' }}
							/>
						</div>
					</div>

					<Tabs
						activeIndex={activeTab}
						theme="light"
						items={[
							{
								simpleTitle: `Import Files ${imports.length ? `[${imports.length}]` : ''}`,
								title: (
									<div className="flex items-center space-x-2">
										<div>Import Files</div>
										{imports.length > 0 && (
											<div className="bg-primary-400 text-primary-800 dark:bg-primary-500 dark:text-primary-300 bg-opacity-25 dark:bg-opacity-25 rounded px-1.5 py-0.5 -my-0.5 flex items-center justify-center text-sm font-medium">
												{imports.length}
											</div>
										)}
									</div>
								),
								onClick: () => setActiveTab(0),
							},
							{
								simpleTitle: `Upload Files ${uploads.length ? `[${uploads.length}]` : ''}`,
								title: (
									<div className="flex items-center space-x-2">
										<div>Upload Files</div>
										{uploads.length > 0 && (
											<div className="bg-primary-400 text-primary-800 dark:bg-primary-500 dark:text-primary-300 bg-opacity-25 dark:bg-opacity-25 rounded px-1.5 py-0.5 -my-0.5 flex items-center justify-center text-sm font-medium">
												{uploads.length}
											</div>
										)}
									</div>
								),
								onClick: () => setActiveTab(1),
							},
						]}
					/>

					{activeTab === 0 && (
						<div>
							<AutoHeight show={appFiles?.items?.length > 0}>
								<TableFrame>
									<Table schema={importSchema} items={appFiles?.items} className="-my-px" />
								</TableFrame>
							</AutoHeight>

							{appFiles?.items?.length === 0 && !isFetching && (
								<p className="text-primary-600 text-sm font-semibold px-4 py-3 rounded-md bg-primary-100 dark:bg-primary-500 dark:text-primary-300 dark:bg-opacity-25">
									No files to import
								</p>
							)}
						</div>
					)}

					{activeTab === 1 && (
						<Stack>
							<FileDropper onChange={(files) => setUploads((f) => [...f, ...files])} />
							{uploads.length > 0 && (
								<TableFrame>
									<Table schema={uploadSchema} items={uploads} className="-my-px" />
								</TableFrame>
							)}
						</Stack>
					)}
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default useCreateCase
