export interface TimeSlot {
	from: string
	to: string
	from24: string
	to24: string
}

export const TimeSlots: TimeSlot[] = [
	{ from: '9:00 am', to: '9:30 am', from24: '09:00:00', to24: '9:30:00' },
	{ from: '9:30 am', to: '10:00 am', from24: '09:30:00', to24: '10:00:00' },
	{ from: '10:00 am', to: '10:30 am', from24: '10:00:00', to24: '10:30:00' },
	{ from: '10:30 am', to: '11:00 am', from24: '10:30:00', to24: '11:00:00' },
	{ from: '11:00 am', to: '11:30 am', from24: '11:00:00', to24: '11:30:00' },
	{ from: '11:30 am', to: '12:00 pm', from24: '11:30:00', to24: '12:00:00' },
	{ from: '12:00 pm', to: '12:30 pm', from24: '12:00:00', to24: '12:30:00' },
	{ from: '12:30 pm', to: '1:00 pm', from24: '12:30:00', to24: '13:00:00' },
	{ from: '1:00 pm', to: '1:30 pm', from24: '13:00:00', to24: '13:30:00' },
	{ from: '1:30 pm', to: '2:00 pm', from24: '13:30:00', to24: '14:00:00' },
	{ from: '2:00 pm', to: '2:30 pm', from24: '14:00:00', to24: '14:30:00' },
	{ from: '2:30 pm', to: '3:00 pm', from24: '14:30:00', to24: '15:00:00' },
	{ from: '3:00 pm', to: '3:30 pm', from24: '15:00:00', to24: '15:30:00' },
	{ from: '3:30 pm', to: '4:00 pm', from24: '15:30:00', to24: '16:00:00' },
	{ from: '4:00 pm', to: '4:30 pm', from24: '16:00:00', to24: '16:30:00' },
	{ from: '4:30 pm', to: '5:00 pm', from24: '16:30:00', to24: '17:00:00' },
]
