import React, { FC } from 'react'
import { ModalContent, ModalFooter, openModal, useModalState } from 'hooks/useModal'
import { useForm } from 'react-hook-form'
import api from 'api'
import { toast } from 'components/toast'
import { CheckIcon } from '@heroicons/react/outline'

// components
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import Actions from 'components/Actions'

interface OpenCreateVenueProps {
	refetch: () => Promise<unknown>
}

const openCreateVenue = ({ refetch }: OpenCreateVenueProps) => {
	return openModal({
		title: 'Create Venue',
		render: (close) => <CreateVenue refetch={refetch} close={close} />,
	})
}

interface CreateVenueFormProps {
	refetch: () => Promise<unknown>
	close: (answer?: boolean) => void
}

interface FormData {
	name: string
	address: string
	phoneNumber: string
}

const CreateVenue: FC<CreateVenueFormProps> = ({ refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			name: '',
			address: '',
			phoneNumber: '',
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.post('/Venues', formData)
			await refetch()
			close()
			toast({ title: 'Venue Created' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput name="name" label="Name" validations={{ required: 'Name is required' }} autoFocus />
					<FormInput name="address" label="Address" validations={{ required: 'Address is required' }} />
					<FormInput
						name="phoneNumber"
						label="Phone number"
						validations={{ required: 'Phone number is required' }}
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openCreateVenue
