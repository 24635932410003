import { FC, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import { TabType, isLinkTabType, isButtonTabType, None, isNotNone } from 'types'
import Badge from './Badge'
import clsx, { ClassValue } from 'clsx'
import { navigate } from './NavigateHoist'

const themes = {
	dark: {
		border: 'border-b border-gray-600',
		tab: '',
		tabInactive:
			'whitespace-nowrap py-2 border-b border-transparent font-medium text-sm leading-5 text-white hover:text-gray-100 hover:border-gray-500 focus:outline-none focus:text-gray-100 focus:border-gray-300 antialiased',
		tabActive:
			'whitespace-nowrap py-2 border-b border-primary-500 font-medium text-sm leading-5 text-primary-300 focus:outline-none focus:text-primary-600 focus:border-primary-400 antialiased',
	},
	light: {
		border: 'border-b border-gray-300 dark:border-gray-600 font-medium text-sm leading-5',
		tab: 'whitespace-nowrap py-4 px-4 border-b -mb-px',
		tabInactive:
			'border-transparent text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300',
		tabActive:
			'border-primary-600 text-primary-600 dark:border-white dark:text-white focus:outline-none focus:text-primary-800 dark:focus:text-primary-400 focus:border-primary-700',
	},
	card: {
		border: '',
		tab: 'whitespace-nowrap px-4 py-3 dark:bg-gray-700 rounded-tl-md rounded-tr-md px-4 mr-2 font-medium text-sm leading-5 shadow',
		tabInactive:
			'bg-gray-100 dark:bg-gray-750 hover:bg-white transition transition-background text-gray-500 dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 relative z-10',
		tabActive:
			'bg-white text-black dark:text-white focus:outline-none focus:text-primary-800 focus:border-primary-700 relative z-20',
	},
	buttons: {
		border: '',
		tab: 'whitespace-nowrap px-4 py-2 rounded-md mr-2 font-medium text-sm leading-5',
		tabInactive:
			'bg-gray-200 hover:bg-white transition transition-background text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300',
		tabActive:
			'bg-gray-700 text-white dark:text-white focus:outline-none focus:text-primary-800 focus:border-primary-700',
	},
	links: {
		border: '',
		tab: 'whitespace-nowrap px-4 py-2 rounded-md mr-2 font-medium text-sm leading-5',
		tabInactive:
			'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none focus:text-gray-700',
		tabActive: 'text-primary-600 dark:text-primary-300 focus:outline-none focus:text-primary-800',
	},
}

interface TabsProps {
	items: (TabType | None)[]
	activeIndex?: number | string
	theme?: 'light' | 'dark' | 'card' | 'buttons' | 'links'
	getActiveIndex?: (x: TabType[]) => number
	className?: ClassValue
}

const Tabs: FC<TabsProps> = ({ items, theme = 'dark', activeIndex, getActiveIndex, className }) => {
	let tabs = items.filter(isNotNone)
	let index = getActiveIndex !== undefined ? getActiveIndex(tabs) : activeIndex || 0

	const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
		let index = +e.target.value
		let item = tabs[index]

		if (isLinkTabType(item)) {
			navigate(item.to, { state: item.state, replace: item.replace })
		} else if (isButtonTabType(item)) {
			item.onClick(item, index)
		}
	}

	return (
		<div className={clsx(className)}>
			<div className="sm:hidden">
				<select
					className="form-input block w-full dark:bg-gray-800 dark:placeholder-gray-400 dark:border-gray-600"
					value={index}
					onChange={handleSelect}
				>
					{tabs.map((item, i) => (
						<option key={i} value={i}>
							{item.simpleTitle || item.title}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block py-2">
				<div className={themes[theme].border}>
					<div className="flex items-end overflow-x-auto px-2 -m-2 overflow-y-hidden -mb-2">
						{tabs.map((item, i) =>
							isLinkTabType(item) ? (
								<Link key={i} to={item.to} state={item.state} replace={item.replace}>
									<div
										className={clsx(
											'cursor-pointer',
											themes[theme].tab,
											i === index ? themes[theme].tabActive : themes[theme].tabInactive
										)}
									>
										{item.title}
										{item.errors && item.errors > 0 && (
											<Badge color="red" className="ml-2">
												{item.errors}
											</Badge>
										)}
									</div>
								</Link>
							) : isButtonTabType(item) ? (
								<div
									key={i}
									className={clsx(
										'cursor-pointer',
										themes[theme].tab,
										(item.key !== undefined ? item.key : i) === index
											? themes[theme].tabActive
											: themes[theme].tabInactive
									)}
									onClick={() => item.onClick(item, i)}
								>
									{item.title}
									{item.errors && item.errors > 0 && (
										<Badge color="red" className="ml-2">
											{item.errors}
										</Badge>
									)}
								</div>
							) : null
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Tabs
