export enum HEARING_STATUS {
	Pending = 0,
	Active = 1,
}

export const hearingStatus = {
	0: 'Pending',
	1: 'Active',
}

export const HearingStatus = {
	readable: (x: HEARING_STATUS) => hearingStatus[x],
	options: Object.entries(hearingStatus).map(([key, val]) => ({ label: val, value: +key })),
}
