import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import openCreateUser from 'modals/openCreateUser'
import openEditUser from 'modals/openEditUser'
import Card from 'components/Card'
import { Auth } from 'Auth'
import api from 'api'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { DotsHorizontalIcon, PencilIcon, MailIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import { confirm, toast } from 'alerts'
import { TableSchema } from 'components/Table'
import SearchForm from 'search/UsersSearch'
import UserDefault from 'components/UserDefault'
import { gql } from '@apollo/client'
import { GQLConnection, GQLUserType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { ACCOrgId } from 'utils/constants'
import { USER_ROLE } from 'types/enums'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: UserOrderBy!, $search: UserSearch) {
		Users(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				fullName
				email
				emailConfirmed
				phoneNumber
				avatar
				disabled
				roles {
					id
					readable
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const Users: FC = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const { data, loading, refetch } = useBufferedQuery<{ Users: GQLConnection<GQLUserType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: state?.search || null,
		},
	})

	const schema: TableSchema<GQLUserType> = {
		cols: [
			{
				title: '',
				width: 'minmax(auto, max-content)',
				value: (x) =>
					x.avatar ? (
						<img
							src={`data:text/jpeg;base64,${x.avatar}`}
							className="overflow-hidden rounded-full w-9 h-9 object-cover shadow-sm"
							alt={x.fullName}
						/>
					) : (
						<UserDefault name={x.fullName} className="w-9 h-9" />
					),
				className: 'pr-0',
				visibility: 'screen-only',
			},
			{
				title: 'Name',
				width: 'auto',
				value: (x) => (
					<div className="flex flex-col items-start">
						<Link to={`${x.id}`} className="anchor">
							{x.fullName} {x.disabled && '(deactivated)'}
						</Link>
						<div>{x.email}</div>
					</div>
				),
				copyValue: (x) => `${x.fullName}${x.disabled ? ' (deactivated)' : ''}`,
			},
			{
				title: 'Email',
				value: (x) => x.email,
				visibility: 'copy-only',
			},
			{
				title: 'Phone',
				width: 'minmax(0, auto)',
				value: (x) => x.phoneNumber,
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Roles',
				width: 'minmax(0, auto)',
				value: (x) => x.roles?.map((role) => role.readable).join(', '),
				truncate: true,
				responsive: 'lg',
			},
		],
		actions: (x) =>
			Auth.is('Admin', 'CaseManager', 'FENZAdmin') && [
				{
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						[
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
								title: 'Edit User',
								icon: <PencilIcon className="w-5 h-5" />,
								onClick: () => openEditUser({ userId: x.id, refetch }),
							},
							{
								title: x.emailConfirmed ? 'Send Reset Password' : 'Resend Verification Email',
								icon: <MailIcon className="w-5 h-5" />,
								onClick: () => sendEmail(x),
							},
						],
						Auth.is('Admin', 'FENZAdmin') && [
							{
								title: 'Delete User',
								intent: 'danger',
								icon: <TrashIcon className="w-5 h-5" />,
								onClick: () => handleDelete(x),
							},
						],
					],
				},
			],
	}

	const sendEmail = (user: GQLUserType) =>
		confirm({
			title: user.emailConfirmed
				? 'Are you sure you want to send a password reset email?'
				: 'Are you sure you want to send a verification email?',
			intent: 'primary',
			onAccept: async () => {
				try {
					if (user.emailConfirmed) {
						await api.post('/Accounts/forgotPassword', { email: user.email })
					} else {
						await api.post(`/Users/verificationemail?userId=${user.id}`, {})
					}

					toast({ title: 'Email sent' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})

	const handleDelete = (user: GQLUserType) =>
		confirm({
			title: `Are you sure you want to delete ${user.fullName}`,
			onAccept: async () => {
				try {
					await api.delete(`/Users/${user.id}`)
					refetch()
					toast({ title: 'User deleted' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})

	return (
		<>
			<PageHeading
				title="Users"
				actions={
					Auth.is('Admin', 'CaseManager', 'FENZAdmin')
						? [
								{
									title: 'Create User',
									intent: 'primary',
									rounded: 'md',
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => openCreateUser({ refetch }),
								},
						  ]
						: undefined
				}
			/>

			<PageContent>
				<Card>
					<GQLPaginatedTable
						schema={schema}
						set={data?.Users}
						isLoading={loading}
						refetch={refetch}
						getKey={(x) => x.id}
						search={(props) => {
							return (
								<SearchForm
									{...props}
									presetValues={
										Auth.is('ACCAdmin') ||
										(Auth.is('Respondent') && Auth.profile()?.organization?.id === ACCOrgId)
											? {
													organization: { value: '', hidden: true },
													role: {
														value: null,
														filter: (x) =>
															x.value === USER_ROLE.ACCAdmin ||
															x.value === USER_ROLE.Respondent,
													},
											  }
											: {}
									}
								/>
							)
						}}
					/>
				</Card>
			</PageContent>
		</>
	)
}

export default Users
