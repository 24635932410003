import { TimeSlots } from 'types/enums'
import { format, parseISO, differenceInMinutes } from 'date-fns'
import { nzDate } from 'utils/funcs'

export const newParty = (user, roleId) => {
	return {
		role: roleId,
		user,
		parties: [],
	}
}

export const newField = (name, obj, value, bool) => {
	return {
		[name]: obj
			? {
					...obj,
					value: bool ? obj.value === 'true' : obj.value || value,
			  }
			: {
					name,
					title: name,
					label: name,
					value,
			  },
	}
}

export const getPartiesAsBools = (field, hearing, caseData) => {
	if (!caseData[field]) return []

	if (!hearing.attendees || !hearing.attendees.length) {
		return caseData[field].parties.map(() => false)
	}

	return caseData[field].parties.map((party) => !!hearing.attendees.find((x) => x.id === party.id))
}

export const getPartyUserAsBool = (field, hearing, caseData) => {
	return !!(hearing.attendees && hearing.attendees.find((x) => caseData[field] && x.id === caseData[field].user.id))
}

export const newHearing = (caseData, data = {}) => {
	let hearing = {
		id: data.id || undefined,
		name: data.name || '',
		description: data.description || 'description',
		hearingType: +data.hearingType || 1,
		forumType: data.forumType || null,
		venue: data.venue || '',
		venueId: data?.venue && !data?.venueId ? 'custom' : data?.venueId || '',
		startDate: data.startDate ? new Date(data.startDate) : null,
		time: data.startDate
			? TimeSlots.find((x) => x.from === format(nzDate(parseISO(data.startDate)), 'h:mm a').toLowerCase())?.from
			: null,
		duration: data.endDate ? differenceInMinutes(parseISO(data.endDate), parseISO(data.startDate)) || null : null,
		setByDate: data.setByDate || null,
		zoomMeeting: data.zoomMeeting || '',
		accessibility: data.accessibility || '',
		interpreterRequired: data.interpreterRequired || false,
		cultural: data.cultural || '',
		security: data.security || '',
		other: data.other || '',
		roomBooked: data.roomBooked || false,
		travelBooked: data.travelBooked || false,
		roomHireDetails: data.roomHireDetails || '',
		travelArrangements: data.travelArrangements || '',
		agreeToMediateApplicant: data.agreeToMediateApplicant || false,
		agreeToMediateDefendant: data.agreeToMediateDefendant || false,
		caseHearingStatus: data.caseHearingStatus || 0,
		notificationsEnabled: true,
		attendees: {
			applicant: {
				user: getPartyUserAsBool('applicant', data, caseData),
				parties: getPartiesAsBools('applicant', data, caseData),
			},
			mediator: {
				user: getPartyUserAsBool('mediator', data, caseData),
				parties: getPartiesAsBools('mediator', data, caseData),
			},
			reviewer: {
				user: getPartyUserAsBool('reviewer', data, caseData),
				parties: getPartiesAsBools('reviewer', data, caseData),
			},
			peerReviewer: {
				user: getPartyUserAsBool('peerReviewer', data, caseData),
				parties: getPartiesAsBools('peerReviewer', data, caseData),
			},
			caseManager: {
				user: getPartyUserAsBool('caseManager', data, caseData),
				parties: [],
			},
			respondent: {
				user: getPartyUserAsBool('respondent', data, caseData),
				parties: getPartiesAsBools('respondent', data, caseData),
			},
		},
		reviews: data.reviews
			? data.reviews.map((x) => x.id).reduce((obj, id) => ({ ...obj, [id]: true }), {})
			: caseData.reviews
			? caseData.reviews.map((x) => x.id).reduce((obj, id) => ({ ...obj, [id]: true }), {})
			: [],
	}

	return hearing
}
