import { Children, FC } from 'react'
import { motion } from 'framer-motion'
import clsx, { ClassValue } from 'clsx'

interface SliderProps {
	index: number
	className?: ClassValue
}

const Slider: FC<SliderProps> = ({ index, className, children }) => {
	return (
		<div className={clsx('flex flex-col overflow-hidden w-full', className)}>
			<motion.div
				className="flex flex-row min-h-[0]"
				style={{ direction: 'ltr', willChange: 'transform', flex: 1 }}
				transition={{
					tension: 190,
					friction: 70,
					mass: 0.4,
				}}
				initial={false}
				animate={{ x: index * -100 + '%' }}
			>
				{Children.map(children, (child, i) => (
					<div
						className="flex flex-col w-full self-stretch justify-start shrink-0 h-[100%] overflow-hidden outline-none"
						key={i}
						aria-hidden={index !== i}
						// tabIndex={index === i ? 0 : -1} // what the fuck???
					>
						{child}
					</div>
				))}
			</motion.div>
		</div>
	)
}

export default Slider
