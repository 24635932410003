import { useState, useEffect, useCallback } from 'react'
import useClickOutside from 'react-cool-onclickoutside'

interface DismissableOptions {
	onOpen?: () => void
	onClose?: () => void
}

const useDismissable = (options: DismissableOptions = {}) => {
	const [open, setOpen] = useState(false)

	const [ignoreClass] = useState(`ignore-dismissable-${Math.floor(Math.random() * 999999)}`)

	const close = useCallback(() => {
		options.onClose && options.onClose()
		setOpen(false)
	}, [options])

	const [prevState, setPrevState] = useState(open)
	useEffect(() => {
		if (open !== prevState) {
			options.onOpen && open && options.onOpen()
			setPrevState(open)
		}
	}, [open, options, prevState])

	useEffect(() => {
		const handle = (e: KeyboardEvent) => e.key === 'Escape' && setOpen(false)

		if (open) {
			window.addEventListener('keyup', handle)
		} else {
			window.removeEventListener('keyup', handle)
		}

		return () => window.removeEventListener('keyup', handle)
	}, [open, close])

	const ref = useClickOutside(
		(e) => {
			setOpen(false)
		},
		{ ignoreClass }
	)

	return { open, setOpen, ignoreClass, ref }
}

export default useDismissable
