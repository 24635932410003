import { FC, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import useData from 'hooks/useData'
import { getFullName } from 'utils/funcs'
import CaseStatusBadge from 'components/CaseStatusBadge'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/FormSelect'
import api from 'api'
import { CheckIcon, XIcon, UserIcon, TagIcon } from '@heroicons/react/outline'
import Stack from 'components/Stack'
import Card from 'components/Card'
import CardHeader from 'components/CardHeader'
import { confirm } from 'alerts'
import { toast } from 'components/toast'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Badge from 'components/Badge'
import { CASE_PARTY_ROLE, CASE_PROCESS, CASE_STATUS, USER_ROLE } from 'types/enums'
import { CaseModel, UserModel } from 'types/models'
import { useForm } from 'react-hook-form'
import { TableSchema } from 'components/Table'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { GQLCaseType, GQLConnection } from 'types/gql'
import { gql } from '@apollo/client'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { PaginatedSet } from 'types'
import queryClient from 'utils/QueryClient'
import { FENZOrgId } from 'utils/constants'
import openApplication from 'modals/openApplication'
import { navigate } from 'components/NavigateHoist'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: CaseOrderBy!, $search: CaseSearch) {
		Cases(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				caseNumber
				applicantName
				accClaimNumber
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				applicant {
					id
					user {
						id
						fullName
					}
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

interface FormData {
	user: UserModel
}

// interface CaseRequestProps {
// 	caseId?: string
// }

const CaseRequest: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData } = useData<CaseModel>(`/Cases/${caseId}`, {}, { suspense: true })

	if (caseData?.status !== CASE_STATUS.Request) {
		if (caseData?.status === CASE_STATUS.Pending) {
			navigate(`/cases/${caseId}/pending`)
		} else {
			navigate(`/cases/${caseId}`)
		}
	}

	const handleDecline = async () => {
		await confirm({
			title: 'Decline case request?',
			onAccept: async () => {
				if (caseData?.organization?.id === FENZOrgId) {
					try {
						await api.put(`/Cases/${caseData?.id}`, {
							...caseData,
							rejected: true,
							status: 2,
							process: CASE_PROCESS.Adjudication,
						})

						await toast({ title: 'Case Declined' })

						navigate('/cases')
					} catch (error) {
						api.handleError(error)
					}
				} else {
					try {
						await api.delete(`/Cases/${caseData?.id}/Decline`)

						await toast({ title: 'Case Declined' })

						navigate('/cases')
					} catch (error) {
						api.handleError(error)
					}
				}
			},
		})
	}

	const [isSaving, setSaving] = useState(false)

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.post(`/Cases/${caseData?.id}/party`, {
				role: CASE_PARTY_ROLE.CaseManager,
				userid: formData.user.id,
			})

			let { data: cachedItem } = await api.get<CaseModel>(`/Cases/${caseData?.id}`)

			await api.put(`/Cases/${caseData?.id}`, {
				...cachedItem,
				status: 2,
			})

			queryClient.removeQueries([`/Cases/${caseData?.id}`, {}])

			toast({ title: 'Case Accepted!' })

			navigate('/cases')
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const formContext = useForm({
		defaultValues: {
			user: caseData?.caseManager ? caseData?.caseManager.user : null,
		},
	})

	const { data: userList } = useData<PaginatedSet<UserModel>>('/Users', {
		pageSize: 100,
		search: { roles: [USER_ROLE.CaseManager] },
	})

	const applicantName = caseData?.fields?.find((x) => x.name === 'applicantName')?.value
	const accClaimNumber = caseData?.fields?.find((x) => x.name === 'accClaimNumber')?.value

	const location = useLocation()

	const state = location?.state as { page?: number; pageSize?: number } | null

	const {
		data: gqlData,
		loading,
		refetch,
	} = useBufferedQuery<{ Cases: GQLConnection<GQLCaseType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 5,
			search: {
				OR: {
					accClaimNumber: accClaimNumber,
					applicantName: applicantName,
				},
				excludeIds: [caseId],
			},
		},
	})

	const schema: TableSchema<GQLCaseType> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`/cases/${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Claim #',
				value: (x) => x.accClaimNumber || '-',
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) => x.applicantName || '-',
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
				responsive: 'lg',
			},
			{
				title: 'Status',
				value: (x) => <Badge status={x.status?.id}>{x.status?.readable}</Badge>,
				responsive: 'lg',
				width: 'minmax(auto, max-content)',
			},
		],
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit} className="flex-1">
			<PageHeading
				pageTitle={`Request: ${caseData?.caseNumber}`}
				title={caseData?.caseNumber}
				infoList={[
					{
						title: <CaseStatusBadge status={caseData?.status} size="sm" />,
					},
					{
						title: caseData?.fields?.find((x) => x.name === 'applicantName')?.value || '-',
						icon: <UserIcon className="w-5 h-5" />,
					},
					{
						title: caseData?.fields?.find((x) => x.name === 'accClaimNumber')?.value || '-',
						icon: <TagIcon className="w-5 h-5" />,
					},
				]}
				actions={[
					caseData?.organization?.id === FENZOrgId &&
						caseData?.application?.id && {
							title: 'View Application',
							intent: 'secondary',
							onClick: async () => {
								if (caseData?.application?.id) {
									openApplication({ applicationId: caseData.application.id, canCreate: false })
								}
							},
						},
					{
						title: 'Decline request',
						intent: 'danger',
						icon: <XIcon className="w-5 h-5" />,
						onClick: handleDecline,
						disabled: isSaving,
					},
					{
						title: 'Accept and create case',
						intent: 'primary',
						icon: <CheckIcon className="w-5 h-5" />,
						type: 'submit',
						isLoading: isSaving,
					},
				]}
			/>

			<PageContent>
				<Stack>
					<Card>
						<div className="flex">
							<div className="flex flex-col flex-1 mr-0 md:mr-8 md:mb-2">
								<FormSelect
									name="user"
									options={userList?.items.map((x) => ({ label: getFullName(x), value: x })) || []}
									placeholder="Select a Case Manager..."
									className="flex-1 mt-1"
									validations={{
										required: 'Case Manager is required',
									}}
								/>
							</div>

							<div className="flex flex-col mr-0 md:mr-8 md:mb-2">
								<div className="text-sm leading-5 text-gray-400 dark:text-gray-300">
									ACC Claim Number
								</div>
								<div className="mt-1 text-sm leading-5 text-gray-900 dark:text-white">
									{accClaimNumber || '-'}
								</div>
							</div>

							<div className="flex flex-col flex-1">
								<div className="text-sm leading-5 text-gray-400 dark:text-gray-300">Applicant Name</div>
								<div className="mt-1 text-sm leading-5 text-gray-900 dark:text-white">
									{applicantName || '-'}
								</div>
							</div>
						</div>
					</Card>

					<Card header={<CardHeader title="Possible Duplicate Cases" />}>
						<GQLPaginatedTable schema={schema} set={gqlData?.Cases} isLoading={loading} refetch={refetch} />
					</Card>
				</Stack>
			</PageContent>
		</Form>
	)
}

export default CaseRequest
