import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { format } from 'date-fns'

export interface TimelineItem {
	title: ReactNode
	date?: Date
	icon: ReactNode
	iconBackground?: ClassValue
}

interface TimelineProps {
	items: TimelineItem[]
}

const Timeline: FC<TimelineProps> = ({ items }) => {
	return (
		<div className="flow-root">
			<ul className="-mb-8">
				{items.map((item, i) => (
					<li key={i}>
						<div className="relative pb-8">
							{i !== items.length - 1 ? (
								<span
									className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
									aria-hidden="true"
								/>
							) : null}
							<div className="relative flex space-x-3">
								<div>
									<span
										className={clsx(
											item.iconBackground || 'bg-gray-400',
											'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-gray-700'
										)}
									>
										{item.icon}
									</span>
								</div>
								<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
									<div>
										<div className="text-sm text-gray-500 dark:text-gray-300">{item.title}</div>
									</div>
									{item.date && (
										<div className="text-right text-sm whitespace-nowrap text-gray-500 dark:text-gray-300">
											{format(item.date, 'dd/MM/yyyy HH:mm:ss')}
										</div>
									)}
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	)
}

export default Timeline
