export enum WEEK_DAY {
	Sunday = 1,
	Monday = 2,
	Tuesday = 4,
	Wednesday = 8,
	Thursday = 16,
	Friday = 32,
	Saturday = 64,
}

const weekDay = {
	1: 'Sunday',
	2: 'Monday',
	4: 'Tuesday',
	8: 'Wednesday',
	16: 'Thursday',
	32: 'Friday',
	64: 'Saturday',
}

export const WeekDay = {
	options: Object.entries(weekDay).map(([key, val]) => ({ label: val, value: +key })),
}
