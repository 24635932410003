import { FC } from 'react'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { EyeIcon, PlusIcon } from '@heroicons/react/outline'
import { ModalResolveFn, RefetchFn } from 'types'
import useData from 'hooks/useData'
import { ApplicationFileModel, ApplicationModel } from 'types/models'
import DataListColumns from 'components/DataListColumns'
import { format } from 'date-fns'
import { CaseProcess, CASE_PARTY_ROLE, CASE_PROCESS } from 'types/enums'
import FormSection from 'components/Form/FormSection'
import TableFrame from 'components/TableFrame'
import Table, { TableSchema } from 'components/Table'
import openFileView from './openFileView'
import { FENZOrgId } from 'utils/constants'
import openApplicationCM from './openApplicationCM'
import { toast } from 'components/toast'

const createField = (name: string, value: string | null = null) => ({
	name,
	value,
	label: name,
	title: name,
})

interface OpenApplicationProps {
	applicationId: string
	canCreate?: boolean
	refetch?: RefetchFn
}

const openApplication = (props: OpenApplicationProps) => {
	return openModal({
		title: 'View Application',
		size: 'lg',
		render: (close) => <TwoFactor close={close} {...props} />,
	})
}

const TwoFactor: FC<OpenApplicationProps & { close: ModalResolveFn }> = ({
	close,
	applicationId,
	canCreate = true,
	refetch,
}) => {
	const { isSaving, setSaving } = useModalState()

	const { data } = useData<ApplicationModel>(`/Applications/${applicationId}`, undefined, { suspense: true })

	const fileSchema: TableSchema<ApplicationFileModel> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.name,
				truncate: true,
			},
			{
				title: 'Category',
				value: (x) => x.category,
				truncate: true,
			},
		],
		actions: (x) => [
			{
				icon: <EyeIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => openFileView({ file: x, endpoint: 'Applications/files' }),
			},
		],
	}

	const handleSubmit = async (formData: FormData) => {
		let user = await openApplicationCM()

		if (!user) return null

		setSaving(true)

		try {
			let { data: newCase } = await api.post('/Cases', {
				name: 'FENZ Case',
				caseType: 1,
				applicationId: data?.id,
				organization: { id: FENZOrgId },
				process: data?.process,
				status: 2,
				fields: [
					createField('accClaimNumber'),
					createField('applicantName', `${data?.firstName} ${data?.lastName}`),
					createField('careManagement'),
					createField('careManagementPlan'),
					createField('specialInstructions'),
					createField('triageNotes'),
					createField('careIndication'),
					createField('issueCode'),
					createField('zoom'),
				],
				folders: [
					'CORRESPONDENCE',
					'SUBMISSIONS AND EVIDENCE',
					'PRACTITIONER NOTES',
					'DRAFT DOCUMENTS',
					'MINUTES',
					'DECISIONS/AGREEMENTS',
					'RECORDINGS',
					'INVOICES',
				],
			})

			await api.post(`/Cases/${newCase.id}/party`, {
				role: CASE_PARTY_ROLE.CaseManager,
				userid: user.id,
			})

			toast({ title: 'Case created' })

			close()
			if (refetch) refetch()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<>
			<ModalContent>
				<Stack dividers>
					<FormSection title="Details">
						<DataListColumns
							items={[
								{ title: 'Name', value: `${data?.firstName} ${data?.lastName}` },
								{ title: 'Phone', value: data?.phone },
								{ title: 'Email', value: data?.email },
								{
									title: 'Created',
									value: data?.created ? format(new Date(data?.created), 'dd/MM/yyyy') : '-',
								},
								{
									title: 'Process',
									value: data?.process ? CaseProcess.readable(data?.process) : '-',
								},
								{ title: 'Authority nature', value: data?.authorityNature },
								{ title: 'Dispute description', value: data?.disputeDescription },
								data?.process === CASE_PROCESS.FastTrackAdjudication && {
									title: 'Fast-track reason',
									value: data?.fastTrackReason,
								},
								{
									title: 'Has attempted to resolve',
									value: data?.hasAttemptedToResolve ? 'Yes' : 'No',
								},
								!data?.hasAttemptedToResolve && {
									title: 'No attempt to resolve reason',
									value: data?.noAttemptToResolveReason,
								},
								{
									title: 'Is on behalf of applicant',
									value: data?.isOnBehalfOfApplicant ? 'Yes' : 'No',
								},
								{
									title: 'Outcomes Sought',
									value: data?.outcomesSought || '-',
								},
								{
									title: 'Additional Information',
									value: data?.additionalInformation || '-',
								},

								// applicant
								// respondent
								// files
							]}
						/>
					</FormSection>

					{data?.parties && data?.parties.length > 0 && (
						<FormSection title="Parties">
							<Stack dividers>
								{data.parties.map((party) => (
									<DataListColumns
										key={party.id}
										items={[
											{
												title: 'Relevance',
												value: party.relevance,
											},
											{ title: 'Name', value: `${party.firstName} ${party.lastName}` },
											{ title: 'Phone', value: party.phone },
											{ title: 'Email', value: party.email },
											{ title: 'Location', value: party.location },
											{
												title: 'Capacity',
												value: party.volunteer ? 'Volunteer' : 'Member of the public',
											},
										]}
									/>
								))}
							</Stack>
						</FormSection>
					)}

					{data?.files && data.files.length > 0 && (
						<FormSection title="Attached Documents">
							<TableFrame>
								<Table schema={fileSchema} items={data?.files || []} className="-my-px" />
							</TableFrame>
						</FormSection>
					)}
				</Stack>
			</ModalContent>

			{canCreate && (
				<ModalFooter>
					<div className="flex justify-end">
						<Actions
							actions={[
								{
									title: 'Create Case',
									intent: 'primary',
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: handleSubmit,
									isLoading: isSaving,
								},
							]}
						/>
					</div>
				</ModalFooter>
			)}
		</>
	)
}

export default openApplication
