import { FC } from 'react'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormDate from 'components/Form/FormDate'
import FormSelect from 'components/Form/FormSelect'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { toast, confirm } from 'alerts'
import { CaseModel } from 'types/models'
import { useForm } from 'react-hook-form'
import { ADR_TYPE, CASE_COMPLEXITY, ClaimType } from 'types/enums'
import { addMonths, endOfDay, startOfDay } from 'date-fns'
import { ensureDate, isComplexIssueCode, updateADRType, updateCaseComplexity } from 'utils/funcs'

interface OpenCreateReviewProps {
	caseData: CaseModel
	refetch: () => Promise<unknown>
}

const openCreateReview = ({ caseData, refetch }: OpenCreateReviewProps) => {
	return openModal({
		title: 'Create Review',
		render: (close) => <CreateReviewForm caseData={caseData} refetch={refetch} close={close} />,
	})
}

interface CreateReviewFormProps {
	caseData: CaseModel
	refetch: () => Promise<unknown>
	close: (answer?: boolean) => void
}

interface FormData {
	reviewNumber: string
	issueCode: string
	lodgementDate: string
	accDecisionDate: string
}

const CreateReviewForm: FC<CreateReviewFormProps> = ({ caseData, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			reviewNumber: '',
			issueCode: '',
			lodgementDate: '',
			accDecisionDate: '',
		},
	})

	const handleSubmit = async (formData: FormData) => {
		const warnOverdue = formData.accDecisionDate
			? endOfDay(addMonths(ensureDate(formData.accDecisionDate), 3)).valueOf() <
			  startOfDay(ensureDate(formData.lodgementDate)).valueOf()
			: false

		const warnIssueCode = ClaimType.getId(formData.issueCode) === 17

		if (warnOverdue) {
			const warnConfirmed = await confirm({
				title: 'Late request!',
				message: (
					<Stack>
						<div>The Lodgement Date for this case is not within 3 months after the ACC Decision Date.</div>
						<div>Please proceed only if ACC have accepted this case as a Late Request.</div>
						<div>Do you wish to proceed?</div>
					</Stack>
				),
				intent: 'primary',
			})

			if (!warnConfirmed) return null
		}

		if (warnIssueCode) {
			const issueConfirmed = await confirm({
				title: 'Confirmation',
				message: (
					<Stack>
						<div>Please confirm with ACC if this case is standard or complex.</div>
					</Stack>
				),
				intent: 'primary',
			})

			if (!issueConfirmed) return null
		}

		setSaving(true)

		try {
			await api.post(`/Cases/${caseData.id}/review`, {
				...formData,
				setByDate: addMonths(ensureDate(formData.lodgementDate), 3).toISOString(),
			})

			// update to complex case if there are 2 or more reviews
			// OR if we just created a review to be one of the complex ones
			if (caseData?.reviews.length + 1 >= 2 || isComplexIssueCode(formData.issueCode)) {
				await updateCaseComplexity(caseData, CASE_COMPLEXITY.Complex)
				await updateADRType(caseData, ADR_TYPE.MultiIssue)
			}

			await refetch()

			close()

			toast({ title: 'Review Created' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput
						name="reviewNumber"
						label="Review Number"
						validations={{ required: 'Review Number is required' }}
						autoFocus
					/>

					<FormSelect
						name="issueCode"
						label="Issue Code"
						options={ClaimType.options}
						validations={{ required: 'Issue Code is required' }}
					/>

					<FormDate name="accDecisionDate" label="ACC Decision Date" inputDisabled />

					<FormDate
						name="lodgementDate"
						label="Lodgement Date"
						validations={{ required: 'Lodgement Date is required' }}
						inputDisabled
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openCreateReview
