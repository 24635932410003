import { ChangeEvent, FC } from 'react'
import clsx, { ClassValue } from 'clsx'

interface CheckboxProps {
	checked: boolean
	name?: string
	label?: string
	className?: ClassValue
	disabled?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	color?: string
}

const Checkbox: FC<CheckboxProps> = ({
	checked,
	name,
	label = '',
	className,
	disabled,
	onChange,
	color = 'text-primary-600 dark:text-primary-500',
}) => {
	return (
		<label
			className={clsx(
				'text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 inline-flex items-start cursor-pointer',
				className
			)}
		>
			<input
				type="checkbox"
				name={name}
				id={name}
				checked={checked}
				disabled={disabled}
				onChange={onChange}
				className={clsx('form-checkbox rounded-sm border-gray-300 mt-1', {
					[color]: !disabled,
					'text-gray-400': disabled,
				})}
			/>
			{label && <span className="ml-3 mt-0.5 select-none">{label}</span>}
		</label>
	)
}

export default Checkbox
