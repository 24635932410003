import { FC, useEffect, useState } from 'react'
import PageHeading from 'components/PageHeading'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import Select from 'components/Select'
import DatePicker from 'components/DatePicker'
import { startOfMonth, endOfMonth, format } from 'date-fns'
import PageNavbar from 'components/PageNavbar'
import { links } from 'pages/Admin'
import SectionHeader from 'components/SectionHeader'
import Tabs from 'components/Tabs'
import { Auth } from 'Auth'
import clsx from 'clsx'
import Suspender from 'components/Suspender'
import api from 'api'
import useData from 'hooks/useData'
import { isNotNone, PaginatedSet, PaginationState } from 'types'
import { CaseModel, LineItemModel, OrganizationModel, UserModel } from 'types/models'
import { USER_ROLE } from 'types/enums'
import { downloadCSV, getFullName } from 'utils/funcs'
import { confirm, success } from 'alerts'
import { Link, useLocation } from 'react-router-dom'
import { TableSchema } from 'components/Table'
import usePaginatedData from 'hooks/usePaginatedData'
import PaginatedTable from 'components/PaginatedTable'
import Panel from 'components/Panel'
import Toggle from 'components/Toggle'
import { env } from 'utils/config'
import { navigate } from 'components/NavigateHoist'

const fmt = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ss")

const Billing: FC = () => {
	const location = useLocation()

	const state = location?.state as { tab?: number } | null

	const activeTab = state?.tab || 0

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Billing" />

			<PageNavbar links={links}>
				<Stack space={6}>
					<div className="px-6 flex justify-between items-start">
						<SectionHeader title="Billing" />

						<Tabs
							activeIndex={activeTab}
							theme="light"
							items={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									title: 'Billing Exports',
									onClick: () => navigate(location.pathname, { replace: true, state: { tab: 0 } }),
								},
								{
									title: 'Unapproved Line Items',
									onClick: () => navigate(location.pathname, { replace: true, state: { tab: 1 } }),
								},
							]}
						/>
					</div>

					<div className={clsx(activeTab !== 0 && 'hidden')}>
						<Suspender render={<BillingExports />} />
					</div>

					<div className={clsx(activeTab !== 1 && 'hidden')}>
						<Suspender render={<BillingLineitems />} />
					</div>
				</Stack>
			</PageNavbar>
		</>
	)
}

const BillingExports = () => {
	const [xeroAuthorized, setXeroAuthorized] = useState(false)
	const [xeroLoading, setXeroLoading] = useState(true)

	useEffect(() => {
		const checkAuthorized = async () => {
			const { data } = await api.get('/Xero/authorized')

			if (data) {
				setXeroAuthorized(true)
			}
			setXeroLoading(false)
		}

		checkAuthorized()
	}, [])

	const xeroLogin = async () => {
		const { data } = await api.get(`/Xero/login-uri?callbackUri=${`${window.location.origin}/xero`}`)

		window.open(String(data))
	}

	const [selectedUser, setSelectedUser] = useState<UserModel | null>(null)

	const { data: users } = useData<PaginatedSet<UserModel>>(
		'/Users',
		{
			pageSize: 100,
			search: { roles: [USER_ROLE.Reviewer, USER_ROLE.Mediator] },
		},
		{ suspense: true }
	)

	const [selectedOrg, setSelectedOrg] = useState<OrganizationModel | null>(null)
	const { data: orgs } = useData<PaginatedSet<OrganizationModel>>(
		'/Organizations',
		{ pageSize: 100, pageNumber: 1 },
		{ suspense: true }
	)

	const [startDate, setStartDate] = useState(startOfMonth(new Date()))
	const [endDate, setEndDate] = useState(endOfMonth(new Date()))

	const [isDownloadingOrgCSV, setDownloadingOrgCSV] = useState(false)
	const [isSendingOrgXero, setSendingOrgXero] = useState(false)

	const downloadOrgCSV = async () => {
		if (!isNotNone(selectedOrg)) return

		setDownloadingOrgCSV(true)

		await downloadCSV(
			`/Billing/org-csv?search=${encodeURIComponent(
				JSON.stringify({
					organizationId: selectedOrg.id,
					invoiceDate: {
						startDate: fmt(startDate),
						endDate: fmt(endDate),
					},
				})
			)}`,
			`billing-org_${selectedOrg.name}_${format(startDate, 'yyyy-MM-dd')}.csv`
		)

		setDownloadingOrgCSV(false)
	}

	const sendOrgXero = async () => {
		if (!isNotNone(selectedOrg)) return

		setSendingOrgXero(true)

		await sendToXero(async () => {
			const { data } = await api.post(
				`/Xero/org-invoice?search=${encodeURIComponent(
					JSON.stringify({
						organizationId: selectedOrg.id,
						invoiceDate: {
							startDate: fmt(startDate),
							endDate: fmt(endDate),
						},
					})
				)}`
			)
			console.log(data)
			success({ title: 'Sent to Xero' })
		})

		setSendingOrgXero(false)
	}

	const [isDownloadingBCICSV, setDownloadingBCICSV] = useState(false)
	const [isSendingBCIXero, setSendingBCIXero] = useState(false)

	const downloadBCICSV = async () => {
		if (!isNotNone(selectedUser)) return

		setDownloadingBCICSV(true)

		await downloadCSV(
			`/Billing/bci-csv?search=${encodeURIComponent(
				JSON.stringify({
					userId: selectedUser.id,
					invoiceDate: {
						startDate: fmt(startDate),
						endDate: fmt(endDate),
					},
				})
			)}`,
			`billing-bci_${getFullName(selectedUser)}_${format(startDate, 'yyyy-MM-dd')}.csv`
		)

		setDownloadingBCICSV(false)
	}

	const sendBCIXero = async () => {
		if (!isNotNone(selectedUser)) return

		setSendingBCIXero(true)

		await sendToXero(async () => {
			const { data } = await api.post(
				`/Xero/bci?search=${encodeURIComponent(
					JSON.stringify({
						userId: selectedUser.id,
						invoiceDate: {
							startDate: fmt(startDate),
							endDate: fmt(endDate),
						},
					})
				)}`
			)
			console.log(data)
			success({ title: 'Sent to Xero' })
		})

		setSendingBCIXero(false)
	}

	const [isDownloadingBCIAllCSV, setDownloadingBCIAllCSV] = useState(false)
	const [isSendingBCIAllXero, setSendingBCIAllXero] = useState(false)
	const [gstRegistered, setGstRegistered] = useState(false)

	const downloadBCIAllCSV = async () => {
		setDownloadingBCIAllCSV(true)

		await downloadCSV(
			`/Billing/bci-csv?search=${encodeURIComponent(
				JSON.stringify({
					isGstRegistered: gstRegistered,
					invoiceDate: {
						startDate: fmt(startDate),
						endDate: fmt(endDate),
					},
				})
			)}`,
			`billing-bci_${gstRegistered ? 'gst-registered' : 'non-gst-registered'}_${format(
				startDate,
				'yyyy-MM-dd'
			)}.csv`
		)

		setDownloadingBCIAllCSV(false)
	}

	const sendBCIAllXero = async () => {
		setSendingBCIAllXero(true)

		await sendToXero(async () => {
			const { data } = await api.post(
				`/Xero/bci?search=${encodeURIComponent(
					JSON.stringify({
						isGstRegistered: gstRegistered,
						invoiceDate: {
							startDate: fmt(startDate),
							endDate: fmt(endDate),
						},
					})
				)}`
			)
			console.log(data)
			success({ title: 'Sent to Xero' })
		})

		setSendingBCIAllXero(false)
	}

	const [isDownloadingACCCSV, setDownloadingACCCSV] = useState(false)

	const downloadACCCSV = async () => {
		setDownloadingACCCSV(true)

		await downloadCSV(
			`/Billing/acc-csv?startDate=${fmt(startDate)}&endDate=${fmt(endDate)}`,
			`ACC Invoice CSV_${format(startDate, 'yyyy-MM-dd')}.csv`
		)

		setDownloadingACCCSV(false)
	}

	const [isDownloadingSummaryCSV, setDownloadingSummaryCSV] = useState(false)

	const downloadSummaryCSV = async () => {
		setDownloadingSummaryCSV(true)

		await downloadCSV(
			`/Billing/final-csv?startDate=${fmt(startDate)}&endDate=${fmt(endDate)}`,
			`billing-summary_${format(startDate, 'yyyy-MM-dd')}.csv`
		)

		setDownloadingSummaryCSV(false)
	}

	const [isDownloadingAEPCSV, setDownloadingAEPCSV] = useState(false)

	const downloadAEPCSV = async () => {
		setDownloadingAEPCSV(true)

		await downloadCSV(
			`/Billing/aep-csv?startDate=${fmt(startDate)}&endDate=${fmt(endDate)}`,
			`AEP Invoice CSV_${format(startDate, 'yyyy-MM-dd')}.csv`
		)

		setDownloadingAEPCSV(false)
	}

	const [isProcessing, setProcessing] = useState(false)

	const process = async () => {
		confirm({
			title: 'This will update the billing status on all relevant closed cases. Is this ok?',
			intent: 'primary',
			onAccept: async () => {
				setProcessing(true)
				try {
					await api.post(`/Billing/processbillableitems?startDate=${fmt(startDate)}&endDate=${fmt(endDate)}`)
				} catch (error) {
					api.handleError(error)
				}
				setProcessing(false)
			},
		})
	}

	const sendToXero = async (fn: () => Promise<any>) => {
		try {
			const { data: isAuthorized } = await api.get('/Xero/authorized')

			if (isAuthorized) {
				await fn()
			} else {
				let callbackUri =
					env === 'dev' ? `${window.location.origin}/xero` : 'https://nzdrc-icra-dev.azurewebsites.net/xero'
				const { data } = await api.get(`/Xero/login-uri?callbackUri=${callbackUri}`)

				window.open(String(data))
			}
		} catch (error) {
			api.handleError(error)
		}
	}

	return (
		<Stack dividers className="pt-6">
			{!xeroAuthorized && (
				<div className="px-6 flex justify-end">
					<Actions
						actions={[
							{
								intent: 'primary',
								title: 'Login to Xero',
								onClick: () => xeroLogin(),
								isLoading: xeroLoading,
							},
						]}
					/>
				</div>
			)}

			<Panel
				title="Date Range"
				subtitle="Work on billing all cases closed within the following dates"
				content={
					<div className="flex space-x-4">
						<div className="flex flex-col space-y-2">
							<div className="block text-sm font-medium leading-5 text-gray-700 dark:text-white">
								Start Date
							</div>
							<DatePicker value={startDate} onChange={(val) => val && setStartDate(val)} />
						</div>
						<div className="flex flex-col space-y-2">
							<div className="block text-sm font-medium leading-5 text-gray-700 dark:text-white">
								End Date
							</div>
							<DatePicker value={endDate} onChange={(val) => val && setEndDate(val)} />
						</div>
					</div>
				}
			/>

			<Panel
				title="Download Data for ACC Approval"
				subtitle="Download ACC Invoice CSV (specified by ACC) to be sent with Draft Invoice"
				content={
					<Actions
						actions={[
							{
								title: 'Export',
								intent: 'primary',
								onClick: () => downloadACCCSV(),
								isLoading: isDownloadingACCCSV,
							},
						]}
					/>
				}
			/>

			<Panel
				title="Download Data for AEP Invoicing"
				subtitle="Download AEP Invoice CSV for an overview of all AEP cases ready for billing"
				content={
					<Actions
						actions={[
							{
								title: 'Export',
								intent: 'primary',
								onClick: () => downloadAEPCSV(),
								isLoading: isDownloadingAEPCSV,
							},
						]}
					/>
				}
			/>

			<Panel
				title="Download Billing Data for Organisation"
				subtitle="Download a CSV that can be uploaded to Xero as an invoice"
				content={
					<div className="flex space-x-4">
						<div className="w-64">
							<Select
								options={orgs?.items.map((x) => ({ label: x.name, value: x })) || []}
								value={selectedOrg}
								onChange={(x) => setSelectedOrg(x)}
								clearable
							/>
						</div>

						<Actions
							actions={[
								{
									title: 'Export',
									intent: 'primary',
									onClick: () => downloadOrgCSV(),
									isLoading: isDownloadingOrgCSV,
								},
								xeroAuthorized && {
									title: 'Xero',
									intent: 'primary',
									onClick: () => sendOrgXero(),
									isLoading: isSendingOrgXero,
								},
							]}
						/>
					</div>
				}
			/>

			<Panel
				title="Download Billing Data for Contractor"
				subtitle="Download a CSV that can be uploaded to Xero as an invoice"
				content={
					<div className="flex space-x-4">
						<div className="w-64">
							<Select
								options={
									users?.items.map((x) => ({
										label: `${getFullName(x)}${!x.isGSTRegistered ? ` - Not GST Registered` : ''}`,
										value: x,
									})) || []
								}
								value={selectedUser}
								onChange={(x) => setSelectedUser(x)}
								clearable
							/>
						</div>

						<Actions
							actions={[
								{
									title: 'Export',
									intent: 'primary',
									onClick: () => downloadBCICSV(),
									isLoading: isDownloadingBCICSV,
								},
								xeroAuthorized && {
									title: 'Xero',
									intent: 'primary',
									onClick: () => sendBCIXero(),
									isLoading: isSendingBCIXero,
								},
							]}
						/>
					</div>
				}
			/>

			<Panel
				title="Download Billing Data for all Contractors"
				subtitle="Download a CSV that can be uploaded to Xero as an invoice"
				content={
					<div className="flex space-x-4">
						<div className="w-64">
							<Toggle
								label={gstRegistered ? 'GST Registered' : 'Not GST Registered'}
								value={gstRegistered}
								onChange={(x) => setGstRegistered(x.target.checked)}
							/>
						</div>

						<Actions
							actions={[
								{
									title: 'Export',
									intent: 'primary',
									onClick: () => downloadBCIAllCSV(),
									isLoading: isDownloadingBCIAllCSV,
								},
								xeroAuthorized && {
									title: 'Xero',
									intent: 'primary',
									onClick: () => sendBCIAllXero(),
									isLoading: isSendingBCIAllXero,
								},
							]}
						/>
					</div>
				}
			/>

			<Panel
				title="Process for Billing"
				subtitle="Mark all closed cases within the current date range as billed"
				content={
					<Actions
						actions={[
							{
								title: 'Process Now',
								intent: 'primary',
								onClick: () => process(),
								isLoading: isProcessing,
							},
						]}
					/>
				}
			/>

			<Panel
				title="Download Billing Summary"
				subtitle="Download a summary of all cases already billed in the current date range"
				content={
					<Actions
						actions={[
							{
								title: 'Download',
								intent: 'primary',
								onClick: () => downloadSummaryCSV(),
								isLoading: isDownloadingSummaryCSV,
							},
						]}
					/>
				}
			/>
		</Stack>
	)
}

const BillingLineitems = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const paginationState: PaginationState = {
		page: state?.page || 1,
		pageSize: state?.pageSize || 10,
		orderDir: 2,
		search: {
			claimable: [0, 1],
			paymentApprovedACC: [0],
		},
	}

	const { data } = usePaginatedData<LineItemModel>('/Billing/Lineitems', paginationState)

	const schema: TableSchema<LineItemModel> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.description,
			},
			{
				title: 'Amount (GST Excl)',
				value: (x) => x.costPrice,
			},
			{
				title: 'Type',
				value: (x) => (x.payableToId ? 'ICRA Disbursements' : 'ACC Reimbursable'),
			},
			{
				title: 'Case',
				value: (x) => (
					<Link to={`/cases/${x.caseId}`} className="anchor">
						{(x.case as CaseModel).caseNumber}
					</Link>
				),
			},
		],
	}

	return <PaginatedTable schema={schema} set={data} paginationState={paginationState} className="px-6" />
}

export default Billing
