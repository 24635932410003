export enum ADR_CLOSING_CATEGORY {
	Open = 0,
	ADRWithdrawnBefore = 12,
	ADRAgreementWithdrawn = 13,
	ADRAgreementNotWithdrawn = 14,
	ADRNoAgreement = 15,
	ADRNonAttendance = 16,
	ADRSettled = 19,
}

export const adrClosingCategory = {
	0: 'Open',
	12: 'ADR (Withdrawn Before)',
	13: 'ADR Agreement (Withdrawn)',
	14: 'ADR (Agreement Not Withdrawn)',
	15: 'ADR No Agreement',
	16: 'ADR Non Attendance',
	19: 'ADR Settled (particular Review no. only)',
}

export const ADRClosingCategory = {
	readable: (x: ADR_CLOSING_CATEGORY) => adrClosingCategory[x],
	options: Object.entries(adrClosingCategory).map(([key, val]) => ({ label: val, value: +key })),
}
