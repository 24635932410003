import { FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import CaseHeader from 'components/CaseHeader'
import Stack from 'components/Stack'
import Hearing from './Event'
import openCreateHearing from 'modals/openCreateHearing'
import { Auth } from 'Auth'
import { parseQueryCode } from 'utils/funcs'
import api from 'api'
import useData from 'hooks/useData'
import { format, parseISO } from 'date-fns'
import { XIcon, DocumentDuplicateIcon, PlusIcon, DocumentIcon } from '@heroicons/react/outline'
import { confirm, toast } from 'alerts'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import { CaseModel, HearingModel } from 'types/models'
import { HearingType } from 'types/enums'
import SectionHeader from 'components/SectionHeader'
import { navigate } from 'components/NavigateHoist'

// interface ConferencesProps {
// 	caseId?: string
// }

const Conferences: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const location = useLocation()

	const qs = location.search ? parseQueryCode(location.search) : {}
	const activeIndex = location.search ? caseData?.hearings.findIndex((x) => x.id === qs.hearing) || 0 : 0

	const deleteHearing = async (hearing: HearingModel) => {
		confirm({
			title: `Are you sure you want to delete this ${HearingType.readable(hearing.hearingType)} hearing?`,
			onAccept: async () => {
				try {
					await api.delete(`/CaseHearings/${hearing.id}`)

					refetch()

					toast({
						title: 'Hearing deleted',
					})
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const getNumber = (hearing: HearingModel) => {
		if (!caseData) return ''

		const list = caseData?.hearings.filter((x) => x.hearingType === hearing.hearingType)

		return list.findIndex((x) => x.id === hearing.id) + 1
	}

	const links = caseData ? caseLinks(caseData) : []

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack>
					<SectionHeader
						title="Conferences"
						actions={
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && caseData
								? [
										{
											title: 'Create Conference',
											icon: <PlusIcon className="w-5 h-5" />,
											intent: 'primary',
											actions: [
												{
													title: 'New conference',
													icon: <DocumentIcon className="w-5 h-5" />,
													onClick: () => openCreateHearing({ caseData, refetch }),
												},
												{
													title: 'Duplicate this conference',
													icon: <DocumentDuplicateIcon className="w-5 h-5" />,
													onClick: () =>
														openCreateHearing({
															caseData: caseData,
															currentHearing: caseData?.hearings[activeIndex],
															refetch,
														}),
												},
											],
										},
								  ]
								: undefined
						}
						className="px-6"
					/>

					<div className="px-2">
						<div className="w-full flex flex-nowrap border-b border-gray-200 dark:border-gray-600">
							{caseData?.hearings.map((hearing, i) =>
								i === activeIndex ? (
									<span
										key={hearing.id}
										className="p-3 -ml-px -mb-px flex items-center whitespace-nowrap text-sm font-medium border-b-2 border-primary-600 cursor-default"
									>
										{HearingType.readable(hearing.hearingType)} #{getNumber(hearing)} (
										{format(parseISO(hearing.startDate || '1970-01-01'), 'dd/MM/yyyy')})
										{Auth.is('Admin', 'CaseManager', 'FENZAdmin') && (
											<button
												type="button"
												className="ml-3 opacity-50 hover:opacity-100"
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()

													deleteHearing(hearing)
												}}
											>
												<XIcon className="w-4 h-4" />
											</button>
										)}
									</span>
								) : (
									<span
										key={hearing.id}
										className="p-3 -ml-px -mb-px overflow-hidden truncate text-sm font-medium text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 cursor-pointer"
										title={`${HearingType.readable(hearing.hearingType)} #${getNumber(
											hearing
										)} (${format(parseISO(hearing.startDate || '1970-01-01'), 'dd/MM/yyyy')}`}
										onClick={() =>
											navigate(`/cases/${caseData.id}/conferences?hearing=${hearing.id}`)
										}
									>
										{HearingType.readable(hearing.hearingType)} #{getNumber(hearing)} (
										{format(parseISO(hearing.startDate || ''), 'dd/MM/yyyy')})
									</span>
								)
							)}
						</div>
					</div>

					{caseData && caseData?.hearings[activeIndex] && (
						<Hearing hearing={caseData?.hearings[activeIndex]} caseData={caseData} refetch={refetch} />
					)}
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Conferences
