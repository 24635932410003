import {
	ArchiveIcon,
	BookOpenIcon,
	CalendarIcon,
	CogIcon,
	DocumentReportIcon,
	DocumentTextIcon,
	PhoneIcon,
	ScaleIcon,
	UploadIcon,
	UserGroupIcon,
	ViewGridIcon,
} from '@heroicons/react/outline'
import { Auth } from 'Auth'
import { NavListItems } from 'components/NavList'

const nav = (): NavListItems => [
	!Auth.isOnly('FileHandler') && {
		title: 'Dashboard',
		to: '/dashboard',
		icon: <ViewGridIcon className="w-5 h-5" />,
		exact: true,
	},
	Auth.is('FileHandler') && {
		title: 'Upload Files',
		to: '/upload',
		icon: <UploadIcon className="w-5 h-5" />,
		exact: true,
	},
	Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') &&
		!Auth.is('Reviewer') && {
			title: 'Cases',
			to: '/cases',
			icon: <ScaleIcon className="w-5 h-5" />,
			exact: false,
		},
	Auth.is(
		'Admin',
		'CaseManager',
		'Reviewer',
		'Mediator',
		'Facilitator',
		'Adjudicator',
		'Support',
		'FENZAdmin',
		'ACCAdmin',
		'Respondent'
	) && {
		title: 'Diary',
		to: '/diary',
		icon: <PhoneIcon className="w-5 h-5" />,
		exact: false,
	},
	Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
		title: 'Applications',
		to: '/applications',
		icon: <ArchiveIcon className="w-5 h-5" />,
		exact: false,
	},
	Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin', 'ACCAdmin', 'Respondent') && {
		title: 'Users',
		to: '/users',
		icon: <UserGroupIcon className="w-5 h-5" />,
		exact: false,
	},
	Auth.is('Admin', 'CaseManager', 'FENZAdmin', 'ACCAdmin', 'Respondent') && {
		title: 'Files',
		to: '/files',
		icon: <DocumentTextIcon className="w-5 h-5" />,
		exact: true,
	},
	Auth.is('Admin', 'CaseManager', 'Reviewer', 'Mediator', 'Facilitator', 'Adjudicator', 'FENZAdmin') && {
		title: 'Availability',
		to: '/availability',
		icon: <CalendarIcon className="w-5 h-5" />,
		exact: true,
	},
	Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') && {
		title: 'Reports',
		to: '/reports',
		icon: <DocumentReportIcon className="w-5 h-5" />,
		exact: true,
	},
	Auth.is('Admin', 'FENZAdmin') && {
		title: 'Admin',
		to: '/admin',
		icon: <CogIcon className="w-5 h-5" />,
		exact: false,
	},
	Auth.is('Admin', 'Reviewer', 'Mediator', 'Support', 'CaseManager', 'FENZAdmin') && {
		items: [
			{
				title: 'Resources',
				href: 'http://resources.icra.co.nz',
				icon: <BookOpenIcon className="w-5 h-5" />,
				exact: true,
				target: '_blank',
			},
		],
	},
]

export default nav
