import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import usePaginatedData from 'hooks/usePaginatedData'
import { format } from 'date-fns'
import openEmailTemplate from 'modals/openEmailTemplate'
import PaginatedTable from 'components/PaginatedTable'
import { EmailLogModel, UserModel } from 'types/models'
import { TableSchema } from 'components/Table'
import { CasePartyRole } from 'types/enums'
import NotificationsSearch from 'search/NotificationsSearch'
import { EyeIcon } from '@heroicons/react/outline'

interface UserTabCasesProps {
	user: UserModel
}

const UserTabCases: FC<UserTabCasesProps> = ({ user }) => {
	const location = useLocation()

	const state = location?.state as {
		logs_search?: any
		logs_page?: number
		logs_pageSize?: number
	} | null

	const logsPaginationState = {
		orderBy: 'created',
		orderDir: 2,
		page: state?.logs_page || 1,
		pageSize: state?.logs_pageSize || 5,
		search: { ...(state?.logs_search || {}), recipient: user?.email || '' },
	}

	const { data: logs, isFetching: logsLoading } = usePaginatedData<EmailLogModel>('/EmailLogs', logsPaginationState)

	const logSchema: TableSchema<EmailLogModel> = {
		cols: [
			{
				title: 'Template',
				value: (x) => x.template,
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Sent',
				value: (x) => format(new Date(x.created), 'dd/MM/yyyy HH:mm:ss'),
				truncate: true,
			},
			{
				title: 'Roles',
				value: (x) =>
					x.partyRole.length > 0 ? x.partyRole.map((x) => CasePartyRole.readable(x)).join(', ') : '-',
				truncate: true,
			},
			{
				title: 'Case',
				value: (x) =>
					x.case ? (
						<Link to={`/cases/${x.case.id}`} className="anchor">
							{x.case.caseNumber}
						</Link>
					) : (
						'-'
					),
				responsive: 'md',
			},
		],
		actions: (x) => [
			{
				icon: <EyeIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => openEmailTemplate({ logId: x.id }),
			},
		],
	}

	return (
		<PaginatedTable
			set={logs}
			schema={logSchema}
			isLoading={logsLoading}
			paginationState={logsPaginationState}
			paginationPrefix="logs_"
			search={(props) => (
				<NotificationsSearch
					{...props}
					presetValues={{ recipient: { value: user?.email || '', locked: true } }}
				/>
			)}
		/>
	)
}

export default UserTabCases
