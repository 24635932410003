import { FC, useState } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import ReactQuill from 'react-quill'
import api from 'api'
import { toast } from 'components/toast'
import { CaseFileModel, CaseModel } from 'types/models'
import { RefetchFn } from 'types'
import { CasePartyRole } from 'types/enums'

const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', { color: [] }],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ align: [] }],
		['clean'],
	],
}

interface OpenCreateSubmissionProps {
	caseData: CaseModel
	refetch?: RefetchFn
}

const openCreateSubmission = ({ caseData, refetch }: OpenCreateSubmissionProps) => {
	return openModal({
		title: 'Create Submission',
		size: 'lg',
		render: (close) => <CreateSubmission close={close} caseData={caseData} refetch={refetch} />,
	})
}

const CreateSubmission: FC<OpenCreateSubmissionProps & { close: () => void }> = ({ close, caseData, refetch }) => {
	const { isSaving, setSaving } = useModalState()
	const [fileName, setFileName] = useState('')
	const [value, setValue] = useState('')

	const handleSubmit = async (e: MouseEvent) => {
		e.preventDefault()

		if (!fileName.trim()) return

		const file = new File([value], `${fileName.trim() || 'file'}.html`, { type: 'text/html' })

		setSaving(true)

		const { data: files } = await api.get<CaseFileModel[]>(`/Casefiles/hierarchy?caseId=${caseData.id}`)

		// const findFolder = (name: string, folder: CaseFileModel): CaseFileModel | undefined => {
		// 	if (folder.name === name) return folder
		// 	if (folder.hasDescendants) {
		// 		for (let i = 0; i < folder.children.length; i++) {
		// 			let result = findFolder(name, folder.children[i])
		// 			if (result) return result
		// 		}
		// 	}
		// }

		const targetFolder = files?.find((x) => x.name === 'APPLICANT SUBMISSIONS/EVIDENCE')

		if (!targetFolder) {
			setSaving(false)
			return
		}

		const body = new FormData()

		body.append('case.id', caseData.id)
		body.append('parentId', targetFolder.id)
		body.append('files', file)

		CasePartyRole.options.forEach((role) => {
			body.append('permissions[0]', String(role.value))
		})

		await api.post('/CaseFiles', body)

		if (refetch) await refetch()

		toast({ title: 'Submission created' })

		close()
	}

	return (
		<>
			<ModalContent>
				<Stack>
					<input
						type="text"
						placeholder="File name"
						className="form-input block w-full pr-10 sm:text-sm sm:leading-5 dark:bg-gray-800 dark:placeholder-gray-400 dark:border-gray-600"
						value={fileName}
						onChange={(e) => setFileName(e.target.value)}
					/>

					<ReactQuill value={value} onChange={(val) => setValue(val)} modules={modules} />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: handleSubmit,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openCreateSubmission
