import { Auth } from 'Auth'
import {
	MailIcon,
	InformationCircleIcon,
	HashtagIcon,
	PhoneIcon,
	DocumentTextIcon,
	ClipboardCheckIcon,
	LinkIcon,
	UsersIcon,
	CurrencyDollarIcon,
	BellIcon,
} from '@heroicons/react/outline'
import { CaseModel } from 'types/models'
import { FENZOrgId } from 'utils/constants'

const caseLinks = (caseData: CaseModel) => {
	const userId = Auth.profile()?.id

	const isFENZ = caseData?.organization?.id === FENZOrgId

	const isLimited = caseData.applicant
		? caseData.applicant.user?.id === userId || caseData.applicant.parties.find((x) => x.id === userId)
		: false // ||
	// (caseData.respondent
	// 	? caseData.respondent?.user?.id === userId || caseData.respondent.parties.find((x) => x.id === userId)
	// 	: false)

	return [
		{
			title: 'Case Information',
			to: `/cases/${caseData.id}/case-information`,
			icon: <InformationCircleIcon className="w-5 h-5" />,
			exact: true,
		},
		Auth.is(
			'Admin',
			'CaseManager',
			'Applicant',
			'PeerReviewer',
			'Reviewer',
			'Mediator',
			'Support',
			'FENZAdmin',
			'Adjudicator',
			'Facilitator',
			'ACCAdmin',
			'Respondent'
		) && {
			title: 'Participants',
			to: `/cases/${caseData.id}/participants`,
			icon: <UsersIcon className="w-5 h-5" />,
			exact: true,
		},
		!isFENZ &&
			Auth.is('Admin', 'CaseManager', 'Support', 'ACCAdmin', 'Respondent') && {
				title: 'Reviews',
				to: `/cases/${caseData.id}/reviews`,
				icon: <HashtagIcon className="w-5 h-5" />,
				exact: true,
			},
		{
			title: 'Conferences',
			to: `/cases/${caseData.id}/conferences`,
			icon: <PhoneIcon className="w-5 h-5" />,
			exact: true,
		},
		{
			title: 'Documents',
			to: `/cases/${caseData.id}/documents`,
			icon: <DocumentTextIcon className="w-5 h-5" />,
			exact: true,
		},
		(!isLimited || Auth.is('Admin', 'CaseManager', 'FENZAdmin', 'ACCAdmin', 'Respondent')) && {
			title: 'Case Status',
			to: `/cases/${caseData.id}/status`,
			icon: <ClipboardCheckIcon className="w-5 h-5" />,
			exact: true,
		},
		Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
			title: 'Billing',
			to: `/cases/${caseData.id}/billing`,
			icon: <CurrencyDollarIcon className="w-5 h-5" />,
			exact: true,
		},
		(!isLimited || Auth.is('Admin', 'CaseManager', 'FENZAdmin', 'ACCAdmin', 'Respondent')) && {
			title: 'Linked Cases',
			to: `/cases/${caseData.id}/linked-cases`,
			icon: <LinkIcon className="w-5 h-5" />,
			exact: true,
		},
		Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') && {
			title: 'Notifications',
			to: `/cases/${caseData.id}/notifications`,
			icon: <MailIcon className="w-5 h-5" />,
			exact: true,
		},
		Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') && {
			title: 'Reminders',
			to: `/cases/${caseData.id}/reminders`,
			icon: <BellIcon className="w-5 h-5" />,
			exact: true,
		},
	]
}

export default caseLinks
