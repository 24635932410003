import { FC, ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { customAlert, CustomAlertSizeValue, useModalSavingState } from 'alerts/CustomAlert'
import { XIcon } from '@heroicons/react/outline'
import Suspender from 'components/Suspender'
import clsx, { ClassValue } from 'clsx'
import { ModalResolveFn } from 'types'

interface ModalHookProps {
	title?: string
	render: (close: ModalResolveFn<boolean | unknown>) => ReactNode
	size?: CustomAlertSizeValue
	dismissable?: boolean
}

type UseModalType = <T extends {}>(
	cb: ((props: T) => ModalHookProps) | ModalHookProps
) => (props: T) => Promise<boolean>

//@ts-ignore
const useModal: UseModalType = (cb) => {
	return async (props) => {
		const { title, render, size, dismissable = true } = typeof cb === 'function' ? cb(props) : cb

		return await customAlert({
			dismissable,
			size,
			children: (close) => (
				<BrowserRouter>
					{/* @ts-ignore */}
					<Modal default title={title} render={render} dismissable={dismissable} close={close} />
				</BrowserRouter>
			),
		})
	}
}

interface ModalProps<T> {
	default?: boolean
	title?: string
	render: (close: ModalResolveFn<T>) => ReactNode
	dismissable: boolean
	close: ModalResolveFn<T>
}

function Modal<T>({ title, render, dismissable, close }: ModalProps<T>) {
	return (
		<div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg border border-gray-100 dark:border-gray-600 relative z-40">
			{title ? (
				<div
					className={
						'flex items-center justify-between bg-white dark:bg-gray-700 px-4 py-5 border-b border-gray-100 dark:border-gray-600 rounded-tl-lg rounded-tr-lg sm:px-6'
					}
				>
					<div className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{title}</div>

					{dismissable && (
						<button
							className="p-2 -m-2 focus:outline-none active:outline-none outline-none rounded-full bg-gray-100 dark:bg-gray-600 hover:bg-gray-200 active:bg-gray-300 dark:hover:bg-gray-800 dark:active:bg-gray-900 transition-all duration-200"
							onClick={() => close()}
							type="button"
						>
							<XIcon className="w-5 h-5" />
						</button>
					)}
				</div>
			) : (
				<div className="absolute top-0 right-0 -mt-2 -mr-2">
					{dismissable && (
						<button
							className="p-2 bg-white dark:bg-gray-800 shadow-lg focus:outline-none active:outline-none outline-none rounded-full hover:bg-gray-200 active:bg-gray-300 dark:hover:bg-gray-800 dark:active:bg-gray-900 transition-all duration-200"
							onClick={() => close()}
							type="button"
						>
							<XIcon className="w-5 h-5" />
						</button>
					)}
				</div>
			)}

			<Suspender render={render(close)} />
		</div>
	)
}

export default useModal

export const useModalState = useModalSavingState

interface ModalContentProps {
	className?: ClassValue
	children: ReactNode
}

export const ModalContent: FC<ModalContentProps> = ({ className, children }) => {
	return <div className={clsx('px-4 py-5 sm:p-6', className)}>{children}</div>
}

interface ModalFooterProps {
	className?: ClassValue
	children: ReactNode
}

export const ModalFooter: FC<ModalFooterProps> = ({ className, children }) => {
	return (
		<div
			className={clsx(
				'bg-gray-50 dark:bg-gray-750 p-4 border-t border-gray-100 dark:border-gray-600 rounded-bl-lg rounded-br-lg',
				className
			)}
		>
			{children}
		</div>
	)
}

interface OpenModalProps<T> {
	title?: string
	render: (close: ModalResolveFn<T>) => ReactNode
	size?: CustomAlertSizeValue
	dismissable?: boolean
}

export async function openModal<T>({ title, render, size, dismissable = true }: OpenModalProps<T>) {
	return await customAlert<T>({
		dismissable,
		size,
		children: (close) => (
			<BrowserRouter>
				<Modal default title={title} render={render} dismissable={dismissable} close={close} />
			</BrowserRouter>
		),
	})
}
