import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon, SearchIcon } from '@heroicons/react/outline'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import useData from 'hooks/useData'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import api from 'api'
import Badge from 'components/Badge'
import Pagination from 'components/Pagination'
import { toast } from 'components/toast'
import clsx from 'clsx'
import { CaseModel } from 'types/models'
import { PaginatedSet, RefetchFn } from 'types'
import { CaseStatus, CaseType } from 'types/enums'
import Table, { TableSchema } from 'components/Table'

interface OpenLinkCasesProps {
	caseData: CaseModel
	refetch: RefetchFn
}

const useLinkCases = ({ caseData, refetch }: OpenLinkCasesProps) => {
	return openModal({
		title: 'Link Cases',
		size: 'xl',
		render: (close) => <LinkCases caseData={caseData} refetch={refetch} close={close} />,
	})
}

/*
	Note:

	Because the PaginatedTable compnent relies on route state, it cannot be used inside of a modal.
	Therefore a combination of <Pagination> and <Table> components is necessary
*/

interface FormData {
	caseNumber: string
	accClaimNumber: string
}

type SearchPayload = { caseNumber?: string; caseFields?: { name: string; value: string }[] }

const LinkCases: FC<OpenLinkCasesProps & { close: () => void }> = ({ caseData, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()
	const [selected, setSelected] = useState<string[]>([])
	const [search, setSearch] = useState<SearchPayload>()
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(5)

	const formContext = useForm({
		defaultValues: {
			caseNumber: '',
			accClaimNumber: '',
		},
	})

	const { caseNumber, accClaimNumber } = formContext.watch(['caseNumber', 'accClaimNumber'])

	const handleSearch = async (formData: FormData) => {
		const newSearch: SearchPayload = {}

		if (formData.caseNumber) {
			newSearch.caseNumber = formData.caseNumber
		}

		if (formData.accClaimNumber) {
			newSearch.caseFields = [
				{
					name: 'accClaimNumber',
					value: formData.accClaimNumber,
				},
			]
		}

		setSearch(newSearch)
	}

	const paginationState = {
		orderBy: 'created',
		orderDirection: 2,
		search,
		pageNumber: page,
		pageSize,
	}

	const { data, isFetching } = useData<PaginatedSet<CaseModel>>('/Cases', paginationState, {
		enabled: !!search,
		onSuccess: (data) => {
			setSelected(data.items.filter((x) => x.group && x.group?.id === caseData.group?.id).map((x) => x.id))
		},
	})

	const handleSubmit = async () => {
		if (!selected.length) return
		setSaving(true)

		try {
			let group_id = caseData.group ? caseData.group.id : null

			if (!group_id) {
				let { data: caseGroup } = await api.post(`/CaseGroups/`, {})
				group_id = caseGroup.id

				await api.post(`/CaseGroups/${group_id}/case/${caseData.id}`, {})
			}

			for (let i = 0; i < selected.length; i++) {
				let item = data?.items.find((x) => x.id === selected[i])

				await api.post(`/CaseGroups/${group_id}/case/${item?.id}`, {})
			}

			await refetch()

			close()

			toast({ title: `Case${selected.length > 1 ? 's' : ''} linked` })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const isAlreadyInGroup = (item: CaseModel) => {
		if (item.id === caseData.id) return true
		if (!item.group) return false
		if (!caseData.group) return false

		return item.group.id === caseData.group.id
	}

	const schema: TableSchema<CaseModel> = {
		cols: [
			{
				title: '',
				width: '50px',
				value: (x) => (
					<input
						type="checkbox"
						id={`checkbox-${x.id}`}
						className={clsx('form-checkbox', isAlreadyInGroup(x) ? 'text-gray-400' : 'text-primary-600')}
						checked={isAlreadyInGroup(x) ? true : selected.indexOf(x.id) > -1}
						onChange={(e) => {
							if (e.target.checked) setSelected((selected) => selected.concat([x.id]))
							else setSelected((selected) => selected.filter((y) => y !== x.id))
						}}
						disabled={isAlreadyInGroup(x)}
					/>
				),
			},
			{
				title: 'Case Number',
				value: (x) => <label htmlFor={`checkbox-${x.id}`}>{x.caseNumber}</label>,
			},
			{
				title: 'ACC Claim Number',
				value: (x) => x.fields?.find((y) => y.name === 'accClaimNumber')?.value,
			},
			{
				title: 'Applicant Name',
				value: (x) => x.fields?.find((y) => y.name === 'applicantName')?.value,
			},
			{
				title: 'Case Type',
				value: (x) => CaseType.readable(x.caseType),
			},
			{
				title: 'Status',
				value: (x) => <Badge status={x.status}>{CaseStatus.readable(x.status)}</Badge>,
			},
		],
	}

	return (
		<>
			<ModalContent>
				<Stack dividers>
					<Stack>
						<Form context={formContext} onSubmit={handleSearch}>
							<Stack>
								<div className="flex space-x-6">
									<div className="w-1/2">
										<FormInput name="caseNumber" label="Case Number" autoFocus />
									</div>

									<div className="w-1/2">
										<FormInput name="accClaimNumber" label="ACC Claim Number" />
									</div>
								</div>

								<div className="flex justify-end">
									<Actions
										actions={{
											title: 'Search',
											icon: <SearchIcon className="w-5 h-5" />,
											intent: 'primary',
											type: 'submit',
											isLoading: isFetching,
											disabled: !caseNumber && !accClaimNumber,
										}}
									/>
								</div>
							</Stack>
						</Form>
					</Stack>

					{search && (
						<Stack>
							<Pagination
								page={page}
								setPage={setPage}
								totalCount={data ? data.totalCount : 0}
								pageCount={data ? data.items.length : 0}
								pageSize={pageSize}
								setPageSize={setPageSize}
								isLoading={isFetching}
								showCounter
							/>

							<Table schema={schema} items={data?.items || []} />

							<Pagination
								page={page}
								setPage={setPage}
								totalCount={data ? data.totalCount : 0}
								pageCount={data ? data.items.length : 0}
								pageSize={pageSize}
								setPageSize={setPageSize}
								showInfo
							/>
						</Stack>
					)}
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								icon: <CheckIcon className="w-5 h-5" />,
								intent: 'save',
								onClick: () => handleSubmit(),
								isLoading: isSaving,
								disabled: selected?.length === 0,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default useLinkCases
