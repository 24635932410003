import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { API_METRIC_TYPE } from 'types/enums'

// components
import PageContent from 'components/PageContent'
import PageHeading from 'components/PageHeading'
import Card from 'components/Card'
import Stack from 'components/Stack'
import MetricsTable from 'components/MetricsTable'

const DecisionDue: FC = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const logsPaginationState = {
		orderBy: 'created',
		orderDir: 2,
		page: state?.page || 1,
		pageSize: state?.pageSize || 5,
		search: {
			metricType: API_METRIC_TYPE.DecisionDue,
			dueDays: 14,
			roleType: state?.search,
		},
	}

	return (
		<>
			<PageHeading title="Cases with decision due in the next 14 days" />

			<PageContent>
				<Stack>
					<Card>
						{/* <GQLPaginatedTable schema={schema} set={cases} isLoading={isFetching} getKey={(x) => x.id} /> */}
						<MetricsTable paginationState={logsPaginationState} />
					</Card>
				</Stack>
			</PageContent>
		</>
	)
}

export default DecisionDue
