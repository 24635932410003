import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { navigate } from 'components/NavigateHoist'
// import logo from 'assets/img/ICRA_WhiteRev.png'
import logo from 'assets/img/newlogo/icra-acronym-white.png'
import Actions from 'components/Actions'
import axios from 'axios'
import store from 'Auth'
import openTwoFactor from 'modals/openTwoFactor'
import openForgottenPassword from 'modals/openForgottenPassword'
import { warning } from 'alerts'
import Storage from 'utils/storage'
import { CardWrapper } from 'components/Card'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import conf from 'utils/config'
import { isAxiosError } from 'types'

interface FormData {
	username: string
	password: string
}

const Login: FC = () => {
	useEffect(() => {
		document.title = 'Login - ICRA'
	}, [])

	const location = useLocation()

	const state = location?.state as { intended?: string } | null

	const intended = state?.intended as string

	const formContext = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
	})

	const handleSubmit = async (formData: FormData) => {
		const { username, password } = formData
		let deviceToken = Storage.getString('deviceToken')

		if (!deviceToken || !username || !password) return

		try {
			var {
				data: { access_token, expires_in, refresh_token, tfa_required },
			} = await axios.post<
				{ username: string; password: string; userAgent: string; deviceToken: string },
				{ data: { access_token: string; expires_in: number; refresh_token: string; tfa_required: string } }
			>(
				`${conf.url}/Auth/token`,
				{
					username,
					password,
					userAgent: window.navigator.userAgent,
					deviceToken,
				},
				{
					headers: {
						Registry: conf.registry,
					},
				}
			)
		} catch (error) {
			warning({
				title: 'Login Failed',
				message: isAxiosError(error) ? error?.response?.data?.title : '',
			})
			return
		}

		try {
			if (tfa_required) {
				let tfaRes: any = await openTwoFactor({ email: username })

				if (!tfaRes) {
					return
				}

				access_token = tfaRes.data.access_token
				expires_in = tfaRes.data.expires_in
				refresh_token = tfaRes.data.refresh_token
			}

			const { data: profile } = await axios.get(`${conf.url}/Accounts/profile`, {
				headers: {
					Registry: conf.registry,
					Authorization: `Bearer ${access_token}`,
				},
			})

			store.getState().login(access_token, expires_in, refresh_token, profile)

			navigate(intended ? intended : '/')
		} catch (error) {
			warning({
				title: 'Login Failed',
				message: isAxiosError(error) ? error?.response?.data?.title : '',
			})
		}
	}

	return (
		<div className="min-h-screen flex flex-col sm:justify-center items-center space-y-6 bg-gray-800">
			<div className="max-w-4xl w-full space-y-6">
				<img src={logo} alt="" className="w-40 ml-6 mt-6 sm:mt-0" />

				<CardWrapper className="bg-white dark:bg-gray-700 shadow rounded-none sm:rounded-md w-full overflow-hidden">
					<div className="grid grid-cols-1 sm:grid-cols-5">
						<div className="p-6 col-span-3 space-y-4">
							<div className="flex flex-col space-y-3">
								<div className="">Kia ora and welcome to</div>
								<div className="uppercase italic font-medium">Te Umanga Arotake Amuamu Motuhake</div>
								<div className="">The Independent Complaint and Review Authority</div>
							</div>

							<div className="mt-4 p-4 text-sm font-medium rounded-md leading-6 bg-primary-400 text-primary-800 dark:bg-primary-500 dark:text-primary-300 bg-opacity-25 dark:bg-opacity-25">
								Sign in with your email and password to access your dashboard and cases in progress.
								Please contact your case manager if you have not received this information.
							</div>
						</div>

						<div className="p-6 col-span-2 bg-gray-50 border-l border-gray-100 dark:bg-gray-750 dark:border-gray-600">
							<Form context={formContext} onSubmit={handleSubmit}>
								<Stack>
									<FormInput
										name="username"
										label="Email Address"
										validations={{ required: 'Email Address is required' }}
									/>

									<FormInput
										name="password"
										label="Password"
										type="password"
										validations={{ required: 'Password is required' }}
									/>

									<div className="flex items-center justify-between">
										<button
											className="text-sm font-medium text-primary-600 dark:text-primary-300"
											onClick={() => openForgottenPassword()}
											type="button"
										>
											Forgotten Password
										</button>

										<Actions
											actions={[
												{
													title: 'Login',
													intent: 'primary',
													type: 'submit',
													className: 'w-full',
													isLoading: formContext.formState.isSubmitting,
												},
											]}
										/>
									</div>
								</Stack>
							</Form>
						</div>
					</div>
				</CardWrapper>

				<div className="px-6 flex justify-between items-center text-gray-100">
					<div className="text-sm">Copyright © 2019, All rights reserved</div>
					<a
						href="https://www.icra.co.nz/terms-conditions/"
						target="_blank"
						rel="noreferrer"
						className="text-sm text-primary-400 dark:text-primary-300"
					>
						Terms &amp; Conditions
					</a>
				</div>
			</div>
		</div>
	)
}

export default Login
