import { FC, useState } from 'react'
import openSlideout, { SlideoutContent, SlideoutHeader } from 'hooks/openSlideout'
import Stack from 'components/Stack'
import { CheckCircleIcon, DotsHorizontalIcon, PencilIcon, TrashIcon, XCircleIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Actions from 'components/Actions'
import Slider from 'components/Slider'
import usePaginatedData from 'hooks/usePaginatedData'
import { TodoModel } from 'types/models'
import { format, startOfDay, subWeeks } from 'date-fns'
import { ensureDate, fixedFormatRelative } from 'utils/funcs'
import api from 'api'
import { Auth } from 'Auth'
import { toast, confirm } from 'alerts'
import openEditTodo from 'modals/openEditTodo'
import { RefetchFn } from 'types'
import clsx from 'clsx'
import { endOfDay } from 'date-fns/fp'
import { Spinner } from 'components/Icon'

interface OpenTodoListSlideoutProps {
	refetch: RefetchFn
}

const openTodoListSlideout = (props: OpenTodoListSlideoutProps) => {
	return openSlideout({
		render: () => <TodoList {...props} />,
	})
}

interface FormData {
	item: string
}

enum TAB_VIEW {
	Active = 0,
	Completed = 1,
}

const TodoList: FC<OpenTodoListSlideoutProps> = ({ refetch }) => {
	const [view, setView] = useState<number>(0)

	const {
		data: activeTodos,
		isFetching: activeFetching,
		refetch: refetchActiveTodoItems,
	} = usePaginatedData<TodoModel>('/ToDoItems', {
		orderDir: 1,
		page: 1,
		pageSize: 100,
		orderBy: 'Created',
		search: {
			completed: false,
		},
	})

	const {
		data: completedTodos,
		isFetching: completedFetching,
		refetch: refetchCompletedTodoItems,
	} = usePaginatedData<TodoModel>('/ToDoItems', {
		orderDir: 2,
		page: 1,
		pageSize: 100,
		orderBy: 'CompletedDate',
		search: {
			completed: true,
			startDate: startOfDay(subWeeks(new Date(), 4)),
			endDate: endOfDay(new Date()),
		},
	})

	const formContext = useForm<FormData>({
		defaultValues: {
			item: '',
		},
	})

	const handleFormSubmit = async (formData: FormData) => {
		try {
			await api.post('/TodoItems', {
				item: formData.item,
				user: {
					id: Auth.profile()?.id,
				},
			})

			toast({ title: 'To-do added' })
			formContext.reset({ item: '' })

			await Promise.all([refetch(), refetchActiveTodoItems()])
		} catch (error) {
			api.handleError(error)
		}
	}

	return (
		<>
			<SlideoutHeader title="To-do List" />

			<SlideoutContent>
				<div className="grid grid-cols-2 bg-gray-100 dark:bg-gray-800 border-b border-t border-gray-200 dark:border-gray-600">
					<button
						type="button"
						className={clsx(
							'p-2 text-center text-sm font-medium focus:outline-none focus:ring-0 border-b-2',
							view === TAB_VIEW.Active
								? 'text-primary-600 dark:text-primary-400 border-primary-600'
								: 'border-gray-100 dark:border-gray-800'
						)}
						onClick={() => setView(TAB_VIEW.Active)}
					>
						Active Items
					</button>

					<button
						type="button"
						className={clsx(
							'p-2 text-center text-sm font-medium focus:outline-none focus:ring-0 border-b-2',
							view === TAB_VIEW.Completed
								? 'text-primary-600 dark:text-primary-400 border-primary-600'
								: 'border-gray-100 dark:border-gray-800'
						)}
						onClick={() => setView(TAB_VIEW.Completed)}
					>
						Completed Items
					</button>
				</div>

				<div className="flex-1 flex flex-col">
					<Slider index={view} className="flex-1">
						<SlideoutContent>
							<Stack dividers space={0} className="border-b border-gray-200 dark:border-gray-600">
								{activeTodos?.items?.map((todo) => (
									<TodoItem
										key={todo.id}
										todo={todo}
										refetch={() =>
											Promise.all([
												refetch(),
												refetchActiveTodoItems(),
												refetchCompletedTodoItems(),
											])
										}
									/>
								))}

								{!activeTodos?.items.length && activeFetching ? (
									<div className="p-4 flex items-center justify-center">
										<Spinner className="w-5 h-5 animate-spin" />
									</div>
								) : (
									!activeTodos?.items.length && (
										<div className="p-4 text-sm font-medium text-center italic">No items</div>
									)
								)}
							</Stack>
						</SlideoutContent>

						<SlideoutContent>
							<Stack dividers space={0} className="border-b border-gray-200 dark:border-gray-600">
								{completedTodos?.items?.map((todo) => (
									<TodoItem
										key={todo.id}
										todo={todo}
										refetch={() =>
											Promise.all([
												refetch(),
												refetchActiveTodoItems(),
												refetchCompletedTodoItems(),
											])
										}
									/>
								))}

								{!completedTodos?.items.length && completedFetching ? (
									<div className="p-4 flex items-center justify-center">
										<Spinner className="w-5 h-5 animate-spin" />
									</div>
								) : (
									!completedTodos?.items.length && (
										<div className="p-4 text-sm font-medium text-center italic">No items</div>
									)
								)}
							</Stack>
						</SlideoutContent>
					</Slider>
				</div>
			</SlideoutContent>

			<Stack>
				<div className="border-t border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-750 p-4 pb-8">
					<Form context={formContext} onSubmit={handleFormSubmit}>
						<Stack>
							<div className="">Add New To-do</div>

							<FormInput
								name="item"
								placeholder="New to-do..."
								as="textarea"
								validations={{ required: 'You cannot create an empty note' }}
							/>

							<div className="flex items-center justify-end">
								<Actions
									actions={[
										{
											title: 'Save',
											type: 'submit',
											intent: 'save',
											isLoading: formContext.formState.isSubmitting,
										},
									]}
								/>
							</div>
						</Stack>
					</Form>
				</div>
			</Stack>
		</>
	)
}

interface TodoItemProps {
	todo: TodoModel
	refetch: RefetchFn
}

const TodoItem: FC<TodoItemProps> = ({ todo, refetch }) => {
	const completeTodo = async (todo: TodoModel) => {
		try {
			await api.put(`/TodoItems/${todo.id}`, {
				...todo,
				completed: true,
				completedDate: new Date(),
			})

			refetch()
		} catch (error) {
			api.handleError(error)
		}
	}

	const unCompleteTodo = async (todo: TodoModel) => {
		try {
			await api.put(`/TodoItems/${todo.id}`, {
				...todo,
				completed: false,
				completedDate: null,
			})

			refetch()
		} catch (error) {
			api.handleError(error)
		}
	}

	const deleteTodo = async (todo: TodoModel) => {
		confirm({
			title: 'Are you sure you want to delete this to-do item?',
			onAccept: async () => {
				try {
					await api.delete(`/TodoItems/${todo.id}`)

					refetch()
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	return (
		<div className="flex flex-row items-center space-x-4 p-4 px-6 hover:bg-gray-50 dark:hover:bg-gray-750">
			<div className="space-y-2 flex-1">
				<div className="text-sm">{todo.item}</div>
				{todo.completed && todo.completedDate !== undefined ? (
					<div
						className="text-xs text-gray-400 italic select-none"
						title={format(ensureDate(todo.completedDate), 'dd/MM/yyyy  HH:mm:ss')}
					>
						Completed: {fixedFormatRelative(todo.completedDate)}
					</div>
				) : (
					<div
						className="text-xs text-gray-400 italic select-none"
						title={format(ensureDate(todo.created), 'dd/MM/yyyy  HH:mm:ss')}
					>
						{fixedFormatRelative(todo.created)}
					</div>
				)}
			</div>

			<Actions
				actions={[
					{
						icon: <DotsHorizontalIcon className="w-5 h-5" />,
						intent: 'menu',
						rounded: 'full',
						actions: [
							!todo.completed && [
								{
									title: 'Mark as completed',
									icon: <CheckCircleIcon className="w-5 h-5" />,
									intent: 'save',
									onClick: () => completeTodo(todo),
								},
								{
									title: 'Edit To-do item',
									icon: <PencilIcon className="w-5 h-5" />,
									onClick: () => openEditTodo({ todo, refetch }),
								},
							],
							todo.completed && [
								{
									title: 'Mark as uncompleted',
									icon: <XCircleIcon className="w-5 h-5" />,
									onClick: () => unCompleteTodo(todo),
								},
							],
							[
								{
									title: 'Delete To-do',
									icon: <TrashIcon className="w-5 h-5" />,
									intent: 'danger',
									onClick: () => deleteTodo(todo),
								},
							],
						],
					},
				]}
			/>
		</div>
	)
}

export default openTodoListSlideout
