export enum CASE_STATUS {
	Request = 1,
	Pending = 2,
	Open = 3,
	Closed = 4,
	Withdrawn = 5,
	DecisionIssued = 6,
	WithdrawnSettled = 7,
	WithdrawnBeforeCMC = 8,
	WithdrawnBeforeCMCSettled = 9,
	DecisionIssuedDismissed = 11,
	ADRWithdrawnBefore = 12,
	ADRAggreementWithdrawn = 13,
	ADRAggreementNotWithdrawn = 14,
	ADRNoAggreement = 15,
	WithdrawnACCRevokedDecision = 16,
	WithdrawnBeforeCMCACCRevokedDecision = 17,
	DismissedNoJurisdiction = 18,
	ADRSettled = 19,
	DecisionIssuedQuashedDirectionGiven = 20,
	DecisionIssuedQuashedSubstituted = 21,
	DecisionIssuedACCToMakeDecision = 22,
	DecisionIssuedReviewUpheld = 23,
	DecisionIssuedModified = 24,
	DeemedDecision = 25,
	DecisionIssuedMultipleReviewNumberOutcomes = 26,
	Rejected = 27,
}

export const caseStatuses = [
	{ value: 1, label: 'Request' },
	{ value: 2, label: 'Pending' },
	{ value: 3, label: 'Open' },
	{ value: 4, label: 'Closed', deprecated: true },
	{ value: 7, label: 'Withdrawn (Settled)' },
	{ value: 5, label: 'Withdrawn' },
	{ value: 6, label: 'Decision Issued', deprecated: true },
	{ value: 8, label: 'Withdrawn Before CMC' },
	{ value: 9, label: 'Withdrawn Before CMC (Settled)' },
	{ value: 16, label: 'Withdrawn (ACC Revoked Decision)' },
	{ value: 17, label: 'Withdrawn Before CMC (ACC Revoked Decision)' },
	{ value: 18, label: 'Dismissed: No Jurisdiction' },
	{ value: 11, label: 'Decision Issued: Dismissed' },
	{ value: 20, label: 'Decision Issued: Quashed and direction given for ACC to reconsider the decision' },
	{ value: 21, label: 'Decision Issued: Quashed and substituted' },
	{ value: 22, label: 'Decision Issued: ACC to make a decision within the timeframe directed' },
	{ value: 23, label: 'Decision Issued: Review upheld, decision now made' },
	{ value: 24, label: 'Decision Issued: Modified' },
	{ value: 12, label: 'ADR (Withdrawn Before)' },
	{ value: 13, label: 'ADR Agreement (Withdrawn)' },
	{ value: 14, label: 'ADR Agreement (Not Withdrawn)' },
	{ value: 15, label: 'ADR No Agreement' },
	{ value: 19, label: 'ADR Settled (particular Review no. only)' },
	{ value: 25, label: 'Deemed Decision' },
	{ value: 26, label: 'Decision Issued: Multiple Review Number Outcomes' },
	{ value: 27, label: 'Rejected' },
]

export const CaseStatus = {
	readable: (x: CASE_STATUS) => caseStatuses.find((y) => y.value === x)?.label || '-',
	options: caseStatuses.filter((x) => !x.deprecated),
}
