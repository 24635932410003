import { FC } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'

interface FormHiddenProps {
	name: string
	defaultValue?: any
	validations?: RegisterOptions
}

const FormHidden: FC<FormHiddenProps> = ({ name, defaultValue, validations }) => {
	useController({ name, defaultValue, rules: validations })

	return null
}

export default FormHidden
