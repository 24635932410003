import { FC } from 'react'
import { openModal, ModalContent, ModalFooter } from 'hooks/useModal'
import { ReviewModel } from 'types/models'
import { ModalResolveFn } from 'types'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormSelect from 'components/Form/FormSelect'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import { ClaimType } from 'types/enums'
import FormDate from 'components/Form/FormDate'
import Stack from 'components/Stack'
import { ensureDate } from 'utils/funcs'
import { confirm } from 'alerts'
import { addMonths, endOfDay, startOfDay } from 'date-fns'

interface OpenPendingReviewProps {
	review?: Partial<ReviewModel>
}

const openPendingReview = ({ review }: OpenPendingReviewProps) => {
	return openModal<Partial<ReviewModel>>({
		title: review ? 'Edit Reviewddd' : 'Add Review',
		dismissable: true,
		render: (close) => <PendingReview close={close} review={review} />,
	})
}

interface PendingReviewProps {
	close: ModalResolveFn<Partial<ReviewModel>>
	review?: Partial<ReviewModel>
}

interface FormData {
	reviewNumber: string
	issueCode: string
	accDecisionDate: Date
	lodgementDate: Date
}

const PendingReview: FC<PendingReviewProps> = ({ close, review }) => {
	const formContext = useForm<FormData>({
		defaultValues: {
			reviewNumber: review?.reviewNumber || '',
			issueCode: review?.issueCode || '',
			accDecisionDate: review?.accDecisionDate ? ensureDate(review.accDecisionDate) : '',
			lodgementDate: review?.lodgementDate ? ensureDate(review.lodgementDate) : '',
		},
	})

	const handleSubmit = async (formData: Partial<ReviewModel>) => {
		const warnOverdue = formData.accDecisionDate
			? endOfDay(addMonths(ensureDate(formData.accDecisionDate), 3)).valueOf() <
			  startOfDay(ensureDate(formData.lodgementDate)).valueOf()
			: false

		const warnIssueCode = formData.issueCode && ClaimType.getId(formData.issueCode) === 17

		if (warnOverdue) {
			const warnConfirmed = await confirm({
				title: 'Late request!',
				message: (
					<Stack>
						<div>The Lodgement Date for this case is not within 3 months after the ACC Decision Date.</div>
						<div>Please proceed only if ACC have accepted this case as a Late Request.</div>
						<div>Do you wish to proceed?</div>
					</Stack>
				),
				intent: 'primary',
			})

			if (!warnConfirmed) return null
		}

		if (warnIssueCode) {
			const issueConfirmed = await confirm({
				title: 'Confirmation',
				message: (
					<Stack>
						<div>Please confirm with ACC if this case is standard or complex.</div>
					</Stack>
				),
				intent: 'primary',
			})

			if (!issueConfirmed) return null
		}

		close(formData)
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput
						name="reviewNumber"
						label="Review Number"
						defaultValue={review?.reviewNumber || ''}
						validations={{ required: 'Review number is required' }}
					/>

					<FormSelect
						name="issueCode"
						label="Issue Code"
						options={ClaimType.options}
						defaultValue={review?.issueCode || ''}
						validations={{ required: 'Issue Code is required' }}
					/>

					<FormDate
						name="accDecisionDate"
						label="ACC Decision Date"
						inputDisabled
						defaultValue={review?.accDecisionDate ? ensureDate(review.accDecisionDate) : ''}
					/>

					<FormDate
						name="lodgementDate"
						label="Lodgement Date"
						inputDisabled
						defaultValue={review?.lodgementDate ? ensureDate(review.lodgementDate) : ''}
						validations={{
							required: 'Lodgement Date is required',
						}}
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openPendingReview
