import { useState } from 'react'
import clsx from 'clsx'
import { getChannelName, getFullName } from 'utils/funcs'
import useData from 'hooks/useData'
import { Auth } from 'Auth'
import { USER_ROLE } from 'types/enums'
import produce from 'immer'
import useChat from './useChat'
import { ChatAltIcon, CogIcon } from '@heroicons/react/outline'
import openChatSettings from 'modals/openChatSettings'

const MainWindow = () => {
	const [filter, setFilter] = useState('')

	const { data: users = { items: [] } } = useData('/Users', {
		pageSize: 100,
		search: {
			roles: [USER_ROLE.CaseManager, USER_ROLE.Administrator],
		},
	})

	const { localStorageState, setLocalStorageState, channels } = useChat()

	const toggleMin = () => {
		setLocalStorageState(
			produce((draft) => {
				draft.main.min = !draft.main.min
			})
		)
	}

	const openChatWindow = (user_id) => {
		setLocalStorageState(
			produce((draft) => {
				if (draft.chats.find((x) => x.user_id === user_id)) return

				draft.chats.push({
					user_id,
					min: false,
				})
			})
		)
	}

	const getUnreadCount = (user) => {
		let foundChannel = channels.find((x) => x.id === getChannelName({ user1: user.id, user2: Auth.profile().id }))

		if (foundChannel) {
			return foundChannel.unread
		} else {
			return 0
		}
	}

	return (
		<div
			className={clsx(
				'mr-2 rounded-t-lg bg-white dark:bg-gray-900 z-20 shadow overflow-hidden pointer-events-auto',
				!localStorageState.main.min && 'w-64'
			)}
		>
			<div
				className="py-3 px-4 flex justify-between items-center text-sm font-medium select-none cursor-pointer border-b border-gray-200 dark:border-gray-800 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-850"
				onClick={toggleMin}
			>
				{localStorageState.main.min ? (
					<ChatAltIcon className="w-5 h-5" />
				) : (
					<>
						<div>Messaging</div>

						<button
							onClick={(e) => {
								e.stopPropagation()
								openChatSettings()
							}}
						>
							<CogIcon className="w-5 h-5" />
						</button>
					</>
				)}
			</div>

			{!localStorageState.main.min && (
				<div className="flex flex-col h-80">
					<div className="p-2">
						<div className="relative rounded-md shadow-sm">
							<input
								type="text"
								value={filter}
								onChange={(e) => setFilter(e.target.value)}
								placeholder="Filter..."
								className="form-input block w-full pr-10 sm:text-sm sm:leading-5 dark:bg-gray-800 dark:placeholder-gray-400 dark:border-gray-600"
							/>
						</div>
					</div>

					<div className="flex flex-col flex-1 overflow-y-scroll">
						{users.items
							.filter((x) => getFullName(x).toLowerCase().indexOf(filter) > -1)
							.map((user) => (
								<div
									key={user.id}
									className="p-2 flex justify-between items-center  cursor-pointer border-b border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800"
									onClick={() => openChatWindow(user.id)}
								>
									<div>{getFullName(user)}</div>

									<UnreadBadge value={getUnreadCount(user)} />
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	)
}

const UnreadBadge = ({ value }) => {
	return value > 0 ? <div className="bg-gray-500 text-white leading-4 rounded-md px-2 py-0.5">{value}</div> : null
}

export default MainWindow
