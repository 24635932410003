import { Spinner } from 'components/Icon'

const Loader = ({ size = '3x', spin = true, className = '', ...props }) => {
	return (
		<div className={`flex flex-col justify-center items-center p-2 ${className}`} {...props}>
			<Spinner className="w-10 h-10 animate-spin" />
		</div>
	)
}
export default Loader
