import { ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import {
	format,
	getDaysInMonth,
	startOfMonth,
	addDays,
	isSameDay,
	getISODay,
	subDays,
	endOfMonth,
	subMonths,
	addMonths,
	startOfDay,
	getDay,
} from 'date-fns'
import { Spinner } from 'components/Icon'
import { isNotNone, None } from 'types'
import { getReservedDate } from 'utils/funcs'

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
const weekEnds = ['Sat', 'Sun']

export type CalendarSize = 'sm' | 'md' | 'lg'

const isWeekend = (day: Date) => {
	let weekday = getDay(day)
	return weekday === 6 || weekday === 0
}

export interface CalendarSchema<T> {
	render: (x: T, day: Date) => ReactNode
	onClick: ((x: T, day: Date) => void) | None
	className?: (x: T, day: Date) => ClassValue
	size?: CalendarSize
}

interface CalendarItem<T> {
	date: Date
	value: T
}

interface CalendarProps<T> {
	contextDate?: Date
	items: CalendarItem<T>[]
	schema: CalendarSchema<T>
	defaultRender?: (x: Date) => ReactNode
	includeWeekend?: boolean
	isLoading?: boolean
	className?: ClassValue
}

const Calendar = <T,>({
	contextDate = startOfDay(new Date()),
	items,
	schema,
	defaultRender,
	includeWeekend = false,
	isLoading,
	className,
}: CalendarProps<T>) => {
	const preDays = new Array(getISODay(startOfMonth(contextDate)) - 1)
		.fill(null)
		.map((_, i) => subDays(endOfMonth(subMonths(contextDate, 1)), i))
		.filter((x) => includeWeekend || !isWeekend(x))
		.reverse()

	const postDays = new Array(7 - getISODay(endOfMonth(contextDate)))
		.fill(null)
		.map((_, i) => addDays(startOfMonth(addMonths(contextDate, 1)), i))
		.filter((x) => includeWeekend || !isWeekend(x))

	const days = new Array(getDaysInMonth(contextDate))
		.fill(null)
		.map((_, i) => addDays(startOfMonth(contextDate), i))
		.filter((x) => includeWeekend || !isWeekend(x))

	const handleClickDay = (day: Date) => {
		let item = getItemForDay(day)

		if (isNotNone(item)) {
			schema.onClick && schema.onClick(item, day)
		}
	}

	const getItemForDay = (day: Date): T | undefined => {
		return items.find((x) => isSameDay(x.date, day))?.value
	}

	const height = {
		'h-24': !schema.size || schema.size === 'lg',
		'h-18': schema.size === 'md',
		'h-10': schema.size === 'sm',
	}

	return (
		<div className={clsx(className)}>
			<div className={clsx('grid gap-2', includeWeekend ? 'grid-cols-7' : 'grid-cols-5')}>
				{weekDays.map((day, i) => (
					<div key={i} className="col-span-1 p-2 text-sm font-medium text-center">
						{day}
					</div>
				))}

				{includeWeekend &&
					weekEnds.map((day, i) => (
						<div key={i} className="col-span-1 p-2 text-sm font-medium text-center">
							{day}
						</div>
					))}

				{preDays.map((day, i) => (
					<div
						key={i}
						className={clsx(
							'rounded-lg p-2 text-sm font-medium relative flex items-center justify-center bg-gradient-to-b transition-color ease-in-out duration-200 bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50',
							height
						)}
					>
						<div className="absolute top-0 right-0 mt-2 mr-2">
							<div
								className={clsx('flex items-center rounded-full text-gray-400', {
									'w-6 h-6 leading-6 bg-white dark:bg-gray-700 justify-center':
										!schema.size || schema.size === 'lg',
									'w-3 h-3 text-xs justify-end': schema.size === 'sm',
								})}
							>
								{format(day, 'd')}
							</div>
						</div>
					</div>
				))}

				{days.map((day, i) => {
					const item = getItemForDay(day)

					return (
						<button
							key={i}
							type="button"
							className={clsx(
								'rounded-lg p-2 text-sm font-medium relative flex items-center cursor-pointer bg-gradient-to-b transition-color ease-in-out duration-200',
								height,
								'disabled:bg-gray-50 dark:disabled:bg-gray-800 dark:disabled:bg-opacity-50 disabled:pointer-events-none',
								isSameDay(new Date(), day)
									? 'bg-primary-100 hover:bg-primary-200 dark:bg-primary-700 dark:hover:bg-primary-600 dark:bg-opacity-50'
									: isWeekend(day)
									? 'bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50'
									: 'bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-750',
								{
									'justify-center': !schema.size || schema.size === 'md' || schema.size === 'lg',
									'justify-start': schema.size === 'sm',
								},
								schema.className && item && schema.className(item, day)
							)}
							disabled={!!getReservedDate(day) || !schema.onClick}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()

								handleClickDay(day)
							}}
						>
							<div className="absolute top-0 right-0 mt-2 mr-2">
								<div
									className={clsx('flex items-center rounded-full text-gray-400', {
										'w-6 h-6 leading-6 bg-white dark:bg-gray-700 justify-center':
											!schema.size || schema.size === 'lg',
										'w-3 h-3 text-xs justify-end': schema.size === 'sm',
									})}
								>
									{format(day, 'd')}
								</div>
							</div>
							{isLoading ? (
								<div>
									<Spinner className="w-6 h-6 animate-spin" />
								</div>
							) : isNotNone(item) ? (
								schema.render(item, day)
							) : defaultRender ? (
								defaultRender(day)
							) : null}
						</button>
					)
				})}

				{postDays.map((day, i) => (
					<div
						key={i}
						className={clsx(
							'rounded-lg p-2 text-sm font-medium relative flex items-center justify-center bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50',
							height
						)}
					>
						<div className="absolute top-0 right-0 mt-2 mr-2">
							<div
								className={clsx('flex items-center rounded-full text-gray-400', {
									'w-6 h-6 leading-6 bg-white dark:bg-gray-700 justify-center':
										!schema.size || schema.size === 'lg',
									'w-3 h-3 text-xs justify-end': schema.size === 'sm',
								})}
							>
								{format(day, 'd')}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Calendar
