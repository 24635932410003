import { FC, useState } from 'react'
import { openModal, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import Table, { TableSchema } from 'components/Table'
import CustomRoleMenu from 'components/CustomRoleMenu'
import { CheckIcon, KeyIcon } from '@heroicons/react/outline'
import api from 'api'
import { humanFileSize, series } from 'utils/funcs'
import produce from 'immer'
import { toast } from 'components/toast'
import useData from 'hooks/useData'
import ButtonSpacer from 'components/ButtonSpacer'
import { format } from 'date-fns'
import { uniq } from 'ramda'
import { CASE_PARTY_ROLE } from 'types/enums'
import { PaginatedSet, RefetchFn } from 'types'
import { AppFileModel, CaseFileModel, CaseModel } from 'types/models'

interface Abbr {
	[x: number]: string
}

const abbr: Abbr = {
	[CASE_PARTY_ROLE.Applicant]: 'AP',
	[CASE_PARTY_ROLE.Respondent]: 'RS',
	[CASE_PARTY_ROLE.Reviewer]: 'RV',
	[CASE_PARTY_ROLE.Mediator]: 'ME',
	[CASE_PARTY_ROLE.PeerReviewer]: 'PR',
}

interface OpenFileImportProps {
	parent: CaseFileModel
	refetch: RefetchFn
}

const openFileImport = ({ parent, refetch }: OpenFileImportProps) => {
	return openModal({
		title: 'Import Files',
		size: 'lg',
		render: (close) => <FileImport close={close} parent={parent} refetch={refetch} />,
	})
}

interface ImportFile {
	file: AppFileModel
	permissions: CASE_PARTY_ROLE[]
	included: boolean
}

const FileImport: FC<OpenFileImportProps & { close: () => void }> = ({ close, parent, refetch }) => {
	const { data } = useData<PaginatedSet<AppFileModel>>('/AppFiles', { pageSize: 100 }, { suspense: true })

	const { isSaving, setSaving } = useModalState()
	const [files, setFiles] = useState<ImportFile[]>(
		data?.items.map((x) => ({ file: x, permissions: [], included: false })) || []
	)

	const { data: caseData } = useData<CaseModel>(`/Cases/${parent.case.id}`, {}, { suspense: true })

	const handleSubmit = async () => {
		setSaving(true)

		try {
			await series(files, async (file: ImportFile) => {
				if (!file.included) return

				await api.post('/CaseFiles/attach', {
					appFileId: file.file.id,
					parentId: parent.id,
					permissions: uniq(file.permissions.concat([CASE_PARTY_ROLE.CaseManager])),
					case: {
						id: caseData?.id,
					},
				})
			})

			if (refetch) await refetch()

			close()

			toast({ title: 'Files imported' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const schema: TableSchema<ImportFile> = {
		cols: [
			{
				title: '',
				width: 'minmax(auto, max-content)',
				value: (x, i) => (
					<input
						id={`checkbox-${i}`}
						type="checkbox"
						className="form-checkbox text-primary-600 cursor-pointer"
						checked={x.included}
						onChange={() => {
							setFiles(
								produce((draft) => {
									draft[i].included = !draft[i].included
								})
							)
						}}
					/>
				),
			},
			{
				title: 'Name',
				value: (x, i) => (
					<label htmlFor={`checkbox-${i}`} className="min-w-0 w-full truncate cursor-pointer font-medium">
						{x.file.name}
					</label>
				),
				truncate: true,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.file.fileSize),
			},
			{
				title: 'Uploaded',
				value: (x) => format(new Date(x.file.created), 'dd/MM/yyyy'),
			},
			{
				title: 'Permissions',
				width: 'minmax(auto, max-content)',
				value: (x, i) => (
					<div className="flex justify-end w-full">
						{x.included ? (
							<Actions
								actions={[
									{
										title: x.permissions.length !== 0 && (
											<div className="flex items-center -space-x-0.5">
												{x.permissions.map((p, i) => (
													<div
														key={i}
														className="rounded-full w-6 h-6 shadow-solid text-white"
													>
														<div className="rounded-full w-6 h-6 flex items-center justify-center text-xs font-normal bg-primary-100 text-primary-600">
															{abbr[p]}
														</div>
													</div>
												))}
											</div>
										),
										icon: x.permissions.length === 0 && (
											<div className="w-6 h-6 flex items-center justify-center">
												<KeyIcon className="w-5 h-5" />
											</div>
										),
										intent: 'borderless',
										size: 'xs',
										placement: 'bottom-end',
										menu: (
											<CustomRoleMenu
												value={x.permissions}
												onChange={(permissions) =>
													setFiles(
														produce((draft) => {
															draft[i].permissions = permissions
														})
													)
												}
											/>
										),
									},
								]}
							/>
						) : (
							<ButtonSpacer />
						)}
					</div>
				),
			},
		],
	}

	return (
		<>
			<Table items={files} schema={schema} className="-my-px" />

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Import',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: handleSubmit,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openFileImport
