import { FC, useState } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import FileDropper from 'components/FileDropper'
import api from 'api'
import openFileView from 'modals/openFileView'
import { CaseModel, LineItemFileModel, LineItemModel } from 'types/models'
import { RefetchFn } from 'types'
import { confirm, toast } from 'alerts'
import { CheckIcon, DotsHorizontalIcon, EyeIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import Table, { TableSchema } from 'components/Table'
import FormSection from 'components/Form/FormSection'
import TableFrame from 'components/TableFrame'

interface OpenLineItemDocsProps {
	caseData: CaseModel
	lineitem: LineItemModel
	refetch: RefetchFn
}

const openLineitemDocs = (props: OpenLineItemDocsProps) => {
	return openModal({
		title: 'View/Edit line item documents',
		size: 'lg',
		render: (close) => <LineitemDocuments close={close} {...props} />,
	})
}

const LineitemDocuments: FC<OpenLineItemDocsProps & { close: () => void }> = ({
	close,
	caseData,
	lineitem,
	refetch,
}) => {
	const { isSaving, setSaving } = useModalState()

	const [files, setFiles] = useState<File[]>([])

	const addFile = (files: File[]) => {
		setFiles((f) => f.concat(files))
	}

	const removeFile = (file: File) => {
		setFiles((f) => f.filter((x) => x !== file))
	}

	const fileSchema: TableSchema<File> = {
		cols: [
			{
				title: 'File',
				value: (x) => x.name,
			},
		],
		actions: (x) => [
			{
				icon: <XIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => removeFile(x),
			},
		],
	}

	const lineitemFileSchema: TableSchema<LineItemFileModel> = {
		cols: [
			{
				title: 'File',
				value: (x) => x.name,
			},
		],
		actions: (x) => [
			{
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						{
							title: 'View File',
							icon: <EyeIcon className="w-5 h-5" />,
							onClick: () => openFileView({ file: x, endpoint: 'BillingFiles' }),
						},
					],
					[
						{
							title: 'Delete File',
							icon: <TrashIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeRealFile(x),
						},
					],
				],
			},
		],
	}

	const removeRealFile = (file: LineItemFileModel) => {
		confirm({
			title: 'Are you sure you want to delete this document?',
			onAccept: async () => {
				await api.delete(`/BillingFiles/${file.id}`)
				await refetch()
			},
		})
	}

	const handleSubmit = async () => {
		setSaving(true)

		try {
			if (files.length) {
				const filesBody = new FormData()

				filesBody.append('Lineitem.Id', lineitem.id)
				filesBody.append('Case.Id', caseData.id)

				for (let i = 0; i < files.length; i++) {
					filesBody.append('Files', files[i])
				}

				await api.post('/BillingFiles', filesBody)
			}

			toast({ title: 'Line Item updated' })

			close()

			if (refetch) refetch()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<>
			<ModalContent>
				<Stack dividers>
					{lineitem.files.length > 0 && (
						<FormSection title="Existing Documents">
							<Stack>
								{/* <div className="font-medium text-lg">Documents</div> */}
								<TableFrame>
									<Table items={lineitem.files} schema={lineitemFileSchema} className="-my-px" />
								</TableFrame>
							</Stack>
						</FormSection>
					)}

					<FormSection title="New Documents">
						{/* <div className="font-medium text-lg">Upload new documents</div> */}

						<Stack className="mt-2">
							<FileDropper onChange={addFile} />

							{files.length > 0 && (
								<TableFrame>
									<Table items={files} schema={fileSchema} className="-my-px" />
								</TableFrame>
							)}
						</Stack>
					</FormSection>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Submit',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: () => handleSubmit(),
								intent: 'save',
								disabled: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openLineitemDocs
