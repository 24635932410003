import { FC } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { getFullName } from 'utils/funcs'
import { ChevronDownIcon, DotsHorizontalIcon, PencilIcon, PlusIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { CardWrapper } from 'components/Card'
import CardHeader from 'components/CardHeader'
import openFindUser from 'modals/openFindUser'
import { UserModel } from 'types/models'
import Table, { TableSchema } from 'components/Table'
import openUserSlideout from 'slideouts/openUserSlideout'
import UserDefault from 'components/UserDefault'
import UserAvatar from 'components/UserAvatar'
import FormHidden from 'components/Form/FormHidden'
import { RefetchFn } from 'types'
import openSelectUser from 'modals/openSelectUser'
import { CASE_PROCESS, CASE_TYPE, USER_ROLE } from 'types/enums'
import { Auth } from 'Auth'
import { ACCOrgId, FENZOrgId } from 'utils/constants'
import { path } from 'ramda'
import Stack from 'components/Stack'

interface Party {
	user: UserModel
	role: string
}

interface PersonnelProps {
	refetch: RefetchFn
}

const Personnel: FC<PersonnelProps> = ({ refetch }) => {
	const { watch, setValue, errors } = useFormContext()

	const { organization, caseType, process } = watch(['organization', 'parties', 'caseType', 'process'])

	const parties: Party[] = []

	// ======================================================
	// Case Manager
	// ======================================================

	const caseManagerUser = watch('parties.caseManager.user')
	if (caseManagerUser) parties.push({ role: 'Case Manager', user: caseManagerUser })

	const addCaseManager = async () => {
		let newUser = await openSelectUser({
			title: 'Select Case Manager',
			filter: { roles: [USER_ROLE.CaseManager] },
			requireConfirmation: true,
		})

		if (newUser) {
			setValue('parties.caseManager.user', newUser, { shouldValidate: !!errors })
		}
	}

	// ======================================================
	// Applicant
	// ======================================================

	const applicantUser = watch('parties.applicant.user')
	if (applicantUser) parties.push({ role: 'Applicant', user: applicantUser })

	const addApplicant = async () => {
		let newUser = await openFindUser({ title: 'Choose or create an Applicant', requireConfirmation: true })

		if (newUser) {
			setValue('parties.applicant.user', newUser, { shouldValidate: !!errors })
		}
	}

	// ======================================================
	// Advocates
	// ======================================================

	const {
		fields: advocates,
		append: appendAdvocate,
		remove: removeAdvocate,
	} = useFieldArray({
		name: 'parties.applicant.parties',
		keyName: 'key',
	})

	if (advocates.length)
		advocates.forEach((user) => parties.push({ role: 'Applicant Advocate(s) / Counsel', user: user as UserModel }))

	const addAdvocate = async () => {
		let newUser = await openFindUser({ title: 'Choose or create a user', requireConfirmation: true })

		if (newUser) {
			appendAdvocate(newUser)
		}
	}

	// ======================================================
	// Reviewer
	// ======================================================

	const reviewerUser = watch('parties.reviewer.user')
	if (reviewerUser) parties.push({ role: 'Reviewer', user: reviewerUser })

	const addReviewer = async () => {
		let newUser = await openSelectUser({
			title: 'Select Reviewer',
			filter: { roles: [USER_ROLE.Reviewer] },
			requireConfirmation: true,
		})

		if (newUser) {
			setValue('parties.reviewer.user', newUser, { shouldValidate: !!errors })
		}
	}

	// ======================================================
	// Reviewer Parties
	// ======================================================

	const { fields: reviewerParties, /*append: appendReviewerParties,*/ remove: removeReviewerParties } = useFieldArray(
		{
			name: 'parties.reviewer.parties',
			keyName: 'key',
		}
	)

	if (reviewerParties.length)
		reviewerParties.forEach((user) => parties.push({ role: 'Reviewer Support', user: user as UserModel }))

	// const addReviewerParty = async () => {
	// 	let newUser = await openSelectUser({
	// 		title: 'Select Reviewer Support',
	// 		filter: { roles: [USER_ROLE.Reviewer] },
	// 		requireConfirmation: true,
	// 	})

	// 	if (newUser) {
	// 		appendReviewerParties(newUser)
	// 	}
	// }

	// ======================================================
	// Mediator
	// ======================================================

	const mediatorUser = watch('parties.mediator.user')
	if (mediatorUser) parties.push({ role: 'Mediator', user: mediatorUser })

	const addMediator = async () => {
		let newUser = await openSelectUser({
			title: 'Select Mediator',
			filter: { roles: [USER_ROLE.Mediator] },
			requireConfirmation: true,
		})

		if (newUser) {
			setValue('parties.mediator.user', newUser, { shouldValidate: !!errors })
		}
	}

	// ======================================================
	// Mediator Parties
	// ======================================================

	const {
		fields: mediatorParties,
		/*append: appendMediatorParties,*/
		remove: removeMediatorParties,
	} = useFieldArray({
		name: 'parties.mediator.parties',
		keyName: 'key',
	})

	if (mediatorParties.length)
		mediatorParties.forEach((user) => parties.push({ role: 'Mediator Support', user: user as UserModel }))

	// const addMediatorParty = async () => {
	// 	let newUser = await openSelectUser({
	// 		title: 'Select Mediator Support',
	// 		filter: { roles: [USER_ROLE.Mediator] },
	// 		requireConfirmation: true,
	// 	})

	// 	if (newUser) {
	// 		appendMediatorParties(newUser)
	// 	}
	// }

	// ======================================================
	// Peer Reviewer
	// ======================================================

	const peerReviewerUser = watch('parties.peerReviewer.user')
	if (peerReviewerUser) parties.push({ role: 'Peer Reviewer', user: peerReviewerUser })

	const addPeerReviewer = async () => {
		let newUser = await openSelectUser({
			title: 'Select Peer Reviewer',
			filter: { roles: [USER_ROLE.PeerReviewer] },
			requireConfirmation: true,
		})

		if (newUser) {
			setValue('parties.peerReviewer.user', newUser)
		}
	}

	// ======================================================
	// Respondent
	// ======================================================

	const respondentUser = watch('parties.respondent.user')
	if (respondentUser)
		parties.push({
			role: organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent',
			user: respondentUser,
		})

	const addRespondent = async () => {
		let newUser

		if (isFENZ) {
			newUser = await openFindUser({
				title: 'Select Respondent',
				filter: { role: USER_ROLE.Respondent },
				requireConfirmation: true,
			})
		} else {
			newUser = await openSelectUser({
				title: organization?.id === ACCOrgId ? 'Select ACC Review Specialist' : 'Select Respondent',
				filter: { roles: [USER_ROLE.Respondent], organizationId: organization?.id },
				requireConfirmation: true,
			})
		}

		if (newUser) {
			setValue('parties.respondent.user', newUser, { shouldValidate: !!errors })
		}
	}

	// ======================================================
	// Respondent Parties / ACC Review Specialists
	// ======================================================

	const {
		fields: respondentParties,
		append: appendRespondentParties,
		remove: removeRespondentParties,
	} = useFieldArray({
		name: 'parties.respondent.parties',
		keyName: 'key',
	})

	if (respondentParties.length)
		respondentParties.forEach((user) =>
			parties.push({
				role: 'Respondent Advocate(s) / Counsel',
				user: user as UserModel,
			})
		)

	const addRespondentParty = async () => {
		let newUser = await openFindUser({
			title: 'Select Respondent Advocate(s) / Counsel',
			requireConfirmation: true,
		})

		if (newUser) {
			appendRespondentParties(newUser)
		}
	}

	// ======================================================
	// Adjudicator
	// ======================================================

	const adjudicatorUser = watch('parties.adjudicator.user')
	if (adjudicatorUser) parties.push({ role: 'Adjudicator', user: adjudicatorUser })

	const addAdjudicator = async () => {
		let newUser = await openSelectUser({
			title: 'Select Adjudicator',
			filter: { roles: [USER_ROLE.Adjudicator] },
			requireConfirmation: true,
		})

		if (newUser) {
			setValue('parties.adjudicator.user', newUser, { shouldValidate: !!errors })
		}
	}

	const { fields: adjudicatorParties } = useFieldArray({
		name: 'parties.adjudicator.parties',
		keyName: 'key',
	})

	if (adjudicatorParties.length)
		adjudicatorParties.forEach((user) => parties.push({ role: 'Adjudicator Support', user: user as UserModel }))

	// ======================================================
	// Facilitator
	// ======================================================

	const facilitatorUser = watch('parties.facilitator.user')
	if (facilitatorUser) parties.push({ role: 'Facilitator', user: facilitatorUser })

	const addFacilitator = async () => {
		let newUser = await openSelectUser({
			title: 'Select Facilitator',
			filter: { roles: [USER_ROLE.Facilitator] },
			requireConfirmation: true,
		})

		if (newUser) {
			setValue('parties.facilitator.user', newUser, { shouldValidate: !!errors })
		}
	}

	const { fields: facilitatorParties } = useFieldArray({
		name: 'parties.facilitator.parties',
		keyName: 'key',
	})

	if (facilitatorParties.length)
		facilitatorParties.forEach((user) => parties.push({ role: 'Facilitator Support', user: user as UserModel }))

	const schema: TableSchema<Party> = {
		cols: [
			{
				title: '',
				value: (x) =>
					!x.user?.avatarUrl ? (
						<UserDefault name={getFullName(x.user)} className="w-9 h-9" />
					) : (
						<UserAvatar
							className="rounded-full w-9 h-9 object-cover shadow-sm"
							src={x.user?.avatarUrl}
							name={getFullName(x.user)}
						/>
					),
				width: 'minmax(auto, max-content)',
				className: 'pr-0',
				responsive: 'md',
			},
			{
				title: 'Name',
				value: (x) => (
					<div className="flex flex-col items-start">
						<button
							type="button"
							onClick={() => x.user && openUserSlideout({ userId: x.user.id })}
							className="anchor"
						>
							{getFullName(x.user)}
						</button>
						<div>{x.user?.email}</div>
						<div className="sm:hidden">{x.role}</div>
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Phone',
				value: (x) => x.user?.phoneNumber,
			},
			{
				title: 'Role',
				value: (x) => x.role,
				truncate: true,
				responsive: 'sm',
			},
		],
		actions: (x, i) => [
			x.role === 'Case Manager' &&
				Auth.is('Admin', 'FENZAdmin') && {
					title: '',
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						{
							title: 'Change Case Manager',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => addCaseManager(),
						},
					],
				},
			x.role === 'Applicant' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						// {
						// 	title: 'Edit User',
						// 	icon: <UserIcon className="w-5 h-5" />,
						// 	onClick: () => openEditUser({ userId: x.user.id, refetch }),
						// },
						{
							title: 'Change Applicant',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => addApplicant(),
						},
					],
					!advocates.length && [
						{
							title: 'Remove Applicant',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => setValue('parties.applicant.user', null),
						},
					],
				],
			},
			(x.role === 'Respondent' || x.role === 'ACC Review Specialist') && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						// {
						// 	title: 'Edit User',
						// 	icon: <UserIcon className="w-5 h-5" />,
						// 	onClick: () => openEditUser({ userId: x.user.id, refetch }),
						// },
						{
							title: `Change ${x.role}`,
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => addRespondent(),
						},
					],
					!advocates.length && [
						{
							title: `Remove ${x.role}`,
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => setValue('parties.respondent.user', null),
						},
					],
				],
			},
			Auth.is('Admin', 'FENZAdmin') &&
				x.role === 'Reviewer' && {
					title: '',
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						[
							// {
							// 	title: 'Edit User',
							// 	icon: <UserIcon className="w-5 h-5" />,
							// 	onClick: () => openEditUser({ userId: x.user.id, refetch }),
							// },
							{
								title: 'Change Reviewer',
								icon: <PencilIcon className="w-5 h-5" />,
								onClick: () => addReviewer(),
							},
						],
						!reviewerParties.length && [
							{
								title: 'Remove Reviewer',
								icon: <XIcon className="w-5 h-5" />,
								intent: 'danger',
								onClick: () => setValue('parties.reviewer.user', null),
							},
						],
					],
				},
			x.role === 'Mediator' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						// {
						// 	title: 'Edit User',
						// 	icon: <UserIcon className="w-5 h-5" />,
						// 	onClick: () => openEditUser({ userId: x.user.id, refetch }),
						// },
						{
							title: 'Change Mediator',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => addMediator(),
						},
					],
					!mediatorParties.length && [
						{
							title: 'Remove Mediator',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => setValue('parties.mediator.user', null),
						},
					],
				],
			},
			x.role === 'Applicant Advocate(s) / Counsel' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					// [
					// 	{
					// 		title: 'Edit User',
					// 		icon: <UserIcon className="w-5 h-5" />,
					// 		onClick: () => openEditUser({ userId: x.user.id, refetch }),
					// 	},
					// ],
					[
						{
							title: 'Remove Applicant Advocate(s) / Counsel',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeAdvocate(advocates.findIndex((y) => y === x)),
						},
					],
				],
			},
			x.role === 'Reviewer Support' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					// [
					// 	{
					// 		title: 'Edit User',
					// 		icon: <UserIcon className="w-5 h-5" />,
					// 		onClick: () => openEditUser({ userId: x.user.id, refetch }),
					// 	},
					// ],
					[
						{
							title: 'Remove Reviewer Support',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeReviewerParties(reviewerParties.findIndex((y) => y === x)),
						},
					],
				],
			},
			x.role === 'Mediator Support' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					// [
					// 	{
					// 		title: 'Edit User',
					// 		icon: <UserIcon className="w-5 h-5" />,
					// 		onClick: () => openEditUser({ userId: x.user.id, refetch }),
					// 	},
					// ],
					[
						{
							title: 'Remove Mediator Support',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeMediatorParties(mediatorParties.findIndex((y) => y === x)),
						},
					],
				],
			},
			x.role === 'Respondent Advocate(s) / Counsel' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					// [
					// 	{
					// 		title: 'Edit User',
					// 		icon: <UserIcon className="w-5 h-5" />,
					// 		onClick: () => openEditUser({ userId: x.user.id, refetch }),
					// 	},
					// ],
					[
						{
							title: `Remove ${x.role}`,
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeRespondentParties(respondentParties.findIndex((y) => y === x)),
						},
					],
				],
			},
			// FOR FENZ
			x.role === 'Adjudicator' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						{
							title: 'Change Adjudicator',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => addAdjudicator(),
						},
					],
					!adjudicatorParties.length && [
						{
							title: 'Remove Adjudicator',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => setValue('parties.adjudicator.user', null),
						},
					],
				],
			},
			x.role === 'Facilitator' && {
				title: '',
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						{
							title: 'Change Facilitator',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => addFacilitator(),
						},
					],
					!facilitatorParties.length && [
						{
							title: 'Remove Adjudicator',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => setValue('parties.facilitator.user', null),
						},
					],
				],
			},
		],
	}

	const pathedErrors: Object | undefined = path(['parties'], errors)

	const errorMessages = pathedErrors ? Object.values(pathedErrors).map((x) => x.user.message) : []

	const isFENZ = organization?.id === FENZOrgId

	return (
		<Stack>
			{errorMessages.length > 0 && (
				<div className="rounded-md bg-red-50 dark:bg-opacity-25 dark:bg-red-200 p-4 shadow">
					<div className="flex">
						<div className="shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400 dark:text-red-200" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800 dark:text-red-300">
								You are missing {errorMessages.length} of the required parties for this case
							</h3>
							<div className="mt-2 text-sm text-red-700 dark:text-red-200">
								<ul className="list-disc pl-5 space-y-1">
									{errorMessages.map((x) => (
										<li key={x}>{x}</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			)}

			<CardWrapper className="overflow-hidden">
				<CardHeader
					title="Participants"
					actions={[
						{
							title: 'Add Participants',
							icon: <ChevronDownIcon className="w-5 h-5" />,
							iconSide: 'right',
							intent: 'secondary',
							rounded: 'md',
							placement: 'bottom-end',
							actions: [
								!caseManagerUser && {
									title: 'Add Case Manager',
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => addCaseManager(),
								},
								!applicantUser && {
									title: 'Add Applicant',
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => addApplicant(),
								},
								!respondentUser && {
									title: `Add ${
										organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent'
									}`,
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => addRespondent(),
								},
								Auth.is('Admin') &&
									!reviewerUser &&
									!isFENZ &&
									(caseType === CASE_TYPE.Review || caseType === CASE_TYPE.Hybrid) && {
										title: 'Add Reviewer',
										icon: <PlusIcon className="w-5 h-5" />,
										onClick: () => addReviewer(),
									},
								!mediatorUser &&
									((!isFENZ && caseType === CASE_TYPE.Mediation) ||
										caseType === CASE_TYPE.Hybrid ||
										(isFENZ && process === CASE_PROCESS.Mediation)) && {
										title: 'Add Mediator',
										icon: <PlusIcon className="w-5 h-5" />,
										onClick: () => addMediator(),
									},
								!peerReviewerUser && {
									title: 'Add Peer Reviewer',
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => addPeerReviewer(),
								},
								!adjudicatorUser &&
									isFENZ &&
									(process === CASE_PROCESS.Adjudication ||
										process === CASE_PROCESS.FastTrackAdjudication) && {
										title: 'Add Adjudicator',
										icon: <PlusIcon className="w-5 h-5" />,
										onClick: () => addAdjudicator(),
									},
								!facilitatorUser &&
									isFENZ &&
									process === CASE_PROCESS.Facilitation && {
										title: 'Add Facilitator',
										icon: <PlusIcon className="w-5 h-5" />,
										onClick: () => addFacilitator(),
									},
								!!applicantUser && {
									title: 'Add Applicant Advocate(s) / Counsel',
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => addAdvocate(),
								},
								// may want to bring these back some time
								// !!reviewerUser && {
								// 	title: 'Add Reviewer Support',
								// 	icon: <PlusIcon className="w-5 h-5" />,
								// 	onClick: () => addReviewerParty(),
								// },
								// !!mediatorUser && {
								// 	title: 'Add Mediator Support',
								// 	icon: <PlusIcon className="w-5 h-5" />,
								// 	onClick: () => addMediatorParty(),
								// },
								!!respondentUser && {
									title: `Add Respondent Advocate(s) / Counsel`,
									icon: <PlusIcon className="w-5 h-5" />,
									onClick: () => addRespondentParty(),
								},
							],
						},
					]}
				/>

				<FormHidden name="parties.applicant.user" validations={{ required: 'Applicant is required' }} />
				<FormHidden
					name="parties.respondent.user"
					validations={{ required: !isFENZ && 'Respondent is required' }}
				/>
				<FormHidden name="parties.caseManager.user" validations={{ required: 'Case Manager is required' }} />

				<FormHidden
					name="parties.reviewer.user"
					validations={{
						required:
							!isFENZ &&
							(caseType === CASE_TYPE.Review || (caseType === CASE_TYPE.Hybrid && !mediatorUser))
								? 'Reviewer is required'
								: false,
					}}
				/>

				{isFENZ ? // 	validations={{ // 	name="parties.mediator.user" // <FormHidden
				// 		required: process === CASE_PROCESS.Mediation ? 'Mediator is required' : false,
				// 	}}
				// />
				null : (
					<FormHidden
						name="parties.mediator.user"
						validations={{
							required:
								caseType === CASE_TYPE.Mediation || (caseType === CASE_TYPE.Hybrid && !reviewerUser)
									? 'Mediator is required'
									: false,
						}}
					/>
				)}

				{/* for FENZ */}
				{/* <FormHidden
					name="parties.adjudicator.user"
					validations={{
						required:
							isFENZ &&
							(process === CASE_PROCESS.Adjudication || process === CASE_PROCESS.FastTrackAdjudication)
								? 'Adjudicator is required'
								: false,
					}}
				/>
				<FormHidden
					name="parties.facilitator.user"
					validations={{
						required: isFENZ && process === CASE_PROCESS.Facilitation ? 'Facilitator is required' : false,
					}}
				/> */}

				<Table items={parties} schema={schema} className="-my-px" />
			</CardWrapper>
		</Stack>
	)
}

export default Personnel
