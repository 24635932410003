const iframe = document.createElement('iframe')
iframe.src = 'https://resources.icra.co.nz/iframe.html'
iframe.width = '0'
iframe.height = '0'
iframe.style.border = '0'
document.body.appendChild(iframe)

const iFrameReady = new Promise<void>((resolve) => {
	iframe.addEventListener('load', () => resolve())
})

interface ActionType {
	type: string
	payload: any
}

const XLocalStorage = {
	dispatch: async (action: ActionType) => {
		await iFrameReady
		iframe.contentWindow?.postMessage(JSON.stringify(action), '*')
	},
}

export default XLocalStorage
