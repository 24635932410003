import { FC, ReactNode } from 'react'
import ReactSelect from 'react-select'
import clsx, { ClassValue } from 'clsx'
import { XIcon } from '@heroicons/react/outline'

interface Option {
	label: ReactNode
	value: any
}

interface SelectProps {
	value: any
	onChange: (value: any) => void
	onBlur?: () => void
	options: Option[]
	clearable?: boolean
	disabled?: boolean
	placeholder?: string
	className?: ClassValue
}

const Select: FC<SelectProps> = ({
	value,
	onChange,
	onBlur,
	options,
	clearable,
	disabled = false,
	placeholder,
	className,
	onChange: onChangeProp,
}) => {
	return (
		<div className={clsx(className)}>
			<ReactSelect
				options={options}
				value={
					options.find((x) => {
						return (
							x.value === value ||
							x.value === +value ||
							(x.value?.id !== undefined && x.value?.id === value?.id)
						)
					}) || null
				}
				onChange={(option: any) => {
					onChange(option ? option.value : null)
					if (onChangeProp) onChangeProp(option ? option.value : null)
				}}
				onBlur={onBlur}
				// inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
				className="react-select-container"
				classNamePrefix="react-select"
				placeholder={placeholder}
				isClearable={clearable}
				isDisabled={disabled}
				menuPlacement="auto"
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: '#f0f5ff',
						primary: '#5850ec',
					},
				})}
				components={{
					ClearIndicator: ({ hasValue, innerProps: { ref, ...restInnerProps } }) => {
						return hasValue ? (
							<span ref={ref} {...restInnerProps}>
								<XIcon className="mr-8 w-5 h-5 cursor-pointer text-gray-400 hover:text-red-500" />
							</span>
						) : null
					},
					DropdownIndicator: ({ innerProps: { ref, ...restInnerProps } }) => null,
				}}
			/>
		</div>
	)
}

export default Select
