export enum REMINDER_TYPE {
	Minute = 1,
	AdjournmentMinute = 2,
	WithdrawalDecision = 3,
	Decision = 4,
}

export const reminderType = {
	1: 'Minute',
	2: 'Adjournment Minute',
	3: 'Withdrawal Decision',
	4: 'Decision',
}

export const ReminderType = {
	readable: (x: REMINDER_TYPE) => reminderType[x],
	getId: (name: string) => +Object.fromEntries(Object.entries(reminderType).map(([a, b]) => [b, a]))[name],
	options: Object.entries(reminderType).map(([key, val]) => ({ label: val, value: +key })),
}
