import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormToggle from 'components/Form/FormToggle'
import Stack from 'components/Stack'
import { CheckIcon } from '@heroicons/react/outline'
import Actions from 'components/Actions'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { toast } from 'components/toast'
import { HearingModel } from 'types/models'
import { RefetchFn } from 'types'

interface OpenEditHearingSpecialRequirementsProps {
	hearing: HearingModel
	refetch: RefetchFn
}

const openEditHearingSpecialRequirements = ({ hearing, refetch }: OpenEditHearingSpecialRequirementsProps) => {
	return openModal({
		title: 'Edit Special Requirements',
		render: (close) => <SpecialRequirements hearing={hearing} refetch={refetch} close={close} />,
	})
}

interface FormData {
	interpreterRequired: boolean
	accessibility: string
	cultural: string
	security: string
	other: string
}

const SpecialRequirements: FC<OpenEditHearingSpecialRequirementsProps & { close: () => void }> = ({
	hearing,
	refetch,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			accessibility: hearing.accessibility,
			interpreterRequired: hearing.interpreterRequired === true,
			cultural: hearing.cultural,
			security: hearing.security,
			other: hearing.other,
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/CaseHearings/${hearing.id}`, {
				...hearing,
				...formData,
			})

			refetch()

			toast({
				title: 'Updated Special Requirements',
			})

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormToggle name="interpreterRequired" label="Interpreter required" />
					<FormInput name="accessibility" label="Accessibility" autoFocus />
					<FormInput name="cultural" label="Cultural" />
					<FormInput name="security" label="Security" />
					<FormInput name="other" label="Other" as="textarea" />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditHearingSpecialRequirements
