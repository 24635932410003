import { FC } from 'react'
import { useParams } from 'react-router-dom'
import useData from 'hooks/useData'
import { ACCOrgId, FENZOrgId } from 'utils/constants'
import { getFullName } from 'utils/funcs'
import CaseHeader from 'components/CaseHeader'
import PageNavbar from 'components/PageNavbar'
import Table, { TableSchema } from 'components/Table'
import {
	PlusIcon,
	DotsHorizontalIcon,
	PencilIcon,
	UserIcon,
	XIcon,
	ChevronDownIcon,
	CheckIcon,
} from '@heroicons/react/outline'
import SectionHeader from 'components/SectionHeader'
import UserAvatar from 'components/UserAvatar'
import caseLinks from './utils/links'
import { CaseModel, CasePartyModel, UserModel } from 'types/models'
import { CASE_PARTY_ROLE, CASE_PROCESS, CASE_TYPE, USER_ROLE } from 'types/enums'
import UserDefault from 'components/UserDefault'
import openUserSlideout from 'slideouts/openUserSlideout'
import openFindUser from 'modals/openFindUser'
import openEditUser from 'modals/openEditUser'
import api from 'api'
import { confirm } from 'alerts'
import { toast } from 'components/toast'
import { Auth } from 'Auth'
import { History } from 'components/Icon'
import openSelectUser from 'modals/openSelectUser'
import openChangeReviewer from 'modals/openChangeReviewer'
import openCasePartyHistory from 'modals/openCasePartyHistory'
import openInvitation from 'modals/openInvitation'
import openChangeAdjudicator from 'modals/openChangeAdjudicator'
import openChangeFacilitator from 'modals/openChangeFacilitator'
import { isNotNone } from 'types'

interface CaseParty {
	party?: CasePartyModel
	user?: UserModel
	role: string
}

const Participants: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const links = caseData ? caseLinks(caseData) : []

	// console.log(caseData)

	const isFENZ = caseData?.organization?.id === FENZOrgId

	const isLimited = Auth.isCaseRole(caseData, 'Applicant', 'Advocate') || Auth.is('ACCAdmin', 'Respondent')

	const caseParties: CaseParty[] = [
		{ party: caseData?.caseManager, user: caseData?.caseManager?.user, role: 'Case Manager' },
		{ party: caseData?.applicant, user: caseData?.applicant?.user, role: 'Applicant' },
		...(caseData?.applicant?.parties.map((x) => ({
			party: caseData?.applicant,
			user: x,
			role: 'Applicant Advocate(s) / Counsel',
		})) || []),
		{ party: caseData?.reviewer, user: caseData?.reviewer?.user, role: 'Reviewer' },
		...(caseData?.reviewer?.parties.map((x) => ({
			party: caseData?.reviewer,
			user: x,
			role: 'Reviewer Support',
		})) || []),
		{ party: caseData?.mediator, user: caseData?.mediator?.user, role: 'Mediator' },
		...(caseData?.mediator?.parties.map((x) => ({
			party: caseData?.mediator,
			user: x,
			role: 'Mediator Support',
		})) || []),
		{ party: caseData?.peerReviewer, user: caseData?.peerReviewer?.user, role: 'Peer Reviewer' },
		...(caseData?.peerReviewer?.parties.map((x) => ({
			party: caseData?.peerReviewer,
			user: x,
			role: 'Peer Reviewer Support',
		})) || []),
		{
			party: caseData?.respondent,
			user: caseData?.respondent?.user,
			role: caseData?.organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent',
		},
		...(caseData?.respondent?.parties.map((x) => ({
			party: caseData?.respondent,
			user: x,
			role: 'Respondent Advocate(s) / Counsel',
		})) || []),
		{ party: caseData?.adjudicator, user: caseData?.adjudicator?.user, role: 'Adjudicator' },
		{ party: caseData?.facilitator, user: caseData?.facilitator?.user, role: 'Facilitator' },
	].filter((x) => x.user)

	const addCaseManager = async () => {
		caseData &&
			openSelectUser({
				title: 'Select Case Manager',
				filter: {
					roles: [USER_ROLE.CaseManager],
				},
				onAccept: async (user) => {
					if (!user) return

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							userId: user?.id,
							role: CASE_PARTY_ROLE.CaseManager,
							accepted: true,
						})

						toast({ title: 'Case Manager added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Case Manager not added',
							message: 'Something went wrong',
							intent: 'error',
						})
					}
				},
			})
	}

	const changeCaseManager = async (x: CaseParty) => {
		await openSelectUser({
			title: 'Choose a user',
			requireConfirmation: true,
			filter: {
				roles: [USER_ROLE.CaseManager],
			},
			onAccept: async (user) => {
				try {
					await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
						id: x.party?.id,
						role: CASE_PARTY_ROLE.CaseManager,
						user,
						accepted: true,
					})

					toast({ title: 'Case Manager updated' })
					refetch()
				} catch (error) {
					api.handleError(error)
					toast({
						title: 'Case Manager not updated',
						message: 'Something went wrong',
						intent: 'error',
					})
				}
			},
		})
	}

	const addApplicant = async () => {
		await openFindUser({
			title: 'Choose or create a user',
			requireConfirmation: true,
			onAccept: async (user) => {
				try {
					await api.post(`/Cases/${caseData?.id}/party`, {
						role: CASE_PARTY_ROLE.Applicant,
						userId: user?.id,
						accepted: true,
					})

					toast({ title: 'Applicant added' })
					refetch()
				} catch (error) {
					api.handleError(error)
					toast({
						title: 'Applicant not added',
						message: 'Something went wrong',
						intent: 'error',
					})
				}
			},
		})
	}

	const changeApplicant = async (x: CaseParty) => {
		await openFindUser({
			title: 'Choose or create a user',
			requireConfirmation: true,
			onAccept: async (user) => {
				try {
					await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
						id: x.party?.id,
						role: CASE_PARTY_ROLE.Applicant,
						user,
						accepted: true,
					})

					toast({ title: 'Applicant updated' })
					refetch()
				} catch (error) {
					api.handleError(error)
					toast({
						title: 'Applicant not updated',
						message: 'Something went wrong',
						intent: 'error',
					})
				}
			},
		})
	}

	const addSupport = async (field: 'reviewer' | 'mediator' | 'respondent' | 'applicant') => {
		const caseParty = caseData?.[field]

		if (!caseParty) return

		const role =
			field === 'applicant'
				? 'Applicant Advocate(s) / Counsel'
				: field === 'respondent'
				? 'Respondent Advocate(s) / Counsel'
				: `${field[0].toUpperCase() + field.substring(1)} Support`

		const handleUploadUser = async (user: UserModel | null) => {
			if (!user) return

			try {
				await api.post(`/CaseParties/${caseParty.id}/partyuser/${user.id}`)

				toast({ title: `${role} added` })
				refetch()
			} catch (error) {
				api.handleError(error)
				toast({
					title: `${role} not removed`,
					message: 'Something went wrong',
					intent: 'error',
				})
			}
		}

		if (field === 'applicant' || field === 'respondent') {
			await openFindUser({
				title: 'Choose or create a user',
				onAccept: handleUploadUser,
			})
		} else {
			await openSelectUser({
				title: `Select ${role}`,
				filter: {
					roles:
						field === 'reviewer'
							? [USER_ROLE.Reviewer]
							: field === 'mediator'
							? [USER_ROLE.Mediator]
							: field === 'respondent'
							? [USER_ROLE.Respondent]
							: [],
				},
				onAccept: handleUploadUser,
			})
		}
	}

	const removeSupport = async (x: CaseParty) => {
		await confirm({
			title: `Are you sure you want to remove this ${x.role} from this case?`,
			onAccept: async () => {
				try {
					await api.delete(`/CaseParties/${x.party?.id}/partyuser/${x.user?.id}`)

					toast({ title: `${x.role} removed` })
					refetch()
				} catch (error) {
					api.handleError(error)
					toast({
						title: `${x.role} not removed`,
						message: 'Something went wrong',
						intent: 'error',
					})
				}
			},
		})
	}

	const addRespondent = async () => {
		caseData &&
			openSelectUser({
				title: `Select ${caseData?.organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent'}`,
				filter: {
					roles: [USER_ROLE.Respondent],
					organizationId: caseData.organization?.id,
				},
				onAccept: async (user) => {
					if (!user) return

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							role: CASE_PARTY_ROLE.Respondent,
							userId: user?.id,
							accepted: true,
						})

						toast({ title: 'Respondent added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Respondent not added',
							message: 'Something went wrong',
							intent: 'error',
						})
					}
				},
			})
	}

	const changeRespondent = async (x: CaseParty) => {
		caseData &&
			openSelectUser({
				title: `Select ${x.role}`,
				filter: {
					roles: [USER_ROLE.Respondent],
					organizationId: caseData.organization?.id,
				},
				onAccept: async (user) => {
					if (!user) return

					try {
						await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
							id: x.party?.id,
							role: CASE_PARTY_ROLE.Respondent,
							user,
							accepted: true,
						})

						toast({ title: `${x.role} added` })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: `${x.role} not added`,
							message: 'Something went wrong',
							intent: 'error',
						})
					}
				},
			})
	}

	const addReviewer = async () => {
		caseData &&
			openChangeReviewer({
				title: 'Select Reviewer',
				caseData,
				filter: {
					roles: [USER_ROLE.Reviewer],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user, billable } = response

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							userId: user?.id,
							role: CASE_PARTY_ROLE.Reviewer,
							billable,
						})

						toast({ title: 'Reviewer added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Reviewer not added',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const changeReviewer = async (x: CaseParty) => {
		caseData &&
			openChangeReviewer({
				title: 'Select Reviewer',
				caseData,
				filter: {
					roles: [USER_ROLE.Reviewer],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user, billable } = response

					// if (user !== caseData?.reviewer?.user) {
					// 	const invitationAccepted = await openInvitation()
					// 	if (!invitationAccepted) {
					// 		toast({ title: 'Reviewer not updated', intent: 'error' })
					// 		return
					// 	}
					// }

					try {
						await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
							id: x.party?.id,
							role: CASE_PARTY_ROLE.Reviewer,
							user,
							billable,
						})

						toast({ title: 'Reviewer updated' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Reviewer not updated',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const addMediator = async () => {
		caseData &&
			openChangeReviewer({
				title: 'Select Mediator',
				caseData,
				filter: {
					roles: [USER_ROLE.Mediator],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user } = response

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							userId: user?.id,
							role: CASE_PARTY_ROLE.Mediator,
						})

						toast({ title: 'Mediator added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Mediator not added',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const changeMediator = async (x: CaseParty) => {
		caseData &&
			openChangeReviewer({
				title: 'Select Mediator',
				caseData,
				filter: {
					roles: [USER_ROLE.Mediator],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user } = response

					try {
						await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
							id: x.party?.id,
							role: CASE_PARTY_ROLE.Mediator,
							user,
						})

						toast({ title: 'Mediator updated' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Mediator not updated',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const addPeerReviewer = async () => {
		caseData &&
			openChangeReviewer({
				title: 'Select Peer Reviewer',
				caseData,
				filter: {
					roles: [USER_ROLE.PeerReviewer],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user } = response

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							userId: user?.id,
							role: CASE_PARTY_ROLE.PeerReviewer,
						})

						toast({ title: 'Peer Reviewer added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Peer Reviewer not added',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const changePeerReviewer = async (x: CaseParty) => {
		caseData &&
			openChangeReviewer({
				title: 'Select Peer Reviewer',
				caseData,
				filter: {
					roles: [USER_ROLE.PeerReviewer],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user } = response

					try {
						await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
							id: x.party?.id,
							role: CASE_PARTY_ROLE.PeerReviewer,
							user,
						})

						toast({ title: 'Peer Reviewer updated' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Peer Reviewer not updated',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	// FOR FENZ
	const addAdjudicator = async () => {
		caseData &&
			openChangeAdjudicator({
				title: 'Select Adjudicator',
				caseData,
				filter: {
					roles: [USER_ROLE.Adjudicator],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user, billable } = response

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							userId: user?.id,
							role: CASE_PARTY_ROLE.Adjudicator,
							billable,
						})

						toast({ title: 'Adjudicator added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Adjudicator not added',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const removeAdjudicator = async (x: CaseParty) => {
		await confirm({
			title: `Are you sure you want to remove this ${x.role} from this case?`,
			onAccept: async () => {
				try {
					await api.delete(`/CaseParties/${x.party?.id}`)

					toast({ title: `${x.role} removed` })
					refetch()
				} catch (error) {
					api.handleError(error)
					toast({
						title: `${x.role} not removed`,
						message: 'Something went wrong',
						intent: 'error',
					})
				}
			},
		})
	}

	const changeAdjudicator = async (x: CaseParty) => {
		caseData &&
			openChangeAdjudicator({
				title: 'Select Adjudicator',
				caseData,
				filter: {
					roles: [USER_ROLE.Adjudicator],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user, billable } = response

					try {
						await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
							id: x.party?.id,
							role: CASE_PARTY_ROLE.Adjudicator,
							user,
							billable,
						})

						toast({ title: 'Adjudicator updated' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Adjudicator not updated',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const addFacilitator = async () => {
		caseData &&
			openChangeFacilitator({
				title: 'Select Facilitator',
				caseData,
				filter: {
					roles: [USER_ROLE.Facilitator],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user, billable } = response

					try {
						await api.post(`/Cases/${caseData?.id}/party`, {
							userId: user?.id,
							role: CASE_PARTY_ROLE.Facilitator,
							billable,
						})

						toast({ title: 'Facilitator added' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Facilitator not added',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const removeFacilitator = async (x: CaseParty) => {
		await confirm({
			title: `Are you sure you want to remove this ${x.role} from this case?`,
			onAccept: async () => {
				try {
					await api.delete(`/CaseParties/${x.party?.id}`)

					toast({ title: `${x.role} removed` })
					refetch()
				} catch (error) {
					api.handleError(error)
					toast({
						title: `${x.role} not removed`,
						message: 'Something went wrong',
						intent: 'error',
					})
				}
			},
		})
	}

	const changeFacilitator = async (x: CaseParty) => {
		caseData &&
			openChangeFacilitator({
				title: 'Select Facilitator',
				caseData,
				filter: {
					roles: [USER_ROLE.Facilitator],
				},
				onAccept: async (response) => {
					if (!response) return

					const { user, billable } = response

					try {
						await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
							id: x.party?.id,
							role: CASE_PARTY_ROLE.Facilitator,
							user,
							billable,
						})

						toast({ title: 'Facilitator updated' })
						refetch()
					} catch (error) {
						api.handleError(error)
						toast({
							title: 'Facilitator not updated',
							message: 'Something went wrong',
							intent: 'error',
						})
						throw new Error()
					}
				},
			})
	}

	const schema: TableSchema<CaseParty> = {
		cols: [
			{
				title: 'Name',
				value: (x) => (
					<div className="flex flex-row space-x-4 items-center">
						<div className="cursor-pointer">
							{!x.user?.avatarUrl ? (
								<UserDefault name={getFullName(x.user)} className="w-9 h-9" />
							) : (
								<UserAvatar
									className="rounded-full w-9 h-9 object-cover shadow-sm"
									src={x.user?.avatarUrl}
									name={getFullName(x.user)}
								/>
							)}
						</div>
						<div className="flex flex-col items-start">
							{!isLimited ? (
								<button
									onClick={() => x.user && openUserSlideout({ userId: x.user.id })}
									className="anchor"
								>
									{getFullName(x.user)}
								</button>
							) : (
								<div>{getFullName(x.user)}</div>
							)}
							{!isLimited && <div>{x.user?.email}</div>}
						</div>
					</div>
				),
				width: 'minmax(max-content, auto)',
			},
			isLimited && {
				title: 'Email',
				value: (x) => (x.role === 'Case Manager' ? x.user?.email : ''),
				truncate: true,
				responsive: 'md',
			},
			isLimited && {
				title: 'Phone',
				value: (x) => (x.role === 'Case Manager' ? x.user?.phoneNumber : ''),
				truncate: true,
				responsive: 'md',
			},
			Auth.is('CaseManager', 'Admin', 'FENZAdmin') &&
				!isLimited && {
					title: 'Phone',
					value: (x) => x.user?.phoneNumber,
					truncate: true,
					responsive: 'md',
				},
			{
				title: 'Role',
				value: (x) => x.role,
				truncate: true,
				responsive: 'md',
			},
			!isLimited && {
				title: 'Accepted',
				value: (x) =>
					x.role === 'Reviewer' || x.role === 'Mediator' ? (x.party?.accepted ? 'Yes' : 'No') : '-',
				truncate: true,
				responsive: 'md',
			},
		],
		actions: (x) => [
			x.role === 'Case Manager' &&
				Auth.is('Admin', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						[
							{
								title: 'Change Case Manager',
								icon: <PencilIcon className="w-5 h-5" />,
								onClick: () => changeCaseManager(x),
							},
						],
					],
				},
			x.role === 'Applicant' &&
				Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						{
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						{
							title: 'Change Applicant',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changeApplicant(x),
						},
					],
				},

			(x.role === 'Respondent' || x.role === 'ACC Review Specialist') &&
				Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						{
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						{
							title: `Change ${x.role}`,
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changeRespondent(x),
						},
					],
				},

			x.role === 'Reviewer' &&
				(Auth.is('Admin', 'CaseManager', 'FENZAdmin') ||
					(x.user?.id === Auth.profile()?.id && !x.party?.accepted)) && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						x.user?.id === Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') &&
							x.user?.id !== Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment on behalf of Reviewer',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Change Reviewer',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changeReviewer(x),
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Reviewer History',
							icon: <History className="w-5 h-5" />,
							onClick: () =>
								caseData &&
								x.party &&
								openCasePartyHistory({
									caseData,
									partyRole: CASE_PARTY_ROLE.Reviewer,
									field: 'reviewer',
								}),
						},
					],
				},

			x.role === 'Mediator' &&
				(Auth.is('Admin', 'CaseManager', 'FENZAdmin') ||
					(x.user?.id === Auth.profile()?.id && !x.party?.accepted)) && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						x.user?.id === Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') &&
							x.user?.id !== Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment on behalf of Mediator',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Change Mediator',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changeMediator(x),
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Mediator History',
							icon: <History className="w-5 h-5" />,
							onClick: () =>
								caseData &&
								x.party &&
								openCasePartyHistory({
									caseData,
									partyRole: CASE_PARTY_ROLE.Mediator,
									field: 'mediator',
								}),
						},
					],
				},

			x.role === 'Peer Reviewer' &&
				Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						{
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						{
							title: 'Change Peer Reviewer',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changePeerReviewer(x),
						},
						{
							title: 'Peer Reviewer History',
							icon: <History className="w-5 h-5" />,
							onClick: () =>
								caseData &&
								x.party &&
								openCasePartyHistory({
									caseData,
									partyRole: CASE_PARTY_ROLE.PeerReviewer,
									field: 'peerReviewer',
								}),
						},
					],
				},

			(x.role === 'Applicant Advocate(s) / Counsel' ||
				x.role === 'Reviewer Support' ||
				x.role === 'Mediator Support' ||
				x.role === 'Respondent Advocate(s) / Counsel') &&
				Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						{
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						{
							title: `Remove ${x.role}`,
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeSupport(x),
						},
					],
				},

			// for FENZ
			x.role === 'Adjudicator' &&
				(Auth.is('Admin', 'CaseManager', 'FENZAdmin') ||
					(x.user?.id === Auth.profile()?.id && !x.party?.accepted)) && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						x.user?.id === Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') &&
							x.user?.id !== Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment on behalf of Adjudicator',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Change Adjudicator',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changeAdjudicator(x),
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Adjudicator History',
							icon: <History className="w-5 h-5" />,
							onClick: () =>
								caseData &&
								x.party &&
								openCasePartyHistory({
									caseData,
									partyRole: CASE_PARTY_ROLE.Adjudicator,
									field: 'adjudicator',
								}),
						},
						// remove adjudicator for admin only
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Remove Adjudicator',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeAdjudicator(x),
						},
					],
				},

			x.role === 'Facilitator' &&
				(Auth.is('Admin', 'CaseManager', 'FENZAdmin') ||
					(x.user?.id === Auth.profile()?.id && !x.party?.accepted)) && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Edit User',
							icon: <UserIcon className="w-5 h-5" />,
							onClick: () =>
								x.user &&
								openEditUser({
									userId: x.user?.id,
									refetch,
								}),
						},
						x.user?.id === Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') &&
							x.user?.id !== Auth.profile()?.id &&
							!x.party?.accepted && {
								title: 'Accept/Decline Appointment on behalf of Facilitator',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: async () => {
									if (isNotNone(x.party)) {
										openInvitation({
											onSubmit: async (accepted) => {
												try {
													if (!accepted) {
														await api.delete(`/Cases/${caseId}/party/${x.party?.id}`)

														toast({ title: 'Role Invitation Declined' })
													} else {
														await api.put(`/Cases/${caseData?.id}/party/${x.party?.id}`, {
															...x.party,
															accepted: true,
															changeReason: 'Role Accepted',
														})

														toast({ title: 'Role Invitation Accepted' })
													}

													refetch()
												} catch (error) {
													api.handleError(error)
												}
											},
										})
									}
								},
							},
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Change Facilitator',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => changeFacilitator(x),
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Facilitator History',
							icon: <History className="w-5 h-5" />,
							onClick: () =>
								caseData &&
								x.party &&
								openCasePartyHistory({
									caseData,
									partyRole: CASE_PARTY_ROLE.Facilitator,
									field: 'facilitator',
								}),
						},
						// remove facilitator for admin only
						Auth.is('Admin', 'FENZAdmin') && {
							title: 'Remove Facilitator',
							icon: <XIcon className="w-5 h-5" />,
							intent: 'danger',
							onClick: () => removeFacilitator(x),
						},
					],
				},
		],
	}

	const isReview = caseData?.caseType === CASE_TYPE.Review
	const isMediation = caseData?.caseType === CASE_TYPE.Mediation
	const isHybrid = caseData?.caseType === CASE_TYPE.Hybrid

	const missingCaseManager = !caseData?.caseManager
	const missingApplicant = !caseData?.applicant
	const missingRespondent = !caseData?.respondent
	const missingReviewer = (isReview || isHybrid) && !isFENZ && !caseData?.reviewer
	const missingPeerReviewer = (isReview || isHybrid) && !isFENZ && !caseData?.peerReviewer
	const missingMediator =
		(isMediation || isHybrid || (isFENZ && caseData.process === CASE_PROCESS.Mediation)) && !caseData?.mediator
	// for FENZ waiting for API
	const missingAdjudicator =
		isFENZ &&
		// (isReview || isHybrid) &&
		!caseData?.adjudicator &&
		caseData.process === CASE_PROCESS.Adjudication
	const missingFacilitator =
		isFENZ &&
		// (isReview || isHybrid) &&
		!caseData?.facilitator &&
		(caseData.process === CASE_PROCESS.Facilitation || caseData.process === CASE_PROCESS.FastTrackAdjudication)

	const isReviewerOrMediator =
		Auth.isCaseRole(caseData, 'Mediator') || Auth.isCaseRole(caseData, 'Reviewer', 'Adjudicator', 'Facilitator')

	const editable = Auth.is('Admin', 'FENZAdmin') || !Auth.is('Applicant') || isReviewerOrMediator

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<div className="px-6 mb-6">
					<SectionHeader
						title="Participants"
						actions={[
							editable &&
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									title: 'Add Participants',
									icon: <ChevronDownIcon className="w-5 h-5" />,
									iconSide: 'right',
									intent: 'primary',
									rounded: 'md',
									placement: 'bottom-end',
									actions: [
										missingCaseManager &&
											Auth.is('Admin', 'FENZAdmin') && {
												title: 'Add Case Manager',
												icon: <PlusIcon className="w-5 h-5" />,
												onClick: () => addCaseManager(),
											},
										missingApplicant &&
											Auth.is('Admin', 'FENZAdmin') && {
												title: 'Add Applicant',
												icon: <PlusIcon className="w-5 h-5" />,
												onClick: () => addApplicant(),
											},
										missingRespondent &&
											Auth.is('Admin', 'FENZAdmin') && {
												title: `Add ${
													caseData?.organization?.id === ACCOrgId
														? 'ACC Review Specialist'
														: 'Respondent'
												}`,
												icon: <PlusIcon className="w-5 h-5" />,
												onClick: () => addRespondent(),
											},
										missingReviewer &&
											Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
												title: 'Add Reviewer',
												icon: <PlusIcon className="w-5 h-5" />,
												onClick: () => addReviewer(),
											},
										missingMediator && {
											title: 'Add Mediator',
											icon: <PlusIcon className="w-5 h-5" />,
											onClick: () => addMediator(),
										},
										missingPeerReviewer && {
											title: 'Add Peer Reviewer',
											icon: <PlusIcon className="w-5 h-5" />,
											onClick: () => addPeerReviewer(),
										},
										missingAdjudicator &&
											Auth.is('Admin', 'FENZAdmin') && {
												title: 'Add Adjudicator',
												icon: <PlusIcon className="w-5 h-5" />,
												onClick: () => addAdjudicator(),
											},
										missingFacilitator &&
											Auth.is('Admin', 'FENZAdmin') && {
												title: 'Add Facilitator',
												icon: <PlusIcon className="w-5 h-5" />,
												onClick: () => addFacilitator(),
											},
										{
											title: 'Add Applicant Advocate(s) / Counsel',
											icon: <PlusIcon className="w-5 h-5" />,
											onClick: () => addSupport('applicant'),
										},
										// may need these again some day
										// (isReview || isHybrid) &&
										// 	caseData?.reviewer && {
										// 		title: 'Add Reviewer Support',
										// 		icon: <PlusIcon className="w-5 h-5" />,
										// 		onClick: () => addSupport('reviewer'),
										// 	},
										// (isMediation || isHybrid) &&
										// 	caseData?.mediator && {
										// 		title: 'Add Mediator Support',
										// 		icon: <PlusIcon className="w-5 h-5" />,
										// 		onClick: () => addSupport('mediator'),
										// 	},
										{
											title: `Add Respondent Advocate(s) / Counsel`,
											icon: <PlusIcon className="w-5 h-5" />,
											onClick: () => addSupport('respondent'),
										},
									],
								},
						]}
					/>
				</div>

				<div className="-mb-6 overflow-hidden rounded-br-md">
					<Table schema={schema} items={caseParties} className="-mb-px" />
				</div>
			</PageNavbar>
		</>
	)
}

export default Participants
