import { Component } from 'react'
import { Outlet } from 'react-router-dom'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Card from 'components/Card'
import Illustration from 'assets/img/undraw_not_found_60pq.svg'
import { isAxiosError } from 'types'

enum ErrorType {
	NotFound,
	NotAllowed,
}

interface CaseBoundaryState {
	hasError: boolean
	error?: Error
	errorType?: ErrorType
}

class CaseBoundary extends Component<{}, CaseBoundaryState> {
	constructor(props: any) {
		super(props)
		this.state = { hasError: false }
	}

	componentDidCatch(error: Error) {
		if (isAxiosError(error)) {
			if (error.response?.status === 401) {
				this.setState({
					hasError: true,
					errorType: ErrorType.NotAllowed,
					error,
				})
				return
			} else if (error.response?.status === 404) {
				this.setState({
					hasError: true,
					errorType: ErrorType.NotFound,
					error,
				})
				return
			}
		}

		throw error
	}

	// static getDerivedStateFromError(error: Error) {
	// 	return { hasError: true, error }
	// }

	render() {
		if (this.state.hasError) {
			return (
				<>
					<PageHeading
						title={this.state.errorType === ErrorType.NotFound ? 'Case not found' : 'Case restricted'}
					/>

					<PageContent>
						<Card>
							<div className="mx-auto mt-6" style={{ width: '500px' }}>
								<Illustration />
							</div>
						</Card>
					</PageContent>
				</>
			)
		}

		return <Outlet />
	}
}

export default CaseBoundary
