import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import Form from 'components/Form/Form'
import FormCheckbox from 'components/Form/FormCheckbox'
import Stack from 'components/Stack'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import api from 'api'
import { CASE_PARTY_ROLE, CasePartyRole } from 'types/enums'
import { toast } from 'components/toast'
import DataListSingle from 'components/DataListSingle'
import { CaseFileModel } from 'types/models'
import { RefetchFn } from 'types'
import equal from 'deep-equal'

const defaultPerms = {
	Reviewer: true,
	Mediator: true,
	Applicant: true,
	Respondent: true,
	PeerReviewer: false,
	Adjudicator: true,
	Facilitator: true,
}

const allPerms = {
	Reviewer: true,
	Mediator: true,
	Applicant: true,
	Respondent: true,
	PeerReviewer: true,
	Adjudicator: true,
	Facilitator: true,
}

const noPerms = {
	Reviewer: false,
	Mediator: false,
	Applicant: false,
	Respondent: false,
	PeerReviewer: false,
	Adjudicator: false,
	Facilitator: false,
}

const roles = CasePartyRole.options.filter((x) => x.label !== 'Case Manager')

interface OpenFilePermissionsProps {
	file: CaseFileModel
	refetch: RefetchFn
}

const openFilePermissions = ({ file, refetch }: OpenFilePermissionsProps) => {
	return openModal({
		title: 'Change File Permissions',
		render: (close) => <FilePermissions close={close} file={file} refetch={refetch} />,
	})
}

interface FilePermissionsProps {
	file: CaseFileModel
	refetch: RefetchFn
	close: (answer?: boolean | undefined) => void
}

interface FormData {
	permissions: {
		[x: string]: boolean
	}
}

const FilePermissions: FC<FilePermissionsProps> = ({ close, file, refetch }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm({
		defaultValues: {
			permissions: roles.reduce((list, role) => {
				return {
					...list,
					[CASE_PARTY_ROLE[role.value]]: file.permissions.indexOf(role.value) > -1,
				}
			}, {}),
		},
	})

	const permissions = formContext.watch('permissions')

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			const permissions = Object.entries(formData.permissions)
				.filter(([_, include]) => include)
				// @ts-ignore
				.map(([name]) => CASE_PARTY_ROLE[name])

			await api.put(`/CaseFiles/${file.id}`, {
				...file,
				// name: removeFileExtension(file.name),
				permissions: [CASE_PARTY_ROLE.CaseManager].concat(permissions),
			})

			refetch()
			close()
			toast({ title: 'Permissions updated' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	console.log(file)

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<DataListSingle
					items={[
						{
							title: 'File',
							value: file.name,
						},
						{
							title: 'Set:',
							value: (
								<Actions
									actions={[
										{
											title: 'Default',
											intent: 'secondary',
											icon: !equal(permissions, defaultPerms) ? (
												<XIcon className="w-5 h-5" />
											) : (
												<CheckIcon className="w-5 h-5" />
											),
											size: 'xs',
											onClick: () => {
												formContext.setValue(
													'permissions',
													equal(permissions, defaultPerms) ? noPerms : defaultPerms
												)
											},
										},
										{
											title: 'All',
											intent: 'secondary',
											icon: !equal(permissions, allPerms) ? (
												<XIcon className="w-5 h-5" />
											) : (
												<CheckIcon className="w-5 h-5" />
											),
											size: 'xs',
											onClick: () => {
												formContext.setValue(
													'permissions',
													equal(permissions, allPerms) ? noPerms : allPerms
												)
											},
										},
									]}
								/>
							),
						},
						{
							title: 'Permissions',
							value: (
								<div className="flex">
									<Stack className="w-1/2">
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.Reviewer)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.Reviewer]}`}
										/>
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.Applicant)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.Applicant]}`}
										/>
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.PeerReviewer)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.PeerReviewer]}`}
										/>
									</Stack>

									<Stack className="w-1/2">
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.Mediator)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.Mediator]}`}
										/>
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.Respondent)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.Respondent]}`}
										/>
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.Facilitator)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.Facilitator]}`}
										/>
										<FormCheckbox
											label={CasePartyRole.readable(CASE_PARTY_ROLE.Adjudicator)}
											name={`permissions.${CASE_PARTY_ROLE[CASE_PARTY_ROLE.Adjudicator]}`}
										/>
									</Stack>
								</div>
							),
						},
					]}
				/>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openFilePermissions
