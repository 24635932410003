export enum CARE_INDICATION {
	Normal = 1,
	SecurityGuardRequired = 2,
}

export const careIndication = {
	1: 'Normal Meeting',
	2: 'Security Guard Required',
}

export const CareIndication = {
	readable: (x: CARE_INDICATION) => careIndication[x],
	options: Object.entries(careIndication).map(([_, val]) => ({ label: val, value: val })),
}
