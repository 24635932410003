import axiosLib, { AxiosRequestConfig } from 'axios'
import { isPast } from 'date-fns'
import storage from 'utils/storage'
import { warning } from 'alerts'
import { getErrorMessage, reportError } from 'utils/funcs'
import conf, { env } from 'utils/config'
import { isAxiosError } from 'types'
import { getToken } from 'utils/refresh'

export const basicApi = axiosLib.create({
	baseURL: conf.url,

	headers: {
		Registry: 'nzdrc-icra.azurewebsites.net',
	},
})

const api = axiosLib.create({
	baseURL: conf.url,

	headers: {
		Registry: 'nzdrc-icra.azurewebsites.net',
	},
})

api.interceptors.request.use(async (config) => {
	const exp = storage.getNumber('expiry')
	const token = storage.getString('token')
	const refresh = storage.getString('refresh')

	if (exp && isPast(new Date(exp)) && token && refresh) {
		await getToken(config)
	} else {
		if (config.headers) config.headers.Authorization = `Bearer ${token}`
	}

	return config
})

export const axios = api

export const rawApi = {
	get: async (url: string, options?: AxiosRequestConfig) => {
		try {
			return await basicApi.get(url, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, options }
			throw error
		}
	},
	delete: async (url: string, options?: AxiosRequestConfig) => {
		try {
			return await basicApi.delete(url, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, options }
			throw error
		}
	},
	post: async (url: string, data: any = {}, options?: AxiosRequestConfig) => {
		try {
			return await basicApi.post(url, data, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, data, options }
			throw error
		}
	},
	put: async (url: string, data: any, options?: AxiosRequestConfig) => {
		try {
			return await basicApi.put(url, data, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, data, options }
			throw error
		}
	},
	handleError: (error: unknown, text?: string, report = true) => {
		if (isAxiosError(error)) {
			// @ts-ignore
			if (report && error.response?.status) reportError(error.request, error)

			let message = text || getErrorMessage(error)

			if (env === 'dev') {
				console.dir(error)
			}

			warning({
				title: 'An error occurred',
				message,
			})
		}
	},
}

const ProxyApi = {
	get: async <T>(url: string, options?: AxiosRequestConfig) => {
		try {
			return await api.get<T>(url, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, options }
			throw error
		}
	},
	delete: async (url: string, options?: AxiosRequestConfig) => {
		try {
			return await api.delete(url, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, options }
			throw error
		}
	},
	post: async (url: string, data: any = {}, options?: AxiosRequestConfig) => {
		try {
			return await api.post(url, data, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, data, options }
			throw error
		}
	},
	put: async (url: string, data: any, options?: AxiosRequestConfig) => {
		try {
			return await api.put(url, data, options)
		} catch (error) {
			// @ts-ignore
			error.request = { url, data, options }
			throw error
		}
	},
	handleError: (error: unknown, text?: string, report = true) => {
		if (isAxiosError(error)) {
			// @ts-ignore
			if (report && error.response?.status) reportError(error.request, error)

			let message = text || getErrorMessage(error)

			if (env === 'dev') {
				console.dir(error)
			}

			warning({
				title: 'An error occurred',
				message,
			})
		}
	},
}

export default ProxyApi
