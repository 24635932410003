import { useMemo, useEffect, FC, useState } from 'react'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormDate from 'components/Form/FormDate'
import FormSelect from 'components/Form/FormSelect'
import FormCheckbox from 'components/Form/FormCheckbox'
import FormToggle from 'components/Form/FormToggle'
import { includer, getFullName, series, getDateFromTime, ensureDate, nzDate, isComplexCase } from 'utils/funcs'
import { getPartiesAsBools, getPartyUserAsBool } from 'pages/Cases/PendingCase/utils/ValidationSchema'
import {
	getMinutes,
	addMinutes,
	subMinutes,
	setMinutes,
	getHours,
	setHours,
	format,
	differenceInMinutes,
	parseISO,
	subDays,
	addDays,
	endOfDay,
} from 'date-fns'
import api from 'api'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import AutoHeight from 'components/AutoHeight'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { CheckIcon } from '@heroicons/react/outline'
import { confirm } from 'alerts'
import { toast } from 'components/toast'
import {
	HEARING_TYPE,
	FORUM_TYPE,
	CASE_TYPE,
	Duration,
	HearingType,
	ForumType,
	TimeSlots,
	HEARING_STATUS,
	ADR_TYPE,
} from 'types/enums'
import { CaseModel, CasePartyModel, HearingModel, ReviewModel, UserModel } from 'types/models'
import { isNotNone, ModalResolveFn, RefetchFn } from 'types'
import { useForm } from 'react-hook-form'
import Table, { TableSchema } from 'components/Table'
import FormSection from 'components/Form/FormSection'
import UserDefault from 'components/UserDefault'
import UserAvatar from 'components/UserAvatar'
import { ACCOrgId, FENZOrgId } from 'utils/constants'
import TableFrame from 'components/TableFrame'
import * as Sentry from '@sentry/react'
import { gql, useQuery } from '@apollo/client'
import { GQLConnection, GQLVenueType } from 'types/gql'

const venuesQuery = gql`
	query {
		Venues {
			items {
				id
				name
				address
			}
		}
	}
`

const endOfToday = getDateFromTime('17:00')

const createHearingObject = (caseData: CaseModel, hearing?: HearingModel) => {
	return {
		description: hearing?.description || 'description',
		hearingType: hearing?.hearingType || HEARING_TYPE.CMC,
		forumType: hearing?.forumType,
		venue: hearing?.venue || '',
		venueId: hearing?.venue && !hearing?.venueId ? 'custom' : hearing?.venueId || '',
		startDate: hearing?.startDate ? ensureDate(hearing?.startDate) : undefined,
		time: hearing?.startDate
			? TimeSlots.find(
					(x) => x.from === format(nzDate(parseISO(hearing?.startDate || '')), 'h:mm a').toLowerCase()
			  )?.from
			: null,
		duration: differenceInMinutes(parseISO(hearing?.endDate || ''), parseISO(hearing?.startDate || '')),
		accessibility: hearing?.accessibility || '',
		interpreterRequired: hearing?.interpreterRequired || false,
		cultural: hearing?.cultural || '',
		security: hearing?.security || '',
		other: hearing?.other || '',
		roomBooked: hearing?.roomBooked || false,
		travelBooked: hearing?.travelBooked || false,
		roomHireDetails: hearing?.roomHireDetails || '',
		travelArrangements: hearing?.travelArrangements || '',
		agreeToMediateApplicant: hearing?.agreeToMediateApplicant || false,
		agreeToMediateDefendant: hearing?.agreeToMediateDefendant || false,
		notificationsEnabled: hearing?.notificationsEnabled !== undefined ? !!hearing?.notificationsEnabled : true,
		status: hearing?.status || 1,
		attendees: {
			applicant: {
				user: getPartyUserAsBool('applicant', hearing, caseData),
				parties: getPartiesAsBools('applicant', hearing, caseData),
			},
			mediator: {
				user: getPartyUserAsBool('mediator', hearing, caseData),
				parties: getPartiesAsBools('mediator', hearing, caseData),
			},
			reviewer: {
				user: getPartyUserAsBool('reviewer', hearing, caseData),
				parties: getPartiesAsBools('reviewer', hearing, caseData),
			},
			peerReviewer: {
				user: getPartyUserAsBool('peerReviewer', hearing, caseData),
				parties: getPartiesAsBools('peerReviewer', hearing, caseData),
			},
			caseManager: {
				user: getPartyUserAsBool('caseManager', hearing, caseData),
				parties: [],
			},
			respondent: {
				user: getPartyUserAsBool('respondent', hearing, caseData),
				parties: getPartiesAsBools('respondent', hearing, caseData),
			},
		},
		parties: {
			caseManager: caseData.caseManager,
			applicant: caseData.applicant,
			mediator: caseData.mediator,
			reviewer: caseData.reviewer,
			peerReviewer: caseData.peerReviewer,
			respondent: caseData.respondent,
		},
		reviews: hearing?.reviews
			? hearing?.reviews.map((x) => x.id).reduce((obj, id) => ({ ...obj, [id]: true }), {})
			: caseData.reviews && caseData.reviews.length > 0
			? caseData.reviews.map((x) => x.id).reduce((obj, id) => ({ ...obj, [id]: true }), {})
			: {},
	}
}

interface OpenCreateHearingProps {
	caseData: CaseModel
	currentHearing?: HearingModel
	refetch: RefetchFn
}

const openCreateHearing = ({ caseData, currentHearing, refetch }: OpenCreateHearingProps) => {
	return openModal({
		title: 'Create Conference',
		size: 'xl',
		render: (close) => (
			<CreateHearing caseData={caseData} refetch={refetch} currentHearing={currentHearing} close={close} />
		),
	})
}

type Attendee = { user: UserModel | undefined; path: string; role: string }

type Attendees = {
	applicant: {
		user: boolean
		parties: boolean[]
	}
	mediator: {
		user: boolean
		parties: boolean[]
	}
	reviewer: {
		user: boolean
		parties: boolean[]
	}
	peerReviewer: {
		user: boolean
		parties: boolean[]
	}
	caseManager: {
		user: boolean
		parties: boolean[]
	}
	respondent: {
		user: boolean
		parties: boolean[]
	}
}

type RealAttendees = {
	applicant: {
		user: UserModel
		parties: UserModel[]
	}
	mediator: {
		user: UserModel
		parties: UserModel[]
	}
	reviewer: {
		user: UserModel
		parties: UserModel[]
	}
	peerReviewer: {
		user: UserModel
		parties: UserModel[]
	}
	caseManager: {
		user: UserModel
		parties: UserModel[]
	}
	respondent: {
		user: UserModel
		parties: UserModel[]
	}
}

interface FormData {
	description: string
	hearingType: HEARING_TYPE
	forumType: FORUM_TYPE
	venue: string
	venueId?: string
	startDate: Date
	time: string | null
	duration: number
	accessibility: string
	interpreterRequired: boolean
	cultural: string
	security: string
	other: string
	roomBooked: boolean
	travelBooked: boolean
	roomHireDetails: string
	travelArrangements: string
	agreeToMediateApplicant: boolean
	agreeToMediateDefendant: boolean
	status: HEARING_STATUS
	reviews: { [id: string]: boolean }
	attendees: Attendees
	notificationsEnabled: boolean
	parties: {
		caseManager: CasePartyModel | undefined
		applicant: CasePartyModel | undefined
		mediator: CasePartyModel | undefined
		reviewer: CasePartyModel | undefined
		peerReviewer: CasePartyModel | undefined
		respondent: CasePartyModel | undefined
	}
}

const CreateHearing: FC<OpenCreateHearingProps & { close: ModalResolveFn<HearingModel> }> = ({
	caseData,
	refetch,
	currentHearing,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const { data: venues } = useQuery<{ Venues: GQLConnection<GQLVenueType> }>(venuesQuery)

	const isFENZ = caseData?.organization?.id === FENZOrgId

	const formContext = useForm<FormData>({
		defaultValues: currentHearing
			? createHearingObject(caseData, currentHearing)
			: createHearingObject(caseData, {
					attendees: [caseData.applicant?.user].filter(isNotNone),
			  } as HearingModel),
	})

	const { watch, setValue } = formContext

	const { parties, hearingType, duration, startDate, time, forumType, roomBooked, travelBooked, venueId } = watch([
		'parties',
		'hearingType',
		'duration',
		'startDate',
		'time',
		'forumType',
		'roomBooked',
		'travelBooked',
		'venueId',
	])

	// Times
	let times = useMemo(() => {
		if (!duration) {
			return TimeSlots
		} else {
			let endPoint = +subMinutes(endOfToday, duration)
			return TimeSlots.filter((x) => +getDateFromTime(x.from24) <= endPoint)
		}
	}, [duration])

	// unset time if it becomes invalid
	useEffect(() => {
		if (time && !times.find((x) => x.from === time)) {
			setValue('time', null)
			setValue('duration', 0)
		}
	}, [time, times, setValue])

	const [reported, setReported] = useState(false)

	// Durations
	let durations = useMemo(() => {
		let list: { label: string; value: number }[] = []

		if (+hearingType === HEARING_TYPE.CMC) list = Duration.CMC.options
		if (+hearingType === HEARING_TYPE.Review) list = Duration.Review.options
		if (+hearingType === HEARING_TYPE.Mediation) list = Duration.Mediation.options

		list = list.filter((x) => {
			if (!time) return true
			let from24 = TimeSlots.find((x) => x.from === time)?.from24
			if (from24) {
				let endPoint = addMinutes(getDateFromTime(from24), +x.value)
				return endPoint.valueOf() <= endOfToday.valueOf()
			}
			return true
		})

		if (!reported) {
			if (list.length === 0 && currentHearing) {
				Sentry.captureMessage('Missing Duration', (scope) => {
					scope.setExtra('hearing', currentHearing)
					scope.setExtra('time', new Date().toISOString())
					return scope
				})
				setReported(true)
			}
		}

		return list
	}, [currentHearing, reported, hearingType, time])

	// unset the duration if it disappears from the list
	useEffect(() => {
		if (duration && !durations?.find((x) => x.value === duration)) {
			setValue('duration', 0)
		}
	}, [durations, duration, setValue])

	const attendees: Attendee[] = [
		{ user: parties.applicant?.user, path: 'applicant.user', role: 'Applicant' },
		...(parties.applicant?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `applicant.parties.${i}`,
			role: 'Advocate',
		})) || []),
		{ user: parties.reviewer?.user, path: 'reviewer.user', role: 'Reviewer' },
		...(parties.reviewer?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `reviewer.parties.${i}`,
			role: 'Reviewer support',
		})) || []),
		{ user: parties.mediator?.user, path: 'mediator.user', role: 'Mediator' },
		...(parties.mediator?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `mediator.parties.${i}`,
			role: 'Mediator support',
		})) || []),
		{ user: parties.peerReviewer?.user, path: 'peerReviewer.user', role: 'Peer Reviewer' },
		{
			user: parties.respondent?.user,
			path: 'respondent.user',
			role: caseData?.organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent',
		},
		...(parties.respondent?.parties?.map((x: UserModel, i: number) => ({
			user: x,
			path: `respondent.parties.${i}`,
			role: 'Respondent support',
		})) || []),
	].filter((x) => isNotNone(x.user)) as Attendee[]

	const attendeeSchema: TableSchema<Attendee> = {
		cols: [
			{
				title: 'Name',
				value: (x) => (
					<FormCheckbox
						name={`attendees.${x.path}`}
						label={
							<div className="flex flex-row space-x-4 items-center pl-2">
								<div className="cursor-pointer">
									{!x.user?.avatarUrl ? (
										<UserDefault name={getFullName(x.user)} className="w-9 h-9" />
									) : (
										<UserAvatar
											className="rounded-full w-9 h-9 object-cover shadow-sm"
											src={x.user?.avatarUrl}
											name={getFullName(x.user)}
										/>
									)}
								</div>
								<div className="flex flex-col items-start">
									<div>{getFullName(x.user)}</div>
									<div className="text-gray-400 font-normal">{x.user?.email}</div>
								</div>
							</div>
						}
						className="flex items-center"
					/>
				),
				className: 'font-medium',
			},
			{
				title: 'Role',
				value: (x) => x.role,
			},
		],
	}

	const reviewSchema: TableSchema<ReviewModel> = {
		cols: [
			{
				title: 'Review #',
				value: (x) => (
					<FormCheckbox name={`reviews.${x.id}`} label={x.reviewNumber} className="flex items-center" />
				),
				className: 'font-medium',
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Issue Code',
				value: (x) => x.issueCode,
				truncate: true,
			},
			{
				title: 'Lodgement Date',
				value: (x) =>
					x.lodgementDate
						? format(
								typeof x.lodgementDate === 'string' ? parseISO(x.lodgementDate) : x.lodgementDate,
								'dd/MM/yyyy'
						  )
						: '-',
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Decision Date',
				value: (x) =>
					x.accDecisionDate
						? format(
								typeof x.accDecisionDate === 'string' ? parseISO(x.accDecisionDate) : x.accDecisionDate,
								'dd/MM/yyyy'
						  )
						: '-',
				width: 'minmax(auto, max-content)',
			},
		],
	}

	const handleSubmit = async (formData: FormData) => {
		try {
			const { parties, reviews, ...hearing } = formData

			setSaving(true)

			let att: { [name: string]: CasePartyModel | undefined } = {
				applicant: caseData.applicant,
				caseManager: caseData.caseManager,
				respondent: caseData.respondent,
			}

			if (caseData.reviewer) att.reviewer = caseData.reviewer
			if (caseData.mediator) att.mediator = caseData.mediator

			let hearingAttendees: Partial<Attendees> = {
				applicant: hearing.attendees.applicant,
				caseManager: hearing.attendees.caseManager,
				respondent: hearing.attendees.respondent,
			}

			if (caseData.reviewer) hearingAttendees.reviewer = hearing.attendees.reviewer
			if (caseData.mediator) hearingAttendees.mediator = hearing.attendees.mediator

			const attendees = Object.values(includer(att, hearingAttendees) as Partial<RealAttendees>)
				.reduce<UserModel[]>((list, val) => {
					if (!isNotNone(val)) return list
					return [...list, val.user, ...(val.parties || [])]
				}, [])
				.filter(isNotNone)

			let startDate = null
			let endDate = null

			if (hearing.forumType === FORUM_TYPE.OnThePapers) {
				startDate = endOfDay(hearing.startDate)
				endDate = endOfDay(hearing.startDate)
			} else {
				let fromDate = new Date(`1970-01-01 ${hearing.time}`)
				startDate = setMinutes(setHours(hearing.startDate, getHours(fromDate)), getMinutes(fromDate))
				endDate = addMinutes(startDate, hearing.duration)
			}

			let decisionDate = addDays(startDate, 28)

			let adrType = undefined
			if (hearing.hearingType === HEARING_TYPE.Mediation) {
				adrType = isComplexCase(caseData) ? ADR_TYPE.MultiIssue : ADR_TYPE.Standard
			}

			const body = {
				...hearing,
				attendees,
				startDate,
				endDate,
				decisionDate,
				adrType,
				peerReviewDate: hearing.hearingType === HEARING_TYPE.Review ? subDays(decisionDate, 10) : null,
				case: { id: caseData.id },
				name: HearingType.readable(hearing.hearingType),
				status: 1,
				isPostponed: false,
				hearingVenue: !hearing.venueId || hearing.venueId === 'custom' ? undefined : { id: hearing.venueId },
			}

			let halt = false

			// check availability of reviewer
			await series(Object.values(attendees), async (attendee) => {
				if (attendee.id === caseData?.reviewer?.user?.id) {
					if (!body.startDate || !body.endDate) return

					if (formData.forumType !== FORUM_TYPE.OnThePapers) {
						const { data: isAvailabile } = await api.get(
							`/CaseHearings/Availability?userId=${attendee.id}&startDate=${format(
								body.startDate,
								"yyyy-MM-dd'T'HH:mm:ss"
							)}&endDate=${format(body.endDate, "yyyy-MM-dd'T'HH:mm:ss")}`
						)

						if (!isAvailabile) {
							const override = await confirm({
								title: 'Availability collision',
								message: (
									<Stack space={2}>
										<div>{getFullName(attendee)} is not available during the time allocated.</div>
										<div>Do you want to proceed anyway?</div>
									</Stack>
								),
							})

							if (!override) {
								halt = true
							}
						}
					}
				}
			})

			if (halt) {
				setSaving(false)
				return
			}

			const { data: newHearing } = await api.post('/CaseHearings', body)

			// update case type if necessary
			let existingCaseTypes = caseData.hearings.map((x) => x.hearingType).concat([hearing.hearingType])

			if (
				(existingCaseTypes.indexOf(HEARING_TYPE.Review) > -1 ||
					existingCaseTypes.indexOf(HEARING_TYPE.CMC) > -1) &&
				existingCaseTypes.indexOf(HEARING_TYPE.Mediation) > -1
			) {
				await api.put(`/Cases/${caseData.id}`, {
					...caseData,
					caseType: CASE_TYPE.Hybrid,
				})
			}

			await series(caseData.reviews, async (review) => {
				// if the original hearing had this review
				// and the new one does
				if (review?.id && reviews[review.id]) {
					// then remove review
					await api.post(`/CaseHearings/${newHearing.id}/review/${review.id}`)
				}
			})

			refetch()

			toast({
				title: 'Conference created!',
			})

			close(newHearing)
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<>
			<Form context={formContext} onSubmit={handleSubmit}>
				<ModalContent>
					<Stack space={8} dividers>
						<FormSection title="Details" subtitle="When and Where the meeting is">
							<Stack>
								<div className="flex space-x-6">
									<FormSelect
										name="hearingType"
										label="Type"
										options={HearingType.options}
										className="flex-1"
										validations={{ required: 'Type is required' }}
										autoFocus
									/>

									<FormDate
										name="startDate"
										label="Date"
										className="flex-1"
										validations={{ required: 'Date is required' }}
										defaultValue={currentHearing?.startDate || null}
										disableHolidays
									/>
								</div>

								<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
									<div className="flex space-x-6">
										<FormSelect
											name="time"
											label="Time"
											options={times.map((x) => ({ label: x.from, value: x.from }))}
											disabled={!startDate}
											className="flex-1"
											validations={{ required: 'Time is required' }}
										/>

										<FormSelect
											name="duration"
											label="Duration"
											options={durations || []}
											disabled={!time}
											className="flex-1"
											validations={{ required: 'Duration is required' }}
										/>
									</div>
								</AutoHeight>

								<FormSelect
									name="forumType"
									label="Forum"
									options={ForumType.options}
									validations={{ required: 'Forum is required' }}
									defaultValue={currentHearing?.forumType || ''}
								/>

								<AutoHeight show={forumType === FORUM_TYPE.FaceToFace}>
									<FormSelect
										name="venueId"
										label="Venue"
										options={
											venues?.Venues.items
												?.concat({ id: 'custom', name: 'Custom' })
												?.map((x) => ({
													value: x.id,
													label: (
														<div>
															<div>{x.name}</div>
															{x.address && (
																<div className="opacity-50 text-sm">{x.address}</div>
															)}
														</div>
													),
												})) || []
										}
										validations={{ required: 'Venue is required' }}
										defaultValue={currentHearing?.venueId || ''}
									/>
								</AutoHeight>

								<AutoHeight show={forumType === FORUM_TYPE.FaceToFace && venueId === 'custom'}>
									<FormInput
										name="venue"
										label="Venue Address"
										validations={{ required: 'Venue is required' }}
										defaultValue={currentHearing?.venue || ''}
									/>
								</AutoHeight>

								<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
									<FormInput
										name="zoomMeeting"
										label="Meeting"
										as="textarea"
										defaultValue={currentHearing?.zoomMeeting || ''}
										validations={
											forumType !== FORUM_TYPE.FaceToFace
												? { required: 'Meeting Details is required' }
												: undefined
										}
									/>
								</AutoHeight>

								<FormToggle name="notificationsEnabled" label="Notifications Enabled" />
							</Stack>
						</FormSection>

						<FormSection title="Attendees" subtitle="Who is attending the meeting">
							<TableFrame>
								<Table items={attendees} schema={attendeeSchema} className="-my-px" />
							</TableFrame>
						</FormSection>

						{!isFENZ && (
							<FormSection
								title="Reviews"
								subtitle="Which Review Numbers are going to be discussed in this meeting?"
							>
								<TableFrame>
									<Table
										items={caseData.reviews.filter((x) => !x.withdrawDate)}
										schema={reviewSchema}
										className="-my-px"
									/>
								</TableFrame>
							</FormSection>
						)}

						<FormSection title="Special Requirements">
							<Stack>
								<FormInput name={`accessibility`} label="Accessibility" />
								<FormInput name={`cultural`} label="Cultural" />
								<FormInput name={`security`} label="Security" />
								<FormInput name={`other`} label="Other" as="textarea" />
								<FormToggle name={`interpreterRequired`} label="Interpreter required" />
							</Stack>
						</FormSection>

						<FormSection title="Operational Details" subtitle="Other details about the meeting">
							<Stack>
								<FormToggle name="roomBooked" label="Room booked?" />

								<AutoHeight show={!!roomBooked}>
									<FormInput name="roomHireDetails" label="Room hire details" as="textarea" />
								</AutoHeight>

								<FormToggle name="travelBooked" label="Travel Booked?" />

								<AutoHeight show={!!travelBooked}>
									<FormInput name="travelArrangements" label="Travel arrangements" as="textarea" />
								</AutoHeight>
							</Stack>
						</FormSection>
					</Stack>
				</ModalContent>

				<ModalFooter>
					<div className="flex justify-end">
						<Actions
							actions={[
								{
									title: 'Save',
									intent: 'save',
									icon: <CheckIcon className="w-5 h-5" />,
									type: 'submit',
									isLoading: isSaving,
								},
							]}
						/>
					</div>
				</ModalFooter>
			</Form>
		</>
	)
}

export default openCreateHearing
