export enum SCAN_STATUS {
	Unknown = 0,
	Clean = 1,
	VirusDetected = 2,
	Error = 3,
	Scanning = 4,
}

export const scanStatus = {
	0: 'Unknown',
	1: 'Clean',
	2: 'Virus Detected',
	3: 'Error',
	4: 'Scanning',
}

export const ScanStatus = {
	readable: (x: SCAN_STATUS) => scanStatus[x],
	getId: (name: string) => +Object.fromEntries(Object.entries(scanStatus).map(([a, b]) => [b, a]))[name],
	options: Object.entries(scanStatus).map(([key, val]) => ({ label: val, value: +key })),
}
