import { FC, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Badge from 'components/Badge'
import Card from 'components/Card'
import DataListColumns from 'components/DataListColumns'
import PageContent from 'components/PageContent'
import PageHeading from 'components/PageHeading'
import PaginatedTable from 'components/PaginatedTable'
import Stack from 'components/Stack'
import { TableSchema } from 'components/Table'
import useData from 'hooks/useData'
import usePaginatedData from 'hooks/usePaginatedData'
import { CaseStatus, CASE_TYPE, UserRole } from 'types/enums'
import { CaseModel, OrganizationModel, UserModel } from 'types/models'
import { getCaseField, getFullName } from 'utils/funcs'
import CaseSearch from 'search/CasesSearch'
import UserSearch from 'search/UsersSearch'
import Tabs from 'components/Tabs'
import UserAvatar from 'components/UserAvatar'
import { PencilIcon } from '@heroicons/react/outline'
import openEditOrganization from 'modals/openEditOrganization'

// interface ViewOrganizationProps {
// 	orgId?: string
// }

const ViewOrganization: FC = () => {
	const location = useLocation()
	const params = useParams()
	const { orgId } = params

	const state = location?.state as {
		cases_search?: any
		cases_page?: number
		cases_pageSize?: number
		users_search?: any
		users_page?: number
		users_pageSize?: number
	} | null

	const casesPaginationState = {
		orderBy: 'created',
		orderDir: 2,
		search: { ...(state?.cases_search || {}), organizationId: orgId },
		page: state?.cases_page || 1,
		pageSize: state?.cases_pageSize || 5,
	}

	const { data: cases, isFetching: casesLoading } = usePaginatedData<CaseModel>('/Cases', casesPaginationState)

	const caseSchema: TableSchema<CaseModel> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`/cases/${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Claim #',
				value: (x) => getCaseField('accClaimNumber', x) || '-',
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) => getCaseField('applicantName', x) || '-',
				truncate: true,
			},
			{
				title: 'Type',
				value: (x) => CASE_TYPE[x.caseType],
				truncate: true,
			},
			{
				title: 'Status',
				value: (x) => (
					<Badge status={x.status} size="sm">
						{CaseStatus.readable(x.status)}
					</Badge>
				),
				width: 'minmax(auto, max-content)',
			},
		],
	}

	const usersPaginationState = {
		orderBy: 'created',
		orderDir: 2,
		search: { ...(state?.users_search || {}), organizationId: orgId },
		page: state?.users_page || 1,
		pageSize: state?.users_pageSize || 5,
	}

	const { data: users, isFetching: usersLoading } = usePaginatedData<UserModel>('/Users', usersPaginationState)

	const userSchema: TableSchema<UserModel> = {
		cols: [
			{
				title: '',
				width: 'minmax(auto, max-content)',
				value: (x) => (
					<UserAvatar
						className="rounded-full w-7 h-7 object-cover shadow-sm"
						src={x.avatarUrl}
						name={getFullName(x)}
					/>
				),
			},
			{
				title: 'Name',
				width: 'auto',
				value: (x) => (
					<Link to={`/users/${x.id}`} className="anchor">
						{getFullName(x)}
					</Link>
				),
			},
			{
				title: 'Email',
				width: 'minmax(0, auto)',
				value: (x) => x.email,
				truncate: true,
				responsive: 'sm',
			},
			{
				title: 'Phone',
				width: 'minmax(0, auto)',
				value: (x) => x.phoneNumber,
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Roles',
				width: 'minmax(0, auto)',
				value: (x) => x.roles.map((role) => UserRole.readable(role.id)).join(', '),
				truncate: true,
				responsive: 'lg',
			},
		],
	}

	const { data: org, refetch } = useData<OrganizationModel>(`/Organizations/${orgId}`)

	const [tab, setTab] = useState<number>(0)

	return (
		<>
			<PageHeading
				title={org?.name}
				actions={[
					{
						title: 'Edit',
						rounded: 'md',
						icon: <PencilIcon className="w-5 h-5" />,
						onClick: () => org && openEditOrganization({ organizationId: org.id, refetch }),
					},
				]}
			/>

			<PageContent>
				<Stack>
					<Card>
						<DataListColumns
							items={[
								{
									title: 'Name',
									value: org?.name,
								},
								{
									title: 'Billing Name',
									value: org?.billingName,
								},
								{
									title: 'Email',
									value: org?.email,
								},
								{ title: 'Phone', value: org?.phone },
							]}
						/>
					</Card>

					<div>
						<div className="pl-2">
							<Tabs
								className="flex-1"
								theme="card"
								activeIndex={tab}
								items={[
									{
										title: 'Users',
										simpleTitle: 'Users',
										onClick: () => setTab(0),
									},
									{
										title: 'Cases',
										simpleTitle: 'Cases',
										onClick: () => setTab(1),
									},
								]}
							/>
						</div>

						<Card className={[tab !== 0 && 'hidden', 'relative z-10']}>
							<PaginatedTable
								set={users}
								schema={userSchema}
								isLoading={usersLoading}
								paginationState={usersPaginationState}
								paginationPrefix="users_"
								search={(props) => (
									<UserSearch
										{...props}
										presetValues={{ organization: { value: orgId || '', locked: true } }}
									/>
								)}
							/>
						</Card>

						<Card className={[tab !== 1 && 'hidden', 'relative z-10']}>
							<PaginatedTable
								set={cases}
								schema={caseSchema}
								isLoading={casesLoading}
								paginationState={casesPaginationState}
								paginationPrefix="cases_"
								search={(props) => (
									<CaseSearch
										{...props}
										presetValues={{ organization: { value: orgId || '', locked: true } }}
									/>
								)}
							/>
						</Card>
					</div>
				</Stack>
			</PageContent>
		</>
	)
}

export default ViewOrganization
