import { FC, Fragment } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { CaseModel } from 'types/models'
import { CaseStatus, CaseType } from 'types/enums'
import { PaginationState } from 'types'
import { getFullName } from 'utils/funcs'

// hooks
import usePaginatedData from 'hooks/usePaginatedData'

// components
import Badge from 'components/Badge'
import PaginatedTable from 'components/PaginatedTable'
import { TableSchema } from 'components/Table'

interface MetricsTableProps {
	paginationState: PaginationState
}

const MetricsTable: FC<MetricsTableProps> = ({ paginationState }) => {
	const { data: cases, isFetching } = usePaginatedData<CaseModel>('/Cases/metrics', paginationState)

	const schema: TableSchema<CaseModel> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`/cases/${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Claim #',
				value: (x) => x.fields?.find((y) => y.name === 'accClaimNumber')?.value || '-',
				truncate: true,
			},
			{
				title: 'Review #',
				value: (x) => {
					return x.reviews?.map(
						(y, i) =>
							x.reviews && (
								<Fragment key={i}>
									<span className={clsx({ 'line-through': !!y.withdrawDate })}>{y.reviewNumber}</span>
									{i !== x.reviews.length - 1 ? ', ' : ''}
								</Fragment>
							)
					)
				},
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) =>
					getFullName(x.applicant?.user) || x.fields?.find((x) => x.name === 'applicantName')?.value || '-',
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Type',
				value: (x) => CaseType.readable(x.caseType),
				truncate: true,
				responsive: 'lg',
			},
			{
				title: 'Status',
				value: (x) => (
					<Badge status={x.status} size="sm">
						{CaseStatus.readable(x.status)}
					</Badge>
				),
				responsive: 'lg',
				width: 'minmax(auto, max-content)',
			},
		],
	}

	return <PaginatedTable schema={schema} set={cases} paginationState={paginationState} isLoading={isFetching} />
}

export default MetricsTable
