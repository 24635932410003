import { FC } from 'react'
import illustration from 'assets/img/undraw_not_found_60pq.svg'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'

const NotFound: FC = () => {
	return (
		<>
			<PageHeading title="Not Found" />

			<PageContent>
				<div className="p-4">
					<img src={illustration} alt="not found" width="500" className="mx-auto" />
				</div>
			</PageContent>
		</>
	)
}

export default NotFound
