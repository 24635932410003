import { FC } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { ModalResolveFn, RefetchFn } from 'types'
import Form from 'components/Form/Form'
import { useForm } from 'react-hook-form'
import Actions from 'components/Actions'
import { CASE_STATUS } from 'types/enums'
import FormSelect, { Option } from 'components/Form/FormSelect'
import api from 'api'
import { CaseModel } from 'types/models'
import Stack from 'components/Stack'
import AutoHeight from 'components/AutoHeight'
import FormToggle from 'components/Form/FormToggle'
import { FENZOrgId } from 'utils/constants'
import { toast } from 'components/toast'

interface OpenChangeCaseStatusProps {
	title?: string
	options: Option[]
	caseData: CaseModel
	refetch: RefetchFn
}

const openChangeCaseStatus = ({ title = 'Close Case', ...props }: OpenChangeCaseStatusProps) => {
	return openModal({
		title,
		render: (close) => <CloseCase close={close} {...props} />,
	})
}

interface FormData {
	status: CASE_STATUS | null
	updateDate: boolean
}

const CloseCase: FC<Omit<OpenChangeCaseStatusProps, 'title'> & { close: ModalResolveFn }> = ({
	close,
	caseData,
	refetch,
	options,
}) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			status: null,
			updateDate: true,
		},
	})

	const status = formContext.watch('status')

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			const changes: {
				completedDate?: Date | null
				billingDate?: Date | null
				rejectedDate?: Date | null
			} = {}

			if (formData.status === CASE_STATUS.Open) {
				changes.completedDate = null
				changes.billingDate = null
			} else if (formData.updateDate) {
				changes.completedDate = new Date()
			}

			if (isFENZ && formData.status === CASE_STATUS.Rejected) {
				changes.rejectedDate = new Date()
			}

			await api.put(`/Cases/${caseData?.id}`, {
				...caseData,
				status: formData.status,
				...changes,
			})

			await refetch()

			toast({ title: 'Case Updated' })

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const isFENZ = caseData?.organization?.id === FENZOrgId

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormSelect
						name="status"
						label="Status"
						options={options.filter((x) => {
							if (!isFENZ) return x.value !== CASE_STATUS.Rejected
							return true
						})}
						validations={{ required: 'Status is required' }}
						autoFocus
					/>

					<AutoHeight show={!!status && status !== CASE_STATUS.Open}>
						<FormToggle name="updateDate" label="Update Completed Date" />
					</AutoHeight>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openChangeCaseStatus
