import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchFormProps } from 'components/PaginatedTable'
import { SearchIcon, XIcon } from '@heroicons/react/outline'
import { Undo } from 'components/Icon'
import Form from 'components/Form/Form'
import FormDate from 'components/Form/FormDate'
import FormSelect from 'components/Form/FormSelect'
import Stack from 'components/Stack'
import { useForm } from 'react-hook-form'
import Actions from 'components/Actions'
import { USER_ROLE } from 'types/enums'
import { PaginationSearch } from 'types'
import { gql } from '@apollo/client'
import { GQLConnection, GQLUserType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import FormInput from 'components/Form/FormInput'
import { Auth } from 'Auth'
import openFindUser from 'modals/openFindUser'
import { getFullName } from 'utils/funcs'
import FormHidden from 'components/Form/FormHidden'

const cmQuery = gql`
	query {
		Users (search: { role: ["${USER_ROLE.CaseManager}"] }) {
			items {
				id
				fullName
			}
		}
	}
`

const SearchForm: FC<SearchFormProps> = ({ setSearch, close }) => {
	const { data: CMData = { Users: { items: [] } } } = useBufferedQuery<{
		Users: GQLConnection<GQLUserType>
	}>(cmQuery, {
		skip: !Auth.is('CaseManager', 'Administrator'),
	})

	const location = useLocation()

	const state = (location?.state as { search: PaginationSearch } | null)?.search

	const defaultValues = useMemo(
		() => ({
			from: state?.from || '',
			to: state?.to || '',
			caseNumber: state?.caseNumber || '',
			caseManager: state?.caseManager || null,
			attendees: state?.attendees,
		}),
		[state?.from, state?.caseManager, state?.caseNumber, state?.attendees, state?.to]
	)

	const formContext = useForm<PaginationSearch>({
		defaultValues,
	})

	const [prevState, setPrevState] = useState<any>(null)

	useEffect(() => {
		if (JSON.stringify(state) !== JSON.stringify(prevState)) {
			setPrevState(state)
			formContext.reset(defaultValues)
		}
	}, [state, prevState, defaultValues, formContext])

	const handleSubmit = (formData: PaginationSearch) => {
		const search: PaginationSearch = {}

		if (formData.from) search.from = formData.from
		if (formData.to) search.to = formData.to
		if (formData.caseNumber) search.caseNumber = formData.caseNumber
		if (formData.caseManager) search.caseManager = formData.caseManager
		if (formData.attendees) search.attendees = formData.attendees

		search.status = [1]

		setSearch(search)
	}

	const { from, to, attendees } = formContext.watch(['from', 'to', 'attendees'])
	const [prevFrom, setPrevFrom] = useState(from)
	const [prevTo, setPrevTo] = useState(to)

	useEffect(() => {
		let bothDate = from instanceof Date && to instanceof Date

		if (bothDate && (prevFrom !== from || prevTo !== to)) {
			if (from.valueOf() > to.valueOf()) {
				formContext.setValue('to', null)
			}
			setPrevFrom(from)
			setPrevTo(from)
		}
	}, [formContext, from, prevFrom, prevTo, to])

	const getAttendee = () => {
		openFindUser({
			title: 'Find Attendee',
			onAccept: async (user) => {
				if (!user) return

				formContext.setValue('attendees', {
					id: user.id,
					fullName: getFullName(user),
				})
			},
		})
	}

	return (
		<Form
			context={formContext}
			onSubmit={handleSubmit}
			className="p-6 bg-gray-50 dark:bg-gray-750 border-t border-gray-200 dark:border-gray-600"
		>
			<Stack dividers>
				<h3 className="text-lg leading-5 font-medium text-gray-900 dark:text-white">Search</h3>

				<Stack>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<FormDate name="from" label="From" className="flex-1" hideOptional autoFocus />
						<FormDate name="to" label="To" className="flex-1" minDate={from} hideOptional />
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<Stack>
							<FormInput name="caseNumber" label="Case Number" hideOptional />
						</Stack>
						{Auth.is('CaseManager', 'Administrator') && (
							<Stack>
								<FormSelect
									name="caseManager"
									label="Case Manager"
									options={
										CMData.Users.items?.map((x) => ({
											label: x.fullName || '',
											value: x.id,
										})) || []
									}
									clearable
									hideOptional
								/>
							</Stack>
						)}
						{Auth.is('CaseManager', 'Administrator') && (
							<div>
								<div className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 cursor-default">
									Attendee
								</div>

								<div className="relative mt-1">
									<div
										className="form-input px-4 h-[38px] flex items-center bg-white border pl-9 w-full sm:text-sm sm:leading-5 cursor-pointer"
										onClick={() => getAttendee()}
									>
										{attendees?.fullName}
									</div>

									{attendees && (
										<div
											className="absolute top-0 right-0 mr-3 h-full flex items-center text-gray-400 hover:text-red-500 cursor-pointer"
											onClick={() => {
												formContext.setValue('attendees', null)
											}}
										>
											<XIcon className="w-4 h-4" />
										</div>
									)}
									<div className="absolute top-0 left-0 ml-3 h-full flex items-center text-gray-400 pointer-events-none">
										<SearchIcon className="w-4 h-4" />
									</div>

									<FormHidden name="attendees" defaultValue={null} />
								</div>
							</div>
						)}
					</div>
				</Stack>

				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Reset',
								icon: <Undo className="w-4 h-5" />,
								onClick: () => {
									setSearch()
									formContext.reset({})
								},
							},
							{
								title: 'Search',
								icon: <SearchIcon className="w-4 h-5" />,
								intent: 'primary',
								type: 'submit',
							},
						]}
					/>
				</div>
			</Stack>
		</Form>
	)
}

export default SearchForm
