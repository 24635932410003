import { FC, useState } from 'react'
import Card, { CardWrapper } from 'components/Card'
import CardHeader from 'components/CardHeader'
import AppFiles from '../components/AppFiles'
import Loader from 'components/Loader'
import Stack from 'components/Stack'
import useData from 'hooks/useData'
import Pagination from 'components/Pagination'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import FileExplorer from 'components/FileExplorer'
import { AppFileModel, CaseModel } from 'types/models'

const Files: FC = () => {
	// pagination
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(10)

	// data loading
	const { isFetching: loading, data = { items: [], totalPages: 0, totalCount: 0 } } = useData<{
		items: CaseModel[]
		totalPages: number
		totalCount: number
	}>('/Cases', {
		pageNumber: page,
		pageSize: pageSize,
	})

	const { data: files, refetch: refetchAppFiles } = useData<{ items: AppFileModel[] }>('/AppFiles', { pageSize: 100 })

	return (
		<>
			<PageHeading title="Files" />

			<PageContent>
				<Stack>
					<CardWrapper className="overflow-hidden">
						<CardHeader title="Uploaded by ACC" />
						<AppFiles files={files?.items} onRefetch={refetchAppFiles} className="-mt-px" />
					</CardWrapper>

					<Card header={<CardHeader title="Case Files" />}>
						<Stack>
							<Pagination
								page={page}
								setPage={setPage}
								totalCount={data ? data.totalCount : 0}
								pageCount={data ? data.items.length : 0}
								pageSize={pageSize}
								setPageSize={setPageSize}
								isLoading={loading}
								showCounter
							/>

							<div style={{ opacity: loading ? 0.5 : 1 }}>
								<FileExplorer cases={data.items} />
							</div>

							{loading && !data.items.length && <Loader />}

							<Pagination
								page={page}
								setPage={setPage}
								totalCount={data ? data.totalCount : 0}
								pageCount={data ? data.items.length : 0}
								pageSize={pageSize}
								setPageSize={setPageSize}
								showInfo
							/>
						</Stack>
					</Card>
				</Stack>
			</PageContent>
		</>
	)
}

export default Files
