export enum CASE_PROCESS {
	Unknown = 0,
	Facilitation = 1,
	Mediation = 2,
	Adjudication = 3,
	FastTrackAdjudication = 4,
}

export const process = {
	0: 'Unknown',
	1: 'Facilitation',
	2: 'Mediation',
	3: 'Adjudication',
	4: 'Fast-Track Adjudication',
}

export const CaseProcess = {
	readable: (x: CASE_PROCESS) => process[x],
	options: Object.entries(process).map(([key, val]) => ({ label: val, value: +key })),
}
