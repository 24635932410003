import { useState, useEffect, FC } from 'react'
import DefaultImage from 'assets/img/default.png'
import Loader from 'components/Loader'
import { blobUrl } from 'utils/funcs'
import api from 'api'
import clsx, { ClassValue } from 'clsx'

interface AvatarProps {
	id?: string
	profile?: boolean
	src?: string
	containerProps?: { [x: string]: any }
	className?: ClassValue
}

const Avatar: FC<AvatarProps> = ({ id = null, profile, src = null, containerProps = {}, className }) => {
	const [loading, setLoading] = useState(id || profile)
	const [blob, setBlob] = useState<string>()

	useEffect(() => {
		setLoading(true)

		api.get<Blob>(profile ? '/Accounts/avatar' : `/Users/${id}/avatar`, {
			responseType: 'blob',
		}).then((res) => {
			if (!res.data.size) {
				setLoading(false)
			} else {
				setLoading(false)
				setBlob(blobUrl(new Blob([res.data])))
			}
		})
	}, [id, profile])

	return (
		<div className="relative" {...containerProps}>
			<img
				src={src || blob || DefaultImage}
				style={{ opacity: loading ? 0 : 1 }}
				alt="avatar"
				className={clsx(className, 'object-cover')}
			/>
			{loading && <Loader className="absolute inset-0 w-full h-full flex items-center justify-center" />}
		</div>
	)
}

export default Avatar
