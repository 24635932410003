import { FC, useEffect, useMemo, useState } from 'react'
import openSlideout, { SlideoutContent, SlideoutHeader } from 'hooks/openSlideout'
import DataListSingle from 'components/DataListSingle'
import { humanFileSize } from 'utils/funcs'
import {
	EyeIcon,
	CloudDownloadIcon,
	KeyIcon,
	TrashIcon,
	DotsHorizontalIcon,
	ExternalLinkIcon,
	PencilAltIcon,
	UploadIcon,
} from '@heroicons/react/outline'
import { format } from 'date-fns'
import openFileView from 'modals/openFileView'
import openFilePermissions from 'modals/openFilePermissions'
import openRenameFile from 'modals/openRenameFile'
import { confirm, toast } from 'alerts'
import api from 'api'
import { CaseFileModel } from 'types/models'
import { isAxiosError, RefetchFn } from 'types'
import { CaseFileCategories, CasePartyRole, ScanStatus, SCAN_STATUS } from 'types/enums'
import { Auth } from 'Auth'
import useData from 'hooks/useData'
import jsPDF from 'jspdf'
import FileSaver from 'file-saver'
import { Spinner } from 'components/Icon'

const openFileSlideout = (props: OpenFileSlideoutProps) => {
	return openSlideout({
		render: () => <ViewFile {...props} />,
	})
}

interface OpenFileSlideoutProps {
	fileId: string
	refetch: RefetchFn
}

const ViewFile: FC<OpenFileSlideoutProps> = ({ fileId, refetch }) => {
	const [isDownloading, setDownloading] = useState(false)
	const [fileName, setFileName] = useState<string | undefined>('')

	const { data: file } = useData<CaseFileModel>(`/CaseFiles/${fileId}`)

	const isInfected = useMemo(() => file?.scanResult === SCAN_STATUS.VirusDetected, [file])
	const isScanning = useMemo(() => file?.scanResult === SCAN_STATUS.Scanning, [file])

	useEffect(() => {
		setFileName(file?.name)
	}, [file])

	if (!file) return null

	const openExternal = async () => {
		try {
			const res = await api.get<Blob>(`/CaseFiles/${file.id}/download`, { responseType: 'blob' })

			window.open(window.URL.createObjectURL(res.data))
		} catch (e) {
			toast({ title: 'Failed to get file', intent: 'error' })
		}
	}

	const downloadFile = async () => {
		setDownloading(true)

		const closeToast = toast({
			title: 'File Downloading',
			intent: 'inProgress',
			icon: <UploadIcon className="h-6 w-6" />,
			isLoading: true,
			dismissable: false,
		})

		if (file.fileType === '.html') {
			var doc = new jsPDF('p', 'pt', 'a4')

			try {
				const res = await api.get<string>(`/CaseFiles/${file.id}/download`, { responseType: 'text' })

				doc.html(
					`
				<style>
					body {
						font-family: Arial, Helvetica, sans-serif;
						padding: 1em;
					}

					p, li {
						line-height: 1.5;
					}

					u {
						text-decoration: underline;
					}
				</style>

				${res.data}
			`,
					{ x: 10, y: 10, width: 595 }
				)

				doc.save(file.name.replace('.html', '.pdf'))

				toast({ title: 'File Downloaded', message: file.name })
			} catch (error) {
				if (isAxiosError(error)) {
					let resBlob = error?.response?.data
					let resJson = resBlob instanceof Blob ? JSON.parse(await resBlob.text()) : {}

					if (resJson?.error === 'File Deleted') {
						toast({
							title: 'File has been deleted, and cannot be downloaded',
							message: `Deleted at ${format(new Date(resJson?.date), 'dd/MM/yyyy HH:mm:ss')}`,
							intent: 'error',
						})
					} else {
						toast({
							title: 'File failed to download',
							message: resJson?.error,
							intent: 'error',
						})
					}
				}
			}
		} else {
			try {
				const res = await api.get<Blob>(`/CaseFiles/${file.id}/download`, { responseType: 'blob' })
				FileSaver.saveAs(res.data, file.name)

				toast({ title: 'File Downloaded', message: file.name })
			} catch (error) {
				if (isAxiosError(error)) {
					let resBlob = error?.response?.data
					let resJson = resBlob instanceof Blob ? JSON.parse(await resBlob.text()) : {}

					if (resJson?.error === 'File Deleted') {
						toast({
							title: 'File has been deleted, and cannot be downloaded',
							message: `Deleted at ${format(new Date(resJson?.date), 'dd/MM/yyyy HH:mm:ss')}`,
							intent: 'error',
						})
					} else {
						toast({
							title: 'File failed to download',
							message: resJson?.error,
							intent: 'error',
						})
					}
				}
			}
		}

		closeToast()
		setDownloading(false)
	}

	const deleteFile = async () => {
		confirm({
			title: 'Are you sure you want to delete this file?',
			onAccept: async () => {
				try {
					await api.delete(`/CaseFiles/${file.id}`)

					await refetch()

					toast({ title: 'File Deleted' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	return (
		<>
			<SlideoutHeader
				title="File Details"
				subtitle={fileName}
				actions={[
					{
						icon: isDownloading ? (
							<Spinner className="w-5 h-5 animate-spin" />
						) : (
							<DotsHorizontalIcon className="w-5 h-5" />
						),
						intent: 'menu',
						disabled: isDownloading,
						actions: [
							[
								{
									title: 'View File',
									icon: <EyeIcon className="w-5 h-5" />,
									disabled: isInfected || isScanning,
									onClick: () => openFileView({ file }),
								},
								{
									title: 'Open File in new tab',
									icon: <ExternalLinkIcon className="w-5 h-5" />,
									disabled: isInfected || isScanning,
									onClick: () => openExternal(),
								},
								{
									title: 'Download File',
									icon: <CloudDownloadIcon className="w-5 h-5" />,
									disabled: isInfected || isScanning,
									onClick: () => downloadFile(),
								},
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									title: 'Edit Permissions',
									icon: <KeyIcon className="w-5 h-5" />,
									onClick: () => openFilePermissions({ file, refetch }),
								},
								// for renaming document
								Auth.is('Admin', 'CaseManager') && {
									title: 'Rename File',
									icon: <PencilAltIcon className="w-5 h-5" />,
									onClick: () =>
										openRenameFile({ file, refetch, callback: (x: string) => setFileName(x) }),
								},
								// for renaming document
							],
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && [
								{
									title: 'Delete File',
									intent: 'danger',
									icon: <TrashIcon className="w-5 h-5" />,
									onClick: () => deleteFile(),
								},
							],
						],
					},
				]}
			/>
			<SlideoutContent>
				<DataListSingle
					items={[
						{ title: 'Type', value: file.fileType === '.pdf' ? 'Document' : 'File' },
						{ title: 'Size', value: humanFileSize(file.fileSize) },
						{ title: 'Scan Status', value: ScanStatus.readable(file.scanResult) },
						{
							title: 'Upload Date',
							value: format(new Date(file.created), 'dd/MM/yyyy HH:mm:ss'),
						},
						Auth.is('CaseManager', 'Admin', 'Support') && {
							title: 'Uploaded by',
							value: file.uploadedBy,
						},
						{
							title: 'Category',
							value: CaseFileCategories.readable(file.caseFileCategory),
						},
						Auth.is('CaseManager', 'Admin', 'FENZAdmin') && {
							title: 'Permissions',
							value: (
								<div className="flex flex-col space-y-2">
									{CasePartyRole.options.map((x) => (
										<div
											key={x.value}
											className={
												file.permissions.indexOf(x.value) > -1
													? 'opacity-100'
													: 'text-red-300 line-through'
											}
										>
											{CasePartyRole.readable(x.value)}
										</div>
									))}
								</div>
							),
						},
					]}
				/>
			</SlideoutContent>
		</>
	)
}

export default openFileSlideout
