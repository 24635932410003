import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormToggle from 'components/Form/FormToggle'
import Stack from 'components/Stack'
import { CheckIcon } from '@heroicons/react/outline'
import Actions from 'components/Actions'
import AutoHeight from 'components/AutoHeight'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { toast } from 'components/toast'
import { HearingModel } from 'types/models'
import { RefetchFn } from 'types'

interface OpenEditHearingOperationalDetails {
	hearing: HearingModel
	refetch: RefetchFn
}

const openEditHearingOperationalDetails = ({ hearing, refetch }: OpenEditHearingOperationalDetails) => {
	return openModal({
		title: 'Edit Operational Details',
		render: (close) => <OperationalDetails hearing={hearing} refetch={refetch} close={close} />,
	})
}

interface FormData {
	roomBooked: boolean
	travelBooked: boolean
	roomHireDetails: string
	travelArrangements: string
}

const OperationalDetails: FC<OpenEditHearingOperationalDetails & { close: () => void }> = ({
	hearing,
	refetch,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			roomBooked: hearing.roomBooked || false,
			travelBooked: hearing.travelBooked || false,
			roomHireDetails: hearing.roomHireDetails || '',
			travelArrangements: hearing.travelArrangements || '',
		},
	})

	const { roomBooked, travelBooked } = formContext.watch(['roomBooked', 'travelBooked'])

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/CaseHearings/${hearing.id}`, {
				...hearing,
				roomBooked: formData.roomBooked,
				travelBooked: formData.travelBooked,
				roomHireDetails: formData.roomBooked ? formData.roomHireDetails : '',
				travelArrangements: formData.travelBooked ? formData.travelArrangements : '',
			})

			refetch()

			toast({ title: 'Updated Operational Details' })

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<>
			<Form context={formContext} onSubmit={handleSubmit}>
				<ModalContent>
					<Stack>
						<FormToggle name="roomBooked" label="Room booked?" />

						<AutoHeight show={roomBooked}>
							<FormInput name="roomHireDetails" label="Room details" as="textarea" className="mt-2" />
						</AutoHeight>

						<FormToggle name="travelBooked" label="Travel booked?" />

						<AutoHeight show={travelBooked}>
							<FormInput
								name="travelArrangements"
								label="Travel arrangements"
								as="textarea"
								className="mt-2"
							/>
						</AutoHeight>
					</Stack>
				</ModalContent>

				<ModalFooter>
					<div className="flex justify-end">
						<Actions
							actions={[
								{
									title: 'Save',
									intent: 'save',
									icon: <CheckIcon className="w-5 h-5" />,
									type: 'submit',
									isLoading: isSaving,
								},
							]}
						/>
					</div>
				</ModalFooter>
			</Form>
		</>
	)
}

export default openEditHearingOperationalDetails
