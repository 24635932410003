import { FC, useEffect, useState } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormSelect from 'components/Form/FormSelect'
import FormDate from 'components/Form/FormDate'
import { getGSTfromIncl } from 'utils/funcs'
import api from 'api'
import { Auth } from 'Auth'
import { toast } from 'components/toast'
import { CaseModel, LineItemModel } from 'types/models'
import { RefetchFn } from 'types'
import { CheckIcon } from '@heroicons/react/outline'
import Actions from 'components/Actions'

const GST = 15

const wildcard = 'Other (specify)'
const hours = 'Exceptional Case Fee (hrs)' // if this changes aithout also changing on the API, stuff will break
const transcription = 'Transcription fee'

const names = {
	reviewer: ['Room Hire', 'Travel', 'Accommodation', wildcard, hours],
	mediator: ['Room Hire', 'Travel', 'Accommodation', wildcard, hours],
	acc: [
		'Room Hire',
		'Travel',
		'Accommodation',
		'Security',
		'Translator',
		'Interpreter',
		'Cultural support',
		transcription,
		wildcard,
		hours,
	],
}

const gstTypes = ['GST Incl', 'GST Excl', 'No GST']

const trinaryOptions = [
	{ value: 1, label: 'Yes' },
	{ value: 2, label: 'No' },
]

interface OpenEditLineItemProps {
	caseData: CaseModel
	lineitem: LineItemModel
	type: 'reviewer' | 'mediator' | 'acc'
	refetch: RefetchFn
}

const openEditLineitem = (props: OpenEditLineItemProps) => {
	return openModal({
		title: 'Edit Line Item',
		size: 'lg',
		render: (close) => <EditLineitem close={close} {...props} />,
	})
}

interface FormData {
	name: string
	specifiedName: string
	costPrice: number
	hours: number
	gstAmountApproved: number
	claimReason: string
	claimable: number
	paymentRequested: number
	paymentApprovedACC: number
	gstType: string
	claimableDate: Date | null
}

const EditLineitem: FC<OpenEditLineItemProps & { close: () => void }> = ({
	close,
	caseData,
	lineitem,
	type,
	refetch,
}) => {
	const { isSaving, setSaving } = useModalState()

	const hourlyRate = 81.22 //lineitem.description === hours ? 219 : 81.22
	// const hourlyRateInclGST = hourlyRate + 11.625

	const defaultValues = {
		name: names[type].indexOf(lineitem.description) > -1 ? lineitem.description : wildcard,
		specifiedName: names[type].indexOf(lineitem.description) === -1 ? lineitem.description : '',
		costPrice: lineitem.description === transcription ? lineitem.costPrice : lineitem.costPrice + lineitem.costGST,
		hours: 0,
		gstAmountApproved: lineitem.gstAmountApproved || lineitem.costPrice,
		claimReason: lineitem.claimReason || '',
		claimable: lineitem.claimable,
		paymentRequested: lineitem.paymentRequested,
		paymentApprovedACC: lineitem.paymentApprovedACC,
		gstType:
			lineitem.description === transcription
				? gstTypes[1]
				: lineitem.costPrice && !lineitem.costGST
				? gstTypes[2]
				: gstTypes[1],
		claimableDate: lineitem.claimableDate || null,
	}

	if (lineitem.description === hours) {
		defaultValues.hours = lineitem.costPrice / hourlyRate
		defaultValues.gstAmountApproved = lineitem.gstAmountApproved
			? Number((lineitem.gstAmountApproved / hourlyRate).toFixed(1))
			: lineitem.costPrice / hourlyRate
	}

	const formContext = useForm<FormData>({
		defaultValues,
	})

	const { name, claimable, costPrice, paymentApprovedACC, gstAmountApproved } = formContext.watch([
		'name',
		'claimable',
		'costPrice',
		'paymentApprovedACC',
		'gstAmountApproved',
	])

	const [prevCostPrice, setPrevCostPrice] = useState(costPrice)

	useEffect(() => {
		if (prevCostPrice !== costPrice) {
			if (costPrice !== gstAmountApproved) {
				formContext.setValue('gstAmountApproved', costPrice)
			}
			setPrevCostPrice(costPrice)
		}
	}, [prevCostPrice, costPrice, gstAmountApproved, formContext])

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		const user =
			type === 'reviewer' ? caseData.reviewer?.user : type === 'mediator' ? caseData.mediator?.user : null

		try {
			const body: { [x: string]: any } = {
				id: lineitem.id,
				description: formData.specifiedName || formData.name,
				itemCode: formData.name === hours ? 'ECF' : formData.name === transcription ? 'TF' : 'AC',
				caseId: caseData.id,
				payableToId: user ? user.id : null,
				quantity: 1,
				claimReason: formData.claimReason,
			}

			if (formData.claimableDate) {
				body.claimableDate = formData.claimableDate
			}

			if (formData.name !== transcription) {
				// incl
				if (formData.gstType === gstTypes[0]) {
					body.costPrice = formData.costPrice - getGSTfromIncl(formData.costPrice)
					body.costGST = getGSTfromIncl(formData.costPrice)
					// excl
				} else if (formData.gstType === gstTypes[1]) {
					body.costPrice = formData.costPrice
					body.costGST = (formData.costPrice / 100) * 15
					// no gst
				} else {
					body.costPrice = formData.costPrice
					body.costGST = 0
				}
			}

			if (Auth.is('Admin', 'FENZAdmin')) {
				body.claimable = formData.claimable
				body.paymentRequested = formData.claimable ? formData.paymentRequested : false
				body.paymentApprovedACC = formData.claimable ? formData.paymentApprovedACC : false
			}

			if (formData.name === hours) {
				// body.quantity = +formData.hours
				body.costPrice = hourlyRate * +formData.hours
				body.costGST = (body.costPrice / 100) * GST
			}

			// if (Auth.is('ACCAdmin')) {
			// body.paymentApprovedACC = formData.paymentApprovedACC
			// }

			if (formData.name === hours) {
				if (formData.paymentApprovedACC) {
					body.gstAmountApproved = formData.gstAmountApproved * hourlyRate
				}
			} else {
				if (formData.paymentApprovedACC) {
					body.gstAmountApproved = formData.gstAmountApproved
				}
			}

			await api.put(`/Billing/lineitem/${lineitem.id}`, body)

			toast({ title: 'Line Item updated' })

			close()

			if (refetch) refetch()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<div className="flex flex-col space-y-6">
					{Auth.is(
						'Admin',
						'CaseManager',
						'Reviewer',
						'Mediator',
						'FENZAdmin',
						'Adjudicator',
						'Facilitator'
					) && (
						<div>
							<FormSelect
								name="name"
								label="Type"
								options={names[type].map((x) => ({ label: x, value: x }))}
								clearable
							/>
						</div>
					)}

					{Auth.is(
						'Admin',
						'CaseManager',
						'Reviewer',
						'Mediator',
						'FENZAdmin',
						'Adjudicator',
						'Facilitator'
					) &&
						name === wildcard && (
							<div>
								<FormInput name="specifiedName" label="Name" />
							</div>
						)}

					{Auth.is(
						'Admin',
						'CaseManager',
						'Reviewer',
						'Mediator',
						'FENZAdmin',
						'Adjudicator',
						'Facilitator'
					) && (
						<>
							{(name === hours || name === wildcard) && (
								<div>
									<FormInput name="hours" label="Hours" type="number" step={0.5} />
								</div>
							)}

							{name !== hours && (
								<div className="flex items-start space-x-4">
									<div className="w-64">
										<FormSelect
											name="gstType"
											label="GST"
											options={gstTypes.map((x) => ({ label: x, value: x }))}
											disabled={name === transcription}
										/>
									</div>

									<FormInput
										name="costPrice"
										label="Amount"
										type="number"
										step={0.01}
										disabled={name === transcription}
									/>
								</div>
							)}
						</>
					)}

					<FormInput name="claimReason" label="Description" />

					{Auth.is('Admin', 'FENZAdmin') && (
						<>
							<FormSelect name="claimable" label="Claimable" options={trinaryOptions} clearable />

							<FormSelect
								name="paymentRequested"
								label="Payment requested from ACC"
								options={trinaryOptions}
								clearable
								disabled={claimable !== 1}
							/>

							<FormSelect
								name="paymentApprovedACC"
								label="Payment approved by ACC"
								options={trinaryOptions}
								clearable
								disabled={claimable !== 1}
							/>
						</>
					)}

					{Auth.is('Admin', 'CaseManager', 'FENZAdmin') && claimable === 1 && paymentApprovedACC === 1 && (
						<>
							<FormInput name="gstAmountApproved" label="Amount Approved (GST Excl)" />

							<FormDate name="claimableDate" label="Billing Date" />
						</>
					)}
				</div>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Submit',
								type: 'submit',
								icon: <CheckIcon className="w-5 h-5" />,
								intent: 'save',
								disabled: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditLineitem
