import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { useController, useFormContext } from 'react-hook-form'

interface FormToggleProps {
	name: string
	label?: ReactNode
	defaultValue?: any
	disabled?: boolean
	autoFocus?: boolean
	className?: ClassValue
}

const FormToggle: FC<FormToggleProps> = ({ name, label, defaultValue, disabled, autoFocus, className }) => {
	const { formState } = useFormContext()
	const {
		field: { onChange, value },
	} = useController({ name, defaultValue })

	return (
		<div className={clsx(className)}>
			<label className="text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 inline-flex items-center cursor-pointer">
				<Toggle checked={value} />
				{label && <span className="ml-3">{label}</span>}
				<input
					type="checkbox"
					checked={value}
					onChange={(e) => onChange(e.target.checked)}
					disabled={disabled || formState.isSubmitting}
					className="hidden"
					autoFocus={autoFocus}
				/>
			</label>
		</div>
	)
}

interface ToggleProps {
	checked: boolean
}

const Toggle: FC<ToggleProps> = ({ checked }) => {
	return (
		<span
			role="checkbox"
			tabIndex={0}
			aria-checked="false"
			className={clsx(
				'relative inline-block shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline',
				{
					'bg-primary-600 dark:bg-emerald-500': checked,
					'bg-gray-200 dark:bg-gray-800': !checked,
				}
			)}
		>
			<span
				aria-hidden="true"
				className={clsx(
					'translate-x-0 inline-block h-5 w-5 rounded-full shadow transition ease-in-out duration-200',
					{
						'translate-x-5 bg-white dark:bg-white': checked,
						'translate-x-0 bg-white dark:bg-gray-400': !checked,
					}
				)}
			></span>
		</span>
	)
}

export default FormToggle
