export enum CASE_COMPLEXITY {
	Standard = 'Standard',
	Complex = 'Complex',
}

export const caseComplexity = {
	Standard: 'Standard',
	Complex: 'Complex',
}

export const CaseComplexity = {
	readable: (x: CASE_COMPLEXITY) => caseComplexity[x],
	options: Object.entries(caseComplexity).map(([_, val]) => ({ label: val, value: val })),
}
