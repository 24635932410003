export enum METRIC_ROLE_TYPE {
	'CaseManager' = 1,
	'Reviewer' = 2,
	'Mediator' = 3,
	'Support' = 4,
	'Admin' = 5,
}

export const metricRoleType = {
	1: 'Case Manager',
	2: 'Reviewer',
	3: 'Mediator',
	4: 'Support',
	5: 'Admin',
}

export const MetricRoleType = {
	readable: (x: METRIC_ROLE_TYPE) => metricRoleType[x],
	options: Object.entries(metricRoleType).map(([key, val]) => ({ label: val, value: +key })),
}
