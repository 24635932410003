import { FC, RefAttributes } from 'react'
import { NavLink as OriginalNavLink, NavLinkProps } from 'react-router-dom'
import clsx, { ClassValue } from 'clsx'

interface Props {
	className?: ClassValue
	inactiveClassName?: ClassValue
	activeClassName?: ClassValue
}

const NavLink: FC<NavLinkProps & RefAttributes<HTMLAnchorElement> & Props> = ({
	className,
	inactiveClassName,
	activeClassName,
	...props
}) => {
	return (
		<OriginalNavLink
			{...props}
			className={({ isActive }) => clsx(className, isActive ? activeClassName : inactiveClassName)}
		/>
	)
}

export default NavLink
