import { useState, useEffect, FC } from 'react'
import jsPDF from 'jspdf'
import FileSaver from 'file-saver'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Card from 'components/Card'
import Loader from 'components/Loader'
import { blobUrl } from 'utils/funcs'
import useData from 'hooks/useData'
import api from 'api'
import { DownloadIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router-dom'
import { CaseFileModel } from 'types/models'
import { toast } from 'components/toast'

// interface ViewFileProps {
// 	fileId?: string
// }

const ViewFile: FC = () => {
	const params = useParams()
	const { fileId } = params

	const [blob, setBlob] = useState<Blob | string>()
	const [downloading, setDownloading] = useState(false)
	const { data: file } = useData<CaseFileModel>(`/CaseFiles/${fileId}`)

	useEffect(() => {
		const downloadFile = async () => {
			if (file?.fileType === '.html') {
				const res = await api.get<string>(`/CaseFiles/${fileId}/download`, { responseType: 'text' })

				setBlob(res.data)
			} else {
				const res = await api.get<Blob>(`/CaseFiles/${fileId}/download`, { responseType: 'blob' })

				setBlob(blobUrl(res.data))
			}
		}

		if (file) {
			downloadFile()
		}
	}, [file, fileId])

	const download = async () => {
		if (downloading || !file) return

		setDownloading(true)

		if (file.fileType === '.html') {
			var doc = new jsPDF('p', 'pt', 'a4')

			const res = await api.get(`/CaseFiles/${fileId}/download`, { responseType: 'text' })

			doc.html(
				`
			<style>
				body {
					font-family: Arial, Helvetica, sans-serif;
					padding: 1em;
				}

				p, li {
					line-height: 1.5;
				}

				u {
					text-decoration: underline;
				}
			</style>

			${res.data}
		`,
				{ x: 10, y: 10, width: 595 }
			)

			doc.save(file.name.replace('.html', '.pdf'))
			// // for testing without downloading
			// var string = doc.output('datauristring')
			// var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
			// var x = window.open()
			// x.document.open()
			// x.document.write(embed)
			// x.document.close()
		} else {
			const res = await api.get<Blob>(`/CaseFiles/${fileId}/download`, { responseType: 'blob' })
			FileSaver.saveAs(res.data, file.name)
		}

		setDownloading(false)

		toast({ title: `${file.name} downloaded` })
	}

	return (
		<>
			<PageHeading
				title="View File"
				actions={[
					{
						title: 'Download',
						intent: 'primary',
						icon: <DownloadIcon className="w-5 h-5" />,
						onClick: download,
					},
				]}
			/>
			<PageContent>
				<Card>
					{!blob ? (
						<Loader />
					) : (file?.fileType === '.jpg' || file?.fileType === '.gif' || file?.fileType === '.png') &&
					  blob instanceof Blob ? (
						<img src={blobUrl(blob)} width="100%" alt={file?.name} />
					) : file?.fileType === '.pdf' && blob instanceof Blob ? (
						<embed src={blobUrl(blob)} width="100%" height="900" type="application/pdf" />
					) : file?.fileType === '.html' && typeof blob === 'string' && blob.indexOf('<script') === -1 ? (
						<div dangerouslySetInnerHTML={{ __html: blob }} />
					) : (
						<p>This filetype cannot be displayed</p>
					)}
				</Card>
			</PageContent>
		</>
	)
}

export default ViewFile
