import { FC, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageContent from 'components/PageContent'
import PageHeading from 'components/PageHeading'
import Card from 'components/Card'
import Badge from 'components/Badge'
import Stack from 'components/Stack'
import { TableSchema } from 'components/Table'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import api from 'api'
import { confirm, warning } from 'alerts'
import { toast } from 'components/toast'
import { Auth } from 'Auth'
import { ArchiveIcon, DotsHorizontalIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import openCreateCase from 'modals/openCreateCase'
import Search from 'search/CasesSearch'
import { gql } from '@apollo/client'
import { GQLConnection, GQLCaseType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import clsx from 'clsx'
import { FENZOrgId } from 'utils/constants'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: CaseOrderBy!, $search: CaseSearch) {
		Cases(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				caseNumber
				applicantName
				accClaimNumber
				type {
					id
					readable
				}
				process {
					id
					readable
				}
				status {
					id
					readable
				}
				applicant {
					id
					user {
						id
						fullName
					}
				}
				reviews {
					id
					reviewNumber
					withdrawDate
				}
				organization {
					id
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const Cases: FC = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const { advocate, caseManager, applicant, reviewer, mediator, respondent, peerReviewer, ...search } =
		state?.search || {}

	const { data, loading, refetch } = useBufferedQuery<{ Cases: GQLConnection<GQLCaseType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: state?.search
				? {
						...search,
						...(applicant && advocate
							? {
									applicant: {
										user: applicant,
										parties: advocate,
									},
							  }
							: {}),
						...(applicant && !advocate
							? {
									applicant: {
										user: applicant,
									},
							  }
							: {}),
						...(!applicant && advocate
							? {
									applicant: {
										parties: advocate,
									},
							  }
							: {}),
						...(caseManager ? { caseManager: { user: { id: caseManager } } } : {}),
						...(reviewer ? { reviewer: { user: { id: reviewer } } } : {}),
						...(mediator ? { mediator: { user: { id: mediator } } } : {}),
						...(respondent ? { respondent: { user: { id: respondent } } } : {}),
						...(peerReviewer ? { peerReviewer: { user: { id: peerReviewer } } } : {}),
				  }
				: null,
		},
	})

	// useEffect(() => {
	// 	if (Auth.is('CaseManager')) {
	// 		if (!caseManager) {
	// 			navigate(location.pathname, {
	// 				replace: true,
	// 				state: { ...state, search: { caseManager: Auth.profile()?.id } },
	// 			})
	// 		}
	// 	}
	// }, [caseManager, location.pathname, state])

	const schema: TableSchema<GQLCaseType> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			!Auth.isOnly('FENZAdmin') && {
				title: 'ACC Claim #',
				value: (x) => x.accClaimNumber || '-',
				truncate: true,
			},
			!Auth.isOnly('FENZAdmin') && {
				title: 'Review #',
				value: (x) =>
					x.reviews?.map(
						(y, i) =>
							x.reviews && (
								<Fragment key={i}>
									<span className={clsx({ 'line-through': !!y.withdrawDate })}>{y.reviewNumber}</span>
									{i !== x.reviews.length - 1 ? ', ' : ''}
								</Fragment>
							)
					),
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) => x.applicant?.user?.fullName || x.applicantName || '-',
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Type',
				value: (x) => (x.organization?.id === FENZOrgId ? x.process?.readable : x.type?.readable),
				truncate: true,
				responsive: 'lg',
			},
			{
				title: 'Status',
				value: (x) => (
					<Badge status={x.status?.id} size="sm">
						{x.status?.readable}
					</Badge>
				),
				responsive: 'lg',
				width: 'minmax(auto, max-content)',
			},
		],
		actions: Auth.is('Admin', 'FENZAdmin')
			? (x) => [
					{
						icon: <DotsHorizontalIcon className="w-5 h-5" />,
						intent: 'menu',
						actions: [
							{
								title: 'Delete',
								intent: 'danger',
								icon: <TrashIcon className="w-5 h-5" />,
								onClick: () => deleteCase(x),
							},
						],
					},
			  ]
			: undefined,
	}

	const deleteCase = async (item: GQLCaseType) => {
		await confirm({
			title: 'Are you sure you want to delete this case?',
			onAccept: async () => {
				try {
					await api.delete(`/Cases/${item.id}`)

					await refetch()

					toast({ title: 'Case Deleted' })
				} catch (error) {
					api.handleError(error)
					warning({ title: 'Case not deleted' })
				}
			},
		})
	}

	return (
		<>
			<PageHeading
				title="Cases"
				actions={[
					Auth.is('FENZAdmin') && {
						title: 'Applications',
						icon: <ArchiveIcon className="w-5 h-5" />,
						rounded: 'md',
						to: '/applications',
					},
					Auth.is('Admin', 'CaseManager') && {
						title: 'Create Case',
						intent: 'primary',
						rounded: 'md',
						icon: <PlusIcon className="w-5 h-5" />,
						onClick: () => openCreateCase({ refetch }),
					},
				]}
			/>

			<PageContent>
				<Stack>
					<Card>
						<GQLPaginatedTable
							schema={schema}
							set={data?.Cases}
							isLoading={loading}
							refetch={refetch}
							search={(props) => <Search {...props} />}
							getKey={(x) => x.id}
						/>
					</Card>
				</Stack>
			</PageContent>
		</>
	)
}

export default Cases
