import { createContext, useContext, useState, useMemo } from 'react'
import axios from 'axios'
import { getChannels, createClient, createChannel, getMessages, send, on } from 'utils/twilio'
import produce from 'immer'
import useLocalStorage from 'hooks/useLocalStorage'

const wrapChannel = (channel) => ({
	id: channel.channelState.uniqueName,
	sid: channel.sid,
	channel,
	messages: [],
	typing: false,
	unread: false,
	lastMessage: null,
	sending: false,
	loading: true,
})

export const ChatContext = createContext()

export const ChatProvider = ({ serverUrl, ...props }) => {
	const [state, setState] = useState({
		identity: '',
		client: null,
		channels: [],
	})

	const [localStorageState, setLocalStorageState] = useLocalStorage('icra-chat', {
		main: { min: true },
		chats: [],
		settings: { notifications: false },
	})

	const updateChannel = (channel, cb) => {
		setState(
			produce((draft) => {
				let i = draft.channels.findIndex((x) => x.sid === channel.sid)
				cb(draft.channels[i])
			})
		)
	}

	const value = useMemo(
		() => ({
			...state,
			localStorageState,
			setLocalStorageState,

			connect: async (user_id) => {
				try {
					let res = await axios.get(`${serverUrl}/token/${user_id}`)
					let identity = res.data.identity
					let client = await createClient(res.data.token)
					let channelList = await getChannels(client)
					let channels = channelList.items

					setState((x) => ({
						...x,
						identity,
						client,
						channels: channels.map(wrapChannel),
					}))

					// subscribe to client events
					// when a new channel is created
					on(client, 'channelAdded', (channel) => {
						setState((x) => ({
							...x,
							channels: [...x.channels, wrapChannel(channel)],
						}))

						prepareChannel(channel)
					})

					// when a channel is deleted
					on(client, 'channelRemoved', (channel) => {
						setState((x) => ({
							...x,
							channels: x.channels.filter((y) => x.sid !== channel.sid),
						}))
					})

					// when a channel is updated
					on(client, 'channelUpdated', (channel) => {
						setState((x) => ({
							...x,
							channels: x.channels.map((y) => (y.sid === channel.sid ? channel : y)),
						}))
					})

					// subscribe to channel events
					const prepareChannel = (channel) => {
						// get all messages for this channel
						getMessages(channel).then((list) => {
							updateChannel(channel, (draft) => {
								draft.messages = list.items
								draft.lastMessage = list.items[list.items.length - 1]
								draft.loading = false

								if (draft.lastMessage) {
									if (draft.lastMessage.index > draft.channel.lastConsumedMessageIndex) {
										draft.unread = draft.lastMessage.index - draft.channel.lastConsumedMessageIndex
									}
								}
							})
						})

						// when a new message comes in
						on(channel, 'messageAdded', (message) => {
							updateChannel(channel, (draft) => {
								draft.messages = [...draft.messages, message]
								draft.lastMessage = message

								if (message.index > draft.channel.lastConsumedMessageIndex) {
									draft.unread = message.index - draft.channel.lastConsumedMessageIndex
								}
							})
						})

						on(channel, 'messageUpdated', (message) => {
							updateChannel(channel, (draft) => {
								draft.messages = draft.messages.map((x) => (x.sid === message.sid ? message : x))
							})
						})

						on(channel, 'messageRemoved', (message) => {
							updateChannel(channel, (draft) => {
								draft.messages = draft.messages.filter((x) => x.sid !== message.sid)
							})
						})

						// when a user starts typing
						on(channel, 'typingStarted', (member) => {
							updateChannel(channel, (draft) => {
								draft.typing = member
							})
						})

						// when typing stops
						on(channel, 'typingEnded', () => {
							updateChannel(channel, (draft) => {
								draft.typing = false
							})
						})
					}

					channels.forEach(prepareChannel)
				} catch (err) {
					console.error('Could not connect to chat server')
					console.log(err)
				}
			},

			createChannel: async (identifier) => {
				try {
					await createChannel(serverUrl, state.client, identifier)
				} catch (err) {
					console.log('createChannel', err)
				}
			},

			sendMessage: async (channel, message) => {
				updateChannel(channel, (draft) => {
					draft.sending = true
				})

				await send(channel, message)

				updateChannel(channel, (draft) => {
					draft.sending = false
				})
			},

			markRead: async (channel) => {
				await channel.channel.updateLastConsumedMessageIndex(channel.lastMessage.index)

				updateChannel(channel, (draft) => {
					draft.unread = 0
				})
			},
		}),
		[localStorageState, serverUrl, setLocalStorageState, state]
	)

	return <ChatContext.Provider {...props} value={value} />
}

const useChatWithContext = () => useContext(ChatContext)

export default useChatWithContext
