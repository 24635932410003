import { FC, ReactNode, useRef, MouseEvent, useState } from 'react'
// import { BackdropTransition, SlideoutTransition } from 'components/AlertTransition'
import alert from 'utils/alert'
import { motion, AnimatePresence, MotionStyle } from 'framer-motion'

export type SizeValue = 'sm' | 'md' | 'lg' | 'xl'

const sizes = {
	sm: '20rem',
	md: '40rem',
	lg: '60rem',
	xl: '75rem',
}

interface SlideoutProps {
	resolve: (x: boolean) => void
	close: () => void
	dismissable?: boolean
	size?: SizeValue
	children: (close: () => void) => ReactNode
}

const Slideout: FC<SlideoutProps> = ({ close, resolve, children, size = 'md', dismissable = true }) => {
	const [open, setOpen] = useState(true)

	const closeSlideout = () => {
		setOpen(false)
		setTimeout(() => {
			close()
		}, 500)
	}

	const backdropRef = useRef<HTMLDivElement>(null)

	return (
		<AnimatePresence>
			{open && (
				<motion.div
					style={{ originY: 0 } as MotionStyle}
					variants={{
						hidden: { opacity: 0 },
						visible: { opacity: 1 },
					}}
					transition={{
						type: 'tween',
						ease: 'easeOut',
						duration: 0.2,
					}}
					initial="hidden"
					animate="visible"
					exit="hidden"
				>
					<div
						className="fixed z-40 inset-0 overflow-hidden"
						onClick={(e: MouseEvent) => {
							if (e.target === backdropRef.current) {
								dismissable && closeSlideout()
							}
						}}
						ref={backdropRef}
					>
						<div className="absolute z-40 inset-0 overflow-hidden">
							<div
								className="absolute z-40 inset-0 bg-black bg-opacity-50 transition-opacity backdrop-filter backdrop-grayscale"
								onClick={() => closeSlideout()}
							/>
							<section className="absolute z-40 inset-y-0 right-0 max-w-full flex">
								<motion.div
									className="pointer-events-none flex-1"
									variants={{
										hidden: { x: '100%' },
										visible: { x: '0%' },
									}}
									transition={{
										type: 'tween',
										ease: 'easeOut',
										duration: 0.2,
									}}
									initial="hidden"
									animate="visible"
									exit="hidden"
								>
									<div className="pointer-events-auto h-full" style={{ maxWidth: sizes[size] }}>
										{children(closeSlideout)}
									</div>
								</motion.div>
							</section>
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default Slideout

interface slideoutProps {
	dismissable?: boolean
	size?: SizeValue
	children: (close: () => void) => ReactNode
}

export const slideout = ({ dismissable, size, children }: slideoutProps) =>
	alert(({ resolve, close }) => (
		<Slideout resolve={resolve} close={close} children={children} dismissable={dismissable} size={size} />
	))
