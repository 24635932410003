const prefix = 'icra'

const Storage = {
	getString: (key: string): string | null => {
		return localStorage.getItem(`${prefix}-${key}`)
	},
	getNumber: (key: string): number | null => {
		const val = localStorage.getItem(`${prefix}-${key}`)
		return val ? +val : null
	},
	getBoolean: (key: string): boolean | null => {
		const val = localStorage.getItem(`${prefix}-${key}`)

		if (val && val !== 'null') {
			return val === 'true'
		}
		return null
	},
	getObject: <T>(key: string): T | null => {
		const val = localStorage.getItem(`${prefix}-${key}`)

		if (val !== null) {
			try {
				return JSON.parse(val)
			} catch (error) {
				return null
			}
		}

		return val
	},
	set: (key: string, value: unknown) => {
		if (typeof value === 'string') {
			localStorage.setItem(`${prefix}-${key}`, value)
		} else if (typeof value === 'number') {
			localStorage.setItem(`${prefix}-${key}`, '' + value)
		} else if (typeof value === 'object') {
			localStorage.setItem(`${prefix}-${key}`, JSON.stringify(value))
		} else if (typeof value === 'boolean') {
			localStorage.setItem(`${prefix}-${key}`, JSON.stringify(value))
		}
	},
	remove: (key: string) => {
		localStorage.removeItem(`${prefix}-${key}`)
	},
}

export default Storage
