import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { toast } from 'components/toast'
import { VenueModel } from 'types/models'
import useData from 'hooks/useData'

interface OpenEditVenueProps {
	venueId: string
	refetch: () => Promise<unknown>
}

const openEditVenue = ({ venueId, refetch }: OpenEditVenueProps) => {
	return openModal({
		title: 'Edit Venue',
		render: (close) => <EditVenueForm venueId={venueId} refetch={refetch} close={close} />,
	})
}

interface EditVenueFormProps {
	venueId: string
	refetch: () => Promise<unknown>
	close: (x?: boolean) => void
}

interface FormData {
	name: string
	address: string
	phoneNumber: string
}

const EditVenueForm: FC<EditVenueFormProps> = ({ venueId, refetch, close }) => {
	const { data: venue } = useData<VenueModel>(`/Venues/${venueId}`, {}, { suspense: true })

	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: venue,
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/Venues/${venue?.id}`, { ...venue, ...formData })
			await refetch()
			close()
			toast({ title: 'Venue Updated' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput name="name" label="Name" validations={{ required: 'Name is required' }} autoFocus />
					<FormInput name="address" label="Address" validations={{ required: 'Address is required' }} />
					<FormInput
						name="phoneNumber"
						label="Phone number"
						validations={{ required: 'Phone number is required' }}
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditVenue
