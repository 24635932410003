import { FC, useState, ChangeEvent } from 'react'
import clsx from 'clsx'
import { warning } from 'alerts'
import { WEEK_DAY, WeekDay, TimeSlots } from 'types/enums'
import { Auth } from 'Auth'
import api from 'api'
import { format } from 'date-fns'
import useData from 'hooks/useData'
import produce from 'immer'
import Actions from 'components/Actions'
import Table, { TableSchema } from 'components/Table'
import { openModal, ModalFooter, useModalState } from 'hooks/useModal'
import { CheckIcon } from '@heroicons/react/outline'
import { AvailabilityTemplateModel } from 'types/models'
import { ModalResolveFn } from 'types'
import { getDateFromTime } from 'utils/funcs'
import { toast } from 'components/toast'

const days = Object.values(WeekDay.options.filter((x) => x.label !== 'Sunday' && x.label !== 'Saturday')).map(
	(x) => +x.value
)

const blocks: string[] = TimeSlots.map((x) => x.from24)

const openAvailabilityTemplate = () => {
	return openModal({
		title: 'Availability Template',
		size: 'xl',
		render: (close) => <AvailabilityTemplateForm close={close} />,
	})
}

interface AvailabilityTemplateFormProps {
	close: ModalResolveFn
}

interface Slot {
	id?: string
	time: string
	days: WEEK_DAY[]
}

const AvailabilityTemplateForm: FC<AvailabilityTemplateFormProps> = ({ close }) => {
	const { isSaving, setSaving } = useModalState()

	const { data: Templates = [], remove } = useData<AvailabilityTemplateModel[]>(
		`/AvailabilityTemplate/user?userId=${Auth.profile()?.id}`,
		{},
		{ suspense: true, optimisticResults: false }
	)

	const [slots, setSlots] = useState<Slot[]>(
		blocks
			.map((x) => ({
				time: x,
				days: [WEEK_DAY.Monday, WEEK_DAY.Tuesday, WEEK_DAY.Wednesday, WEEK_DAY.Thursday, WEEK_DAY.Friday],
			}))
			.map((slot) => {
				let foundTemplate = Templates.find((x) => x.time === slot.time)

				if (foundTemplate) {
					return {
						...slot,
						days: foundTemplate.days,
						id: foundTemplate.id,
					}
				}
				return slot
			})
	)

	const onToggleDay = (day: number, slotIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked

		setSlots(
			produce((draft) => {
				let foundIndex = draft[slotIndex].days.indexOf(day)

				if (draft[slotIndex].days.indexOf(day) > -1 && !checked) {
					draft[slotIndex].days.splice(foundIndex, 1)
				} else if (draft[slotIndex].days.indexOf(day) === -1 && checked) {
					draft[slotIndex].days.push(day)
				}
			})
		)
	}

	const onToggleColumn = (day: number, checked: boolean) => {
		if (checked) {
			setSlots((s) => s.map((x) => (x.days.includes(day) ? x : { ...x, days: [...x.days, day] })))
		} else {
			setSlots((s) => s.map((x) => ({ ...x, days: x.days.filter((y) => y !== day) })))
		}
	}

	const isAllChecked = (day: number) => {
		return slots.filter((x) => x.days.includes(day)).length === TimeSlots.length
	}

	const saveTemplate = async () => {
		let slotsToSave = slots.filter((slot) => {
			let original = Templates.find((x) => x.time === slot.time)

			if (original) {
				let slotSum = slot.days.reduce((a, b) => a + b, 0)
				let originalSum = original.days.reduce((a, b) => a + b, 0)

				if (originalSum === slotSum) {
					return false
				}
			}

			return true
		})

		if (!slotsToSave.length) {
			toast({ title: 'Availability Template Saved!' })
			close()
			return
		}

		setSaving(true)

		try {
			for (let i = 0; i < slotsToSave.length; i++) {
				let slot = slotsToSave[i]

				if (slot.id) {
					await api.put(`/AvailabilityTemplate/${slot.id}`, {
						user: {
							id: Auth.profile()?.id,
						},
						time: slot.time,
						days: slot.days,
						id: slot.id,
					})
				} else {
					await api.post('/AvailabilityTemplate', {
						user: {
							id: Auth.profile()?.id,
						},
						time: slot.time,
						days: slot.days,
					})
				}
			}

			toast({ title: 'Availability Template Saved!' })
			remove()

			close()
		} catch (error) {
			console.log(error)

			warning({ title: 'Something went wrong' })
			setSaving(false)
		}
	}

	const schema: TableSchema<Slot> = {
		cols: [
			{
				title: '',
				value: (slot) => format(getDateFromTime(slot.time), 'h:mm a'),
			},
			...days.map((day) => {
				return {
					title: (
						<div className="flex items-center space-x-4">
							<input
								type="checkbox"
								checked={isAllChecked(day)}
								onChange={(e) => onToggleColumn(day, e.target.checked)}
								className="form-checkbox text-primary-600 dark:text-emerald-500 dark:hover:text-emerald-600 dark:ring-emerald-500"
							/>
							<div>{WEEK_DAY[day]}</div>
						</div>
					),
					className: 'relative',
					value: (slot: Slot, i: number) => (
						<label className={clsx('absolute inset-0 flex items-center justify-center')}>
							<input
								type="checkbox"
								checked={slot.days.indexOf(day) > -1}
								onChange={onToggleDay(day, i)}
								className="form-checkbox text-primary-600 dark:text-emerald-500 dark:hover:text-emerald-600 dark:ring-emerald-500"
							/>
						</label>
					),
				}
			}),
		],
	}

	return (
		<>
			<Table items={slots} schema={schema} className="-my-px" />

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								icon: <CheckIcon className="w-5 h-5" />,
								intent: 'save',
								onClick: saveTemplate,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openAvailabilityTemplate
