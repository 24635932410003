import { FC, Suspense, useRef } from 'react'
import { Outlet, Link } from 'react-router-dom'
import logo from 'assets/img/newlogo/icra-acronym-white.png'
import Loader from 'components/Loader'
import Topbar from 'components/Topbar'
import MobileMenu from './MobileMenu'
import { isNotNone, LinkRouteConfig, None } from 'types'
import { Auth } from 'Auth'

const PageTopbar: FC = () => {
	const ref = useRef<HTMLDivElement>(null)

	const links: (LinkRouteConfig | None)[] = [
		Auth.is('Applicant', 'Respondent') && {
			title: 'My Cases',
			to: '/dashboard',
			icon: null,
			show: () => true,
			exact: true,
		},
		Auth.is('Applicant', 'Respondent') && {
			title: 'Diary',
			to: '/diary',
			icon: null,
			show: () => true,
			exact: true,
		},
		// Auth.is('ACCAdmin') && {
		// 	title: 'ACC Cases',
		// 	to: '/dashboard',
		// 	icon: null,
		// 	show: () => true,
		// 	exact: true,
		// },
	]

	return (
		<>
			<div className="min-h-screen bg-gray-200 dark:bg-gray-800">
				<MobileMenu links={links.filter(isNotNone)} withTopbar />

				<div className="bg-gray-800 dark:bg-gray-900 hidden md:block">
					<nav className="bg-gray-800 dark:bg-gray-900">
						<div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
							<div className="flex items-center justify-between h-16 px-4 sm:px-0">
								<div className="flex items-center">
									<div className="shrink-0">
										<Link to="/dashboard">
											<img src={logo} alt="ICRA" className="mb-2 h-10" />
										</Link>
									</div>
									<div className="hidden md:block">
										<div className="ml-10 flex items-baseline space-x-4">
											{links.filter(isNotNone).map((x, i) => (
												<Link
													key={i}
													to={x.to}
													className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
												>
													{x.title}
												</Link>
											))}
										</div>
									</div>
								</div>
								<div className="hidden md:block">
									<Topbar intent="dark" />
								</div>
							</div>
						</div>
					</nav>
				</div>

				<main ref={ref} className="relative z-0">
					<Suspense
						fallback={
							<div className="flex justify-center items-center h-screen">
								<Loader />
							</div>
						}
					>
						{/* <div className="relative z-20">{children}</div> */}
						<div className="relative z-20">
							<Outlet />
						</div>
					</Suspense>
				</main>
			</div>
		</>
	)
}

export default PageTopbar
