import { FC, useEffect, useState } from 'react'
import clsx, { ClassValue } from 'clsx'
import { usePopper } from 'hooks/usePopper'
import { Placement } from '@popperjs/core/index'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import useDismissable from 'hooks/useDismissable'
import {
	addDays,
	addMonths,
	endOfMonth,
	format,
	getDaysInMonth,
	getISODay,
	isSameDay,
	parse,
	startOfMonth,
	subDays,
	subMonths,
} from 'date-fns'
import { ensureDate, isValidDate } from 'utils/funcs'

const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

const isDate = (x: any): x is Date => x && x instanceof Date && isValidDate(x)

interface DatePickerProps {
	value: Date
	onChange: (x: Date | null) => void
	onBlur?: () => void
	placeholder?: string
	placement?: Placement
	offset?: number | [number, number]
	disabled?: boolean
	inputDisabled?: boolean
	hasError?: boolean
	className?: ClassValue
	inputClassName?: ClassValue
}

const DatePicker: FC<DatePickerProps> = ({
	value,
	onChange,
	onBlur,
	placeholder = 'DD/MM/YYYY',
	placement = 'bottom-end',
	offset = 10,
	disabled,
	inputDisabled,
	hasError,
	className,
	inputClassName,
}) => {
	const [display, setDisplay] = useState(isDate(value) ? format(value, 'dd/MM/yyyy') : '')
	const [context, setContext] = useState<Date>(startOfMonth(isDate(value) ? value : new Date()))
	const [prevValue, setPrevValue] = useState<Date | null | undefined>()

	useEffect(() => {
		if (prevValue !== value && value) {
			let ensuredDate = ensureDate(value)
			setDisplay(isDate(ensuredDate) ? format(ensuredDate, 'dd/MM/yyyy') : '')
			setPrevValue(value)
		}
	}, [prevValue, value])

	const {
		open,
		setOpen,
		ignoreClass,
		ref: dismissableRef,
	} = useDismissable({
		onOpen: () => {
			const hasValue = isDate(value)

			setDisplay(hasValue ? format(value, 'dd/MM/yyyy') : '')
			setContext(hasValue ? value : new Date())
		},
	})

	const [reference, tooltip] = usePopper({
		placement,
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: { offset: offset instanceof Array ? offset : [0, offset] },
			},
		],
	})

	const preDays = new Array(getISODay(startOfMonth(context)) - 1)
		.fill(null)
		.map((_, i) => subDays(endOfMonth(subMonths(context, 1)), i))
		.reverse()

	const postDays = new Array(7 - getISODay(endOfMonth(context)))
		.fill(null)
		.map((_, i) => addDays(startOfMonth(addMonths(context, 1)), i))

	const days = new Array(getDaysInMonth(context)).fill(null).map((_, i) => addDays(startOfMonth(context), i))

	const select = (day: Date) => {
		onChange(day)
		setOpen(false)

		setDisplay(format(day, 'dd/MM/yyyy'))
	}

	return (
		<div className={clsx(className)} ref={dismissableRef}>
			<div className={clsx('relative rounded-md')}>
				<input
					type="text"
					value={display}
					onChange={(e) => setDisplay(e.target.value)}
					placeholder={placeholder}
					disabled={disabled}
					ref={reference}
					onClick={() => setOpen(true)}
					onKeyDown={(e) => {
						if (inputDisabled) {
							e.preventDefault()
						} else {
							setOpen(false)
						}
					}}
					onBlur={() => {
						if (!display) {
							onChange(null)
						} else {
							let displayDate = parse(display, 'dd/MM/yyyy', new Date())

							if (isValidDate(displayDate)) {
								onChange(displayDate)
							} else {
								setDisplay(isDate(value) ? format(value, 'dd/MM/yyyy') : '')
							}
						}

						if (onBlur) onBlur()
					}}
					className={clsx(
						'form-input block w-full sm:text-sm sm:leading-5',
						hasError &&
							'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
						inputClassName,
						ignoreClass
					)}
				/>

				<div className={clsx(!open && 'hidden', 'z-10')} ref={tooltip}>
					<div className="mt rounded-md shadow-lg bg-white dark:bg-gray-700 w-80 overflow-hidden">
						<div className="flex items-center dark:bg-gray-750 p-4 border-b border-gray-100 dark:border-gray-600">
							<button
								type="button"
								onClick={() => setContext((c) => subMonths(c, 1))}
								className="inline-flex items-center font-medium border transition ease-in-out duration-150 disabled:cursor-not-allowed border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500 text-gray-700 active:text-gray-800 hover:text-gray-500 dark:text-gray-200 dark:active:text-white dark:hover:text-white bg-white active:bg-gray-50 dark:bg-gray-800 dark:active:bg-gray-900 dark:hover:bg-gray-750 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-700 px-3 py-2 text-sm leading-5 rounded-md"
							>
								<ArrowLeftIcon className="w-4 h-4" />
							</button>

							<div className="flex-1 flex items-center justify-center text-sm font-medium">
								{format(context, 'MMMM yyyy')}
							</div>

							<button
								type="button"
								onClick={() => setContext((c) => addMonths(c, 1))}
								className="inline-flex items-center font-medium border transition ease-in-out duration-150 disabled:cursor-not-allowed border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500 text-gray-700 active:text-gray-800 hover:text-gray-500 dark:text-gray-200 dark:active:text-white dark:hover:text-white bg-white active:bg-gray-50 dark:bg-gray-800 dark:active:bg-gray-900 dark:hover:bg-gray-750 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-700 px-3 py-2 text-sm leading-5 rounded-md"
							>
								<ArrowRightIcon className="w-4 h-4" />
							</button>
						</div>

						<div className="p-4 grid grid-cols-7">
							{weekDays.map((day, i) => (
								<div
									key={i}
									className="col-span-1 w-8 h-8 flex items-center justify-center rounded m-1 cursor-default text-sm text-gray-400"
								>
									{day}
								</div>
							))}

							{preDays.map((day, i) => (
								<div
									key={i}
									className="col-span-1 w-8 h-8 flex items-center justify-center rounded text-gray-300 dark:text-gray-500 m-1 cursor-default text-sm font-medium"
								>
									{format(day, 'd')}
								</div>
							))}

							{days.map((day, i) => (
								<button
									key={i}
									type="button"
									className={clsx(
										'col-span-1 w-7 h-7 flex items-center justify-center rounded m-2 cursor-pointer text-sm font-medium hover:bg-primary-100 dark:hover:bg-gray-800',
										isSameDay(new Date(), day) && 'text-primary-400 ring-2 ring-primary-300',
										value && isSameDay(ensureDate(value), day) && 'bg-primary-600 text-white'
									)}
									onClick={() => select(day)}
								>
									{format(day, 'd')}
								</button>
							))}

							{postDays.map((day, i) => (
								<div
									key={i}
									className="col-span-1 w-8 h-8 flex items-center justify-center rounded text-gray-300 dark:text-gray-500 m-1 cursor-default text-sm font-medium"
								>
									{format(day, 'd')}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DatePicker
