import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { toast } from 'components/toast'

interface OpenCreateOrganizationProps {
	refetch: () => Promise<unknown>
}

const openCreateOrganization = ({ refetch }: OpenCreateOrganizationProps) => {
	return openModal({
		title: 'Create Organisation',
		render: (close) => <CreateOrganizationForm refetch={refetch} close={close} />,
	})
}

interface CreateOrganizationFormProps {
	refetch: () => Promise<unknown>
	close: (answer?: boolean) => void
}

interface FormData {
	name: string
	billingName: string
	email: string
	phone: string
}

const CreateOrganizationForm: FC<CreateOrganizationFormProps> = ({ refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			name: '',
			billingName: '',
			email: '',
			phone: '',
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.post('/Organizations', formData)
			await refetch()
			close()
			toast({ title: 'Organisation Created' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput name="name" label="Name" validations={{ required: 'Name is required' }} autoFocus />
					<FormInput
						name="billingName"
						label="Billing Name"
						validations={{ required: 'Billing Name is required' }}
					/>
					<FormInput name="email" label="Email" validations={{ required: 'Email is required' }} />
					<FormInput name="phone" label="Phone" validations={{ required: 'Phone is required' }} />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openCreateOrganization
