import { FC, useState } from 'react'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Card from 'components/Card'
import Stack from 'components/Stack'
import { Auth } from 'Auth'
import api from 'api'
import { ClockIcon, RefreshIcon, DownloadIcon } from '@heroicons/react/outline'
import {
	format,
	startOfMonth,
	endOfMonth,
	addMonths,
	getDaysInMonth,
	addDays,
	isSameDay,
	parseISO,
	endOfDay,
	startOfDay,
} from 'date-fns'
import useBufferedData from 'hooks/useBufferedData'
import MonthSwitcher from 'components/MonthSwitcher'
import { confirm, toast } from 'alerts'
import openAvailabilityTemplate from 'modals/openAvailabilityTemplate'
import FileSaver from 'file-saver'
import { AvailabilityModel } from 'types/models'
import Calendar, { CalendarSchema } from 'components/Calendar'
import openEditAvailabilitySlideout from 'slideouts/openEditAvailabilitySlideout'
import openViewAvailabilitySlideout from 'slideouts/openViewAvailabilitySlideout'
import Actions from 'components/Actions'
import { getConferencesForTimeSlot, getReservedDate } from 'utils/funcs'
import { gql } from '@apollo/client'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { GQLConnection, GQLEventType } from 'types/gql'
import { TimeSlots } from 'types/enums'

const query = gql`
	query ($search: EventSearch) {
		Events(search: $search, orderBy: date_DESC) {
			items {
				id
				startDate
				endDate
				case {
					id
					caseNumber
				}
			}
		}
	}
`

const Availability: FC = () => {
	const [startDate, setStartDate] = useState(startOfMonth(new Date()))
	const [endDate, setEndDate] = useState(endOfMonth(new Date()))

	const {
		data: events,
		isLoading,
		refetch,
	} = useBufferedData<AvailabilityModel[]>(
		`/Availability`,
		{
			startDate: format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss"),
			endDate: format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss"),
		},
		{
			enabled: Auth.is('Reviewer', 'Mediator', 'Adjudicator', 'Facilitator'),
		}
	)

	const { data: gqlData } = useBufferedQuery<{ Events: GQLConnection<GQLEventType> }>(query, {
		variables: {
			search: {
				isPostponed: false,
				from: startOfDay(startDate).toISOString(),
				to: endOfDay(endDate).toISOString(),
			},
		},
	})

	const reviewerSchema: CalendarSchema<number> = {
		onClick: Auth.is('Reviewer', 'Mediator', 'Adjudicator', 'Facilitator')
			? (_, date) => !getReservedDate(date) && openEditAvailabilitySlideout({ date, refetch })
			: null,
		render: (hrs, date) => (getReservedDate(date) ? <div>{getReservedDate(date)}</div> : <div>{hrs} hrs</div>),
	}

	const adminSchema: CalendarSchema<number> = {
		onClick: (_, date) => !getReservedDate(date) && openViewAvailabilitySlideout({ date }),
		render: (x, date) => (getReservedDate(date) ? <div>{getReservedDate(date)}</div> : null),
	}

	const items = new Array(getDaysInMonth(startDate)).fill(null).map((_, i) => {
		let filteredEvents = events?.filter((x) => isSameDay(addDays(startDate, i), parseISO(x.startDate))) || []

		let confs = gqlData?.Events?.items
			? TimeSlots.reduce<number>((n, slot) => {
					if (!gqlData?.Events?.items) return n
					let foundEvents = getConferencesForTimeSlot(addDays(startDate, i), slot, gqlData.Events.items)

					if (foundEvents.length > 0) return n + 0.5
					return n
			  }, 0)
			: 0

		return {
			date: addDays(startDate, i),
			value: filteredEvents.length > 0 ? filteredEvents.length * 0.5 - confs : 0,
		}
	})

	const regenerateAvailability = async () => {
		confirm({
			title: `Are you sure you want to regenerate your availability for ${format(startDate, 'MMMM yyyy')}?`,
			intent: 'primary',
			onAccept: async () => {
				const pattern = "yyyy-MM-dd'T'HH:mm:ss"

				const start = format(startDate, pattern)
				const end = format(endDate, pattern)

				try {
					await api.post(
						`/Availability/regenerate?startDate=${start}&endDate=${end}&availabilityUserId=${
							Auth.profile()?.id
						}`
					)

					refetch()

					toast({ title: 'Availability Regenerated' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const downloadAvailability = async () => {
		const start = startOfMonth(new Date())
		const end = addMonths(start, 5)

		const { data } = await api.get<Blob>(
			`/Availability/csv?startDate=${format(start, "yyyy-MM-dd'T'HH:mm:ss")}&endDate=${format(
				end,
				"yyyy-MM-dd'T'HH:mm:ss"
			)}`
		)

		FileSaver.saveAs(new Blob([data], { type: 'text/plain;charset=utf-8' }), 'availability.csv')
	}

	return (
		<>
			<PageHeading
				title={Auth.is('Admin', 'CaseManager', 'FENZAdmin') ? 'Availability' : 'My Availability'}
				actions={[
					Auth.is('Reviewer', 'Adjudicator', 'Facilitator') && {
						title: 'Edit Template',
						intent: 'secondary',
						rounded: 'md',
						icon: <ClockIcon className="w-5 h-5" />,
						onClick: () => openAvailabilityTemplate(),
					},
					Auth.is('Reviewer', 'Adjudicator', 'Facilitator') && {
						title: 'Regenerate Month',
						intent: 'secondary',
						rounded: 'md',
						icon: <RefreshIcon className="w-5 h-5" />,
						onClick: () => regenerateAvailability(),
						disabled: !refetch,
					},
					Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
						title: 'Download Availability',
						intent: 'primary',
						rounded: 'md',
						icon: <DownloadIcon className="w-5 h-5" />,
						onClick: () => downloadAvailability(),
					},
				]}
			/>

			<PageContent>
				<Card>
					<Stack>
						<div className="flex justify-end space-x-4">
							<Actions
								actions={[
									{
										title: 'Today',
										rounded: 'md',
										onClick: () => {
											setStartDate(startOfMonth(new Date()))
											setEndDate(endOfMonth(new Date()))
										},
									},
								]}
							/>

							<MonthSwitcher
								value={[startDate, endDate]}
								onChange={(values) => {
									setStartDate(values[0])
									setEndDate(values[1])
								}}
							/>
						</div>

						<Calendar
							contextDate={startDate}
							items={items}
							schema={Auth.is('Admin', 'CaseManager', 'FENZAdmin') ? adminSchema : reviewerSchema}
							isLoading={isLoading}
						/>
					</Stack>
				</Card>
			</PageContent>
		</>
	)
}

export default Availability
