import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { smartReplaceClasses } from 'utils/funcs'

const fixClasses = (className = '') =>
	smartReplaceClasses(className, [{ pattern: /^max-w-\dxl$/, defaultValue: 'max-w-7xl' }])

interface PageContentProps {
	className?: ClassValue
	width?: number
	children: ReactNode
}

const PageContent: FC<PageContentProps> = ({ className, children }) => {
	return <div className={fixClasses(clsx('w-full mx-auto pb-8 sm:px-6 lg:px-8', className))}>{children}</div>
}

export default PageContent
