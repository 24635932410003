import { FC } from 'react'
import * as ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import queryClient from './QueryClient'
import client from 'utils/ApolloClient'
import { ApolloProvider } from '@apollo/client'

const mountedAlerts: HTMLDivElement[] = []

interface DialogContentType<T> {
	resolve: (x?: T) => void
	close: () => void
}

function alert<T>(DialogContent: FC<DialogContentType<T>>): Promise<T | undefined> {
	return new Promise((resolvePromise) => {
		const alertRoot = document.createElement('div')
		const body = document.body
		body.appendChild(alertRoot)
		mountedAlerts.push(alertRoot)

		const close = () => {
			ReactDOM.unmountComponentAtNode(alertRoot)
			mountedAlerts.splice(
				mountedAlerts.findIndex((x) => x === alertRoot),
				1
			)
			if (document.contains(alertRoot)) {
				document.body.removeChild(alertRoot)
			}
		}

		const resolve = (x?: T) => resolvePromise(x)

		ReactDOM.render(
			<QueryClientProvider client={queryClient}>
				<ApolloProvider client={client}>
					<DialogContent resolve={resolve} close={close} />
				</ApolloProvider>
			</QueryClientProvider>,
			alertRoot
		)
	})
}

export default alert
