import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import { format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns'

interface MonthSwitcherProps {
	value: [Date, Date]
	onChange: (x: [Date, Date]) => void
	className?: ClassValue
}

const MonthSwitcher: FC<MonthSwitcherProps> = ({ value, onChange, className }) => {
	const next = () => onChange([startOfMonth(addMonths(value[0], 1)), endOfMonth(addMonths(value[1], 1))])
	const prev = () => onChange([startOfMonth(subMonths(value[0], 1)), endOfMonth(subMonths(value[1], 1))])

	return (
		<div className={clsx('flex', className)}>
			<span className="relative z-0 inline-flex rounded-tl-md rounded-bl-md">
				<button
					type="button"
					onClick={prev}
					className={clsx(
						'relative inline-flex items-center px-4 py-2 rounded-tl-md rounded-bl-md border bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 dark:text-gray-200 dark:bg-gray-800',
						'border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500',
						'focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-700'
					)}
					aria-label="Previous"
				>
					<ArrowLeftIcon className="w-4 h-4" />
				</button>
			</span>
			<span className="-ml-px w-24 inline-flex items-center justify-center border border-gray-300 bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 text-sm leading-5 font-medium text-gray-700">
				{format(value[0], 'MMM yyyy')}
			</span>
			<span className="-ml-px relative z-0 inline-flex rounded-tr-md rounded-br-md">
				<button
					type="button"
					onClick={next}
					className={clsx(
						'relative inline-flex items-center px-4 py-2 rounded-tr-md rounded-br-md border bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 dark:text-gray-200 dark:bg-gray-800',
						'border-gray-300 focus:border-primary-500 dark:border-gray-600 dark:focus:border-primary-500',
						'focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-700'
					)}
					aria-label="Next"
				>
					<ArrowRightIcon className="w-4 h-4" />
				</button>
			</span>
		</div>
	)
}

export default MonthSwitcher
