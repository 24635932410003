import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import Actions from './Actions'
import { ActionType, isNotNone } from 'types'
import ButtonSpacer from './ButtonSpacer'

interface SectionHeaderProps {
	title: ReactNode
	subtitle?: ReactNode
	actions?: ActionType[]
	className?: ClassValue
}

const SectionHeader: FC<SectionHeaderProps> = ({ title, subtitle, actions, className }) => (
	<div className={clsx('flex justify-between items-center flex-wrap sm:flex-nowrap', className)}>
		<div>
			<h3 className="text-lg leading-5 font-medium text-gray-900 dark:text-white">{title}</h3>
			{subtitle && <p className="mt-1 text-sm leading-5 text-gray-500 dark:text-gray-300">{subtitle}</p>}
		</div>
		<div className="shrink-0">
			{actions && actions.filter(isNotNone).length > 0 ? <Actions actions={actions} /> : <ButtonSpacer />}
		</div>
	</div>
)

export default SectionHeader
