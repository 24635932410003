import { FC } from 'react'
import Actions from './Actions'
import { ActionType } from 'types'
import clsx, { ClassValue } from 'clsx'
import ButtonSpacer from './ButtonSpacer'

interface CardHeaderProps {
	title: string
	subtitle?: string
	actions?: ActionType[]
	className?: ClassValue
}

const CardHeader: FC<CardHeaderProps> = ({ title, subtitle, actions, className }) => {
	return (
		<div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
			<div
				className={clsx(
					'flex flex-col sm:flex-row justify-between items-start sm:items-center flex-wrap sm:flex-nowrap space-y-4 sm:space-y-0',
					className
				)}
			>
				<div>
					<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{title}</h3>
					{subtitle && <p className="mt-1 text-sm leading-5 text-gray-500 dark:text-gray-400">{subtitle}</p>}
				</div>

				<div className="shrink-0 my-0 sm:-my-2">
					{actions && actions.length > 0 ? (
						<Actions actions={actions} />
					) : (
						<div className="hidden sm:block">
							<ButtonSpacer />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default CardHeader
