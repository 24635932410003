import { FC } from 'react'
import Button from 'components/Button'
import Stack from 'components/Stack'
import { XIcon, CheckIcon } from '@heroicons/react/outline'
import logo from 'assets/img/newlogo/icra-acronym-white.png'
import { openModal, ModalContent } from 'hooks/useModal'

const openInvitationModal = () => {
	return openModal<boolean>({
		render: (close) => <InvitationModal close={close} />,
	})
}

interface InvitationModalProps {
	close: (answer: boolean) => void
}

const InvitationModal: FC<InvitationModalProps> = ({ close }) => (
	<ModalContent>
		<div className="flex justify-center mb-10 mt-4">
			<img src={logo} alt="ICRA" className="h-12" />
		</div>

		<Stack space={4}>
			<p>I accept the appointment and confirm that:</p>

			<p>
				To the best of my knowledge there are no circumstances past or present likely to give rise to
				justifiable doubts as to my impartiality or independence in the eyes of any of the parties; and
			</p>

			<p>
				I will assume a continuing duty to disclose forthwith to the parties any circumstances arising in the
				future likely to give rise to justifiable doubts as to my impartiality or independence in the eyes of
				any of the parties until the review is concluded.
			</p>

			<Stack className="pt-4">
				<Button intent="danger" onClick={() => close(false)} className="w-full justify-center">
					<XIcon className="w-5 h-5 mr-2" />I decline the appointment
				</Button>

				<Button intent="primary" onClick={() => close(true)} className="w-full justify-center">
					<CheckIcon className="w-5 h-5 mr-2" />I accept the appointment
				</Button>
			</Stack>
		</Stack>
	</ModalContent>
)

export default openInvitationModal
