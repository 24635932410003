import { FC } from 'react'
import PageHeading from 'components/PageHeading'
import CaseStatusBadge from 'components/CaseStatusBadge'
import { Auth } from 'Auth'
import { CASE_STATUS, CaseStatus, CASE_APPEAL_STATUS, CaseAppealStatus, REMINDER_TYPE } from 'types/enums'
import api from 'api'
import { BellIcon, ChevronDownIcon, HashtagIcon, UserCircleIcon, CheckIcon, FireIcon } from '@heroicons/react/outline'
import openCreateReminder from 'modals/openCreateReminder'
import { confirm } from 'alerts'
import { CaseModel } from 'types/models'
import openChangeCaseStatus from 'modals/openChangeCaseStatus'
import { RefetchFn } from 'types'
import { FENZOrgId } from 'utils/constants'
import { toast } from 'components/toast'

interface CaseHeaderProps {
	caseData?: CaseModel
	refetch: RefetchFn
}

const CaseHeader: FC<CaseHeaderProps> = ({ caseData, refetch }) => {
	const setAppealStatus = (newStatus: CASE_APPEAL_STATUS) => {
		confirm({
			title: `Are you sure you want to set the case appeal status to "${CaseAppealStatus.readable(newStatus)}"?`,
			intent: 'primary',
			onAccept: async () => {
				try {
					await api.put(`/Cases/${caseData?.id}`, {
						...caseData,
						appealStatus: newStatus,
					})

					refetch()

					toast({ title: 'Appeal Status Updated' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const removeAppealStatus = (newStatus: CASE_APPEAL_STATUS) => {
		confirm({
			title: 'Are you sure you want to remove Appeal Status?',
			intent: 'danger',
			onAccept: async () => {
				try {
					await api.put(`/Cases/${caseData?.id}`, {
						...caseData,
						appealStatus: newStatus,
					})

					refetch()

					toast({ title: 'Appeal Status Updated' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const isFENZ = caseData?.organization?.id === FENZOrgId

	return (
		<PageHeading
			pageTitle={`Case: ${caseData?.caseNumber}`}
			title={caseData?.caseNumber}
			infoList={[
				{
					title: <CaseStatusBadge status={caseData?.status} size="sm" />,
				},
				{
					title: caseData?.fields?.find((x) => x.name === 'applicantName')?.value || '-',
					icon: <UserCircleIcon className="w-5 h-5" />,
				},
				!isFENZ && {
					title: caseData?.fields?.find((x) => x.name === 'accClaimNumber')?.value || '-',
					icon: <HashtagIcon className="w-5 h-5" />,
				},
				isFENZ && {
					title: 'Fire Emergency New Zealand',
					icon: <FireIcon className="w-5 h-5" />,
				},
			]}
			actions={[
				caseData?.status === CASE_STATUS.Open &&
					Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
						title: (
							<div className="flex">
								<BellIcon className="w-5 h-5 -ml-1 mr-2" />
								Create Reminder
							</div>
						),
						intent: 'secondary',
						rounded: 'md',
						icon: <ChevronDownIcon className="w-5 h-5" />,
						iconSide: 'right',
						actions: [
							{
								title: 'Minute Required',
								onClick: () => openCreateReminder({ caseData, type: REMINDER_TYPE.Minute }),
							},
							{
								title: 'Adjournment Minute Required',
								onClick: () => openCreateReminder({ caseData, type: REMINDER_TYPE.AdjournmentMinute }),
							},
							{
								title: 'Withdrawal Decision Required',
								onClick: () => openCreateReminder({ caseData, type: REMINDER_TYPE.WithdrawalDecision }),
							},
							{
								title: 'Decision Required',
								onClick: () => openCreateReminder({ caseData, type: REMINDER_TYPE.Decision }),
							},
						],
					},
				(caseData?.status === CASE_STATUS.DecisionIssued ||
					caseData?.status === CASE_STATUS.DecisionIssuedDismissed ||
					caseData?.status === CASE_STATUS.DecisionIssuedQuashedDirectionGiven ||
					caseData?.status === CASE_STATUS.DecisionIssuedQuashedSubstituted ||
					caseData?.status === CASE_STATUS.DecisionIssuedACCToMakeDecision ||
					caseData?.status === CASE_STATUS.DecisionIssuedReviewUpheld ||
					caseData?.status === CASE_STATUS.DecisionIssuedModified ||
					caseData?.status === CASE_STATUS.DismissedNoJurisdiction ||
					caseData?.status === CASE_STATUS.Withdrawn ||
					caseData?.status === CASE_STATUS.WithdrawnSettled ||
					caseData?.status === CASE_STATUS.WithdrawnBeforeCMC ||
					caseData?.status === CASE_STATUS.WithdrawnBeforeCMCSettled ||
					caseData?.status === CASE_STATUS.WithdrawnACCRevokedDecision ||
					caseData?.status === CASE_STATUS.WithdrawnBeforeCMCACCRevokedDecision) &&
					caseData?.appealStatus !== null &&
					caseData?.appealStatus !== 0 &&
					Auth.is('Admin') && {
						title: CaseAppealStatus.readable(CASE_APPEAL_STATUS.RemoveAppealStatus),
						// intent: 'danger',
						rounded: 'md',
						onClick: () => removeAppealStatus(CASE_APPEAL_STATUS.RemoveAppealStatus),
					},
				(caseData?.status === CASE_STATUS.DecisionIssued ||
					caseData?.status === CASE_STATUS.DecisionIssuedDismissed ||
					caseData?.status === CASE_STATUS.DecisionIssuedQuashedDirectionGiven ||
					caseData?.status === CASE_STATUS.DecisionIssuedQuashedSubstituted ||
					caseData?.status === CASE_STATUS.DecisionIssuedACCToMakeDecision ||
					caseData?.status === CASE_STATUS.DecisionIssuedReviewUpheld ||
					caseData?.status === CASE_STATUS.DecisionIssuedModified ||
					caseData?.status === CASE_STATUS.DismissedNoJurisdiction ||
					caseData?.status === CASE_STATUS.Withdrawn ||
					caseData?.status === CASE_STATUS.WithdrawnSettled ||
					caseData?.status === CASE_STATUS.WithdrawnBeforeCMC ||
					caseData?.status === CASE_STATUS.WithdrawnBeforeCMCSettled ||
					caseData?.status === CASE_STATUS.WithdrawnACCRevokedDecision ||
					caseData?.status === CASE_STATUS.WithdrawnBeforeCMCACCRevokedDecision) &&
					Auth.is('Admin', 'FENZAdmin') && {
						title: 'Set Appeal Status',
						intent: 'secondary',
						rounded: 'md',
						icon: <ChevronDownIcon className="w-5 h-5" />,
						iconSide: 'right',
						actions: [
							[
								{
									title: CaseAppealStatus.readable(CASE_APPEAL_STATUS.DecisionUnderAppeal),
									onClick: () => setAppealStatus(CASE_APPEAL_STATUS.DecisionUnderAppeal),
								},
							],
							[
								{
									title: CaseAppealStatus.readable(CASE_APPEAL_STATUS.AppealWithdrawn),
									onClick: () => setAppealStatus(CASE_APPEAL_STATUS.AppealWithdrawn),
								},
							],
							[
								{
									title: CaseAppealStatus.readable(CASE_APPEAL_STATUS.AppealDismissed),
									onClick: () => setAppealStatus(CASE_APPEAL_STATUS.AppealDismissed),
								},
								{
									title: CaseAppealStatus.readable(CASE_APPEAL_STATUS.ReviewDecisionModified),
									onClick: () => setAppealStatus(CASE_APPEAL_STATUS.ReviewDecisionModified),
								},
								{
									title: CaseAppealStatus.readable(
										CASE_APPEAL_STATUS.ReviewDecisionQuashedSubstituted
									),
									onClick: () => setAppealStatus(CASE_APPEAL_STATUS.ReviewDecisionQuashedSubstituted),
								},
							],
						],
					},
				(caseData?.status || 0) > CASE_STATUS.Open &&
					Auth.is('Admin', 'FENZAdmin') && {
						title: 'Change Case Status',
						intent: 'secondary',
						rounded: 'md',
						onClick: () => {
							if (caseData)
								openChangeCaseStatus({
									title: 'Change Case Status',
									caseData,
									refetch,
									options: CaseStatus.options.filter((x) => x.value > 2),
								})
						},
					},
				caseData?.status === CASE_STATUS.Open &&
					Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
						title: (
							<div className="flex">
								<CheckIcon className="w-5 h-5 -ml-1 mr-2" />
								Close Case
							</div>
						),
						intent: 'secondary',
						rounded: 'md',
						onClick: () => {
							if (caseData)
								openChangeCaseStatus({
									caseData,
									refetch,
									options: CaseStatus.options.filter((x) => x.value > 4),
								})
						},
					},
			]}
		/>
	)
}

export default CaseHeader
