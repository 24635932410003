import { FC, useRef } from 'react'
import CaseHeader from 'components/CaseHeader'
import FileExplorer from 'components/FileExplorer'
import { Auth } from 'Auth'
import useData from 'hooks/useData'
import { UploadIcon, DocumentAddIcon, ChevronDownIcon } from '@heroicons/react/outline'
import openFileUploadApplicant from 'modals/openFileUploadApplicant'
import openCreateSubmission from 'modals/openCreateSubmission'
import PageNavbar from 'components/PageNavbar'
import Stack from 'components/Stack'
import SectionHeader from 'components/SectionHeader'
import caseLinks from './utils/links'
import { useParams } from 'react-router-dom'
import { CaseModel } from 'types/models'
import { RefetchFn } from 'types'

// interface FilesProps {
// 	caseId?: string
// }

const Files: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const ref = useRef<{ refetch: RefetchFn }>()

	const links = caseData ? caseLinks(caseData) : []

	const isAdvocate = !!caseData?.applicant?.parties.find((x) => x.id === Auth.profile()?.id)

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader
						title="Documents and Submissions"
						actions={[
							(Auth.profile()?.id === caseData?.applicant?.user?.id || isAdvocate) && {
								title: 'Add File(s)',
								icon: <ChevronDownIcon className="w-5 h-5" />,
								iconSide: 'right',
								intent: 'primary',
								actions: [
									{
										title: 'Create Submission',
										intent: 'secondary',
										icon: <DocumentAddIcon className="w-5 h-5" />,
										onClick: () =>
											caseData &&
											openCreateSubmission({ caseData, refetch: ref?.current?.refetch }),
									},
									{
										title: 'Upload Files',
										intent: 'secondary',
										icon: <UploadIcon className="w-5 h-5" />,
										onClick: () =>
											caseData &&
											openFileUploadApplicant({ caseData, refetch: ref?.current?.refetch }),
									},
								],
							},
						]}
					/>

					{caseData && <FileExplorer cases={[caseData]} ref={ref} open />}
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Files
