import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { path } from 'ramda'
import { isNotNone } from 'types'
import clsx, { ClassValue } from 'clsx'
import { split } from 'utils/funcs'

interface ErrorBadgeProps {
	fields: string[]
	className?: ClassValue
}

const ErrorBadge: FC<ErrorBadgeProps> = ({
	fields,
	className = '-my-1 px-1 py-0.5 min-w-[25px] rounded bg-red-200 text-red-600 dark:bg-opacity-25 dark:bg-red-200 dark:text-red-200 text-sm font-medium text-center mr-2',
}) => {
	const {
		formState: { errors },
	} = useFormContext()

	const count = fields.map((x) => path(split(x), errors)).filter(isNotNone)

	return count.length ? <div className={clsx(className)}>{count.length}</div> : null
}

export default ErrorBadge
