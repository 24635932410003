import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { isNotNone } from 'types'

interface DataListItemProps {
	title: ReactNode
	value: ReactNode
}

type DataListItem = DataListItemProps | false | undefined | null

interface DataListSingleProps {
	items: DataListItem[]
	striped?: boolean
	className?: ClassValue
}

const DataListSingle: FC<DataListSingleProps> = ({ items, striped, className }) => (
	<div className={clsx(className)}>
		<dl>
			{items.filter(isNotNone).map(
				(item, i) =>
					item.value && (
						<div
							key={i}
							className={clsx(
								'px-4 py-5 grid grid-cols-3 gap-4',
								striped
									? {
											'bg-gray-50 dark:bg-gray-750': i % 2,
											'bg-white dark:bg-gray-700': !(i % 2),
									  }
									: {
											'py-5': i === 0,
											'border-t border-gray-200 dark:border-gray-600 py-5': i > 0,
									  }
							)}
						>
							<dt className="text-sm leading-5 font-medium text-gray-500 dark:text-gray-300">
								{item.title}
							</dt>
							<dd className="text-sm leading-5 break-normal text-gray-900 dark:text-white mt-0 col-span-2">
								{item.value}
							</dd>
						</div>
					)
			)}
		</dl>
	</div>
)

export default DataListSingle
