import { FC } from 'react'
import openSlideout, { SlideoutHeader, SlideoutContent } from 'hooks/openSlideout'
import { getFullName } from 'utils/funcs'
import DataListSingle from 'components/DataListSingle'
import Badge from 'components/Badge'
import { CheckIcon, XIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import Address from 'components/Address'
import { UserModel } from 'types/models'
import { UserRole } from 'types/enums'
import useData from 'hooks/useData'
import { Auth } from 'Auth'
import { navigate } from 'components/NavigateHoist'

const openUserSlideout = (props: OpenUserSlideout) => {
	return openSlideout({
		render: (close) => <ViewUser {...props} close={close} />,
	})
}

interface OpenUserSlideout {
	userId: string
}

const ViewUser: FC<OpenUserSlideout & { close: () => void }> = ({ userId, close }) => {
	const { data: user } = useData<UserModel>(`/Users/${userId}`, {}, { suspense: true })

	if (!user) return null

	return (
		<>
			<SlideoutHeader
				title={getFullName(user)}
				actions={
					Auth.is('Admin', 'CaseManager', 'FENZAdmin')
						? [
								{
									// changed to `button` from `to` for navigate hoist
									icon: <ExternalLinkIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => {
										navigate(`/users/${user.id}`)
										close()
									},
								},
						  ]
						: undefined
				}
			/>
			<SlideoutContent>
				<DataListSingle
					items={[
						{ title: 'Email address', value: <div className="break-all">{user.email}</div> },
						{ title: 'Phone', value: user.phoneNumber },
						{
							title: 'Organisation',
							value: user.organization?.name,
						},
						Auth.is('Admin', 'CaseManager', 'Reviewer', 'FENZAdmin') &&
							user.physicalAddress && {
								title: 'Address',
								value: <Address address={user.physicalAddress} />,
							},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Notes',
							value: user.note,
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Roles',
							value: (
								<>
									<ul className="pl-5 list-disc">
										{user.roles.map((x) => (
											<li key={x.id}>{UserRole.readable(x.id)}</li>
										))}
									</ul>
									{user.roles.length === 0 && <div>No roles</div>}
								</>
							),
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Verified',
							value: user?.emailConfirmed ? (
								<Badge color="green" size="sm" icon={<CheckIcon className="w-5 h-5 mr-1.5" />}>
									Yes
								</Badge>
							) : (
								<Badge color="red" size="sm" icon={<XIcon className="w-5 h-5 mr-1.5" />}>
									No
								</Badge>
							),
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
							title: 'Notifications',
							value: user?.notificationsEnabled ? (
								<Badge color="green" size="sm" icon={<CheckIcon className="w-5 h-5 mr-1.5" />}>
									Enabled
								</Badge>
							) : (
								<Badge color="red" size="sm" icon={<XIcon className="w-5 h-5 mr-1.5" />}>
									Disabled
								</Badge>
							),
						},
					]}
				/>
			</SlideoutContent>
		</>
	)
}

export default openUserSlideout
