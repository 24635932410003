import { FC } from 'react'
import { API_METRIC_TYPE, METRIC_ROLE_TYPE } from 'types/enums'
import DashboardViewCard from 'components/DashboardViewCard'

const SupportDashboard: FC = () => {
	const roleType = METRIC_ROLE_TYPE.Support

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
			<DashboardViewCard
				title="Number of pending cases"
				search={{
					metricType: API_METRIC_TYPE.PendingCases,
					roleType,
				}}
				to="/dashboard/pending-cases"
			/>

			<DashboardViewCard
				title="Number of active cases"
				search={{
					metricType: API_METRIC_TYPE.ActiveCases,
					roleType,
				}}
				to="/dashboard/active-cases"
			/>

			<DashboardViewCard
				title="Case assignments declined by the Reviewer"
				search={{ metricType: API_METRIC_TYPE.ReviewerDeclined, roleType }}
				to="/dashboard/reviewer-declined"
			/>

			<DashboardViewCard
				title="Cases without a hearing set down"
				search={{ metricType: API_METRIC_TYPE.NoHearingSetDown, roleType }}
				to="/dashboard/no-hearing-set-down"
			/>

			<DashboardViewCard
				title="Case assignments not yet accepted by the reviewer"
				search={{
					metricType: API_METRIC_TYPE.NeedsApproval,
					roleType,
				}}
				to="/dashboard/cases-with-unapproved-assignments"
			/>

			<DashboardViewCard
				title="Cases with decisions due in the next 14 days"
				search={{ metricType: API_METRIC_TYPE.DecisionDue, dueDays: 14, roleType }}
				to="/dashboard/decision-due"
			/>
		</div>
	)
}

export default SupportDashboard
