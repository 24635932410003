import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { usePopper } from 'hooks/usePopper'
import { Placement } from '@popperjs/core/index'
import { motion, AnimatePresence, MotionStyle } from 'framer-motion'
import useDismissable from 'hooks/useDismissable'

interface CustomMenuProps {
	menu: ReactNode
	children: (x: { onClick: () => void }) => ReactNode
	className?: ClassValue
	placement?: Placement
	maxWidth?: number
}

const CustomMenu: FC<CustomMenuProps> = ({ placement = 'bottom-end', className, children, menu, maxWidth }) => {
	const [reference, tooltip] = usePopper({
		placement,
		strategy: 'fixed',
		modifiers: [
			{
				name: 'offset',
				options: { offset: [0, 0] },
			},
		],
	})

	const { open, setOpen, ignoreClass, ref } = useDismissable()

	return (
		<div className={clsx('block text-left', className)}>
			{/*
				// @ts-ignore */}
			<div ref={reference} className={ignoreClass}>
				{children({ onClick: () => setOpen((x) => !x) })}
			</div>

			<div ref={tooltip} className="z-20">
				<div ref={ref}>
					<AnimatePresence>
						{open && (
							<motion.div
								className={clsx(className, 'relative z-[60]')}
								style={{ originY: 0 } as MotionStyle}
								variants={{
									hidden: { scale: 0.95, opacity: 0 },
									visible: { scale: 1, opacity: 1 },
								}}
								transition={{
									type: 'tween',
									ease: 'easeOut',
									duration: 0.2,
								}}
								initial="hidden"
								animate="visible"
								exit="hidden"
							>
								<div
									className="mt-2 rounded-md shadow-lg focus:outline-none"
									style={{
										maxWidth: maxWidth
											? typeof maxWidth === 'number'
												? maxWidth + 'px'
												: maxWidth
											: 'auto',
									}}
								>
									<div className="rounded-md ring-1 ring-black ring-opacity-5 whitespace-nowrap">
										{menu}
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	)
}

export default CustomMenu
