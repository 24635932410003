import { FC, useState } from 'react'
import clsx from 'clsx'
import { useFormContext, useFieldArray } from 'react-hook-form'
import ErrorBadge from 'components/Form/ErrorBadge'
import Tabs from 'components/Tabs'
import Hearing from './Event'
import { newHearing } from './utils/ValidationSchema'
import { format, parseISO } from 'date-fns'
import { XIcon, PlusIcon } from '@heroicons/react/outline'
import { HEARING_TYPE } from 'types/enums'
import { CaseModel } from 'types/models'
import { FormHearingType } from 'pages/Cases/PendingCase/PendingCase'
import { FENZOrgId } from 'utils/constants'
import Card from 'components/Card'

const getNumber = (hearing: FormHearingType, hearings: FormHearingType[], type: HEARING_TYPE) => {
	const list = hearings.filter((x) => x.hearingType && +x.hearingType === +type)
	return list.findIndex((x) => x === hearing) + 1
}

interface HearingsProps {
	caseData?: CaseModel
}

const Hearings: FC<HearingsProps> = ({ caseData }) => {
	const { watch } = useFormContext()

	const [activeIndex, setActiveIndex] = useState(0)

	const { fields: hearings, append, remove } = useFieldArray({ name: 'hearings', keyName: 'key' })

	const watchedHearings = watch('hearings')
	const organization = watch('organization')

	const types = watch(hearings.map((_, i) => `hearings[${i}].hearingType`))

	watch(hearings.map((_, i) => `hearings[${i}].startDate`))

	return (
		<>
			<div className="pl-2">
				<Tabs
					activeIndex={activeIndex}
					theme="card"
					items={[
						...hearings.map((hearing, i) => {
							return {
								title: (
									<span className="flex items-center space-x-2">
										<div>
											{HEARING_TYPE[watchedHearings[i]?.hearingType]} #
											{getNumber(
												watchedHearings[i],
												watchedHearings,
												types[`hearings[${i}].hearingType`]
											)}
											{watchedHearings[i]?.startDate &&
												` (${format(
													watchedHearings[i].startDate instanceof Date
														? watchedHearings[i].startDate
														: parseISO(watchedHearings[i].startDate),
													'dd/MM/yyyy'
												)})`}
										</div>
										<ErrorBadge
											fields={[
												`hearings[${i}].startDate`,
												`hearings[${i}].time`,
												`hearings[${i}].forumType`,
												`hearings[${i}].venue`,
												`hearings[${i}].reviewNumber`,
												`hearings[${i}].issueCode`,
												`hearings[${i}].lodgementDate`,
												`hearings[${i}].zoomMeeting`,
												`hearings[${i}].duration`,
											]}
										/>
										{(hearings.length > 1 || organization.id === FENZOrgId) && (
											<button
												type="button"
												className="ml-3 opacity-50 hover:opacity-100"
												onClick={(e) => {
													e.stopPropagation()

													if (hearings.length - 1 === activeIndex) {
														setActiveIndex(hearings.length - 2)
													}

													// setTimeout(() => remove(i), 0)
													remove(i)
												}}
											>
												<XIcon className="w-4 h-4" />
											</button>
										)}
									</span>
								),
								simpleTitle: `${HEARING_TYPE[hearing.hearingType]} #${getNumber(
									watchedHearings[i],
									watchedHearings,
									types[`hearings[${i}].hearingType`]
								)} ${hearing.startDate && ` (${format(hearing.startDate, 'dd/MM/yyyy')})`}`,
								onClick: () => setActiveIndex(i),
							}
						}),
						{
							title: (
								<span className="flex items-center">
									<PlusIcon className="w-5 h-5 inline-block" />
								</span>
							),
							simpleTitle: 'Add Hearing',
							onClick: () => {
								append(newHearing(caseData))
								setActiveIndex(hearings.length)
							},
						},
					]}
				/>
			</div>

			<div className="relative z-10">
				{new Array(hearings.length).fill(null).map((_, i) => (
					<div key={hearings[i].key} className={clsx(i !== activeIndex && 'hidden')}>
						{caseData && <Hearing index={i} hearing={hearings[i] as FormHearingType} caseData={caseData} />}
					</div>
				))}
				{hearings.length === 0 && <Card>No Conferences</Card>}
			</div>
		</>
	)
}

export default Hearings
