import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import Table, { TableSchema } from 'components/Table'
import { CheckIcon } from '@heroicons/react/outline'
import Form from 'components/Form/Form'
import FormCheckbox from 'components/Form/FormCheckbox'
import useData from 'hooks/useData'
import { Auth } from 'Auth'
import api from 'api'
import { getFullName, series } from 'utils/funcs'
import { format } from 'date-fns'
import { warning } from 'alerts'
import { toast } from 'components/toast'
import { CaseModel, UserModel } from 'types/models'
import { isNotNone, PaginatedSet } from 'types'

interface OpenChatExportProps {
	user: UserModel
	channel: any
}

const openChatExport = ({ user, channel }: OpenChatExportProps) => {
	return openModal({
		title: 'Save conversation to public case notes',
		size: 'lg',
		render: (close) => <ChatExport close={close} user={user} channel={channel} />,
	})
}

interface FormData {
	cases: {
		id: string
		included: boolean
	}[]
}

const ChatExport: FC<OpenChatExportProps & { close: () => void }> = ({ close, user, channel }) => {
	const { isSaving, setSaving } = useModalState()

	const { data } = useData<PaginatedSet<CaseModel>>(
		'/Cases',
		{
			search: {
				caseManager: [{ name: 'email', value: Auth.profile()?.email }],
			},
		},
		{ suspense: true }
	)

	const formContext = useForm<FormData>({
		defaultValues: {
			cases: data?.items.map((x) => ({ id: x.id, included: false })),
		},
	})

	const handleSubmit = async (formData: FormData) => {
		let included = formData.cases.filter((x) => x.included)

		if (!included.length) return false

		setSaving(true)

		let profile = Auth.profile()

		const formatMessage = (message: any) => {
			let author = message.author === profile?.id && isNotNone(profile) ? getFullName(profile) : getFullName(user)
			let date = format(message.timestamp, 'yyyy-MM-dd HH:mm:ss')

			return `<b>${author}</b> ${date}<br/>${message.body}`
		}

		let messages: any[] = channel.messages.filter((x: any) => {
			if (!x.attributes) return true
			if (!x.attributes.exported) return true
			return false
		})

		if (!messages.length) {
			warning({ title: 'No exportable messages!' })
			close()
			return
		}

		try {
			await series(included, async (item) => {
				await api.post('/CaseNotes', {
					isPublic: true,
					text: messages.reduce((str: string, message) => `${str}<br/>${formatMessage(message)}<br/>`, ''),
					case: {
						id: item.id,
					},
				})
			})

			await series(messages, async (message) => {
				message.updateAttributes({ exported: true })
			})

			toast({
				title: 'Conversation Saved',
			})

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const schema: TableSchema<CaseModel> = {
		cols: [
			{
				title: '',
				width: '50px',
				value: (_, i) => <FormCheckbox name={`cases.${i}.included`} />,
			},
			{
				title: 'Case number',
				value: (x) => x.caseNumber,
			},
			{
				title: 'ACC Claim Number',
				value: (x) => x?.fields?.find((x) => x.name === 'accClaimNumber')?.value,
			},
			{
				title: 'Applicant Name',
				value: (x) => x?.fields?.find((x) => x.name === 'applicantName')?.value,
			},
		],
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Table schema={schema} items={data?.items || []} />
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								icon: <CheckIcon className="w-5 h-5" />,
								intent: 'save',
								onClick: handleSubmit,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openChatExport
