import { FC, ReactNode } from 'react'
import usePaginatedData from 'hooks/usePaginatedData'
import { CaseModel } from 'types/models'
import DashCard from 'components/DashCard'
import { API_METRIC_TYPE } from 'types/enums'
import { METRIC_ROLE_TYPE } from 'types/enums'

interface DashboardViewCardProps {
	title: ReactNode
	to?: string
	state?: any
	linkText?: ReactNode
	disabled?: boolean
	search: {
		metricType: API_METRIC_TYPE
		roleType: METRIC_ROLE_TYPE
		dueDays?: number
	}
}

const DashboardViewCard: FC<DashboardViewCardProps> = ({ title, to, state, search, disabled = false }) => {
	const { data: cases, isFetching } = usePaginatedData<CaseModel>(
		'/Cases/metrics',
		{
			page: 1,
			pageSize: 0,
			search,
		},
		{ enabled: !disabled }
	)

	return (
		<DashCard
			title={title}
			value={disabled ? <div className="text-base">Coming soon</div> : cases?.totalCount}
			to={to}
			isLoading={isFetching}
			state={state}
			disabled={disabled}
		/>
	)
}

export default DashboardViewCard
