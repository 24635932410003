import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'
import {
	ChevronDownIcon,
	CheckIcon,
	ChevronLeftIcon,
	BellIcon,
	DeviceMobileIcon,
	LogoutIcon,
	CubeIcon,
	ClipboardCheckIcon,
	UserIcon,
} from '@heroicons/react/outline'
import store, { Auth } from 'Auth'
import Avatar from 'components/Avatar'
import UserDefault from 'components/UserDefault'
import { getFullName } from 'utils/funcs'
import Actions from 'components/Actions'
import ReminderMenu from 'components/ReminderMenu'
import { useDarkMode } from 'components/DarkMode'
import openTodoListSlideout from 'slideouts/openTodoListSlideout'
import { ReminderModel, TodoModel } from 'types/models'
import useData from 'hooks/useData'
import { PaginatedSet } from 'types'
import { endOfDay, startOfDay, subDays } from 'date-fns'

interface TopbarProps {
	includeSearch?: boolean
	intent?: 'light' | 'dark'
	className?: ClassValue
}

const Topbar: FC<TopbarProps> = ({ includeSearch = false, intent = 'light', className, children }) => {
	const profile = Auth.profile()

	const logout = async () => {
		store.getState().logout()
	}

	const isRemindableUser = Auth.is('Reviewer', 'Mediator', 'Adjudicator', 'Facilitator')

	const { data: todos, refetch: refetchTodoCount } = useData<PaginatedSet<TodoModel>>('/ToDoItems', {
		orderDir: 1,
		page: 1,
		pageSize: 1,
		search: { completed: false },
	})

	const { data: reminders, refetch: refetchReminderCount } = useData<ReminderModel[]>(
		'/Reminders',
		{
			startDate: startOfDay(subDays(new Date(), 10)).toISOString(),
			endDate: endOfDay(new Date()).toISOString(),
			search: {
				reminderStatus: 0,
			},
		},
		{
			enabled: isRemindableUser,
		}
	)

	const { darkMode, setDarkMode } = useDarkMode()

	return (
		<div
			className={clsx(
				'max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8 relative z-30 flex items-center justify-between',
				includeSearch && 'md:justify-between',
				className
			)}
		>
			<div>{children}</div>

			<div
				className={clsx(
					'flex items-center space-x-2',
					intent === 'dark'
						? 'text-gray-200'
						: 'text-gray-200 md:text-gray-800 dark:text-gray-200 md:dark:text-gray-200'
				)}
			>
				<Actions
					actions={[
						isRemindableUser && {
							title: <div className={clsx('text-sm font-medium')}>{reminders?.length}</div>,
							icon: (
								<BellIcon
									className={clsx('w-6 h-6', intent === 'dark' && 'text-gray-200 antialiased')}
								/>
							),
							intent: 'invisible',
							placement: 'bottom-end',
							size: 'xs',
							menu: <ReminderMenu refetch={refetchReminderCount} />,
						},
						{
							title: <div className={clsx('text-sm font-medium')}>{todos?.totalCount}</div>,
							icon: (
								<ClipboardCheckIcon
									className={clsx('w-6 h-6', intent === 'dark' && 'text-gray-200 antialiased')}
								/>
							),
							intent: 'invisible',
							placement: 'bottom-end',
							size: 'xs',
							onClick: () => openTodoListSlideout({ refetch: refetchTodoCount }),
						},
					]}
				/>

				<Actions
					actions={[
						{
							title: (
								<div className="flex items-center space-x-4">
									{profile?.avatarUrl ? (
										<Avatar profile className="inline-block h-8 w-8 rounded-full" />
									) : (
										<UserDefault
											name={profile ? getFullName(profile) : ''}
											className="w-8 h-8 text-base"
										/>
									)}

									<div
										className={clsx(
											'text-sm font-medium',
											intent === 'dark' && 'text-gray-200 antialiased'
										)}
									>
										{profile ? `${profile.firstName} ${profile.lastName}` : 'user'}
									</div>
								</div>
							),
							icon: (
								<ChevronDownIcon
									className={clsx('w-5 h-5 opacity-75', intent === 'dark' && 'text-white')}
								/>
							),
							iconSide: 'right',
							intent: 'invisible',
							placement: 'bottom-end',
							size: 'xs',
							actions: [
								[
									{
										title: 'Colour Scheme',
										icon: <ChevronLeftIcon className="w-4 h-4" />,
										iconSide: 'left',
										placement: 'left-start',
										hoverable: true,
										actions: [
											{
												title: 'Dark Mode',
												icon: (
													<CheckIcon
														className={clsx(
															'w-4 h-4',
															darkMode === true ? 'opacity-100' : 'opacity-0'
														)}
													/>
												),
												iconSide: 'left',
												onClick: () => setDarkMode(true),
											},
											{
												title: 'Light Mode',
												icon: (
													<CheckIcon
														className={clsx(
															'w-4 h-4',
															darkMode === false ? 'opacity-100' : 'opacity-0'
														)}
													/>
												),
												iconSide: 'left',
												onClick: () => setDarkMode(false),
											},
											{
												title: 'System Preference',
												icon: (
													<CheckIcon
														className={clsx(
															'w-4 h-4',
															darkMode === null ? 'opacity-100' : 'opacity-0'
														)}
													/>
												),
												iconSide: 'left',
												onClick: () => setDarkMode(null),
											},
										],
									},
									profile && {
										title: 'Manage Devices',
										icon: <DeviceMobileIcon className="w-4 h-4" />,
										iconSide: 'left',
										to: '/devices',
									},
									profile && {
										title: `Your Profile`,
										icon: <UserIcon className="w-4 h-4" />,
										iconSide: 'left',
										to: '/profile',
									},
									Auth.is('Admin', 'FENZAdmin') && {
										title: 'Changelog',
										icon: <CubeIcon className="w-4 h-4" />,
										iconSide: 'left',
										to: '/changelog',
									},
								],

								[
									{
										title: 'Logout',
										icon: <LogoutIcon className="w-4 h-4" />,
										iconSide: 'left',
										onClick: logout,
									},
								],
							],
						},
					]}
				/>
			</div>
		</div>
	)
}

export default Topbar
