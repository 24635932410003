import { FC, useState } from 'react'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Card from 'components/Card'
import Panel from 'components/Panel'
import Stack from 'components/Stack'
import DatePicker from 'components/DatePicker'
import { endOfMonth, startOfMonth } from 'date-fns'
import DecisionDue from './ReportPanels/DecisionDue'

const Reports: FC = () => {
	const [startDate, setStartDate] = useState(startOfMonth(new Date()))
	const [endDate, setEndDate] = useState(endOfMonth(new Date()))

	return (
		<>
			<PageHeading title="Reports" />

			<PageContent>
				<Card>
					<Stack dividers className="py-6">
						<Panel
							title="Date Range"
							subtitle="Select a date range that matches the time frame for your report."
							content={
								<div className="flex space-x-4">
									<div className="flex flex-col space-y-2">
										<div className="block text-sm font-medium leading-5 text-gray-700 dark:text-white">
											Start Date
										</div>
										<DatePicker value={startDate} onChange={(val) => val && setStartDate(val)} />
									</div>
									<div className="flex flex-col space-y-2">
										<div className="block text-sm font-medium leading-5 text-gray-700 dark:text-white">
											End Date
										</div>
										<DatePicker value={endDate} onChange={(val) => val && setEndDate(val)} />
									</div>
								</div>
							}
						/>

						<DecisionDue startDate={startDate} endDate={endDate} />
					</Stack>
				</Card>
			</PageContent>
		</>
	)
}

export default Reports
