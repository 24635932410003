import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Table, { TableSchema } from 'components/Table'
import Form from 'components/Form/Form'
import FormCheckbox from 'components/Form/FormCheckbox'
import { CheckIcon } from '@heroicons/react/outline'
import Actions from 'components/Actions'
import api from 'api'
import { format, parseISO } from 'date-fns'
import { series } from 'utils/funcs'
import { openModal, ModalFooter, useModalState } from 'hooks/useModal'
import { toast } from 'components/toast'
import { CaseModel, HearingModel, ReviewModel } from 'types/models'
import { RefetchFn } from 'types'

interface OpenEditHearingAttendeesProps {
	caseData: CaseModel
	hearing: HearingModel
	refetch: RefetchFn
}

const useEditHearingReviews = ({ caseData, hearing, refetch }: OpenEditHearingAttendeesProps) => {
	return openModal({
		title: 'Edit Reviews',
		size: 'xl',
		render: (close) => <EditReviews caseData={caseData} hearing={hearing} refetch={refetch} close={close} />,
	})
}

interface FormData {
	reviews: {
		[id: string]: boolean
	}
}

const EditReviews: FC<OpenEditHearingAttendeesProps & { close: () => void }> = ({
	caseData,
	hearing,
	refetch,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const reviews = caseData.reviews.filter((x) => !x.withdrawalReason)

	const formContext = useForm<FormData>({
		defaultValues: {
			reviews: reviews.reduce(
				(obj, review) => ({
					...obj,
					[review.id || '']: !!hearing.reviews.find((y) => y.id === review.id),
				}),
				{}
			),
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await series(Object.entries(formData.reviews), async ([id, included]) => {
				const review = caseData.reviews.find((x) => x.id === id)

				if (!review) return

				// if included is now true, and it wasn't in the list previously
				if (included && !hearing.reviews.find((x) => x.id === review.id)) {
					// then add it
					await api.post(`/CaseHearings/${hearing.id}/review/${review.id}?triggerNotifications=true`)
					return
				}

				// if included is now false, and it was in the list previously
				if (!included && hearing.reviews.find((x) => x.id === review.id)) {
					// then remove it
					await api.delete(`/CaseHearings/${hearing.id}/review/${review.id}`)
					return
				}
			})

			await refetch()

			toast({
				title: 'Updated Reviews',
			})

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const schema: TableSchema<ReviewModel> = {
		cols: [
			{
				title: 'Review Number',
				value: (x) => <FormCheckbox name={`reviews.${x.id}`} label={x.reviewNumber} />,
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Issue Code',
				value: (x) => x.issueCode,
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'ACC Decision Date',
				value: (x) => (x.accDecisionDate ? format(parseISO(x.accDecisionDate), 'dd/MM/yyyy') : '-'),
				responsive: 'md',
			},
			{
				title: 'Lodgement Date',
				value: (x) => format(parseISO(x.lodgementDate), 'dd/MM/yyyy'),
				responsive: 'md',
			},
			{
				title: 'Set By Date',
				value: (x) => (x.setByDate ? format(parseISO(x.setByDate), 'dd/MM/yyyy') : '-'),
				responsive: 'md',
			},
		],
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<Table items={reviews} schema={schema} className="-my-px" />

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default useEditHearingReviews
