import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import PageHeading from 'components/PageHeading'
import api from 'api'
import { getFullName } from 'utils/funcs'
import FileSaver from 'file-saver'
import { format, endOfDay } from 'date-fns'
import useData from 'hooks/useData'
import Form from 'components/Form/Form'
import FormDate from 'components/Form/FormDate'
import FormSelect from 'components/Form/FormSelect'
import FormSection from 'components/Form/FormSection'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { DocumentDownloadIcon } from '@heroicons/react/outline'
import { toast } from 'components/toast'
import PageNavbar from 'components/PageNavbar'
import { links } from 'pages/Admin'
import { UserModel } from 'types/models'
import { CaseStatus, CASE_STATUS, USER_ROLE } from 'types/enums'
import { PaginatedSet } from 'types'
import SectionHeader from 'components/SectionHeader'

const fmt = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ss")

interface FormData {
	caseManager: UserModel | null
	reviewer: UserModel | null
	status: CASE_STATUS | null
	decisionDueDate: {
		from: Date | null
		to: Date | null
	}
	completedDate: {
		from: Date | null
		to: Date | null
	}
	applicationDate: {
		from: Date | null
		to: Date | null
	}
}

const CasesCSVExport: FC = () => {
	const formContext = useForm<FormData>({
		defaultValues: {
			caseManager: null,
			reviewer: null,
			status: null,
			decisionDueDate: {
				from: null,
				to: null,
			},
			completedDate: {
				from: null,
				to: null,
			},
			applicationDate: {
				from: null,
				to: null,
			},
		},
	})

	const decisionDueFrom = formContext.watch('decisionDueDate.from')
	const completedFrom = formContext.watch('completedDate.from')
	const applicationFrom = formContext.watch('applicationDate.from')

	const [isSaving, setSaving] = useState(false)

	const { data: reviewerUsers } = useData<PaginatedSet<UserModel>>('/Users', {
		pageSize: 100,
		search: { roles: [USER_ROLE.Reviewer] },
	})

	const { data: caseManagerUsers } = useData<PaginatedSet<UserModel>>('/Users', {
		pageSize: 100,
		search: { roles: [USER_ROLE.CaseManager] },
	})

	const handleSubmit = async (formData: FormData) => {
		const search: { [x: string]: any } = {}

		// reviewer id
		if (formData.reviewer) {
			search.reviewerUserId = formData.reviewer.id
		}

		// case manager id
		if (formData.caseManager) {
			search.caseManagerUserId = formData.caseManager.id
		}

		// status
		if (formData.status) {
			search.outComeTypeId = formData.status
		}

		// decision date
		if (formData.decisionDueDate.from && formData.decisionDueDate.to) {
			search.decisionDueDate = {
				from: fmt(formData.decisionDueDate.from),
				to: fmt(endOfDay(formData.decisionDueDate.to)),
			}
		}

		// completed date
		if (formData.completedDate.from && formData.completedDate.to) {
			search.completedDate = {
				from: fmt(formData.completedDate.from),
				to: fmt(endOfDay(formData.completedDate.to)),
			}
		}

		// application date
		if (formData.applicationDate.from && formData.applicationDate.to) {
			search.applicationDate = {
				from: fmt(formData.applicationDate.from),
				to: fmt(endOfDay(formData.applicationDate.to)),
			}
		}

		setSaving(true)

		try {
			let res = await api.get<Blob>(
				`/Report/casetocsv${search ? `?search=${encodeURIComponent(JSON.stringify(search))}` : ''}`,
				{ responseType: 'blob' }
			)

			FileSaver.saveAs(res.data, `Cases_export_${format(new Date(), 'yyyy-MM-dd')}.csv`)

			toast({
				title: 'CSV Exported',
			})
		} catch (error) {
			api.handleError(error)
		}
		setSaving(false)
	}

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Cases CSV Export" />

			<PageNavbar links={links}>
				<Stack>
					<SectionHeader title="Cases CSV Export" className="px-6" />

					<Form onSubmit={handleSubmit} context={formContext} className="px-6">
						<Stack space={8} dividers>
							<FormSection title="Users">
								<Stack>
									<FormSelect
										label="Case Manager"
										name="caseManager"
										options={
											caseManagerUsers?.items.map((x) => ({
												label: getFullName(x),
												value: x,
											})) || []
										}
										clearable
									/>

									<FormSelect
										label="Reviewer"
										name="reviewer"
										options={
											reviewerUsers?.items.map((x) => ({
												label: getFullName(x),
												value: x,
											})) || []
										}
										clearable
									/>

									<FormSelect
										label="Case Status"
										name="status"
										options={CaseStatus.options}
										clearable
									/>
								</Stack>
							</FormSection>

							<FormSection title="Decision Due Date">
								<Stack>
									<FormDate label="From" name="decisionDueDate.from" />
									<FormDate
										label="To"
										name="decisionDueDate.to"
										disabled={!decisionDueFrom}
										validations={{
											required: decisionDueFrom ? 'To is required' : false,
										}}
										hideOptional
									/>
								</Stack>
							</FormSection>

							<FormSection title="Completed Date">
								<Stack>
									<FormDate label="From" name="completedDate.from" />
									<FormDate
										label="To"
										name="completedDate.to"
										disabled={!completedFrom}
										validations={{
											required: completedFrom ? 'To is required' : false,
										}}
										hideOptional
									/>
								</Stack>
							</FormSection>

							<FormSection title="Application Date">
								<Stack>
									<FormDate label="From" name="applicationDate.from" />
									<FormDate
										label="To"
										name="applicationDate.to"
										disabled={!applicationFrom}
										validations={{
											required: applicationFrom ? 'To is required' : false,
										}}
										hideOptional
									/>
								</Stack>
							</FormSection>

							<div className="mt-6 flex justify-end">
								<Actions
									actions={[
										{
											title: 'Download CSV',
											icon: <DocumentDownloadIcon className="w-5 h-5" />,
											intent: 'primary',
											type: 'submit',
											isLoading: isSaving,
										},
									]}
								/>
							</div>
						</Stack>
					</Form>
				</Stack>
			</PageNavbar>
		</>
	)
}

export default CasesCSVExport
