import { FC, ReactNode, useEffect } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/outline'
import { Spinner } from 'components/Icon'
import clsx, { ClassValue } from 'clsx'

interface Theme {
	body?: ClassValue
	title?: ClassValue
	message?: ClassValue
	icon?: ClassValue
	closeButton?: ClassValue
	progressBar?: ClassValue
}

const themeCtx: Record<string, Theme> = {
	default: {
		body: [
			'rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
			'dark:bg-gray-700 dark:ring-white dark:ring-opacity-5',
		],
		title: ['text-gray-900', 'dark:text-gray-200'],
		message: ['text-gray-500', 'dark:text-gray-400'],
		icon: 'text-green-400',
		closeButton: ['text-gray-900', 'dark:text-gray-200'],
		progressBar: ['bg-green-300', 'dark:bg-green-600 dark:bg-opacity-25'],
	},
	error: {
		body: [
			'rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
			'dark:bg-gray-700 dark:ring-white dark:ring-opacity-5',
		],
		title: ['text-gray-900', 'dark:text-gray-200'],
		message: ['text-gray-500', 'dark:text-gray-400'],
		icon: ['text-red-400', 'dark:text-red-500'],
		closeButton: 'text-gray-900',
		progressBar: ['bg-red-300', 'dark:bg-red-600 dark:bg-opacity-50'],
	},
	inProgress: {
		body: [
			'rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
			'dark:bg-gray-700 dark:ring-white dark:ring-opacity-5',
		],
		title: ['text-gray-900', 'dark:text-gray-200'],
		message: ['text-gray-500', 'dark:text-gray-400'],
		icon: 'text-orange-400',
		closeButton: ['text-gray-900', 'dark:text-gray-200'],
		progressBar: ['bg-orange-300', 'dark:bg-orange-600 dark:bg-opacity-25'],
	},
}

export interface ToastProps {
	title: ReactNode
	message?: ReactNode
	icon?: ReactNode
	dismissable?: boolean
	duration?: number
	isLoading?: boolean
	intent?: keyof typeof themeCtx
	onClose: () => void
}

export const Toast: FC<ToastProps> = ({
	title,
	message,
	icon,
	duration = 6000,
	dismissable = true,
	isLoading = false,
	intent = 'default',
	onClose,
}) => {
	const toastTheme = intent ? themeCtx?.[intent] : {}

	let iconEl =
		icon ||
		(intent === 'default' ? (
			<CheckCircleIcon className="h-6 w-6" />
		) : intent === 'error' ? (
			<ExclamationCircleIcon className="h-6 w-6" />
		) : null)

	useEffect(() => {
		duration && dismissable && setTimeout(onClose, duration)
	}, [duration, dismissable, onClose])

	return (
		<div className={clsx('pointer-events-auto relative w-full max-w-sm overflow-hidden', toastTheme?.body)}>
			<div className="p-4">
				<div className="flex items-start">
					{iconEl && <div className={clsx('flex-shrink-0', toastTheme?.icon)}>{iconEl}</div>}
					<div className="ml-3 w-0 flex-1 pt-0.5">
						<p className={clsx('text-sm font-medium', toastTheme?.title)}>{title}</p>
						{message && <p className={clsx('mt-1 text-sm', toastTheme?.message)}>{message}</p>}
					</div>
					{isLoading && (
						<div className="flex items-center self-stretch">
							<Spinner className="h-5 w-5 animate-spin" />
						</div>
					)}
					{dismissable && (
						<div className="ml-4 flex flex-shrink-0">
							<button
								type="button"
								className={clsx(
									'inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
									toastTheme?.closeButton
								)}
								onClick={onClose}
							>
								<span className="sr-only">Close</span>
								<XIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
