export enum REMINDER_STATUS {
	Uncompleted = 0,
	Completed = 1,
}

export const reminderStatus = {
	0: 'Uncompleted',
	1: 'Completed',
}

export const ReminderStatus = {
	readable: (x: REMINDER_STATUS) => reminderStatus[x],
	options: Object.entries(reminderStatus).map(([key, val]) => ({ label: val, value: +key })),
}
