import { FC, useState } from 'react'
import { parseQueryCode, getErrorMessage } from 'utils/funcs'
import logo from 'assets/img/newlogo/icra-acronym.png'
import logoDark from 'assets/img/newlogo/icra-acronym-white.png'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import { CardWrapper, CardContent, CardFooter } from 'components/Card'
import Stack from 'components/Stack'
import { rawApi } from 'api'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import { warning } from 'alerts'
import { isAxiosError } from 'types'
import { navigate } from 'components/NavigateHoist'
import { toast } from 'components/toast'

interface FormData {
	password: string
	confirmPassword: string
}

const ConfirmUser: FC = () => {
	const [isSaving, setSaving] = useState(false)

	const formContext = useForm({
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	})

	const password = formContext.watch('password')

	const changePassword = async (formData: FormData) => {
		const { code, userId } = parseQueryCode(window.location.search)

		setSaving(true)

		try {
			await rawApi.post('/Accounts/confirm', {
				code,
				userId,
				password: formData.password,
			})

			toast({
				title: 'All confirmed',
				message: 'Please continue to login page and log in as usual',
			})

			navigate('/')
		} catch (error) {
			if (isAxiosError(error)) {
				if (getErrorMessage(error).indexOf('Invalid token') > -1) {
					warning({
						title: 'Invalid token',
						message:
							'This may be because your account is already confirmed. If you are having trouble logging in, try the "forgotten account" button',
					})
				} else {
					rawApi.handleError(error)
				}
			}

			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={changePassword}>
			<div className="flex flex-col justify-center items-center h-screen flex-1">
				<CardWrapper className="w-full max-w-2xl">
					<CardContent>
						<Stack space={8}>
							<div className="flex justify-center">
								<img src={logo} alt="ICRA" className="h-10 dark:hidden" />
								<img src={logoDark} alt="ICRA" className="h-10 hidden dark:inline-block" />
							</div>

							<Stack>
								<p className="text-center">
									Please choose a password for your account, with a minimum of 8 characters.
								</p>

								<FormInput
									name="password"
									label="Password"
									type="password"
									validations={{
										minLength: 8,
										required: 'Password is required',
									}}
									autoFocus
								/>

								<FormInput
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									validations={{
										validate: (value) => value === password,
										required: 'Confirm Password is required',
									}}
									customErrors={{
										validate: 'Confirmation password must match the above',
									}}
								/>
							</Stack>
						</Stack>
					</CardContent>

					<CardFooter>
						<div className="flex justify-end">
							<Actions
								actions={[
									{
										title: 'Confirm your account',
										intent: 'save',
										icon: <CheckIcon className="w-5 h-5" />,
										type: 'submit',
										isLoading: isSaving,
									},
								]}
							/>
						</div>
					</CardFooter>
				</CardWrapper>
			</div>
		</Form>
	)
}

export default ConfirmUser
