import { FC, useState } from 'react'
import { parseQueryCode } from 'utils/funcs'
import logo from 'assets/img/newlogo/icra-acronym.png'
import logoDark from 'assets/img/newlogo/icra-acronym-white.png'
import { CardWrapper, CardContent, CardFooter } from 'components/Card'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import api from 'api'
import { toast } from 'components/toast'
import { useLocation } from 'react-router-dom'
import { navigate } from 'components/NavigateHoist'

const ReviewerInvite: FC = () => {
	const [isSaving, setSaving] = useState(false)
	const location = useLocation()

	const { caseId, casePartyId, userId } = parseQueryCode(location.search)

	const accept = async () => {
		if (!caseId || !casePartyId || !userId) return null

		setSaving(true)

		try {
			await api.put(`/Cases/${caseId}/party/${casePartyId}`, {
				id: casePartyId,
				accepted: true,
				changeReason: 'Role Accepted',
				user: {
					id: userId,
				},
			})

			await toast({ title: 'Appointment accepted' })

			navigate('/dashboard')
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const decline = async () => {
		if (!caseId || !casePartyId || !userId) return null

		setSaving(true)

		try {
			await api.delete(`/Cases/${caseId}/party/${casePartyId}`)

			await toast({
				title: 'Appointment declined',
			})

			navigate('/dashboard')
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<div className="flex flex-col items-center justify-center h-screen flex-1">
			<CardWrapper className="w-full max-w-2xl">
				<CardContent>
					<Stack>
						<div className="flex justify-center">
							<img src={logo} alt="ICRA" className="h-10 dark:hidden" />
							<img src={logoDark} alt="ICRA" className="h-10 hidden dark:inline-block" />
						</div>

						<Stack>
							<p>I accept the appointment and confirm that:</p>

							<p>
								To the best of my knowledge there are no circumstances past or present likely to give
								rise to justifiable doubts as to my impartiality or independence in the eyes of any of
								the parties; and
							</p>

							<p>
								I will assume a continuing duty to disclose forthwith to the parties any circumstances
								arising in the future likely to give rise to justifiable doubts as to my impartiality or
								independence in the eyes of any of the parties until the review is concluded.
							</p>
						</Stack>
					</Stack>
				</CardContent>

				<CardFooter>
					<div className="flex justify-between">
						<Actions
							actions={[
								{
									title: 'I decline the appointment',
									icon: <XIcon className="w-5 h-5" />,
									intent: 'danger',
									onClick: decline,
									isLoading: isSaving,
								},
							]}
						/>

						<Actions
							actions={[
								{
									title: 'I accept the appointment',
									icon: <CheckIcon className="w-5 h-5" />,
									intent: 'primary',
									onClick: accept,
									isLoading: isSaving,
								},
							]}
						/>
					</div>
				</CardFooter>
			</CardWrapper>
		</div>
	)
}

export default ReviewerInvite
