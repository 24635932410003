import { FC, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageContent from 'components/PageContent'
import PageHeading from 'components/PageHeading'
import Card from 'components/Card'
import { TableSchema } from 'components/Table'
import { gql } from '@apollo/client'
import { GQLConnection, GQLEventType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { endOfDay, endOfMonth, format, isSameDay, parseISO, startOfDay } from 'date-fns'
import EventCalendar from 'components/EventCalendar'
import Actions from 'components/Actions'
import { CalendarIcon } from '@heroicons/react/outline'
import { ensureDate } from 'utils/funcs'
import EventsSearch from 'search/EventsSearch'
import openUserSlideout from 'slideouts/openUserSlideout'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { Auth } from 'Auth'
import clsx from 'clsx'
import { navigate } from 'components/NavigateHoist'
import { isNotNone } from 'types'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: EventOrderBy!, $search: EventSearch) {
		Events(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				startDate
				endDate
				type {
					id
					readable
				}
				forumType {
					id
					readable
				}
				case {
					id
					caseNumber
				}
				reviews {
					id
					reviewNumber
					withdrawDate
				}
				attendees {
					id
					fullName
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const Events: FC = () => {
	const location = useLocation()

	const state = (location?.state || {}) as { search?: any; page?: number; pageSize?: number }

	const search = state?.search
		? {
				...state?.search,
				attendees: [state.search?.attendees?.id].filter(isNotNone),
				status: [1],
				isPostponed: false,
		  }
		: {
				status: [1],
				isPostponed: false,
		  }

	if (!search.caseNumber && !search.caseManager && !search.attendees) {
		if (!search.from) {
			search.from = startOfDay(new Date()).toISOString()
		}

		if (!search.to) {
			if (Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin')) {
				search.to = endOfDay(new Date()).toISOString()
			} else {
				search.to = endOfMonth(new Date()).toISOString()
			}
		}
	}

	const { data, loading, refetch } = useBufferedQuery<{ Events: GQLConnection<GQLEventType> }>(query, {
		variables: {
			orderBy: 'date_ASC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 100,
			search,
		},
	})

	const schema: TableSchema<GQLEventType> = {
		cols: [
			{
				title: 'Type',
				value: (x) => (
					<div className="flex flex-col items-start">
						<Link to={`/cases/${x.case?.id}/conferences?hearing=${x.id}`} className="anchor">
							{x.type?.readable}
						</Link>
						<div>{x.case?.caseNumber}</div>
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Date',
				value: (x) => (
					<div className="flex flex-col">
						<div>{x.startDate ? format(parseISO(x.startDate), 'EEE dd/MM/yyyy') : '-'}</div>
						<div>
							{x.startDate && x.endDate
								? `${format(parseISO(x.startDate), 'hh:mm bbb')} - ${format(
										parseISO(x.endDate),
										'hh:mm bbb'
								  )}`
								: '-'}
						</div>
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Forum',
				value: (x) => x.forumType?.readable,
			},
			{
				title: 'Reviews',
				value: (x) =>
					x.reviews?.map(
						(y, i) =>
							x.reviews && (
								<Fragment key={i}>
									<span className={clsx({ 'line-through': !!y.withdrawDate })}>{y.reviewNumber}</span>
									{i !== x.reviews.length - 1 ? ', ' : ''}
								</Fragment>
							)
					),
				truncate: true,
			},
			{
				title: 'Attendees',
				value: (x) =>
					Auth.is('Applicant')
						? x.attendees?.map((x, i) => x.fullName).join(', ')
						: (
								<div className="flex flex-wrap">
									{x.attendees?.map((x, i) => (
										<div key={i} className="mr-4">
											<button
												key={i}
												className="anchor"
												onClick={() => openUserSlideout({ userId: x.id })}
											>
												{x.fullName}
											</button>
										</div>
									))}
								</div>
						  ) || '-',
				truncate: true,
				responsive: 'md',
			},
		],
	}

	const resetDefaultSearches = () => {
		navigate(location.pathname, { replace: true, state: { search: {} } })
	}

	const getTimeframe = () => {
		if (!search.from && !search.to) return ''

		let from = ensureDate(search.from)
		let to = ensureDate(search.to)

		if (!isSameDay(from, to)) {
			return `${format(from, "eee',' d MMM yyyy")} - ${format(to, "eee',' d MMM yyyy")}`
		} else {
			return `${format(from, "eee',' d MMM yyyy")}`
		}
	}

	return (
		<>
			<div className="max-w-7xl mx-auto flex justify-between items-center pr-8">
				<PageHeading title="Diary" />

				<div className="flex items-center space-x-4">
					<div className="text-sm font-medium">{getTimeframe()}</div>

					<Actions
						actions={[
							{
								icon: <CalendarIcon className="w-4 h-4" />,
								intent: 'secondary',
								size: 'sm',
								rounded: 'md',
								menu: (
									<div className="bg-white dark:bg-gray-900 rounded-md p-4 w-[600px]">
										<EventCalendar
											selected={ensureDate(search.from)}
											actions={[
												!Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') && {
													title: 'Current Month',
													onClick: resetDefaultSearches,
													rounded: 'md',
												},
											]}
										/>
									</div>
								),
							},
						]}
					/>
				</div>
			</div>

			<PageContent>
				<Card>
					<GQLPaginatedTable
						schema={schema}
						set={data?.Events}
						isLoading={loading}
						refetch={refetch}
						search={(props) => <EventsSearch {...props} />}
					/>
				</Card>
			</PageContent>
		</>
	)
}

export default Events
