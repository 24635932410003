import { FC } from 'react'
import { openModal, ModalContent, ModalFooter } from 'hooks/useModal'
import { UserModel } from 'types/models'
import { ModalResolveFn } from 'types'
import Actions from 'components/Actions'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import DataListColumns from 'components/DataListColumns'
import { getFullName } from 'utils/funcs'
import Truncate from 'components/Truncate'
import { Auth } from 'Auth'

interface OpenConfirmUserProps {
	user: UserModel
}

const openConfirmUser = ({ user }: OpenConfirmUserProps) => {
	return openModal<boolean>({
		title: 'Do you wish to add this user to the case?',
		dismissable: false,
		render: (close) => <ConfirmUser close={close} user={user} />,
	})
}

interface ConfirmUserProps {
	close: ModalResolveFn<boolean>
	user: UserModel
}

const ConfirmUser: FC<ConfirmUserProps> = ({ close, user }) => {
	return (
		<>
			<ModalContent>
				<DataListColumns
					items={[
						{
							title: 'Name',
							value: getFullName(user),
						},
						{
							title: 'Email',
							value: <Truncate>{user.email}</Truncate>,
						},
						{
							title: 'Phone Number',
							value: user.phoneNumber,
						},
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') &&
							user.note && {
								title: 'Notes',
								value: user.note,
							},
					]}
				/>
			</ModalContent>
			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'No',
								icon: <XIcon className="w-5 h-5" />,
								intent: 'secondary',
								onClick: () => close(false),
							},
							{
								title: 'Yes',
								icon: <CheckIcon className="w-5 h-5" />,
								intent: 'primary',
								onClick: () => close(true),
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openConfirmUser
