import { env } from 'utils/config'

export const complexClaimTypes = ['W3', 'W5', 'X7', 'X16', 'Y19', 'Y17']

export const FENZOrgId = '2877dbac-e952-4fca-94ff-ca363d4e11a8'

export const ACCOrgId = env === 'dev' ? '3fa371cc-3f21-4b1b-8102-21e11ab3d3f7' : '4d458cf9-e7b0-4383-8588-639e47862eb5'

export const ACCOrg =
	env === 'dev'
		? {
				name: 'ACC',
				email: 'support@applicable.co.nz',
				phone: '123456789',
				billingName: 'Accident Compensation Corporation',
				address: null,
				id: '3fa371cc-3f21-4b1b-8102-21e11ab3d3f7',
		  }
		: {
				name: 'ACC',
				email: 'support@applicable.co.nz',
				phone: '123456789',
				billingName: 'Accident Compensation Corporation',
				address: null,
				id: '3fa371cc-3f21-4b1b-8102-21e11ab3d3f7',
		  }

export const holidays = [
	{ name: "New Year's Day", date: new Date('1 Jan 2021') },
	{ name: "Day after New Year's Day", date: new Date('4 Jan 2021') },
	{ name: 'Waitangi Day', date: new Date('8 Feb 2021') },
	{ name: 'Good Friday', date: new Date('2 Apr 2021') },
	{ name: 'Easter Monday', date: new Date('5 Apr 2021') },
	{ name: 'Anzac Day', date: new Date('26 Apr 2021') },
	{ name: "Queen's Birthday", date: new Date('7 Jun 2021') },
	{ name: 'Labour Day', date: new Date('25 Oct 2021') },
	{ name: 'Christmas Day', date: new Date('27 Dec 2021') },
	{ name: 'Boxing Day', date: new Date('28 Dec 2021') },

	{ name: "New Year's Day", date: new Date('3 Jan 2022') },
	{ name: "Day after New Year's Day", date: new Date('4 Jan 2022') },
	{ name: 'Waitangi Day', date: new Date('7 Feb 2022') },
	{ name: 'Good Friday', date: new Date('15 Apr 2022') },
	{ name: 'Easter Monday', date: new Date('18 Apr 2022') },
	{ name: 'Anzac Day', date: new Date('25 Apr 2022') },
	{ name: "Queen's Birthday", date: new Date('6 Jun 2022') },
	{ name: 'Matariki', date: new Date('24 Jun 2022') },
	{ name: 'Labour Day', date: new Date('24 Oct 2022') },
	{ name: 'Christmas Day', date: new Date('26 Dec 2022') },
	{ name: 'Boxing Day', date: new Date('27 Dec 2022') },

	{ name: "New Year's Day", date: new Date('3 Jan 2023') },
	{ name: "Day after New Year's Day", date: new Date('2 Jan 2023') },
	{ name: 'Waitangi Day', date: new Date('6 Feb 2023') },
	{ name: 'Good Friday', date: new Date('7 Apr 2023') },
	{ name: 'Easter Monday', date: new Date('10 Apr 2023') },
	{ name: 'Anzac Day', date: new Date('25 Apr 2023') },
	{ name: "King's Birthday", date: new Date('5 Jun 2023') },
	{ name: 'Matariki', date: new Date('14 Jul 2023') },
	{ name: 'Labour Day', date: new Date('23 Oct 2023') },
	{ name: 'Christmas Day', date: new Date('25 Dec 2023') },
	{ name: 'Boxing Day', date: new Date('26 Dec 2023') },

	{ name: "New Year's Day", date: new Date('1 Jan 2024') },
	{ name: "Day after New Year's Day", date: new Date('2 Jan 2024') },
	{ name: 'Waitangi Day', date: new Date('6 Feb 2024') },
	{ name: 'Good Friday', date: new Date('29 Mar 2024') },
	{ name: 'Easter Monday', date: new Date('1 Apr 2024') },
	{ name: 'Anzac Day', date: new Date('25 Apr 2024') },
	{ name: "King's Birthday", date: new Date('3 Jun 2024') },
	{ name: 'Matariki', date: new Date('28 Jul 2024') },
	{ name: 'Labour Day', date: new Date('28 Oct 2024') },
	{ name: 'Christmas Day', date: new Date('25 Dec 2024') },
	{ name: 'Boxing Day', date: new Date('26 Dec 2024') },
]
