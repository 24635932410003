import { FC, Fragment } from 'react'
import Stack from 'components/Stack'
import { format, parseISO } from 'date-fns'
import openEditCasePrimaryInformation from 'modals/openEditCasePrimaryInformation'
import openCaseManagement from 'modals/openCaseManagement'
import DataListColumns from 'components/DataListColumns'
import CaseHeader from 'components/CaseHeader'
import { Auth } from 'Auth'
import { PencilIcon } from '@heroicons/react/outline'
import useData from 'hooks/useData'
import CaseNotes from 'components/CaseNotes'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import { useParams } from 'react-router-dom'
import { CaseModel } from 'types/models'
import { CaseAppealStatus, CaseProcess, CaseType, CASE_STATUS } from 'types/enums'
import clsx from 'clsx'
import { FENZOrgId } from 'utils/constants'

const getCaseField = (caseData: CaseModel | undefined, name: string) => {
	return caseData?.fields?.find((x) => x.name === name)
}

// interface CaseDataProps {
// 	caseId?: string
// }

const CaseData: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`, {})

	const accClaimNumber = getCaseField(caseData, 'accClaimNumber')?.value
	const applicationDate = format(parseISO(caseData?.created || ''), 'dd/MM/yyyy')
	const applicantName = getCaseField(caseData, 'applicantName')?.value
	const careIndicationStatus = getCaseField(caseData, 'careIndication')?.value
	const careManagementPlan = getCaseField(caseData, 'careManagementPlan')?.value
	const specialInstructions = getCaseField(caseData, 'specialInstructions')?.value
	const triageNotes = getCaseField(caseData, 'triageNotes')?.value
	const complex = getCaseField(caseData, 'complex')?.value

	const links = caseData ? caseLinks(caseData) : []

	const isFENZ = caseData?.organization?.id === FENZOrgId

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack dividers space={6}>
					<Stack className="px-6">
						<SectionHeader
							title="Primary Information"
							actions={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									icon: <PencilIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => caseData && openEditCasePrimaryInformation({ caseData, refetch }),
								},
							]}
						/>

						<DataListColumns
							items={
								!isFENZ
									? [
											{
												title: 'Organisation',
												value: caseData?.organization?.name,
											},
											{
												title: 'ACC Claim Number',
												value: accClaimNumber || '-',
											},
											{
												title: 'Case Complexity',
												value: complex || '-',
											},
											{
												title: 'ICRA Case Number',
												value: caseData?.caseNumber,
											},
											{
												title: `Review Number${
													caseData?.reviews && caseData.reviews.length > 1 ? 's' : ''
												}`,
												value: caseData?.reviews.length
													? caseData?.reviews.map((x, i) => (
															<Fragment key={i}>
																<span
																	className={clsx({
																		'line-through': !!x.withdrawDate,
																	})}
																>
																	{x.reviewNumber}
																</span>
																{i !== caseData.reviews.length - 1 ? ', ' : ''}
															</Fragment>
													  ))
													: '-',
											},
											{
												title: 'Case Type',
												value: caseData?.caseType ? CaseType.readable(caseData.caseType) : '-',
											},

											{
												title: 'Application Date',
												value: applicationDate,
											},
											(caseData?.status === CASE_STATUS.DecisionIssued ||
												caseData?.status === CASE_STATUS.DecisionIssuedDismissed ||
												caseData?.status === CASE_STATUS.DecisionIssuedQuashedDirectionGiven ||
												caseData?.status === CASE_STATUS.DecisionIssuedQuashedSubstituted ||
												caseData?.status === CASE_STATUS.DecisionIssuedACCToMakeDecision ||
												caseData?.status === CASE_STATUS.DecisionIssuedReviewUpheld ||
												caseData?.status === CASE_STATUS.DecisionIssuedModified ||
												caseData?.status === CASE_STATUS.DismissedNoJurisdiction ||
												caseData?.status === CASE_STATUS.Withdrawn ||
												caseData?.status === CASE_STATUS.WithdrawnSettled ||
												caseData?.status === CASE_STATUS.WithdrawnBeforeCMC ||
												caseData?.status === CASE_STATUS.WithdrawnBeforeCMCSettled ||
												caseData?.status === CASE_STATUS.WithdrawnACCRevokedDecision ||
												caseData?.status ===
													CASE_STATUS.WithdrawnBeforeCMCACCRevokedDecision) && {
												title: 'Appeal Status',
												value: caseData?.appealStatus
													? CaseAppealStatus.readable(caseData.appealStatus)
													: '-',
											},
									  ]
									: [
											{
												title: 'Applicant',
												value: applicantName,
											},
											{
												title: 'ICRA Case Number',
												value: caseData?.caseNumber,
											},
											{
												title: 'Case Process',
												value: caseData?.process ? CaseProcess.readable(caseData.process) : '-',
											},
											{
												title: 'Application Date',
												value: applicationDate,
											},
									  ]
							}
						/>
					</Stack>

					{Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') && (
						<Stack className="px-6">
							<SectionHeader
								title="Case Management"
								actions={[
									Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
										icon: <PencilIcon className="w-5 h-5" />,
										intent: 'menu',
										onClick: () => caseData && openCaseManagement({ caseData, refetch }),
									},
								]}
							/>

							<DataListColumns
								items={[
									{
										title: 'Care Indication Status',
										value: careIndicationStatus || '-',
									},
									{
										title: 'Additional Information',
										value: specialInstructions || '-',
									},
									{
										title: 'Care Indicator Information',
										value: careManagementPlan || '-',
									},
									{
										title: 'Triage Notes',
										value: triageNotes || '-',
									},
								]}
							/>
						</Stack>
					)}

					{!Auth.is('ACCAdmin', 'Respondent') && (
						<Stack className="px-6">
							<SectionHeader
								title={Auth.is('Admin', 'CaseManager', 'FENZAdmin') ? 'Case Notes' : 'Private Notes'}
							/>
							{caseData && <CaseNotes caseData={caseData} />}
						</Stack>
					)}
				</Stack>
			</PageNavbar>
		</>
	)
}

export default CaseData
