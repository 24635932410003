import { useEffect } from 'react'
import useChat from './useChat'
import MainWindow from './MainWindow'
import ChannelWindow from './ChannelWindow'
import useStore, { Auth } from 'Auth'

const Root = () => {
	const profile = useStore((store) => store.profile)

	if (!profile) return null
	if (!Auth.is('Admin', 'CaseManager')) return null

	return <Chat />
}

const Chat = () => {
	const { client, connect, localStorageState } = useChat()

	useEffect(() => {
		if (!client) connect(Auth.profile().id)
	}, [client, connect])

	return (
		<div className="flex flex-row-reverse items-end fixed inset-0 z-20 pointer-events-none">
			<MainWindow />

			{localStorageState.chats.map((chat) => (
				<ChannelWindow key={chat.user_id} data={chat} />
			))}
		</div>
	)
}

export default Root
