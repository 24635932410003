import { FC, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import useStore, { Auth } from 'Auth'

import PageSidebar from 'components/PageSidebar'
import PageTopbar from 'components/PageTopbar'

// logged out
import Login from 'pages/Unauthenticated/Login'
import ConfirmUser from 'pages/Unauthenticated/ConfirmUser'
import ResetPassword from 'pages/Unauthenticated/ResetPassword'

// dashboard
import Dashboard from 'pages/Dashboards/Dashboard'

import MetricDecisionDue from 'pages/Dashboards/Metrics/DecisionDue'
import MetricActiveCases from 'pages/Dashboards/Metrics/ActiveCases'
import MetricPendingCases from 'pages/Dashboards/Metrics/PendingCases'
import MetricNeedApprovalCases from 'pages/Dashboards/Metrics/NeedApprovalCases'
import MetricNoHearingSetDown from 'pages/Dashboards/Metrics/NoHearingSetDown'
import MetricReviewerDeclined from 'pages/Dashboards/Metrics/ReviewerDeclined'
import MetricCasesWithUnapprovedAssignments from 'pages/Dashboards/Metrics/CasesWithUnapprovedAssignments'

// Case pages
import Cases from 'pages/Cases/Cases'
import CaseBoundary from 'pages/Cases/CaseBoundary'
import ViewCase from 'pages/Cases/CaseActivated'
import PendingCase from 'pages/Cases/CasePendingBoundary'
import CaseRequest from 'pages/Cases/CaseRequest'
import OpenCase from 'pages/Cases/Case/CaseInformation'
import CaseParticipants from 'pages/Cases/Case/Participants'
import CaseReviews from 'pages/Cases/Case/Reviews'
import CaseEvents from 'pages/Cases/Case/Events'
import CaseDocuments from 'pages/Cases/Case/Documents'
import CaseStatus from 'pages/Cases/Case/Status'
import CaseBilling from 'pages/Cases/Case/Billing'
import LinkedCases from 'pages/Cases/Case/LinkedCases'
import CaseNotifications from 'pages/Cases/Case/Notifications'
import CaseReminders from 'pages/Cases/Case/Reminders'

// User pages
import Users from 'pages/Users/Users'
import ViewUser from 'pages/Users/ViewUser'

// Other main nav pages
import Diary from 'pages/Diary'
import Availability from 'pages/Availability'
import Documents from 'pages/Documents'
import Applications from 'pages/Applications'

// Admin pages
import Admin from 'pages/Admin/index'
import AdminNotifications from 'pages/Admin/NotificationLog'
import AdminUploads from 'pages/Admin/Uploads'
import AdminCasesCSVExport from 'pages/Admin/CasesCSVExport'
import AdminOrganizations from 'pages/Admin/Organizations/Organizations'
import ViewOrganization from 'pages/Admin/Organizations/ViewOrganization'
import AdminBilling from 'pages/Admin/Billing'
import AdminReminders from 'pages/Admin/Reminders'
import AdminVenues from 'pages/Admin/Venues'

// Report pages
import Reports from 'pages/Reports/Reports'

// Misc pages
import ViewFile from 'pages/ViewFile'
import Devices from 'pages/Devices'
import ReviewerInvite from 'pages/ReviewerInvite'
import Changelog from 'pages/Changelog'
import NotFound from 'pages/NotFound'
import Xero from 'pages/Xero'

// Test pages
import Test from 'pages/Test'
import Test2 from 'pages/Test2'
import Test3 from 'pages/Test3'
import { Spinner } from 'components/Icon'
import { ACCOrgId } from 'utils/constants'

// const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

// const Route: FC<RouteComponentProps> = ({ children }) => <>{children}</>

const Router: FC = () => {
	const isLoggedIn = useStore((store) => store.isLoggedIn)
	const profile = useStore((store) => store.profile)

	// subscribe to fakeRoles for <DevTools /> to work
	useStore((store) => store.fakeRoles)

	// subscribe to render for re-renderer to work (used in logout)
	useStore((store) => store.render)

	const Wrapper =
		Auth.is('Applicant', 'FileHandler', 'Respondent') && Auth.profile()?.organization?.id !== ACCOrgId
			? PageTopbar
			: PageSidebar

	const isLoadingProfile = Auth.isLoggedIn() && !Auth.profile()

	// console.log(Auth.isLoggedIn())
	// console.log(Auth.profile())
	// console.log(profile)

	return (
		<Suspense fallback={null}>
			<Routes>
				{isLoadingProfile ? (
					<Route path="*" element={<LoaderPage />} />
				) : (
					<>
						{!isLoggedIn() && (
							<>
								<Route path="login" element={<Login />} />
								<Route path="user/confirm" element={<ConfirmUser />} />
								<Route path="user/resetpassword" element={<ResetPassword />} />
								<Route path="/*" element={<Navigate to="/login" />} />
							</>
						)}

						{isLoggedIn() && (
							<Route path="/" element={<Wrapper />}>
								<Route path="/" element={<Navigate to="/dashboard" />} />
								<Route path="/login" element={<Navigate to="/dashboard" />} />

								<Route path="user/resetpassword" element={<ResetPassword />} />

								<Route path="dashboard" element={<Dashboard />} />

								{/* <Route path="/dashboard/:metric" element={<ViewMetric />} /> */}
								<Route path="/dashboard/decision-due" element={<MetricDecisionDue />} />
								<Route path="/dashboard/active-cases" element={<MetricActiveCases />} />
								<Route path="/dashboard/pending-cases" element={<MetricPendingCases />} />
								<Route path="/dashboard/need-approval-cases" element={<MetricNeedApprovalCases />} />
								<Route path="/dashboard/no-hearing-set-down" element={<MetricNoHearingSetDown />} />
								<Route path="/dashboard/reviewer-declined" element={<MetricReviewerDeclined />} />
								<Route
									path="/dashboard/cases-with-unapproved-assignments"
									element={<MetricCasesWithUnapprovedAssignments />}
								/>

								<Route path="*" element={<NotFound />} />

								{Auth.is(
									'Admin',
									'CaseManager',
									'Reviewer',
									'Mediator',
									'Support',
									'FENZAdmin',
									'Facilitator',
									'Adjudicator'
								) && (
									<Route path="cases">
										<Route index element={<Cases />} />
										<Route path=":caseId" element={<CaseBoundary />}>
											<Route path="request" element={<CaseRequest />} />
											<Route path="pending" element={<PendingCase />} />
											<Route element={<ViewCase />}>
												<Route index element={<OpenCase />} />
												<Route path="case-information" element={<OpenCase />} />
												<Route path="participants" element={<CaseParticipants />} />
												<Route path="reviews" element={<CaseReviews />} />
												<Route path="conferences" element={<CaseEvents />} />
												<Route path="documents" element={<CaseDocuments />} />
												<Route path="status" element={<CaseStatus />} />
												<Route path="billing" element={<CaseBilling />} />
												<Route path="linked-cases" element={<LinkedCases />} />
												<Route path="notifications" element={<CaseNotifications />} />
												<Route path="reminders" element={<CaseReminders />} />
											</Route>
										</Route>
									</Route>
								)}

								{Auth.is('Applicant') && (
									<Route path="cases/:caseId" element={<CaseBoundary />}>
										<Route element={<ViewCase />}>
											<Route index element={<OpenCase />} />
											<Route path="case-information" element={<OpenCase />} />
											<Route path="participants" element={<CaseParticipants />} />
											<Route path="conferences" element={<CaseEvents />} />
											<Route path="documents" element={<CaseDocuments />} />
										</Route>
									</Route>
								)}

								{Auth.is('ACCAdmin', 'Respondent') && (
									<Route path="cases/:caseId" element={<CaseBoundary />}>
										<Route element={<ViewCase />}>
											<Route index element={<OpenCase />} />
											<Route path="case-information" element={<OpenCase />} />
											<Route path="participants" element={<CaseParticipants />} />
											<Route path="reviews" element={<CaseReviews />} />
											<Route path="conferences" element={<CaseEvents />} />
											<Route path="documents" element={<CaseDocuments />} />
											<Route path="status" element={<CaseStatus />} />
											<Route path="linked-cases" element={<LinkedCases />} />
										</Route>
									</Route>
								)}

								{Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') && (
									<Route path="reports" element={<Reports />} />
								)}

								{Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin', 'ACCAdmin') ||
								(Auth.is('Respondent') && Auth.profile()?.organization?.id === ACCOrgId) ? (
									<Route path="/users">
										<Route index element={<Users />} />
										<Route path=":userId" element={<ViewUser />} />
									</Route>
								) : (
									<Route path="users/:userId" element={<ViewUser />} />
								)}

								<Route path="diary" element={<Diary />} />

								{Auth.is('Admin', 'CaseManager', 'FENZAdmin') && (
									<Route path="/applications" element={<Applications />} />
								)}

								{Auth.is('Admin', 'CaseManager', 'FENZAdmin', 'ACCAdmin', 'Respondent') && (
									<Route path="files" element={<Documents />} />
								)}

								{Auth.is('Admin', 'CaseManager', 'FENZAdmin', 'Reviewer', 'Mediator') && (
									<Route path="availability" element={<Availability />} />
								)}

								{Auth.is('Admin', 'FENZAdmin') && (
									<Route path="admin" element={<Admin />}>
										<Route path="notifications" element={<AdminNotifications />} />
										<Route path="uploads" element={<AdminUploads />} />
										<Route path="reminders" element={<AdminReminders />} />
										<Route path="cases-export" element={<AdminCasesCSVExport />} />
										<Route path="organisations">
											<Route index element={<AdminOrganizations />} />
											<Route path=":orgId" element={<ViewOrganization />} />
										</Route>
										<Route path="billing" element={<AdminBilling />} />
										<Route path="venues" element={<AdminVenues />} />
									</Route>
								)}

								<Route path="view-file/:fileId" element={<ViewFile />} />

								<Route path="ReviewerInvite" element={<ReviewerInvite />} />
								<Route path="MediatorInvite" element={<ReviewerInvite />} />
								<Route path="PractitionerInvite" element={<ReviewerInvite />} />

								{Auth.is('Admin', 'FENZAdmin') && <Route path="changelog" element={<Changelog />} />}
								{Auth.is('Admin') && <Route path="test" element={<Test />} />}
								{Auth.is('Admin') && <Route path="test2" element={<Test2 />} />}
								{Auth.is('Admin') && <Route path="test3" element={<Test3 />} />}
								<Route path="xero" element={<Xero />} />

								<Route path="devices" element={<Devices />} />
								<Route path="profile" element={<ViewUser userId={profile?.id} />} />
							</Route>
						)}
					</>
				)}
			</Routes>
		</Suspense>
	)
}

const LoaderPage: FC = () => {
	return (
		<div className="w-screen h-screen flex items-center justify-center">
			<Spinner className="animate-spin w-10 h-10" />
		</div>
	)
}

export default Router
