import { FC, ReactNode } from 'react'
import { slideout } from 'alerts'
import Suspender from 'components/Suspender'
import Actions from 'components/Actions'
import { ActionType } from 'types'
import { SizeValue } from 'alerts/Slideout'
import { XIcon } from '@heroicons/react/outline'
import clsx, { ClassValue } from 'clsx'
import { BrowserRouter } from 'react-router-dom'

type CloseFn = () => void

interface OpenSlideoutProps {
	render: (close: CloseFn) => ReactNode
	size?: SizeValue
}

const openSlideout = async ({ render, size = 'md' }: OpenSlideoutProps) => {
	return await slideout({
		size,
		children: (close) => (
			<BrowserRouter>
				<SlideoutInner render={render} close={close} />
			</BrowserRouter>
		),
	})
}

interface SlideoutInnerProps {
	render: (x: CloseFn) => ReactNode
	close: CloseFn
}

const SlideoutInner: FC<SlideoutInnerProps> = ({ render, close }) => {
	return (
		<div className="h-full w-screen max-w-md relative">
			<div className="absolute top-0 left-0 -mx-12 mt-2">
				<Actions
					actions={[
						{
							icon: <XIcon className="w-5 h-5 -mx-2 text-white" />,
							intent: 'invisible',
							onClick: () => close(),
						},
					]}
				/>
			</div>
			<div className=" h-full divide-y divide-gray-200 dark:divide-gray-600 flex flex-col bg-white dark:bg-gray-700 shadow-xl">
				<div className="flex-1 h-0 flex flex-col">
					<Suspender render={render(close)} />
				</div>
			</div>
		</div>
	)
}

interface SlideoutHeaderProps {
	title: ReactNode
	subtitle?: ReactNode
	actions?: ActionType[]
}

export const SlideoutHeader: FC<SlideoutHeaderProps> = ({ title, subtitle, actions }) => {
	return (
		<div className="py-6 px-4 bg-gray-100 dark:bg-gray-800 sm:px-6 shrink-0">
			<div className="flex items-center justify-between">
				<div className="text-lg font-medium text-gray-900 dark:text-gray-100">{title}</div>
				<div className="ml-3 h-7 flex items-center">{actions && <Actions actions={actions} />}</div>
			</div>
			{subtitle && (
				<div className="mt-1">
					<div className="text-sm text-gray-500 dark:text-gray-400">{subtitle}</div>
				</div>
			)}
		</div>
	)
}

interface SlideoutContentProps {
	className?: ClassValue
}

export const SlideoutContent: FC<SlideoutContentProps> = ({ className, children }) => {
	return <div className={clsx('flex-1 flex flex-col justify-between overflow-y-auto', className)}>{children}</div>
}

export default openSlideout
