import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { ActionType } from 'types'
import Actions from 'components/Actions'

interface FormSectionProps {
	title: ReactNode
	subtitle?: ReactNode
	actions?: ActionType
	vertical?: boolean
	children: ReactNode
}

const FormSection: FC<FormSectionProps> = ({ title, subtitle, actions, vertical = false, children }) => {
	return (
		<div className={clsx(vertical ? 'flex flex-col space-y-6' : 'md:grid md:grid-cols-3 md:gap-6')}>
			<div className="md:col-span-1">
				<h3 className="-mt-1 text-lg font-medium leading-6 text-gray-900 dark:text-white">{title}</h3>
				{subtitle && <p className="mt-1 text-sm leading-5 text-gray-500 dark:text-gray-300">{subtitle}</p>}
				{actions && (
					<div className="mt-2">
						<Actions actions={actions} />
					</div>
				)}
			</div>
			<div className="mt-5 md:mt-0 md:col-span-2">{children}</div>
		</div>
	)
}

export default FormSection
