import { FC, FormEvent, useState, useEffect, useRef } from 'react'
import { CardWrapper } from 'components/Card'
import Stack from 'components/Stack'
import Table, { TableSchema } from 'components/Table'
import { humanFileSize } from 'utils/funcs'
import { toast } from 'components/toast'
import api from 'api'
import { AppFileModel } from 'types/models'
import useData from 'hooks/useData'
import { format, parseISO } from 'date-fns'
import FileDropper from 'components/FileDropper'
import Actions from 'components/Actions'
import { ExclamationCircleIcon, UploadIcon } from '@heroicons/react/outline'
import SectionHeader from 'components/SectionHeader'
import Badge from 'components/Badge'
import { SCAN_STATUS } from 'types/enums'
import { Spinner } from 'components/Icon'
import clsx from 'clsx'
import { warning } from 'alerts'

const ACCFileHandlerDashboard: FC = () => {
	const [files, setFiles] = useState<File[]>([])

	const fileSizeLimit = 150000000 // 150mb

	const { data: appFiles, refetch } = useData<{ items: AppFileModel[] }>(
		'/AppFiles',
		{ pageSize: 100 },
		{ suspense: true }
	)

	// create polling refetch if there is any scanning files
	const timer = useRef<ReturnType<typeof setInterval>>()
	useEffect(() => {
		let anyScanning = (appFiles?.items?.filter((x) => x.scanResult === SCAN_STATUS.Scanning).length || 0) > 0
		// let anyScanning = true

		if (timer.current) {
			clearInterval(timer.current)
		}

		if (anyScanning) {
			timer.current = setInterval(() => refetch(), 3 * 1000)
		}
	}, [appFiles, refetch])

	// filtering
	const [name, setName] = useState('')

	let items = appFiles?.items.filter((x) => {
		let pass = true

		if (name.trim().length && x.name.toLowerCase().indexOf(name.trim().toLowerCase()) === -1) pass = false

		return pass
	})

	const [isSaving, setSaving] = useState(false)

	const uploadFiles = async (event: FormEvent) => {
		event.stopPropagation()
		event.preventDefault()

		if (!files.length) return

		if (files.find((x) => x.size > fileSizeLimit)) {
			warning({
				title: 'One of the files is too large',
				message: 'The max file size allowed is ' + humanFileSize(fileSizeLimit),
			})

			return false
		}

		setSaving(true)

		// create the upload body
		const body = new FormData()

		// and append each file to it
		for (let i = 0; i < files.length; i++) {
			body.append('files', files[i])
		}

		try {
			// upload the files
			await api.post(`/AppFiles`, body)

			// then re-request the appfiles for the page (GET request)
			refetch()
			// clear the file uploader component
			setFiles([])

			// show a modal saying it completed
			toast({
				title: 'File Uploaded',
			})
			setSaving(false)
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const uploadsSchema: TableSchema<File> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.name,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.size),
				width: 'minmax(auto, max-content)',
			},
		],
	}

	const existingFilesSchema: TableSchema<AppFileModel> = {
		cols: [
			{
				title: 'Name',
				value: (x) => (
					<div
						className={clsx(
							'flex items-center gap-3',
							x.scanResult === SCAN_STATUS.VirusDetected ? 'text-red-500 dark:text-red-300' : ''
						)}
					>
						{x.scanResult === SCAN_STATUS.Scanning ? (
							<Badge color="gray" icon={<Spinner className="h-4 w-4 mr-2 animate-spin" />}>
								Scanning...
							</Badge>
						) : x.scanResult === SCAN_STATUS.VirusDetected ? (
							<Badge color="red" icon={<ExclamationCircleIcon className="w-4 h-4 mr-2" />}>
								Infected
							</Badge>
						) : null}
						<div>{x.name}</div>
					</div>
				),
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.fileSize),
			},
			{
				title: 'Date',
				value: (x) => format(parseISO(x.created), 'dd/MM/yyyy HH:mm:ss'),
			},
		],
	}

	return (
		<Stack space={6}>
			<CardWrapper className="p-6">
				<form onSubmit={uploadFiles} encType="multipart/form-data">
					<Stack>
						<div className="flex justify-end">
							<Badge color="blue" icon={null}>
								Max file size: {humanFileSize(fileSizeLimit)}
							</Badge>
						</div>

						<FileDropper onChange={setFiles} className="h-64" />

						{files.length > 0 && (
							<Stack>
								<Table schema={uploadsSchema} items={files} className="-mx-6" />

								<div className="flex justify-end">
									<Actions
										actions={{
											title: 'Upload',
											intent: 'save',
											icon: <UploadIcon className="w-5 h-5" />,
											type: 'submit',
											isLoading: isSaving,
										}}
									/>
								</div>
							</Stack>
						)}
					</Stack>
				</form>
			</CardWrapper>

			<CardWrapper className="overflow-hidden">
				<Stack>
					<div className="px-6 pt-6 flex items-center justify-between">
						<SectionHeader title="Previously uploaded files" />
						<input
							type="text"
							className="form-input block w-full sm:text-sm sm:leading-5 dark:bg-gray-800 dark:placeholder-gray-400 dark:border-gray-600"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="filter"
							style={{ width: 300 }}
						/>
					</div>

					<Table schema={existingFilesSchema} items={items || []} />
				</Stack>
			</CardWrapper>
		</Stack>
	)
}

export default ACCFileHandlerDashboard
