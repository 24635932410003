import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import { CASE_STATUS } from 'types/enums'

type SizeType = 'sm' | 'md'

const sizes = {
	sm: 'px-2 py-0.5 -my-0.5 text-xs leading-5',
	md: 'px-2.5 py-0.5 -my-0.5 text-sm leading-6',
}

export const themes = {
	gray: 'bg-gray-400 text-gray-800 dark:bg-gray-500 dark:text-gray-300 bg-opacity-25 dark:bg-opacity-25',
	red: 'bg-red-400 text-red-800 dark:bg-red-500 dark:text-red-300 bg-opacity-25 dark:bg-opacity-25',
	orange: 'bg-orange-400 text-orange-800 dark:bg-orange-500 dark:text-orange-300 bg-opacity-25 dark:bg-opacity-25',
	yellow: 'bg-yellow-400 text-yellow-800 dark:bg-yellow-500 dark:text-yellow-300 bg-opacity-25 dark:bg-opacity-25',
	green: 'bg-green-400 text-green-800 dark:bg-green-500 dark:text-green-300 bg-opacity-25 dark:bg-opacity-25',
	teal: 'bg-teal-400 text-teal-800 dark:bg-teal-500 dark:text-teal-300 bg-opacity-25 dark:bg-opacity-25',
	blue: 'bg-blue-400 text-blue-800 dark:bg-blue-500 dark:text-blue-300 bg-opacity-25 dark:bg-opacity-25',
	indigo: 'bg-indigo-400 text-indigo-800 dark:bg-indigo-500 dark:text-indigo-300 bg-opacity-25 dark:bg-opacity-25',
	purple: 'bg-purple-400 text-purple-800 dark:bg-purple-500 dark:text-purple-300 bg-opacity-25 dark:bg-opacity-25',
	pink: 'bg-pink-400 text-pink-800 dark:bg-pink-500 dark:text-pink-300 bg-opacity-25 dark:bg-opacity-25',
	brand: 'bg-brand-400 text-brand-800 dark:bg-brand-500 dark:text-brand-300 bg-opacity-25 dark:bg-opacity-25',
}

type ThemeType = keyof typeof themes

const getTheme = (status: number | string): ThemeType => {
	if (+status === CASE_STATUS.Rejected) return 'pink'
	if (+status === CASE_STATUS.Request) return 'orange'
	if (+status === CASE_STATUS.Pending) return 'indigo'
	if (+status === CASE_STATUS.Open) return 'green'
	if (+status > CASE_STATUS.Open) return 'red'
	return 'gray'
}

export interface BadgeProps {
	color?: ThemeType
	size?: SizeType
	status?: CASE_STATUS
	icon?: ReactNode
	className?: ClassValue
	children?: ReactNode
	rounded?: boolean
}

const Badge: FC<BadgeProps> = ({ color, status, size = 'md', icon, className, rounded = false, children }) => {
	const chosenTheme = status ? getTheme(status) : color || 'gray'

	return (
		<span
			className={clsx(
				`inline-flex items-center font-medium`,
				themes[chosenTheme],
				sizes[size],
				rounded ? 'rounded-full' : 'rounded',
				className
			)}
		>
			{icon !== undefined ? (
				icon
			) : (
				<svg className="mr-2 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
					<circle cx="4" cy="4" r="3" />
				</svg>
			)}
			{children}
		</span>
	)
}

export default Badge
