import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'
import Stack from 'components/Stack'
import { AddressModel } from 'types/models'

interface AddressProps {
	address: Partial<AddressModel>
	className?: ClassValue
}

const Address: FC<AddressProps> = ({ address, className }) => {
	return (
		<div className={clsx(className)}>
			<Stack space={1}>
				{address.address1 && <div className="break-words">{address.address1}</div>}
				{address.address2 && <div className="break-words">{address.address2}</div>}
				{address.address3 && <div className="break-words">{address.address3}</div>}
				{address.city && (
					<div className="break-words">
						{address.city} {address.zipOrPostcode}
					</div>
				)}
				{address.country && <div className="break-words">{address.country.name}</div>}
			</Stack>
		</div>
	)
}

export default Address
