import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { DotsHorizontalIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import { links } from 'pages/Admin'
import { GQLConnection, GQLVenueType } from 'types/gql'
import api from 'api'

// hooks
import useBufferedQuery from 'hooks/useBufferedQuery'

// grapqhl
import { gql } from '@apollo/client'

// components
import PageHeading from 'components/PageHeading'
import PageNavbar from 'components/PageNavbar'
import Stack from 'components/Stack'
import SectionHeader from 'components/SectionHeader'
import openCreateVenue from 'modals/openCreateVenue'
import { TableSchema } from 'components/Table'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import openEditVenue from 'modals/openEditVenue'
import { confirm, toast } from 'alerts'

const query = gql`
	query ($pageNumber: Int, $pageSize: Int, $orderBy: VenueOrderBy, $search: VenueSearch) {
		Venues(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				created
				name
				address
				phoneNumber
			}
			pageInfo {
				pageNumber
				pageSize
				totalCount
				totalPages
				itemCount
			}
		}
	}
`

const Venues: FC = () => {
	const location = useLocation()
	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const { data, loading, refetch } = useBufferedQuery<{ Venues: GQLConnection<GQLVenueType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
		},
	})

	const schema: TableSchema<GQLVenueType> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.name,
			},
			{
				title: 'Address',
				value: (x) => x.address,
			},
			{
				title: 'Phone number',
				value: (x) => x.phoneNumber,
			},
		],
		actions: (x) => [
			{
				icon: <DotsHorizontalIcon className="w-5 h-5" />,
				intent: 'menu',
				actions: [
					[
						{
							title: 'Edit Venue',
							icon: <PencilIcon className="w-5 h-5" />,
							onClick: () => openEditVenue({ venueId: x.id, refetch }),
						},
					],
					x.name !== 'ACC' && [
						{
							title: 'Delete Venue',
							intent: 'danger',
							icon: <TrashIcon className="w-5 h-5" />,
							onClick: () => deleteVenue(x),
						},
					],
				],
			},
		],
	}

	const deleteVenue = async (item: GQLVenueType) => {
		await confirm({
			title: 'Are you sure you want to delete this venue?',
			onAccept: async () => {
				try {
					await api.delete(`/Venues/${item.id}`)
					await refetch()
					toast({ title: 'Venue Deleted' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Venues" />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader
						title="Venues"
						actions={[
							{
								title: 'Add Venue',
								intent: 'primary',
								icon: <PlusIcon className="w-5 h-5" />,
								onClick: () => openCreateVenue({ refetch }),
							},
						]}
					/>

					<GQLPaginatedTable schema={schema} set={data?.Venues} isLoading={loading} refetch={refetch} />
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Venues
