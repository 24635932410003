import { FC } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import {
	OfficeBuildingIcon,
	MailIcon,
	CurrencyDollarIcon,
	DocumentTextIcon,
	BellIcon,
	DocumentDownloadIcon,
	LibraryIcon,
} from '@heroicons/react/outline'
import { navigate } from 'components/NavigateHoist'

export const links = [
	{
		title: 'Notification Log',
		to: '/admin/notifications',
		icon: <MailIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
	{
		title: 'File Upload Log',
		to: `/admin/uploads`,
		icon: <DocumentTextIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
	{
		title: 'Reminder Log',
		to: `/admin/reminders`,
		icon: <BellIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
	{
		title: 'Cases CSV Export',
		to: `/admin/cases-export`,
		icon: <DocumentDownloadIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
	{
		title: 'Organisations',
		to: `/admin/organisations`,
		icon: <OfficeBuildingIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
	{
		title: 'Billing',
		to: `/admin/billing`,
		icon: <CurrencyDollarIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
	{
		title: 'Venues',
		to: `/admin/venues`,
		icon: <LibraryIcon className="w-5 h-5" />,
		show: () => true,
		exact: true,
	},
]

const Admin: FC = () => {
	const location = useLocation()

	if (location.pathname === '/admin') {
		navigate('/admin/notifications', { replace: true })
		return null
	}

	return (
		<>
			<Outlet />
		</>
	)
}

export default Admin
