export enum FORUM_TYPE {
	Phone = 1,
	FaceToFace = 2,
	Video = 3,
	OnThePapers = 4,
}

export const forumType = {
	1: 'Phone',
	2: 'Face to face',
	3: 'Video',
	4: 'On The Papers',
}

export const ForumType = {
	readable: (x: FORUM_TYPE) => forumType[x],
	options: Object.entries(forumType).map(([key, val]) => ({ label: val, value: +key })),
}
