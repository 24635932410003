import { FC } from 'react'
import { useParams } from 'react-router-dom'
import useData from 'hooks/useData'
import { CaseModel } from 'types/models'
import { CASE_TYPE } from 'types/enums'
import { Auth } from 'Auth'
import CaseStatusBadge from 'components/CaseStatusBadge'
import { HashtagIcon, InformationCircleIcon, UserIcon } from '@heroicons/react/outline'
import PageHeading from 'components/PageHeading'
import Card from 'components/Card'
import PendingCase from './PendingCase/PendingCase'
import PageContent from 'components/PageContent'

// interface CasePendingBoundaryProps {
// 	caseId: string
// }

const CasePendingBoundary: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: item } = useData<CaseModel>(
		`/Cases/${caseId}`,
		{},
		{
			refetchOnWindowFocus: Auth.is('CaseManager'),
			refetchOnMount: 'always',
			suspense: true,
		}
	)

	const isReview = item?.caseType === CASE_TYPE.Review
	const isMediation = item?.caseType === CASE_TYPE.Mediation

	const hasReviewer = !!item?.reviewer
	const hasMediator = !!item?.mediator

	if (
		Auth.is('Admin', 'FENZAdmin') ||
		(Auth.is('CaseManager') && ((isReview && hasReviewer) || (isMediation && hasMediator)))
	) {
		return <PendingCase caseId={item?.id} />
	}

	// if (Auth.is('Admin', 'FENZAdmin') || (Auth.is('CaseManager') && item?.reviewer)) {
	// 	return <PendingCase caseId={item?.id} />
	// }

	return (
		<>
			<PageHeading
				pageTitle={`Pending: ${item?.caseNumber}`}
				title={item?.caseNumber}
				infoList={[
					{
						title: <CaseStatusBadge status={item?.status} size="sm" />,
					},
					{
						title: item?.fields?.find((x) => x.name === 'applicantName')?.value || '-',
						icon: <UserIcon className="w-5 h-5" />,
					},
					{
						title: item?.fields?.find((x) => x.name === 'accClaimNumber')?.value || '-',
						icon: <HashtagIcon className="w-5 h-5" />,
					},
				]}
			/>

			<PageContent>
				<Card>
					<div className="p-4 rounded-md bg-primary-50">
						<div className="flex">
							<div className="shrink-0">
								<InformationCircleIcon className="h-5 w-5 text-primary-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-primary-800">
									This case is locked until the{' '}
									{item?.caseType === CASE_TYPE.Review
										? 'Reviewer is'
										: item?.caseType === CASE_TYPE.Mediation
										? 'Mediator is'
										: 'Reviewer or Mediator are'}{' '}
									assigned
								</h3>
							</div>
						</div>
					</div>
				</Card>

				{/* <Card>
					This case is locked until the{' '}
					{item?.caseType === CASE_TYPE.Review
						? 'Reviewer is'
						: item?.caseType === CASE_TYPE.Mediation
						? 'Mediator is'
						: 'Reviewer or Mediator are'}{' '}
					assigned
				</Card> */}
			</PageContent>
		</>
	)
}

export default CasePendingBoundary
