import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchFormProps } from 'components/PaginatedTable'
import { SearchIcon } from '@heroicons/react/outline'
import { Undo } from 'components/Icon'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import { useForm } from 'react-hook-form'
import Actions from 'components/Actions'
import FormSelect from 'components/Form/FormSelect'
import { UserRole } from 'types/enums'
import { PresetValue, PaginationSearch } from 'types'
import { gql } from '@apollo/client'
import { GQLConnection, GQLOrganizationType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'

const query = gql`
	query {
		Organizations {
			items {
				id
				name
			}
		}
	}
`

interface PresetValues {
	organization?: PresetValue<string>
	role?: PresetValue<string | null>
}

interface Props {
	presetValues?: PresetValues
}

const SearchForm: FC<SearchFormProps & Props> = ({ setSearch, close, presetValues }) => {
	const { data = { Organizations: { items: [] } } } = useBufferedQuery<{
		Organizations: GQLConnection<GQLOrganizationType>
	}>(query)

	const location = useLocation()

	const state = (location.state as { search: PaginationSearch })?.search

	const formContext = useForm<PaginationSearch>({
		defaultValues: {
			firstName: state?.firstName || '',
			lastName: state?.lastName || '',
			email: state?.email || '',
			phoneNumber: state?.phoneNumber || '',
			role: state?.role || null,
			organization: presetValues?.organization?.value || state?.organization || null,
		},
	})

	const handleSubmit = (formData: PaginationSearch) => {
		const search: PaginationSearch = {}

		if (formData.firstName) search.firstName = formData.firstName
		if (formData.middleName) search.middleName = formData.middleName
		if (formData.lastName) search.lastName = formData.lastName
		if (formData.email) search.email = formData.email
		if (formData.phoneNumber) search.phoneNumber = formData.phoneNumber
		if (formData.role) search.role = [formData.role]
		if (formData.organization) search.organization = formData.organization

		setSearch(search)
	}

	return (
		<Form
			context={formContext}
			onSubmit={handleSubmit}
			className="p-6 bg-gray-50 dark:bg-gray-750 border-t border-gray-200 dark:border-gray-600"
		>
			<Stack dividers>
				<h3 className="text-lg leading-5 font-medium text-gray-900 dark:text-white">Search</h3>

				<Stack>
					<div className="flex items-start space-x-4">
						<FormInput name="firstName" label="First Name" className="flex-1" hideOptional autoFocus />
						<FormInput name="middleName" label="Middle Name" className="flex-1" hideOptional />
						<FormInput name="lastName" label="Last Name" className="flex-1" hideOptional />
					</div>

					<div className="flex space-x-4">
						<Stack className="flex-1">
							<FormInput name="email" label="Email" hideOptional />

							<FormSelect
								name="role"
								label="Role"
								options={
									presetValues?.role?.filter
										? UserRole.options.filter(presetValues?.role.filter)
										: UserRole.options
								}
								clearable
								hideOptional
							/>
						</Stack>

						<Stack className="flex-1">
							<FormInput name="phoneNumber" label="Phone" hideOptional />

							{!presetValues?.organization?.hidden && (
								<FormSelect
									name="organization"
									label="Organisation"
									options={
										data.Organizations.items?.map((x) => ({ label: x.name || '', value: x.id })) ||
										[]
									}
									clearable={!presetValues?.organization?.locked || true}
									disabled={presetValues?.organization?.locked}
									hideOptional
								/>
							)}
						</Stack>
					</div>
				</Stack>

				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Reset',
								icon: <Undo className="w-4 h-5" />,
								onClick: () => {
									setSearch()
									formContext.reset({})
								},
							},
							{
								title: 'Search',
								icon: <SearchIcon className="w-4 h-5" />,
								intent: 'primary',
								type: 'submit',
							},
						]}
					/>
				</div>
			</Stack>
		</Form>
	)
}

export default SearchForm
