import { useEffect, useState, useCallback, useMemo, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'
import Actions from 'components/Actions'
import Truncate from 'components/BasicTruncate'
import { ActionType, isNotNone, None } from 'types'
import { onlyText } from 'react-children-utilities'

const colSpan: { [key: number]: string } = {
	1: 'col-span-1',
	2: 'col-span-2',
	3: 'col-span-3',
	4: 'col-span-4',
	5: 'col-span-5',
	6: 'col-span-6',
	7: 'col-span-7',
	8: 'col-span-8',
	9: 'col-span-9',
	10: 'col-span-10',
	11: 'col-span-11',
	12: 'col-span-12',
}

const useMedia = (queries: string[]) => {
	const mediaQueryLists = useMemo(() => queries.map((q) => window.matchMedia(q)), [queries])

	const getValue = useCallback(() => {
		const matches = mediaQueryLists.map((mql) => mql.matches)
		return matches
	}, [mediaQueryLists])

	const [value, setValue] = useState<boolean[]>(getValue)

	useEffect(() => {
		const handler = () => setValue(getValue)
		mediaQueryLists.forEach((mql) => mql.addListener(handler))
		return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
	}, [getValue, mediaQueryLists])

	return value
}

const breakpoints = {
	sm: '640px',
	md: '768px',
	lg: '1024px',
	xl: '1280px',
	xxl: '1536px',
}

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

type OnClickParams = { [x: string]: any }

export const isVisible =
	(visibilities: Array<Visibility | None>) =>
	<T,>(x: TableCol<T>) =>
		visibilities.reduce<boolean>((pass, vis) => pass || x.visibility === vis, false)

type Visibility = 'copy-only' | 'screen-only' | 'all'

export interface TableCol<T> {
	title: ReactNode
	value: (x: T, i: number) => ReactNode
	copyValue?: (x: T, i: number) => string
	truncate?: boolean
	width?: string
	className?: ClassValue
	responsive?: Breakpoint
	onClick?: (x: string, y: OnClickParams) => void
	visibility?: Visibility
}

export interface TableSchema<T> {
	cols: (TableCol<T> | None)[]
	actions?: (x: T, i: number) => ActionType[] | None
}

interface TableProps<T> {
	schema: TableSchema<T>
	items: T[]
	getKey?: (x: T, i: number) => number | string
	isStriped?: boolean
	className?: ClassValue
}

const Table = <T,>({ schema, items, getKey = (_, i) => i, isStriped = false, className }: TableProps<T>) => {
	const [sm, md, lg, xl, xxl] = useMedia(Object.values(breakpoints).map((x) => `(min-width: ${x})`))
	const mqs = { sm, md, lg, xl, xxl }

	const cols = schema.cols
		.filter(isNotNone)
		.filter(isVisible([undefined, 'all', 'screen-only']))
		.filter((x) => {
			if (x.responsive) {
				return mqs[x.responsive]
			}

			return true
		})

	const tmplCols = cols.map((x) => x.width || 'minmax(0, auto)').join(' ')

	const actions = schema.actions

	return (
		<div className={clsx(className)}>
			<div
				className="grid border-t border-gray-200 dark:border-gray-600"
				style={{
					gridTemplateColumns: isNotNone(actions) ? tmplCols.concat(' minmax(auto, max-content)') : tmplCols,
				}}
			>
				{cols.map((col, i) => (
					<div
						key={i}
						className={clsx(
							'px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap dark:border-gray-600 dark:bg-gray-750 dark:text-gray-300',
							col.className
						)}
					>
						{col.title}
					</div>
				))}

				{isNotNone(actions) && (
					<div className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap dark:border-gray-600 dark:bg-gray-750 dark:text-gray-300"></div>
				)}

				{items.map((item, i) => (
					<div key={getKey(item, i)} className="contents group">
						{cols.map((col, j) => (
							<div
								key={j}
								className={clsx(
									'min-h-11 whitespace-nowrap text-sm leading-5 text-gray-500 dark:text-gray-300 group-hover:text-gray-700 dark:group-hover:text-gray-100 flex items-center border-b border-gray-100 dark:border-gray-600 group-hover:bg-primary-50 dark:group-hover:bg-gray-600',
									j === 0 && 'font-medium',
									!col.truncate && 'px-6 py-4',
									isStriped && i % 2 && 'bg-gray-50 dark:bg-gray-750',
									col.className
								)}
							>
								{col.truncate ? (
									<Truncate title={onlyText(col.value(item, i))} className="px-6 py-4">
										{col.value(item, i)}
									</Truncate>
								) : col.value ? (
									col.value(item, i)
								) : null}
							</div>
						))}
						{actions?.length ? (
							<div
								className={clsx(
									'px-6 whitespace-nowrap text-sm leading-5 text-gray-500 dark:text-gray-300 group-hover:text-gray-700 dark:group-hover:text-gray-100 flex items-center justify-center border-b border-gray-100 dark:border-gray-600 group-hover:bg-primary-50 dark:group-hover:bg-gray-600',
									isStriped && i % 2 && 'bg-gray-50 dark:bg-gray-750'
								)}
							>
								<Actions actions={actions(item, i)} className="flex-nowrap" />
							</div>
						) : null}
					</div>
				))}

				{items.length === 0 && (
					<div className="contents group">
						<div
							className={clsx(
								colSpan[cols.length + (actions?.length ? 1 : 0)],
								'px-6 py-3',
								'whitespace-nowrap text-sm leading-5 text-gray-500 dark:text-gray-300 flex items-center justify-center border-b border-gray-100 dark:border-gray-600 select-none'
							)}
						>
							No items
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Table
