import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react'

// https://github.com/react-container-query/container-query-toolkit/blob/master/src/matchQueries.ts
export interface ContainerRules {
	[key: string]: {
		minWidth?: number
		maxWidth?: number
	}
}

interface Entry {
	minWidth: number
	maxWidth: number
	className: string
}

function matchQueries(rules: ContainerRules) {
	const entries: Array<Entry> = []

	for (const className of Object.keys(rules)) {
		const rule = rules[className]
		entries.push({
			minWidth: rule.minWidth != null ? rule.minWidth : 0,
			maxWidth: rule.maxWidth != null ? rule.maxWidth : Infinity,
			className,
		})
	}

	return function ({ width }: { width?: number }) {
		const classNameMap: { [key: string]: boolean } = {}

		for (const { className, minWidth, maxWidth } of entries) {
			if (width != null) {
				classNameMap[className] = minWidth <= width && width <= maxWidth
			} else {
				classNameMap[className] = true
			}
		}

		return classNameMap
	}
}

class DummyObserver {
	constructor(cb: (entries: any) => void) {
		;(function () {})() // do nothing
	}
	observe(target: any) {}
	unobserve(target: any) {}
}

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
	queries: ContainerRules
	onQueryChange?: (x: Record<string, boolean>) => void
}

const Container: FC<ContainerProps> = ({ queries, onQueryChange, ...props }) => {
	const ref = useRef<HTMLDivElement>(null)
	const [matches, setMatches] = useState<Record<string, boolean>>({})

	useEffect(() => {
		const target = ref.current

		// const ResizeObserver = null

		const Observer = window.ResizeObserver || DummyObserver

		const resizeObserver = new Observer((entries) => {
			let match = matchQueries(queries)({ width: entries[0].contentRect.width })

			// this seems fuckin rough on perf, but lets deal with it later
			if (JSON.stringify(match) !== JSON.stringify(matches)) {
				setMatches(match)
				if (onQueryChange) onQueryChange(match)
			}
		})

		if (target) resizeObserver.observe(target)

		return () => {
			if (target) resizeObserver.unobserve(target)
		}
	}, [queries, onQueryChange, matches])

	return <div ref={ref} {...props} />
}

export default Container
