import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormCheckbox from 'components/Form/FormCheckbox'
import FormToggle from 'components/Form/FormToggle'
import FormSection from 'components/Form/FormSection'
import FormSelect from 'components/Form/FormSelect'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import useData from 'hooks/useData'
import Stack from 'components/Stack'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import { toast, warning } from 'alerts'
import AutoHeight from 'components/AutoHeight'
import countries, { nz } from 'utils/countries'
import { PaginatedSet, PresetValue } from 'types'
import { OrganizationModel, UserModel } from 'types/models'
import { USER_ROLE, UserRole } from 'types/enums'
import { ACCOrg } from 'utils/constants'
import { Auth } from 'Auth'

interface PresetValues {
	firstName?: PresetValue<string>
	lastName?: PresetValue<string>
	email?: PresetValue<string>
	organization?: PresetValue<OrganizationModel>
	roles?: {
		administrator?: PresetValue<boolean>
		support?: PresetValue<boolean>
		caseManager?: PresetValue<boolean>
		reviewer?: PresetValue<boolean>
		mediator?: PresetValue<boolean>
		peerReviewer?: PresetValue<boolean>
		respondent?: PresetValue<boolean>
		accAdmin?: PresetValue<boolean>
		fileHandler?: PresetValue<boolean>
		adjudicator?: PresetValue<boolean>
		facilitator?: PresetValue<boolean>
		FENZAdmin?: PresetValue<boolean>
	}
}

interface OpenCreateUserProps {
	refetch?: () => Promise<any>
	presetValues?: PresetValues
}

const openCreateUser = ({ refetch, presetValues }: OpenCreateUserProps) => {
	return openModal<UserModel>({
		title: 'Create User',
		size: 'xl',
		render: (close) => <CreateUserForm close={close} refetch={refetch} presetValues={presetValues} />,
	})
}

interface CreateUserFormData {
	user: Partial<UserModel>
	roles: {
		[USER_ROLE.FileHandler]: boolean
		[USER_ROLE.Support]: boolean
		[USER_ROLE.Respondent]: boolean
		[USER_ROLE.CaseManager]: boolean
		[USER_ROLE.Administrator]: boolean
		[USER_ROLE.Reviewer]: boolean
		[USER_ROLE.Mediator]: boolean
		[USER_ROLE.PeerReviewer]: boolean
		[USER_ROLE.ACCAdmin]: boolean
		[USER_ROLE.Adjudicator]: boolean
		[USER_ROLE.Facilitator]: boolean
		[USER_ROLE.FENZAdmin]: boolean
	}
}

interface CreateUserFormProps {
	close: (x: UserModel) => void
	refetch?: () => Promise<any>
	presetValues?: PresetValues
}

const CreateUserForm: FC<CreateUserFormProps> = ({ close, refetch, presetValues }) => {
	const { isSaving, setSaving } = useModalState()

	const { data: orgs } = useData<PaginatedSet<OrganizationModel>>(
		'/Organizations?pageSize=100&orderBy=created&orderDirection=1'
	)

	const formContext = useForm<CreateUserFormData>({
		defaultValues: {
			user: {
				firstName: presetValues?.firstName?.value || '',
				middleName: '',
				lastName: presetValues?.lastName?.value || '',
				email: presetValues?.email?.value || '',
				note: '',
				phoneNumber: '',
				useOrganization: false,
				organization: presetValues?.organization?.value,
				notificationsEnabled: true,
				disableEmailVerification: false,
				physicalAddress: {
					address1: '',
					address2: '',
					address3: '',
					city: '',
					zipOrPostcode: '',
					country: nz,
				},
			},
			roles: {
				[USER_ROLE.FileHandler]: presetValues?.roles?.fileHandler?.value || false,
				[USER_ROLE.Respondent]: presetValues?.roles?.respondent?.value || false,
				[USER_ROLE.Support]: presetValues?.roles?.support?.value || false,
				[USER_ROLE.CaseManager]: presetValues?.roles?.caseManager?.value || false,
				[USER_ROLE.Administrator]: presetValues?.roles?.administrator?.value || false,
				[USER_ROLE.Reviewer]: presetValues?.roles?.reviewer?.value || false,
				[USER_ROLE.Mediator]: presetValues?.roles?.mediator?.value || false,
				[USER_ROLE.PeerReviewer]: presetValues?.roles?.peerReviewer?.value || false,
				[USER_ROLE.ACCAdmin]: presetValues?.roles?.accAdmin?.value || false,
				[USER_ROLE.Adjudicator]: presetValues?.roles?.adjudicator?.value || false,
				[USER_ROLE.Facilitator]: presetValues?.roles?.facilitator?.value || false,
				[USER_ROLE.FENZAdmin]: presetValues?.roles?.FENZAdmin?.value || false,
			},
		},
	})

	const isReviewer = formContext.watch(`roles.${USER_ROLE.Reviewer}`)
	const isMediator = formContext.watch(`roles.${USER_ROLE.Mediator}`)

	const org: OrganizationModel = formContext.watch('user.organization')

	const handleSubmit = async (formData: CreateUserFormData) => {
		const data = { ...formData.user }

		if (!data.organization) data.useOrganization = false

		data.roles = Object.entries(formData.roles).reduce<Array<{ id: USER_ROLE; name: string }>>(
			(previousValue, [key, keep]) => {
				if (keep) {
					return [...previousValue, { id: key as USER_ROLE, name: '' }]
				}
				return previousValue
			},
			[]
		)

		setSaving(true)

		try {
			let { data: users } = await api.get<PaginatedSet<UserModel>>(
				`/Users?search=${JSON.stringify({ email: data.email })}`
			)

			if (users.items.length > 0) {
				warning({
					title: 'User Exists',
					message: 'A user with that email address already exists',
				})
				setSaving(false)
				return
			}

			const res = await api.post('/Accounts/register', data)

			if (refetch) refetch()

			close(res.data as UserModel)

			// used the formdata since api has no disableEmailVerification column response on res.data
			toast({
				title: 'User Registered',
				message:
					!formData.user.disableEmailVerification &&
					'An email will be sent to their inbox to complete registration',
			})
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack space={8} dividers>
					<FormSection title="Primary Information">
						<Stack>
							<div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
								<FormInput
									name="user.firstName"
									label="First Name"
									validations={{ required: 'First Name is required' }}
									disabled={presetValues?.firstName?.locked}
									autoFocus
								/>
								<FormInput name="user.middleName" label="Middle Name" />
								<FormInput
									name="user.lastName"
									label="Last Name"
									validations={{ required: 'Last Name is required' }}
									disabled={presetValues?.lastName?.locked}
								/>
							</div>

							<div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
								<FormInput
									name="user.email"
									label="Email Address"
									validations={{ required: 'Email Address is required' }}
									disabled={presetValues?.email?.locked}
								/>
								<FormInput
									name="user.phoneNumber"
									label="Phone Number"
									validations={{ required: 'Phone Number is required' }}
								/>
							</div>
						</Stack>
					</FormSection>

					<FormSection title="Address">
						<Stack>
							<FormInput
								name="user.physicalAddress.address1"
								label="Address 1"
								validations={{ required: 'Address 1 is required' }}
							/>

							<div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
								<FormInput name="user.physicalAddress.address2" label="Address 2" />
								<FormInput name="user.physicalAddress.address3" label="Address 3" />
							</div>

							<div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
								<FormInput
									name="user.physicalAddress.city"
									label="City"
									validations={{ required: 'City is required' }}
								/>
								<FormInput
									name="user.physicalAddress.zipOrPostcode"
									label="Post Code"
									validations={{ required: 'Post Code is required' }}
								/>
								<FormSelect
									name="user.physicalAddress.country"
									label="Country"
									options={countries?.map((x) => ({ label: x.name, value: x })) || []}
									validations={{ required: 'Country is required' }}
								/>
							</div>
						</Stack>
					</FormSection>

					<FormSection title="Notes">
						<FormInput name="user.note" label="Notes" as="textarea" />
					</FormSection>

					<FormSection title="Organisation">
						<Stack>
							<FormSelect
								name="user.organization"
								label="Organisation"
								options={orgs?.items.map((x) => ({ label: x.name, value: x })) || []}
								disabled={presetValues?.organization?.locked}
								clearable
							/>

							<AutoHeight show={!!org}>
								<FormToggle
									name="user.useOrganization"
									label="Also send case notifications to organisation"
								/>
							</AutoHeight>
						</Stack>
					</FormSection>

					{Auth.is('Admin', 'CaseManager', 'FENZAdmin') && (
						<FormSection title="Roles">
							<div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0">
								<Stack className="flex-1">
									{Auth.is('Admin', 'FENZAdmin') && (
										<FormCheckbox
											name={`roles.${USER_ROLE.Administrator}`}
											label={UserRole.readable(USER_ROLE.Administrator)}
											disabled={presetValues?.roles?.administrator?.locked}
										/>
									)}
									{Auth.is('Admin', 'FENZAdmin') && (
										<FormCheckbox
											name={`roles.${USER_ROLE.Support}`}
											label={UserRole.readable(USER_ROLE.Support)}
											disabled={presetValues?.roles?.support?.locked}
										/>
									)}

									<FormCheckbox
										name={`roles.${USER_ROLE.CaseManager}`}
										label={UserRole.readable(USER_ROLE.CaseManager)}
										disabled={presetValues?.roles?.caseManager?.locked}
									/>
								</Stack>

								<Stack className="flex-1">
									<FormCheckbox
										name={`roles.${USER_ROLE.Reviewer}`}
										label={UserRole.readable(USER_ROLE.Reviewer)}
										disabled={presetValues?.roles?.reviewer?.locked}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.Mediator}`}
										label={UserRole.readable(USER_ROLE.Mediator)}
										disabled={presetValues?.roles?.mediator?.locked}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.PeerReviewer}`}
										label={UserRole.readable(USER_ROLE.PeerReviewer)}
										disabled={presetValues?.roles?.peerReviewer?.locked}
									/>
								</Stack>

								<Stack className="flex-1">
									<FormCheckbox
										name={`roles.${USER_ROLE.Respondent}`}
										label={
											org?.id === ACCOrg.id
												? 'ACC Review Specialist'
												: UserRole.readable(USER_ROLE.Respondent)
										}
										disabled={presetValues?.roles?.respondent?.locked}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.ACCAdmin}`}
										label={UserRole.readable(USER_ROLE.ACCAdmin)}
										disabled={presetValues?.roles?.accAdmin?.locked}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.FileHandler}`}
										label={UserRole.readable(USER_ROLE.FileHandler)}
										disabled={presetValues?.roles?.fileHandler?.locked}
									/>
								</Stack>

								{/* new role for fenz */}
								<Stack className="flex-1">
									{Auth.is('Admin', 'FENZAdmin') && (
										<FormCheckbox
											name={`roles.${USER_ROLE.FENZAdmin}`}
											label={UserRole.readable(USER_ROLE.FENZAdmin)}
											disabled={presetValues?.roles?.FENZAdmin?.locked}
										/>
									)}

									<FormCheckbox
										name={`roles.${USER_ROLE.Adjudicator}`}
										label={UserRole.readable(USER_ROLE.Adjudicator)}
										disabled={presetValues?.roles?.adjudicator?.locked}
									/>

									<FormCheckbox
										name={`roles.${USER_ROLE.Facilitator}`}
										label={UserRole.readable(USER_ROLE.Facilitator)}
										disabled={presetValues?.roles?.facilitator?.locked}
									/>
								</Stack>
								{/* new role for fenz */}
							</div>
						</FormSection>
					)}

					<AutoHeight show={Auth.is('Admin', 'FENZAdmin') && (!!isReviewer || !!isMediator)}>
						<FormSection title="Billing">
							<Stack>
								<div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
									<FormInput name="user.bankAccountNumber" label="Bank Account Number" />

									<FormInput name="user.gstnumber" label="GST Number" />
								</div>

								<FormToggle name="user.isGSTRegistered" label="User is GST Registered" />
							</Stack>
						</FormSection>
					</AutoHeight>

					<FormSection title="Settings">
						<div className="flex flex-row">
							<Stack className="flex-1">
								<FormToggle name="user.notificationsEnabled" label="Notifications Enabled" />
							</Stack>

							<Stack className="flex-1">
								<FormToggle name="user.disableEmailVerification" label="Disable account verification" />
							</Stack>
						</div>
					</FormSection>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openCreateUser
