import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query'
import api from 'api'
import { PaginatedSet, InitialPaginationState } from 'types'
import { queryString } from 'utils/funcs'

const usePaginatedData = <T>(
	endpoint: string,
	paginationState: InitialPaginationState = {},
	options?: UseQueryOptions<PaginatedSet<T>, unknown, T, any[]>
): UseQueryResult<PaginatedSet<T>> => {
	let result = useQuery(
		[endpoint, paginationState],
		async () => {
			let qs = queryString({
				pageNumber: paginationState.page,
				pageSize: paginationState.pageSize,
				orderBy: paginationState.orderBy,
				orderDirection: paginationState.orderDir,
			})

			if (paginationState.search) {
				let search = JSON.stringify(paginationState.search)

				if (search !== '{}') {
					qs += `&search=${encodeURIComponent(search)}`
				}
			}

			const { data }: { data: PaginatedSet<T> } = await api.get(endpoint + (qs ? `?${qs}` : ''))

			return data
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			...options,
		}
	)

	return result as UseQueryResult<PaginatedSet<T>> // the fuck is this for
}

export default usePaginatedData
