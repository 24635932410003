import { FC, useEffect, useMemo } from 'react'
import Stack from 'components/Stack'
import Card from 'components/Card'
import CardHeader from 'components/CardHeader'
import Badge from 'components/Badge'
import { format } from 'date-fns'
import versions from 'utils/versions'
import useStore from 'Auth'
import semver from 'semver'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'

const Changelog: FC = () => {
	const latestVersion = useStore((store) => store.version)
	const releases = useMemo(() => versions.sort((a, b) => (semver.gt(a.number, b.number) ? -1 : 1)), [])

	useEffect(() => {
		if (latestVersion !== releases[0].number) {
			useStore.getState().setLatestVersion(releases[0].number)
		}
	}, [latestVersion, releases])

	return (
		<>
			<PageHeading title="Version History" />

			<PageContent>
				<Stack>
					{releases.map((version, i) => (
						<Card
							key={i}
							header={
								<CardHeader
									title={version.number}
									subtitle={'Released on ' + format(new Date(version.date), 'do MMMM yyyy')}
								/>
							}
						>
							<Stack space={2}>
								{version.new.map((text, i) => (
									<div key={i} className="flex items-center">
										<Badge color="indigo" className="mr-2" size="sm">
											New
										</Badge>
										{text}
									</div>
								))}

								{version.change.map((text, i) => (
									<div key={i}>
										<Badge color="green" className="mr-2" size="sm">
											Change
										</Badge>
										{text}
									</div>
								))}

								{version.bug.map((text, i) => (
									<div key={i}>
										<Badge color="red" className="mr-2" size="sm">
											Fix
										</Badge>
										{text}
									</div>
								))}
							</Stack>
						</Card>
					))}
				</Stack>
			</PageContent>
		</>
	)
}

export default Changelog
