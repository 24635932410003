import { FC, ReactNode, useState, useEffect, useRef, useCallback, MouseEvent } from 'react'
import { BackdropTransition, AlertTransition } from 'components/AlertTransition'
import Stack from 'components/Stack'
import { XIcon } from '@heroicons/react/outline'
import alert from 'utils/alert'
import useAutoFocus from 'hooks/useAutoFocus'

interface WarningProps {
	resolve: (x: boolean) => void
	close: () => void
	title: ReactNode
	message?: ReactNode
	dismissable?: boolean
}

const Warning: FC<WarningProps> = ({ resolve, close, title, message, dismissable = true }) => {
	const [open, setOpen] = useState(true)

	const closeAlert = useCallback(
		(x: boolean) => {
			setOpen(false)
			close()
			setTimeout(() => {
				resolve(x)
			}, 300)
		},
		[resolve, close]
	)

	const ref = useRef<HTMLDivElement>(null)

	const autoFocusRef = useAutoFocus<HTMLButtonElement>()

	useEffect(() => {
		const handleClose = () => closeAlert(false)

		window.addEventListener('keyup', handleClose)
		return () => window.removeEventListener('keyup', handleClose)
	}, [closeAlert])

	return (
		<BackdropTransition show={open}>
			<div
				className="fixed inset-0 w-screen h-screen flex p-4 justify-center bg-black bg-opacity-50 overflow-y-auto z-[60] backdrop-filter backdrop-grayscale"
				onClick={(e: MouseEvent) => {
					if (e.target === ref.current) {
						dismissable && closeAlert(false)
					}
				}}
				ref={ref}
			>
				<AlertTransition
					show={open}
					className="w-full pointer-events-none sm:flex sm:justify-center sm:items-start"
				>
					<div
						className="bg-white dark:bg-gray-700 rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl sm:max-w-sm sm:w-full sm:p-6 pointer-events-auto"
						ref={dismissable ? ref : undefined}
					>
						<div>
							<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
								<XIcon className="h-6 w-6 text-red-600" />
							</div>
							<div className="mt-3 text-center sm:mt-5">
								<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">{title}</h3>
								{message && (
									<div className="mt-2 text-sm leading-5 text-gray-500 dark:text-gray-300">
										<Stack space={2}>{message}</Stack>
									</div>
								)}
							</div>
						</div>
						<div className="mt-5 sm:mt-6">
							<span className="flex w-full rounded-md shadow-sm">
								<button
									onClick={() => closeAlert(true)}
									type="button"
									className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5 dark:border-gray-600 dark:text-gray-200 dark:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700"
									ref={autoFocusRef}
								>
									Ok
								</button>
							</span>
						</div>
					</div>
				</AlertTransition>
			</div>
		</BackdropTransition>
	)
}

export default Warning

export const warning = async ({ title, message }: { title: ReactNode; message?: ReactNode }) => {
	await alert(({ resolve, close }) => (
		<Warning resolve={resolve} close={close} title={title} message={message} dismissable={true} />
	))
}
