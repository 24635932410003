import { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import clsx, { ClassValue } from 'clsx'
import { FilePlus } from 'components/Icon'

interface FileUploaderProps {
	onChange: (x: File[]) => void
	description?: string
	className?: ClassValue
}

const FileUploader: FC<FileUploaderProps> = ({ onChange, description = '', className }) => {
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			onChange(acceptedFiles)
		},
		[onChange]
	)
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<div {...getRootProps()} className="focus:outline-none">
			<input {...getInputProps()} />

			<div
				className={clsx(
					'flex justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md',
					{
						'border-gray-300 hover:border-primary-300 dark:border-gray-600': !isDragActive,
						'border-primary-300 bg-primary-100 dark:bg-primary-600': isDragActive,
					},
					className
				)}
			>
				<div>
					<FilePlus className="mx-auto h-12 w-12 text-gray-400" />

					<p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
						<button
							type="button"
							className="font-medium text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1"
						>
							Upload a file
						</button>
						or drag and drop
					</p>
					{description && <p className="mt-1 text-xs text-gray-500">{description}</p>}
				</div>
			</div>
		</div>
	)
}

export default FileUploader
