import { FC, ReactNode, useEffect } from 'react'
import Actions from 'components/Actions'
import ButtonSpacer from 'components/ButtonSpacer'
import { ActionType, isNotNone, None } from 'types'
import clsx, { ClassValue } from 'clsx'

interface PageInfo {
	title: ReactNode
	icon?: ReactNode
}

type PageInfoType = PageInfo | None

interface PageHeadingProps {
	title?: ReactNode
	pageTitle?: string
	actions?: ActionType[]
	infoList?: PageInfoType[]
	className?: ClassValue
}

const PageHeading: FC<PageHeadingProps> = ({ title = '', pageTitle, actions = [], infoList, className }) => {
	useEffect(() => {
		const value = pageTitle || title
		document.title = `${value} - ICRA`
	}, [title, pageTitle])

	return (
		<div className={clsx(className)}>
			<div className="pt-3 lg:pt-6 antialiased">
				<div className="max-w-7xl mx-auto px-4 mb-8 sm:px-6 lg:px-8">
					<div className="md:flex md:items-start md:justify-between flex-wrap">
						<div className="mr-4 my-3 flex-1 mb-6 md:mb-0">
							<h2 className="flex items-center text-2xl leading-8 font-bold text-gray-900 dark:text-white sm:text-3xl sm:leading-9 whitespace-nowrap">
								{title}
							</h2>
							{infoList && (
								<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
									{infoList.filter(isNotNone).map((info, i) => (
										<div
											key={i}
											className="mt-2 flex items-center text-sm leading-5 text-gray-600 dark:text-gray-300 sm:mr-6"
										>
											{info.icon && (
												<div className="shrink-0 mr-1.5 text-gray-500 dark:text-gray-200">
													{info.icon}
												</div>
											)}
											{info.title}
										</div>
									))}
								</div>
							)}
						</div>

						{actions.filter(isNotNone).length > 0 ? (
							<div className="my-3 flex">
								<Actions actions={actions} />
							</div>
						) : (
							<div className="my-3 hidden md:block">
								<ButtonSpacer />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PageHeading
