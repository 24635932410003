import { ReactNode, useRef, MouseEvent, useEffect, useCallback, createContext, useState, useContext } from 'react'
import { BackdropTransition, AlertTransition } from 'components/AlertTransition'
import alert from 'utils/alert'
import { ModalResolveFn } from 'types'

export type CustomAlertSizeValue = 'sm' | 'md' | 'lg' | 'xl'

const sizes = {
	sm: '20rem',
	md: '40rem',
	lg: '60rem',
	xl: '75rem',
}

type ModalSavingStateType = { isSaving: boolean; setSaving: (x: boolean) => void }

const ModalSavingState = createContext<ModalSavingStateType>({ isSaving: false, setSaving: (x) => {} })

export const useModalSavingState = () => useContext(ModalSavingState)

interface CustomAlertProps<T> {
	resolve: (x?: T) => void
	close: () => void
	dismissable?: boolean
	size?: CustomAlertSizeValue
	children: (close: (x?: T) => void) => ReactNode
}

function CustomAlert<T>({ resolve, close, children, size = 'md', dismissable = false }: CustomAlertProps<T>) {
	const [open, setOpen] = useState(true)

	const savingState = useModalSavingState()

	const closeModal = useCallback(
		(x) => {
			if (savingState?.isSaving) return
			resolve(x)

			setOpen(false)
			setTimeout(() => {
				close()
			}, 500)
		},
		[close, resolve, savingState?.isSaving]
	)

	const backdropRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handle = (e: KeyboardEvent) => {
			if (e.key === 'Escape' && dismissable) closeModal(false)
		}

		window.addEventListener('keydown', handle)
		return () => window.removeEventListener('keydown', handle)
	}, [closeModal, dismissable])

	return (
		<BackdropTransition show={open}>
			<div
				className="fixed inset-0 z-40 w-screen h-screen flex p-4 justify-center bg-black bg-opacity-50 overflow-y-auto backdrop-filter backdrop-grayscale"
				onClick={(e: MouseEvent) => {
					if (e.target === backdropRef.current) {
						dismissable && closeModal(false)
					}
				}}
				ref={backdropRef}
			>
				<AlertTransition
					show={open}
					className="w-full pointer-events-none sm:flex sm:justify-center sm:items-start relative z-40"
				>
					<div
						className="rounded-lg sm:max-w-sm sm:w-full sm:p-6 pointer-events-auto"
						style={{ maxWidth: sizes[size] }}
					>
						{children(closeModal)}
					</div>
				</AlertTransition>
			</div>
		</BackdropTransition>
	)
}

export default CustomAlert

interface customAlertProps<T> {
	dismissable?: boolean
	size?: CustomAlertSizeValue
	children: (close: ModalResolveFn<T>) => ReactNode
}

export function customAlert<T = boolean>({ dismissable, size, children }: customAlertProps<T>) {
	return alert<T>(({ resolve, close }) => {
		const [isSaving, setSaving] = useState(false)

		return (
			<ModalSavingState.Provider value={{ isSaving, setSaving }}>
				<CustomAlert
					resolve={resolve}
					close={close}
					children={children}
					dismissable={dismissable}
					size={size}
				/>
			</ModalSavingState.Provider>
		)
	})
}
