import { FC } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import api from 'api'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormCheckbox from 'components/Form/FormCheckbox'
import { CheckIcon } from '@heroicons/react/outline'
import { isAxiosError, ModalResolveFn } from 'types'
import Storage from 'utils/storage'
import conf from 'utils/config'
import { warning } from 'alerts'

interface OpenTwoFactorProps {
	email: string
}

const openTwoFactor = ({ email }: OpenTwoFactorProps) => {
	return openModal({
		title: 'Login Verification',
		render: (close) => <TwoFactor close={close} email={email} />,
	})
}

interface TwoFactorProps {
	close: ModalResolveFn
	email: string
}

interface FormData {
	code: string
	rememberDevice: boolean
}

const TwoFactor: FC<TwoFactorProps> = ({ close, email }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			code: '',
			rememberDevice: true,
		},
	})

	const handleSubmit = async (formData: FormData) => {
		if (!formData.code) return false

		setSaving(true)

		try {
			let res = await axios.post(
				`${conf.url}/Auth/verifytwofactor`,
				{
					...formData,
					email,
					userAgent: window.navigator.userAgent,
					deviceToken: Storage.getString('deviceToken'),
				},
				{
					headers: {
						Registry: conf.registry,
					},
				}
			)

			close(res)
		} catch (error) {
			if (isAxiosError(error)) {
				if (
					error?.response?.data?.title !== 'Unable to authenticate user' &&
					error?.response?.data?.title !== 'The following validation errors occurred'
				) {
					api.handleError(error)
				} else {
					warning({ title: 'Login Failed', message: error?.response?.data?.title })
				}
			}

			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<div className="text-sm font-semibold px-4 py-3 bg-primary-100 text-primary-600 rounded">
						Please enter your verification code (this was emailed to you)
					</div>
					<FormInput
						name="code"
						label="Verification Code"
						validations={{ required: 'Verification Code is required' }}
					/>
					<FormCheckbox name="rememberDevice" label="Remember me on this device" />
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Submit',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openTwoFactor
