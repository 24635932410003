import { FC, ReactNode, useEffect, useState } from 'react'
import { motion, AnimatePresence, MotionStyle } from 'framer-motion'

interface AutoHeightProps {
	show: boolean
	children: ReactNode
	style?: { [x: string]: string }
}

const AutoHeight: FC<AutoHeightProps> = ({ show, style, children }) => {
	const [initialState, setInitialState] = useState(show)
	const [isAnimating, setAnimating] = useState(false)
	const [isVisible, setVisible] = useState<boolean>(show)

	useEffect(() => {
		if (show) {
			setAnimating(true)
			setTimeout(() => setVisible(true), 1)
			setTimeout(() => setAnimating(false), 400)
		} else {
			setAnimating(true)
			setTimeout(() => setVisible(false), 1)
			setTimeout(() => setAnimating(false), 400)
		}
	}, [show])

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					style={
						{
							originY: 0,
							padding: '0.5rem',
							margin: '-0.5rem',
							overflow: isAnimating ? 'hidden' : 'visible',
							...style,
						} as MotionStyle
					}
					variants={{
						hidden: { height: 0 },
						visible: { height: 'auto' },
					}}
					transition={{
						type: 'tween',
						ease: 'easeOut',
						duration: 0.3,
					}}
					onAnimationComplete={() => setInitialState(false)}
					initial={initialState ? 'visible' : 'hidden'}
					animate="visible"
					exit="hidden"
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default AutoHeight
