import { FC, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import Stack from 'components/Stack'
import api from 'api'
import { ensureDate, getDateFromTime, nzDate } from 'utils/funcs'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormDate from 'components/Form/FormDate'
import FormSelect from 'components/Form/FormSelect'
import { toast } from 'components/toast'
import {
	FORUM_TYPE,
	ForumType,
	HEARING_TYPE,
	Duration,
	TimeSlots,
	ADR_CLOSING_CATEGORY,
	ADR_TYPE,
	ADRClosingCategory,
	ADRType,
} from 'types/enums'
import {
	setMinutes,
	addMinutes,
	subMinutes,
	getMinutes,
	setHours,
	getHours,
	differenceInMinutes,
	format,
	parseISO,
	subDays,
	addDays,
	endOfDay,
} from 'date-fns'
import { HearingModel } from 'types/models'
import { RefetchFn } from 'types'
import FormToggle from 'components/Form/FormToggle'
import AutoHeight from 'components/AutoHeight'
import { gql, useQuery } from '@apollo/client'
import { GQLConnection, GQLVenueType } from 'types/gql'

const venuesQuery = gql`
	query {
		Venues {
			items {
				id
				name
				address
			}
		}
	}
`

const endOfToday = getDateFromTime('17:00')

interface OpenEditHearingDetailsProps {
	hearing: HearingModel
	refetch: RefetchFn
}

const openEditHearingDetails = ({ hearing, refetch }: OpenEditHearingDetailsProps) => {
	return openModal({
		title: 'Edit Details',
		render: (close) => <EditHearingDetailsForm hearing={hearing} refetch={refetch} close={close} />,
	})
}

interface FormData {
	hearingType: HEARING_TYPE
	date: Date
	time: string | null
	duration: number | null
	forumType: FORUM_TYPE
	venue: string
	venueId?: string
	zoomMeeting: string
	closingCategory: ADR_CLOSING_CATEGORY
	adrType: ADR_TYPE
	updateClosingDate: boolean
	notificationsEnabled: boolean
	nonAttendance: boolean
	isPostponed: boolean
}

const EditHearingDetailsForm: FC<OpenEditHearingDetailsProps & { close: () => void }> = ({
	hearing,
	refetch,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const { data: venues } = useQuery<{ Venues: GQLConnection<GQLVenueType> }>(venuesQuery)

	const formContext = useForm<FormData>({
		defaultValues: {
			hearingType: hearing.hearingType,
			date: hearing.startDate || new Date().valueOf(),
			time: TimeSlots.find(
				(x) => x.from === format(nzDate(parseISO(hearing.startDate || '1970-01-01')), 'h:mm a').toLowerCase()
			)?.from,
			duration: hearing.endDate
				? differenceInMinutes(parseISO(hearing.endDate), parseISO(hearing.startDate || '1970-01-01')) || null
				: null,
			forumType: hearing.forumType,
			venue: hearing.venue || '',
			venueId: hearing?.venue && !hearing?.venueId ? 'custom' : hearing?.venueId || '',
			zoomMeeting: hearing.zoomMeeting || '',
			closingCategory: hearing.closingCategory,
			adrType: hearing.adrType,
			updateClosingDate: false,
			notificationsEnabled: hearing?.notificationsEnabled !== undefined ? !!hearing?.notificationsEnabled : true,
			nonAttendance: hearing?.nonAttendance !== undefined ? hearing?.nonAttendance : false,
			isPostponed: hearing?.isPostponed,
		},
	})

	const { time, date, forumType, duration, isPostponed, venueId } = formContext.watch([
		'time',
		'date',
		'forumType',
		'duration',
		'isPostponed',
		'venueId',
	])

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			let startDate: string | Date | undefined = hearing.startDate
			let endDate: string | Date | undefined = hearing.endDate

			let fromDate = new Date(`1970-01-01 ${formData.time}`)

			startDate = setMinutes(
				setHours(ensureDate(formData.date || hearing.startDate), getHours(fromDate)),
				getMinutes(fromDate)
			)

			if (formData.duration) {
				endDate = addMinutes(startDate, formData.duration)
			}

			if (formData.forumType === FORUM_TYPE.OnThePapers && hearing.startDate) {
				startDate = endOfDay(parseISO(hearing.startDate))
				endDate = endOfDay(parseISO(hearing.startDate))
			}

			if (!startDate) return

			let decisionDate = addDays(ensureDate(startDate), 28)

			const closingDate = !hearing.closingDate || formData.updateClosingDate ? new Date() : hearing.closingDate

			const body = {
				...hearing,
				...formData,
				startDate,
				endDate,
				decisionDate,
				peerReviewDate: hearing.hearingType === HEARING_TYPE.Review ? subDays(decisionDate, 10) : null,
				closingDate,
				hearingVenue: !formData.venueId || formData.venueId === 'custom' ? undefined : { id: formData.venueId },
			}

			await api.put(`/CaseHearings/${hearing.id}`, body)

			await refetch()

			toast({ title: 'Updated Details' })

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	let durations = useMemo(() => {
		if (+hearing.hearingType === HEARING_TYPE.CMC) return Duration.CMC.options
		if (+hearing.hearingType === HEARING_TYPE.Review) return Duration.Review.options
		if (+hearing.hearingType === HEARING_TYPE.Mediation) return Duration.Mediation.options
	}, [hearing.hearingType])

	let timeOptions = useMemo(() => {
		if (!duration) {
			return TimeSlots.map((x) => x.from)
		} else {
			let endPoint = +subMinutes(endOfToday, duration)
			return TimeSlots.filter((x) => +getDateFromTime(x.from24) <= endPoint).map((x) => x.from)
		}
	}, [duration])

	// unset time if it becomes invalid
	useEffect(() => {
		if (time && !timeOptions.find((x) => x === time)) {
			formContext.setValue('time', null)
		}
	}, [time, timeOptions, formContext])

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					{hearing.isPostponed ? (
						<Stack>
							<FormToggle name="isPostponed" label="Postponed" />

							<AutoHeight show={hearing.isPostponed && !isPostponed}>
								<FormDate
									name="date"
									label="Date"
									autoFocus
									validations={{
										required: 'Date is required',
									}}
									disableHolidays
								/>
							</AutoHeight>
						</Stack>
					) : (
						hearing.hearingType !== HEARING_TYPE.Review && (
							<FormDate
								name="date"
								label="Date"
								autoFocus
								validations={{
									required: 'Date is required',
								}}
								disableHolidays
							/>
						)
					)}

					<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
						<div className="flex space-x-6">
							<div className="w-1/2">
								<FormSelect
									name="time"
									label="Time"
									options={timeOptions.map((x) => ({ label: x, value: x }))}
									disabled={!date}
									validations={{
										required: 'Time is required',
									}}
								/>
							</div>

							<div className="w-1/2">
								<FormSelect
									name="duration"
									label="Duration"
									options={durations || []}
									disabled={!time}
									validations={{
										required: 'Duration is required',
									}}
								/>
							</div>
						</div>
					</AutoHeight>

					<FormSelect
						name="forumType"
						label="Forum"
						options={ForumType.options}
						validations={{
							required: 'Forum is required',
						}}
					/>

					{/* <AutoHeight show={forumType === FORUM_TYPE.FaceToFace}>
						<FormInput name="venue" label="Venue" validations={{ required: 'Venue is required' }} />
					</AutoHeight> */}

					<AutoHeight show={forumType === FORUM_TYPE.FaceToFace}>
						<FormSelect
							name="venueId"
							label="Venue"
							options={
								venues?.Venues.items?.concat({ id: 'custom', name: 'Custom' })?.map((x) => ({
									value: x.id,
									label: (
										<div>
											<div>{x.name}</div>
											{x.address && <div className="opacity-50 text-sm">{x.address}</div>}
										</div>
									),
								})) || []
							}
							validations={{ required: 'Venue is required' }}
							defaultValue={hearing?.venueId || ''}
						/>
					</AutoHeight>

					<AutoHeight show={forumType === FORUM_TYPE.FaceToFace && venueId === 'custom'}>
						<FormInput
							name="venue"
							label="Venue Address"
							validations={{ required: 'Venue is required' }}
							defaultValue={hearing?.venue || ''}
						/>
					</AutoHeight>

					<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
						<FormInput
							name="zoomMeeting"
							label="Meeting Details"
							as="textarea"
							validations={
								forumType !== FORUM_TYPE.FaceToFace
									? { required: 'Meeting Details is required' }
									: undefined
							}
						/>
					</AutoHeight>

					<FormToggle name="notificationsEnabled" label="Notifications Enabled" />

					{(hearing.hearingType === HEARING_TYPE.Review ||
						hearing.hearingType === HEARING_TYPE.Mediation) && (
						<AutoHeight show={forumType === FORUM_TYPE.FaceToFace}>
							<FormToggle name="nonAttendance" label="Billing: Non-Attendance" />
						</AutoHeight>
					)}

					<AutoHeight show={hearing.hearingType === HEARING_TYPE.Mediation}>
						<Stack>
							<FormSelect
								name="adrType"
								label="ADR Type"
								options={ADRType.options}
								defaultValue={ADR_CLOSING_CATEGORY.Open}
								validations={{ required: 'ADR Type is required' }}
							/>

							<FormSelect
								name="closingCategory"
								label="Closing Category"
								options={ADRClosingCategory.options}
								validations={{ required: 'Closing Category is required' }}
							/>
						</Stack>
					</AutoHeight>

					{hearing.closingDate && <FormToggle name="updateClosingDate" label="Update Closing Date" />}
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditHearingDetails
