import { FC, useState } from 'react'
import SectionHeader from 'components/SectionHeader'
import DataListColumns from 'components/DataListColumns'
import { format, parseISO } from 'date-fns'
import { Auth } from 'Auth'
import { getFullName, getCaseRole, ensureDate } from 'utils/funcs'
import Stack from 'components/Stack'
import openEditHearingDetails from 'modals/openEditHearingDetails'
import openEditHearingReviews from 'modals/openEditHearingReviews'
import openEditHearingAttendees from 'modals/openEditHearingAttendees'
import openEditHearingSpecialRequirements from 'modals/openEditHearingSpecialRequirements'
import openEditHearingOperationalDetails from 'modals/openEditHearingOperationalDetails'
import openAdjournHearing from 'modals/openAdjournHearing'
import openUserSlideout from 'slideouts/openUserSlideout'
import { PencilIcon, PlusIcon } from '@heroicons/react/outline'
import { AdjournmentModel, CaseModel, HearingModel, ReviewModel, UserModel } from 'types/models'
import { RefetchFn } from 'types'
import Table, { TableSchema } from 'components/Table'
import { ADRClosingCategory, ADRType, ForumType, FORUM_TYPE, HEARING_TYPE } from 'types/enums'
import UserAvatar from 'components/UserAvatar'
import UserDefault from 'components/UserDefault'
import TableFrame from 'components/TableFrame'
import { FENZOrgId } from 'utils/constants'
import Container from 'components/Container'
import BasicTruncate from 'components/BasicTruncate'

interface HearingProps {
	hearing: HearingModel
	caseData: CaseModel
	refetch: RefetchFn
}

const Hearing: FC<HearingProps> = ({ hearing, caseData, refetch }) => {
	const [matches, setMatches] = useState<Record<string, boolean>>({})

	const adjournmentSchema: TableSchema<AdjournmentModel> = {
		cols: [
			{
				title: 'Requested By',
				value: (x) => x.requestedBy,
				truncate: true,
			},
			{
				title: 'Reason',
				value: (x) => x.reasonForAdjournment,
				truncate: true,
			},
			{
				title: 'Granted',
				value: (x) => (x.status === 1 ? 'Yes' : 'No'),
				truncate: true,
			},
			{
				title: 'Date',
				value: (x) => format(parseISO(x.created || ''), 'dd/MM/yyyy'),
			},
		],
	}

	const attendeeSchema: TableSchema<UserModel> = {
		cols: [
			Auth.is(
				'Admin',
				'CaseManager',
				'Support',
				'Reviewer',
				'Mediator',
				'PeerReviewer',
				'FENZAdmin',
				'Adjudicator',
				'Facilitator'
			) && {
				title: 'Name',
				value: (x) => (
					<div className="flex flex-row items-center space-x-3">
						{!x?.avatarUrl ? (
							<UserDefault name={getFullName(x)} className="w-9 h-9" />
						) : (
							<UserAvatar
								className="rounded-full w-9 h-9 object-cover shadow-sm"
								src={x?.avatarUrl}
								name={getFullName(x)}
							/>
						)}

						<div className="flex flex-col items-start">
							<button onClick={() => x && openUserSlideout({ userId: x.id })} className="anchor">
								{getFullName(x)}
							</button>
							<div className="font-normal">{x.email}</div>
						</div>
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			Auth.is('Applicant') && {
				title: 'Name',
				value: (x) => (
					<div className="flex flex-row items-center space-x-3">
						{!x?.avatarUrl ? (
							<UserDefault name={getFullName(x)} className="w-9 h-9" />
						) : (
							<UserAvatar
								className="rounded-full w-9 h-9 object-cover shadow-sm"
								src={x?.avatarUrl}
								name={getFullName(x)}
							/>
						)}

						<div className="flex flex-col items-start">{getFullName(x)}</div>
					</div>
				),
			},
			Auth.is(
				'Admin',
				'CaseManager',
				'Support',
				'Reviewer',
				'Mediator',
				'PeerReviewer',
				'FENZAdmin',
				'Adjudicator',
				'Facilitator'
			) && {
				title: 'Phone',
				value: (x) => x.phoneNumber,
				truncate: true,
				responsive: 'md',
			},
			{
				title: 'Case Role',
				value: (x) => getCaseRole(caseData, x) || '-',
				truncate: true,
				responsive: 'md',
			},
		],
	}

	const reviewSchema: TableSchema<ReviewModel> = {
		cols: matches.min
			? [
					{
						title: 'Review',
						value: (x) => (
							<BasicTruncate title={x.issueCode} className="font-normal text-black dark:text-white">
								{x.reviewNumber}
								<br />
								{x.issueCode}
							</BasicTruncate>
						),
					},
					{
						title: 'Dates',
						value: (x) => (
							<div>
								<div className="flex justify-between w-full gap-3">
									<div className="text-gray-500 dark:text-gray-300">ACC Decision</div>
									<div className="tabular-nums text-black dark:text-white">
										{x.accDecisionDate ? format(parseISO(x.accDecisionDate), 'dd/MM/yyyy') : '-'}
									</div>
								</div>
								<div className="flex justify-between w-full gap-3">
									<div className="text-gray-500 dark:text-gray-300">Lodgement</div>
									<div className="tabular-nums text-black dark:text-white">
										{format(parseISO(x.lodgementDate), 'dd/MM/yyyy')}
									</div>
								</div>
								<div className="flex justify-between w-full gap-3">
									<div className="text-gray-500 dark:text-gray-300">Set By</div>
									<div className="tabular-nums text-black dark:text-white">
										{x.setByDate ? format(parseISO(x.setByDate), 'dd/MM/yyyy') : '-'}
									</div>
								</div>
								{hearing.hearingType === HEARING_TYPE.Review && !hearing.isPostponed && (
									<div className="flex justify-between w-full gap-3">
										<div className="text-gray-500 dark:text-gray-300">Decision Due</div>
										<div className="tabular-nums text-black dark:text-white">
											{hearing.decisionDate
												? format(new Date(hearing.decisionDate), 'dd/MM/yyyy')
												: '-'}
										</div>
									</div>
								)}
							</div>
						),
					},
			  ]
			: [
					{
						title: 'Review Number',
						value: (x) => x.reviewNumber,
					},
					{
						title: 'Issue Code',
						value: (x) => x.issueCode,
						truncate: true,
						responsive: 'sm',
					},
					{
						title: 'ACC Decision Date',
						value: (x) => (x.accDecisionDate ? format(parseISO(x.accDecisionDate), 'dd/MM/yyyy') : '-'),
						responsive: 'md',
					},
					{
						title: 'Lodgement Date',
						value: (x) => format(parseISO(x.lodgementDate), 'dd/MM/yyyy'),
						responsive: 'md',
					},
					{
						title: 'Set By Date',
						value: (x) => (x.setByDate ? format(parseISO(x.setByDate), 'dd/MM/yyyy') : '-'),
						responsive: 'md',
					},
					hearing.hearingType === HEARING_TYPE.Review &&
						!hearing.isPostponed && {
							title: 'Decision Due Date',
							value: () =>
								hearing.decisionDate ? format(new Date(hearing.decisionDate), 'dd/MM/yyyy') : '-',
							responsive: 'md',
						},
			  ],
	}

	const isFENZ = caseData?.organization?.id === FENZOrgId

	return (
		<Container
			queries={{
				min: { maxWidth: 700 },
			}}
			onQueryChange={setMatches}
		>
			<Stack dividers>
				<Stack space={4} className="px-6">
					<SectionHeader
						title="Details"
						actions={[
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
								icon: <PencilIcon className="w-5 h-5" />,
								intent: 'menu',
								onClick: () => openEditHearingDetails({ hearing, refetch }),
							},
						]}
					/>

					<DataListColumns
						items={[
							!hearing.isPostponed && {
								title: 'Date',
								value: format(parseISO(hearing.startDate || '1970-01-01'), 'dd/MM/yyyy'),
							},
							!hearing.isPostponed &&
								hearing.forumType !== FORUM_TYPE.OnThePapers && {
									title: 'Time',
									value:
										format(parseISO(hearing.startDate || '1970-01-01'), 'hh:mm b') +
										' - ' +
										format(parseISO(hearing.endDate || '1970-01-01'), 'hh:mm b'),
								},
							hearing.isPostponed && {
								title: 'Date',
								value: 'Postponed',
							},
							hearing.isPostponed && {
								title: 'Original Date',
								value: format(
									parseISO(hearing.originalDate || hearing.startDate || '1970-01-01'),
									'dd/MM/yyyy'
								),
							},
							hearing.forumType !== FORUM_TYPE.OnThePapers && {
								title: 'Meeting Details',
								value: hearing.zoomMeeting
									? hearing.zoomMeeting.split('\n').map((z, i) => <div key={i}>{z}</div>)
									: '-',
							},
							{
								title: 'Forum',
								value: hearing.forumType ? ForumType.readable(hearing.forumType) : '-',
							},
							hearing.hearingVenue
								? {
										title: 'Venue',
										value: (
											<div>
												<div>{hearing.hearingVenue.name}</div>
												<div>{hearing.hearingVenue.address}</div>
												<div>{hearing.hearingVenue.phoneNumber}</div>
											</div>
										),
								  }
								: hearing.forumType === FORUM_TYPE.FaceToFace
								? {
										title: 'Venue',
										value: hearing.venue,
								  }
								: null,
							Auth.is('Admin', 'Reviewer', 'CaseManager', 'FENZAdmin', 'Adjudicator', 'Facilitator') &&
								hearing.hearingType === HEARING_TYPE.Review &&
								hearing.peerReviewDate &&
								!hearing.isPostponed && {
									title: 'Peer Review Date',
									value: format(ensureDate(hearing.peerReviewDate || '1970-01-01'), 'dd/MM/yyyy'),
								},
							{
								title: 'Notifications Enabled',
								value: hearing.notificationsEnabled ? 'Yes' : 'No',
							},
							hearing.nonAttendance && {
								title: 'Billing: Non-Attendance',
								value: 'Yes',
							},
							hearing.hearingType === HEARING_TYPE.Mediation && {
								title: 'ADR Type',
								value: ADRType.readable(hearing.adrType),
							},
							hearing.hearingType === HEARING_TYPE.Mediation &&
								!!hearing.closingCategory && {
									title: 'Closing Category',
									value: ADRClosingCategory.readable(hearing.closingCategory),
								},
							hearing.hearingType === HEARING_TYPE.Mediation &&
								!!hearing.closingCategory && {
									title: 'Closing Date',
									value: hearing.closingDate
										? format(ensureDate(hearing.closingDate), 'dd/MM/yyyy HH:mm a')
										: '-',
								},
						]}
					/>
				</Stack>

				{!Auth.is('Applicant') && hearing.hearingType === HEARING_TYPE.Review && (
					<Stack space={4} className="px-6">
						<SectionHeader
							title="Adjournments"
							actions={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									icon: <PlusIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => {
										openAdjournHearing({ hearing, refetch })
									},
								},
							]}
						/>

						<TableFrame>
							<Table
								items={hearing.adjournments.sort(
									(a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()
								)}
								schema={adjournmentSchema}
								className="-my-px"
							/>
						</TableFrame>
					</Stack>
				)}

				{!isFENZ && (
					<Stack space={4} className="px-6">
						<SectionHeader
							title="Reviews"
							actions={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									icon: <PencilIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => openEditHearingReviews({ caseData, hearing, refetch }),
								},
							]}
						/>

						<TableFrame>
							<Table
								items={hearing.reviews.filter((x) => !x.withdrawalReason)}
								schema={reviewSchema}
								className="-my-px"
							/>
						</TableFrame>
					</Stack>
				)}

				<Stack space={4} className="px-6">
					<SectionHeader
						title="Attendees"
						actions={[
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
								icon: <PencilIcon className="w-5 h-5" />,
								intent: 'menu',
								onClick: () => openEditHearingAttendees({ caseData, hearing, refetch }),
							},
						]}
					/>
					<TableFrame>
						<Table items={hearing.attendees} schema={attendeeSchema} className="-my-px" />
					</TableFrame>
				</Stack>

				{!Auth.is('Applicant') && (
					<Stack space={4} className="px-6">
						<SectionHeader
							title="Special Requirements"
							actions={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									icon: <PencilIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => openEditHearingSpecialRequirements({ hearing, refetch }),
								},
							]}
						/>
						<DataListColumns
							items={[
								{
									title: 'Interpreter required',
									value: hearing.interpreterRequired ? 'Yes' : 'No',
								},
								{ title: 'Accessibility', value: hearing.accessibility || '-' },
								{ title: 'Cultural', value: hearing.cultural || '-' },
								{ title: 'Security', value: hearing.security || '-' },
								{ title: 'Other', value: hearing.other || '-' },
							]}
						/>
					</Stack>
				)}

				{!Auth.is('Applicant') && (
					<Stack space={4} className="px-6">
						<SectionHeader
							title="Operational details"
							actions={[
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									icon: <PencilIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => openEditHearingOperationalDetails({ hearing, refetch }),
								},
							]}
						/>

						<DataListColumns
							items={[
								{
									title: 'Room booked',
									value: hearing.roomBooked === true ? 'Yes' : 'No',
								},
								{
									title: 'Travel booked',
									value: hearing.travelBooked === true ? 'Yes' : 'No',
								},
								{
									title: 'Room hire details',
									value: hearing.roomHireDetails || 'N/A',
								},
								{
									title: 'Travel arrangements',
									value: hearing.travelArrangements || 'N/A',
								},
							]}
						/>
					</Stack>
				)}
			</Stack>
		</Container>
	)
}

export default Hearing
