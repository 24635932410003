import { FC } from 'react'
import clsx, { ClassValue } from 'clsx'

interface DividerProps {
	className?: ClassValue
}

const Divider: FC<DividerProps> = ({ className }) => (
	<div className={clsx('border-t border-black border-opacity-10 dark:border-gray-600', className)} />
)

export default Divider
