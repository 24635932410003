import { FC, ReactNode } from 'react'
import { openModal, ModalContent } from 'hooks/useModal'
import useData from 'hooks/useData'
import { CasePartyRole, CASE_PARTY_ROLE } from 'types/enums'
import { CaseModel, CasePartyLog, UserModel } from 'types/models'
import { Spinner } from 'components/Icon'
import Timeline, { TimelineItem } from 'components/Timeline'
import { CheckIcon, InformationCircleIcon, PlusIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline'
import { getFullName } from 'utils/funcs'
import openUserSlideout from 'slideouts/openUserSlideout'
import Badge from 'components/Badge'

const UserButton = ({ user }: { user?: UserModel }) => (
	<button
		onClick={() => user && openUserSlideout({ userId: user.id })}
		className="text-primary-600 dark:text-primary-400 inline-block"
	>
		{getFullName(user)}
	</button>
)

const getReason = (log: CasePartyLog): { title: ReactNode; icon: ReactNode; iconBackground?: string } => {
	if (log.changeReason === 'Deleted or Role Declined') {
		return {
			title: (
				<>
					<UserButton user={log.oldUser} /> was removed, or declined role
				</>
			),
			icon: <XIcon className="w-5 h-5 text-white" />,
			iconBackground: 'bg-red-400',
		}
	}

	if (log.changeReason === 'Role Assigned') {
		return {
			title: (
				<>
					<UserButton user={log.newUser} /> was assigned
				</>
			),
			icon: <PlusIcon className="w-5 h-5 text-white" />,
			iconBackground: 'bg-blue-500',
		}
	}

	if (log.changeReason === 'Role Accepted') {
		return {
			title: (
				<>
					<UserButton user={log.oldUser} /> accepted role
				</>
			),
			icon: <CheckIcon className="w-5 h-5 text-white" />,
			iconBackground: 'bg-green-500',
		}
	}

	if (log.oldUser && log.newUser) {
		if (log.oldUser.id === log.newUser.id) {
			if (!log.changeReason) {
				return {
					title: (
						<>
							<UserButton user={log.oldUser} /> accepted role
						</>
					),
					icon: <CheckIcon className="w-5 h-5 text-white" />,
					iconBackground: 'bg-green-500',
				}
			} else {
				return {
					title: (
						<div>
							<UserButton user={log.oldUser} />
							<div>{log.changeReason}</div>
						</div>
					),
					icon: <InformationCircleIcon className="w-5 h-5 text-white" />,
					iconBackground: 'bg-blue-500',
				}
			}
		} else {
			return {
				title: (
					<div>
						<div className="flex items-center space-x-1">
							<UserButton user={log.oldUser} />
							<span>was removed and</span>
							<UserButton user={log.newUser} />
							<span>was assigned</span>
							{log.billable ? (
								<Badge color="green" icon={null} size="sm">
									Billable
								</Badge>
							) : null}
						</div>
						{log.changeReason && <div>{log.changeReason}</div>}
					</div>
				),
				icon: <InformationCircleIcon className="w-5 h-5 text-white" />,
				iconBackground: 'bg-blue-500',
			}
		}
	}

	return {
		title: (
			<>
				<UserButton user={log.oldUser} />
			</>
		),
		icon: <QuestionMarkCircleIcon className="w-5 h-5 text-white" />,
	}
}

interface OpenCasePartyHistoryProps {
	caseData: CaseModel
	partyRole: CASE_PARTY_ROLE
	field: 'reviewer' | 'peerReviewer' | 'mediator' | 'adjudicator' | 'facilitator'
}

const openCasePartyHistory = ({ caseData, partyRole, field }: OpenCasePartyHistoryProps) => {
	return openModal({
		title: `${CasePartyRole.readable(partyRole)} History`,
		size: 'lg',
		render: () => <History caseData={caseData} partyRole={partyRole} field={field} />,
	})
}

const History: FC<OpenCasePartyHistoryProps> = ({ caseData, partyRole, field }) => {
	const { data, isFetching } = useData<CasePartyLog[]>(`/Cases/${caseData.id}/history/${partyRole}`)

	let histories: TimelineItem[] =
		data?.reverse().map((x) => ({
			...getReason(x),
			date: new Date(x.created),
		})) || []

	return (
		<ModalContent>
			{isFetching && histories.length === 0 && (
				<div className="flex items-center justify-center">
					<Spinner className="w-9 h-9 animate-spin" />
				</div>
			)}

			<Timeline items={histories} />
		</ModalContent>
	)
}

export default openCasePartyHistory
