import { FC, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DataListColumns from 'components/DataListColumns'
import useData from 'hooks/useData'
import { getFullName } from 'utils/funcs'
import api from 'api'
import { confirm, toast } from 'alerts'
import { Auth } from 'Auth'
import openEditUser from 'modals/openEditUser'
import {
	MailIcon,
	TrashIcon,
	CheckIcon,
	XIcon,
	ShieldCheckIcon,
	ShieldExclamationIcon,
	PencilIcon,
	BanIcon,
} from '@heroicons/react/outline'
import Stack from 'components/Stack'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Card from 'components/Card'
import Badge from 'components/Badge'
import Address from 'components/Address'
import { UserModel } from 'types/models'
import { UserRole, USER_ROLE } from 'types/enums'
import Tabs from 'components/Tabs'
import UserAvatar from 'components/UserAvatar'
import { isNotNone } from 'types'
import UserTabCases from './UserTabs/UserTabCases'
import UserTabNotifications from './UserTabs/UserTabNotifications'
import UserTabReminders from './UserTabs/UserTabReminders'
import { navigate } from 'components/NavigateHoist'

interface ViewUserProps {
	userId?: string
}

const ViewUser: FC<ViewUserProps> = ({ userId }) => {
	const params = useParams()
	const id = userId || params.userId

	const { data: user, refetch } = useData<UserModel>(`/Users/${id}`, {}, { suspense: true })

	const sendEmail = () => {
		if (!user) return

		confirm({
			title: user.emailConfirmed
				? 'Are you sure you want to send a password reset email?'
				: 'Are you sure you want to send a verification email?',
			intent: 'primary',
			onAccept: async () => {
				try {
					if (user.emailConfirmed) {
						await api.post('/Accounts/forgotPassword', { email: user.email })
					} else {
						await api.post(`/Users/verificationemail?userId=${user.id}`, {})
					}

					toast({ title: 'Email sent' })
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	const handleDelete = () =>
		confirm({
			title: `Are you sure you want to delete ${getFullName(user)}`,
			onAccept: async () => {
				try {
					await api.delete(`/Users/${user?.id}`)

					toast({ title: 'User deleted' })

					navigate('/users')
				} catch (error) {
					api.handleError(error)
				}
			},
		})

	const isMe = Auth.profile()?.id === user?.id

	const isReviewer = user?.roles.find((x) => x.id === USER_ROLE.Reviewer)

	const [tab, setTab] = useState(Auth.is('ACCAdmin', 'Respondent') ? 'cases' : 'notifications')

	return (
		<>
			<PageHeading
				pageTitle={getFullName(user)}
				title={
					<>
						<UserAvatar
							className="rounded-full w-10 h-10 object-cover shadow-sm text-base mr-6"
							src={user?.avatarUrl}
							name={getFullName(user)}
						/>
						{getFullName(user)}
					</>
				}
				actions={[
					Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
						title: user?.emailConfirmed ? 'Reset Password' : 'Resend Verification',
						intent: 'secondary',
						rounded: 'md',
						icon: <MailIcon className="w-5 h-5" />,
						onClick: sendEmail,
					},
					Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
						title: 'Edit',
						intent: isMe ? 'primary' : 'secondary',
						rounded: 'md',
						icon: <PencilIcon className="w-5 h-5" />,
						onClick: () => user && openEditUser({ userId: user.id, refetch }),
					},
					Auth.is('Admin', 'FENZAdmin') && {
						title: 'Delete',
						intent: 'danger',
						rounded: 'md',
						icon: <TrashIcon className="w-5 h-5" />,
						onClick: handleDelete,
					},
				]}
			/>

			<PageContent>
				<Stack>
					<Card>
						<div className="flex flex-col md:flex-row space-y-8 md:space-y-0">
							<DataListColumns
								className="md:w-2/3 flex-1 md:flex-auto"
								items={[
									{ title: 'Name', value: getFullName(user) },
									{ title: 'Email address', value: user?.email },
									{ title: 'Phone number', value: user?.phoneNumber },
									Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
										title: 'User verified?',
										value: user?.emailConfirmed ? (
											<Badge color="green" icon={<CheckIcon className="w-5 h-5 mr-1.5" />}>
												Yes
											</Badge>
										) : (
											<Badge color="red" icon={<XIcon className="w-5 h-5 mr-1.5" />}>
												No
											</Badge>
										),
									},
									{
										title: 'Roles',
										value: (
											<>
												<ul className="pl-5 list-disc">
													{user?.roles.map((x) => (
														<li key={x.id}>{UserRole.readable(x.id)}</li>
													))}
												</ul>
												{user?.roles.length === 0 && <div>No roles</div>}
											</>
										),
									},
									Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
										title: 'Two Factor Verification',
										value: user?.twoFactorEnabled ? (
											<Badge color="green" icon={<ShieldCheckIcon className="w-5 h-5 mr-1.5" />}>
												Enabled
											</Badge>
										) : (
											<Badge
												color="red"
												icon={<ShieldExclamationIcon className="w-5 h-5 mr-1.5" />}
											>
												Disabled
											</Badge>
										),
									},
								]}
							/>

							<div className="md:w-1/3 flex-1 md:flex-auto">
								<Stack>
									{user?.organization && (
										<div>
											<div className="text-sm leading-5 text-gray-500 dark:text-gray-300">
												Organisation
											</div>
											<div className="mt-1 text-sm leading-5">
												{Auth.is('Admin', 'FENZAdmin') ? (
													<Link
														to={`/admin/organisations/${user.organization.id}`}
														className="anchor"
													>
														{user.organization.name}
													</Link>
												) : (
													user.organization.name
												)}
											</div>
										</div>
									)}

									{user?.physicalAddress && (
										<div>
											<div className="text-sm leading-5 text-gray-500 dark:text-gray-300">
												Address
											</div>
											<div className="mt-1 text-sm leading-5">
												<Address address={user?.physicalAddress} />
											</div>
										</div>
									)}

									{user?.disabled && (
										<div>
											<div className="text-sm leading-5 text-gray-500 dark:text-gray-300">
												Deactivated
											</div>
											<div className="mt-1 text-sm leading-5">
												<Badge color="red" icon={<BanIcon className="w-5 h-5 mr-1.5" />}>
													Yes
												</Badge>
											</div>
										</div>
									)}

									{Auth.is('Admin', 'CaseManager', 'FENZAdmin') && isNotNone(user?.note) && (
										<div>
											<div>
												<div className="text-sm leading-5 text-gray-500 dark:text-gray-300">
													Notes
												</div>
												<div className="mt-1 text-sm leading-5">{user?.note}</div>
											</div>
										</div>
									)}
								</Stack>
							</div>
						</div>
					</Card>

					<div>
						<div className="pl-2 mb-4 sm:mb-0">
							<Tabs
								className="flex-1"
								theme="card"
								activeIndex={tab}
								items={[
									!Auth.is('ACCAdmin', 'Respondent') && {
										title: 'Notifications',
										simpleTitle: 'Notifications',
										key: 'notifications',
										onClick: () => setTab('notifications'),
									},
									isReviewer && {
										title: 'Reminders',
										simpleTitle: 'Reminders',
										key: 'reminders',
										onClick: () => setTab('reminders'),
									},
									{
										title: 'Cases',
										simpleTitle: 'Cases',
										key: 'cases',
										onClick: () => setTab('cases'),
									},
								]}
							/>
						</div>

						{!Auth.is('ACCAdmin', 'Respondent') && (
							<Card className={[tab !== 'notifications' && 'hidden', 'relative z-10']}>
								{user && <UserTabNotifications user={user} />}
							</Card>
						)}

						{isReviewer && user && (
							<Card className={[tab !== 'reminders' && 'hidden', 'relative z-10']}>
								<UserTabReminders user={user} />
							</Card>
						)}

						<Card className={[tab !== 'cases' && 'hidden', 'relative z-10']}>
							{user && <UserTabCases user={user} />}
						</Card>
					</div>
				</Stack>
			</PageContent>
		</>
	)
}

export default ViewUser
