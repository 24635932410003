import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { env } from 'utils/config'
import { detectIE } from 'utils/funcs'
import { BrowserRouter } from 'react-router-dom'
import NavigateHoist from 'components/NavigateHoist'
import { ToastProvider } from 'components/toast'

console.log('build #' + process.env.REACT_APP_COMMIT)

if (env === 'production') {
	Sentry.init({
		dsn: 'https://bc44cf2798f34fbbbfdb7bd801b85c90@sentry.io/1492247',
		release: process.env.REACT_APP_COMMIT,
		integrations: [new Integrations.BrowserTracing()],
		ignoreErrors: [
			'Retrier attempt is already in progress',
			'Non-Error exception captured with keys: current, from, message, to, transition',
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications.',
		],
	})
	Sentry.setExtra('commit', process.env.REACT_APP_COMMIT)
}

if (detectIE()) {
	alert('This website does not support Internet Explorer.\nPlease use Edge or any other browser.')
} else {
	ReactDOM.render(
		<ToastProvider>
			<Suspense fallback={null}>
				<BrowserRouter>
					<NavigateHoist />
					<App />
				</BrowserRouter>
			</Suspense>
		</ToastProvider>,
		document.getElementById('root')
	)
}
