import { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageHeading from 'components/PageHeading'
import Stack from 'components/Stack'
import { format, parseISO, startOfDay } from 'date-fns'
import openEmailTemplate from 'modals/openEmailTemplate'
import { EyeIcon } from '@heroicons/react/outline'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { TableSchema } from 'components/Table'
import PageNavbar from 'components/PageNavbar'
import { links } from 'pages/Admin'
import { gql } from '@apollo/client'
import { GQLConnection, GQLEmailLogType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import Search from 'search/NotificationsSearch'
import endOfDay from 'date-fns/esm/fp/endOfDay/index.js'
import SectionHeader from 'components/SectionHeader'
import Container from 'components/Container'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: EmailLogOrderBy!, $search: EmailLogSearch) {
		EmailLogs(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				created
				recipients
				template
				status
				partyRole {
					id
					readable
				}
				case {
					id
					caseNumber
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

const NotificationLog: FC = () => {
	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const searchDate = state?.search?.date
		? {
				startDate: startOfDay(state?.search?.date),
				endDate: endOfDay(state?.search?.date),
		  }
		: {}

	const { data, loading, refetch } = useBufferedQuery<{ EmailLogs: GQLConnection<GQLEmailLogType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: {
				role: state?.search?.role,
				caseNumber: state?.search?.caseNumber,
				recipient: state?.search?.recipient,
				...searchDate,
			},
		},
	})

	const [matches, setMatches] = useState<Record<string, boolean>>({})

	const schema: TableSchema<GQLEmailLogType> = {
		cols: [
			{
				title: 'Recipients',
				value: (x) => (
					<div className="flex flex-col space-y-1">
						{x.recipients?.map((y) => (
							<div key={y}>{y}</div>
						))}
					</div>
				),
				truncate: true,
				className: 'flex items-start',
			},
			!matches.hideTemplate && {
				title: 'Template',
				value: (x) => x.template,
				truncate: true,
			},
			{
				title: 'Status',
				value: (x) => x.status,
				truncate: true,
			},
			{
				title: 'Send Date',
				value: (x) => (
					<div className="flex flex-col space-y-1">
						<div>{format(parseISO(x.created || ''), 'dd/MM/yyyy')}</div>
						<div>{format(parseISO(x.created || ''), 'HH:mm:ss')}</div>
					</div>
				),
				truncate: true,
			},
			!matches.hideCase && {
				title: 'Case',
				value: (x) => (
					<div className="flex flex-col space-y-1">
						<Link to={`/cases/${x.case?.id}`} className="anchor">
							{x.case?.caseNumber}
						</Link>
						<div>{x.partyRole?.map((y) => y.readable).join(', ')}</div>
					</div>
				),
			},
		],
		actions: (x) => [
			{
				icon: <EyeIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => openEmailTemplate({ logId: x.id }),
			},
		],
	}

	return (
		<>
			<PageHeading title="Admin" pageTitle="Admin: Notifications" />

			<PageNavbar links={links}>
				<Container
					className="max-w-full"
					queries={{
						hideCaseParties: { maxWidth: 800 },
						hideCase: { maxWidth: 700 },
						hideTemplate: { maxWidth: 600 },
					}}
					onQueryChange={setMatches}
				>
					<Stack className="px-6">
						<SectionHeader title="Notification Log" />
						<GQLPaginatedTable
							schema={schema}
							set={data?.EmailLogs}
							isLoading={loading}
							refetch={refetch}
							search={(props) => <Search {...props} />}
						/>
					</Stack>
				</Container>
			</PageNavbar>
		</>
	)
}

export default NotificationLog
