import { FC } from 'react'
import { endOfDay, format, parseISO, startOfDay } from 'date-fns'
import useData from 'hooks/useData'
import Stack from 'components/Stack'
import { EyeIcon } from '@heroicons/react/outline'
import CaseHeader from 'components/CaseHeader'
import { useParams, useLocation } from 'react-router-dom'
import openEmailTemplate from 'modals/openEmailTemplate'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import { TableSchema } from 'components/Table'
import { gql } from '@apollo/client'
import { GQLConnection, GQLEmailLogType } from 'types/gql'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { CaseModel } from 'types/models'
import Search from 'search/NotificationsSearch'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: EmailLogOrderBy!, $search: EmailLogSearch) {
		EmailLogs(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				created
				recipients
				template
				status
				partyRole {
					id
					readable
				}
				case {
					id
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

// interface NotificationsProps {
// 	caseId?: string
// }

const Notifications: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const links = caseData ? caseLinks(caseData) : []

	const location = useLocation()

	const state = location?.state as { search?: any; page?: number; pageSize?: number } | null

	const searchDate = state?.search?.date
		? {
				startDate: startOfDay(state?.search?.date),
				endDate: endOfDay(state?.search?.date),
		  }
		: {}

	const {
		data,
		loading,
		refetch: refetchEmailLogs,
	} = useBufferedQuery<{ EmailLogs: GQLConnection<GQLEmailLogType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.page || 1,
			pageSize: state?.pageSize || 10,
			search: {
				role: state?.search?.role,
				caseNumber: caseData?.caseNumber,
				recipient: state?.search?.recipient,
				...searchDate,
			},
		},
	})

	const schema: TableSchema<GQLEmailLogType> = {
		cols: [
			{
				title: 'Recipients',
				value: (x) => (
					<div className="flex flex-col space-y-1">
						{x.recipients?.map((y) => (
							<div key={y}>{y}</div>
						))}
					</div>
				),
				truncate: true,
				className: 'flex items-start',
			},
			{
				title: 'Template',
				value: (x) => x.template,
				truncate: true,
			},
			{
				title: 'Status',
				value: (x) => x.status,
				truncate: true,
			},
			{
				title: 'Send Date',
				value: (x) => (
					<div className="flex flex-col space-y-1">
						<div>{format(parseISO(x.created || ''), 'dd/MM/yyyy')}</div>
						<div>{format(parseISO(x.created || ''), 'HH:mm:ss')}</div>
					</div>
				),
				truncate: true,
			},
			{
				title: 'Parties',
				value: (x) => x.partyRole?.map((y) => y.readable).join(', '),
				truncate: true,
			},
		],
		actions: (x) => [
			{
				icon: <EyeIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => openEmailTemplate({ logId: x.id }),
			},
		],
	}

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack className="px-6">
					<SectionHeader title="Notifications" />

					<GQLPaginatedTable
						schema={schema}
						set={data?.EmailLogs}
						isLoading={loading}
						refetch={refetchEmailLogs}
						search={(props) => (
							<Search
								{...props}
								presetValues={{ caseNumber: { value: caseData?.caseNumber || '', locked: true } }}
							/>
						)}
					/>
				</Stack>
			</PageNavbar>
		</>
	)
}

export default Notifications
