import { FC, useState } from 'react'
import { format } from 'date-fns'
import { CASE_PARTY_ROLE, CASE_STATUS, TimeSlot, TimeSlots, USER_ROLE } from 'types/enums'
import openSlideout, { SlideoutHeader, SlideoutContent } from 'hooks/openSlideout'
import clsx from 'clsx'
import Slider from 'components/Slider'
import { ArrowCircleRightIcon, ArrowLeftIcon, FilterIcon } from '@heroicons/react/outline'
import { gql } from '@apollo/client'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { GQLCaseType, GQLConnection, GQLUserType } from 'types/gql'
import UserDefault from 'components/UserDefault'
import { getDateFromTime, getFullName } from 'utils/funcs'
import { Auth } from 'Auth'
import openFindCase from 'modals/openFindCase'
import api from 'api'
import { CaseModel, UserModel } from 'types/models'
import { confirm, toast, warning } from 'alerts'
import openConfirmUser from 'modals/openConfirmUser'
import Actions from 'components/Actions'

const query = gql`
	query ($search: UserSearch) {
		Users(search: $search, orderBy: firstName_ASC) {
			items {
				id
				fullName
				email
				avatar
			}
		}
	}
`

const openViewAvailabilitySlideout = (props: OpenViewAvailabilitySlideoutProps) => {
	return openSlideout({
		size: 'xl',
		render: () => <ViewAvailabilitySlideout {...props} />,
	})
}

interface OpenViewAvailabilitySlideoutProps {
	date: Date
}

const ViewAvailabilitySlideout: FC<OpenViewAvailabilitySlideoutProps> = ({ date }) => {
	const [slot, setSlot] = useState<TimeSlot | null>(null)
	const [stage, setStage] = useState(0)

	const { data } = useBufferedQuery<{ Users: GQLConnection<GQLUserType> }>(query, {
		variables: {
			search: {
				role: [USER_ROLE.Reviewer],
				available_from: getDateFromTime(slot?.from24 || '00:00', date).toISOString(),
				available_to: getDateFromTime(slot?.to24 || '00:00', date).toISOString(),
				disabled: false,
				excludeIds: ['13f19d31-730f-40b4-8bab-677dbd1633d9'],
			},
		},
		skip: !slot,
	})

	const attachReviewer = async (user: GQLUserType, gqlCaseData: GQLCaseType | null) => {
		if (!gqlCaseData) return false // this keeps the find case modal open

		const { data: caseData } = await api.get<CaseModel>(`/Cases/${gqlCaseData.id}`)
		const { data: userData } = await api.get<UserModel>(`/Users/${user.id}`)

		if (caseData.reviewer && caseData.reviewer.user?.id === userData.id) {
			warning({
				title: `${getFullName(userData)} is already the reviewer for case ${caseData.caseNumber}`,
			})
			return false
		}

		if (caseData.reviewer) {
			let confirmed = await confirm({
				title: 'Case already has a reviewer',
				message: `Are you sure you want to replace ${getFullName(caseData.reviewer.user)}?`,
			})

			if (confirmed) confirmed = await openConfirmUser({ user: userData })

			if (!confirmed) {
				return false // this keeps the find case modal open
			}

			try {
				await api.put(`/Cases/${caseData?.id}/party/${caseData.reviewer.id}`, {
					id: caseData.reviewer.id,
					role: CASE_PARTY_ROLE.Reviewer,
					user: userData,
				})

				toast({ title: 'Reviewer updated' })
			} catch (error) {
				api.handleError(error)
				toast({
					title: 'Reviewer not updated',
					message: 'Something went wrong',
					intent: 'error',
				})
				return false
			}
		} else {
			let confirmed = await openConfirmUser({ user: userData })

			if (!confirmed) {
				return false // this keeps the find case modal open
			}

			try {
				await api.post(`/Cases/${caseData?.id}/party`, {
					userId: userData?.id,
					role: CASE_PARTY_ROLE.Reviewer,
				})

				toast({ title: 'Reviewer added' })
			} catch (error) {
				api.handleError(error)
				toast({
					title: 'Reviewer not added',
					message: 'Something went wrong',
					intent: 'error',
				})
				return false
			}
		}

		return true
	}

	const [filter, setFilter] = useState('')

	const filterItems = (x: GQLUserType) => {
		if (filter.length === 0) return true
		if (!x.fullName || !x.email) return false
		if (x.fullName.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true
		if (x.email.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true
		return false
	}

	return (
		<>
			<SlideoutHeader
				// title={
				// 	<div className="space-y-2 w-full">
				// 		<div className="text-sm font-medium opacity-50">View Availability:</div>
				// 		<div className="text-2xl font-bold">{format(date, "EEE do 'of' MMM")}</div>
				// 	</div>
				// }
				title="View Availability"
				subtitle={format(date, "EEE do 'of' MMMM")}
				actions={[]}
			/>
			<Slider index={stage}>
				<SlideoutContent>
					<div className="flex-1 flex flex-col px-4 py-8">
						<div className="flex-1 flex flex-col min-h-0 overflow-y-scroll">
							{TimeSlots.map((slot, i) => (
								<div
									key={i}
									className={clsx(
										i % 2 &&
											i < TimeSlots.length - 1 &&
											'mb-3 pb-3 border-b border-gray-200 dark:border-gray-600'
									)}
								>
									<button
										className="py-1 pl-1 text-sm font-medium flex items-center justify-between tabular-nums hover:text-primary-600 dark:hover:text-primary-400"
										onClick={() => {
											setSlot(slot)
											setStage(1)
										}}
									>
										<div className="flex items-center group">
											<div>
												{slot.from} - {slot.to}
											</div>
											<ArrowCircleRightIcon className="w-5 h-5 opacity-0 group-hover:translate-x-3 group-hover:opacity-100 transition-all duration-100 ease-out" />
										</div>
									</button>
								</div>
							))}
						</div>
					</div>
				</SlideoutContent>

				<SlideoutContent>
					<div className="flex items-center space-x-4 p-4 bg-gray-50 dark:bg-gray-750">
						<Actions
							actions={[
								{
									icon: <ArrowLeftIcon className="w-5 h-5 -mx-2" />,
									onClick: () => setStage(0),
									intent: 'invisible',
								},
							]}
						/>
						<div className="flex flex-col">
							<div className="text-lg font-mediumtext-gray-900 dark:text-gray-100">
								Available Reviewers
							</div>
							<div className="mt-1 text-sm text-gray-500 dark:text-gray-400">
								{slot?.from} - {slot?.to}
							</div>
						</div>
					</div>

					<div className="relative">
						<FilterIcon className="absolute top-0 left-0 ml-4 mt-5 w-5 h-5" />
						<input
							type="text"
							className="p-4 pl-12 bg-white dark:bg-gray-700 w-full border-b border-gray-200 dark:border-gray-600"
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
							placeholder="filter..."
						/>
					</div>

					<SlideoutContent>
						<div className="divide-y divide-gray-200 dark:divide-gray-600">
							{data?.Users?.items?.filter(filterItems).map((x) => (
								<div
									key={x.id}
									className={clsx(
										'flex items-center space-x-3 p-4 hover:bg-primary-100 dark:hover:bg-gray-600',
										Auth.is('Admin', 'FENZAdmin') && 'cursor-pointer'
									)}
									onClick={() => {
										if (Auth.is('Admin', 'FENZAdmin')) {
											openFindCase({
												title: 'Attach reviewer to case',
												filter: {
													status: [CASE_STATUS.Pending, CASE_STATUS.Open],
												},
												onAccept: async (gqlCaseData) => {
													return await attachReviewer(x, gqlCaseData)
												},
											})
										}
									}}
								>
									{x.avatar ? (
										<img
											src={`data:text/jpeg;base64,${x.avatar}`}
											className="overflow-hidden rounded-full w-9 h-9 object-cover shadow-sm"
											alt={x.fullName}
										/>
									) : (
										<UserDefault name={x.fullName} className="w-9 h-9" />
									)}
									<div className="flex flex-col">
										<div className="text-sm font-medium">{x.fullName}</div>
										<div className="text-sm text-gray-500 dark:text-gray-400">{x.email}</div>
									</div>
								</div>
							))}

							{(data?.Users?.items?.length || 0) > 0 &&
								(!data?.Users?.items?.filter(filterItems).length || 0) > 0 && (
									<div className="p-4">No reviewers found matching this filter</div>
								)}
						</div>
					</SlideoutContent>
				</SlideoutContent>
			</Slider>
		</>
	)
}

export default openViewAvailabilitySlideout
