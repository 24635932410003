import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { fixedFormatRelative, getFullName } from 'utils/funcs'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import { TrashIcon, DotsHorizontalIcon, PencilIcon } from '@heroicons/react/outline'
import { format, parseISO } from 'date-fns'
import { Auth } from 'Auth'
import api from 'api'
import { confirm } from 'alerts'
import { CaseModel, CaseNoteModel } from 'types/models'
import { PaginatedSet, RefetchFn } from 'types'
import UserAvatar from 'components/UserAvatar'
import { Spinner } from './Icon'
import openEditNote from 'modals/openEditNote'
import { useLocation } from 'react-router-dom'
import Pagination2 from './Pagination2'
import useBufferedData from 'hooks/useBufferedData'
import { navigate } from './NavigateHoist'

interface CaseNotesProps {
	caseData: CaseModel
}

interface FormData {
	content: string
}

const CaseNotes: FC<CaseNotesProps> = ({ caseData }) => {
	const location = useLocation()
	const locationState = (location?.state as { [x: string]: any } | null) || {}

	const { data, isFetching, refetch } = useBufferedData<PaginatedSet<CaseNoteModel>>('/CaseNotes', {
		caseId: caseData.id,
		pageNumber: locationState.page || 1,
		pageSize: locationState.pageSize || 10,
		orderBy: 'created',
		orderDirection: 2,
		isPublic: Auth.is('Admin', 'CaseManager', 'FENZAdmin'),
	})

	const formContext = useForm({
		defaultValues: {
			content: '',
		},
	})

	const [isSaving, setSaving] = useState(false)

	const handleFormSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.post('/CaseNotes', {
				text: formData.content,
				isPublic: Auth.is('Admin', 'CaseManager', 'FENZAdmin'),
				case: {
					id: caseData.id,
				},
			})

			refetch()
			formContext.reset({ content: '' })
		} catch (error) {
			api.handleError(error)
		}

		setSaving(false)
	}

	return (
		<Stack>
			<div className="p-4 rounded-md border border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-750">
				<Form context={formContext} onSubmit={handleFormSubmit}>
					<Stack>
						<FormInput
							name="content"
							placeholder="New note..."
							as="textarea"
							validations={{ required: 'You cannot create an empty note' }}
						/>

						<div className="flex items-center justify-end">
							<Actions
								actions={[
									{
										title: 'Save',
										type: 'submit',
										intent: 'save',
										isLoading: isSaving,
									},
								]}
							/>
						</div>
					</Stack>
				</Form>
			</div>

			{(data?.items?.length || 0) > 0 && (
				<Pagination2
					items={data?.items || []}
					itemCount={data?.items.length || 0}
					totalCount={data?.totalCount || 0}
					totalPages={data?.totalPages || 0}
					isLoading={isFetching}
					page={data?.pageNumber || 1}
					pageSize={data?.pageSize || 10}
					setPage={async (x) => {
						navigate(location.pathname, { replace: true, state: { ...locationState, page: x } })
					}}
					setPageSize={async (x) => {
						navigate(location.pathname, {
							replace: true,
							state: { ...locationState, pageSize: x, page: 1 },
						})
					}}
				>
					<Stack space={2}>
						{data?.items?.map((x) => (
							<NoteBlock key={x.id} item={x} refetch={refetch} />
						))}
						{!data?.items?.length && isFetching && (
							<div className="flex items-center justify-center">
								<Spinner className="w-10 h-10 animate-spin" />
							</div>
						)}
					</Stack>
				</Pagination2>
			)}
		</Stack>
	)
}

interface NoteBlockProps {
	item: CaseNoteModel
	refetch: RefetchFn
}

const NoteBlock: FC<NoteBlockProps> = ({ item, refetch }) => {
	const handleDelete = async () => {
		await confirm({
			title: 'Are you sure you want to delete this note?',
			onAccept: async () => {
				try {
					await api.delete(`/CaseNotes/${item.id}`)

					refetch()
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	return (
		<div className="py-4 relative flex flex-row">
			<div className="flex items-start mr-4">
				<UserAvatar
					src={item.createdBy.avatarUrl}
					name={getFullName(item.createdBy)}
					className="rounded-full w-9 h-9"
				/>
			</div>

			<div className="flex flex-col flex-1">
				<div className="text-sm font-medium leading-5 text-gray-900 dark:text-white">
					{getFullName(item.createdBy)}
				</div>

				<div className="text-sm leading-5 text-gray-400">
					Commented:{' '}
					<span
						className="border-b border-dotted border-gray-300"
						title={format(parseISO(item.created), 'dd/MM/yyyy HH:mm:ss')}
					>
						{fixedFormatRelative(item.created)}
					</span>
				</div>

				<div className="break-words pt-1 text-sm">{item.text}</div>
			</div>

			<div className="absolute top-0 right-0 mr-4 mt-4 flex items-center">
				{!item.isPublic && (
					<div className="px-1.5 py-0.5 mr-4 text-xs font-medium rounded bg-primary-100 text-primary-600 dark:text-primary-300 dark:bg-primary-500 dark:bg-opacity-25">
						Private
					</div>
				)}

				<Actions
					actions={{
						intent: 'menu',
						icon: <DotsHorizontalIcon className="w-5 h-5" />,
						actions: [
							Auth.is('Admin', 'FENZAdmin') && [
								{
									title: 'Edit Note',
									icon: <PencilIcon className="w-5 h-5" />,
									onClick: () => openEditNote({ note: item, refetch }),
								},
							],
							[
								{
									title: 'Delete Note',
									icon: <TrashIcon className="w-5 h-5" />,
									intent: 'danger',
									onClick: handleDelete,
								},
							],
						],
					}}
				/>
			</div>
		</div>
	)
}

export default CaseNotes
