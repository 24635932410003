import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { CardWrapper } from 'components/Card'
import { isNotNone } from 'types'
import { themes } from 'components/Badge'
import clsx from 'clsx'
import { Spinner } from './Icon'
import { ChevronRightIcon } from '@heroicons/react/outline'

interface DashCardProps {
	title: ReactNode
	value: ReactNode
	to?: string
	isLoading?: boolean
	state?: any
	disabled?: boolean
}

const DashCard: FC<DashCardProps> = ({ title, value, to, isLoading, state, disabled = false }) => {
	return (
		<CardWrapper
			className={clsx(
				'overflow-hidden flex flex-col justify-between space-y-2 py-5 px-4',
				disabled && 'opacity-50'
			)}
		>
			<div className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</div>

			<div className="flex justify-between items-end">
				<div className="text-3xl font-semibold text-gray-900 dark:text-white">
					{isLoading ? <Spinner className="w-9 h-9 animate-spin" /> : value}
				</div>
				{isNotNone(to) && (
					<div className="text-sm">
						<Link
							to={to}
							state={state}
							className={clsx(
								'block py-1.5 px-2 font-medium rounded-full group',
								themes.brand,
								'hover:bg-opacity-40',
								disabled && 'pointer-events-none'
							)}
						>
							<div className="flex items-center justify-start">
								<div className="flex justify-center w-0 group-hover:w-12 transition-all duration-100 overflow-hidden mr-0">
									View
								</div>
								<ChevronRightIcon className="w-4 h-4" />
							</div>
						</Link>
					</div>
				)}
			</div>
		</CardWrapper>
	)
}

export default DashCard
