import { FC, useState } from 'react'
import Actions from 'components/Actions'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import api from 'api'
import { format, startOfWeek, endOfWeek, addHours, addDays, subDays, getYear, parseISO } from 'date-fns'
import EventAvailabilityTable, { SubSlot } from 'components/EventAvailabilityTable'
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { CaseModel, HearingModel } from 'types/models'
import { ModalResolveFn } from 'types'
import { AxiosResponse } from 'axios'
import { toast } from 'components/toast'

const shortDateRange = (from: Date, to: Date) => {
	let y = getYear(from) !== getYear(to)

	let pattern = `d MMM${y ? ' yyyy' : ''}`

	return `${format(from, pattern)} - ${format(to, pattern)}`
}

interface OpenBookingProps {
	caseData: CaseModel
}

const useBooking = ({ caseData }: OpenBookingProps) => {
	return openModal<HearingModel>({
		title: 'Book Case Management Conference (CMC)',
		size: 'xl',
		render: (close) => <BookingForm close={close} caseData={caseData} />,
	})
}

interface BookingFormProps {
	close: ModalResolveFn<HearingModel>
	caseData: CaseModel
}

const BookingForm: FC<BookingFormProps> = ({ close, caseData }) => {
	const [date, setDate] = useState(startOfWeek(new Date()))

	const [selectedSlot, setSelectedSlot] = useState<SubSlot | undefined>(undefined)

	const { isSaving, setSaving } = useModalState()

	const createHearing = async () => {
		if (!caseData || !selectedSlot) return null

		setSaving(true)

		try {
			const newHearing: AxiosResponse<HearingModel> = await api.post('/CaseHearings', {
				case: {
					id: caseData.id,
				},
				startDate: selectedSlot.date,
				endDate: addHours(parseISO(selectedSlot.date), 1),
				hearingType: 1,
				name: 'CMC',
				notificationsEnabled: true,
				isPostponed: false,
			})

			toast({
				title: 'Case Request Created!',
				message: `First CMC conference booked for ${format(parseISO(selectedSlot.date), 'hh:mm a')} on ${format(
					parseISO(selectedSlot.date),
					'dd/MM/yyyy'
				)}`,
			})

			close(newHearing.data)

			setSelectedSlot(undefined)
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const forward = () => setDate((c) => addDays(c, 7))
	const back = () => setDate((c) => subDays(c, 7))

	return (
		<>
			<ModalContent>
				<div className="flex flex-col items-stretch flex-1">
					<div className="flex justify-end items-center mb-6">
						<div className="mr-4">{shortDateRange(date, endOfWeek(date))}</div>

						<button
							onClick={back}
							className="relative inline-flex items-center px-2 py-2 rounded-l-md rounded-r-none border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 dark:border-gray-600 dark:text-gray-200 dark:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700"
						>
							<ChevronLeftIcon className="w-5 h-5" />
						</button>

						<button
							onClick={forward}
							className="-ml-px relative inline-flex items-center px-2 py-2 rounded-l-none rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 dark:border-gray-600 dark:text-gray-200 dark:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700"
						>
							<ChevronRightIcon className="w-5 h-5" />
						</button>
					</div>

					<EventAvailabilityTable
						onSelect={(slot) => setSelectedSlot((s) => (s === slot ? undefined : slot))}
						selected={selectedSlot}
						context={date}
					/>
				</div>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Skip for now',
								intent: 'secondary',
								onClick: close,
								disabled: isSaving,
							},
							{
								title: 'Book',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: createHearing,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default useBooking
