import { FC } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import logo from 'assets/img/newlogo/icra-acronym-white.png'
import { ModalResolveFn } from 'types'

interface OpenInvitationProps {
	onSubmit?: (accepted: boolean) => Promise<any> | void
}

const openInvitation = (props: OpenInvitationProps) => {
	return openModal<boolean>({
		render: (close) => <Invitation close={close} {...props} />,
	})
}

const Invitation: FC<OpenInvitationProps & { close: ModalResolveFn<boolean> }> = ({ close, onSubmit }) => {
	const { isSaving, setSaving } = useModalState()

	const handleAccept = async (accepted: boolean) => {
		if (onSubmit) {
			setSaving(true)
			await onSubmit(accepted)
			close(accepted)
		} else {
			close(accepted)
		}
	}

	return (
		<>
			<ModalContent>
				<Stack>
					<div className="flex justify-center">
						<img src={logo} className="h-20" alt="ICRA" />
					</div>
					<p>I accept the appointment and confirm that:</p>
					<p>
						To the best of my knowledge there are no circumstances past or present likely to give rise to
						justifiable doubts as to my impartiality or independence in the eyes of any of the parties; and
					</p>
					<p>
						I will assume a continuing duty to disclose forthwith to the parties any circumstances arising
						in the future likely to give rise to justifiable doubts as to my impartiality or independence in
						the eyes of any of the parties until the review is concluded.
					</p>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'I Decline the appointment',
								intent: 'secondary',
								onClick: () => handleAccept(false),
								disabled: isSaving,
							},
							{
								title: 'I Accept the appointment',
								intent: 'save',
								onClick: () => handleAccept(true),
								disabled: isSaving,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openInvitation
