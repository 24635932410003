import { FC } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import { rawApi } from 'api'
import { toast } from 'components/toast'
import { ModalResolveFn } from 'types'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'

const openForgottenPassword = () => {
	return openModal({
		title: 'Forgotten Password',
		render: (close) => <ForgottenPassword close={close} />,
	})
}

interface ForgottenPasswordProps {
	close: ModalResolveFn
}

interface FormData {
	email: string
}

const ForgottenPassword: FC<ForgottenPasswordProps> = ({ close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			email: '',
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await rawApi.post('/Accounts/forgotPassword', { email: formData.email })

			toast({
				title: 'Submitted',
				message: 'Please check your email inbox to continue',
			})

			close()
		} catch (error) {
			rawApi.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<>
				<ModalContent>
					<p className="mb-6">Please enter your email address and we will send a password reset email.</p>
					<FormInput
						name="email"
						label="Email Address"
						validations={{ required: 'Email Address is required' }}
					/>
				</ModalContent>

				<ModalFooter>
					<div className="flex justify-end">
						<Actions
							actions={[
								{
									title: 'Submit',
									intent: 'save',
									icon: <CheckIcon className="w-5 h-5" />,
									type: 'submit',
									isLoading: isSaving,
								},
							]}
						/>
					</div>
				</ModalFooter>
			</>
		</Form>
	)
}

export default openForgottenPassword
