import { FC, Component, ElementType, Suspense } from 'react'
import DefaultLoader from 'components/Loader'
import * as Sentry from '@sentry/react'
import { isNotNone } from 'types'

interface ErrorBoundaryProps {
	render: ElementType
}

interface ErrorBoundaryState {
	error?: Error
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	static getDerivedStateFromError(error: Error) {
		return { error }
	}

	componentDidCatch(error: Error) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info)
		Sentry.captureException(error)
		console.dir(error)
	}

	render() {
		if (isNotNone(this.state?.error)) {
			return <this.props.render error={this.state.error} />
		}

		return this.props.children
	}
}

interface DefaultErrorProps {
	error: Error
}

export const defaultError: FC<DefaultErrorProps> = ({ error }) => (
	<div className="alert alert-danger" role="alert">
		<h4 className="alert-heading">Something went wrong</h4>
		<hr />
		{error.message && <p className="m-0">{error.message}</p>}
	</div>
)

const withSuspense =
	(Component: ElementType, { loader = <DefaultLoader />, error = defaultError } = {}) =>
	(props: any) => {
		return (
			<ErrorBoundary render={error}>
				<Suspense fallback={loader}>
					<Component {...props} />
				</Suspense>
			</ErrorBoundary>
		)
	}

const Suspender = withSuspense(({ render }) => render)

export default Suspender
