import { ChangeEvent, FC, useReducer } from 'react'
import { openModal, ModalContent } from 'hooks/useModal'
import Checkbox from 'components/Checkbox'
import Stack from 'components/Stack'
import produce from 'immer'
import storage from 'utils/storage'

const openChatSettings = () => {
	return openModal({
		title: 'Chat Settings',
		render: (close) => <ChatSettings close={close} />,
	})
}

interface ChatSettingsProps {
	close: (answer?: boolean | undefined) => void
}

interface SettingsType {
	settings: {
		notifications: boolean
	}
}

const ChatSettings: FC<ChatSettingsProps> = ({ close }) => {
	const forceUpdate = useReducer((c) => c + 1, 0)[1]

	const toggleNotifications = (e: ChangeEvent<HTMLInputElement>) => {
		const doAction = async () => {
			try {
				const checked = e.target.checked

				if (checked && Notification.permission !== 'granted') {
					await Notification.requestPermission()
				}

				storage.set(
					'chat',
					produce(storage.getObject<SettingsType>('chat'), (draft) => {
						if (draft) draft.settings.notifications = checked
					})
				)

				forceUpdate()
			} catch (e) {}
		}

		doAction()
	}

	return (
		<ModalContent>
			<Stack>
				{Notification.permission === 'denied' && <div>You have chosen to deny browser notifications.</div>}

				<Checkbox
					checked={storage.getObject<SettingsType>('chat')?.settings.notifications || false}
					onChange={toggleNotifications}
					label="Enable browser notifications (browser)"
					disabled={Notification.permission === 'denied'}
				/>
			</Stack>
		</ModalContent>
	)
}

export default openChatSettings
