import { useState, useMemo, FC } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import FileDropper from 'components/FileDropper'
import Actions from 'components/Actions'
import Table, { TableSchema } from 'components/Table'
import Stack from 'components/Stack'
import { XIcon, CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { humanFileSize, isAcceptableFile } from 'utils/funcs'
import { Auth } from 'Auth'
import { toast, warning } from 'alerts'
import { CaseFileModel, CaseModel } from 'types/models'
import { RefetchFn } from 'types'
import { CasePartyRole, CASE_PARTY_ROLE } from 'types/enums'

interface OpenCreateSubmissionProps {
	caseData: CaseModel
	refetch?: RefetchFn
}

const openFileUploadApplicant = ({ caseData, refetch }: OpenCreateSubmissionProps) => {
	return openModal({
		title: 'Upload Files',
		size: 'lg',
		render: (close) => <FileUploadApplicant close={close} caseData={caseData} refetch={refetch} />,
	})
}

interface UploadFile {
	file: File
	permissions: boolean[]
}

const FileUploadApplicant: FC<OpenCreateSubmissionProps & { close: () => void }> = ({ close, caseData, refetch }) => {
	const { isSaving, setSaving } = useModalState()
	const [files, setFiles] = useState<UploadFile[]>([])

	const roles = useMemo(() => CasePartyRole.options.filter((x) => x.label !== 'Case Manager'), [])

	const handleSubmit = async (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()

		if (!files.length) {
			return
		}

		setSaving(true)

		const { data } = await api.get<CaseFileModel[]>(`/Casefiles/hierarchy?caseId=${caseData.id}`)

		// const findFolder = (name: string, folder: CaseFileModel): CaseFileModel | undefined => {
		// 	if (folder.name === name) return folder
		// 	if (folder.hasDescendants) {
		// 		for (let i = 0; i < folder.children.length; i++) {
		// 			let result = findFolder(name, folder.children[i])
		// 			if (result) return result
		// 		}
		// 	}
		// }

		const targetFolder = data.find((x) => x.name === 'APPLICANT SUBMISSIONS/EVIDENCE')

		if (!targetFolder) return

		if (!targetFolder) {
			warning({ title: 'Applicant submissions folder not found' })
			return
		}

		const body = new FormData()

		body.append('case.id', caseData.id)
		body.append('parentId', targetFolder.id)

		let hadUnacceptable = 0

		for (let i = 0; i < files.length; i++) {
			const item = files[i]

			if (!isAcceptableFile(item.file)) {
				hadUnacceptable++
				continue
			}

			body.append('files', item.file)

			Auth.is('Applicant') && body.append(`permissions[${i}]`, String(CASE_PARTY_ROLE.Applicant))
			body.append(`permissions[${i}]`, String(CASE_PARTY_ROLE.CaseManager))
		}

		try {
			if (hadUnacceptable) {
				warning({
					title: 'Invalid Files',
					message: 'At least one file with an invalid type was skipped',
				})
				if (files.length === hadUnacceptable) {
					setSaving(false)
					return
				}
			}

			await api.post('/CaseFiles', body)

			if (refetch) await refetch()

			toast({ title: 'File Uploaded' })

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	const schema: TableSchema<UploadFile> = {
		cols: [
			{
				title: 'Name',
				value: (x) => <div className="min-w-0 w-full truncate">{x.file.name}</div>,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.file.size),
				width: 'minmax(auto, max-content)',
			},
		],
		actions: (x) => [
			{
				icon: <XIcon className="w-5 h-5" />,
				intent: 'menu',
				onClick: () => setFiles((f) => f.filter((y) => y !== x)),
			},
		],
	}

	return (
		<>
			<ModalContent>
				<Stack>
					<FileDropper
						onChange={(newfiles) =>
							setFiles((f) => [
								...f,
								...newfiles
									.filter((x) => !f.find((y) => y.file.name === x.name))
									.map((x) => ({ file: x, permissions: roles.map((_) => true) })),
							])
						}
					/>
					{files.length > 0 && <Table items={files} schema={schema} />}
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Upload',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								onClick: handleSubmit,
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</>
	)
}

export default openFileUploadApplicant
