import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchFormProps } from 'components/PaginatedTable'
import { SearchIcon } from '@heroicons/react/outline'
import { Undo } from 'components/Icon'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import { useForm } from 'react-hook-form'
import Actions from 'components/Actions'
import FormSelect from 'components/Form/FormSelect'
import { UserRole } from 'types/enums'
import { PresetValue, PaginationSearch } from 'types'
import FormDate from 'components/Form/FormDate'

interface PresetValues {
	caseNumber?: PresetValue<string>
	recipient?: PresetValue<string>
	date?: PresetValue<string>
}

interface Props {
	presetValues?: PresetValues
}

const SearchForm: FC<SearchFormProps & Props> = ({ setSearch, close, presetValues }) => {
	const location = useLocation()

	const state = (location.state as { search: PaginationSearch })?.search

	const formContext = useForm<PaginationSearch>({
		defaultValues: {
			role: state?.role || null,
			caseNumber: presetValues?.caseNumber?.value || state?.caseNumber || '',
			recipient: presetValues?.recipient?.value || state?.recipient || '',
			date: presetValues?.date?.value || state?.date || '',
		},
	})

	const handleSubmit = (formData: PaginationSearch) => {
		const search: PaginationSearch = {}

		if (formData.role) search.role = formData.role
		if (formData.caseNumber) search.caseNumber = formData.caseNumber
		if (formData.recipient) search.recipient = formData.recipient
		if (formData.date) search.date = formData.date

		setSearch(search)
	}

	return (
		<Form
			context={formContext}
			onSubmit={handleSubmit}
			className="p-6 bg-gray-50 dark:bg-gray-750 border-t border-gray-200 dark:border-gray-600"
		>
			<Stack dividers>
				<h3 className="text-lg leading-5 font-medium text-gray-900 dark:text-white">Search</h3>

				<div className="flex space-x-4">
					<Stack className="flex-1">
						<FormInput
							name="caseNumber"
							label="Case Number"
							className="flex-1"
							disabled={presetValues?.caseNumber?.locked}
							hideOptional
							autoFocus={!presetValues?.caseNumber?.locked}
						/>

						<FormSelect name="role" label="Role" options={UserRole.options} clearable hideOptional />
					</Stack>

					<Stack className="flex-1">
						<FormInput
							name="recipient"
							label="Recipient"
							disabled={presetValues?.recipient?.locked}
							hideOptional
							autoFocus={presetValues?.caseNumber?.locked && !presetValues?.recipient?.locked}
						/>

						<FormDate name="date" label="Send Date" disabled={presetValues?.date?.locked} hideOptional />
					</Stack>
				</div>

				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Reset',
								icon: <Undo className="w-4 h-5" />,
								onClick: () => {
									setSearch()
									formContext.reset({})
								},
							},
							{
								title: 'Search',
								icon: <SearchIcon className="w-4 h-5" />,
								intent: 'primary',
								type: 'submit',
							},
						]}
					/>
				</div>
			</Stack>
		</Form>
	)
}

export default SearchForm
