import { useEffect, useState, useRef, Fragment } from 'react'
import Message from './Message'
import { getFullName, getChannelName, last } from 'utils/funcs'
import produce from 'immer'
import useChat from './useChat'
import { Auth } from 'Auth'
import useData from 'hooks/useData'
import clsx from 'clsx'
import Loader from 'components/Loader'
import openChatExport from 'modals/openChatExport'
import { XIcon, DocumentAddIcon } from '@heroicons/react/outline'
import { Spinner } from 'components/Icon'

const ChannelWindow = ({ data }) => {
	const { localStorageState, setLocalStorageState, client, channels, createChannel, sendMessage, markRead } =
		useChat()

	const [channel, setChannel] = useState(null)
	const [loadingChannel, setLoadingChannel] = useState(false)
	const [messageIndex, setMessageIndex] = useState(-1)

	const { isFetching: loading, data: user } = useData(`/Users/${data.user_id}`)

	useEffect(() => {
		const channelIdentifier = { user1: data.user_id, user2: Auth.profile().id }

		if (client && !channel) {
			let foundChannel = channels.find((x) => x.id === getChannelName(channelIdentifier))

			if (foundChannel) {
				setChannel(foundChannel)
			} else if (!loadingChannel) {
				setLoadingChannel(true)
				createChannel(channelIdentifier)
			}
		}

		if (channel) {
			let foundChannel = channels.find((x) => x.id === getChannelName(channelIdentifier))

			if (foundChannel && foundChannel !== channel) {
				setChannel(foundChannel)
			}
		}
	}, [client, channel, channels, data.user_id, loadingChannel, createChannel])

	useEffect(() => {
		if (!channel) return

		if (channel.unread && !data.min) {
			scrollWindow()
			markRead(channel)
		}

		if (channel.messages.length && last(channel.messages).index !== messageIndex) {
			if (!data.min) {
				scrollWindow()
			} else {
				if (localStorageState && localStorageState.settings.notifications) {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							const text = last(channel.messages).body
							const notification = new Notification('New Message', { body: text })
							setTimeout(notification.close.bind(notification), 4000)
						}
					})
				}
			}
			setMessageIndex(last(channel.messages).index)
		}
	}, [channel, data, localStorageState, markRead, messageIndex])

	const ref = useRef()

	const scrollWindow = () => {
		if (ref.current) {
			ref.current.scrollBy(0, ref.current.scrollHeight)
		}
	}

	useEffect(() => {
		scrollWindow()
	}, [])

	useEffect(() => {
		if (!data.min) {
			scrollWindow()
		}
	}, [data])

	const toggleMin = () => {
		setLocalStorageState(
			produce((draft) => {
				for (let i = 0; i < draft.chats.length; i++) {
					if (draft.chats[i].user_id === data.user_id) draft.chats[i].min = !draft.chats[i].min
				}
			})
		)
	}

	const closeWindow = (e) => {
		e.stopPropagation()

		setLocalStorageState(
			produce((draft) => {
				draft.chats = draft.chats.filter((x) => x.user_id !== data.user_id)
			})
		)
	}

	const openModal = (e) => {
		e.stopPropagation()

		openChatExport({ user, channel })
	}

	const [value, setValue] = useState('')
	const send = async (e) => {
		e.stopPropagation()
		e.preventDefault()

		if (channel && value.trim().length) {
			await sendMessage(channel.channel, value)
			setValue('')
		}
	}

	let lastExportedMessage = channel
		? channel.messages.reduce((index, item) => {
				if (item.attributes && item.attributes.exported) return item
				return index
		  }, null)
		: null

	return (
		<div
			className={clsx(
				'mr-4 rounded-t-lg bg-white dark:bg-gray-900 z-20 shadow overflow-hidden pointer-events-auto',
				!data.min && 'w-64'
			)}
		>
			<div
				className="py-3 px-4 flex justify-between items-center text-sm font-medium select-none cursor-pointer border-b border-gray-200 dark:border-gray-800 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-850"
				style={{ animation: channel && channel.unread && data.min ? 'message-flash 1s infinite' : '' }}
				onClick={toggleMin}
			>
				<div className="mr-2">{loading ? <Spinner className="w-5 h-5 animate-spin" /> : getFullName(user)}</div>

				<div className="flex items-center">
					{!data.min && (
						<button className="ml-2" onClick={openModal}>
							<DocumentAddIcon className="w-5 h-5" />
						</button>
					)}

					<button className="ml-2" onClick={closeWindow}>
						<XIcon className="w-5 h-5" />
					</button>
				</div>
			</div>

			{!data.min && (
				<div className="flex flex-col justify-end h-80">
					<div ref={ref} className="flex-1 flex flex-col overflow-y-scroll">
						{channel &&
							channel.messages.map((message, i) => (
								<Fragment key={i}>
									<Message key={message.sid} message={message} />
									{lastExportedMessage === message && (
										<div className="border-b border-gray-200 dark:border-gray-800 text-gray-400 dark:text-gray-500 text-center text-sm mx-2 mb-2">
											Archived
										</div>
									)}
								</Fragment>
							))}

						{(!channel || channel.loading) && <Loader />}
					</div>

					<div>
						<form onSubmit={send}>
							<input
								type="text"
								value={value}
								onChange={(e) => setValue(e.target.value)}
								placeholder="Type a message..."
								disabled={!channel || (channel && (channel.loading || channel.sending))}
								className="form-input rounded-none block w-full sm:text-sm sm:leading-5 dark:bg-gray-800 dark:placeholder-gray-400 dark:border-gray-600"
							/>
						</form>
					</div>
				</div>
			)}
		</div>
	)
}

export default ChannelWindow
