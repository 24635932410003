import { FC, useState } from 'react'
import Card from 'components/Card'
import Stack from 'components/Stack'
import Button from 'components/Button'
import Inline from 'components/Inline'
import Actions from 'components/Actions'
import CardHeader from 'components/CardHeader'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import CircleBadge from 'components/CircleBadge'
import MonthSwitcher from 'components/MonthSwitcher'
import CustomRoleMenu from 'components/CustomRoleMenu'
import { DotsHorizontalIcon, KeyIcon, ChevronRightIcon } from '@heroicons/react/outline'
import openCreateUser from 'modals/openCreateUser'
import openFindUser from 'modals/openFindUser'
import openPartyInvitation from 'modals/openPartyInvitation'
import { confirm, success, warning, toast } from 'alerts'
import openConfirmUser from 'modals/openConfirmUser'
import { startOfMonth, endOfMonth } from 'date-fns'
import clsx from 'clsx'
import { getFullName } from 'utils/funcs'
import { Auth } from 'Auth'
import { CASE_PARTY_ROLE } from 'types/enums'
import openBooking from 'modals/openBooking'
import api from 'api'
import { CaseModel } from 'types/models'

const Test: FC = () => {
	const [roles, setRoles] = useState<CASE_PARTY_ROLE[]>([])
	const [dateRange, setDateRange] = useState<[Date, Date]>([startOfMonth(new Date()), endOfMonth(new Date())])

	const [radioSelected, setRadioSelected] = useState(0)

	return (
		<>
			<PageHeading title="Test" />

			<PageContent>
				<Stack>
					<Card header={<CardHeader title="Crash" />}>
						<Actions
							actions={[
								{
									title: 'Crash',
									intent: 'danger',
									onClick: () => {
										throw new Error('Test error')
									},
								},
							]}
						/>
					</Card>

					<Card header={<CardHeader title="List" />}>
						<Actions
							actions={[
								{
									icon: <DotsHorizontalIcon className="w-5 h-5" />,
									intent: 'menu',
									placement: 'bottom-start',
									actions: [
										[
											{
												title: 'Item One',
												iconSide: 'right',
												icon: <ChevronRightIcon className="w-4 h-4 ml-4" />,
												placement: 'right-start',
												hoverable: true,
												actions: [
													[
														{
															title: 'Item One aaaaaa bbbbbb cccccc',
															iconSide: 'right',
															icon: <ChevronRightIcon className="w-4 h-4 ml-4" />,
															onClick: () => {},
														},
														{
															title: 'Item Two',
															placement: 'right-start',
															hoverable: true,
															iconSide: 'right',
															icon: <ChevronRightIcon className="w-4 h-4 ml-4" />,
															actions: [
																[
																	{
																		title: 'Item Three',
																		onClick: () => {},
																	},
																],
																[
																	{
																		title: 'Item Four Five Six',
																		placement: 'right-start',
																		hoverable: true,
																		iconSide: 'right',
																		icon: (
																			<ChevronRightIcon className="w-4 h-4 ml-4" />
																		),
																		actions: [
																			{
																				title: 'Item Five',
																				onClick: () => {},
																			},
																			{
																				title: 'Item Six',
																				onClick: () => {},
																			},
																			{
																				title: 'Item Seven',
																				onClick: () => {},
																			},
																			{
																				title: 'Item Eight',
																				onClick: () => {},
																			},
																		],
																	},
																],
															],
														},
													],
													[
														{
															title: 'Item Three',
															onClick: () => {},
														},
													],
												],
											},
											{
												title: 'Item Two',
												placement: 'right-start',
												hoverable: true,
												iconSide: 'right',
												icon: <ChevronRightIcon className="w-4 h-4 ml-4" />,
												actions: [
													[
														{
															title: 'Item Three',
															onClick: () => {},
														},
													],
													[
														{
															title: 'Item Four Five Six',
															placement: 'right-start',
															hoverable: true,
															iconSide: 'right',
															icon: <ChevronRightIcon className="w-4 h-4 ml-4" />,
															actions: [
																{
																	title: 'Item Five',
																	onClick: () => {},
																},
																{
																	title: 'Item Six',
																	onClick: () => {},
																},
																{
																	title: 'Item Seven',
																	onClick: () => {},
																},
																{
																	title: 'Item Eight',
																	onClick: () => {},
																},
															],
														},
													],
												],
											},
										],
										[
											{
												title: 'Item Three',
												onClick: () => {},
											},
										],
									],
								},
							]}
						/>
					</Card>

					<Card header={<CardHeader title="Experimental Inputs" />}>
						<Stack>
							<div>
								<label className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 mb-0 select-none">
									Form Label
								</label>

								<div className="relative rounded-md shadow-sm mt-1">
									<input
										type="text"
										className="form-input block w-full pr-10 sm:text-sm sm:leading-5"
									/>
								</div>
							</div>

							<div>
								<label className="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 mb-0 select-none">
									Form Label
								</label>

								<div className="relative rounded-md shadow-sm mt-1">
									<div className="text-sm font-medium flex">
										<label
											className={clsx(
												'px-3 py-2 bg-white dark:bg-gray-800 rounded-l-md border border-gray-300 dark:border-gray-600 space-x-2 flex items-center flex-1 group font-normal cursor-pointer transition ease-in-out duration-150',
												radioSelected === 1
													? 'border border-primary-200 bg-primary-50 dark:bg-primary-500 dark:text-primary-300 dark:bg-opacity-25  group-focus:ring-primary z-10'
													: 'hover:bg-gray-50'
											)}
										>
											<input
												type="radio"
												name="type"
												value="1"
												className="form-radio"
												onChange={() => setRadioSelected(1)}
											/>
											<div>Undecided</div>
										</label>

										<label
											className={clsx(
												'-ml-px px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 space-x-2 flex items-center flex-1 group font-normal cursor-pointer transition ease-in-out duration-150',
												radioSelected === 2
													? 'border border-primary-200 bg-primary-50 dark:bg-primary-500 dark:text-primary-300 dark:bg-opacity-25  group-focus:ring-primary z-10'
													: 'hover:bg-gray-50'
											)}
										>
											<input
												type="radio"
												name="type"
												value="2"
												className="form-radio"
												onChange={() => setRadioSelected(2)}
											/>
											<div>Yes</div>
										</label>

										<label
											className={clsx(
												'-ml-px px-3 py-2 bg-white dark:bg-gray-800 rounded-r-md border border-gray-300 dark:border-gray-600 space-x-2 flex items-center flex-1 group font-normal cursor-pointer transition ease-in-out duration-150',
												radioSelected === 3
													? 'border border-primary-200 bg-primary-50 dark:bg-primary-500 dark:text-primary-300 dark:bg-opacity-25 group-focus:ring-primary z-10'
													: 'hover:bg-gray-50'
											)}
										>
											<input
												type="radio"
												name="type"
												value="3"
												className="form-radio"
												onChange={() => setRadioSelected(3)}
											/>
											<div>No</div>
										</label>
									</div>
								</div>
							</div>
						</Stack>
					</Card>

					<Card header={<CardHeader title="Toasts" />}>
						<Actions
							actions={[
								{
									title: 'Open Success Toast',
									intent: 'primary',
									onClick: () => {
										toast({ title: 'Thing Saved' })
									},
								},
								{
									title: 'Delete Dialog',
									intent: 'primary',
									onClick: () => {
										confirm({
											title: 'Are you sure you want to delete this thing?',
											message: "Can't be undone",
											onAccept: async () => {
												await new Promise((resolve) => setTimeout(resolve, 3000))
												toast({ title: 'Thing deleted' })
											},
										})
									},
								},
							]}
						/>
					</Card>

					<Card header={<CardHeader title="Inline" />}>
						<Inline>
							<Button intent="primary">Button One</Button>
							<Button intent="primary">Button Two</Button>
							<Button intent="primary">Button Three</Button>
							<Button intent="primary">Button Four</Button>
							<Button intent="primary">Button Five</Button>
							<Button intent="primary">Button Six</Button>
							<Button intent="primary">Button Seven</Button>
						</Inline>
					</Card>

					<Card header={<CardHeader title="Month Switcher" />}>
						<MonthSwitcher value={dateRange} onChange={setDateRange} />
					</Card>

					<Card header={<CardHeader title="Role Stuff" />}>
						<div className="flex justify-between">
							<div className="flex overflow-hidden -space-x-2">
								{roles.indexOf(CASE_PARTY_ROLE.CaseManager) > -1 && <CircleBadge>CM</CircleBadge>}

								{roles.indexOf(CASE_PARTY_ROLE.Applicant) > -1 && <CircleBadge>AP</CircleBadge>}

								{roles.indexOf(CASE_PARTY_ROLE.Reviewer) > -1 && <CircleBadge>RV</CircleBadge>}

								{roles.indexOf(CASE_PARTY_ROLE.Respondent) > -1 && <CircleBadge>RS</CircleBadge>}

								{roles.indexOf(CASE_PARTY_ROLE.Mediator) > -1 && <CircleBadge>ME</CircleBadge>}

								{roles.indexOf(CASE_PARTY_ROLE.PeerReviewer) > -1 && <CircleBadge>PR</CircleBadge>}
							</div>

							<Actions
								actions={{
									icon: <KeyIcon className="w-5 h-5" />,
									intent: 'menu',
									menu: <CustomRoleMenu value={roles} onChange={setRoles} />,
								}}
							/>
						</div>
					</Card>

					<Card header={<CardHeader title="Alerts" />}>
						<Actions
							actions={[
								{
									title: 'Show Confirm',
									intent: 'primary',
									onClick: async () => {
										await confirm({
											title: 'Are you sure?',
											message: "Can't be undone",
											onAccept: async () => {
												await new Promise((resolve) => setTimeout(resolve, 3000))
												console.log('confirmed')
											},
										})
									},
								},
								{
									title: 'Show Success',
									intent: 'primary',
									onClick: () =>
										success({
											title: 'You did it!',
											message: 'You crazy son of a bitch',
										}),
								},
								{
									title: 'Show Warning',
									intent: 'primary',
									onClick: () =>
										warning({
											title: 'Something went wrong',
											message: 'Dunno what though',
										}),
								},
							]}
						/>
					</Card>

					<Card header={<CardHeader title="Modals" />}>
						<Actions
							actions={[
								// {
								// 	title: 'Show Booking Modal',
								// 	intent: 'primary',
								// 	onClick: () => {
								// 		openBooking({ caseData: { id: '123' } })
								// 	},
								// },
								{
									title: 'Show Create User',
									intent: 'primary',
									onClick: () => {
										openCreateUser({ refetch: async () => console.log('refetch') })
									},
								},
								{
									title: 'Show Party Invitation',
									intent: 'primary',
									onClick: async () => {
										const accepted = await openPartyInvitation()
										console.log('accepted', accepted)
									},
								},
								// {
								// 	title: 'Show File Upload',
								// 	intent: 'primary',
								// 	onClick: () => {
								// 		openFileUpload()
								// 	},
								// },
								{
									title: 'Show Find User',
									intent: 'primary',
									onClick: async () => {
										const selectedUser = await openFindUser({ title: 'Add or find user' })

										console.log('selected user', getFullName(selectedUser))
									},
								},
								{
									title: 'Show Confirm User',
									intent: 'primary',
									onClick: async () => {
										const profile = Auth.profile()

										if (profile) {
											const confirmed = await openConfirmUser({ user: profile })

											console.log('user confirmed', confirmed)
										}
									},
								},
								{
									title: 'Show Booking',
									intent: 'primary',
									onClick: async () => {
										const { data: caseData } = await api.get<CaseModel>(
											`/Cases/0145fb06-dbfa-4b7c-af3e-191f11bfd829`
										)

										await openBooking({ caseData })
									},
								},
							]}
						/>
					</Card>

					<Card header={<CardHeader title="Buttons" />}>
						<Stack space={4}>
							<Inline>
								<Button intent="primary">Primary</Button>
								<Button intent="secondary">Secondary</Button>
								<Button intent="danger">Danger</Button>
								<Button intent="save">Save</Button>
							</Inline>

							<Inline>
								<Button intent="primary" isLoading>
									Primary
								</Button>
								<Button intent="secondary" isLoading>
									Secondary
								</Button>
								<Button intent="danger" isLoading>
									Danger
								</Button>
								<Button intent="save" isLoading>
									Save
								</Button>
							</Inline>
						</Stack>
					</Card>
				</Stack>
			</PageContent>
		</>
	)
}

export default Test
