export enum ADR_TYPE {
	Standard = 0,
	MultiIssue = 1,
}

export const adrType = {
	0: 'Standard',
	1: 'Multi-issue',
}

export const ADRType = {
	readable: (x: ADR_TYPE) => adrType[x],
	options: Object.entries(adrType).map(([key, val]) => ({ label: val, value: +key })),
}
