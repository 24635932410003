import { FC, useState, useRef, useEffect, useCallback } from 'react'
import clsx, { ClassValue } from 'clsx'

const isDescendentOf = (child: HTMLElement, targetAncestor: HTMLElement): boolean => {
	let pass = false
	let ancestor = child.parentElement

	while (ancestor) {
		if (ancestor.parentElement === targetAncestor) pass = true
		ancestor = ancestor.parentElement
	}

	return pass
}

interface TruncateProps {
	className?: ClassValue
}

export const Truncate: FC<TruncateProps> = ({ className, children }) => {
	const [hovered, setHovered] = useState(false)
	const [maxWidth, setMaxWidth] = useState<string>('initial')
	const [enabled, setEnabled] = useState(false)
	const ref = useRef<HTMLDivElement>(null)
	const spanRef = useRef<HTMLSpanElement>(null)
	const popupRef = useRef<HTMLDivElement>(null)

	// decide whether this element should do the popover at all
	const checkEnabled = useCallback(() => {
		if (spanRef.current && ref.current) {
			setEnabled(spanRef.current.getBoundingClientRect().width > ref.current.clientWidth)
		}
	}, [])

	// decide how wide the outer div should be
	const checkMaxWidth = useCallback(() => {
		if (ref.current) {
			let maxWidth = ref.current.clientWidth > 0 ? `${ref.current.clientWidth}px` : 'initial'
			setMaxWidth(maxWidth)
		}
	}, [])

	// handle initial load
	useEffect(() => {
		if (ref.current) {
			checkMaxWidth()
			checkEnabled()
		}
	}, [ref, checkMaxWidth, checkEnabled])

	// on window resize, decide if the popover should appear
	useEffect(() => {
		window.addEventListener('resize', checkEnabled)
		return () => window.removeEventListener('resize', checkEnabled)
	}, [checkEnabled])

	// More thorough handling of mouseout event
	useEffect(() => {
		if (ref.current && hovered) {
			// note this is not the React.MouseEvent because we are not passing it to a component...
			const fn = (e: MouseEvent) => {
				if (
					ref.current &&
					e.target !== popupRef.current &&
					// ...thus we must do `as HTMLElement` below, because the native MouseEvent does not take a generic
					!isDescendentOf(e.target as HTMLElement, ref.current)
				) {
					setHovered(false)
				}
			}

			window.addEventListener('mouseover', fn)
			return () => window.removeEventListener('mouseover', fn)
		}
	}, [ref, popupRef, hovered])

	return (
		<div className="relative whitespace-nowrap">
			<div ref={ref} className="overflow-x-hidden" onMouseOver={() => setHovered(true)}>
				<div className="whitespace-no-wrap overflow-hidden overflow-ellipsis" style={{ maxWidth }}>
					<span className="whitespace-nowrap" ref={spanRef}>
						{children}
					</span>
				</div>
			</div>

			<div
				className={clsx(
					'absolute z-10 top-0 left-0 break-normal',
					className || '-mx-2 px-2 -my-1 py-1 rounded-md bg-yellow-200 text-yellow-900',
					hovered && enabled ? 'block' : 'hidden'
				)}
				ref={popupRef}
			>
				{children}
			</div>
		</div>
	)
}

export default Truncate
