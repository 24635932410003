import { FC, Suspense, useRef, useState } from 'react'
import clsx from 'clsx'
import { Link, Outlet } from 'react-router-dom'
import logo from 'assets/img/newlogo/icra-acronym-white.png'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import Topbar from 'components/Topbar'
import versions from 'utils/versions'
import semver from 'semver'
import { site } from 'utils/config'
import nav from 'nav'
import MobileSidebar from './MobileSidebar'
import { MenuIcon } from '@heroicons/react/outline'

const PageSidebar: FC = () => {
	const [isOpen, setOpen] = useState(false)

	const releases = versions.sort((a, b) => (semver.gt(a.number, b.number) ? -1 : 1))

	const ref = useRef<HTMLDivElement>(null)

	const links = nav()

	return (
		<div className="h-screen flex flex-col md:flex-row md:overflow-hidden bg-gray-200 dark:bg-gray-800">
			<Sidebar
				name="main"
				intent="dark"
				tooltipIntent="light"
				minimizable
				items={links}
				className="hidden md:flex"
				header={(min) => (
					<div className={clsx('flex items-center transition-all', min ? 'pl-2.5' : 'pl-5')}>
						<Link to="/dashboard">
							<img
								src={logo}
								alt="ICRA"
								className={clsx(
									'my-10 h-12 object-cover object-left transition-all',
									min ? 'w-10' : 'w-full'
								)}
							/>
						</Link>
					</div>
				)}
				footer={(min) => (
					<div className={clsx('flex justify-between items-center', min ? 'flex-col space-y-2' : 'p-4')}>
						<div
							className={clsx(
								'text-white/50 text-sm font-medium cursor-default',
								min ? 'text-center' : ''
							)}
						>
							{!min ? `Version: ${releases[0].number}` : `v${releases[0].number}`}
						</div>

						{site === 'livetest' ? (
							<div
								className={clsx(
									'py-0.5 -my-0.5 font-medium text-sm leading-6 text-red-300 bg-red-400 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-25',
									min ? 'w-full text-center' : 'px-2.5 rounded-full'
								)}
							>
								LiveTest
							</div>
						) : site === 'demo' ? (
							<div
								className={clsx(
									'py-0.5 -my-0.5 font-medium text-sm leading-6 text-green-300 bg-green-400 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-25',
									min ? 'w-full text-center' : 'px-2.5 rounded-full'
								)}
							>
								Demo
							</div>
						) : site === 'staging' ? (
							<div
								className={clsx(
									'py-0.5 -my-0.5 font-medium text-sm leading-6 text-orange-300 bg-orange-400 dark:bg-orange-500 bg-opacity-25 dark:bg-opacity-25',
									min ? 'w-full text-center' : 'px-2.5 rounded-full'
								)}
							>
								Staging
							</div>
						) : site === 'dev' ? (
							<div
								className={clsx(
									'py-0.5 -my-0.5 font-medium text-sm leading-6 text-primary-300 bg-primary-400 dark:bg-primary-500 bg-opacity-25 dark:bg-opacity-25',
									min ? 'w-full text-center' : 'px-2.5 rounded-full'
								)}
							>
								Dev
							</div>
						) : null}
					</div>
				)}
			/>

			<MobileSidebar
				intent="dark"
				isOpen={isOpen}
				setOpen={setOpen}
				items={links}
				header={
					<div className={clsx('flex items-center pl-5')}>
						<Link to="/dashboard">
							<img
								src={logo}
								alt="ICRA"
								className="my-5 h-12 object-cover object-left transition-all w-full"
							/>
						</Link>
					</div>
				}
				footer={
					<div className="flex flex-row justify-between items-center p-4">
						<div className="text-gray-400 text-sm font-medium cursor-default">
							Version: {releases[0].number}
						</div>

						{site === 'livetest' ? (
							<div className="py-0.5 -my-0.5 font-medium text-sm leading-6 text-red-300 bg-red-400 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-25 px-2.5 rounded-full">
								LiveTest
							</div>
						) : site === 'demo' ? (
							<div className="py-0.5 -my-0.5 font-medium text-sm leading-6 text-green-300 bg-green-400 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-25 px-2.5 rounded-full">
								Demo
							</div>
						) : site === 'staging' ? (
							<div className="py-0.5 -my-0.5 font-medium text-sm leading-6 text-orange-300 bg-orange-400 dark:bg-orange-500 bg-opacity-25 dark:bg-opacity-25 px-2.5 rounded-full">
								Staging
							</div>
						) : site === 'dev' ? (
							<div className="py-0.5 -my-0.5 font-medium text-sm leading-6 text-primary-300 bg-primary-400 dark:bg-primary-500 bg-opacity-25 dark:bg-opacity-25 px-2.5 rounded-full">
								Dev
							</div>
						) : null}
					</div>
				}
			/>

			<div className="flex flex-col flex-1 md:w-0 md:overflow-hidden">
				<main className="flex-1 relative z-0 md:overflow-y-auto focus:outline-none" ref={ref}>
					<div className="bg-brand-700 md:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-50">
						<div className="max-w-7xl mx-auto">
							<div className="border-b border-gray-300 dark:border-gray-700">
								<Topbar includeSearch={false}>
									<div className="flex gap-3">
										<button
											type="button"
											className="-ml-0.5 -mt-0.5 h-12 w-12 md:hidden inline-flex items-center justify-center rounded-md text-white/50 hover:text-white dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
											onClick={() => setOpen(true)}
										>
											<span className="sr-only">Open sidebar</span>
											<MenuIcon className="h-6 w-6" aria-hidden="true" />
										</button>
										<img src={logo} alt="ICRA" className="h-12 md:hidden" />
									</div>
								</Topbar>
							</div>
						</div>
					</div>

					<Suspense
						fallback={
							<div className="flex justify-center items-center h-screen">
								<Loader />
							</div>
						}
					>
						{/* <div className="relative z-20">{children}</div> */}
						<div className="relative z-20">
							<Outlet />
						</div>
					</Suspense>
				</main>
			</div>
		</div>
	)
}

export default PageSidebar
