interface Version {
	number: string
	date: string
	new: string[]
	change: string[]
	bug: string[]
}

const versions: Version[] = [
	{
		number: '1.0.0',
		date: '2019-11-27',
		new: ['Advocate edit button on Participants page'],
		change: [
			'Extend availability to 6 months worth',
			'Include PDFs in notifications to ACC',
			'Redirect back to original page after login if redirected there from somewhere else',
		],
		bug: [
			'Importing files not working',
			'Searching cases by type fails',
			'Availability stops at January',
			'Cannot edit file permissions',
		],
	},
	{
		number: '1.1.0',
		date: '2020-02-04',
		new: ['Changelog page', 'File Upload Log on Admin page', 'Billing page for open cases'],
		change: [
			'Admin can now reassign case managers on a pending case',
			'Ability to change case respondent on pending case (from ACC Officer to other Organisation user)',
			"Todo's repurposed as reminders to upload minute/decision files",
			'Added extra step to creating case request for booking CMC date',
		],
		bug: [
			'Case Request page crashes when applicant has ampersand in their name',
			'Internet Explorer crashing when using dashboard calendar',
			'Editing file permissions was dropping the case manager permission',
		],
	},
	{
		number: '1.2.0',
		date: '2020-02-27',
		new: [
			'Can now filter email logs by the Case they were about',
			'File Handler view now includes date of file upload',
			'Added new case statuses for various outcomes',
			"Ability to duplicate CMC's and Hearings",
			'Added new page for linking Cases together',
			'Added new page for displaying logs specific to a Case',
			'Added new reminder todos: "Adjournment Minute" and "Withdrawal Minute"',
		],
		change: [
			'Removed right click from Users and Cases tables',
			'Mixed forum renamed to "On the papers"',
			'Improved wording of user roles, eg. "Reviewer" now correctly printed as "Reviewer"',
		],
		bug: [],
	},
	{
		number: '1.3.0',
		date: '2020-02-28',
		new: [],
		change: [
			'Changed design of file upload and file import forms to resolve visual bugs, these will need more time and attention in future.',
		],
		bug: [
			'Fixed the file upload process not realising when it has failed, and not notifying the user',
			'Fixed a bug that caused no default file category to be chosen on file upload form, which was causing the server to reject the uploads',
		],
	},
	{
		number: '1.4.0',
		date: '2020-03-24',
		new: [
			'Added CSV Export feature for admins',
			'Added Organisations page for creating, editing and deleting Organisations',
			'Added ACC Email Override functionality, now with improved messaging about where emails will be sent',
			'Added Peer Reviewer user role, and case party',
			'Added Decision Due date for Review Hearings',
			'Added ability for reviewers to define their own weekly schedule, and regenerate their availability a month at a time',
		],
		change: [
			'Change wording of "Defendant" to "Respondent"',
			'Change availability time slots to be half an hour long each',
			'Change "Check All" button when uploading files to not include Mediatior and Peer Reviewer users in permissions',
		],
		bug: ['Fixed the reviewer acceptance notifications never allowing users to accept'],
	},
	{
		number: '1.5.0',
		date: '2020-03-25',
		new: [],
		change: ['Changed Chat functionality to include only Case Managers and Administrators'],
		bug: [
			'Stopped users from being able to double-click buttons on the account confirm page, and the password reset page, which was likely causing much confusion.',
		],
	},
	{
		number: '1.6.0',
		date: '2020-04-20',
		new: ['Add middle name for users'],
		change: [
			'Changed how Applicants and Advocates are handled in event attendees checkboxes',
			'Changed ACC Claim Number to no longer be a required field for cases',
			'Changed Venue to no longer be used for "On the papers" events',
			'Changed date format in Case CSV Export',
			'Extended expiry time on two-factor codes',
			'Implemented many notifications changes',
		],
		bug: ["Added check to make sure the app doesn't attempt to create users if the email is already taken"],
	},
	{
		number: '1.7.0',
		date: '2020-04-24',
		new: [
			'Add new button to view some types of files in a new tab',
			'Added checkbox to allow case managers to manually set Reviewers as "Accepted"',
		],
		change: ['Changes to Cases Export formatting'],
		bug: ['Fix Applicants, Advocates and Respondents from being able to see too many of the case pages'],
	},
	{
		number: '1.8.0',
		date: '2020-04-30',
		new: ['Add case manager filter for hearings on dashboard', 'Add filter for notification logs'],
		change: [
			'Changed the decline case request button to be the only way to trigger notification 2b',
			'Improved loading times of the Cases page by delaying the loading of certain data until it is needed',
			'Removed the ability for reviewers to edit case parties',
		],
		bug: [],
	},
	{
		number: '1.9.0',
		date: '2020-05-12',
		new: [],
		change: ['When uploading more than one file at a time, only one notification is sent'],
		bug: [],
	},
	{
		number: '1.10.0',
		date: '2020-06-02',
		new: ['Reviewers are now able to accept via the edit reviewer form', 'Added applicant name to case sidebar'],
		change: [],
		bug: ['Fixed an instance where "On the papers" was showing as "Mixed"'],
	},
	{
		number: '1.11.0',
		date: '2020-06-22',
		new: [
			'Added new file categories and folders',
			'Added new case statuses',
			'Added case appeal status functionality',
			'Added ability to view past history of reviewers',
		],
		change: [
			'Change default case type from "Hybrid" to "Review"',
			'Set default case complexity to "Standard"',
			'Rename tabs on pending case form to match the names of open case pages',
			'Rename "Decision Date" to "ACC Decision Date" on hearing dates edit form',
			'Change sorting order of cases on the dashboard',
			'Improved design of Case Request page, putting duplicate cases in a table with a link to the case',
		],
		bug: ['Fix user roles appearing wrongly named on user profile page'],
	},
	{
		number: '1.12.0',
		date: '2020-07-28',
		new: [
			'Review numbers are now stored as Reviews. These belong to Cases, and can be assigned to multiple conferences.',
		],
		change: [
			'Change "Check all" button to include mediator permission during file upload',
			'Updated label of "Notes" inputs for cases',
			'Mediators are now able to accept or decline a case assignment',
			'Accepting a case party assignment manually (reviewer or mediator) now requires the user accept an agreement.',
			"Update what a Case Manager can and can't do in regards to case parties",
		],
		bug: ['Switched to better data-fetching library to stop some random crashes while submitting forms'],
	},
	{
		number: '1.13.0',
		date: '2020-09-07',
		new: [
			'New confirmation dialog when choosing a user by searching their name.',
			'New dialog when trying to download a recording file',
			'New option to disable notifications for users',
			'New option to disable notifications for specific hearings',
		],
		change: [
			'Reviewers can no longer create hearings.',
			'"Public case notes" renamed to "Case notes"',
			'Various changes to case CSV export',
		],
		bug: ['Reopening a Case now removes the closed date'],
	},
	{
		number: '1.14.0',
		date: '2020-09-16',
		new: [
			'New user role "ACC Admin", can view ACC cases',
			'Added Organisation field to cases',
			'Added Respondent support users',
		],
		change: [
			'New User modal window in pending case form no longer automatically sets the user unless the "set as..." checkbox is used',
		],
		bug: [],
	},
	{
		number: '1.14.1',
		date: '2020-09-23',
		new: ['New user role "Support", that is like a read-only Admin'],
		change: [],
		bug: [],
	},
	{
		number: '1.14.2',
		date: '2020-09-23',
		new: ['Added two new case statuses for billing purposes', 'Added Organisation filter on Case search'],
		change: [
			'Removed Decision Issued case status',
			'Stop Case Managers being able to create Administrator users, or being able to edit users to be Admins',
		],
		bug: [],
	},
	{
		number: '1.14.3',
		date: '2020-10-28',
		new: ['Added the ability to deactivate users'],
		change: [],
		bug: ['Fixed new outcome names in cases csv export'],
	},
	{
		number: '1.15.0',
		date: '2020-11-02',
		new: [
			'Added new billing pages in both case view, and admin area',
			'Added ability to change closing date of a case',
			"Added ability to set an adjournment when editing a case hearing's date",
		],
		change: ['Removed case status "Decision Issued: Upheld"'],
		bug: [],
	},
	{
		number: '1.15.1',
		date: '2020-11-18',
		new: [
			'Line Items can now have their billing date overridden',
			'Added ADR Type and Closing Category to mediations',
		],
		change: [
			'File Upload permissions have been overhauled to be more restricted',
			'Unapproved Line Items list has been improved',
			'Handling of Transcription Fees has been improved',
			'Billing interface in general has been improved',
		],
		bug: ['Show ECF Hours in CSV correctly'],
	},
	{
		number: '1.15.2',
		date: '2020-11-23',
		new: ['Added the display of ADR Type, and Closing Category and date to Mediation type hearings'],
		change: [
			'Billing Calculator wording changes',
			'Improvements to the process of setting line items as claimable/requested/approved',
			'Changes to billing CSV exports',
		],
		bug: [],
	},
	{
		number: '1.15.3',
		date: '2020-11-30',
		new: ['Added No GST option to line items', 'Added check all button when editing file permissions'],
		change: ['Changed default file permissions when creating a new case'],
		bug: [],
	},
	{
		number: '1.15.4',
		date: '2020-12-17',
		new: ['Added "Issue Code" dropdown to the Case Advanced Search form'],
		change: [
			'Switch to using upload date rather than import date for imported files',
			'Add billable change to reviewer history window, and also start honoring this value in the billing process',
			'Changed billing line item creation process to allow a lineitem to be created and approved in one go',
			'Changed how hearings are filtered on the dashboard',
			'Removed time options from hearings if their forum is "On The Papers"',
			'Change folder that files are uploaded into via the minute/decision todo items',
		],
		bug: [],
	},
	{
		number: '1.15.5',
		date: '2020-12-21',
		new: [
			'Added two new case closing categories, "Decision Issued: ACC to make a decision within the timeframe directed" and "Decision Issued: Review upheld, decision now made"',
			'Added new Issue Code "W6: First and Later Premium Reviews"',
		],
		change: [],
		bug: [],
	},
	{
		number: '1.15.6',
		date: '2021-01-20',
		new: ['Add Decision Date field to cases CSV export', 'ADR Notice pdf now stored in folder SET DOWN NOTICES'],
		change: [
			'Late case popup logic reworked',
			'Show all case hearings on dashboard, including ones for closed cases',
		],
		bug: ['Fix wrong hearings showing for users on dashboard'],
	},
	{
		number: '1.15.7',
		date: '2021-01-27',
		new: ['Added notification "ADR (Mediation) Outcome"'],
		change: [],
		bug: [],
	},
	{
		number: '1.16.0',
		date: '2021-02-24',
		new: [
			'Added Multi-Factor authentication, in the form of remembering a device and not needing a Two-Factor email on subsequent logins',
		],
		change: [
			'Case Manager can no longer assign users the Support role',
			'Admins are now the only user able to change a users 2FA setting',
			'Removed issue code "W6: First and Later Premium Reviews"',
			'Reopened cases now have their billing date reset',
		],
		bug: [],
	},
	{
		number: '1.16.1',
		date: '2021-03-17',
		new: [
			'Add user notes feature',
			'Add confirmation popup when selecting Respondent and Reviewer',
			'Added phone number filter on users page',
		],
		change: [
			'Add case number to case hearings on dashboard',
			'Cases CSV Export - use the #Reviews "Decision due date" as Decision_due_date',
			'Notification 32. Adjournment Minute uploaded, change trigger - should also trigger if Hearing "CMC" is selected',
			'Notification 17. ADR (Mediation) Set down to be sent to Respondent Advocate',
			'Adding a new participant to a case and event should trigger a new notice (CMC, hearing set down or ADR Notice) to the added participant/attendee of the event.',
			'Changed peer review date to from 7 to 10 days ahead of decision due date',
		],
		bug: [
			'Add missing review numbers to case hearings on dashboard',
			'Fix notifications enabled checkbox sometimes not working',
		],
	},
	{
		number: '1.16.2',
		date: '2021-03-31',
		new: [],
		change: [
			'Cases CSV Export - add column Reviewer acceptance (fill with value Yes/No)',
			'Cases CSV Export - add column "set by date"',
		],
		bug: [],
	},
	{
		number: '1.16.3',
		date: '2021-04-12',
		new: [
			'Added new case closing category: "Decision Issued: Modified"',
			'Added new case closing category: "Deemed Decision"',
		],
		change: [],
		bug: [],
	},
	{
		number: '2.0.0',
		date: '2021-06-29',
		new: ['New design!'],
		change: [],
		bug: [],
	},
	{
		number: '2.1.0',
		date: '2021-07-14',
		new: [
			'Add reminder log pages',
			'Add button for reviewers to reset Diary date range',
			"Add button for Admins and Case Managers to be able to copy a paginated table's data to clipboard",
			'Add review numbers to Cases table, and also on the Case Information page for each case',
		],
		change: [
			'Pending cases are now locked for Case Managers until the Reviewer has been assigned',
			'Case Complexity automatically updates based on the cases reviews',
			"All mediations ADR type's update based on case complexity",
			"Add/Edit Line Item form's default GST type is now Excl",
			'Withdrawn reviews are no longer available for new conferences',
		],
		bug: ['Better error message for when a user tries to download a file that has actually been deleted'],
	},
	{
		number: '2.2.0',
		date: '2021-07-26',
		new: [
			'Add ability to search cases by appeal status',
			'Add appeal status to case csv export',
			'Add new Reports page, with the Decision Due report',
			'Add new billing export for "All Reviewers/Mediators" for Xero',
			'Add new billing export for AEP invoicing',
		],
		change: [
			'Speed up case creation by creating a case and its folders in one step',
			'Speed up case csv export by using filters',
		],
		bug: [
			'Stop recording reviewer/mediator history while case is pending',
			'Fix devices not being saved during two-factor login step',
			'Fix "New Conference" form from falsely reporting availability collisions',
		],
	},
	{
		number: '2.3.0',
		date: '2021-08-11',
		new: [
			'New notification: Decision on Costs upload by Case manager',
			'New file upload category: Decision on Costs',
			'New "View Availability" slideout for case managers and admins on availability page',
		],
		change: [
			'Show all withdrawn review numbers in ACC invoicing csv again',
			'3 Day Reminder notification for Case Management Conference not to be sent out if CMC is scheduled within 3 days from current date',
			'Swap positions of "Applicant name" and "Case number" fields in case search area',
			'Stop showing public holidays in availability CSV',
			'Updated "Edit Availability" slideout for reviewers to see what cases are booked in which slots',
		],
		bug: ['Fix active cases tile on case manager dashboard to show correct number'],
	},
	{
		number: '2.3.1',
		date: '2021-09-07',
		new: [],
		change: [
			'New version of Agreement to Mediate to be attached to all ADR Notices',
			'Billing - billable cases without a Reviewer to appear on billing files for the billing period',
			'Changes to notification 2.b. Case declined by Case Manager',
		],
		bug: [],
	},
	{
		number: '2.4.0',
		date: '2021-09-22',
		new: [],
		change: ['Revised security'],
		bug: [
			'Fixed bug with form submission for ACC File Handlers',
			'Fixed disabled endpoint for viewing billing files',
			'Fixed multiple issues around which data Support users could see',
			'Fixed an issue where reviewers could see the UI to modify peer reviewers on a case',
			'Fixed issue where mediators and reviewers could not load conferences directly',
			'Fixed issue where adjournments could be created by just about anyone',
			'Fixed ACC Admin not being able to see conference attendee names',
			'Fix bug with Adjournments set to forum "on the papers" would not update the hearing date',
			'Stopped ACC Admin from being able to search users',
			'Fixed ACC Admin not being able to view case status or linked cases pages',
			'Fixed some roles not being able to view their own email logs',
			'Fixed ACC Admin being unable to see any case folders or files',
			'Fixed accidentally disabled AppFiles delete endpoint',
		],
	},
	{
		number: '2.5.0',
		date: '2021-10-27',
		new: [
			'Added notification to participants in an open case upon change of Case Manager by Admin user/Changing the Case Manager in an open case',
			'3 Day Reminder notification for CMC dropping off queue',
			'New mediation closing category "ADR Non Attendance" for mediation "no shows"',
			'Document details - include “uploaded by: [user]”',
			'Cases CSV Export - Add filter "ICRA received date" (Application Date)',
			'Added ability to rename Documents on the platform',
			'Added ability to remove "Appeal Status"',
			'Added ability to disable the Account Verification Email upon user creation',
		],
		change: [
			'If Issue Code "X29: Cover - Treatment injury" is selected, prompt for Case Manager to confirm case complexity with ACC',
		],
		bug: [
			'Submissions by Respondent now appear in folder ACC SUBMISSIONS/EVIDENCE',
			'File uploads by Respondent now have permissions Respondent (not Applicant)',
		],
	},
	{
		number: '2.6.0',
		date: '2021-12-09',
		new: [
			'Added custom functionality for handling Fire Emergency NZ cases',
			'Added refresh button to pagination section of many tables, so you dont need to refresh the entire app to get fresh data',
		],
		change: [
			'Changed responsive design strategy for certain pages, resulting in more readable content at all screen widths',
			'Updated sidebar components to be better, and now support animation',
			'Updated mobile navigation to be better',
		],
		bug: ['Fixed an issue where only the first 100 notes on a case were visible by adding pagination controls'],
	},
	{
		number: '2.6.1',
		date: '2021-12-15',
		new: [],
		change: [
			'Updated dashboard to work with any role, instead of having separate dashboards for each role',
			'Improved accuracy of certain dashboard card measurements',
		],
		bug: [],
	},
]

export default versions
