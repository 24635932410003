import { FC, useState } from 'react'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import { useForm } from 'react-hook-form'
import FileDropper from 'components/FileDropper'
import Actions from 'components/Actions'
import Table, { TableSchema } from 'components/Table'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/FormSelect'
import CustomRoleMenu, { defaultRoleItems, roleItems, isDefault } from 'components/CustomRoleMenu'
import Stack from 'components/Stack'
import { XIcon, CheckIcon, KeyIcon } from '@heroicons/react/outline'
import api from 'api'
import { humanFileSize, hearingDifferentiator, getFullName, isAcceptableFile } from 'utils/funcs'
import { CASE_FILE_CATEGORIES, CASE_PARTY_ROLE, CaseFileCategories } from 'types/enums'
import { CaseFileModel, HearingModel, CaseModel } from 'types/models'
import produce from 'immer'
import { Auth } from 'Auth'
import { warning, confirm, toast } from 'alerts'
import useData from 'hooks/useData'
import clsx from 'clsx'
import { isAxiosError, isNotNone, ModalResolveFn, RefetchFn } from 'types'
import TableFrame from 'components/TableFrame'
import Badge from 'components/Badge'

const abbr = {
	[CASE_PARTY_ROLE.Applicant]: 'AP',
	[CASE_PARTY_ROLE.Respondent]: 'RS',
	[CASE_PARTY_ROLE.Reviewer]: 'RV',
	[CASE_PARTY_ROLE.Mediator]: 'ME',
	[CASE_PARTY_ROLE.PeerReviewer]: 'PR',
	[CASE_PARTY_ROLE.CaseManager]: 'CM',
	// to change later to real enum number for FENZ
	[CASE_PARTY_ROLE.Adjudicator]: 'AJ',
	[CASE_PARTY_ROLE.Facilitator]: 'FC',
}

interface FileWithPermissions {
	file: File
	permissions: CASE_PARTY_ROLE[]
}

const allFilesAreDefault = (files: FileWithPermissions[]) =>
	files.reduce((pass, file) => pass && isDefault(file.permissions, defaultRoleItems), true)

const allFilesAreAll = (files: FileWithPermissions[]) =>
	files.reduce((pass, file) => pass && file.permissions.length === roleItems.length, true)

interface OpenFileUploadProps {
	title?: string
	parent: CaseFileModel
	refetch?: RefetchFn
}

const openFileUpload = ({ title = 'Upload Files', parent, refetch }: OpenFileUploadProps) => {
	return openModal<CaseFileModel>({
		title,
		size: 'lg',
		render: (close) => <FileUpload close={close} parent={parent} refetch={refetch} />,
	})
}

interface FileUploadProps {
	close: ModalResolveFn<CaseFileModel>
	parent: CaseFileModel
	refetch?: RefetchFn
}

interface FormData {
	category: CASE_FILE_CATEGORIES
	hearing: HearingModel | null
}

const FileUpload: FC<FileUploadProps> = ({ close, parent, refetch }) => {
	const { isSaving, setSaving } = useModalState()
	const [files, setFiles] = useState<FileWithPermissions[]>([])

	const fileSizeLimit = 150000000 // 150mb

	const { data: caseData } = useData<CaseModel>(`/Cases/${parent.case.id}`, {}, { suspense: true })

	const formContext = useForm<FormData>({
		defaultValues: {
			category: CASE_FILE_CATEGORIES.General,
			hearing: null,
		},
	})

	const category = formContext.watch('category')

	const handleSubmit = async (formData: FormData) => {
		if (!files.length) return

		if (files.find((x) => x.file.size > fileSizeLimit)) {
			warning({
				title: 'One of the files is too large',
				message: 'The max file size allowed is ' + humanFileSize(fileSizeLimit),
			})

			return false
		}

		const confirmed = await confirm({
			title: `Are you sure you would like to upload ${files[0].file.name} to case ${[
				caseData?.caseNumber,
				caseData?.fields?.find((x) => x.name === 'accClaimNumber')?.value,
				caseData?.applicant?.user ? getFullName(caseData.applicant.user) : null,
			]
				.filter(isNotNone)
				.join(', ')}?`,
			intent: 'primary',
		})

		if (!confirmed) return false

		setSaving(true)

		if (
			category === CASE_FILE_CATEGORIES.FullSettlement ||
			category === CASE_FILE_CATEGORIES.PartialSettlement ||
			category === CASE_FILE_CATEGORIES.NoSettlement
		) {
			await toast({
				title: 'Please ensure that you close the mediation tab with the appropriate ADR Type and Closing Category for billing purposes',
			})
		}

		try {
			let body = new FormData()

			body.append('case.id', parent.case.id)
			body.append('parentId', parent.id)
			body.append('caseFileCategory', String(formData.category || 0))

			if (formData.category !== CASE_FILE_CATEGORIES.General && formData.hearing) {
				body.append('caseHearing.id', formData.hearing.id)
			}

			let hadUnacceptable = 0

			for (let i = 0; i < files.length; i++) {
				let item = files[i]

				if (!isAcceptableFile(item.file)) {
					hadUnacceptable++
					continue
				}

				body.append('files', item.file)

				let permissions = []

				if (Auth.is('Admin', 'CaseManager', 'FENZAdmin')) {
					item.permissions.forEach((permission) => {
						permissions.push(permission)
					})
				} else {
					if (caseData?.reviewer?.user?.id === Auth.profile()?.id) {
						permissions.push(CASE_PARTY_ROLE.Reviewer)
					} else if (caseData?.mediator?.user?.id === Auth.profile()?.id) {
						permissions.push(CASE_PARTY_ROLE.Mediator)
					}
					//  else {
					// 	CasePartyRole.options.forEach((role) => {
					// 		permissions.push(role.value)
					// 	})
					// }
				}

				permissions.push(CASE_PARTY_ROLE.CaseManager)

				permissions.forEach((x) => body.append(`permissions[${i}]`, String(x)))

				body.append(
					'permissions[' + i + ']',
					new Blob([JSON.stringify(permissions)], {
						type: 'application/json',
					})
				)
			}

			// debug
			// req = FormData2JSON(body)
			// end debug

			if (hadUnacceptable) {
				warning({
					title: 'Invalid Files',
					message: 'At least one file with an invalid type was skipped',
				})

				if (files.length === hadUnacceptable) {
					setSaving(false)
					return
				}
			}

			const { data: newFile } = await api.post('/CaseFiles', body)

			if (refetch) refetch()

			close(newFile)

			toast({
				title: 'Files uploaded',
			})
		} catch (error) {
			if (isAxiosError(error)) {
				if (error.response?.data.errors) {
					warning({
						title: 'An error occurred',
						message: error.response?.data.errors,
					})

					setSaving(false)
					return
				}
			}

			api.handleError(error)

			setSaving(false)
		}
	}

	const schema: TableSchema<FileWithPermissions> = {
		cols: [
			{
				title: 'Name',
				value: (x) => <div className="min-w-0 w-full truncate">{x.file.name}</div>,
				truncate: true,
			},
			{
				title: 'Size',
				value: (x) => (
					<div className={clsx(x.file.size > fileSizeLimit && 'text-red-500')}>
						{humanFileSize(x.file.size)}
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
				title: <div className="w-full text-right pr-13">Permissions</div>,
				width: 'minmax(auto, max-content)',
				value: (x, i) => (
					<div className="flex justify-end w-full">
						<Actions
							actions={[
								{
									title: x.permissions.length !== 0 && (
										<div className="flex items-center -space-x-0.5">
											{x.permissions.map((p, i) => (
												<div
													key={i}
													className="rounded-full w-6 h-6 shadow-solid text-white dark:text-gray-700"
												>
													<div className="rounded-full w-6 h-6 flex items-center justify-center text-xs font-normal bg-primary-100 text-primary-600 dark:bg-primary-700 dark:text-primary-300 ring-1 ring-black/10">
														{abbr[p]}
													</div>
												</div>
											))}
										</div>
									),
									icon: x.permissions.length === 0 && (
										<div className="w-6 h-6 flex items-center justify-center">
											<KeyIcon className="w-5 h-5" />
										</div>
									),
									intent: 'borderless',
									size: 'xs',
									menu: (
										<CustomRoleMenu
											value={x.permissions}
											onChange={(permissions: CASE_PARTY_ROLE[]) =>
												setFiles(
													produce((draft) => {
														draft[i].permissions = permissions
													})
												)
											}
										/>
									),
								},
								{
									icon: <XIcon className="w-5 h-5" />,
									intent: 'menu',
									onClick: () => setFiles((f) => f.filter((y) => y.file.name !== x.file.name)),
								},
							]}
						/>
					</div>
				),
			},
		],
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<div className="flex justify-end">
						<Badge color="blue" icon={null}>
							Max file size: {humanFileSize(fileSizeLimit)}
						</Badge>
					</div>

					<FileDropper
						onChange={(newfiles) =>
							setFiles((f) => [
								...f,
								...newfiles
									.filter((x) => !f.find((y) => y.file.name === x.name))
									.map((x) => ({ file: x, permissions: [] })),
							])
						}
					/>

					{files.length > 0 && (
						<Stack>
							{Auth.is('Admin', 'CaseManager', 'FENZAdmin') && (
								<div className="flex justify-end items-center space-x-4">
									<div className="text-sm font-medium">Permissions:</div>

									<Actions
										actions={[
											{
												title: 'Default',
												intent: 'secondary',
												icon: allFilesAreDefault(files) ? (
													<XIcon className="w-5 h-5" />
												) : (
													<CheckIcon className="w-5 h-5" />
												),
												size: 'xs',
												onClick: () =>
													setFiles((f) =>
														f.map((x) => ({
															file: x.file,
															permissions: allFilesAreDefault(files)
																? []
																: defaultRoleItems,
														}))
													),
											},
											{
												title: 'All',
												intent: 'secondary',
												icon: allFilesAreAll(files) ? (
													<XIcon className="w-5 h-5" />
												) : (
													<CheckIcon className="w-5 h-5" />
												),
												size: 'xs',
												onClick: () =>
													setFiles((f) =>
														f.map((x) => ({
															file: x.file,
															permissions: allFilesAreAll(files)
																? []
																: roleItems.map((y) => y.value),
														}))
													),
											},
										]}
									/>
								</div>
							)}

							<TableFrame>
								<Table items={files} schema={schema} className="-my-px" />
							</TableFrame>

							<div className="flex space-x-6">
								<div className={clsx(category ? 'w-1/2' : 'w-full')}>
									<FormSelect
										label="File Category"
										name="category"
										hideOptional
										options={CaseFileCategories.options.filter((x) => {
											if (!Auth.is('Reviewer', 'Mediator', 'Adjudicator', 'Facilitator')) {
												return true
											}

											if (
												Auth.is('Reviewer', 'Mediator', 'Adjudicator', 'Facilitator') &&
												(x.value === CASE_FILE_CATEGORIES.General ||
													x.value === CASE_FILE_CATEGORIES.DraftDecision ||
													x.value === CASE_FILE_CATEGORIES.DraftMinute)
											) {
												return true
											}
											return false
										})}
									/>
								</div>

								{category > 0 && (
									<div className="w-1/2">
										<FormSelect
											label="Case Hearing"
											name="hearing"
											options={
												caseData
													? caseData.hearings.map((x) => ({
															label: hearingDifferentiator(x, caseData),
															value: x,
													  }))
													: []
											}
										/>
									</div>
								)}
							</div>
						</Stack>
					)}
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Upload',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openFileUpload
