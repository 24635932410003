import { ReactNode } from 'react'
import { FormProvider, UseFormMethods } from 'react-hook-form'
import clsx, { ClassValue } from 'clsx'

interface FormProps<T> {
	context: UseFormMethods<T>
	onSubmit?: (x: any) => void | Promise<any>
	className?: ClassValue
	children: ReactNode
}

const Form = <T,>({ context, onSubmit, className, children }: FormProps<T>) => {
	return (
		<FormProvider {...context}>
			<form onSubmit={onSubmit && context.handleSubmit(onSubmit)} className={clsx(className)}>
				{children}
			</form>
		</FormProvider>
	)
}

export default Form
