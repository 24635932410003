import { FC } from 'react'
import DataListColumns from 'components/DataListColumns'
import Stack from 'components/Stack'
import SectionHeader from 'components/SectionHeader'
import { CaseModel } from 'types/models'
import { format } from 'date-fns'
import { ensureDate } from 'utils/funcs'
import { CaseStatus, FORUM_TYPE, HearingType, HEARING_TYPE } from 'types/enums'

interface BillingInfoProps {
	caseData: CaseModel
}

const BillingInfo: FC<BillingInfoProps> = ({ caseData }) => {
	return (
		<Stack space={6} dividers>
			<Stack space={6} className="px-6">
				<DataListColumns
					items={[
						{
							title: 'Complex Case',
							value: caseData.fields?.find((y) => y.name === 'complex')?.value || '-',
						},
						{
							title: 'Decision Date',
							value: caseData.decisionDate
								? format(ensureDate(caseData.decisionDate), 'dd/MM/yyyy')
								: '-',
						},
						{
							title: 'ICRA Case Ref',
							value: caseData.caseNumber,
						},
						{
							title: 'Outcome',
							value: CaseStatus.readable(caseData.status),
						},
						{
							title: 'ACC Claim #',
							value: caseData.fields?.find((y) => y.name === 'accClaimNumber')?.value || '-',
						},
						{
							title: 'Outcome Date',
							value: caseData.completedDate
								? format(ensureDate(caseData.completedDate), 'dd/MM/yyyy')
								: '-',
						},
						{
							title: 'Care Indicator',
							value: caseData.fields?.find((y) => y.name === 'careIndication')?.value || '-',
						},
					]}
				/>
			</Stack>

			<Stack space={6} className="px-6">
				<SectionHeader title="Hearings" />

				{caseData.hearings.map((hearing) => (
					<div
						key={hearing.id}
						className="p-4 rounded-md border border-gray-200 bg-gray-50 dark:bg-gray-750 dark:border-gray-600"
					>
						<DataListColumns
							items={[
								{
									title: 'Review #',
									value: hearing.reviewNumber,
								},
								{
									title: 'Lodgement Date',
									value: hearing.lodgementDate
										? format(ensureDate(hearing.lodgementDate), 'dd/MM/yyyy')
										: '-',
								},
								{
									title: 'Claim',
									value: '-',
								},
								{
									title: `${HearingType.readable(hearing.hearingType)} Date`,
									value: hearing.startDate
										? format(ensureDate(hearing.startDate), 'dd/MM/yyyy')
										: '-',
								},
								{
									title: 'Type',
									value:
										hearing.hearingType === HEARING_TYPE.Review
											? 'Review'
											: hearing.hearingType === HEARING_TYPE.Mediation
											? 'ADR'
											: 'Hybrid',
								},
								{
									title: `${HearingType.readable(hearing.hearingType)} Time`,
									value: `${format(ensureDate(hearing.startDate || ''), 'H:mm')} - ${format(
										ensureDate(hearing.endDate || ''),
										'H:mm'
									)}`,
								},
								hearing.forumType && {
									title:
										hearing.forumType === FORUM_TYPE.FaceToFace
											? 'In-Person'
											: hearing.forumType === FORUM_TYPE.Phone
											? 'Phone'
											: hearing.forumType === FORUM_TYPE.Video
											? 'Meeting'
											: '',
									value:
										hearing.forumType === FORUM_TYPE.FaceToFace
											? hearing.venue
											: hearing.forumType === FORUM_TYPE.Phone
											? 'Yes'
											: hearing.forumType === FORUM_TYPE.Video
											? hearing.zoomMeeting
											: '',
								},
							]}
						/>
					</div>
				))}
			</Stack>
		</Stack>
	)
}

export default BillingInfo
