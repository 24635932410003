import { FC, ReactNode } from 'react'

interface PanelProps {
	title: ReactNode
	subtitle: ReactNode
	content: ReactNode
}

const Panel: FC<PanelProps> = ({ title, subtitle, content }) => {
	return (
		<div className="sm:flex sm:items-start sm:justify-between px-6">
			<div className="space-y-2 flex-1">
				<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">{title}</h3>
				<div className="max-w-xl text-sm leading-5 text-gray-500 dark:text-gray-300">{subtitle}</div>
			</div>
			<div className="mt-5 sm:mt-0 sm:ml-6 sm:shrink-0 sm:flex sm:items-center">{content}</div>
		</div>
	)
}

export default Panel
