import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { AdjournmentModel, HearingModel } from 'types/models'
import { ModalResolveFn, RefetchFn } from 'types'
import { Duration, FORUM_TYPE, ForumType, HEARING_TYPE, TimeSlots } from 'types/enums'
import FormSelect from 'components/Form/FormSelect'
import AutoHeight from 'components/AutoHeight'
import FormToggle from 'components/Form/FormToggle'
import { ensureDate, getDateFromTime, nzDate } from 'utils/funcs'
import {
	addDays,
	addMinutes,
	differenceInMinutes,
	endOfDay,
	format,
	getHours,
	getMinutes,
	parseISO,
	setHours,
	setMinutes,
	startOfDay,
	subDays,
	subMinutes,
} from 'date-fns'
import FormDate from 'components/Form/FormDate'
import Stack from 'components/Stack'
import * as Sentry from '@sentry/react'
import { gql, useQuery } from '@apollo/client'
import { GQLConnection, GQLVenueType } from 'types/gql'
import { toast } from 'components/toast'

const venuesQuery = gql`
	query {
		Venues {
			items {
				id
				name
				address
			}
		}
	}
`

const endOfToday = getDateFromTime('17:00')

const requestedBys = [
	'Advocate',
	'Applicant',
	'ACC',
	'Reviewer',
	'ACC and Advocate/Applicant',
	'Adjudicator',
	'Facilitator',
].map((x) => ({
	label: x,
	value: x,
}))

const otherReason = 'Other'

const reasons = [
	'Additional Evidence Required by ACC',
	'Additional Evidence Required by Customer',
	'Additional Evidence Required by ACC and Customer',
	'Delay in Evidence from ACC',
	'Delay in Evidence from Customer',
	'ACC not available',
	'Customer not available',
	'Reviewer not available',
	'Covid-19',
	'Joint adjournment request ACC and Customer',
	'URGENT SET DOWN CASE',
	otherReason,
].map((x) => ({ label: x, value: x }))

const grantOptions = [
	{ value: 1, label: 'Granted' },
	{ value: 2, label: 'Declined' },
]

interface OpenAdjournHearingProps {
	hearing: HearingModel
	refetch: RefetchFn
}

const openAdjournHearing = (props: OpenAdjournHearingProps) => {
	return openModal<AdjournmentModel>({
		title: 'Adjourn Review Hearing',
		render: (close) => <CreateFolder close={close} {...props} />,
	})
}

interface FormData {
	postponed: boolean
	date: Date | null
	time: string | null
	duration: number
	forumType: FORUM_TYPE
	venue: string
	venueId: string
	zoomMeeting: string
	requestedBy: string
	reason: string
	customReason: string
	adjournmentStatus: string | null
}

const CreateFolder: FC<OpenAdjournHearingProps & { close: ModalResolveFn<AdjournmentModel> }> = ({
	hearing,
	refetch,
	close,
}) => {
	const { isSaving, setSaving } = useModalState()

	const { data: venues } = useQuery<{ Venues: GQLConnection<GQLVenueType> }>(venuesQuery)

	const formContext = useForm<FormData>({
		defaultValues: {
			date: hearing.startDate ? startOfDay(ensureDate(hearing.startDate)) : null,
			time: hearing?.startDate
				? TimeSlots.find(
						(x) => x.from24 === format(nzDate(parseISO(hearing?.startDate || '')), 'HH:mm:ss').toLowerCase()
				  )?.from
				: null,
			duration: differenceInMinutes(parseISO(hearing?.endDate || ''), parseISO(hearing?.startDate || '')),
			forumType: hearing.forumType,
			venue: hearing.venue || '',
			venueId: hearing?.venue && !hearing?.venueId ? 'custom' : hearing?.venueId || '',
			zoomMeeting: hearing.zoomMeeting || '',
			postponed: false,
			requestedBy: '',
			reason: '',
			customReason: '',
			adjournmentStatus: null,
		},
	})

	const reason = formContext.watch('reason')
	const postponed = formContext.watch('postponed')
	const time = formContext.watch('time')
	const duration = formContext.watch('duration')
	const startDate = formContext.watch('date')
	const forumType = formContext.watch('forumType')
	const venueId = formContext.watch('venueId')

	// Times
	let times = useMemo(() => {
		if (!duration) {
			return TimeSlots
		} else {
			let endPoint = +subMinutes(endOfToday, duration)
			return TimeSlots.filter((x) => +getDateFromTime(x.from24) <= endPoint)
		}
	}, [duration])

	// unset time if it becomes invalid
	useEffect(() => {
		if (time && !times.find((x) => x.from === time)) {
			formContext.setValue('time', null)
			formContext.setValue('duration', 0)
		}
	}, [time, times, formContext])

	const [reported, setReported] = useState(false)

	// Durations
	let durations = useMemo(() => {
		let list: { label: string; value: number }[] = []

		if (+hearing.hearingType === HEARING_TYPE.CMC) list = Duration.CMC.options
		if (+hearing.hearingType === HEARING_TYPE.Review) list = Duration.Review.options
		if (+hearing.hearingType === HEARING_TYPE.Mediation) list = Duration.Mediation.options

		list = list.filter((x) => {
			if (!time) return true
			let from24 = TimeSlots.find((x) => x.from === time)?.from24
			if (from24) {
				let endPoint = addMinutes(getDateFromTime(from24), +x.value)
				return endPoint.valueOf() <= endOfToday.valueOf()
			}
			return true
		})

		if (!reported) {
			if (list.length === 0) {
				Sentry.captureMessage('Missing Duration', (scope) => {
					scope.setExtra('hearing', hearing)
					scope.setExtra('now', new Date().toISOString())
					scope.setExtra('time', time)
					scope.setExtra('endOfToday', endOfToday.toISOString())
					scope.setExtra('list', Duration.CMC.options)
					return scope
				})
				setReported(true)
			}
		}

		return list
	}, [time, hearing, reported])

	// unset the duration if it disappears from the list
	useEffect(() => {
		if (duration && !durations?.find((x) => x.value === duration)) {
			formContext.setValue('duration', 0)
		}
	}, [durations, duration, formContext])

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			let startDate = null
			let endDate = null

			if (formData.forumType === FORUM_TYPE.OnThePapers && formData.date) {
				startDate = endOfDay(formData.date)
				endDate = endOfDay(formData.date)
			} else if (formData.forumType === FORUM_TYPE.OnThePapers && hearing.startDate) {
				startDate = endOfDay(parseISO(hearing.startDate))
				endDate = endOfDay(parseISO(hearing.startDate))
			} else if (formData.date && formData.time && !formData.postponed) {
				let fromDate = new Date(`1970-01-01 ${formData.time}`)
				startDate = setMinutes(setHours(formData.date, getHours(fromDate)), getMinutes(fromDate))
				endDate = addMinutes(startDate, formData.duration)
			} else {
				if (hearing.startDate) {
					startDate = new Date(hearing.startDate)
				}
				if (hearing.endDate) {
					endDate = new Date(hearing.endDate)
				}
			}

			let decisionDate = startDate ? addDays(startDate, 28) : null

			await api.put(`/CaseHearings/${hearing.id}`, {
				...hearing,
				...formData,
				...(formData.postponed
					? {
							isPostponed: true,
							decisionDate: null,
							peerReviewDate: null,
					  }
					: {
							startDate,
							endDate,
							isPostponed: false,
							decisionDate,
							peerReviewDate:
								hearing.hearingType === HEARING_TYPE.Review && decisionDate
									? subDays(decisionDate, 10)
									: null,
					  }),
				hearingVenue: !formData.venueId || formData.venueId === 'custom' ? undefined : { id: formData.venueId },
			})

			const { data: adjournment } = await api.post('/Adjournments', {
				reasonForAdjournment: formData.reason === otherReason ? formData.customReason : formData.reason,
				status: formData.adjournmentStatus,
				requestedBy: formData.requestedBy,
				caseHearingId: hearing.id,
			})

			await refetch()

			toast({ title: 'Hearing Adjourned' })

			close(adjournment as AdjournmentModel)
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormToggle name="postponed" label="Postponed (New date is unknown)" />

					<AutoHeight show={!postponed}>
						<Stack>
							<FormDate
								name="date"
								label="Date"
								className="flex-1"
								validations={{ required: 'Date is required' }}
								defaultValue={hearing?.startDate || null}
								disableHolidays
							/>

							<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
								<div className="flex space-x-6">
									<FormSelect
										name="time"
										label="Time"
										options={times.map((x) => ({ label: x.from, value: x.from }))}
										disabled={!startDate}
										className="flex-1"
										validations={{ required: 'Time is required' }}
									/>

									<FormSelect
										name="duration"
										label="Duration"
										options={durations || []}
										disabled={!time}
										className="flex-1"
										validations={{ required: 'Duration is required' }}
									/>
								</div>
							</AutoHeight>

							<FormSelect
								name="forumType"
								label="Forum"
								options={ForumType.options}
								validations={{
									required: 'Forum is required',
								}}
							/>

							<AutoHeight show={forumType === FORUM_TYPE.FaceToFace}>
								<FormSelect
									name="venueId"
									label="Venue"
									options={
										venues?.Venues.items?.concat({ id: 'custom', name: 'Custom' })?.map((x) => ({
											value: x.id,
											label: (
												<div>
													<div>{x.name}</div>
													{x.address && <div className="opacity-50 text-sm">{x.address}</div>}
												</div>
											),
										})) || []
									}
									validations={{ required: 'Venue is required' }}
									defaultValue={hearing?.venueId || ''}
								/>
							</AutoHeight>

							<AutoHeight show={forumType === FORUM_TYPE.FaceToFace && venueId === 'custom'}>
								<FormInput
									name="venue"
									label="Venue Address"
									validations={{ required: 'Venue is required' }}
									defaultValue={hearing?.venue || ''}
								/>
							</AutoHeight>

							<AutoHeight show={forumType !== FORUM_TYPE.OnThePapers}>
								<FormInput
									name="zoomMeeting"
									label="Meeting Details"
									as="textarea"
									validations={
										forumType !== FORUM_TYPE.FaceToFace
											? { required: 'Meeting Details is required' }
											: undefined
									}
								/>
							</AutoHeight>
						</Stack>
					</AutoHeight>

					<FormSelect
						name="requestedBy"
						label="Requested By"
						options={requestedBys}
						validations={{ required: 'Requested By is required' }}
						autoFocus
					/>

					<FormSelect
						name="reason"
						label="Reason for Adjournment"
						options={reasons}
						validations={{ required: 'Reason for Adjournment is required' }}
					/>

					<AutoHeight show={reason === otherReason}>
						<FormInput
							name="customReason"
							label="Please Specify"
							validations={{ required: 'Please Specify is required' }}
						/>
					</AutoHeight>

					<FormSelect
						name="adjournmentStatus"
						label="Granted / Declined"
						options={grantOptions}
						validations={{ required: 'Granted / Declined is required' }}
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openAdjournHearing
