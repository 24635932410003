export enum CASE_TYPE {
	Mediation = 1,
	Review = 2,
	Hybrid = 3,
}

export const caseType = {
	1: 'Mediation',
	2: 'Review',
	3: 'Hybrid',
}

export const CaseType = {
	readable: (x: CASE_TYPE) => caseType[x],
	getId: (name: string) => +Object.fromEntries(Object.entries(caseType).map(([a, b]) => [b, a]))[name],
	options: Object.entries(caseType).map(([key, val]) => ({ label: val, value: +key })),
}
