import { FC } from 'react'
import Card from 'components/Card'
import PageHeading from 'components/PageHeading'
import PageContent from 'components/PageContent'
import Actions from 'components/Actions'
import api from 'api'
import { success, warning } from 'alerts'

const Test2: FC = () => {
	const xeroLogin = async () => {
		const { data } = await api.get(`/Xero/login-uri?callbackUri=${`${window.location.origin}/xero`}`)
		// const { data } = await api.get(`/Xero/login-uri?callbackUri=${'https://nzdrc-icra-dev.azurewebsites.net/xero'}`)

		window.open(String(data))
	}

	const checkAuthorized = async () => {
		const { data } = await api.get('/Xero/authorized')

		if (data) {
			success({ title: 'Xero is authorized' })
		} else {
			warning({ title: 'Xero not authorized' })
		}
	}

	return (
		<>
			<PageHeading title="Xero Test" />

			<PageContent>
				<Card>
					<Actions
						actions={[
							{
								title: 'Xero Login',
								onClick: () => xeroLogin(),
							},
							{
								title: 'Check Authorized',
								onClick: () => checkAuthorized(),
							},
						]}
					/>
				</Card>
			</PageContent>
		</>
	)
}

export default Test2
