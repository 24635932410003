import { useEffect } from 'react'
import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router-dom'

const container: { navigate: NavigateFunction } = {
	navigate: () => {},
}

const NavigateHoist = () => {
	const navigate = useNavigate()

	useEffect(() => {
		container.navigate = navigate
	}, [navigate])

	return null
}

export default NavigateHoist

export const navigate = (to: To, options?: NavigateOptions | undefined) => {
	// console.log(options)
	container.navigate(to, options)
}
