import { ChangeEvent, FC } from 'react'
import Stack from 'components/Stack'
import Button from 'components/Button'
import { uniq } from 'ramda'
import Checkbox from 'components/Checkbox'
import { XIcon, CheckIcon } from '@heroicons/react/outline'
import { CasePartyRole, CASE_PARTY_ROLE } from 'types/enums'

type Option<T> = { label: string; value: T }

export const roleItems = CasePartyRole.options.filter((x) => x.label !== 'Case Manager')

export const defaultRoleItems = roleItems.filter((x) => x.label !== 'Peer Reviewer').map((x) => x.value)

export const isDefault = (value: CASE_PARTY_ROLE[], defaultSet: CASE_PARTY_ROLE[]) =>
	value.length === defaultSet.length && value.reduce((pass, x) => pass && defaultSet.indexOf(x) > -1, true)

interface CustomRoleMenuProps {
	items?: Option<CASE_PARTY_ROLE>[]
	defaultSet?: CASE_PARTY_ROLE[]
	value: CASE_PARTY_ROLE[]
	onChange: (x: CASE_PARTY_ROLE[]) => void
}

const CustomRoleMenu: FC<CustomRoleMenuProps> = ({
	items = roleItems,
	defaultSet = defaultRoleItems,
	value,
	onChange,
}) => {
	const checkOne = (item: CASE_PARTY_ROLE) => (e: ChangeEvent<HTMLInputElement>) =>
		onChange(e.target.checked ? uniq([...value, item]) : value.filter((x) => x !== item))

	const checkAll = () => onChange(value.length === items.length ? [] : items.map((x) => x.value))

	const checkDefault = () => onChange(isDefault(value, defaultSet) ? [] : defaultSet)

	const checkbox = (i: number, item: Option<CASE_PARTY_ROLE>) => {
		return (
			<Checkbox
				key={i}
				label={item.label}
				checked={value.indexOf(item.value) > -1}
				onChange={checkOne(item.value)}
			/>
		)
	}

	return (
		<div className="bg-white dark:bg-gray-700 rounded-md">
			<div className="flex flex-col">
				<div className="flex space-x-4 p-4">
					<div className="w-1/2">
						<Stack>{items.map((item, i) => i % 2 === 0 && checkbox(i, item))}</Stack>
					</div>

					<div className="w-1/2">
						<Stack>{items.map((item, i) => i % 2 === 1 && checkbox(i, item))}</Stack>
					</div>
				</div>

				<div className="flex justify-end space-x-4 p-4 border-t border-gray-200 bg-gray-100 dark:border-gray-600 dark:bg-gray-750 rounded-br-lg rounded-bl-lg">
					{defaultSet && (
						<Button intent="secondary" size="xs" onClick={checkDefault}>
							{isDefault(value, defaultSet) ? (
								<XIcon className="w-4 h-4 mr-2" />
							) : (
								<CheckIcon className="w-4 h-4 mr-2" />
							)}
							Default
						</Button>
					)}

					<Button intent="secondary" size="xs" onClick={checkAll}>
						{value.length === items.length ? (
							<XIcon className="w-4 h-4 mr-2" />
						) : (
							<CheckIcon className="w-4 h-4 mr-2" />
						)}
						All
					</Button>
				</div>
			</div>
		</div>
	)
}

export default CustomRoleMenu
