import { ReactNode } from 'react'
import { UserModel, CaseModel, CaseFieldModel, HearingModel } from 'types/models'
import { AxiosError } from 'axios'
import { warning } from 'alerts'
import { curry } from 'ramda'
import { isNotNone, None } from 'types'
import processString from 'react-process-string'
import {
	differenceInDays,
	format,
	formatRelative,
	parseISO,
	isSameDay,
	subSeconds,
	addSeconds,
	setMinutes,
	setHours,
	setSeconds,
	setMilliseconds,
} from 'date-fns'
import { GQLCaseType, GQLEventType, GQLUserType } from 'types/gql'
import { ACCOrgId, complexClaimTypes, holidays } from './constants'
import { env } from './config'
import * as Sentry from '@sentry/react'
import { ADR_TYPE, CASE_COMPLEXITY, HearingType, HEARING_TYPE, TimeSlot } from 'types/enums'
import { utcToZonedTime, format as formatTZ, zonedTimeToUtc } from 'date-fns-tz'
import api from 'api'
import { TableSchema, isVisible } from 'components/Table'
import { onlyText } from 'react-children-utilities'
import FileSaver from 'file-saver'
import mime from 'mime-types'
import { toast } from 'components/toast'

export const nzDate = (x: Date) => utcToZonedTime(x, 'Pacific/Auckland')
export const isNZ = () => formatTZ(new Date(), 'zzzz').indexOf('New Zealand') > -1

export const toNZDate = (date: Date) => {
	const utc = zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
	return utcToZonedTime(utc, 'Pacific/Auckland')
}

export const processLinks = (x: string) => {
	let config = [
		{
			regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
			fn: (key: number, result: string[]) => (
				<span key={key}>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
						className="anchor"
					>
						{result[1]}://{result[2]}.{result[3]}
						{result[4]}
					</a>
					{result[5]}
				</span>
			),
		},
		{
			regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
			fn: (key: number, result: string[]) => (
				<span key={key}>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`http://${result[1]}.${result[2]}${result[3]}`}
						className="anchor"
					>
						http://{result[1]}.{result[2]}
						{result[3]}
					</a>
					{result[4]}
				</span>
			),
		},
	]

	return processString(config)(x)
}

interface ClassFixType {
	pattern: RegExp
	defaultValue: string
}

export const smartReplaceClasses = (className: string, fixes: ClassFixType[]) => {
	let names = className.split(' ')

	for (let i = 0; i < fixes.length; i++) {
		let fix = fixes[i]
		if (!names.find((x) => fix.pattern.test(x))) names.push(fix.defaultValue)
	}

	return names.join(' ')
}

export const getFullName = (x?: UserModel, fallback: string = '') =>
	x ? [x.firstName, x.middleName, x.lastName].filter(isNotNone).join(' ') : fallback

export const getCaseField = curry((name: string, item?: CaseModel) => item?.fields?.find((x) => x.name === name)?.value)

export const handleError = (error: AxiosError<any>) => {
	let message: ReactNode = null

	if (error?.response?.data.title) {
		message = <p>{error.response.data.title}</p>
	}

	if (error?.response?.data.errors) {
		// @ts-ignore
		message = Object.values(error.response.data.errors).map((x: string, i: number) => <p key={i}>{x}</p>)
	}

	warning({ title: 'An error occurred', message })
}

export const enumToOption = (item: any) => {
	return Object.keys(item)
		.filter((x) => isNaN(+x))
		.map((label) => ({ label, value: item[label] }))
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const humanFileSize = (bytes: number, si = true) => {
	var thresh = si ? 1000 : 1024
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}
	var units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	var u = -1
	do {
		bytes /= thresh
		++u
	} while (Math.abs(bytes) >= thresh && u < units.length - 1)
	return bytes.toFixed(1) + ' ' + units[u]
}

export const getState = <T,>(location?: any) => location?.state as T | null

export const queryString = (obj: Object) => {
	return Object.entries(obj)
		.filter(([_, val]) => val !== undefined)
		.map(([key, val]) => [key, encodeURIComponent(val)].join('='))
		.join('&')
}

export const getDateFromTime = (time: string, rootDate: Date = new Date()) => {
	let [hour, min] = time.split(':')

	// new Date(`${rootDate ? format(rootDate, 'yyyy-MM-dd') : '1970-01-01'} ` + time)
	return setMilliseconds(setSeconds(setHours(setMinutes(rootDate, +min), +hour), 0), 0)
}

// https://stackoverflow.com/questions/21327371/get-timezone-offset-from-timezone-name-using-javascript
export const getTzOffset = (name: string) => {
	var dateText = Intl.DateTimeFormat([], { timeZone: name, timeZoneName: 'short' }).format(new Date())
	var timezoneString = dateText.split(' ')[1].slice(3)
	var timezoneOffset = parseInt(timezoneString.split(':')[0]) * 60 //?
	if (timezoneString.includes(':')) {
		timezoneOffset = timezoneOffset + parseInt(timezoneString.split(':')[1])
	}
	return timezoneOffset
}

export const blobUrl = (blob: Blob) => {
	return window.URL.createObjectURL(blob)
}

export const getCaseRole = (caseData: CaseModel | GQLCaseType, user: UserModel | GQLUserType) => {
	const roles = []

	if (caseData.caseManager?.user?.id.toLowerCase() === user.id.toLowerCase()) {
		roles.push('Case Manager')
	}
	if (caseData.applicant?.user?.id.toLowerCase() === user.id.toLowerCase()) {
		roles.push('Applicant')
	}
	if (
		caseData.applicant?.parties &&
		// @ts-ignore
		caseData.applicant?.parties.find((x: UserModel | GQLUserType) => x.id.toLowerCase() === user.id.toLowerCase())
	) {
		roles.push('Advocate')
	}
	if (caseData.respondent?.user?.id.toLowerCase() === user.id.toLowerCase()) {
		roles.push(caseData.organization?.id === ACCOrgId ? 'ACC Review Specialist' : 'Respondent')
	}
	if (
		caseData.respondent?.parties &&
		// @ts-ignore
		caseData.respondent?.parties?.find((x: UserModel | GQLUserType) => x.id.toLowerCase() === user.id.toLowerCase())
	) {
		roles.push('Respondent Support')
	}
	if (caseData.reviewer?.user?.id.toLowerCase() === user.id.toLowerCase()) {
		roles.push('Reviewer')
	}
	if (
		caseData.reviewer?.parties &&
		// @ts-ignore
		caseData.reviewer?.parties.find((x: UserModel | GQLUserType) => x.id.toLowerCase() === user.id.toLowerCase())
	) {
		roles.push('Reviewer Support')
	}
	if (caseData.mediator?.user?.id.toLowerCase() === user.id.toLowerCase()) {
		roles.push('Mediator')
	}
	if (
		caseData.mediator?.parties &&
		// @ts-ignore
		caseData.mediator?.parties.find((x: UserModel | GQLUserType) => x.id.toLowerCase() === user.id.toLowerCase())
	) {
		roles.push('Mediator Support')
	}
	if (caseData.peerReviewer?.user?.id.toLowerCase() === user.id.toLowerCase()) {
		roles.push('Peer Reviewer')
	}
	if (
		caseData.peerReviewer?.parties &&
		// @ts-ignore
		caseData.peerReviewer?.parties.find(
			(x: UserModel | GQLUserType) => x.id.toLowerCase() === user.id.toLowerCase()
		)
	) {
		roles.push('Peer Reviewer')
	}

	return roles.join(', ')
}

export const series = async <T,>(items: T[], fn: (x: T) => Promise<any>) => {
	let output = []

	for (let i = 0; i < items.length; i++) {
		output.push(await fn(items[i]))
	}

	return output
}

export function isValidDate(d: Date) {
	return !isNaN(d.valueOf())
}

export const getChannelName = (data: { case: CaseModel; user1: UserModel; user2: UserModel }) => {
	let str = ''
	if (data.case) str += `case=${data.case};`
	let userIDs = [data.user1, data.user2].sort()
	str += `u=${userIDs[0]},${userIDs[1]};`
	return str.substr(0, str.length - 1)
}

export const last = <T,>(arr: T[]): T => arr[arr.length - 1]

export const detectIE = () => {
	var ua = window.navigator.userAgent

	var msie = ua.indexOf('MSIE ')
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
	}

	var trident = ua.indexOf('Trident/')
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:')
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
	}

	// other browser
	return false
}

const errorIsStatus = (error: AxiosError<any>, status: number) => {
	return error.response && error.response.status === status
}

export const getErrorMessage = (error: AxiosError<any>) => {
	let data = error ? (error.response ? error.response.data : null) : null
	let message = []

	if (errorIsStatus(error, 404)) {
		message.push('Not found')
	}

	if (data) {
		if (data.title) {
			message.push(data.title)
		}

		if (data.errors instanceof Array) {
			message.push(data.errors[0].message.split(', ')[0])
		} else if (data.errors && typeof data.errors === 'object') {
			Object.values(data.errors).forEach((msg) => {
				message.push(msg)
			})
		}
	}

	return message.join('\n')
}

export const reportError = (request: any, error: AxiosError) => {
	Sentry.withScope((scope) => {
		scope.setExtra('request', JSON.stringify(request, null, 4))
		if (error && error.response) {
			scope.setExtra('response', {
				data: JSON.stringify(error.response.data, null, 4),
				status: error.response.status,
				statusText: error.response.statusText,
				headers: error.response.headers,
			})
		} else {
			scope.setExtra('error', error)
		}

		Sentry.captureException(new Error('Request Failure'))
	})

	if (env === 'dev') {
		console.group('Captured Error')
		console.log('request', request)
		if (error && error.response) {
			console.log(
				'response',
				JSON.stringify(
					{
						data: error.response.data,
						status: error.response.status,
						statusText: error.response.statusText,
						headers: error.response.headers,
					},
					null,
					4
				)
			)
		} else {
			console.log('error', error)
		}
		console.groupEnd()
	}
}

// this thing is insane, type it later
export const includer = (data: any, map: any): any => {
	if (map instanceof Array) {
		return data.reduce((res: any, val: any, i: any) => {
			return map[i] ? [...res, includer(val, map[i])] : res
		}, [])
	} else if (typeof map === 'object') {
		return Object.keys(map).reduce((res, key) => {
			return map[key] ? { ...res, [key]: includer(data[key], map[key]) } : res
		}, {})
	} else if (typeof map === 'boolean') {
		if (map) return data
	}
}

export const fieldsBag = (fields: CaseFieldModel[]): { [x: string]: CaseFieldModel } => {
	return fields.reduce((acc, field) => {
		return { ...acc, [field.name]: field }
	}, {})
}

export const hearingDifferentiator = (hearing: HearingModel, caseData: CaseModel) => {
	const list = caseData.hearings.filter((x) => x.hearingType === hearing.hearingType)

	let num = list.findIndex((x) => x.id === hearing.id) + 1

	return `${HearingType.readable(hearing.hearingType)} #${num} (${format(
		parseISO(hearing.startDate || ''),
		'dd/MM/yyyy'
	)})`
}

export const parseQueryCode = (str: string): { [x: string]: string } => {
	return str
		.replace('?', '')
		.replace('&amp;', '&')
		.split('&')
		.reduce((a, x) => {
			let [key, val] = x.split('=')
			return { ...a, [key]: val }
		}, {})
}

export const ensureDate = (val: string | Date | None): Date => (val instanceof Date ? val : new Date(val || ''))

export const split = (str: string) => {
	return str.replace(/\[/g, '.').replace(/\]/g, '').split('.')
}

export const getGSTfromIncl = (x: number) => (x * 3) / 23

export const createNumberedFileName = (filename: string) => {
	const reg = /\(([\d]+)\)$/
	const match = filename.match(reg)
	if (!match) return `${filename} (2)`
	return filename.replace(reg, `(${+match[1] + 1})`)
}

export const fixedFormatRelative = (x: string) => {
	let target = parseISO(x)

	let diff = differenceInDays(new Date(), target)

	if (diff >= 6) {
		return format(target, 'dd/MM/yyyy')
	} else {
		return formatRelative(target, new Date())
	}
}

export const getReservedDate = (d: Date) => {
	return holidays.find((x) => isSameDay(x.date, d))?.name
}

export const isComplexIssueCode = (issueCode: string) => {
	return complexClaimTypes.find((x) => issueCode?.indexOf(`${x}:`) === 0)
}

export const isComplexCase = (caseData: CaseModel) => {
	if (caseData.reviews.length >= 2) {
		return true
	}

	if (caseData.reviews.find((x) => isComplexIssueCode(x.issueCode))) {
		return true
	}

	if (caseData.fields?.find((x) => x.name === 'complex')?.value === CASE_COMPLEXITY.Complex) {
		return true
	}

	return false
}

export const isComplexReview = (review: { issueCode?: string }) => {
	return review.issueCode && isComplexIssueCode(review.issueCode)
}

export const updateCaseComplexity = async (caseData: CaseModel, complexity: CASE_COMPLEXITY) => {
	let field = caseData?.fields?.find((x) => x.name === 'complex')

	if (field) {
		await api.put(`/CaseFields/${field.id}`, {
			...field,
			value: complexity,
		})
	}
}

export const updateADRType = async (caseData: CaseModel, adrType: ADR_TYPE) => {
	let mediations = caseData?.hearings?.filter((x) => x.hearingType === HEARING_TYPE.Mediation)

	for (let i = 0; i < mediations.length; i++) {
		let mediation = mediations[i]

		await api.put(`/CaseHearings/${mediation.id}`, {
			...mediation,
			adrType,
		})
	}
}

export const copyTabularData = async <T,>(schema: TableSchema<T>, items: T[]) => {
	const rows: string[] = []

	const cols = schema.cols.filter(isNotNone).filter(isVisible([undefined, 'all', 'copy-only']))

	rows.push(cols.map((x) => onlyText(x.title)).join('\t'))

	items.forEach((item) => {
		rows.push(cols.map((x, i) => onlyText(!!x.copyValue ? x.copyValue(item, i) : x.value(item, i))).join('\t'))
	})

	// it doesn't like clipboard-write as an option
	// @ts-ignore
	await navigator.permissions.query({ name: 'clipboard-write' })

	navigator.clipboard.writeText(rows.join('\n'))
}

export const downloadCSV = async (url: string, name: string) => {
	try {
		const { data } = await api.get<string>(url)
		FileSaver.saveAs(new Blob([data], { type: 'text/plain;charset=utf-8' }), name)
		toast({ title: 'CSV Downloaded', message: name })
	} catch (error) {
		api.handleError(error)
	}
}

export const getConferencesForTimeSlot = (date: Date, slot: TimeSlot, conferences: GQLEventType[]) => {
	let A = {
		S: addSeconds(getDateFromTime(slot.from24, date), 1),
		E: subSeconds(getDateFromTime(slot.to24, date), 1),
	}

	let list = conferences?.filter((x) => {
		let found = false

		try {
			let B = {
				S: addSeconds(new Date(x?.startDate || ''), 1),
				E: subSeconds(new Date(x?.endDate || ''), 1),
			}

			// found = startDate <= slotTo && endDate >= slotFrom
			found = A.E >= B.S && A.S <= B.E
		} catch (e) {}

		return found
	})

	return list
}

export const isAcceptableFile = (file: File) => {
	const extensions = [
		'csv',
		'doc',
		'docm',
		'docx',
		'eml',
		'emz',
		'gz',
		'htm',
		'html',
		'jpeg',
		'jpg',
		'm4a',
		'mhtml',
		'mov',
		'mp3',
		'mpga', // this is what the mime-types lib reports when it's actually an mp3
		'mp4',
		'msg',
		'odt',
		'pages',
		'pdf',
		'png',
		'rtf',
		'tif',
		'tiff',
		'txt',
		'vtt',
		'wav',
		'xls',
		'xlsx',
		'zip',
	]
	let ext = mime.extension(file.type)
	if (!ext) return false
	return !!extensions.filter((x) => (typeof ext === 'string' ? x === ext.toLowerCase() : false)).length
}

export const removeFileExtension = (fileName: string) => {
	return fileName.split('.').slice(0, -1).join('.')
}

export interface QProm<T = any> extends Promise<T> {
	isFulfilled: () => boolean
	isPending: () => boolean
	isRejected: () => boolean
}

export const isQProm = (x: any): x is QProm => x.isFulfilled !== undefined
/**
 * This function allow you to modify a JS Promise by adding some status properties.
 * Based on: http://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
 * But modified according to the specs of promises : https://promisesaplus.com/
 */
export function makeQuerablePromise(promise: Promise<any> | QProm<any>): QProm<any> {
	// Don't modify any promise that has been already modified.
	if (isQProm(promise)) return promise

	// Set initial state
	var isPending = true
	var isRejected = false
	var isFulfilled = false

	// Observe the promise, saving the fulfillment in a closure scope.
	var result = promise.then(
		function (v: any) {
			isFulfilled = true
			isPending = false
			return v
		},
		function (e: any) {
			isRejected = true
			isPending = false
			throw e
		}
	)

	;(result as QProm<any>).isFulfilled = () => isFulfilled
	;(result as QProm<any>).isPending = () => isPending
	;(result as QProm<any>).isRejected = () => isRejected

	return result as QProm<any>
}
