import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { toast } from 'components/toast'
import { CaseNoteModel } from 'types/models'
import { ModalResolveFn, RefetchFn } from 'types'

interface OpenEditNoteProps {
	note: CaseNoteModel
	refetch: RefetchFn
}

const openEditNote = ({ note, refetch }: OpenEditNoteProps) => {
	return openModal({
		title: 'Edit Note',
		render: (close) => <CreateFolder note={note} refetch={refetch} close={close} />,
	})
}

interface FormData {
	text: string
}

const CreateFolder: FC<OpenEditNoteProps & { close: ModalResolveFn }> = ({ note, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: { text: note.text },
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			const { data: newNode } = await api.put(`/CaseNotes/${note.id}`, {
				...note,
				text: formData.text,
			})

			await refetch()

			toast({ title: 'Note updated' })

			close(newNode)
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<FormInput name="text" label="Content" validations={{ required: 'Content is required ' }} autoFocus />
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditNote
