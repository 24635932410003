import { FC } from 'react'
import CaseHeader from 'components/CaseHeader'
import { format, parseISO } from 'date-fns'
import { Auth } from 'Auth'
import api from 'api'
import useData from 'hooks/useData'
import openEditReview from 'modals/openEditReview'
import {
	PlusIcon,
	DotsHorizontalIcon,
	PencilIcon,
	BanIcon,
	TrashIcon,
	ClipboardCopyIcon,
} from '@heroicons/react/outline'
import Stack from 'components/Stack'
import openCreateReview from 'modals/openCreateReview'
import { confirm, toast } from 'alerts'
import SectionHeader from 'components/SectionHeader'
import PageNavbar from 'components/PageNavbar'
import caseLinks from './utils/links'
import { CaseModel, ReviewModel } from 'types/models'
import { useParams } from 'react-router-dom'
import Table, { TableSchema } from 'components/Table'
import clsx from 'clsx'
import { copyTabularData, ensureDate, isComplexReview, updateADRType, updateCaseComplexity } from 'utils/funcs'
import { ADR_TYPE, CASE_COMPLEXITY, HEARING_TYPE } from 'types/enums'

// interface ReviewsProps {
// 	caseId?: string
// }

const Reviews: FC = () => {
	const params = useParams()
	const { caseId } = params

	const { data: caseData, refetch } = useData<CaseModel>(`/Cases/${caseId}`)

	const getDecisionDueDate = (item: ReviewModel) => {
		const hearings = caseData?.hearings
			.filter((x) => {
				return x.hearingType === HEARING_TYPE.Review
			})
			.filter((x) => {
				return x.isPostponed === false
			})
			.filter((x) => {
				return !!x.reviews.find((y) => y.id === item.id)
			})

		if (!hearings?.length) return null

		const orderedHearings = hearings.sort((a, b) => {
			return ensureDate(b.startDate).valueOf() - ensureDate(a.startDate).valueOf()
		})

		return orderedHearings[0].decisionDate ? ensureDate(orderedHearings[0].decisionDate) : null
	}

	const reviewSchema: TableSchema<ReviewModel> = {
		cols: [
			{
				title: 'Review Number',
				value: (x) => (
					<div className="flex items-start">
						<div className={clsx(!!x.withdrawalReason ? 'line-through' : '')}>{x.reviewNumber}</div>
						{!!x.withdrawDate && (
							<div className="text-xxs font-normal text-red-500 dark:text-red-300 border border-red-300 dark:border-red-600 px-0.5 ml-3">
								Withdrawn: {format(ensureDate(x.withdrawDate), 'dd/MM/yyyy')}
							</div>
						)}
					</div>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'Issue Code',
				value: (x) => <span className={clsx(!!x.withdrawalReason ? 'line-through' : '')}>{x.issueCode}</span>,
				truncate: true,
			},
			{
				title: 'ACC Decision Date',
				value: (x) => (
					<div className={clsx(!!x.withdrawalReason ? 'line-through' : '')}>
						{x.accDecisionDate ? format(parseISO(x.accDecisionDate), 'dd/MM/yyyy') : '-'}
					</div>
				),
				responsive: 'sm',
			},
			{
				title: 'Lodgement Date',
				value: (x) => (
					<div className={clsx(!!x.withdrawalReason ? 'line-through' : '')}>
						{format(parseISO(x.lodgementDate), 'dd/MM/yyyy')}
					</div>
				),
				responsive: 'md',
			},
			{
				title: 'Set By Date',
				value: (x) => (
					<div className={clsx(!!x.withdrawalReason ? 'line-through' : '')}>
						{x.setByDate ? format(parseISO(x.setByDate), 'dd/MM/yyyy') : '-'}
					</div>
				),
				responsive: 'md',
			},
			{
				title: 'Decision Due Date',
				value: (x) => {
					let decisionDate = getDecisionDueDate(x)

					return (
						<div className={clsx(!!x.withdrawalReason ? 'line-through' : '')}>
							{decisionDate ? format(decisionDate, 'dd/MM/yyyy') : '-'}
						</div>
					)
				},
				responsive: 'md',
			},
		],
		actions: (x) =>
			Auth.is('Admin', 'CaseManager', 'FENZAdmin') && [
				Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
					icon: <DotsHorizontalIcon className="w-5 h-5" />,
					intent: 'menu',
					actions: [
						Auth.is('Admin', 'CaseManager', 'FENZAdmin') && [
							{
								title: 'Edit Review',
								icon: <PencilIcon className="w-5 h-5" />,
								onClick: () => caseData && openEditReview({ caseData, review: x, refetch }),
							},
							x.withdrawalReason
								? {
										title: 'Restore Review',
										icon: <PlusIcon className="w-5 h-5" />,
										onClick: () => handleRestore(x),
								  }
								: {
										title: 'Withdraw Review',
										icon: <BanIcon className="w-5 h-5" />,
										onClick: () => handleWithdraw(x),
								  },
						],
						Auth.is('Admin', 'FENZAdmin') && [
							{
								title: 'Delete Review',
								icon: <TrashIcon className="w-5 h-5" />,
								intent: 'danger',
								onClick: () => handleDelete(x),
							},
						],
					],
				},
			],
	}

	const handleDelete = async (item: ReviewModel) => {
		await confirm({
			title: `Are you sure you want to delete review "${item.reviewNumber}"?`,
			onAccept: async () => {
				if (!caseData) return

				await api.delete(`/Cases/${caseData?.id}/review/${item.id}`)

				if (
					caseData.reviews.length - 1 < 2 ||
					![...caseData.reviews]
						.filter((x) => x.id === item.id)
						.filter((x) => isComplexReview(x as { issueCode?: string })).length
				) {
					await updateCaseComplexity(caseData, CASE_COMPLEXITY.Standard)
					await updateADRType(caseData, ADR_TYPE.Standard)
				}

				if (refetch) await refetch()

				toast({
					title: 'Review Deleted',
				})
			},
		})
	}

	const handleWithdraw = async (item: ReviewModel) => {
		await confirm({
			title: `Are you sure you want to withdraw review "${item.reviewNumber}"?`,
			intent: 'primary',
			onAccept: async () => {
				await api.put(`/Cases/${caseData?.id}/review/${item.id}`, {
					...item,
					withdrawalReason: 1,
					withdrawDate: new Date(),
				})

				if (refetch) await refetch()

				toast({
					title: 'Review Withdrawn',
				})
			},
		})
	}

	const handleRestore = async (item: ReviewModel) => {
		await confirm({
			title: `Are you sure you want to restore review "${item.reviewNumber}"?`,
			intent: 'primary',
			onAccept: async () => {
				await api.put(`/Cases/${caseData?.id}/review/${item.id}`, {
					...item,
					withdrawalReason: null,
					withdrawDate: null,
				})

				if (refetch) await refetch()

				toast({
					title: 'Review Restored',
				})
			},
		})
	}

	const links = caseData ? caseLinks(caseData) : []

	return (
		<>
			<CaseHeader caseData={caseData} refetch={refetch} />

			<PageNavbar links={links}>
				<Stack>
					<SectionHeader
						title="Reviews"
						actions={[
							!!navigator?.clipboard?.writeText &&
								Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
									icon: <ClipboardCopyIcon className="w-4 h-4" />,
									size: 'sm',
									rounded: 'md',
									onClick: () => {
										try {
											copyTabularData(reviewSchema, caseData?.reviews || [])
											toast({ title: 'Table data copied' })
										} catch (e) {
											toast({ title: 'Table data not copied', intent: 'error' })
										}
									},
								},
							Auth.is('Admin', 'CaseManager', 'FENZAdmin') && {
								title: 'Create Review',
								intent: 'primary',
								icon: <PlusIcon className="w-5 h-5" />,
								onClick: () => caseData && openCreateReview({ caseData, refetch }),
							},
						]}
						className="px-6"
					/>

					<div className="-px-6">
						<Table items={caseData?.reviews || []} schema={reviewSchema} className="-my-px" />
					</div>
				</Stack>
				{/* <Stack dividers space={4}>
					{caseData?.reviews.map((item) => (
						<Stack key={item.id} className="px-6">
							<SectionHeader
								title={`Review: ${item.reviewNumber}`}
								actions={[
									{
										icon: <DotsHorizontalIcon className="w-5 h-5" />,
										intent: 'menu',
										actions: [
											[
												{
													title: 'Edit Review',
													icon: <PencilIcon className="w-5 h-5" />,
													onClick: () => openEditReview({ caseData, refetch, review: item }),
												},
												Auth.is('Admin', 'CaseManager') &&
													(item.withdrawalReason
														? {
																title: 'Restore Review',
																icon: <PlusIcon className="w-5 h-5" />,
																onClick: () => handleRestore(item),
														  }
														: {
																title: 'Withdraw Review',
																icon: <BanIcon className="w-5 h-5" />,
																onClick: () => handleWithdraw(item),
														  }),
											],
											[
												{
													title: 'Delete Review',
													intent: 'danger',
													icon: <TrashIcon className="w-5 h-5" />,
													onClick: () => handleDelete(item),
												},
											],
										],
									},
								]}
							/>

							<DataListColumns
								items={[
									{
										title: 'Issue Code',
										value: <ShortText>{item.issueCode}</ShortText>,
									},
									{
										title: 'ACC Decision Date',
										value: (
											<ShortText>
												{item.accDecisionDate
													? format(parseISO(item.accDecisionDate), 'dd/MM/yyyy')
													: '-'}
											</ShortText>
										),
									},
									{
										title: 'Lodgement Date',
										value: (
											<ShortText>
												{item.lodgementDate
													? format(parseISO(item.lodgementDate), 'dd/MM/yyyy')
													: '-'}
											</ShortText>
										),
									},
									{
										title: 'Set By Date',
										value: (
											<ShortText>
												{item.lodgementDate
													? format(parseISO(item.lodgementDate), 'dd/MM/yyyy')
													: '-'}
											</ShortText>
										),
									},
									{
										title: 'Decision Due Date',
										value: (
											<ShortText>
												{item.lodgementDate
													? format(parseISO(item.lodgementDate), 'dd/MM/yyyy')
													: '-'}
											</ShortText>
										),
									},
								]}
							/>
						</Stack>
					))}
				</Stack> */}
			</PageNavbar>
		</>
	)
}

export default Reviews
