import axios, { AxiosRequestConfig } from 'axios'
import storage from 'utils/storage'
import conf from 'utils/config'
import store from 'Auth'
import { makeQuerablePromise } from './funcs'

const state = {
	value: makeQuerablePromise(Promise.resolve(null)),
}

const getState = async (cb: () => Promise<any>, onError?: () => void) => {
	if (state.value.isPending()) {
		return await state.value
	}

	try {
		// set the long standing promise, ie: get new refresh token
		state.value = makeQuerablePromise(cb())

		return await state.value
	} catch (error) {
		// put error handling here, it will only trigger once
		if (onError) onError()
	}
}

export const getToken = async (config: AxiosRequestConfig<any>) => {
	const token = storage.getString('token')
	const refresh = storage.getString('refresh')

	if (token && refresh) {
		const res = await getState(
			() =>
				axios.post<
					{ refreshToken: string; bearerToken: string },
					{ data: { access_token: string; expires_in: number; refresh_token: string } }
				>(
					`${conf.url}/Auth/refreshToken`,
					{
						refreshToken: refresh,
						bearerToken: token,
					},
					{
						headers: {
							Registry: conf.registry,
						},
					}
				),
			() => {
				store.getState().logout()
			}
		)

		if (res) {
			const {
				data: { access_token, expires_in, refresh_token },
			} = res

			store.getState().login(access_token, expires_in, refresh_token)

			if (config.headers) config.headers.Authorization = `Bearer ${access_token}`
		}
	}
}
