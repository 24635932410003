import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState, useEffect } from 'react'
import Storage from 'utils/storage'

type MediaChange = (this: MediaQueryList, ev: MediaQueryListEvent) => any

const changeDarkMode = (x: boolean) => document.querySelector('html')?.classList[x ? 'add' : 'remove']('dark')

interface DarkModeContextType {
	darkMode: boolean | null
	isDark: boolean
	systemIsDark: boolean
	setDarkMode: Dispatch<SetStateAction<boolean | null>>
}

const DarkModeContext = createContext<DarkModeContextType>({} as DarkModeContextType)

export const useDarkMode = () => useContext(DarkModeContext)

interface DarkModeProviderProps {
	children: ReactNode
}

export const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
	const [darkMode, setDarkMode] = useState<boolean | null>(Storage.getBoolean('dark'))
	const matchesMedia = window.matchMedia('(prefers-color-scheme: dark)')
	const [isActuallyDark, setActuallyDark] = useState<boolean>(matchesMedia.matches)

	useEffect(() => {
		Storage.set('dark', darkMode)
	}, [darkMode])

	useEffect(() => {
		if (darkMode !== null) {
			changeDarkMode(darkMode)
		} else {
			const func: MediaChange = (e) => {
				changeDarkMode(e.matches)
				setActuallyDark(e.matches)
			}

			changeDarkMode(matchesMedia.matches)

			if (matchesMedia.addEventListener !== undefined) {
				matchesMedia.addEventListener('change', func)
				return () => matchesMedia.removeEventListener('change', func)
			}
		}
	}, [darkMode, matchesMedia])

	return (
		<DarkModeContext.Provider
			value={{
				darkMode,
				setDarkMode,
				systemIsDark: isActuallyDark,
				isDark: darkMode ?? isActuallyDark,
			}}
		>
			{children}
		</DarkModeContext.Provider>
	)
}
