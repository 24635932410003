import React from 'react'
import { Auth } from 'Auth'
import clsx from 'clsx'

const Message = ({ message }) => {
	const us = message.author === Auth.profile().id
	// const exported = message.attributes && message.attributes.exported

	return (
		<div className={clsx('flex', us ? 'justify-end' : 'justify-start')}>
			<div
				className={clsx(
					'py-1 px-3 m-2 relative',
					us
						? 'rounded-tl-lg rounded-tr-lg rounded-bl-lg bg-primary-600 text-white'
						: 'rounded-tl-lg rounded-tr-lg rounded-br-lg bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white'
				)}
			>
				{message.body}
			</div>
		</div>
	)
}

export default Message
