import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import api from 'api'
import { toast } from 'components/toast'
import { CaseFileModel, TodoModel } from 'types/models'
import { RefetchFn } from 'types'

interface OpenEditTodoProps {
	todo: TodoModel
	refetch: RefetchFn
}

const openEditTodo = ({ todo, refetch }: OpenEditTodoProps) => {
	return openModal<CaseFileModel>({
		title: 'Edit Todo',
		render: (close) => <EditTodo todo={todo} refetch={refetch} close={close} />,
	})
}

interface FormData {
	item: string
}

const EditTodo: FC<OpenEditTodoProps & { close: () => void }> = ({ todo, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: { item: todo.item },
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/TodoItems/${todo.id}`, {
				...todo,
				item: formData.item,
			})

			await refetch()

			toast({ title: 'Todo Updated' })

			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<FormInput name="item" as="textarea" validations={{ required: 'Content is required' }} autoFocus />
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditTodo
