import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import Stack from 'components/Stack'
import { getFullName } from 'utils/funcs'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/FormSelect'
import useData from 'hooks/useData'
import { Spinner } from 'components/Icon'
import { UserModel } from 'types/models'
import { USER_ROLE } from 'types/enums'
import { CheckIcon } from '@heroicons/react/outline'
import { ModalResolveFn, PaginatedSet } from 'types'
import openConfirmUser from './openConfirmUser'

interface FilterType {
	roles?: USER_ROLE[]
	organizationId?: string
}

interface OpenSelectUserProps {
	title: string
	filter?: FilterType
	requireConfirmation?: boolean
	onAccept?: (x: UserModel | null) => void | Promise<void>
}

const openSelectUser = ({ title, filter, requireConfirmation, onAccept }: OpenSelectUserProps) => {
	return openModal<UserModel>({
		title,
		size: 'md',
		render: (close) => (
			<FindUser close={close} filter={filter} requireConfirmation={requireConfirmation} onAccept={onAccept} />
		),
	})
}

interface FormData {
	user: UserModel | null
}

const FindUser: FC<Omit<OpenSelectUserProps, 'title'> & { close: ModalResolveFn<UserModel> }> = ({
	close,
	filter = {},
	requireConfirmation,
	onAccept,
}) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: { user: null },
	})

	const handleSubmit = async (formData: FormData) => {
		if (formData.user) {
			setSaving(true)
			if (requireConfirmation) {
				const confirmed = await openConfirmUser({ user: formData.user })
				if (onAccept) await onAccept(formData.user)
				if (confirmed) close(formData.user)
			} else {
				if (onAccept) await onAccept(formData.user)
				close(formData.user)
			}
			setSaving(false)
		}
	}

	const [pageNumber, setPageNumber] = useState(1)
	const [users, setUsers] = useState<UserModel[]>([])

	const { isFetching } = useData<PaginatedSet<UserModel>>(
		'/users',
		{
			pageNumber,
			pageSize: 100,
			search: filter,
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setUsers((prev) => [...prev, ...data.items])
				if (data.pageNumber < data.totalPages) setPageNumber((prev) => prev + 1)
			},
		}
	)

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					{isFetching ? (
						<div className="flex items-center justify-center">
							<Spinner className="w-10 h-10 spin" />
						</div>
					) : (
						<FormSelect
							name="user"
							label="User"
							options={users.map((x) => ({ label: getFullName(x), value: x })) || []}
							className="flex-1"
							validations={{ required: 'User is required' }}
							autoFocus
						/>
					)}
				</Stack>
			</ModalContent>
			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openSelectUser
