import { FC, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'

type IntentType = 'primary'

const intents = {
	primary: {
		wrapper: 'bg-primary-50',
		text: 'text-primary-600',
	},
}

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const sizes = {
	xs: {
		wrapper: 'h-6 w-6',
		text: 'text-xs font-medium leading-none',
	},
	sm: {
		wrapper: 'h-8 w-8',
		text: 'text-sm font-medium leading-none',
	},
	md: {
		wrapper: 'h-10 w-10',
		text: 'font-medium leading-none',
	},
	lg: {
		wrapper: 'h-12 w-12',
		text: 'text-lg font-medium leading-none',
	},
	xl: {
		wrapper: 'h-14 w-14',
		text: 'text-xl font-medium leading-none',
	},
}

interface CircleBadgeProps {
	intent?: IntentType
	size?: SizeType
	className?: ClassValue
	children: ReactNode
}

const CircleBadge: FC<CircleBadgeProps> = ({ intent = 'primary', size = 'md', className, children }) => (
	<div
		className={clsx(
			'inline-flex items-center justify-center rounded-full text-white shadow-solid',
			intents[intent].wrapper,
			sizes[size].wrapper,
			className
		)}
	>
		<span className={clsx(intents[intent].text, sizes[size].text)}>{children}</span>
	</div>
)

export default CircleBadge
