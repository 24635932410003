import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchFormProps } from 'components/PaginatedTable'
import { SearchIcon } from '@heroicons/react/outline'
import { Undo } from 'components/Icon'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import Stack from 'components/Stack'
import { useForm } from 'react-hook-form'
import Actions from 'components/Actions'
import FormSelect from 'components/Form/FormSelect'
import { ReminderStatus, ReminderType, USER_ROLE } from 'types/enums'
import { PresetValue, PaginationSearch, isNotNone } from 'types'
import FormDate from 'components/Form/FormDate'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { gql } from '@apollo/client'
import { GQLConnection, GQLUserType } from 'types/gql'
import { Auth } from 'Auth'

const query = gql`
	query {
		reviewers: Users(search: { role: "${USER_ROLE.Reviewer}" }) {
			items {
				id
				fullName
			}
		}
	}
`

export interface PresetValues {
	caseNumber?: PresetValue<string>
	userId?: PresetValue<string>
	date?: PresetValue<string>
}

interface Props {
	presetValues?: PresetValues
}

const SearchForm: FC<SearchFormProps & Props> = ({ setSearch, close, presetValues }) => {
	const location = useLocation()

	const state = (location.state as { search: PaginationSearch })?.search

	const formContext = useForm<PaginationSearch>({
		defaultValues: {
			caseNumber: presetValues?.caseNumber?.value || state?.caseNumber || '',
			userId: presetValues?.userId?.value || state?.userId || '',
			date: presetValues?.date?.value || state?.date || '',
			status: state?.status || null,
			type: state?.type || null,
		},
	})

	const handleSubmit = (formData: PaginationSearch) => {
		const search: PaginationSearch = {}

		if (isNotNone(formData.caseNumber)) search.caseNumber = formData.caseNumber
		if (isNotNone(formData.userId)) search.userId = formData.userId
		if (isNotNone(formData.date)) search.date = formData.date
		if (isNotNone(formData.status)) search.status = formData.status
		if (isNotNone(formData.type)) search.type = formData.type

		setSearch(search)
	}

	const {
		data = {
			reviewers: { items: [] },
		},
	} = useBufferedQuery<{
		reviewers: GQLConnection<GQLUserType>
	}>(query, { skip: Auth.is('Applicant') })

	return (
		<Form
			context={formContext}
			onSubmit={handleSubmit}
			className="p-6 bg-gray-50 dark:bg-gray-750 border-t border-gray-200 dark:border-gray-600"
		>
			<Stack dividers>
				<h3 className="text-lg leading-5 font-medium text-gray-900 dark:text-white">Search</h3>

				<div className="flex space-x-4">
					<Stack className="flex-1">
						<FormInput
							name="caseNumber"
							label="Case Number"
							className="flex-1"
							disabled={presetValues?.caseNumber?.locked}
							hideOptional
							autoFocus={!presetValues?.caseNumber?.locked}
						/>

						<FormDate name="date" label="Created Date" disabled={presetValues?.date?.locked} hideOptional />

						<FormSelect name="type" label="Type" options={ReminderType.options} clearable hideOptional />
					</Stack>

					<Stack className="flex-1">
						<FormSelect
							name="userId"
							label="User"
							options={data.reviewers.items?.map((x) => ({ label: x.fullName || '', value: x.id })) || []}
							clearable={!presetValues?.userId?.locked || true}
							disabled={presetValues?.userId?.locked}
							hideOptional
						/>

						<FormSelect
							name="status"
							label="Completed"
							options={ReminderStatus.options}
							clearable
							hideOptional
						/>
					</Stack>
				</div>

				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Reset',
								icon: <Undo className="w-4 h-5" />,
								onClick: () => {
									setSearch()
									formContext.reset({})
								},
							},
							{
								title: 'Search',
								icon: <SearchIcon className="w-4 h-5" />,
								intent: 'primary',
								type: 'submit',
							},
						]}
					/>
				</div>
			</Stack>
		</Form>
	)
}

export default SearchForm
