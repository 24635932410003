import { FC, ReactNode, SetStateAction, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import NavList, { NavListIntentType, NavListItems } from './NavList'
import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { SidebarIntents } from './Sidebar'

interface MobileSidebarProps {
	items: NavListItems
	header?: ReactNode
	footer?: ReactNode
	intent?: NavListIntentType
	isOpen: boolean
	setOpen: (x: SetStateAction<boolean>) => void
}

const MobileSidebar: FC<MobileSidebarProps> = ({ items, header, footer, intent = 'dark', isOpen, setOpen }) => {
	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={() => setOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="transition ease-in-out duration-300"
					enterFrom="-translate-x-full"
					enterTo="translate-x-0"
					leave="transition ease-in-out duration-300"
					leaveFrom="translate-x-0"
					leaveTo="-translate-x-full"
				>
					<div className={clsx('relative flex-1 flex flex-col max-w-xs w-full', SidebarIntents[intent])}>
						<Transition.Child
							as={Fragment}
							enter="ease-in-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in-out duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="absolute top-0 right-0 -mr-12 pt-2">
								<button
									type="button"
									className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
									onClick={() => setOpen(false)}
								>
									<span className="sr-only">Close sidebar</span>
									<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
								</button>
							</div>
						</Transition.Child>
						<div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
							{header}
							<div className="py-6 px-2">
								<NavList items={items} intent={intent} onLinkClick={() => setOpen(false)} />
							</div>
						</div>
						{footer}
					</div>
				</Transition.Child>
				<div className="shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
			</Dialog>
		</Transition.Root>
	)
}

export default MobileSidebar
