import { ReactNode, FC, Fragment } from 'react'
import { isNotNone, None } from 'types'
import clsx from 'clsx'
import NavLinkComp from './NavLink'
import Tooltip, { TooltipIntent } from './Tooltip'

export interface NavListIntent {
	link: string
	activeLink: string
	title: string
	border: string
}

export const NavListIntents: Record<string, NavListIntent> = {
	dark: {
		link: 'text-white/75 hover:text-white hover:bg-white/25 focus:text-white focus:bg-white/25 dark:hover:bg-gray-800 antialiased',
		activeLink: 'text-white bg-white text-brand-700 dark:bg-brand-700 dark:text-white antialiased',
		title: 'text-gray-400',
		border: 'border-white/25',
	},
	light: {
		link: 'text-gray-600 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-200 dark:hover:bg-gray-600 hover:bg-primary-100 focus:text-gray-900 focus:bg-gray-100 dark:focus:bg-gray-800 dark:focus:text-gray-200',
		activeLink:
			'text-primary-900 bg-primary-200 dark:text-white dark:bg-gradient-to-r dark:from-primary-600 dark:to-primary-500',
		title: 'text-gray-500 dark:text-gray-400',
		border: 'border-gray-300 dark:border-gray-500',
	},
	primary: {
		link: 'text-gray-200 hover:text-white hover:bg-primary-700 focus:text-white focus:bg-primary-700 dark:hover:bg-primary-800 antialiased',
		activeLink: 'text-primary-600 bg-white',
		title: 'text-primary-200',
		border: 'border-gray-500',
	},
}

export type NavListIntentType = keyof typeof NavListIntents

interface NavItemType {
	title: ReactNode
	icon?: ReactNode
}

export interface NavLink extends NavItemType {
	to: string
	exact?: boolean
}
export const isNavLink = (x: any): x is NavLink => x.to !== undefined

export interface NavAnchor extends NavItemType {
	href: string
	target?: '_blank'
}
export const isNavAnchor = (x: any): x is NavAnchor => x.href !== undefined

export interface NavButton extends NavItemType {
	onClick: () => void
}
export const isNavButton = (x: any): x is NavButton => x.onClick !== undefined

export type NavItem = NavLink | NavAnchor | NavButton | None

export interface NavGroup {
	title?: ReactNode
	items: NavItem[]
}
export const isNavGroup = (x: any): x is NavGroup => x.items !== undefined

export type NavListItems = (NavItem | NavGroup | None)[]

interface NavListProps {
	items: NavListItems
	intent?: NavListIntentType
	onLinkClick?: () => void
	tooltips?: boolean
	tooltipIntent?: TooltipIntent
}

const NavList: FC<NavListProps> = ({
	items,
	intent = 'dark',
	onLinkClick,
	tooltips = false,
	tooltipIntent = 'dark',
}) => {
	const renderItem = (item: NavItem) => (
		<Tooltip message={isNotNone(item) ? item?.title || '-' : '-'} enabled={tooltips} intent={tooltipIntent}>
			{isNavLink(item)
				? renderLink(item)
				: isNavAnchor(item)
				? renderAnchor(item)
				: isNavButton(item)
				? renderButton(item)
				: null}
		</Tooltip>
	)

	const renderLink = (item: NavLink) => (
		<NavLinkComp
			to={item.to}
			className="flex items-center px-2.5 py-2 text-sm font-medium rounded"
			activeClassName={NavListIntents[intent].activeLink}
			inactiveClassName={NavListIntents[intent].link}
			onClick={onLinkClick}
		>
			{item.icon && <div className="pointer-events-none mr-3">{item.icon}</div>}
			<span className="truncate">{item.title}</span>
		</NavLinkComp>
	)

	const renderAnchor = (item: NavAnchor) => (
		<a
			href={item.href}
			target={item.target}
			rel={item.target ? 'noreferrer' : ''}
			className={clsx('flex items-center px-2.5 py-2 text-sm font-medium rounded', NavListIntents[intent].link)}
			onClick={onLinkClick}
		>
			{item.icon && <div className="pointer-events-none mr-3">{item.icon}</div>}
			<span className="truncate">{item.title}</span>
		</a>
	)

	const renderButton = (item: NavButton) => (
		<button
			onClick={() => {
				item.onClick()
				onLinkClick && onLinkClick()
			}}
			className={clsx(
				'flex items-center w-full px-2.5 py-2 text-sm font-medium rounded',
				NavListIntents[intent].link
			)}
		>
			{item.icon && <div className="pointer-events-none mr-3">{item.icon}</div>}
			<span className="truncate">{item.title}</span>
		</button>
	)

	return (
		<nav className="space-y-1" aria-label="Sidebar">
			{items.map((item, i) =>
				isNavGroup(item) ? (
					<div key={i}>
						<div className={clsx('mt-4 pt-3', i !== 0 && ['border-t', NavListIntents[intent].border])}>
							{item.title && (
								<div
									className={clsx(
										'px-3 mb-2 text-xs font-semibold uppercase tracking-wider',
										NavListIntents[intent].title
									)}
								>
									{item.title}
								</div>
							)}
							<div className="space-y-1">
								{item.items.map((item, i) => (
									<Fragment key={i}>{renderItem(item)}</Fragment>
								))}
							</div>
						</div>
					</div>
				) : (
					<Fragment key={i}>{renderItem(item)}</Fragment>
				)
			)}
		</nav>
	)
}

export default NavList
