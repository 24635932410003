import { FC, useEffect } from 'react'
import { parseQueryCode } from 'utils/funcs'
import api from 'api'
import { navigate } from 'components/NavigateHoist'

const Xero: FC = () => {
	let { code, session_state } = parseQueryCode(window.location.search)

	useEffect(() => {
		const run = async () => {
			try {
				await api.post(`/Xero/authorize?code=${code}&state=${session_state}`)

				navigate('/admin/billing')
			} catch (error) {
				console.dir(error)
			}
		}
		run()
	}, [code, session_state])

	return <div></div>
}

export default Xero
