export const Roles = [
	{ name: 'Administrator', id: 'c394d44c-67f5-42aa-9dcd-9bad43c5e761' },
	{ name: 'CaseManager', id: '0c616ca3-07ac-4f8d-b6e6-99f4468adbd2' },
	{ name: 'Reviewer', id: '2a0dd94b-d1b9-49c8-8bf5-fde1a7c6b135' },
	{ name: 'Mediator', id: '08fe22b7-3142-4c85-a017-579b88ec0a5c' },
	{ name: 'Respondent', id: '4317924a-7702-436e-aef0-843526ed278b' },
	{ name: 'PeerReviewer', id: 'fcfda96b-be77-47a4-949d-0fc2b1a2e171' },
	{ name: 'ACCAdmin', id: 'fe605e25-6694-47d8-8896-bf97f5fd21f5' },
	{ name: 'FileHandler', id: '67fcb8f3-9032-4a1a-bdbd-07d6329a450b' },
	{ name: 'Support', id: 'e4da0f55-205e-4896-9569-136ea3ec1e58' },
	{ name: 'Adjudicator', id: '332a2930-748f-4438-bb93-be2914ef5334' },
	{ name: 'Facilitator', id: 'ac299733-b301-4855-bf11-51482cbd5e36' },
	{ name: 'FENZAdmin', id: '594af135-319f-4a45-9166-7d242719affc' },
]

export enum USER_ROLE {
	Administrator = 'c394d44c-67f5-42aa-9dcd-9bad43c5e761',
	CaseManager = '0c616ca3-07ac-4f8d-b6e6-99f4468adbd2',
	Reviewer = '2a0dd94b-d1b9-49c8-8bf5-fde1a7c6b135',
	Mediator = '08fe22b7-3142-4c85-a017-579b88ec0a5c',
	Respondent = '4317924a-7702-436e-aef0-843526ed278b',
	PeerReviewer = 'fcfda96b-be77-47a4-949d-0fc2b1a2e171',
	ACCAdmin = 'fe605e25-6694-47d8-8896-bf97f5fd21f5',
	FileHandler = '67fcb8f3-9032-4a1a-bdbd-07d6329a450b',
	Support = 'e4da0f55-205e-4896-9569-136ea3ec1e58',
	Adjudicator = '332a2930-748f-4438-bb93-be2914ef5334',
	Facilitator = 'ac299733-b301-4855-bf11-51482cbd5e36',
	FENZAdmin = '594af135-319f-4a45-9166-7d242719affc',
}

export const userRole = {
	'c394d44c-67f5-42aa-9dcd-9bad43c5e761': 'Administrator',
	'0c616ca3-07ac-4f8d-b6e6-99f4468adbd2': 'Case Manager',
	'2a0dd94b-d1b9-49c8-8bf5-fde1a7c6b135': 'Reviewer',
	'08fe22b7-3142-4c85-a017-579b88ec0a5c': 'Mediator',
	'4317924a-7702-436e-aef0-843526ed278b': 'Respondent',
	'fcfda96b-be77-47a4-949d-0fc2b1a2e171': 'Peer Reviewer',
	'fe605e25-6694-47d8-8896-bf97f5fd21f5': 'ACC Administrator',
	'67fcb8f3-9032-4a1a-bdbd-07d6329a450b': 'ACC File Handler',
	'e4da0f55-205e-4896-9569-136ea3ec1e58': 'Support',
	'332a2930-748f-4438-bb93-be2914ef5334': 'Adjudicator',
	'ac299733-b301-4855-bf11-51482cbd5e36': 'Facilitator',
	'594af135-319f-4a45-9166-7d242719affc': 'FENZ Admin',
}

export const UserRole = {
	readable: (x: USER_ROLE) => userRole[x],
	options: Object.entries(userRole).map(([id, name]) => ({ label: name, value: id })),
}
