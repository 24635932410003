import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormSelect from 'components/Form/FormSelect'
import api from 'api'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { CheckIcon } from '@heroicons/react/outline'
import { toast } from 'components/toast'
import { CaseModel, OrganizationModel } from 'types/models'
import { CaseComplexity, CaseProcess, CaseType, CASE_PROCESS, CASE_TYPE } from 'types/enums'
import usePaginatedData from 'hooks/usePaginatedData'
import { RefetchFn } from 'types'
import { FENZOrgId } from 'utils/constants'

interface OpenEditCasePrimaryInformationProps {
	caseData: CaseModel
	refetch: RefetchFn
}

const openEditCasePrimaryInformation = ({ caseData, refetch }: OpenEditCasePrimaryInformationProps) => {
	return openModal({
		title: 'Primary Information',
		render: (close) => <EditCasePrimaryInformation caseData={caseData} refetch={refetch} close={close} />,
	})
}

interface EditCasePrimaryInformationProps {
	caseData: CaseModel
	refetch: RefetchFn
	close: (answer?: boolean | undefined) => void
}

interface FormData {
	caseType: CASE_TYPE
	accClaimNumber: string
	applicantName: string
	complex: string
	organization: OrganizationModel | null
	process: CASE_PROCESS
}

const EditCasePrimaryInformation: FC<EditCasePrimaryInformationProps> = ({ caseData, refetch, close }) => {
	const { isSaving, setSaving } = useModalState()

	const paginationState = {
		page: 1,
		pageSize: 100,
	}

	const { data: organizations } = usePaginatedData<OrganizationModel>('/Organizations', paginationState)

	const formContext = useForm<FormData>({
		defaultValues: {
			caseType: caseData.caseType || '',
			accClaimNumber: caseData.fields?.find((x) => x.name === 'accClaimNumber')?.value || '',
			applicantName: caseData.fields?.find((x) => x.name === 'applicantName')?.value || '',
			complex: caseData.fields?.find((x) => x.name === 'complex')?.value || '',
			organization: caseData?.organization || null,
			// for FENZ waiting for API
			process: caseData?.process,
		},
	})

	const { watch } = formContext

	const orgValue: OrganizationModel | null = watch('organization')

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/Cases/${caseData.id}`, {
				...caseData,
				...formData,
				fields: caseData.fields?.map((x) => {
					if (x.name === 'complex') return { ...x, value: formData.complex }
					if (x.name === 'accClaimNumber') return { ...x, value: formData.accClaimNumber }
					if (x.name === 'applicantName') return { ...x, value: formData.applicantName }
					return x
				}),
			})

			refetch()
			toast({ title: 'Saved' })
			close()
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormInput name="applicantName" label="Applicant's name" autoFocus />
					<FormInput name="accClaimNumber" label="ACC Claim Number" />
					<FormSelect label="Case Complexity" name="complex" options={CaseComplexity.options} />
					<FormSelect name="caseType" label="Case Type" options={CaseType.options} />
					<FormSelect
						name="organization"
						label="Organisation"
						options={organizations?.items.map((x) => ({ label: x.name, value: x })) || []}
					/>

					{/* for FENZ org id only */}
					{orgValue && orgValue?.id === FENZOrgId && (
						<FormSelect
							name="process"
							label="Process"
							options={CaseProcess.options}
							validations={{ required: 'Process is required' }}
						/>
					)}
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditCasePrimaryInformation
