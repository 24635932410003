import { FC, useState } from 'react'
import api from 'api'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import FormCheckbox from 'components/Form/FormCheckbox'
import FormToggle from 'components/Form/FormToggle'
import FormSection from 'components/Form/FormSection'
import FormSelect from 'components/Form/FormSelect'
import { CheckIcon } from '@heroicons/react/outline'
import useData from 'hooks/useData'
import Stack from 'components/Stack'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import Actions from 'components/Actions'
import { toast, warning } from 'alerts'
import AutoHeight from 'components/AutoHeight'
import countries from 'utils/countries'
import { ModalResolveFn, PaginatedSet } from 'types'
import { OrganizationModel, UserModel } from 'types/models'
import { USER_ROLE, UserRole } from 'types/enums'
import { blobUrl, getFullName } from 'utils/funcs'
import UserDefault from 'components/UserDefault'
import { Auth } from 'Auth'
import FileDropper from 'components/FileDropper'
import Avatar from 'components/Avatar'

interface OpenEditUserProps {
	refetch: () => Promise<any>
	userId: string
}

const openEditUser = ({ refetch, userId }: OpenEditUserProps) => {
	return openModal({
		title: 'Edit User',
		size: 'xl',
		render: (close) => <EditUserForm close={close} refetch={refetch} userId={userId} />,
	})
}

const userHasRole = (user: UserModel | undefined, role: USER_ROLE): boolean => !!user?.roles.find((x) => x.id === role)

interface EditUserFormData {
	user: Partial<UserModel>
	roles: {
		[USER_ROLE.FileHandler]: boolean
		[USER_ROLE.Respondent]: boolean
		[USER_ROLE.Support]: boolean
		[USER_ROLE.CaseManager]: boolean
		[USER_ROLE.Administrator]: boolean
		[USER_ROLE.Reviewer]: boolean
		[USER_ROLE.Mediator]: boolean
		[USER_ROLE.PeerReviewer]: boolean
		[USER_ROLE.ACCAdmin]: boolean
		[USER_ROLE.Facilitator]: boolean
		[USER_ROLE.Adjudicator]: boolean
		[USER_ROLE.FENZAdmin]: boolean
	}
}

interface EditUserFormProps {
	close: ModalResolveFn
	refetch: () => Promise<any>
	userId: string
}

const EditUserForm: FC<EditUserFormProps> = ({ close, refetch, userId }) => {
	const { isSaving, setSaving } = useModalState()

	const { data: user, remove } = useData<UserModel>(
		`/Users/${userId}`,
		{},
		{ suspense: true, optimisticResults: false }
	)

	const { data: orgs } = useData<PaginatedSet<OrganizationModel>>('/Organizations?pageSize=100')

	const formContext = useForm<EditUserFormData>({
		defaultValues: {
			user,
			roles: {
				[USER_ROLE.FileHandler]: userHasRole(user, USER_ROLE.FileHandler),
				[USER_ROLE.Respondent]: userHasRole(user, USER_ROLE.Respondent),
				[USER_ROLE.Support]: userHasRole(user, USER_ROLE.Support),
				[USER_ROLE.CaseManager]: userHasRole(user, USER_ROLE.CaseManager),
				[USER_ROLE.Administrator]: userHasRole(user, USER_ROLE.Administrator),
				[USER_ROLE.Reviewer]: userHasRole(user, USER_ROLE.Reviewer),
				[USER_ROLE.Mediator]: userHasRole(user, USER_ROLE.Mediator),
				[USER_ROLE.PeerReviewer]: userHasRole(user, USER_ROLE.PeerReviewer),
				[USER_ROLE.ACCAdmin]: userHasRole(user, USER_ROLE.ACCAdmin),
				[USER_ROLE.Adjudicator]: userHasRole(user, USER_ROLE.Adjudicator),
				[USER_ROLE.Facilitator]: userHasRole(user, USER_ROLE.Facilitator),
				[USER_ROLE.FENZAdmin]: userHasRole(user, USER_ROLE.FENZAdmin),
			},
		},
	})

	const isReviewer = formContext.watch(`roles.${USER_ROLE.Reviewer}`)
	const isMediator = formContext.watch(`roles.${USER_ROLE.Mediator}`)

	const org = formContext.watch('user.organization')

	const [img, setImg] = useState<File | null>(null)

	const registerUser = async (formData: EditUserFormData) => {
		const data = { ...formData.user }

		if (!data.organization) data.useOrganization = false

		if (formData.roles) {
			data.roles = Object.entries(formData.roles).reduce<Array<{ id: USER_ROLE; name: string }>>(
				(list, [key, keep], i) => {
					if (keep) {
						return [...list, { id: key as USER_ROLE, name: '' }]
					}
					return list
				},
				[]
			)
		}

		setSaving(true)

		try {
			if (formData.user.email !== user?.email) {
				let { data: users } = await api.get<PaginatedSet<UserModel>>(
					`/Users?search=${JSON.stringify({ email: data.email })}`
				)

				if (users.items.length > 0) {
					warning({
						title: 'User Exists',
						message: 'A user with that email address already exists',
					})
					setSaving(false)
					return
				}
			}

			await api.put(`/Users/${user?.id}`, { ...user, ...data })

			if (img) {
				const body = new FormData()
				body.append('file', img)
				await api.post(`/Accounts/avatar`, body)
			}

			// if (Auth.profile()?.id === user?.id && img) {
			// 	const body = new FormData()
			// 	body.append('file', img)

			// 	await api.post(`/Accounts/avatar`, body)
			// }

			if (refetch) refetch()
			remove()

			close()

			toast({ title: 'User Updated' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={registerUser}>
			<ModalContent>
				<Stack space={8} dividers>
					<FormSection title="Primary Information">
						<Stack>
							<div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
								<FormInput
									name="user.firstName"
									label="First Name"
									validations={{ required: 'First Name is required' }}
									autoFocus
								/>
								<FormInput name="user.middleName" label="Middle Name" />
								<FormInput
									name="user.lastName"
									label="Last Name"
									validations={{ required: 'Last Name is required' }}
								/>
							</div>

							<div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
								<FormInput
									name="user.email"
									label="Email Address"
									validations={{ required: 'Email Address is required' }}
								/>
								<FormInput
									name="user.phoneNumber"
									label="Phone Number"
									validations={{ required: 'Phone Number is required' }}
								/>
							</div>
						</Stack>
					</FormSection>

					{Auth.profile()?.id === user?.id && (
						<FormSection title="Profile Image">
							<div className="flex flex-start space-x-6">
								<div className="w-20 h-20 rounded-full overflow-hidden">
									{img ? (
										<img src={blobUrl(img)} alt="" className="object-cover w-full h-full" />
									) : user?.avatarUrl ? (
										<Avatar id={userId} />
									) : (
										<UserDefault name={getFullName(user)} className="w-9 h-9" />
									)}
								</div>

								<div className="flex-1">
									<FileDropper onChange={(x) => setImg(x[0])} />
								</div>
							</div>
						</FormSection>
					)}

					<FormSection title="Address">
						<Stack>
							<FormInput
								name="user.physicalAddress.address1"
								label="Address 1"
								validations={{ required: 'Address 1 is required' }}
							/>

							<div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
								<FormInput name="user.physicalAddress.address2" label="Address 2" />
								<FormInput name="user.physicalAddress.address3" label="Address 3" />
							</div>

							<div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
								<FormInput
									name="user.physicalAddress.city"
									label="City"
									validations={{ required: 'City is required' }}
								/>
								<FormInput
									name="user.physicalAddress.zipOrPostcode"
									label="Post Code"
									validations={{ required: 'Post Code is required' }}
								/>
								<FormSelect
									name="user.physicalAddress.country"
									label="Country"
									options={countries?.map((x) => ({ label: x.name, value: x })) || []}
									validations={{ required: 'Country is required' }}
								/>
							</div>
						</Stack>
					</FormSection>

					<FormSection title="Notes">
						<FormInput name="user.note" label="Notes" as="textarea" />
					</FormSection>

					<FormSection title="Organisation">
						<Stack>
							<FormSelect
								name="user.organization"
								label="Organisation"
								options={orgs?.items.map((x) => ({ label: x.name, value: x })) || []}
								clearable
							/>

							<AutoHeight show={!!org}>
								<FormToggle
									name="user.useOrganization"
									label="Also send case notifications to organisation"
								/>
							</AutoHeight>
						</Stack>
					</FormSection>

					{Auth.is('Admin', 'FENZAdmin') && (
						<FormSection title="Roles">
							<div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0">
								<Stack className="flex-1">
									{Auth.is('Admin', 'FENZAdmin') && (
										<FormCheckbox
											name={`roles.${USER_ROLE.Administrator}`}
											label={UserRole.readable(USER_ROLE.Administrator)}
										/>
									)}
									<FormCheckbox
										name={`roles.${USER_ROLE.Support}`}
										label={UserRole.readable(USER_ROLE.Support)}
									/>
									{Auth.is('Admin', 'FENZAdmin') && (
										<FormCheckbox
											name={`roles.${USER_ROLE.CaseManager}`}
											label={UserRole.readable(USER_ROLE.CaseManager)}
										/>
									)}
								</Stack>

								<Stack className="flex-1">
									<FormCheckbox
										name={`roles.${USER_ROLE.Reviewer}`}
										label={UserRole.readable(USER_ROLE.Reviewer)}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.Mediator}`}
										label={UserRole.readable(USER_ROLE.Mediator)}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.PeerReviewer}`}
										label={UserRole.readable(USER_ROLE.PeerReviewer)}
									/>
								</Stack>

								<Stack className="flex-1">
									<FormCheckbox
										name={`roles.${USER_ROLE.Respondent}`}
										label={UserRole.readable(USER_ROLE.Respondent)}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.ACCAdmin}`}
										label={UserRole.readable(USER_ROLE.ACCAdmin)}
									/>
									<FormCheckbox
										name={`roles.${USER_ROLE.FileHandler}`}
										label={UserRole.readable(USER_ROLE.FileHandler)}
									/>
								</Stack>

								<Stack className="flex-1">
									{Auth.is('Admin', 'FENZAdmin') && (
										<FormCheckbox
											name={`roles.${USER_ROLE.FENZAdmin}`}
											label={UserRole.readable(USER_ROLE.FENZAdmin)}
										/>
									)}

									<FormCheckbox
										name={`roles.${USER_ROLE.Adjudicator}`}
										label={UserRole.readable(USER_ROLE.Adjudicator)}
									/>

									<FormCheckbox
										name={`roles.${USER_ROLE.Facilitator}`}
										label={UserRole.readable(USER_ROLE.Facilitator)}
									/>
								</Stack>
							</div>
						</FormSection>
					)}

					<AutoHeight show={Auth.is('Admin', 'FENZAdmin') && (!!isReviewer || !!isMediator)}>
						<FormSection title="Billing">
							<Stack>
								<div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
									<FormInput name="user.bankAccountNumber" label="Bank Account Number" />

									<FormInput name="user.gstnumber" label="GST Number" />
								</div>

								<FormToggle name="user.isGSTRegistered" label="User is GST Registered" />
							</Stack>
						</FormSection>
					</AutoHeight>

					<FormSection title="Settings">
						<div className="flex flex-row">
							<Stack className="flex-1">
								{Auth.is('Admin', 'FENZAdmin') && (
									<FormToggle name="user.twoFactorEnabled" label="Two Factor Enabled" />
								)}

								<FormToggle name="user.notificationsEnabled" label="Notifications Enabled" />
							</Stack>

							<Stack className="flex-1">
								<FormToggle name="user.disabled" label="User Disabled" />
							</Stack>
						</div>
					</FormSection>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditUser
