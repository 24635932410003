import { FC } from 'react'
import Form from 'components/Form/Form'
import FormDate from 'components/Form/FormDate'
import { CheckIcon } from '@heroicons/react/outline'
import { openModal, ModalContent, ModalFooter, useModalState } from 'hooks/useModal'
import api from 'api'
import { CaseModel } from 'types/models'
import { ModalResolveFn } from 'types'
import { useForm } from 'react-hook-form'
import Stack from 'components/Stack'
import Actions from 'components/Actions'
import { toast } from 'components/toast'

interface OpenEditCaseCompletionProps {
	caseData: CaseModel
	refetch: () => Promise<unknown>
}

const openEditCaseCompletion = (props: OpenEditCaseCompletionProps) => {
	return openModal({
		title: 'Edit Case Completion Status',
		render: (close) => <EditCaseCompletion close={close} {...props} />,
	})
}

interface FormData {
	completedDate?: Date
	billingDate?: Date
}

const EditCaseCompletion: FC<OpenEditCaseCompletionProps & { close: ModalResolveFn }> = ({
	close,
	caseData,
	refetch,
}) => {
	const { isSaving, setSaving } = useModalState()

	const formContext = useForm<FormData>({
		defaultValues: {
			completedDate: caseData.completedDate,
			billingDate: caseData.billingDate,
		},
	})

	const handleSubmit = async (formData: FormData) => {
		setSaving(true)

		try {
			await api.put(`/Cases/${caseData.id}`, { ...caseData, ...formData })

			await refetch()

			close()

			toast({ title: 'Case Updated' })
		} catch (error) {
			api.handleError(error)
			setSaving(false)
		}
	}

	return (
		<Form context={formContext} onSubmit={handleSubmit}>
			<ModalContent>
				<Stack>
					<FormDate
						name="completedDate"
						label="Completed Date"
						validations={{ required: 'Completed Date is required' }}
					/>

					<FormDate
						name="billingDate"
						label="Billing Date"
						validations={{ required: 'Billing Date is required' }}
					/>
				</Stack>
			</ModalContent>

			<ModalFooter>
				<div className="flex justify-end">
					<Actions
						actions={[
							{
								title: 'Save',
								intent: 'save',
								icon: <CheckIcon className="w-5 h-5" />,
								type: 'submit',
								isLoading: isSaving,
							},
						]}
					/>
				</div>
			</ModalFooter>
		</Form>
	)
}

export default openEditCaseCompletion
