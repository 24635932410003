import { FC, useState } from 'react'
import { ClassValue } from 'clsx'
import { humanFileSize } from 'utils/funcs'
import FileSaver from 'file-saver'
import { format } from 'date-fns'
import api from 'api'
import Table, { TableSchema } from 'components/Table'
import openFileView from 'modals/openFileView'
import { EyeIcon, TrashIcon, DotsHorizontalIcon, CloudDownloadIcon } from '@heroicons/react/outline'
import { confirm } from 'alerts'
import { AppFileModel } from 'types/models'
import { Spinner } from 'components/Icon'
import { Auth } from 'Auth'
import { toast } from 'components/toast'

interface AppFilesType {
	files?: AppFileModel[]
	onRefetch: () => void
	className?: ClassValue
}

const AppFiles: FC<AppFilesType> = ({ files, onRefetch, className }) => {
	const [isDownloading, setDownloading] = useState<AppFileModel[]>([])

	const schema: TableSchema<AppFileModel> = {
		cols: [
			{
				title: 'Name',
				value: (x) => x.name,
			},
			{
				title: 'Uploaded',
				value: (x) => <span>{format(new Date(x.created), 'dd/MM/yyyy HH:mm:ss')}</span>,
			},
			{
				title: 'Size',
				value: (x) => humanFileSize(x.fileSize),
			},
		],
		actions: (x) =>
			Auth.is('Admin', 'FENZAdmin')
				? [
						{
							icon:
								isDownloading.indexOf(x) > -1 ? (
									<Spinner className="w-5 h-5 animate-spin" />
								) : (
									<DotsHorizontalIcon className="w-5 h-5" />
								),
							intent: 'menu',
							actions: [
								[
									{
										title: 'View File',
										icon: <EyeIcon className="w-5 h-5" />,
										onClick: () => openFileView({ file: x, endpoint: 'AppFiles' }),
										disabled: isDownloading.indexOf(x) > -1,
									},
									{
										title: 'Download File',
										icon: <CloudDownloadIcon className="w-5 h-5" />,
										onClick: () => downloadFile(x),
										disabled: isDownloading.indexOf(x) > -1,
									},
								],
								[
									{
										title: 'Delete File',
										icon: <TrashIcon className="w-5 h-5" />,
										intent: 'danger',
										onClick: () => deleteFile(x),
										disabled: isDownloading.indexOf(x) > -1,
									},
								],
							],
						},
				  ]
				: undefined,
	}

	const downloadFile = async (file: AppFileModel) => {
		setDownloading((list) => list.concat([file]))

		const res = await api.get<Blob>(`/AppFiles/${file.id}/download`, { responseType: 'blob' })
		FileSaver.saveAs(res.data, file.name)

		setDownloading((list) => list.filter((x) => x !== file))

		toast({ title: 'File Downloaded', message: file.name })
	}

	const deleteFile = (file: AppFileModel) => {
		confirm({
			title: `Are you sure you want to delete ${file.name}?`,
			onAccept: async () => {
				try {
					await api.delete(`/AppFiles/${file.id}`)

					onRefetch()

					toast({
						title: 'File Deleted',
					})
				} catch (error) {
					api.handleError(error)
				}
			},
		})
	}

	return <Table schema={schema} items={files || []} className={className} />
}

export default AppFiles
