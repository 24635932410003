import { FC, ChangeEvent, ReactNode } from 'react'
import Actions from 'components/Actions'
import { ArrowLeftIcon, ArrowRightIcon, ClipboardCopyIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline'
import AutoHeight from 'components/AutoHeight'
import { Spinner } from 'components/Icon'
import { TableSchema } from './Table'
import { copyTabularData } from 'utils/funcs'
import { Auth } from 'Auth'
import { toast } from 'components/toast'

interface PaginationProps {
	schema?: TableSchema<any>
	items: any[]
	pageSize: number
	setPageSize: (x: number) => void | Promise<void>

	totalPages: number
	itemCount: number
	totalCount: number

	page: number
	setPage: (x: number) => void | Promise<void>

	refetch?: () => void

	isSearchVisible?: boolean
	setSearchVisible?: (x: boolean | ((x: boolean) => boolean)) => void

	searchCount?: number
	searchComponent?: ReactNode

	isLoading?: boolean
}

const Pagination2: FC<PaginationProps> = ({
	schema,
	items,
	pageSize,
	setPageSize,
	totalPages,
	itemCount,
	totalCount,
	page,
	setPage,
	refetch,
	searchCount,
	isSearchVisible,
	setSearchVisible,
	searchComponent,
	isLoading,
	children,
}) => {
	return (
		<>
			<div className="flex flex-col sm:flex-row items-start sm:items-center justify-between pb-6">
				<div className="flex items-center flex-1 flex-wrap gap-3">
					<div>
						<select
							className="block form-input w-full transition duration-150 ease-in-out sm:text-sm sm:leading-4 mr-3"
							value={pageSize}
							onChange={(e: ChangeEvent<HTMLSelectElement>) => setPageSize(+e.target.value)}
						>
							<option value={5}>5 results</option>
							<option value={10}>10 results</option>
							<option value={20}>20 results</option>
							<option value={50}>50 results</option>
							<option value={100}>100 results</option>
						</select>
					</div>

					{(searchComponent || refetch) && (
						<Actions
							actions={[
								refetch && {
									icon: <RefreshIcon className="w-4 h-4" />,
									onClick: () => refetch(),
									size: 'sm',
									rounded: 'md',
								},
								!!searchComponent && {
									title: searchCount ? (
										<div className="whitespace-nowrap">
											Searching by {searchCount} field{searchCount !== 1 ? 's' : ''}
										</div>
									) : (
										'Search'
									),
									icon: <SearchIcon className="w-4 h-4" />,
									onClick: () => {
										if (setSearchVisible) setSearchVisible((x) => !x)
									},
									intent: searchCount ? 'primary' : 'secondary',
									size: 'sm',
									rounded: 'md',
									className: 'px-4',
								},
							]}
						/>
					)}
				</div>

				<div className="flex items-center gap-3">
					{isLoading && <Spinner className="w-5 h-5 spin mr-3" />}

					{!!navigator?.clipboard?.writeText &&
						Auth.is('Admin', 'CaseManager', 'Support', 'FENZAdmin') &&
						schema && (
							<div className="hidden sm:block">
								<Actions
									actions={[
										{
											icon: <ClipboardCopyIcon className="w-4 h-4" />,
											size: 'sm',
											rounded: 'md',
											onClick: () => {
												try {
													copyTabularData(schema, items)
													toast({ title: 'Table data copied' })
												} catch (e) {
													toast({ title: 'Table data not copied', intent: 'error' })
												}
											},
										},
									]}
								/>
							</div>
						)}

					<div className="hidden sm:flex items-center">
						{totalPages <= 200 ? (
							<select
								className="form-input transition duration-150 ease-in-out sm:text-sm sm:leading-4 w-20 mr-3"
								value={page}
								onChange={(e: ChangeEvent<HTMLSelectElement>) => setPage(+e.target.value)}
							>
								{[...new Array(totalPages || 0)].fill(null).map((_, i) => (
									<option key={i} value={i + 1}>
										{i + 1}
									</option>
								))}
							</select>
						) : (
							<input
								type="number"
								className="form-input transition duration-150 ease-in-out sm:text-sm sm:leading-4 w-20 mr-3"
								value={page}
								onChange={(e) => setPage(+e.target.value)}
								min={1}
								max={totalPages}
							/>
						)}

						<Actions
							actions={[
								{
									title: '',
									icon: <ArrowLeftIcon className="w-4 h-4" />,
									onClick: () => setPage(page > 1 ? page - 1 : page),
									rounded: 'md',
								},
								{
									title: '',
									icon: <ArrowRightIcon className="w-4 h-4" />,
									onClick: () => setPage(page < totalPages ? page + 1 : totalPages),
									rounded: 'md',
								},
							]}
						/>
					</div>
				</div>
			</div>

			<div className="-mx-6">
				<AutoHeight show={!!isSearchVisible}>
					<div>{searchComponent}</div>
				</AutoHeight>
			</div>

			{children}

			<div className="mt-6 flex flex-col sm:flex-row justify-between items-center">
				<div className="mt-4 sm:mt-0 text-sm leading-5 text-gray-700 dark:text-gray-200 order-3 sm:order-1">
					Showing <span className="font-medium">{totalCount === 0 ? 0 : (page - 1) * pageSize + 1}</span> to{' '}
					<span className="font-medium">{(page - 1) * pageSize + (itemCount || 0)}</span> of{' '}
					<span className="font-medium">{totalCount || 0}</span> results
				</div>

				<div className="flex items-center order-2">
					{totalPages <= 200 ? (
						<select
							className="form-input transition duration-150 ease-in-out sm:text-sm sm:leading-4 w-20 mr-3"
							value={page}
							onChange={(e: ChangeEvent<HTMLSelectElement>) => setPage(+e.target.value)}
						>
							{[...new Array(totalPages || 0)].fill(null).map((_, i) => (
								<option key={i} value={i + 1}>
									{i + 1}
								</option>
							))}
						</select>
					) : (
						<input
							type="number"
							className="form-input transition duration-150 ease-in-out sm:text-sm sm:leading-4 w-20 mr-3"
							value={page}
							onChange={(e) => setPage(+e.target.value)}
							min={1}
							max={totalPages}
						/>
					)}

					<Actions
						actions={[
							{
								title: '',
								icon: <ArrowLeftIcon className="w-4 h-4" />,
								onClick: () => setPage(page > 1 ? page - 1 : page),
								rounded: 'md',
							},
							{
								title: '',
								icon: <ArrowRightIcon className="w-4 h-4" />,
								onClick: () => setPage(page < totalPages ? page + 1 : totalPages),
								rounded: 'md',
							},
						]}
					/>
				</div>
			</div>
		</>
	)
}

export default Pagination2
